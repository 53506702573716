import { useTranslation } from '@frontend/i18n';
import { useSettingsNavigate } from '@frontend/settings-routing';
import { Heading, Modal, ModalControlModalProps, Text } from '@frontend/design-system';
import { ProviderAppointmentTypeMappingInstructions } from './ProviderAppointmentTypeMappingInstructions';

type EmptyAppointmentTypeMappingModalProps = {
  modalProps: ModalControlModalProps;
  locationId: string;
};
export const EmptyAppointmentTypeMappingModal = ({ modalProps, locationId }: EmptyAppointmentTypeMappingModalProps) => {
  const { t } = useTranslation('scheduleGlobalSettings');
  const { navigate: settingsNavigate } = useSettingsNavigate();

  const handleStartMapping = () => {
    settingsNavigate({
      to: '/schedule/appointment-types/:locationId',
      params: {
        locationId,
      },
    });
  };

  return (
    <Modal maxWidth={600} {...modalProps}>
      <Modal.Header onClose={modalProps.onClose}>
        <Heading level={2}>{t('Connect Providers & Appointments')}</Heading>
        <Text size='medium' weight='regular' color='light'>
          ⏱️ {t('Est. time: 5–10 minutes')}
        </Text>
      </Modal.Header>
      <Modal.Body>
        <ProviderAppointmentTypeMappingInstructions isModalContent locationId={locationId} />
      </Modal.Body>
      <Modal.Footer
        primary={{
          label: t('Start Mapping'),
          onClick: handleStartMapping,
        }}
        secondary={{
          label: t('Cancel'),
        }}
      />
    </Modal>
  );
};
