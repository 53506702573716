import { ReactNode, useMemo } from 'react';
import { css } from '@emotion/react';
import { InvoiceModel } from '@frontend/api-invoices';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Button, PopoverMenu, PopoverMenuItem, usePopoverMenu } from '@frontend/design-system';
import { InvoiceCard } from './invoice-card';

const SORT_OPTIONS = [
  { value: 'newest', label: 'Newest' },
  { value: 'oldest', label: 'Oldest' },
  { value: 'highest', label: 'Highest Value' },
  { value: 'lowest', label: 'Lowest Value' },
] as const;

export type SortOptions = (typeof SORT_OPTIONS)[number]['value'];

const style = {
  heading: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  cards: css`
    display: flex;
    flex-direction: column;
    row-gap: ${theme.spacing(2)};
  `,
};

export type OutstandingInvoiceCardsProps = {
  invoices: InvoiceModel[];
  selectedOrder: SortOptions;
  onSelectOrder: (selected: SortOptions) => void;
  trackingId: string;
  altAction?: ReactNode;
  cardActions?: (props: { invoice: InvoiceModel }) => ReactNode;
  className?: string;
};
export function OutstandingInvoiceCards({
  invoices,
  selectedOrder,
  onSelectOrder,
  altAction,
  className,
  trackingId,
  cardActions: CardActions,
}: OutstandingInvoiceCardsProps) {
  const { getTriggerProps, getMenuProps, getItemProps } = usePopoverMenu({});

  const selectedOrderLabel = useMemo(() => {
    return SORT_OPTIONS.find((option) => option.value === selectedOrder)?.label;
  }, [selectedOrder]);
  return (
    <div className={['invoice-cards', className].join(' ')}>
      <div className='invoice-cards__heading' css={style.heading}>
        <div>
          <Button variant='tertiary' size='large' {...getTriggerProps()}>
            {selectedOrderLabel}
            <Icon name='alt-caret-down-tiny' size={8} />
          </Button>
          <PopoverMenu {...getMenuProps()}>
            {SORT_OPTIONS.map((option, index) => (
              <PopoverMenuItem
                {...getItemProps({
                  index,
                  onClick: () => {
                    onSelectOrder(option.value);
                  },
                })}
                trackingId={`${trackingId}--sort-option--${option.value}`}
                key={option.value}
              >
                {option.label}
              </PopoverMenuItem>
            ))}
          </PopoverMenu>
        </div>
        <div>{altAction}</div>
      </div>

      <div className='invoice-cards__cards' css={style.cards}>
        {invoices.map((invoice) => (
          <InvoiceCard key={invoice.id} invoice={invoice}>
            {CardActions && <CardActions invoice={invoice} />}
          </InvoiceCard>
        ))}
      </div>
    </div>
  );
}
