import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Modal, Text, ModalControlModalProps } from '@frontend/design-system';

export const WeaveNumberModal = (props: ModalControlModalProps) => {
  const { t } = useTranslation('porting');
  return (
    <Modal maxWidth={600} {...props}>
      <Modal.Header onClose={props.onClose} css={{ marginBottom: theme.spacing(2) }}>
        {t('What is a weave number?')}
      </Modal.Header>
      <Modal.Body>
        <Text>
          {t(
            "This is the number that Weave has assigned to you free of charge. You can use this number to practice using Weave before you go live, or you can incorporate it into your business however you'd like."
          )}
        </Text>
      </Modal.Body>
      <Modal.Footer primary={{ label: t('Close'), onClick: props.onClose }} />
    </Modal>
  );
};
