import { useEffect } from 'react';
import {
  Media,
  MediaCollectionName_Enum,
} from '@weave/schema-gen-ts/dist/schemas/messaging/media/public/v1/service.pb';
import { PublicMediaQueries } from '@frontend/api-public-media';
import { theme } from '@frontend/theme';
import { ContentLoader } from '@frontend/design-system';
import { Image } from './image';
import { ImageGalleryContentWrapper } from './image-gallery-content-wrapper';
import { ImageGalleryHeader } from './image-gallery-header';
import { ASSETS_PER_PAGE, PaginationControl } from './pagination/pagination-control';
import { usePaginationHook } from './pagination/use-pagination-hook';
import { LocationIdsFieldProps, SearchFieldProps } from './types';

export const StockImageGalleryPanel = ({
  handleImageSelect,
  imageSize,
  locationIds,
  locationIdsFieldProps,
  onDoubleClick,
  searchFieldProps,
  selectedImages,
  trackingId,
}: {
  handleImageSelect: (media?: Media) => void;
  imageSize?: 'small' | 'large';
  locationIds: string[];
  locationIdsFieldProps: LocationIdsFieldProps;
  onDoubleClick?: (media?: Media) => void;
  searchFieldProps: SearchFieldProps;
  selectedImages: (Media | undefined)[];
  trackingId: string;
}) => {
  const paginationProps = usePaginationHook();

  const { data, isFetched, isLoading } = PublicMediaQueries.useListSystemMedia({
    businessGroupIds: locationIdsFieldProps.value,
    collectionName: MediaCollectionName_Enum.IMAGE_GALLERY,
    pageSize: ASSETS_PER_PAGE,
    pageToken: paginationProps.pageToken,
  });

  const images = (data?.pages || [])[0]?.media || [];
  const totalAssets = data?.pages?.[0]?.mediaCount || 0;

  useEffect(() => {
    const token = data?.pages?.[data?.pages.length - 1]?.nextPageToken;
    paginationProps.getNextAsset(token);
  }, [data?.pages.map((page) => page.nextPageToken).join(',')]);

  useEffect(() => {
    if (isFetched && paginationProps.totalPages === 0) {
      const totalPages = Math.ceil(totalAssets / ASSETS_PER_PAGE);
      paginationProps.setTotalPages(totalPages);
    }
  }, [paginationProps.assets]);

  return (
    <ImageGalleryContentWrapper>
      <div
        css={{
          height: imageSize === 'large' ? 500 : locationIds.length > 1 ? theme.spacing(49) : theme.spacing(41),
          overflowY: 'auto',
        }}
      >
        <ImageGalleryHeader
          locationIdsFieldProps={{ ...locationIdsFieldProps, name: 'locationIds' }}
          searchFieldProps={{ ...searchFieldProps, name: 'search' }}
          searchSize='large'
        />
        <div css={{ display: 'flex', flexWrap: 'wrap', gap: theme.spacing(1) }}>
          {isLoading ? (
            <ContentLoader show={isLoading} />
          ) : (
            images.map((image) => (
              <Image
                key={image.mediaId}
                image={image}
                onClick={() => {
                  if (selectedImages.find((img) => image.mediaId === img?.mediaId)) {
                    handleImageSelect();
                  } else {
                    handleImageSelect(image);
                  }
                }}
                onDoubleClick={() => onDoubleClick?.(image)}
                selected={!!selectedImages.find((img) => image.mediaId === img?.mediaId)}
                size={imageSize}
              />
            ))
          )}
        </div>
      </div>
      <PaginationControl {...{ ...paginationProps, trackingId }} />
    </ImageGalleryContentWrapper>
  );
};
