import type { Terminal } from '@stripe/terminal-js';
import { LogLevel } from '@weave/schema-gen-ts/dist/schemas/payments/terminallogs/service.pb';
import { log } from '../log';
import { PaymentsTerminalError } from '../payment-terminal-error';
import type {
  TerminalCollectAndProcessProps,
  TerminalStrategyMethods,
  TerminalStrategyProps,
} from '../terminal-strategy';
import {
  createTempTerminalInstance,
  isReaderConnected,
  isStripeErrorResponse,
  sdkCollectPaymentMethod,
  sdkConnect,
  sdkProcessPayment,
} from './sdk-utils';
import { getStripeTerminalSDK } from './stripe-sdk-loader';

const createSdkTerminalSession = async ({
  reader,
  locationId,
  paymentsUrl,
  appData,
  onPaymentStatusChange,
  sdkFailIfReaderInUse = false,
}: TerminalStrategyProps): Promise<TerminalStrategyMethods> => {
  onPaymentStatusChange?.('initializing');

  const logMetaData = {
    paymentsUrl,
    locationId,
    reader,
    ...appData,
  };

  const stripeTerminalSDK = await getStripeTerminalSDK();

  let terminalInstance: Terminal | null = createTempTerminalInstance({
    stripeTerminalSDK,
    stripeLocationId: reader.stripeLocationId,
    paymentsUrl,
    locationId,
  });

  // 2. connect to reader - can take ~500ms
  await sdkConnect({
    terminalInstance,
    reader,
    onPaymentStatusChange,
    logMetaData,
    failIfReaderInUse: sdkFailIfReaderInUse,
  });

  /**
   * This method is responsible for collecting and processing the payment using the sdk.
   *
   * See [Terminal Payments Flowchart]{@link https://drive.google.com/file/d/1ZHd1HElTDGwqnVPENfrwkXSXqouKHArO/view?usp=sharing}
   */
  const collectAndProcessPayment = async ({
    paymentIntent,
    invoiceId,
    failIfReaderInUse = sdkFailIfReaderInUse,
  }: TerminalCollectAndProcessProps) => {
    if (!paymentIntent.clientSecret) {
      throw new PaymentsTerminalError('collect', 'Client secret is missing');
    }

    if (!terminalInstance) {
      // In case of retries, or a setup error, try creating the instance again
      terminalInstance = createTempTerminalInstance({
        stripeTerminalSDK,
        stripeLocationId: reader.stripeLocationId,
        paymentsUrl,
        locationId,
      });
      // This case shouldn't happen, leaving here in case the stripe lib doesn't follow it's types
      // we should review the logs and see if this ever happens. If not, then remove.
      if (!terminalInstance) {
        log('stripeTerminal is not defined in collectAndProcessPayment', {
          type: 'collection_flow',
          metaData: logMetaData,
          extraData: {
            event: 'info',
            invoiceId: invoiceId,
            paymentIntentId: paymentIntent?.id,
            error: 'stripeTerminal is not defined',
            severity: LogLevel.LOG_LEVEL_ERROR,
          },
        });
        throw new PaymentsTerminalError('collect', 'Failed to create terminal instance');
      }
    }

    const isConnected = await isReaderConnected(terminalInstance);
    if (!isConnected) {
      await sdkConnect({
        terminalInstance,
        reader,
        onPaymentStatusChange,
        logMetaData,
        failIfReaderInUse,
      });
    }

    onPaymentStatusChange?.('waiting-input');

    // 3. collect payment method
    const collectResult = await sdkCollectPaymentMethod({
      terminalInstance,
      paymentIntent,
      invoiceId,
      logMetaData,
    });

    log('collect payment method success', {
      type: 'metric',
      metaData: logMetaData,
      extraData: {
        metricLabel: 'payment_collection_succeeded',
      },
    });

    onPaymentStatusChange?.('processing');

    // 4. process payment
    const processResult = await sdkProcessPayment({
      terminalInstance,
      collectResult,
      invoiceId,
      logMetaData,
    });

    log('payment collection success', {
      type: 'collection_flow',
      metaData: logMetaData,
      extraData: {
        event: 'payment_collection_success',
        invoiceId: invoiceId,
        paymentIntentId: processResult?.paymentIntent.id,
      },
    });

    onPaymentStatusChange?.('success');

    // 5. Finished! - clean up
    try {
      await terminalInstance.disconnectReader();
    } catch (error) {
      // Intentionally ignore
      console.error('Error disconnecting reader', error);
    }
    terminalInstance = null;
    return;
  };

  const cancelCurrentAction = async () => {
    if (!terminalInstance) {
      throw new PaymentsTerminalError('cancel', 'Failed to create terminal instance');
    }
    try {
      // canceling when there is no payment will throw an error
      if (terminalInstance.getPaymentStatus() !== 'waiting_for_input') return;

      const response = await terminalInstance.cancelCollectPaymentMethod();
      await terminalInstance.disconnectReader();
      if (isStripeErrorResponse(response)) {
        throw new PaymentsTerminalError('cancel', response.error);
      }
    } catch (error) {
      console.log('Error cancelling current action, here is the error', { error });
    }
    terminalInstance = null;
  };

  return {
    cancelCurrentAction,
    collectAndProcessPayment,
    usingServerDrivenFlow: false,
  };
};

export const SDKStrategy = { createSdkTerminalSession };
