import { useEffect } from 'react';
import { css } from '@emotion/react';
import { FallbackRender } from '@sentry/react/types/errorboundary';
import { useLocation, useNavigate } from '@tanstack/react-location';
import { Trans, useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Accordion } from '@frontend/design-system';
import { ErrorPage } from './error-page';
// import Laugh from './kefka-laugh.mp3';

type Props = Parameters<FallbackRender>[0];

export const StackErrorPage = ({ error }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const loc = useLocation();

  //Once the error occurs, treat the next location change with a full refresh
  useEffect(() => {
    loc.subscribe(() => {
      window.location.reload();
    });
  }, []);

  return (
    <section
      css={css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      `}
    >
      <ErrorPage
        title={t("There's Been An Error")}
        subTitle={
          <Trans t={t}>
            We ran into an error on the page you're visiting. The error has been reported. If you continue experiencing
            this issue, please call Customer Support at <a href='tel:+18885795668'>+1-888-579-5668</a>.
          </Trans>
        }
        primaryButtonText={t('Try Again')}
        primaryButtonOnClick={() => window.location.reload()}
        secondaryButtonText={t('Go to Home Page')}
        secondaryButtonOnClick={() => navigate({ to: '/home/dashboard' })}
      />
      <Accordion
        css={css`
          width: 100%;
          max-width: 700px;
        `}
      >
        <Accordion.Item value='error-details'>
          <Accordion.Header title={t('Show Error Details')} />
          <Accordion.Body>
            {/* For when we decide to add some easter eggs
            <audio src={Laugh} controls={undefined} autoPlay={true} />
            <img
              src='https://media2.giphy.com/media/BzyTuYCmvSORqs1ABM/giphy-downsized.gif?cid=6104955ebbkj2kqna9wbod2slbou0l0pdccy6g6bp5c9ooia&rid=giphy-downsized.gif&ct=g'
              alt='Cat'
            /> */}
            <pre
              css={css`
                overflow: auto;
                padding: ${theme.spacing(2)};
                background: ${theme.colors.neutral10};
                border-radius: ${theme.borderRadius.small};
                max-width: 800px;
              `}
            >
              {error instanceof Error ? error?.stack : <code>{t('Hmm, no error to speak of')}</code>}
            </pre>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </section>
  );
};
