import { useState } from 'react';
import { css } from '@emotion/react';
import { BulkMessagingApi } from '@frontend/api-bulk-messaging';
import { SIDEBAR_BREAKPOINTS, useContentComposer } from '@frontend/content-composer';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { BulkEmailPrefixes } from '@frontend/tracking-prefixes';
import { Button, Modal, useModalControl, useAlert } from '@frontend/design-system';
import { SendTestEmailModalBody } from './send-test-email-modal-body';

type Props = {
  subject: string;
};

export const SendTestEmailButton = ({ subject }: Props) => {
  const { t } = useTranslation('email-composer');
  const { renderHtml } = useContentComposer();
  const [loading, setLoading] = useState(false);
  const alert = useAlert();
  const { modalProps, triggerProps, closeModal } = useModalControl();
  const { selectedOrgId: orgId } = useAppScopeStore();

  const sendTestEmail = async (passedEmailAddress: string) => {
    if (!passedEmailAddress) {
      alert.error(t('Please enter an email address.'));
      return;
    }

    setLoading(true);

    try {
      const html = await renderHtml();
      if (!html) return;
      await BulkMessagingApi.sendTestEmail(orgId, subject ? subject : t('Test Email'), html, passedEmailAddress);
      alert.success(t('Test email sent!'));
      closeModal();
    } catch (error) {
      alert.error(t('Failed to send test email! Try again.'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button
        variant='secondary'
        {...triggerProps}
        css={css`
          height: 100%;
          @media (max-width: ${SIDEBAR_BREAKPOINTS.desktop}px) {
            display: none;
          }
        `}
        trackingId={`${BulkEmailPrefixes.Composer}-send-test-email-btn`}
      >
        {t('Send Test Email')}
      </Button>
      <Button
        variant='secondary'
        {...triggerProps}
        css={css`
          height: 100%;
          width: fit-content;
          @media (min-width: ${SIDEBAR_BREAKPOINTS.desktop + 1}px) {
            display: none;
          }
        `}
        trackingId={`${BulkEmailPrefixes.Composer}-send-test-email-btn`}
      >
        {t('Send Test')}
      </Button>
      <Modal
        {...modalProps}
        minWidth={700}
        css={css`
          @media (max-width: ${SIDEBAR_BREAKPOINTS.desktop}px) {
            min-width: min(95%, 700px);
          }
        `}
      >
        <SendTestEmailModalBody hasSubject={!!subject} loading={loading} sendTestEmail={sendTestEmail} />
      </Modal>
    </>
  );
};
