import { useTranslation } from '@frontend/i18n';
import { useContentComposer } from '../content-composer-provider';
import { FacebookReviewButtonSection } from '../sections';
import { ToolboxButton } from '../sidebar';

export const FacebookReviewButtonTool = () => {
  const { t } = useTranslation('content-composer');
  const { insertSection } = useContentComposer();

  return (
    <ToolboxButton
      icon='social-facebook'
      title={t('Facebook Review Button')}
      subtitle={t(`Link to a location's Facebook account`)}
      onClick={() => insertSection(<FacebookReviewButtonSection />)}
    >
      <FacebookReviewButtonSection />
    </ToolboxButton>
  );
};
