import { useEffect } from 'react';
import { InsuranceDetailQueries } from '@frontend/api-insurance-detail';
import { formatDate, getTodaysDate } from '@frontend/date';
import { SchemaInsuranceVerificationAPIService } from '@frontend/schema';
import { FormFieldActionTypes, useForm } from '@frontend/design-system';
import { RelationshipEnum } from '../constants';
import {
  getPayerCombinedId,
  prepareInsuranceDetails,
  removeNonAlphaNumericCharacters,
  sanitizeText,
  validateNationalProviderId,
  validateNationalProviderTaxId,
} from '../helpers';
import { usePersonInfoStore } from '../providers';
import { useGetFormData } from './use-get-form-data';

const MAX_DATE = getTodaysDate('YYYY-MM-DD');

export const useInsuranceForm = () => {
  const { personInfo, locationId } = usePersonInfoStore(['locationId', 'personInfo']);
  const personId = personInfo?.PersonID as string;
  const { isLoading, payerList, nationalProviderIdList, primSubscriberList, providerList, insuranceDetails } =
    useGetFormData();

  const form = useForm({
    fields: {
      payerId: { type: 'dropdown', required: true },
      groupName: { type: 'text' },
      groupNumber: { type: 'text' },
      patientMemberId: { type: 'text', required: true },
      patientFirstName: { type: 'text', required: true },
      patientLastName: { type: 'text', required: true },
      patientBirthDate: { type: 'datePicker', required: true, maxDate: MAX_DATE },
      nationalProviderId: {
        type: 'text',
        required: true,
        validator: ({ value }) => validateNationalProviderId(value),
      },
      taxId: { type: 'text', validator: ({ value }) => validateNationalProviderTaxId(value), required: true },
      relationship: { type: 'dropdown', required: true, value: RelationshipEnum.Self },
      primarySubscriber: { type: 'dropdown', hidden: true },
      subscriberFirstName: { type: 'text', required: true, hidden: true },
      subscriberLastName: { type: 'text', required: true, hidden: true },
      subscriberBirthDate: {
        type: 'datePicker',
        hidden: true,
        required: true,
        maxDate: MAX_DATE,
      },
      subscriberMemberId: { type: 'text', required: true, hidden: true },
    },
    fieldStateReducer: (state, action) => {
      const isUpdateAction = action.type === FormFieldActionTypes.Update;

      if (isUpdateAction && action.payload.name === 'nationalProviderId') {
        const taxId =
          providerList.find((provider) => provider.nationalProviderId === action.payload.value)?.taxId ?? '';
        if (taxId) {
          return {
            ...state,
            taxId: {
              ...state.taxId,
              value: taxId,
              error: validateNationalProviderTaxId(taxId),
            },
          };
        }
      }

      if (
        isUpdateAction &&
        (action.payload.name === 'taxId' ||
          action.payload.name === 'patientMemberId' ||
          action.payload.name === 'groupNumber')
      ) {
        return {
          ...state,
          [action.payload.name]: {
            ...state[action.payload.name],
            value: removeNonAlphaNumericCharacters(action.payload.value),
          },
        };
      }

      if (isUpdateAction && action.payload.name === 'relationship') {
        const isShowPrimSubscriber = action.payload.value && action.payload.value !== RelationshipEnum.Self;

        return {
          ...state,
          primarySubscriber: {
            ...state.primarySubscriber,
            hidden: !isShowPrimSubscriber,
            value: isShowPrimSubscriber ? state.primarySubscriber.value : '',
            error: '',
          },
          subscriberFirstName: {
            ...state.subscriberFirstName,
            hidden: !isShowPrimSubscriber,
            value: isShowPrimSubscriber ? state.subscriberFirstName.value : '',
            error: '',
          },
          subscriberLastName: {
            ...state.subscriberLastName,
            hidden: !isShowPrimSubscriber,
            value: isShowPrimSubscriber ? state.subscriberLastName.value : '',
            error: '',
          },
          subscriberBirthDate: {
            ...state.subscriberBirthDate,
            hidden: !isShowPrimSubscriber,
            value: isShowPrimSubscriber ? state.subscriberBirthDate.value : '',
            error: '',
          },
          subscriberMemberId: {
            ...state.subscriberMemberId,
            hidden: !isShowPrimSubscriber,
            value: isShowPrimSubscriber ? state.subscriberMemberId.value : '',
            error: '',
          },
        };
      }

      return state;
    },
  });

  const { data: subscriberInfo, isLoading: isLoadingSubscriberInfo } =
    InsuranceDetailQueries.useGetSubscriberByDependentsPersonID(personId, locationId ? locationId : '', {
      disabled: isLoading || !form.values.relationship || form.values.relationship === RelationshipEnum.Self,
    });

  // seed subscriber details into form when relationship is changed and when we have only one subscriber
  useEffect(() => {
    const shouldSeedPrimarySubscriber =
      form.values.relationship !== RelationshipEnum.Self &&
      !form.values.primarySubscriber &&
      primSubscriberList.length === 1;

    if (shouldSeedPrimarySubscriber) {
      form.seedValues({
        primarySubscriber: primSubscriberList[0]?.value ?? '',
      });
    }
  }, [form.values.relationship, primSubscriberList]);

  // seed insurance details into form
  useEffect(() => {
    if (isLoading) {
      return;
    }
    const details = prepareInsuranceDetails(insuranceDetails ?? {}, payerList);
    let nationalProviderId = details.nationalProviderId;
    const providerTaxId = details.providerTaxId ?? providerList[0]?.taxId ?? '';

    if (!nationalProviderId && providerTaxId) {
      nationalProviderId = providerList.find((provider) => provider.taxId === providerTaxId)?.nationalProviderId ?? '';
    }

    const payerName = payerList.find((payer) => payer.value === details.payerId)?.label ?? '';
    const combinedPayerId = payerName ? getPayerCombinedId(details.payerId, payerName) : '';

    form.seedValues({
      patientFirstName: personInfo?.FirstName ?? details.firstName,
      patientLastName: personInfo?.LastName ?? details.lastName,
      patientBirthDate: formatDate(personInfo?.Birthdate ?? details.dateOfBirth, 'MM/DD/YYYY', true),
      patientMemberId: details.memberId,
      groupName: details.groupName,
      groupNumber: details.groupNumber,
      payerId: combinedPayerId,
      relationship: details.relationship,
      nationalProviderId: nationalProviderId,
      taxId: providerTaxId,
    });

    const isProviderIdValid = validateNationalProviderId(nationalProviderId) === '';
    const isTaxIdValid = validateNationalProviderTaxId(providerTaxId) === '';
    if (isProviderIdValid && isTaxIdValid) {
      form.validate();
    }
  }, [isLoading, insuranceDetails, payerList, providerList]);

  // seed subscriber details into form
  useEffect(() => {
    // SP-TODO: need to validate as it keeps failing currently and returns 404 error
    if (subscriberInfo) {
      form.seedValues({
        // SP-TODO: ask BE to update UUID type to string
        primarySubscriber: subscriberInfo.personId as string,
        subscriberFirstName: subscriberInfo.firstName,
        subscriberLastName: subscriberInfo.lastName,
        subscriberBirthDate: formatDate(subscriberInfo.birthDate, 'MM/DD/YYYY'),
        subscriberMemberId: sanitizeText(subscriberInfo.memberId) || undefined,
      });
    }
  }, [subscriberInfo, isLoadingSubscriberInfo]);

  // seed subscriber details into form when primary subscriber is selected
  useEffect(() => {
    if (!form.values.primarySubscriber) {
      return;
    }
    const shouldGetSubscriberDetails =
      !form.values.subscriberFirstName ||
      form.values.subscriberFirstName !== subscriberInfo?.firstName ||
      !form.values.subscriberBirthDate ||
      form.values.subscriberBirthDate !== subscriberInfo?.birthDate;

    if (shouldGetSubscriberDetails) {
      SchemaInsuranceVerificationAPIService.GetInsuranceByPersonID({ personId: form.values.primarySubscriber }).then(
        (res) => {
          form.seedValues({
            subscriberFirstName: res.firstName,
            subscriberLastName: res.lastName,
            subscriberBirthDate: formatDate(res.birthDate, 'MM/DD/YYYY'),
            subscriberMemberId: sanitizeText(res.memberId),
          });
        }
      );
    }
  }, [form.values.primarySubscriber, subscriberInfo]);

  return { form, isLoading, payerList, nationalProviderIdList, primSubscriberList, providerList };
};
