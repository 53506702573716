import { useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import { BarChartData, Chart } from '@frontend/charts';
import { i18next, useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { useLocations } from '../../../hooks';
import { formatDateByTimezone, getHourlyInsightsLabel } from '../../../utils';
import { DemoChip } from '../../demo-chip';
import { InfoTipPopover } from '../../info-tip-popover';
import { useFetchAggregatedLongDurationCallReports, usePhoneAnalyticsShallowStore } from '../hooks';
import { ChartProps } from './shared-types';

const colors = {
  incoming: theme.colors.secondary.seaweed30,
  outgoing: theme.colors.warning50,
};

const labels = {
  incoming: i18next.t('Incoming', { ns: 'analytics' }),
  outgoing: i18next.t('Outgoing', { ns: 'analytics' }),
};

export const LongDurationCallsChart = ({
  defaultChartAppearance = {},
  exportPdfProps,
  onFetchStateChange,
}: ChartProps) => {
  const { t } = useTranslation('analytics');
  const { locations } = useLocations();
  const { filterHintText, filters, isDemoAccount } = usePhoneAnalyticsShallowStore(
    'filterHintText',
    'filters',
    'isDemoAccount'
  );
  const { data, isHourlyData, isLoading, isMultiLocation } = useFetchAggregatedLongDurationCallReports();

  const chartData: BarChartData = useMemo(() => {
    let groups: BarChartData['groups'] = [];

    if (isMultiLocation) {
      // There will be multiple location objects with single/multiple dates
      groups = Object.entries(data?.aggregatedMultiLocations || {}).map(([key, { inbound, outbound }]) => {
        return {
          name: locations?.[key] || key,
          values: {
            incoming: inbound || 0,
            outgoing: outbound || 0,
          },
        };
      });
    } else {
      // For hourly data: There will be only one object with single date and hourly data for the given location
      // For daily data: There will be only one object with multiple dates for the given location
      const locationData = Object.values(data?.locations || {})[0] || {};

      if (isHourlyData) {
        // Hourly data is restricted to a single date, hence only one object
        const { inbound, outbound } = Object.values(locationData || {})[0] || {};
        const limitedIncoming = inbound?.hourly || [];
        const limitedOutgoing = outbound?.hourly || [];

        groups = limitedIncoming.map((incoming, i) => ({
          name: getHourlyInsightsLabel(i),
          values: {
            incoming,
            outgoing: limitedOutgoing[i],
          },
        }));
      } else {
        groups = Object.entries(locationData).map(([date, { inbound, outbound }]) => {
          return {
            name: dayjs(date).isValid() ? formatDateByTimezone(date, filters.start_date, filters.time_zone) : date,
            values: {
              incoming: inbound?.total || 0,
              outgoing: outbound?.total || 0,
            },
          };
        });
      }
    }

    return {
      groups,
    };
  }, [data, filters, isHourlyData, isMultiLocation, locations]);

  useEffect(() => {
    onFetchStateChange?.(isLoading);
  }, [isLoading]);

  return (
    <Chart colors={colors} isLoading={isLoading} labels={labels}>
      <Chart.Header
        {...exportPdfProps}
        infoTip={<InfoTipPopover>{t('Number of calls with duration greater than 10 mins')}</InfoTipPopover>}
        leftElement={isDemoAccount && <DemoChip />}
        subtitle={filterHintText}
        title={t('Long Duration Calls')}
      />
      <Chart.Legends />
      <Chart.BarChart
        appearance={{
          ...defaultChartAppearance,
          customYAxisTickFormat: undefined,
        }}
        data={chartData}
      />
    </Chart>
  );
};
