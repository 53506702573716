import { useMemo } from 'react';
import { Area, AreaChart, ResponsiveContainer } from 'recharts';
import { generateAreaGradientDefs } from '.';

export interface TinyAreaChartProps {
  color: string;
  data: number[];
  height?: number;
  width?: number;
}

export const TinyAreaChart = ({ data, color, width, height }: TinyAreaChartProps) => {
  const chartData = useMemo(() => data.map((value) => ({ value })), [data]);
  const colorGradientId = `${color}gradient`;

  return (
    <ResponsiveContainer width={width} height={height}>
      <AreaChart data={chartData} margin={{ bottom: 0 }}>
        {generateAreaGradientDefs(colorGradientId, color, 1)}
        <Area type='monotone' stroke={color} fill={`url(#${colorGradientId})`} dataKey='value' strokeWidth={2} />
      </AreaChart>
    </ResponsiveContainer>
  );
};
