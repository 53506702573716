import { Button, Section } from '@react-email/components';
import { useComposerSection, useLinks } from '../../hooks';
import { ButtonSectionProps, ComposerSectionOutputProps, UsableComposerSectionProps } from '../../types';
import { getPaddingStyles } from '../../utils/get-padding-styles';
import { cornersStyle, frameColorStyle } from './styles';

export const ButtonOutput = ({ renderMode }: ComposerSectionOutputProps) => {
  const { props } = useComposerSection<ButtonSectionProps>();

  return (
    <Section style={createFrameStyle(props)}>
      <Button style={createButtonStyle(props)} href={createButtonLink(props, renderMode)}>
        {props.text ?? ''}
      </Button>
    </Section>
  );
};

const createFrameStyle = (props: UsableComposerSectionProps<ButtonSectionProps>) => ({
  ...getPaddingStyles(props),
  ...frameColorStyle(props),
  textAlign: props.frameAlignment,
});

const createButtonStyle = (props: UsableComposerSectionProps<ButtonSectionProps>) => ({
  ...cornersStyle(props),
  background: props.backgroundColor,
  color: props.textColor,
  border: '0',
  fontSize: '16px',
  lineHeight: '18px',
  cursor: 'pointer',
  padding: '10px 20px',
});

const createButtonLink = (props: UsableComposerSectionProps<ButtonSectionProps>, renderMode?: string) => {
  const { formatLink, getAddressLink, onlineScheduleLink } = useLinks();

  switch (props.linkType) {
    case 'phone':
    case 'email':
      return formatLink(props.link ?? '', props.linkType, renderMode);
    case 'custom':
      return formatLink(props.link ?? '');
    case 'form':
      // TODO: Placeholder until we get a real form link
      return renderMode === 'preview' ? 'https://wv3.io/wf0001' : '';
    case 'address':
      return renderMode === 'preview' ? getAddressLink(renderMode) : '{{BUSINESS_GROUP_ADDRESS}}';
    case 'schedule':
      return renderMode === 'preview' ? onlineScheduleLink : '{{SCHEDULE_LINK}}';
    default: {
      return '';
    }
  }
};
