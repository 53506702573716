import {
  AlignmentSetting,
  ButtonSetting,
  FrameSetting,
  PaddingSetting,
  SettingsPanel,
} from '@frontend/content-composer-settings';
import { useTranslation } from '@frontend/i18n';
import { useSettingsForm } from '../../hooks';
import { ButtonSectionProps } from '../../types';

export const ButtonSettings = () => {
  const { t } = useTranslation('content-composer');

  const { getFieldProps } = useSettingsForm<ButtonSectionProps>({
    backgroundColor: { type: 'text' },
    corners: { type: 'radio' },
    frameAlignment: { type: 'radio' },
    frameColor: { type: 'text' },
    paddingTop: { type: 'text' },
    paddingBottom: { type: 'text' },
    paddingLeft: { type: 'text' },
    paddingRight: { type: 'text' },
    textColor: { type: 'text' },
  });

  return (
    <SettingsPanel title={t('Button Styling')}>
      <AlignmentSetting {...getFieldProps('frameAlignment')} />
      <ButtonSetting
        backgroundColorFieldProps={getFieldProps('backgroundColor')}
        cornersFieldProps={getFieldProps('corners')}
        textColorFieldProps={getFieldProps('textColor')}
      />
      <FrameSetting {...getFieldProps('frameColor')} />
      <PaddingSetting
        paddingTopFieldProps={getFieldProps('paddingTop')}
        paddingBottomFieldProps={getFieldProps('paddingBottom')}
        paddingLeftFieldProps={getFieldProps('paddingLeft')}
        paddingRightFieldProps={getFieldProps('paddingRight')}
        isLast
      />
    </SettingsPanel>
  );
};
