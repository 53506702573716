import { useTranslation } from '@frontend/i18n';
import { BulkEmailPrefixes } from '@frontend/tracking-prefixes';
import { useContentComposer } from '../content-composer-provider';
import { ButtonSection } from '../sections';
import { ToolboxButton } from '../sidebar';

export const ButtonTool = () => {
  const { t } = useTranslation('content-composer');
  const { insertSection } = useContentComposer();

  return (
    <ToolboxButton
      icon='updates'
      title={t('Button')}
      subtitle={t('Insert a call to action button.')}
      onClick={() => insertSection(<ButtonSection />)}
      trackingId={`${BulkEmailPrefixes.Composer}-button-tool-btn`}
    >
      <ButtonSection />
    </ToolboxButton>
  );
};
