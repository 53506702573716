import { useNavigate } from '@tanstack/react-location';
import { Trans, useTranslation } from '@frontend/i18n';
import { useLocationDataShallowStore } from '@frontend/location-helpers';
import { Text, TextLink } from '@frontend/design-system';

export const OverdueBy45DaysMessage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('billing-alerts');
  const { locationData } = useLocationDataShallowStore('locationData');

  const goToBilling = () => {
    navigate({ to: '/portal/account/billing' });
  };

  if (locationData?.Active) {
    return (
      <Trans t={t}>
        <Text>
          Your bill is critically overdue. Your account will be suspended soon if the outstanding dues are not resolved{' '}
          <TextLink as='span' onClick={goToBilling} trackingId='billing-overdue-45-resolve-balance-here-btn'>
            here
          </TextLink>
          . Contact support at{' '}
          <TextLink as='span' href='tel:+18885795668' trackingId='billing-overdue-45-support-number-link-btn'>
            (888)-579-5668
          </TextLink>{' '}
          option 2, then option 3
        </Text>
      </Trans>
    );
  }

  return (
    <Trans t={t}>
      <Text>
        Your account has been suspended. You will be unable to access this application without first resolving your
        overdue bill at{' '}
        <TextLink as='span' href='tel:+18885795668' trackingId='billing-overdue-45-support-number-link-btn'>
          888.579.5668
        </TextLink>{' '}
        option 2, then option 3
      </Text>
    </Trans>
  );
};
