import { FC, useRef, useEffect } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { TextField, Text, BaseFieldProps } from '@frontend/design-system';
import { MAX_CHANNEL_NAME_LENGTH } from '../../../constants';

interface CreateChannelProps {
  fieldProps: BaseFieldProps<string, '', HTMLInputElement>;
  show: boolean;
}

export const CreateChannel: FC<CreateChannelProps> = ({ fieldProps, show }) => {
  const { t } = useTranslation('team-chat');
  const textFieldRef = useRef<HTMLDivElement>(null);

  const charLeft = MAX_CHANNEL_NAME_LENGTH - fieldProps.value.length;

  useEffect(() => {
    if (show && textFieldRef.current) {
      /*
        Modal body get focused when the modalProps.show property is set to true. Hence, when we focus on the text field,
        it get overridden by the modal body focus. To avoid this, we are setting a timeout of 600ms to focus on
        the text field.
       */
      setTimeout(() => {
        textFieldRef.current?.focus();
      }, 600);
    }
  }, [show]);

  return (
    <>
      <TextField
        name={t('Create channel')}
        label=''
        placeholder={t('Name')}
        css={textAreaStyle}
        startAdornment={<Text>{t('#')}</Text>}
        maxLength={MAX_CHANNEL_NAME_LENGTH}
        fieldComponentProps={{ ref: textFieldRef }}
        {...fieldProps}
      />
      <Text textAlign='right' size='medium' css={charIndicatorTextStyle}>
        {t('{{charLeft}}/{{totalCount}} left', {
          charLeft,
          totalCount: MAX_CHANNEL_NAME_LENGTH,
        })}
      </Text>
      <Text css={modalTextStyle}>
        {t(
          'Channels are where conversations happen around a topic, choose a name that is easy to understand and find.'
        )}
      </Text>
    </>
  );
};

export const textAreaStyle = css({
  margin: theme.spacing(1, 0, 0, 0),
  input: {
    marginLeft: `${theme.spacing(0.5)} !important`,
  },
});

export const modalTextStyle = css({
  color: theme.colors.black,
  marginTop: theme.spacing(1),
});

export const modalButtonStyle = css({ float: 'right', width: 'fit-content' });

const charIndicatorTextStyle = css({ marginTop: theme.spacing(0.5) });
