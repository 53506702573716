export const ScheduleRequestsTrackingIds = {
  requestTabBtn: 'schedule-requests-request-tab-btn',
  multiLocationAccordionItem: 'schedule-requests-multi-location-accordion-item',
  requestListItemAccordion: 'schedule-requests-request-list-item-accordion',
  requestListItemMoreBtn: 'schedule-requests-request-list-item-more-btn',
  requestListItemReviewRequestBtn: 'schedule-requests-request-list-item-review-request-btn',
  requestListItemMessageBtn: 'schedule-requests-request-list-item-message-btn',
  requestListItemDeleteBtn: 'schedule-requests-request-list-item-delete-btn',
  requestListItemRejectBtn: 'schedule-requests-request-list-item-reject-btn',
  deleteConfirmationModalConfirmBtn: 'schedule-requests-delete-confirmation-modal-confirm-btn',
  deleteConfirmationModalCancelBtn: 'schedule-requests-delete-confirmation-modal-cancel-btn',
  rejectConfirmationModalConfirmBtn: 'schedule-requests-reject-confirmation-modal-confirm-btn',
  rejectConfirmationModalCancelBtn: 'schedule-requests-reject-confirmation-modal-cancel-btn',
  linkPatientBtn: 'schedule-requests-link-patient-btn',
  personSelectorAddNewPersonBtn: 'schedule-requests-person-selector-add-new-person-btn',
  personSelectorAddToListBtn: 'schedule-requests-person-selector-add-to-list-btn',
  personSelectorCancelBtn: 'schedule-requests-person-selector-cancel-btn',
  requestFormMessageLinkBtn: 'schedule-requests-request-form-message-link-btn',
  requestApproveBtn: 'schedule-requests-request-approve-btn',
  requestCancelBtn: 'schedule-requests-request-cancel-btn',
  approveConfirmationModalConfirmBtn: 'schedule-requests-approve-confirmation-modal-confirm-btn',
  writebackErrorConfirmationModalConfirmBtn: 'schedule-requests-writeback-error-confirmation-modal-confirm-btn',
  writebackErrorConfirmationModalCancelBtn: 'schedule-requests-writeback-error-confirmation-modal-cancel-btn',
  requestErrorConfirmationModalConfirmBtn: 'schedule-requests-request-error-confirmation-modal-confirm-btn',
  requestErrorConfirmationModalCancelBtn: 'schedule-requests-request-error-confirmation-modal-cancel-btn',
  noWritebacksRequestErrorConfirmationModalConfirmBtn:
    'schedule-requests-no-writebacks-request-error-confirmation-modal-confirm-btn',
  noWritebacksRequestErrorConfirmationModalCancelBtn:
    'schedule-requests-no-writebacks-request-error-confirmation-modal-cancel-btn',
  noWritebacksApproveConfirmationModalConfirmBtn:
    'schedule-requests-no-writebacks-approve-confirmation-modal-confirm-btn',
  viewHistoryBtn: 'schedule-requests-view-history-btn',
};
