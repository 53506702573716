import { NumberType } from '@weave/schema-gen-ts/dist/shared/porting/v1/enums.pb';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Modal, ModalControlModalProps, Text } from '@frontend/design-system';

interface Props extends ModalControlModalProps {
  numberType: NumberType;
}

export const NumberNotPortableInfoModal = ({ numberType, ...modalProps }: Props) => {
  const { t } = useTranslation('porting');

  const isHosting = numberType === NumberType.NUMBER_TYPE_SMS;
  const numberTypeText = numberType === NumberType.NUMBER_TYPE_FAX ? t('Fax') : t('Phone');

  return (
    <Modal {...modalProps} maxWidth={500}>
      <Modal.Header onClose={modalProps.onClose}>
        {t('Number Not {{portableText}}', { portableText: isHosting ? t('Hostable') : t('Portable') })}
      </Modal.Header>
      <Modal.Body>
        <Text css={{ margin: theme.spacing(1, 0) }}>
          {t(
            'The {{numberType}} number you listed for {{portingText}} is not able to port over to Weave. Please reach out to support regarding this {{numberType}} number to see if there is anything we can do on our end to help.',
            {
              numberType: numberTypeText,
              portingText: isHosting ? t('SMS Hosting') : t('Porting'),
            }
          )}
        </Text>
      </Modal.Body>
      <Modal.Footer primary={{ label: t('Close'), onClick: modalProps.onClose }} />
    </Modal>
  );
};
