import { QuickActionsResponse } from '@weave/schema-gen-ts/dist/schemas/quick-actions/qa_messages.pb';

/**
 * This is temporary, until backend fixes and issue where enabled is an array with a single empty string element
 * That happens when the user has no enabled quick actions
 */
export const regulatedEnabledData = (data: QuickActionsResponse | undefined) => {
  if (data?.enabled?.length === 1) {
    return data?.enabled[0] === '' ? [] : data?.enabled;
  }
  return data?.enabled;
};
