import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Button, Text } from '@frontend/design-system';
import { PaginationHookResponse } from './use-pagination-hook';

export const ASSETS_PER_PAGE = 24;

export const PaginationControl = ({
  activePage,
  getNextPage,
  getPreviousPage,
  totalPages,
  trackingId,
}: PaginationHookResponse & { trackingId: string }) => {
  const { t } = useTranslation('messages');

  return (
    <div
      css={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        width: '100%',
        padding: theme.spacing(1),
      }}
    >
      <div css={{ display: 'flex', alignItems: 'center', columnGap: theme.spacing(2) }}>
        <Button
          variant='tertiary'
          iconName='caret-left'
          onClick={() => getPreviousPage()}
          disabled={activePage === 1}
          css={buttonStyling}
          trackingId={trackingId + '-previous-image-page-button'}
        />
        <Text>
          {t('Page {{activePage}} of {{totalPages}}', { activePage, totalPages: totalPages === 0 ? '-' : totalPages })}
        </Text>
        <Button
          variant='tertiary'
          iconName='caret-right'
          onClick={() => getNextPage()}
          disabled={activePage === totalPages}
          css={buttonStyling}
          trackingId={trackingId + '-next-image-page-button'}
        />
      </div>
    </div>
  );
};

const buttonStyling = {
  '&:not([aria-disabled="true"])': {
    svg: {
      color: theme.colors.neutral50,
    },
  },
};
