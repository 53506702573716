import { DataSourcesHooks } from '@frontend/api-data-sources';
import { ListAPI, ListTypes } from '@frontend/api-list';
import { PersonHelpers } from '@frontend/api-person';
import { InfinitePaginatedList } from '@frontend/components';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore, useScopedInfiniteQuery } from '@frontend/scope';
import { Text } from '@frontend/design-system';
import { ContactListRow } from './contact-list-row';

const LIMIT = 25;

export const BirthdaysView = ({
  birthdayFilter,
  clientLocationIds,
}: {
  birthdayFilter: ListTypes.BirthdayFilters;
  clientLocationIds?: string[];
}) => {
  const { t } = useTranslation('contacts');
  const { demoSourceIds } = DataSourcesHooks.useDemoLocationSourceIdsShallowStore('demoSourceIds');

  const listBirthdaysQuery = useScopedInfiniteQuery({
    queryKey: ['list-birthdays', birthdayFilter, ...demoSourceIds, ...(clientLocationIds || [])],
    queryFn: ({ pageParam }) =>
      ListAPI.getBirthdays({
        filterStatus: 'active',
        limit: LIMIT,
        skip: pageParam,
        dataSources: demoSourceIds,
        ...ListTypes.birthdayFiltersMap[birthdayFilter],
        clientLocationIds,
      }),
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.rows.length < LIMIT) return undefined;
      return pages.length * LIMIT;
    },
  });

  const { selectedLocationIds, getLocationName } = useAppScopeStore();
  const { getLocationsByDatasource } = DataSourcesHooks.useDatasourceToLocations(selectedLocationIds, getLocationName);

  return (
    <InfinitePaginatedList
      infiniteQueryProps={listBirthdaysQuery}
      renderListItem={({ listItem }) => (
        <ContactListRow
          listItem={{
            Gender: listItem.gender,
            Birthdate: listItem.birthdate,
            ClientLocationID: listItem.client_location_id,
            ClientLocationName: listItem.client_location_name,
            FirstName: listItem.first_name,
            HomePhone: listItem.phone_home,
            LastName: listItem.last_name,
            MobilePhone: listItem.phone_mobile,
            PersonID: listItem.person_id,
            Status: listItem.status,
            SourceType: undefined,
            Accounts: '',
            Address: {
              City: listItem.address.city,
              State: listItem.address.state,
              Street: listItem.address.state,
              Zip: listItem.address.zip,
            },
            Appointments: [],
            ContactInfo: {},
            Email: '',
            Notes: '',
            OtherContactInfo: '',
            PMDisplayID: '',
            PMID: '',
            Preferences: {},
            PreferredName: '',
            Recall: [],
            SourceID: '',
            SourceName: '',
            WorkPhone: '',
            WeaveHouseholdID: '',
            Department: '',
            ReceiveSMS: true,
          }}
          getLocationsByDatasource={getLocationsByDatasource}
        >
          <Text color='light'>
            {t(`Turning {{newAge}} {{today}}`, {
              newAge: PersonHelpers.getAge({ Birthdate: listItem.birthdate }),
              today: birthdayFilter.replace(/-/g, ' '),
            })}
          </Text>
        </ContactListRow>
      )}
    />
  );
};
