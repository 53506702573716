import { useMemo } from 'react';
import { http } from '@frontend/fetch';
import { useTranslation } from '@frontend/i18n';
import { ApiPayload } from './shared-types';
import { useFetchRoiData } from './use-fetch-roi-data';

type MissedCallTextROI = {
  count: number;
  date: string;
};

type TotalCounts = {
  appointments_count?: number;
  sms_count?: number;
};

type Response = TotalCounts & {
  data?: MissedCallTextROI[];
};

type ApiParams = {
  payload: ApiPayload;
  url: string;
};

type Props = {
  apiParams: ApiParams;
  isEnabled?: boolean;
  totalCountKey: keyof TotalCounts;
};

const api = async ({ payload, url }: ApiParams) => {
  const response = await http.post<{ data: Response }>(url, payload);
  return response.data;
};

export const useFetchMissedCallTextConversionSummary = ({ totalCountKey, ...rest }: Props) => {
  const { t } = useTranslation('analytics');

  const { data, isLoading } = useFetchRoiData<ApiParams, Response>({
    ...rest,
    api,
    errorMessage: t("Couldn't fetch the missed call text conversion summary. Please try again."),
    uniqueId: 'mct-conversion-summary',
  });

  const totalValue = data?.[totalCountKey] || 0;
  const conversionValue = useMemo(() => data?.data?.reduce((sum, { count }) => sum + count, 0) || 0, [data]);

  return {
    conversionValue,
    data: data?.data || [],
    isLoading,
    totalValue,
  };
};
