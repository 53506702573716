import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const chipsContainerStyle = css`
  display: flex;
  flex-wrap: wrap;
  gap: ${theme.spacing(1)};
`;

export const activeLocationChipStyle = css`
  background-color: ${theme.colors.primary5};
  color: ${theme.colors.text.default};
`;
