import { ProviderDetails } from '@weave/schema-gen-ts/dist/schemas/forms-digital/weave_digital_forms.pb';
import { UseMutateAsyncFunction, useMutation, useQueryClient } from 'react-query';
import { FormsQueryKeys, ProviderMapping } from '@frontend/api-forms';
import { useLocationAccordionContext } from '../../../../../src/context';
import { ProviderMappingPayload } from '../types';

export interface UseProviderMappingMutationsResults {
  bulkSaveProviderMapping: UseMutateAsyncFunction<
    {
      success: boolean;
    }[],
    unknown,
    BulkSaveMappingPayload,
    unknown
  >;
  isBulkSavingMapping: boolean;
}

export const useProviderMappingMutations = (): UseProviderMappingMutationsResults => {
  const queryClient = useQueryClient();
  const { locationId } = useLocationAccordionContext();

  const { mutateAsync: bulkSaveProviderMapping, isLoading: isBulkSavingMapping } = useMutation(bulkSaveMapping, {
    onSettled: () => {
      return invalidateMappedData();
    },
  });

  function invalidateMappedData() {
    return queryClient.invalidateQueries([FormsQueryKeys.providerMapping.weaveProviderUser, locationId]);
  }

  return {
    bulkSaveProviderMapping,
    isBulkSavingMapping,
  };
};

interface BulkSaveMappingPayload {
  locationId: string;
  mappingToUpdate: ProviderDetails[];
  mappingToDelete: ProviderDetails[];
}

function bulkSaveMapping({ locationId, mappingToUpdate, mappingToDelete }: BulkSaveMappingPayload) {
  const payloadToUpdate: ProviderMappingPayload[] = [];
  const payloadToDelete: string[] = [];

  for (const { email, name, id, userId } of mappingToUpdate) {
    if (!email || !userId) {
      continue;
    }

    payloadToUpdate.push({
      name: name || '',
      userId: userId,
      email: email,
      id: id,
    });
  }

  for (const { id } of mappingToDelete) {
    if (!id) {
      continue;
    }

    payloadToDelete.push(id);
  }

  const promises: Array<Promise<{ success: boolean }>> = [];

  if (payloadToUpdate.length) {
    promises.push(ProviderMapping.API.bulkProviderMappingUpdate(locationId, payloadToUpdate));
  }

  if (payloadToDelete.length) {
    promises.push(ProviderMapping.API.bulkProviderMappingDelete(locationId, payloadToDelete));
  }

  return Promise.all(promises);
}
