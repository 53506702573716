import { Recipient } from '@weave/schema-gen-ts/dist/schemas/messaging/bulk/v2';
import { useTranslation } from '@frontend/i18n';
import { downloadCSV } from '@frontend/media-helpers';
import { BulkEmailPrefixes } from '@frontend/tracking-prefixes';
import { Button, FileUpload, useAlert } from '@frontend/design-system';
import { Mode } from '../types';
import { UploadStep } from './upload-step';
import { useCsvParse } from './use-csv-parse';

const CSV_EXAMPLE_NAME = 'recipient-list-example';
const CSV_EXAMPLE_CONTENT = `
First Name,Last Name,Email,Preferred Name
John,Doe,john.doe@example.com,John
Tony,Dow,tony.dow@example.com,Homer
Bruce,Nolan,bruce.nolan@example.com,Bruce
Samuel,Zabaglione,samuel.zabaglione@example.com,Sam
`.trim();

type EmailCsvRow = {
  firstname: string;
  lastname: string;
  email: string;
  preferredname?: string;
};

type Props = {
  campaignId: string;
  locationId: string;
  setRecipientsToUpload: (recipients: Recipient[]) => void;
  setMode: (mode: Mode) => void;
};

export const UploadCSVModal = ({ campaignId, locationId, setRecipientsToUpload, setMode }: Props) => {
  const { t } = useTranslation('bulk-messaging');
  const parseCsv = useCsvParse<EmailCsvRow>();
  const alert = useAlert();

  const getErrorMessage = (recipients: Recipient[]) => {
    if (recipients.length === 0) return t('No contacts found in the file.');

    const invalidRecipients = [];
    for (let i = 0; i < recipients.length; i++) {
      const recipient = recipients[i];
      if (!recipient.firstName || !recipient.lastName || !recipient.destination) {
        // Start counting rows at 2 because the first row is the CSV header and the index is 0-based
        invalidRecipients.push(i + 2);
      }
    }

    if (invalidRecipients.length > 0) {
      switch (invalidRecipients.length) {
        case 1:
          return t('There is an issue with row {{rowNumber}}. Please fix the issue and try again.', {
            rowNumber: invalidRecipients[0],
          });

        case 2:
          return t(
            'There are issues with rows {{firstRowNumber}} and {{secondRowNumber}}. Please fix the issues and try again.',
            { firstRowNumber: invalidRecipients[0], secondRowNumber: invalidRecipients[1] }
          );

        case 3:
          return t(
            'There are issues with rows {{firstRowNumber}}, {{secondRowNumber}}, and {{thirdRowNumber}}. Please fix the issues and try again.',
            {
              firstRowNumber: invalidRecipients[0],
              secondRowNumber: invalidRecipients[1],
              thirdRowNumber: invalidRecipients[2],
            }
          );

        case 4:
          return t(
            'There are issues with rows {{firstRowNumber}}, {{secondRowNumber}}, {{thirdRowNumber}}, and 1 more row. Please fix the issues and try again.',
            {
              firstRowNumber: invalidRecipients[0],
              secondRowNumber: invalidRecipients[1],
              thirdRowNumber: invalidRecipients[2],
            }
          );

        default:
          return t(
            'There are issues with rows {{firstRowNumber}}, {{secondRowNumber}}, {{thirdRowNumber}}, and {{additionalRowsCount}} more rows. Please fix the issues and try again.',
            {
              firstRowNumber: invalidRecipients[0],
              secondRowNumber: invalidRecipients[1],
              thirdRowNumber: invalidRecipients[2],
              additionalRowsCount: invalidRecipients.length - 3,
            }
          );
      }
    }

    return '';
  };

  const formatRecipientsData = (data: EmailCsvRow[]): Recipient[] =>
    data.map((row) => ({
      campaignId,
      destination: row.email,
      firstName: row.firstname,
      lastName: row.lastname,
      locationId,
      preferredName: row.preferredname ?? row.firstname,
    }));

  const handleFileUpload = async (files: File[]) => {
    const results = await parseCsv(files);
    if (results) {
      const recipients = formatRecipientsData(results.data);
      const errorMessage = getErrorMessage(recipients);
      if (errorMessage) {
        alert.error(errorMessage);
      } else {
        // change view to the displayed lists and add this to selected lists
        setRecipientsToUpload(recipients);
        setMode('display');
      }
    }
  };

  return (
    <>
      <UploadStep title={t('Step 1:')} instructions={t('Download File.')} details={t('Download the example CSV file.')}>
        <Button
          variant='secondary'
          onClick={() => downloadCSV(CSV_EXAMPLE_CONTENT, CSV_EXAMPLE_NAME)}
          trackingId={`${BulkEmailPrefixes.Audience}-contacts-upload-download-btn`}
          size='large'
        >
          {t('Download Example CSV')}
        </Button>
      </UploadStep>
      <UploadStep
        title={t('Step 2:')}
        instructions={t('Copy & Paste Contacts Into Downloaded File.')}
        details={t('Uploaded lists have no count restrictions.')}
      />
      <UploadStep
        title={t('Step 3:')}
        instructions={t('Upload List Below.')}
        details={t('Unsubscribed email addresses will not receive an email.')}
      >
        <FileUpload
          trackingId={`${BulkEmailPrefixes.Audience}-contacts-csv-file-upload`}
          helperText={t('Drag & drop CSV or select from your computer')}
          multiple={false}
          onFileUpload={handleFileUpload}
          acceptedFileType='csv'
          keepPreviouslySelected={false}
          validateUpload={async (file: File) => {
            const results = await parseCsv([file]);
            if (results) {
              const recipients = formatRecipientsData(results.data);
              return getErrorMessage(recipients);
            }
            return t('Please upload a CSV file.');
          }}
        />
      </UploadStep>
    </>
  );
};
