import { useMemo } from 'react';
import { FormPacketSelectorTypes } from '@frontend/form-packet-selector';
import { ModalControlResponse } from '@frontend/design-system';
import { SendFormsOrigin } from '../../types';
import useDocumentToSendConfig from './useDocumentToSendConfig';
import usePersonConfig from './usePersonConfig';
import useStepsConfig from './useStepsConfig';

export interface CommonProps {
  sendFormsModalControls: ModalControlResponse;
  locationIds: string[];
}

export interface FromFormsContextProps {
  origin: SendFormsOrigin.FORMS;
  document: FormPacketSelectorTypes.FormOrPacketToSend;
}

export interface FromContactsContextProps {
  origin: SendFormsOrigin.CONTACTS;
  person: FormPacketSelectorTypes.MinimalPersonData;
}

export interface FromCompleteContextProps {
  origin: SendFormsOrigin.COMPLETE;
}
export interface FromInvalidContextProps {
  origin: SendFormsOrigin.NONE;
}

export type SpecialProps =
  | FromFormsContextProps
  | FromContactsContextProps
  | FromCompleteContextProps
  | FromInvalidContextProps;
export type UseSendFormsProvidersProps = CommonProps & SpecialProps;
export type UseSendFormsProvidersResults = UseSendFormsProvidersProps;

export const useSendFormsProviders = (props: UseSendFormsProvidersProps): UseSendFormsProvidersResults => {
  const { locationIds, origin, sendFormsModalControls } = props;
  let document: FormPacketSelectorTypes.FormOrPacketToSend | null = null;
  let person: FormPacketSelectorTypes.MinimalPersonData | null = null;

  if (origin === SendFormsOrigin.FORMS) {
    document = props.document;
  }

  if (origin === SendFormsOrigin.CONTACTS) {
    person = props.person;
  }

  useStepsConfig({ origin, locationIds });
  useDocumentToSendConfig({ documentToSend: document });
  usePersonConfig({ person });

  const results = useMemo<UseSendFormsProvidersResults>(() => {
    if (origin === SendFormsOrigin.FORMS) {
      if (!document) {
        throw new Error('Document is required for SendFormsOrigin.FORMS');
      }

      return {
        locationIds,
        origin,
        sendFormsModalControls,
        document,
      };
    }

    if (origin === SendFormsOrigin.CONTACTS) {
      if (!person) {
        throw new Error('Person is required for SendFormsOrigin.CONTACTS');
      }

      return {
        locationIds,
        origin,
        sendFormsModalControls,
        person,
      };
    }

    return {
      locationIds,
      origin,
      sendFormsModalControls,
    };
  }, [locationIds, origin, sendFormsModalControls, document, person]);

  return results;
};
