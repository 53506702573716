import { useEffect } from 'react';
import { ROOT_NODE, SerializedNode, SerializedNodes, useEditor } from '@craftjs/core';
import { nanoid } from 'nanoid';
import { useAlert } from '@frontend/design-system';
import {
  ComposerDocument,
  ComposerDocumentLoader,
  ComposerDocumentSaver,
  ComposerParts,
  ComposerSectionProps,
} from '../types';
import { isComposerSection } from '../utils';

// TODO: Figure out how to programmatically get the name of the FooterSection
const FOOTER_SECTION = 'FooterSection';

// TODO: These load/save functions are tightly coupled to Bulk Email and probably should be pulled out into a separate library
const load: ComposerDocumentLoader = (document, parts) => {
  const serializedNodes =
    document.sections?.reduce((acc, section) => {
      const sectionHandler = parts[section.type];
      if (!sectionHandler)
        throw new Error(
          `Failed to load composer document. A component handler for ${section.type} sections was not specified.`
        );

      if (!isComposerSection(sectionHandler)) return acc;

      const { type: _, ...props } = section;

      return {
        ...acc,
        [nanoid(10)]: {
          type: { resolvedName: section.type },
          isCanvas: false,
          props,
          displayName: section.type,
          parent: ROOT_NODE,
          linkedNodes: {},
          nodes: [],
          hidden: false,
        } as SerializedNode,
      };
    }, {} as SerializedNodes) ?? {};

  const { nodes, linkedNodes } = Object.entries(serializedNodes).reduce(
    (acc, [id, node]) => {
      // linkedNodes should only contain the footer node id, if it exists
      if (typeof node.type === 'object' && node.type.resolvedName === FOOTER_SECTION) {
        return { ...acc, linkedNodes: { footer: id } };
      }
      // nodes should contain all node ids except the footer. Ordered from top to bottom of the email.
      return { ...acc, nodes: [...acc.nodes, id] };
    },
    { nodes: [], linkedNodes: {} } as {
      nodes: string[];
      linkedNodes: { footer?: string };
    }
  );

  const root: SerializedNode = {
    type: { resolvedName: 'Designer' }, // TODO: Figure out how to programmatically get the name of the Designer
    isCanvas: true,
    props: { size: 'desktop' }, // TODO: Load in the current composer's state and intelligently keep props (like the size)
    displayName: 'Designer', // TODO: Figure out how to programmatically get the name of the Designer
    parent: null,
    linkedNodes,
    nodes,
    hidden: false,
  };

  return { [ROOT_NODE]: root, ...serializedNodes };
};

const save: ComposerDocumentSaver = (serializedNodes) => {
  const root = serializedNodes[ROOT_NODE];
  if (!root) throw new Error('Failed to save composer document. The root node was not found.');

  const sections: ComposerSectionProps[] = [];

  const addNodeToSections = (id: string) => {
    const node = serializedNodes[id];

    if (!node)
      throw new Error(
        'Failed to save composer document. The root node references a child node that does not exist in the state. Maybe the state is corrupted?'
      );

    if (typeof node.type === 'string')
      throw new Error(
        'Failed to save composer document. The passed serialized nodes are in an unexpected format. All node types should have a resolvedName.'
      );

    sections.push({ ...node.props, type: node.type.resolvedName });
  };

  // Add the content
  root.nodes.forEach(addNodeToSections);

  // Add the footer
  Object.entries(root.linkedNodes).forEach(([_name, id]) => {
    addNodeToSections(id);
  });

  return { sections } as ComposerDocument;
};

export const useStateManagement = (initialValue: ComposerDocument | undefined, parts: ComposerParts) => {
  const {
    query: { getSerializedNodes },
    actions: { deserialize },
  } = useEditor();

  const alert = useAlert();

  const getValue = () => {
    const serializedNodes = getSerializedNodes();
    try {
      const document = save(serializedNodes);
      return document;
    } catch (error) {
      return '';
    }
  };

  const setValue = (document: ComposerDocument) => {
    try {
      const serializedNodes = load(document, parts);
      deserialize(serializedNodes);
    } catch (error) {
      alert.error('Error loading the page!');
    }
  };
  useEffect(() => {
    if (!initialValue) return;
    setValue(initialValue);
  }, []);

  return { getValue, setValue };
};
