import { css } from '@emotion/react';
import { motion, Reorder, useDragControls } from 'framer-motion';
import { DashboardTrackingIds } from '@frontend/dashboard';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Text, IconButton } from '@frontend/design-system';

type QuickActionItemProps = {
  label: string;
  type?: 'add' | 'remove';
  onClick?: () => void;
  constraintRef?: React.MutableRefObject<HTMLUListElement | null>;
};

const quickActionsTrackingIds = DashboardTrackingIds.featureModule('quick-actions');

export const QuickActionItem = ({ label, type = 'add', onClick }: QuickActionItemProps) => {
  const { t } = useTranslation('quick-actions');

  return (
    <motion.li transition={{ duration: 0.3 }} layoutId={`quick-action-${label}`} css={quickActionItemCommonStyles}>
      <Text color='subdued'>{label}</Text>
      <IconButton
        label={t('Add Quick Action')}
        onClick={onClick}
        trackingId={quickActionsTrackingIds(`${type}-quick-action-item`)}
      >
        <Icon name={type === 'remove' ? 'remove' : 'add'} color={type === 'remove' ? 'error' : 'primary'} />
      </IconButton>
    </motion.li>
  );
};

export const QuickActionReorderItem = ({ label, type = 'add', onClick, constraintRef }: QuickActionItemProps) => {
  const { t } = useTranslation('quick-actions');
  const controls = useDragControls();

  return (
    <Reorder.Item
      whileDrag={{ cursor: 'grabbing', boxShadow: theme.shadows.floating }}
      value={label}
      dragListener={false}
      dragControls={controls}
      dragConstraints={constraintRef}
      dragElastic={0.05}
      transition={{ duration: 0.3 }}
      layoutId={`quick-action-${label}`}
      css={[
        quickActionItemCommonStyles,
        css`
          position: relative;
          user-select: none;
        `,
      ]}
    >
      <Icon
        css={css`
          cursor: grab;
        `}
        name='dragand-drop-handle'
        onPointerDown={(e) => controls.start(e)}
      />
      <Text
        color='subdued'
        css={css`
          margin-right: auto;
          margin-left: ${theme.spacing(2)};
        `}
      >
        {label}
      </Text>
      <IconButton
        label={t('Add Quick Action')}
        trackingId={quickActionsTrackingIds(`${type}-quick-action-item`)}
        onClick={onClick}
      >
        <Icon name={type === 'remove' ? 'remove' : 'add'} color={type === 'remove' ? 'error' : 'primary'} />
      </IconButton>
    </Reorder.Item>
  );
};

const quickActionItemCommonStyles = css`
  display: flex;
  padding: ${theme.spacing(2, 2, 2, 3)};
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${theme.colors.neutral20};
  background-color: ${theme.colors.white};
`;
