import { useCallback, useEffect } from 'react';
import { css } from '@emotion/react';
import { useNavigate } from '@tanstack/react-location';
import { useDropzone } from 'react-dropzone';
import { Page } from '@frontend/components';
import appConfig from '@frontend/env';
import { useFetch } from '@frontend/fetch';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useLocationDataShallowStore } from '@frontend/location-helpers';
import { theme } from '@frontend/theme';
import {
  ContentLoader,
  Modal,
  PrimaryButton,
  Text,
  TextButton,
  useModalControl,
  BannerNotification,
  TextLink,
  useAlert,
} from '@frontend/design-system';
import { acceptedFiles } from '../constants/files-accepted';
import { useFormsPaymentStatus, useFormsUpload } from '../hooks';
import { useOnboardCompany } from '../hooks/use-onboarding-company';
import { formatBytes } from '../shared/utils';
import { DigitalFormsSetupTasks } from '../views/digital-forms-setup-tasks';
import DefaultFileUploader from './file-uploader';
import FormsSubmissionConfirmation from './forms-submission-confirmation';
import { UploadedForm } from './forms.interface';
import UploadedFormsTable from './uploaded-forms';

export interface RejectedFile {
  file: File;
  errorMsg: string;
}

type SortedFiles = { accepted: File[]; rejected: RejectedFile[] };

const DIGITIZATION_HELP_ARTICLE = 'https://www.weavehelp.com/hc/en-us/p/contact-us';

const hrStyles = css`
  margin: ${theme.spacing(2.5, 0, 4)};
`;

const descriptionContainerStyle = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const descriptionTextContainerStyle = css`
  margin-bottom: ${theme.spacing(0.5)};

  p {
    margin-bottom: ${theme.spacing(1)};
  }

  p:last-of-type {
    margin: 0;
  }
`;

const paymentAlertContainerStyle = css`
  display: grid;
  grid-template-columns: repeat(10, 1fr);
`;

const maxFileCount = 20;
const maxFileSizeBytes = 5 * 1024 * 1024; // 5MB

const PlusIcon = () => <Icon name='plus' size={20} />;

/**
 * @deprecated
 */
export const FormsUpload = () => {
  const alert = useAlert();
  const { modalProps, triggerProps, closeModal } = useModalControl();
  const { t } = useTranslation('forms');
  useFetch({ baseUrl: appConfig.BACKEND_API });
  const { locationId } = useLocationDataShallowStore('locationId');
  const navigate = useNavigate();

  const { isLoading: isLoadingOnboarding, isError: isErrorOnboarding } = useOnboardCompany(locationId);

  const { paidForForms, isLoading: isLoadingPaymentStatus } = useFormsPaymentStatus(
    locationId,
    !isLoadingOnboarding && !isErrorOnboarding
  );

  const {
    uploadedForms,
    showDefaultUploader,
    formsSubmissionFinished,
    isLoadingFormsFinishedStatus,
    isLoadingUploadedForms,
    loader,
    setLoader,
    setFormsSubmissionFinished,
    uploadForms,
    deleteForms,
  } = useFormsUpload({ locationId });

  useEffect(() => {
    if (!isErrorOnboarding) {
      return;
    }

    // Redirect to dashboard if onboarding fails
    navigate({ to: '/portal/forms/dashboard' });
  }, [isErrorOnboarding]);

  const isLoadingInitialData = isLoadingFormsFinishedStatus || isLoadingUploadedForms;

  const onFileDrop = useCallback((acceptedFiles: File[]) => {
    if (formsSubmissionFinished) {
      alert.error(t('already submitted'));
      return;
    }

    if (acceptedFiles.length + uploadedForms.length > maxFileCount) {
      alert.error(t('limit exceeded'));
      return;
    }

    const allowedMaxSize = formatBytes(maxFileSizeBytes);

    if (acceptedFiles.length > 0) {
      const sortedFiles = acceptedFiles.reduce(
        (acc, file) => {
          if (file.size <= maxFileSizeBytes) {
            return { ...acc, accepted: [...acc.accepted, file] };
          } else {
            return {
              ...acc,
              rejected: [
                ...acc.rejected,
                {
                  file: file,
                  errorMsg: t(`size of the file {{fileName}} exceeds {{allowedMaxSize}}`, {
                    fileName: file.name,
                    allowedMaxSize: allowedMaxSize,
                  }),
                },
              ],
            };
          }
        },
        { accepted: [], rejected: [] } as SortedFiles
      );

      handleFormUpload(sortedFiles);
    } else {
      alert.warning(t('invalid format'));
    }
  }, []);

  const { getInputProps, open } = useDropzone({
    accept: acceptedFiles,
    noClick: true,
    noKeyboard: true,
    onDrop: onFileDrop,
    preventDropOnDocument: true,
    noDragEventsBubbling: true,
  });

  const handleFormUpload = async (files: SortedFiles) => {
    try {
      if (files.accepted.length) {
        const formData = new FormData();
        files.accepted.forEach((file) => {
          formData.append('files', file);
        });

        setLoader({
          display: true,
          message: t('refreshing'),
        });

        await uploadForms({ form_data: formData, location_id: locationId });

        files.accepted.forEach((file) => {
          alert.success(`${t('submitted success')}: ${file.name}`);
        });
      }

      files.rejected.forEach(({ errorMsg }) => {
        alert.error(errorMsg);
      });
    } catch (error: any) {
      alert.error(t('error submitting'));
    } finally {
      setLoader({
        display: false,
        message: '',
      });
    }
  };

  const onDeleteForm = async (locationId: string, form: UploadedForm) => {
    setLoader({
      display: true,
      message: t('deleting form'),
    });
    try {
      const deleteFormResult = await deleteForms({
        file_ids: [form.id],
        location_id: locationId,
      });
      if (deleteFormResult.success) {
        alert.success(`${t('deleted form success')}: ${form.name}`);
      } else {
        alert.error(`${t('deleted form error')}: ${form.name}`);
      }
    } catch (error: any) {
      alert.error(`${t('deleted form error')}: ${form.name}`);
    }
    setLoader({
      display: false,
      message: '',
    });
  };

  const onUploadForm = () => {
    if (uploadedForms.length < maxFileCount) {
      open();
    } else {
      alert.error(t('limit exceeded'));
    }
  };

  const onModalClose = (formsSubmitted = false) => {
    closeModal();
    formsSubmitted && setFormsSubmissionFinished(true);
  };

  function contactUsHandler() {
    window.open(DIGITIZATION_HELP_ARTICLE, '_blank');
  }

  function getPageContent() {
    if (isLoadingPaymentStatus || isLoadingInitialData) {
      return null;
    }

    if (!paidForForms) {
      return (
        <>
          <BannerNotification
            status='info'
            css={css`
              max-width: 880px;
            `}
            message={
              <>
                <div css={paymentAlertContainerStyle}>
                  <div
                    css={css`
                      grid-column: 1 / 8;
                    `}
                  >
                    {t('PENDING_FORMS_PAYMENT')}
                  </div>
                  <TextLink
                    onClick={contactUsHandler}
                    weight='bold'
                    css={css`
                      grid-column: 9 / 11;
                    `}
                  >
                    {t('CONTACT_US_FOR_DIGITIZATION')}
                  </TextLink>
                </div>
              </>
            }
          />
        </>
      );
    }

    return (
      <>
        <div css={descriptionContainerStyle}>
          <div css={descriptionTextContainerStyle}>
            {!formsSubmissionFinished ? (
              <>
                <Text>
                  {t('form upload note 1')} <span style={{ fontWeight: 600 }}>{t('start digitization')}</span>{' '}
                  {t('form upload note 2')}
                </Text>

                <BannerNotification
                  status={showDefaultUploader ? 'warn' : 'info'}
                  css={css`
                    margin-top: ${theme.spacing(2)};
                    box-shadow: ${theme.shadows.light};
                  `}
                  message={
                    !showDefaultUploader ? (
                      <ul
                        css={css`
                          margin-bottom: 0;
                        `}
                      >
                        <li>{t('forms banner accepted format')} </li>
                        <li>{t('forms banner digitization note')}</li>
                        <li>{t('file contain single form')}</li>
                      </ul>
                    ) : (
                      <>{t('forms banner digitization note')}</>
                    )
                  }
                />
              </>
            ) : (
              <BannerNotification status='success' message={t('form upload thanks note')} fullWidth />
            )}
          </div>

          {uploadedForms.length > 0 && !formsSubmissionFinished && (
            <>
              <input {...getInputProps()} data-trackingid='onb-portal-phoneBillUploadStep-input-phoneBillUpload' />
              <TextButton
                css={css`
                  align-self: flex-end;
                  font-weight: 600;
                `}
                onClick={onUploadForm}
                icon={PlusIcon}
              >
                {t('upload form')}
              </TextButton>
            </>
          )}
        </div>

        {(showDefaultUploader || uploadedForms.length > 0) && !formsSubmissionFinished && <hr css={hrStyles} />}
        {!isLoadingInitialData && (
          <>
            {uploadedForms.length > 0 && (
              <>
                <UploadedFormsTable
                  locationId={locationId}
                  forms={uploadedForms}
                  onDeleteForm={onDeleteForm}
                  formsSubmissionFinished={formsSubmissionFinished}
                />
                {!formsSubmissionFinished && (
                  <div
                    css={css`
                      width: 100%;
                      display: flex;
                      justify-content: flex-end;
                      background-color: ${theme.colors.neutral5};
                      align-items: center;
                      padding: ${theme.spacing(2)};
                    `}
                  >
                    <Text
                      weight='bold'
                      css={css`
                        margin-bottom: 0;
                        margin-right: ${theme.spacing(1)};
                      `}
                    >
                      {t('forms upload complete')}
                    </Text>
                    <PrimaryButton
                      css={css`
                        max-width: 150px;
                      `}
                      {...triggerProps}
                    >
                      {t('start digitization')}
                    </PrimaryButton>
                  </div>
                )}
                <Modal {...modalProps} maxWidth={600}>
                  <FormsSubmissionConfirmation onClose={onModalClose} locationId={locationId} />
                </Modal>
              </>
            )}
            {showDefaultUploader && !formsSubmissionFinished && <DefaultFileUploader onFileDrop={onFileDrop} />}
          </>
        )}
      </>
    );
  }

  return (
    <>
      <DigitalFormsSetupTasks />
      <Page
        title={t('Weave Upload')}
        subtitle={t('Upload your forms below for our team to recreate digitally.')}
        loading={loader.display}
      >
        {getPageContent()}

        <ContentLoader
          show={!!loader.message || isLoadingPaymentStatus || isLoadingInitialData}
          message={loader.message}
          backgroundOpacity={0}
        />
      </Page>
    </>
  );
};
