import { PersonsV3, PersonTypes } from '@frontend/api-person';
import { useTranslation } from '@frontend/i18n';
import { CollectPaymentModalBody, CollectPaymentModalSteps } from '@frontend/payments-collection-flow';
import { MultiStepModal } from '@frontend/payments-multistep-modal';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { ModalControlModalProps } from '@frontend/design-system';
import { CreateInvoice, PreviewInvoice, SelectInvoice } from './modal-steps';
import { PersonInvoiceModalSteps } from './use-person-invoice-payment-modal';

type PaymentModalProps = ModalControlModalProps & {
  personId: string;
  person?: PersonTypes.Person;
  initialStep?: PersonInvoiceModalSteps | CollectPaymentModalSteps;
};

export const PaymentModal = ({ personId, person, initialStep, ...rest }: PaymentModalProps) => {
  const { t } = useTranslation('payments');
  const { selectedLocationIds } = useAppScopeStore();

  const personQuery = PersonsV3.PersonQueries.useGetPersonLegacyQuery(
    { locationIds: selectedLocationIds, personId: personId ?? '' },
    { enabled: !person && !!personId }
  );
  const personName = personQuery.data?.firstName || person?.FirstName || '';

  return (
    <MultiStepModal
      modalProps={rest}
      closeModal={rest.onClose}
      initialStep={initialStep || PersonInvoiceModalSteps.SelectInvoice}
      css={{ maxHeight: 750 }}
      maxWidth={parseInt(theme.spacing(82))}
    >
      <MultiStepModal.Step
        id={PersonInvoiceModalSteps.SelectInvoice}
        title={t(`{{personName}}'s Outstanding payments`, { personName })}
      >
        <SelectInvoice personId={personId} />
      </MultiStepModal.Step>
      <MultiStepModal.Step id={PersonInvoiceModalSteps.PreviewInvoice} title={t('Invoice')}>
        <PreviewInvoice />
      </MultiStepModal.Step>
      <MultiStepModal.Step id={PersonInvoiceModalSteps.CreateInvoice} title={t('Create Invoice')}>
        <CreateInvoice person={personQuery.data!} />
      </MultiStepModal.Step>
      <CollectPaymentModalBody />
    </MultiStepModal>
  );
};
