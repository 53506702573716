import { useEffect, useMemo, useState } from 'react';
import { AnalyticsCommonTypes, PhoneAnalyticsTypes } from '@frontend/api-analytics';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { TableFilters } from '@frontend/design-system';
import { FiltersTray } from '..';
import { useLocations, useTimePeriodDropDownSelector } from '../../hooks';
import { trackingIds } from '../../tracking-ids';
import { appendLocalTimeStamp, formatters } from '../../utils';
import { filtersStyles } from '../../views/common-styles';
import { DatesRangeSelector, HoursSelector, LocationsSelector } from '../filter-selectors';
import { AdditionalPhoneFilters } from './additional-phone-filters';
import { usePhoneAnalyticsShallowStore } from './hooks';

type Props = {
  isLoadingData?: boolean;
  isTableView?: boolean;
};

export const PhoneAnalyticsFilters = ({ isLoadingData, isTableView }: Props) => {
  const { t } = useTranslation('analytics');
  const { getSelectedLocationData, selectedLocationIdsWithParents } = useAppScopeStore();
  const locationId = selectedLocationIdsWithParents[0];
  const locationData = getSelectedLocationData()[locationId];

  const {
    filters,
    hasCustomFilters,
    locationId: phoneAnalyticsLocationId,
    setDefaultFilters,
    setFilterHintText,
    setFilters,
    setLocationId,
    resetStore,
  } = usePhoneAnalyticsShallowStore(
    'filters',
    'hasCustomFilters',
    'locationId',
    'setDefaultFilters',
    'setFilterHintText',
    'setFilters',
    'setLocationId',
    'resetStore'
  );

  const [trayFilters, setTrayFilters] = useState<PhoneAnalyticsTypes.Filters>({});

  const { TimePeriodDropDownSelector, defaultEndDate, defaultStartDate, selectedPeriod, timePeriods } =
    useTimePeriodDropDownSelector({
      values: {
        // Fallback is added to show the correct date range when user navigates between pages after applying custom filters
        startDate: trayFilters.start_date || filters.start_date,
        endDate: trayFilters.end_date || filters.end_date,
      },
    });

  const { isLoading: isLoadingLocations, isSalesforceMultiLocation, isWAMMultiLocation, locations } = useLocations();
  const [errors, setErrors] = useState<Record<string, boolean>>({});

  const defaultFilters: PhoneAnalyticsTypes.Filters = useMemo(
    () => ({
      call_type: [],
      end_date: appendLocalTimeStamp(defaultEndDate),
      location_id: locations ? Object.keys(locations) : [],
      number_search: '',
      open_office: false,
      phone_number: [],
      result: [],
      source_type: [],
      start_date: appendLocalTimeStamp(defaultStartDate),
      time_zone: locationData?.timezone,
    }),
    [defaultEndDate, defaultStartDate, locations]
  );

  const handleUpdateTrayFilters = (newFilters: Partial<PhoneAnalyticsTypes.Filters>) => {
    setTrayFilters((prevFilters) => ({ ...prevFilters, ...newFilters }));
  };

  const applyDefaultFilters = () => {
    // Also used for resetting filters whenever required
    setDefaultFilters(defaultFilters);
    setErrors({});
    setFilters(defaultFilters);
  };

  const applyActiveFiltersToTrayState = () => {
    handleUpdateTrayFilters(filters);
  };

  const handleApplyTrayFilters = () => {
    // This method will be invoked when user clicks on apply button in the tray
    setFilters(trayFilters);
  };

  const handleUpdateErrors = (error: AnalyticsCommonTypes.filterSelectorError) => {
    setErrors((prevErrors) => ({ ...prevErrors, [error.name]: error.value }));
  };

  const hasErrors = useMemo(() => Object.values(errors).some((error) => error), [errors]);

  useEffect(() => {
    // Update local state when main filters has changed
    applyActiveFiltersToTrayState();

    // This text is displayed in sub view pages and charts to indicate the currently applied filters
    setFilterHintText(
      selectedPeriod
        ? timePeriods[selectedPeriod].label
        : `${formatters.date.format(filters.start_date || '')} - ${formatters.date.format(filters.end_date || '')}`
    );
    // Do not add dependency for selectedPeriod here, because selectedPeriod needs to be considered only when user hit the apply button
  }, [filters, timePeriods]);

  useEffect(() => {
    // Avoid resetting when custom filters are already applied (user has navigated from sub page to the main page)
    if (!hasCustomFilters) {
      applyDefaultFilters();
    }
  }, [defaultFilters]);

  useEffect(() => {
    if (!phoneAnalyticsLocationId) {
      setLocationId(locationId);
    } else if (locationId !== phoneAnalyticsLocationId) {
      //  Reset when location is switched
      resetStore();
      setLocationId(locationId);
      applyDefaultFilters();
    }
  }, [locationId, phoneAnalyticsLocationId]);

  return (
    <FiltersTray
      disableApply={hasErrors}
      disableTray={isLoadingData}
      headerLabel={t('Filter Phone Analytics')}
      onApplyFilters={handleApplyTrayFilters}
      onResetFilters={applyDefaultFilters}
      onRevertLocalChanges={applyActiveFiltersToTrayState}
      showFilteredBadge={hasCustomFilters}
      trackingId={trackingIds.phoneAnalytics.filtersOpen}
    >
      {(isSalesforceMultiLocation || isWAMMultiLocation) && (
        <TableFilters.Section sectionHeaderLabel={t('Location')}>
          <div css={filtersStyles.traySection}>
            <LocationsSelector
              disabled={isLoadingData || isLoadingLocations}
              locations={locations}
              onChange={(value) => handleUpdateTrayFilters({ location_id: value })}
              onError={handleUpdateErrors}
              value={trayFilters.location_id}
            />
          </div>
        </TableFilters.Section>
      )}

      <TableFilters.Section sectionHeaderLabel={t('Time')}>
        <div css={filtersStyles.traySection}>
          <TimePeriodDropDownSelector
            disabled={isLoadingData || isLoadingLocations}
            onChange={({ startDate, endDate }) => {
              handleUpdateTrayFilters({
                start_date: startDate,
                end_date: endDate,
              });
            }}
            trackingIdBase={trackingIds.phoneAnalytics.timePeriodOnTrayFilter}
          />
          <DatesRangeSelector
            endDate={trayFilters.end_date}
            onChange={({ endDate, startDate }) =>
              handleUpdateTrayFilters({
                end_date: appendLocalTimeStamp(endDate),
                start_date: appendLocalTimeStamp(startDate),
              })
            }
            startDate={trayFilters.start_date}
          />
        </div>
      </TableFilters.Section>

      {isTableView ? (
        <AdditionalPhoneFilters disabled={isLoadingData} filters={filters} onChange={handleUpdateTrayFilters} />
      ) : (
        <TableFilters.Section sectionHeaderLabel={t('View')}>
          <HoursSelector
            disabled={isLoadingData}
            onChange={(value) => handleUpdateTrayFilters({ open_office: value === 'open-hours' })}
            value={trayFilters.open_office ? 'open-hours' : 'all-hours'}
          />
        </TableFilters.Section>
      )}
    </FiltersTray>
  );
};
