import { useEffect, useState } from 'react';
import { Person } from '@weave/schema-gen-ts/dist/schemas/persons/v3/persons.pb';
import { useQueryClient } from 'react-query';
import { convertInvoiceV3toInvoiceModel, InvoiceModel } from '@frontend/api-invoices';
import { PersonsV3 } from '@frontend/api-person';
import { getTodaysDate } from '@frontend/date';
import { useTranslation } from '@frontend/i18n';
import { useGetInvoiceDetails } from '@frontend/payment-request-details';
import { useQueryPaginatedInvoices } from '@frontend/payments-invoice-controller';
import {
  CreateInvoiceForm,
  DropZoneOverlay,
  shouldShowModal,
  ThresholdModals,
  useCreateInvoiceForm,
  useCreatePaymentRequest,
} from '@frontend/person-invoice-create';
import { pendo } from '@frontend/tracking';
import { Modal, useModalControl, useModalLoadingState, useAlert } from '@frontend/design-system';

type PersonProps = {
  selectedPerson: Person;
  onSelectInvoice: (invoice: InvoiceModel) => void;
  onClose: () => void;
};

const convertToCents = (amount: number) => {
  return Math.round(amount * 100);
};

export const useCreateInvoiceModalContentsProps = ({
  selectedPerson,
  onSelectInvoice,
  onClose,
}: PersonProps): CreateInvoiceModalContentsProps => {
  const { dropZoneProps, resetFields } = useCreateInvoiceForm();
  const [newInvoice, setNewInvoice] = useState<InvoiceModel>();

  const { loading } = useGetInvoiceDetails({
    invoiceId: newInvoice?.id || '',
    personId: selectedPerson?.personId,
    onSuccess: (newInvoice: InvoiceModel) => onCreateComplete(newInvoice),
  });

  const onCreateComplete = (newInvoice: InvoiceModel) => {
    onSelectInvoice(newInvoice);
    resetFields(false);
  };

  const handleOnClose = () => {
    resetFields(false);
    onClose();
  };

  return {
    dropZoneProps,
    asyncModalBodyProps: {
      onClose: handleOnClose,
      setInvoice: setNewInvoice,
      selectedPerson,
      invoiceLoading: loading,
    },
  };
};

type CreateInvoiceModalContentsProps = {
  dropZoneProps: ReturnType<typeof useCreateInvoiceForm>['dropZoneProps'];
  asyncModalBodyProps: AsyncModalProps;
  onCreated?: (invoice: InvoiceModel) => void;
  hideHeader?: boolean;
};

interface AsyncModalProps {
  onClose: () => void;
  onBack?: () => void;
  setInvoice: (invoice: InvoiceModel) => void;
  selectedPerson: Person;
  invoiceLoading: boolean;
  onCreated?: (invoice: InvoiceModel) => void;
  hideHeader?: boolean;
}

export const CreateInvoiceModalContents = ({
  onCreated,
  hideHeader,
  asyncModalBodyProps,
  dropZoneProps,
}: CreateInvoiceModalContentsProps) => {
  const { t } = useTranslation('payments');
  const { setLoading } = useModalLoadingState();
  const {
    createFormProps,
    attachment,
    setSelectedPerson,
    selectedPerson: V2SelectedPerson,
    skipAttachmentAuth,
  } = useCreateInvoiceForm();
  const { onClose, onBack, setInvoice, selectedPerson, invoiceLoading } = asyncModalBodyProps;
  const alerts = useAlert();
  const queryClient = useQueryClient();
  const { invoicesQueryKey } = useQueryPaginatedInvoices();
  const { createPaymentRequest, creatingPaymentRequest } = useCreatePaymentRequest({
    locationId: createFormProps.form.values.locationId,
  });
  const { modalProps: thresholdModalProps, triggerProps: thresholdTriggerProps } = useModalControl();

  const handleCreateInvoice = async () => {
    const { form } = createFormProps;
    setLoading(true);
    try {
      const createInvoice = convertInvoiceV3toInvoiceModel(
        await createPaymentRequest({
          personId: form.values.personId!,
          amount: convertToCents(Number(form.values.amount)),
          attachment,
          mobileNumber: V2SelectedPerson?.MobilePhone,
          memo: form.values.memo,
          providerName: form.values.provider === 'none' ? undefined : form.values.provider,
          skipAttachmentAuth,
        }),
        createFormProps.form.values.locationId
      );
      queryClient.invalidateQueries(invoicesQueryKey);
      await setTimeout(() => setInvoice(createInvoice), 2000);
      onCreated?.(createInvoice);
      pendo.track('payments-create-invoice', {
        date: getTodaysDate('MM/DD/YYYY'),
        amount: Number(form.values.amount),
      });
    } catch (err) {
      alerts.error('Invoice could not be created. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!!selectedPerson) {
      const person = PersonsV3.PersonHelpers.convertPersonV3ToPerson(selectedPerson);
      setSelectedPerson(person);
    }
    return () => {
      setSelectedPerson(null);
    };
  }, [selectedPerson]);

  useEffect(() => {
    setLoading(invoiceLoading);
  }, [invoiceLoading]);

  return (
    <>
      {!hideHeader && <Modal.Header onClose={onClose}>{t('Create Invoice')}</Modal.Header>}
      <Modal.Body>
        <DropZoneOverlay {...dropZoneProps}>
          <CreateInvoiceForm {...createFormProps} />
        </DropZoneOverlay>
      </Modal.Body>
      <Modal.Footer
        primary={{
          disabled: !createFormProps.form.isComplete || creatingPaymentRequest,
          label: t('Create Invoice'),
          onClick: shouldShowModal(createFormProps.form.values.amount)
            ? thresholdTriggerProps.onClick
            : handleCreateInvoice,
        }}
        secondary={{
          label: t('Cancel'),
          onClick: onClose,
        }}
        tertiary={{
          label: !!onBack ? t('Back') : undefined,
          onClick: onBack,
        }}
      />
      <ThresholdModals
        {...thresholdModalProps}
        amount={createFormProps.form.values.amount}
        onConfirm={handleCreateInvoice}
      />
    </>
  );
};
