import { useMemo } from 'react';
import { BASE_URL_ANALYTICS } from '@frontend/api-analytics';
import { http } from '@frontend/fetch';
import { useTranslation } from '@frontend/i18n';
import { ConversionType, ROIFetchParams } from './shared-types';
import { useFetchRoiData } from './use-fetch-roi-data';

type ConversionData = {
  name: string;
  count?: number;
};

type ConversionDetail = {
  conversion_type: ConversionType;
  data?: ConversionData[];
};

type TimelineResponse = {
  conversion_details: ConversionDetail[];
  location_id: string;
};

type UseFetchMissedCallTextAttributionTimelineProps = {
  timeline?: Partial<Record<ConversionType, ConversionData[]>>;
  isLoading?: boolean;
};

type ApiParams = ROIFetchParams & {
  viewType: 'day' | 'week' | 'month' | '7_day';
};

type Props = {
  apiParams: ApiParams;
  isEnabled?: boolean;
};

const api = async ({ attributeType, messagingCategory, payload, viewType }: ApiParams) => {
  const response = await http.post<{ data: TimelineResponse }>(
    `${BASE_URL_ANALYTICS}/roi/v2/${messagingCategory}/timeline/${attributeType}/${viewType}`,
    payload
  );
  return response.data;
};

export const useFetchMissedCallTextAttributionTimeline = (
  props: Props
): UseFetchMissedCallTextAttributionTimelineProps => {
  const { t } = useTranslation('analytics');

  const { data, isLoading } = useFetchRoiData<ApiParams, TimelineResponse>({
    ...props,
    api,
    errorMessage: t("Couldn't fetch the missed call text attribution timeline. Please try again."),
    uniqueId: 'mct-attribution-timeline',
  });

  const timeline: UseFetchMissedCallTextAttributionTimelineProps['timeline'] = useMemo(() => {
    const timelineMap: Partial<Record<ConversionType, ConversionData[]>> = {};

    data?.conversion_details.forEach((item) => {
      timelineMap[item.conversion_type] = item.data;
    });

    return timelineMap;
  }, [data]);

  return {
    isLoading,
    timeline,
  };
};
