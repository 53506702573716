import { ElementType } from 'react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { PolymorphicComponentPropWithoutRef, SearchField, SwitchChipGroup } from '@frontend/design-system';
import { useFormPacketList } from '../../hooks';
import { Category } from '../../types/form-packet-selector.types';
import { searchFieldContainerStyle } from './form-packet-filters.styles';

type FormPacketFiltersProps = Pick<ReturnType<typeof useFormPacketList>, 'searchFieldProps' | 'switchChipGroupProps'>;

type PolymorphicProps<C extends ElementType> = PolymorphicComponentPropWithoutRef<C, FormPacketFiltersProps>;

export const FormPacketFilters = <C extends ElementType = 'div'>({
  as,
  switchChipGroupProps,
  searchFieldProps,
  ...rest
}: PolymorphicProps<C>) => {
  const { t } = useTranslation('form-packet-selector');
  const Component = as || 'div';
  const labelsMap: Record<Category, string> = {
    [Category.FORMS]: t('Forms'),
    [Category.PACKETS]: t('Packets'),
  };

  return (
    <Component
      css={{ display: 'grid', gridTemplateColumns: 'auto 1fr', gap: theme.spacing(1), padding: theme.spacing(0.5) }}
      {...rest}
    >
      <SwitchChipGroup {...switchChipGroupProps}>
        {Object.values(Category).map((value) => (
          <SwitchChipGroup.Option key={value} value={value}>
            {labelsMap[value]}
          </SwitchChipGroup.Option>
        ))}
      </SwitchChipGroup>
      <div css={searchFieldContainerStyle}>
        <SearchField {...searchFieldProps} />
      </div>
    </Component>
  );
};
