import { memo } from 'react';
import { Handle, Position } from '@xyflow/react';
import { theme } from '@frontend/theme';
import { styles, Text } from '@frontend/design-system';
import { DataPayload } from '../data';

export const BooleanNode = memo(({ data }: { data: DataPayload }) => {
  return (
    <div
      style={{
        padding: theme.spacing(2),
        border: `1px solid transparent`,
        borderRadius: theme.borderRadius.medium,
        background: theme.colors.secondary.seaweed70,
        color: theme.colors.white,
      }}
    >
      <Handle type='target' position={Position.Top} id={`${data.id}-top`} isConnectable={true} />
      <div
        style={{
          display: 'grid',
          gridTemplateAreas: '"icon title actions" "icon subtitle actions"',
          gridTemplateColumns: 'auto 1fr',
          columnGap: theme.spacing(1),
          alignItems: 'center',
          height: 40,
          width: 190,
        }}
      >
        <Text color='white' as='span' size='medium' weight='bold' css={[{ gridArea: 'title' }, styles.truncate]}>
          Boolean
        </Text>
        <Text color='white' as='span' size='medium' css={[{ gridArea: 'subtitle' }, styles.truncate]}>
          {data.label}
        </Text>
      </div>

      <Handle type='source' position={Position.Bottom} id={`${data.id}-bottom`} isConnectable={true} />
    </div>
  );
});

BooleanNode.displayName = 'BooleanNode';
