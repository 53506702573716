import { memo, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { Virtuoso } from 'react-virtuoso';
import { theme } from '@frontend/theme';
import { useAlert, SpinningLoader } from '@frontend/design-system';
import { MESSAGES_QUERY_LIMIT, INITIAL_MESSAGE_ITEM_INDEX } from '../../constants';
import { useTeamChatStore } from '../../providers';
import { getPreviousMessages } from '../../utils';
import { NewConversation } from '../common';
import { MessageComposer } from '../composer';
import { EmptyConversation } from './empty-conversation';
import { VirtuosoItem } from './message';

export const ConversationBody = memo(() => {
  const { activeConversation, isNewConversation, streamClient, setActiveConversation, currentUser } = useTeamChatStore([
    'activeConversation',
    'isNewConversation',
    'streamClient',
    'setActiveConversation',
    'currentUser',
  ]);
  const shouldFetchMessages = useRef<boolean>(activeConversation?.messages.length === MESSAGES_QUERY_LIMIT);
  const [isFetching, setIsFetching] = useState(false);
  const [firstItemIndex, setFirstItemIndex] = useState(INITIAL_MESSAGE_ITEM_INDEX);
  const [unreadMessageIndex, setUnreadMessageIndex] = useState<number | null>(null);

  const { error } = useAlert();

  const fetchMessages = async () => {
    try {
      if (activeConversation && streamClient?.user && currentUser && shouldFetchMessages.current) {
        setIsFetching(true);

        const { conversation: newConversation, hasMoreMessages } = await getPreviousMessages({
          channelId: activeConversation.channelId,
          client: streamClient,
          conversation: activeConversation,
          currentUserId: currentUser.userID,
        });
        setActiveConversation(newConversation);
        shouldFetchMessages.current = hasMoreMessages;
        setIsFetching(false);
      }
    } catch (err) {
      error('Failed to fetch old messages');
      setIsFetching(false);
    }
  };

  useEffect(() => {
    if (streamClient && !!activeConversation?.unreadCount) {
      // Mark messages read only if there is an unread count
      const channel = streamClient.getChannelById('team', activeConversation.channelId, {});
      channel.markRead();
    }
  }, [activeConversation?.unreadCount, streamClient, activeConversation?.messages.length]);

  useLayoutEffect(() => {
    if (activeConversation) {
      setFirstItemIndex(INITIAL_MESSAGE_ITEM_INDEX - activeConversation.messages.length);
    }
  }, [activeConversation?.messages.length]);

  return (
    <>
      {isNewConversation && <NewConversation />}
      {activeConversation?.messages.length ? (
        <Virtuoso
          data={activeConversation.messages}
          style={{ height: '100%' }}
          initialTopMostItemIndex={activeConversation.messages.length - activeConversation.unreadCount}
          startReached={fetchMessages}
          firstItemIndex={firstItemIndex}
          itemContent={(index, message) => (
            <VirtuosoItem
              index={index}
              message={message}
              unreadCount={activeConversation.unreadCount}
              messagesLength={activeConversation.messages.length}
              onSetUnreadMessageIndex={setUnreadMessageIndex}
              unreadMessageIndex={unreadMessageIndex}
            />
          )}
          components={{
            Header: ({ context }: { context?: { isFetching: boolean; firstItemIndex: number } }) =>
              context?.isFetching ? (
                <div css={styles.spinner}>
                  <SpinningLoader size='xs' />
                </div>
              ) : null,
          }}
          context={{
            isFetching,
            firstItemIndex,
          }}
          followOutput='auto'
        />
      ) : (
        <EmptyConversation
          memberCount={activeConversation?.members.length || 0}
          conversationType={activeConversation?.type ?? 'DM'}
        />
      )}
      <MessageComposer />
    </>
  );
});

ConversationBody.displayName = 'ConversationBody';

const styles = {
  spinner: css({
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(1),
    width: '100%',
  }),
};
