import { ReactElement } from 'react';
import { css } from '@emotion/react';
import { EmailTemplate } from '@weave/schema-gen-ts/dist/schemas/messaging/bulk/email-template/v1/emailtemplate.pb';
import { VirtuosoGrid } from 'react-virtuoso';
import { useTranslation } from '@frontend/i18n';
import { breakpoints } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';
import { SpinningLoader, Text } from '@frontend/design-system';

type Props = {
  DisplayedCard: (index: number, template: EmailTemplate) => ReactElement;
  EmptyCardState?: ReactElement;
  getNextPage: () => void;
  hasNextPage?: boolean;
  isFetched: boolean;
  isFetchingNextPage: boolean;
  templates: EmailTemplate[];
};

export const ListTemplates = ({
  DisplayedCard,
  EmptyCardState,
  getNextPage,
  hasNextPage,
  isFetched,
  isFetchingNextPage,
  templates,
}: Props) => {
  const { t } = useTranslation('bulk-messaging');

  {
    /* Virtuoso doesn't honor a 100% height for the parent container - needs an explicit height */
  }
  return (
    <div css={{ height: `calc(100vh - ${theme.spacing(29)})`, width: '100%', paddingBottom: theme.spacing(5) }}>
      {templates?.length ? (
        <VirtuosoGrid
          css={css`
            width: 100%;
            .virtuoso-grid-list {
              display: flex;
              flex-wrap: wrap;
              gap: ${theme.spacing(2)};
              .virtuoso-grid-item {
                width: calc(33.33% - ${theme.spacing(1.35)});
                @media (max-width: ${breakpoints.medium.max}px) {
                  width: calc(50% - ${theme.spacing(1.35)});
                }
                @media (max-width: ${breakpoints.small.min}px) {
                  width: 100%;
                }
              }
            }
          `}
          data={templates}
          endReached={getNextPage}
          totalCount={templates.length}
          itemContent={DisplayedCard}
          components={{
            Footer: () => {
              if (isFetchingNextPage) {
                return <SpinningLoader />;
              } else if (isFetched && hasNextPage && !isFetchingNextPage) {
                return (
                  <Text css={{ margin: 0, padding: theme.spacing(6, 0), textAlign: 'center' }}>
                    🎉 {t('You have reached the end')}
                  </Text>
                );
              }
              return null;
            },
          }}
        />
      ) : (
        EmptyCardState
      )}
    </div>
  );
};
