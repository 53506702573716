import { Recipient } from '@weave/schema-gen-ts/dist/schemas/messaging/bulk/v2';
import { List, ListListsRequest } from '@weave/schema-gen-ts/dist/schemas/messaging/bulk/v2/service.pb';
import { Mode } from '../types';
import { SavedListModal } from './saved-list-modal';
import { UploadCSVModal } from './upload-csv-modal';

type Props = {
  campaignId: string;
  getNextPage: () => void;
  hasMore: boolean;
  isFetchingNextPage: boolean;
  lists: List[];
  locationId: string;
  mode: Mode;
  queryKey: (string | ListListsRequest)[];
  recipientsToUpload: Recipient[];
  selectedListIds: string[];
  setLists: (lists: List[]) => void;
  setMode: (mode: Mode) => void;
  setRecipientsToUpload: (recipients: Recipient[]) => void;
  setSelectedListIds: (listIds: string[]) => void;
};

export const SavedListModalContent = ({
  campaignId,
  getNextPage,
  hasMore,
  isFetchingNextPage,
  lists,
  locationId,
  mode,
  queryKey,
  recipientsToUpload,
  selectedListIds,
  setLists,
  setMode,
  setRecipientsToUpload,
  setSelectedListIds,
}: Props) => {
  return mode === 'csv' ? (
    <UploadCSVModal
      campaignId={campaignId}
      locationId={locationId}
      setRecipientsToUpload={setRecipientsToUpload}
      setMode={setMode}
    />
  ) : (
    <SavedListModal
      getNextPage={getNextPage}
      hasMore={hasMore}
      isFetchingNextPage={isFetchingNextPage}
      lists={lists}
      queryKey={queryKey}
      recipientsToUpload={recipientsToUpload}
      selectedListIds={selectedListIds}
      setLists={setLists}
      setMode={setMode}
      setRecipientsToUpload={setRecipientsToUpload}
      setSelectedListIds={setSelectedListIds}
    />
  );
};
