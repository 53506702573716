import { theme } from '@frontend/theme';
import { SpinningLoader } from '@frontend/design-system';

interface Props {
  show: boolean;
}

export const StepLoader = ({ show }: Props) => {
  if (!show) return null;

  return (
    <SpinningLoader size='medium' css={{ position: 'absolute', top: theme.spacing(1), right: theme.spacing(1) }} />
  );
};
