import { useEffect, useState } from 'react';
import {
  Media,
  MediaCollectionName_Enum as MediaCollectionName,
} from '@weave/schema-gen-ts/dist/schemas/messaging/media/public/v1/service.pb';
import { PublicMediaMutations, PublicMediaQueries } from '@frontend/api-public-media';
// import { useOrgData } from '@frontend/get-org-data';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { ContentLoader, useModalControl } from '@frontend/design-system';
import { DeleteImageModal } from './delete-image-modal';
import { Image } from './image';
import { ImageGalleryContentWrapper } from './image-gallery-content-wrapper';
import { ImageGalleryHeader } from './image-gallery-header';
import { ImageUploader } from './image-uploader';
import { LocationAccessModal } from './location-access-modal';
import { ASSETS_PER_PAGE, PaginationControl } from './pagination/pagination-control';
import { usePaginationHook } from './pagination/use-pagination-hook';
import { LocationIdsFieldProps, SearchFieldProps } from './types';

export const YourImageGalleryPanel = ({
  handleImageSelect,
  imageSize,
  locationIds,
  locationIdsFieldProps,
  onDoubleClick,
  searchFieldProps,
  selectedImages,
  trackingId,
}: {
  handleImageSelect: (media?: Media) => void;
  imageSize?: 'small' | 'large';
  locationIds: string[];
  locationIdsFieldProps: LocationIdsFieldProps;
  onDoubleClick?: (media?: Media) => void;
  searchFieldProps: SearchFieldProps;
  selectedImages: (Media | undefined)[];
  trackingId: string;
}) => {
  const { t } = useTranslation('messages');
  const paginationProps = usePaginationHook();

  // const { locationIdsWithoutParent } = useOrgData();

  const { data, isFetched, isLoading, queryKeys } = PublicMediaQueries.useListMedia({
    businessGroupIds: locationIdsFieldProps.value,
    collectionName: MediaCollectionName.IMAGE_GALLERY,
    pageSize: ASSETS_PER_PAGE - 1,
    pageToken: paginationProps.pageToken,
  });

  const images = (data?.pages || [])[0]?.media || [];
  const totalAssets = data?.pages?.[0]?.mediaCount || 0;

  useEffect(() => {
    const token = data?.pages?.[data?.pages.length - 1]?.nextPageToken;
    paginationProps.getNextAsset(token);
  }, [data?.pages.map((page) => page.nextPageToken).join(',')]);

  useEffect(() => {
    if (isFetched && paginationProps.totalPages === 0) {
      const totalPages = Math.ceil(totalAssets / (ASSETS_PER_PAGE - 1));
      paginationProps.setTotalPages(totalPages);
    }
  }, [paginationProps.assets]);

  const { modalProps: locationAccessModalProps, triggerProps: locationAccessTriggerProps } = useModalControl();

  const { modalProps: deleteImageModalProps, triggerProps: deleteImageTriggerProps } = useModalControl();
  const [deleteImageId, setDeleteImageId] = useState<string | undefined>();
  const { mutate: deleteMutation } = PublicMediaMutations.useDeleteMedia(queryKeys);

  return (
    <ImageGalleryContentWrapper>
      <div
        css={{
          height: imageSize === 'large' ? 500 : locationIds.length > 1 ? theme.spacing(49) : theme.spacing(41),
          overflowY: 'auto',
        }}
      >
        <ImageGalleryHeader
          locationIdsFieldProps={{ ...locationIdsFieldProps, name: 'locationIds' }}
          searchFieldProps={{ ...searchFieldProps, name: 'search' }}
          showLocation={locationIdsFieldProps.value.length > 1}
        />
        <div css={{ display: 'flex', flexWrap: 'wrap', gap: theme.spacing(1) }}>
          {isLoading ? (
            <ContentLoader show={isLoading} />
          ) : (
            <>
              <ImageUploader
                imageSize={imageSize}
                onImageUpload={handleImageSelect}
                queryKeys={queryKeys}
                selectedLocationIds={locationIdsFieldProps.value}
              />
              {images.map((image) => (
                <Image
                  key={image.mediaId}
                  // TODO: what permission should they have to modify?
                  canModify
                  image={image}
                  selected={!!selectedImages.find((img) => image.mediaId === img?.mediaId)}
                  onClick={() => {
                    if (selectedImages.find((img) => image.mediaId === img?.mediaId)) {
                      handleImageSelect();
                    } else {
                      handleImageSelect(image);
                    }
                  }}
                  onDoubleClick={() => onDoubleClick?.(image)}
                  popoverActions={[
                    {
                      Icon: () => <Icon name='location' />,
                      label: t('Location Access'),
                      onClick: () => {
                        locationAccessTriggerProps.onClick();
                      },
                      // TODO: switch this once the API is ready
                      hide: true, // locationIdsWithoutParent.length === 1,
                      trackingId: `${trackingId}-location-access-option`,
                    },
                    {
                      Icon: () => <Icon name='trash' color='error' />,
                      label: t('Delete Image'),
                      onClick: () => {
                        deleteImageTriggerProps.onClick();
                        setDeleteImageId(image.mediaId);
                      },
                      css: { span: { color: theme.colors.critical50 } },
                      trackingId: `${trackingId}-delete-image-option`,
                    },
                  ]}
                  size={imageSize}
                />
              ))}
            </>
          )}
        </div>
      </div>
      <PaginationControl {...{ ...paginationProps, trackingId }} />
      <LocationAccessModal
        locationIdsFieldProps={locationIdsFieldProps}
        modalProps={locationAccessModalProps}
        onSave={() => {
          // TODO: connect the location access change once the API is ready
        }}
        trackingId={trackingId}
      />
      <DeleteImageModal
        modalProps={deleteImageModalProps}
        onDelete={async () => {
          if (deleteImageId) {
            await deleteMutation({ collectionName: MediaCollectionName.IMAGE_GALLERY, mediaId: deleteImageId });
            deleteImageModalProps.onClose();
            setDeleteImageId(undefined);
          }
        }}
      />
    </ImageGalleryContentWrapper>
  );
};
