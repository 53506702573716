import { useState } from 'react';
import { useEditor, useNode } from '@craftjs/core';
import { Media } from '@weave/schema-gen-ts/dist/schemas/messaging/media/public/v1/service.pb';
import { PercentCrop } from 'react-image-crop';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Text, useModalControl } from '@frontend/design-system';
import { useComposerSection } from '../../hooks';
import { frameAlignmentStyling } from '../../styles';
import { ComposerSection, LogoSectionProps } from '../../types';
import { getPaddingStyles } from '../../utils/get-padding-styles';
import { LogoOutput } from '../outputs';
import { LogoSettings } from '../settings';
import { LogoTool } from '../tools';
import { BaseComposerSection } from './floating-toolbar';
import { AddImageButton, ImageItem, UploadOrSelectImageModal } from './image-elements';

export const LogoSection: ComposerSection<LogoSectionProps> = ({
  corners,
  crops,
  frameAlignment,
  frameColor,
  height,
  images = [],
  paddingBottom,
  paddingLeft,
  paddingRight,
  paddingTop,
  ...rest
}) => {
  const [selectedIndex, setSelectedIndex] = useState<number | undefined>();
  const { t } = useTranslation('content-composer');

  const { setProps } = useComposerSection<LogoSectionProps>();

  const { id } = useNode((state) => ({
    hasSelectedNode: state.events.selected,
    hasDraggedNode: state.events.dragged,
    id: state.id,
  }));
  const { actions, selected } = useEditor((state, query) => {
    const [currentNodeId] = state.events.selected;
    let selected;

    if (currentNodeId) {
      selected = {
        id: currentNodeId,
        isDeletable: query.node(currentNodeId).isDeletable(),
      };
    }

    return {
      selected,
    };
  });

  const { modalProps, triggerProps } = useModalControl();

  const handleCrop = (crop: PercentCrop) => {
    setSelectedIndex(undefined);
    setProps((props) => {
      if (selectedIndex === undefined) return;
      const newCrops = [...(crops || [])];
      newCrops[selectedIndex] = crop;
      props.crops = newCrops;
    });
  };

  return (
    <BaseComposerSection
      sectionType='Logo'
      title={t('Logo')}
      onDelete={selected?.isDeletable ? () => actions.delete(selected.id) : undefined}
      {...rest}
      id={id}
      css={[
        frameAlignmentStyling(frameAlignment),
        getPaddingStyles({ paddingTop, paddingRight, paddingBottom, paddingLeft }),
        {
          background: frameColor,
        },
      ]}
      onClick={() => {
        setSelectedIndex(undefined);
      }}
    >
      <Text
        as='div'
        weight='bold'
        css={[
          {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
          },
          !images?.[0] && {
            minHeight: 320,
          },
        ]}
      >
        {images?.[0] ? (
          <ImageItem
            image={images[0]}
            corners={corners}
            height={height}
            onRemove={() => setProps((props) => (props.images = []))}
            onReplace={triggerProps.onClick}
            selectedImage={selectedIndex === 0 && selected?.id === id}
            selectImage={() => setSelectedIndex(0)}
            type='logo'
            crop={crops?.[0]}
            onCrop={(crop) => handleCrop(crop)}
          />
        ) : (
          <AddImageButton
            text={t('Add Logo')}
            onClick={() => {
              setSelectedIndex(0);
              triggerProps.onClick();
            }}
          />
        )}
      </Text>
      <UploadOrSelectImageModal
        selectedImages={images}
        selectedIndex={0}
        modalProps={modalProps}
        onSelect={(files: (Media | undefined)[]) => {
          setProps((props) => (props.images = files));
        }}
      />
    </BaseComposerSection>
  );
};

LogoSection.craft = {
  custom: {
    controlOutputRender: true,
  },
  props: {
    corners: 'square',
    frameAlignment: 'center',
    frameColor: theme.colors.neutral5.toUpperCase(),
    height: theme.spacing(8),
    images: [],
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  related: {
    output: LogoOutput,
    settings: LogoSettings,
    tool: LogoTool,
  },
};
