import { Trans, useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Modal, Text, ModalControlModalProps, TextLink } from '@frontend/design-system';
import { trackingIds } from '../../constants';

export enum RequestChangeRestrictedModalType {
  DateChange = 'date-change',
  CancelRequest = 'cancel-request',
}

interface Props extends ModalControlModalProps {
  modalType: RequestChangeRestrictedModalType;
}

export const RequestChangeRestrictedModal = ({ modalType, ...modalProps }: Props) => {
  const { t } = useTranslation('porting', { keyPrefix: 'phoneNumbers' });

  const title =
    modalType === RequestChangeRestrictedModalType.DateChange
      ? t('Date Change Not Permitted')
      : t('Cancel Request Not Permitted');
  const message =
    modalType === RequestChangeRestrictedModalType.DateChange
      ? t('You can not change the port date within a week of the scheduled port date.')
      : t('Edits to your port request need to be made 3 days before your scheduled port date.');
  return (
    <Modal maxWidth={600} {...modalProps}>
      <Modal.Header closeBtnTrackingId={trackingIds.closeRequestModal} onClose={modalProps.onClose}>
        {title}
      </Modal.Header>
      <Modal.Body>
        <Text css={{ margin: theme.spacing(2, 0) }}>
          <Trans t={t}>
            {{ message }} Please contact{' '}
            <TextLink rel='noreferrer' target='_blank' href='https://www.weavehelp.com/hc/en-us/p/contact-us'>
              Weave Support
            </TextLink>{' '}
            to make any changes.
          </Trans>
        </Text>
      </Modal.Body>
      <Modal.Footer
        primary={{ label: t('Okay'), onClick: modalProps.onClose, trackingId: trackingIds.closeRequestModal }}
      />
    </Modal>
  );
};
