import { useTranslation } from '@frontend/i18n';
import { useContentComposer } from '../content-composer-provider';
import { CustomLinkButtonSection } from '../sections';
import { ToolboxButton } from '../sidebar';

export const CustomLinkButtonTool = () => {
  const { t } = useTranslation('content-composer');
  const { insertSection } = useContentComposer();

  return (
    <ToolboxButton
      icon='link-with-border'
      title={t('Custom Review Button')}
      subtitle={t('Link to a custom URL')}
      onClick={() => insertSection(<CustomLinkButtonSection />)}
      showBetaLabel
    >
      <CustomLinkButtonSection />
    </ToolboxButton>
  );
};
