import { useEffect } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Tabs } from '@frontend/design-system';
import { PhoneNumbersTable, PortRequestsTable } from '../components';
import { PhoneNumbersPageTab, trackingIds } from '../constants';
import { usePhoneNumbersPageShallowStore } from '../hooks';

export const PhoneNumbersAndPortingPage = () => {
  const { t } = useTranslation('porting', { keyPrefix: 'phoneNumbers' });
  const { selectedTab, setSelectedTab } = usePhoneNumbersPageShallowStore('selectedTab', 'setSelectedTab');

  useEffect(() => {
    if (!selectedTab) setSelectedTab(PhoneNumbersPageTab.PhoneNumbers);
  }, []);

  return (
    <section css={containerStyle}>
      <Tabs
        initialTab={selectedTab ?? PhoneNumbersPageTab.PhoneNumbers}
        onChange={(id) => setSelectedTab(id as PhoneNumbersPageTab)}
      >
        <Tabs.Bar css={{ padding: theme.spacing(0, 2) }}>
          <Tabs.Tab
            id={PhoneNumbersPageTab.PhoneNumbers}
            controls={`${PhoneNumbersPageTab.PhoneNumbers}-panel`}
            trackingId={trackingIds.phoneNumbersTab}
          >
            {t('Phone Numbers')}
          </Tabs.Tab>
          <Tabs.Tab
            id={PhoneNumbersPageTab.PortRequests}
            controls={`${PhoneNumbersPageTab.PortRequests}-panel`}
            trackingId={trackingIds.portRequestsTab}
          >
            {t('Port Requests')}
          </Tabs.Tab>
        </Tabs.Bar>
        {selectedTab === PhoneNumbersPageTab.PhoneNumbers ? <PhoneNumbersTable /> : <PortRequestsTable />}
      </Tabs>
    </section>
  );
};

const containerStyle = css({
  minHeight: 300,
  position: 'relative',
  boxShadow: theme.shadows.light,
  background: theme.colors.white,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
});
