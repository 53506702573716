export const ScheduleMassMessageTrackingIds = {
  contactPatientsBtn: 'schedule-mass-message-contact-patients-btn',
  datePickerField: 'schedule-mass-message-date-picker-field',
  appointmentStatusFilterField: 'schedule-mass-message-appointment-status-filter-field',
  messageListAccordion: 'schedule-mass-message-message-list-accordion',
  phoneListAccordion: 'schedule-mass-message-phone-list-accordion',
  backBtn: 'schedule-mass-message-back-btn',
  cancelBtn: 'schedule-mass-message-cancel-btn',
  nextBtn: 'schedule-mass-message-next-btn',
  closeModalBtn: 'schedule-mass-message-close-modal-btn',
  selectAllMessageListCheckbox: 'schedule-mass-message-select-all-message-list-checkbox',
  textAreaTemplateBtn: 'schedule-mass-message-text-area-template-btn',
  textAreaEmojiBtn: 'schedule-mass-message-text-area-emoji-btn',
  textAreaImageBtn: 'schedule-mass-message-text-area-image-btn',
  sendMassMessageBtn: 'schedule-mass-message-send-mass-message-btn',
  sendMassMessageModalCancelBtn: 'schedule-mass-message-send-mass-message-modal-cancel-btn',
  sendMassMessageModalBackBtn: 'schedule-mass-message-send-mass-message-modal-back-btn',
  sendMassMessageModalCloseBtn: 'schedule-mass-message-send-mass-message-modal-close-btn',
};

export const ScheduleMassMessageFilterTrackingIds = {
  openFiltersButton: 'schedule-mass-message-filter-button',
  clearProviderFilterButton: 'schedule-mass-message-clear-provider-filter-button',
  selectAllProvidersFilterButton: 'schedule-mass-message-select-all-providers-filter-button',
  clearInsuranceStatusesFilterButton: 'schedule-mass-message-clear-insurance-statuses-filter-button',
  selectAllInsuranceStatusesFilterButton: 'schedule-mass-message-select-all-insurance-statuses-filter-button',
};
