import {
  ScheduleRequest,
  ScheduleRequestCountResponse,
} from '@weave/schema-gen-ts/dist/schemas/schedule/api/v2/api.pb';
import { ReviewedStatus } from '@weave/schema-gen-ts/dist/schemas/schedule/v3/booking_submission.pb';
import { UseMutationOptions, UseQueryOptions, useInfiniteQuery, useQueryClient } from 'react-query';
import { Options } from '@frontend/fetch';
import { useLocalizedQuery } from '@frontend/location-helpers';
import { useMutation, useQuery } from '@frontend/react-query-helpers';
import { SchedulerServiceV3 } from '@frontend/schema';
import { useAppScopeStore } from '@frontend/scope';
import {
  createAppointmentType,
  deleteAppointmentTypes,
  getAppointmentTypes,
  getAppointmentTypesForMultiLocation,
  updateAppointmentType,
  listScheduleEntries,
  createSchedulerEntries,
  updateSchedulerEntries,
  getCalendarEvent,
  createCalendarEvent,
  updateCalendarEvent,
  deleteCalendarEvents,
  updateOperatory,
  getOperatory,
  getFeatureFlagStatus,
  getListCalendarAvailabilities,
  appointmentWriteback,
  appointmentStatusWriteback,
  listSourceTenants,
  updateCalendarEventStatus,
  personWriteback,
  listScheduleRequests,
} from './api';
import { convertScheduleRequestV3ToV2 } from './helpers';
import {
  AppointmentTypesQueryKeys,
  CalendarEventsQueryKeys,
  OperatoriesQueryKeys,
  ScheduleEntriesQueryKeys,
  scheduleBookingSiteQueryKeys,
  scheduleRequestQueryKeys,
} from './query-keys';
import {
  DeleteScheduleRequestApiType,
  GetCalendarEventApiType,
  GetFeatureFlagStatusApiType,
  GetOperatoryApiType,
  GetScheduleRequestApiType,
  ListAppointmentTypesApiType,
  ListAppointmentTypesMultiLocationApiType,
  ListCalendarAvailabilitiesApiType,
  ListScheduleEntriesApiType,
  PersonWriteBackApiType,
  UpdateCalendarEventStatusApiType,
  AppointmentStatusWriteBackApiType,
  AppointmentWriteBackApiType,
  CreateCalendarEventApiType,
  UpdateScheduleRequestApiType,
  ListScheduleRequestsApiType,
} from './types';

const CACHE_AND_STALE_TIME_FOR_APPOINTMENT_OPENINGS = 1000 * 60 * 3; // 3 minutes

export const useGetAppointmentTypes = (opts?: UseQueryOptions<ListAppointmentTypesApiType['output']>) => {
  const { selectedLocationIds } = useAppScopeStore();
  return useQuery({
    queryKey: AppointmentTypesQueryKeys.getAppointmentTypes(selectedLocationIds[0]),
    queryFn: () => getAppointmentTypes({ locationId: selectedLocationIds[0] }),
    ...opts,
  });
};

export const useUpdateAppointmentType = (currentPage = 1, currentLimit = 10) => {
  const { selectedLocationIds } = useAppScopeStore();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateAppointmentType,
    onSuccess: () => {
      queryClient.invalidateQueries(
        AppointmentTypesQueryKeys.getAppointmentTypes(
          selectedLocationIds[0],
          currentPage.toString(),
          currentLimit.toString()
        )
      );
    },
    onError: () => {
      console.error('Failed to update appointment type');
    },
  });
};

export const useCreateAppointmentType = () => {
  const { selectedLocationIds } = useAppScopeStore();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createAppointmentType,
    onSuccess: () => {
      queryClient.invalidateQueries(AppointmentTypesQueryKeys.getAppointmentTypes(selectedLocationIds[0]));
    },
    onError: () => {
      console.error('Failed to create appointment type');
    },
  });
};

export const useDeleteAppointmentTypes = (currentPage = 1, currentLimit = 10) => {
  const { selectedLocationIds } = useAppScopeStore();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteAppointmentTypes,
    onSuccess: () => {
      queryClient.invalidateQueries(
        AppointmentTypesQueryKeys.getAppointmentTypes(
          selectedLocationIds[0],
          currentPage.toString(),
          currentLimit.toString()
        )
      );
    },
    onError: () => {
      console.error('Failed to delete appointment type');
    },
  });
};

export const useArchiveAppointmentType = (currentPage = 1, currentLimit = 10) => {
  const { selectedLocationIds } = useAppScopeStore();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateAppointmentType,
    onSuccess: () => {
      queryClient.invalidateQueries(
        AppointmentTypesQueryKeys.getAppointmentTypes(
          selectedLocationIds[0],
          currentPage.toString(),
          currentLimit.toString()
        )
      );
    },
    onError: () => {
      console.error('Failed to archive appointment type');
    },
  });
};

export const useGetAppointmentTypesForMultiLocation = (
  opts?: UseQueryOptions<ListAppointmentTypesMultiLocationApiType['output']>
) => {
  const { selectedLocationIds, selectedParentsIds } = useAppScopeStore();
  return useQuery({
    queryKey: AppointmentTypesQueryKeys.getAppointmentTypesMultiLocation(selectedLocationIds, selectedParentsIds[0]),
    queryFn: () =>
      getAppointmentTypesForMultiLocation({
        locationsIds: selectedLocationIds,
        parentLocationId: selectedParentsIds[0],
      }),
    ...opts,
  });
};

type UseListScheduleEntriesQueryParams = {
  locationId: string;
  entityIds: string[];
  opts?: UseQueryOptions<ListScheduleEntriesApiType['output']>;
};
export const useListScheduleEntries = ({ locationId, entityIds, opts }: UseListScheduleEntriesQueryParams) => {
  return useQuery({
    queryKey: ScheduleEntriesQueryKeys.getScheduleEntriesList(locationId, entityIds),
    queryFn: () => listScheduleEntries({ locationId, ids: entityIds }),
    ...opts,
  });
};

export const useCreateScheduleEntries = (locationId: string, entityIds?: string[]) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createSchedulerEntries,
    onSuccess: () => {
      queryClient.invalidateQueries(ScheduleEntriesQueryKeys.getScheduleEntriesList(locationId, entityIds || []));
    },
  });
};

export const useUpdateScheduleEntries = (locationId: string, entityIds: string[]) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateSchedulerEntries,
    onSuccess: () => {
      queryClient.invalidateQueries(ScheduleEntriesQueryKeys.getScheduleEntriesList(locationId, entityIds));
    },
  });
};

// Calendar Events
export const useGetCalendarEvent = (eventId: string, opts?: UseQueryOptions<GetCalendarEventApiType['output']>) => {
  return useLocalizedQuery({
    queryFn: () => getCalendarEvent({ eventId }),
    queryKey: CalendarEventsQueryKeys.getCalendarEvent(eventId),
    ...opts,
  });
};

export const useCreateCalendarEvent = () => {
  return useMutation({
    mutationFn: (payload: CreateCalendarEventApiType['input']) => createCalendarEvent(payload),
    onError(error) {
      console.error('an error occurred while creating calendar event', error);
    },
  });
};

export const useUpdateCalendarEvent = () => {
  return useMutation({
    mutationFn: updateCalendarEvent,
  });
};

export const useDeleteCalendarEvent = () => {
  return useMutation({
    mutationFn: deleteCalendarEvents,
  });
};

export const useUpdateOperatory = () => {
  return useMutation({
    mutationFn: updateOperatory,
  });
};

export const useGetOperatoryByOperatorId = (
  operatoryId: string,
  opts?: UseQueryOptions<GetOperatoryApiType['output']>
) => {
  return useLocalizedQuery({
    queryFn: () => getOperatory({ operatoryId }),
    queryKey: OperatoriesQueryKeys.getOperatoryByOperatoryId(operatoryId),
    ...opts,
  });
};

export const useUpdateCalendarEventStatus = () => {
  return useMutation({
    mutationFn: (req: UpdateCalendarEventStatusApiType['input']) => updateCalendarEventStatus(req),
    onError(error) {
      console.error('an error occurred while adding updating status', error);
    },
  });
};

export const useAppointmentWriteback = () => {
  return useMutation({
    mutationFn: (req: AppointmentWriteBackApiType['input']) => appointmentWriteback(req),
    onError(error) {
      console.error('an error occurred while adding appointment writeback', error);
    },
  });
};

export const useAppointmentStatusWriteback = () => {
  return useMutation({
    mutationFn: (req: AppointmentStatusWriteBackApiType['input']) => appointmentStatusWriteback(req),
    onError(error) {
      console.error('an error occurred while adding appointment status writeback', error);
    },
  });
};

export const usePersonWriteback = () => {
  return useMutation({
    mutationFn: (req: PersonWriteBackApiType['input']) => personWriteback(req),
    onError(error) {
      console.error('an error occurred during person writeback', error);
    },
  });
};

type UseListSourceTenantsArgs = {
  locationId: string;
  isEnabled?: boolean;
};

export const useListSourceTenants = ({ locationId, isEnabled = false }: UseListSourceTenantsArgs) => {
  return useQuery({
    queryKey: [locationId, 'source-tenants'],
    queryFn: () => listSourceTenants({ locationId }),
    enabled: !!locationId && isEnabled,
  });
};

export const useGetFeatureFlagStatus = (req: GetFeatureFlagStatusApiType['input'], options?: Options) => {
  return useQuery({
    queryKey: scheduleBookingSiteQueryKeys.featureFlagStatus(req),
    queryFn: () => getFeatureFlagStatus(req, options),
    enabled: !!req.locationId && !!req.featureFlagNames.length,
    retry: 1,
  });
};

export const useListCalendarAvailabilities = (
  req: ListCalendarAvailabilitiesApiType['input'],
  queryOptions?: UseQueryOptions<ListCalendarAvailabilitiesApiType['output']>,
  httpOptions?: Options
) => {
  return useQuery({
    queryKey: scheduleBookingSiteQueryKeys.calendarAvailabilities(req),
    queryFn: () => getListCalendarAvailabilities(req, httpOptions),
    retry: 1,
    cacheTime: CACHE_AND_STALE_TIME_FOR_APPOINTMENT_OPENINGS,
    staleTime: CACHE_AND_STALE_TIME_FOR_APPOINTMENT_OPENINGS,
    ...queryOptions,
    enabled: !!req.calendarId && !!req.startDateTime && !!req.endDateTime && (queryOptions?.enabled ?? true),
  });
};

export const useListScheduleRequestsInfiniteQuery = (
  request: Omit<ListScheduleRequestsApiType['input'], 'page'>,
  queryOptions?: { enabled?: boolean }
) => {
  return useInfiniteQuery({
    queryKey: scheduleRequestQueryKeys.infiniteList(request),
    queryFn: async ({ pageParam = 1 }) => {
      const req = { ...request, page: pageParam };
      const res = await listScheduleRequests(req);

      return {
        rows: (res.bookingSubmissions ?? []).map<ScheduleRequest>(convertScheduleRequestV3ToV2),
        totalCount: res.totalCount ?? 0,
        page: req.page,
        totalPages: Math.ceil((res.totalCount ?? 0) / (req.limit ?? 1)),
      };
    },
    getNextPageParam: (lastPage) => (lastPage.page < lastPage.totalPages ? lastPage.page + 1 : undefined),
    getPreviousPageParam: (firstPage) => (firstPage.page > 1 ? firstPage.page - 1 : undefined),
    enabled: request.locationsIds.length > 0 && (queryOptions?.enabled ?? true),
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });
};

export const useListScheduleRequestsQuery = (
  request: ListScheduleRequestsApiType['input'],
  queryOptions?: { enabled?: boolean }
) => {
  return useQuery({
    queryKey: scheduleRequestQueryKeys.list(request),
    queryFn: () => listScheduleRequests(request),
    enabled: !!request.locationsIds.length && (queryOptions?.enabled ?? true),
  });
};

export const usePendingScheduleRequestCountQuery = (
  locationIds: string[],
  queryOptions?: UseQueryOptions<ScheduleRequestCountResponse>
) => {
  return useQuery({
    queryKey: scheduleRequestQueryKeys.pendingRequestCount(locationIds),
    queryFn: async () => {
      const res = await Promise.all(
        locationIds.map(async (locationId) => {
          const response = await listScheduleRequests({
            locationsIds: [locationId],
            reviewedStatuses: [ReviewedStatus.PENDING],
            limit: 1,
          });
          return {
            locationId,
            count: +(response.totalCount ?? 0),
          };
        })
      );

      // parsing to v2 api response
      const response: ScheduleRequestCountResponse = {
        total: res.reduce((acc, r) => acc + r.count, 0),
        locationScheduleRequestCounts: res.map((r) => ({
          locationId: r.locationId,
          total: r.count,
        })),
      };

      return response;
    },
    ...queryOptions,
    enabled: locationIds.length > 0 && (queryOptions?.enabled ?? true),
  });
};

export const useGetScheduleRequest = (
  req: GetScheduleRequestApiType['input'],
  queryOption?: UseQueryOptions<ScheduleRequest | undefined>
) => {
  return useQuery({
    queryKey: scheduleRequestQueryKeys.scheduleRequest(req.submissionId),
    queryFn: async () => {
      const res = await SchedulerServiceV3.GetBookingSubmission(req);
      return res.bookingSubmission ? convertScheduleRequestV3ToV2(res.bookingSubmission) : undefined;
    },
    ...queryOption,
    enabled: !!req.submissionId && (queryOption?.enabled ?? true),
  });
};

export const useDeleteScheduleRequest = () => {
  return useMutation({
    mutationFn: (req: DeleteScheduleRequestApiType['input']) => SchedulerServiceV3.DeleteBookingSubmission(req),
    onError(error) {
      console.error('an error occurred while deleting schedule request', error);
    },
  });
};

export const useUpdateScheduleRequest = (
  mutationOptions?: UseMutationOptions<
    UpdateScheduleRequestApiType['output'],
    unknown,
    UpdateScheduleRequestApiType['input'],
    unknown
  >
) =>
  useMutation({
    mutationFn: SchedulerServiceV3.UpdateBookingSubmission,
    onError(error) {
      console.error('an error occurred while updating schedule request', error);
    },
    ...mutationOptions,
  });
