import { useMutation } from 'react-query';
import {
  InvoiceModelV3,
  PaymentRequestPayload,
  createPaymentRequest as createPaymentRequestApi,
} from '@frontend/api-invoices';
import { useTranslation } from '@frontend/i18n';
import { paymentsQueryKeys, useMerchant } from '@frontend/payments-hooks';
import { useAlert } from '@frontend/design-system';

interface UseCreatePaymentRequestParams {
  locationId: string | undefined;
}

export const useCreatePaymentRequest = ({ locationId }: UseCreatePaymentRequestParams) => {
  const { t } = useTranslation('payments');
  const alerts = useAlert();
  const { merchantsData } = useMerchant({ allLocations: true });
  const { paymentsUrl } = merchantsData[locationId ?? ''] ?? {};

  const { error, isLoading, mutateAsync } = useMutation<InvoiceModelV3, unknown, PaymentRequestPayload>({
    mutationKey: [locationId, paymentsQueryKeys.createPaymentRequest],
    mutationFn: ({ personId, amount, attachment, skipAttachmentAuth, providerName, memo }) => {
      const formData = new FormData();
      formData.append(
        'invoice-json',
        JSON.stringify({
          amount,
          personId,
          skipAttachmentAuth,
          providerName,
          memo,
        })
      );

      if (attachment) {
        formData.append('invoice-attachment', attachment);
      }
      return createPaymentRequestApi(paymentsUrl!, formData, locationId!);
    },
    onError: () => {
      alerts.error(t("Couldn't create invoice."));
    },
  });

  const createPaymentRequest = async (options: PaymentRequestPayload) => {
    if (!locationId) alerts.error(t('Please select a location'));
    if (!paymentsUrl) console.error('paymentsUrl is empty');
    return await mutateAsync(options);
  };

  return {
    createPaymentRequest,
    paymentRequestError: error ? t('Error creating payment request') : undefined,
    creatingPaymentRequest: isLoading,
  };
};
