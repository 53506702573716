import { useTranslation } from '@frontend/i18n';
import { BulkEmailPrefixes } from '@frontend/tracking-prefixes';
import { useContentComposer } from '../content-composer-provider';
import { ImageSection } from '../sections';
import { ToolboxButton } from '../sidebar';

export const ImageTool = () => {
  const { t } = useTranslation('content-composer');
  const { insertSection } = useContentComposer();

  return (
    <ToolboxButton
      icon='image'
      title={t('Image')}
      subtitle={t('Insert an image.')}
      onClick={() => insertSection(<ImageSection />)}
      trackingId={`${BulkEmailPrefixes.Composer}-image-tool-btn`}
    >
      <ImageSection />
    </ToolboxButton>
  );
};
