import { Trans, useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';
import { formatters } from '../../../utils';
import { useROIShallowStore } from '../hooks';
import { getDateRangeText } from '../utils/date-range-text';

interface MessagesSentDescriptionProps {
  achievedValue: number | string;
  totalValue: number | string;
}

export const MessagesSentDescription = ({ achievedValue, totalValue }: MessagesSentDescriptionProps) => {
  const { t } = useTranslation('analytics');
  const { filters } = useROIShallowStore('filters');

  return (
    <div className='description'>
      <Text
        css={{
          fontSize: theme.fontSize(96),
          lineHeight: 1,
        }}
        weight='bold'
      >
        {achievedValue}
      </Text>
      <Text>{t('Appointments Scheduled')}</Text>
      <Text style={{ marginTop: theme.spacing(4) }}>
        <Trans t={t}>
          <Text as='span' color='subdued' size='medium'>
            {getDateRangeText(filters?.startDate, filters?.endDate, true)},
          </Text>{' '}
          <Text as='strong' size='medium' weight='bold'>
            {totalValue}
          </Text>{' '}
          <Text as='span' color='subdued' size='medium'>
            Missed Call Texts were sent which led to
          </Text>{' '}
          <Text as='strong' size='medium' weight='bold'>
            {achievedValue}
          </Text>{' '}
          <Text as='span' color='subdued' size='medium'>
            appointments being scheduled
          </Text>{' '}
          {(filters?.locationIds?.length || 0) > 1 ? (
            <>
              <Text as='span' color='subdued' size='medium'>
                across
              </Text>{' '}
              <Text as='strong' size='medium' weight='bold'>
                {formatters.value.format(filters?.locationIds?.length)}
              </Text>{' '}
              <Text as='span' color='subdued' size='medium'>
                locations.
              </Text>{' '}
            </>
          ) : (
            <Text as='span' color='subdued' size='medium'>
              later.
            </Text>
          )}
        </Trans>
      </Text>
    </div>
  );
};
