import { useMemo } from 'react';
import { css } from '@emotion/react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { InvoiceModel } from '@frontend/api-invoices';
import { formatCentsToCurrency } from '@frontend/currency';
import { useTranslation } from '@frontend/i18n';
import { Icon, IconProps } from '@frontend/icons';
import { theme } from '@frontend/theme';
import {
  Button,
  Chip,
  ChipProps,
  formatDate,
  PopoverMenu,
  PopoverMenuItem,
  useAlert,
  usePopoverMenu,
} from '@frontend/design-system';

const getFormattedDate = (date: string) => {
  const currentYear = new Date().getFullYear();
  const d = new Date(date);

  if (d.getFullYear() === currentYear) {
    return formatDate(date, 'MMMM D', true);
  }
  return formatDate(date, 'MMM D, YYYY', true);
};

export type InvoiceCardProps = React.PropsWithChildren<{
  invoice: InvoiceModel;
  className?: string;
}>;

const styles = {
  card: css`
    box-shadow: ${theme.shadows.light};
    border-radius: ${theme.borderRadius.medium};
    padding: ${theme.spacing(2)};
    background: ${theme.colors.white};
  `,
  header: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: ${theme.spacing(2)};
    margin-bottom: ${theme.spacing(2)};
  `,
  date: css`
    font-size: ${theme.font.size.large};
    font-weight: ${theme.font.weight.bold};
  `,
  infoBlocks: css`
    display: flex;
    gap: ${theme.spacing(2)};
    margin-bottom: ${theme.spacing(1)};
  `,
  infoBlock: css`
    min-width: 70px;

    label {
      font-size: ${theme.font.size.small};
      color: ${theme.colors.text.subdued};
      font-weight: ${theme.font.weight.regular};
    }
    div {
      font-weight: ${theme.font.weight.bold};
      font-size: ${theme.font.size.medium};
    }
  `,
  actions: css`
    display: flex;
    gap: ${theme.spacing(1)};
  `,
  actionFullButton: css`
    flex-grow: 1;
    button {
      width: 100%;
    }
  `,
  actionMiniButton: css`
    flex-grow: 0;
    button {
      width: auto;
    }
  `,
};

export function InvoiceCard({ invoice, className, children }: InvoiceCardProps) {
  const { t } = useTranslation();

  const formattedBilledAt = useMemo(() => {
    return getFormattedDate(invoice.billedAt);
  }, [invoice]);

  const formattedBilledAmount = useMemo(() => {
    return formatCentsToCurrency(invoice.billedAmount, false);
  }, [invoice]);

  const formattedOwedAmount = useMemo(() => {
    return formatCentsToCurrency(invoice.billedAmount - (invoice.payment?.paidAmount || 0), false);
  }, [invoice]);

  const billedAtData = useMemo(() => {
    const d = new Date(invoice.billedAt);
    const n = new Date();

    // get count of days passed
    const daysPassed =
      Math.floor((new Date().getTime() - d.getTime()) / (1000 * 3600 * 24)) + (d.getDate() === n.getDate() ? 0 : 1);

    let variant: ChipProps['variant'] = 'neutral';
    let daysPassedLabel = '';
    if (daysPassed === 1) {
      daysPassedLabel = t(`1 Day`);
    } else if (daysPassed < 30) {
      daysPassedLabel = t(`{{ days }} Days`, { days: daysPassed });
    } else if (daysPassed < 60) {
      variant = 'warn';
      daysPassedLabel = t(`{{ days }}+ Days`, { days: 30 });
    } else if (daysPassed < 90) {
      variant = 'tangerine';
      daysPassedLabel = t(`{{ days }}+ Days`, { days: 60 });
    } else {
      variant = 'critical';
      daysPassedLabel = t(`{{ days }}+ Days`, { days: 90 });
    }

    return {
      variant,
      daysPassedLabel,
    };
  }, [invoice]);

  return (
    <div className={`card ${className || ''}`} css={styles.card}>
      <div className='card__header' css={styles.header}>
        <div className='card__date' css={styles.date}>
          {formattedBilledAt}
        </div>
        <div className='card__date-chip'>
          <Chip variant={billedAtData.variant}>{billedAtData.daysPassedLabel}</Chip>
        </div>
      </div>
      <div className='card__info-blocks' css={styles.infoBlocks}>
        <div className='card__info-block' css={styles.infoBlock}>
          <label>{t('Amount')}</label>
          <div>{formattedBilledAmount}</div>
        </div>
        <div className='card__info-block' css={styles.infoBlock}>
          <label>{t('Owed')}</label>
          <div>{formattedOwedAmount}</div>
        </div>
        <div className='card__info-block' css={styles.infoBlock}>
          <label>{t('Provider')}</label>
          <div>{invoice.providerName || t('N/A')}</div>
        </div>
      </div>
      <div className='card__body'>{children}</div>
    </div>
  );
}

export type InvoiceCardActionsProps = {
  className?: string;
  invoice: InvoiceModel;
  trackingId: string;
  onClickRemind: (invoice: InvoiceModel) => void;
  onClickCollect: (invoice: InvoiceModel) => void;
  onClickView: (invoice: InvoiceModel) => void;
  onClickDelete: (invoice: InvoiceModel) => void;
};
export function InvoiceCardActions({
  className,
  invoice,
  trackingId,
  onClickRemind,
  onClickCollect,
  onClickView,
  onClickDelete,
}: InvoiceCardActionsProps) {
  const { t } = useTranslation();

  const { getMenuProps, getTriggerProps, getItemProps } = usePopoverMenu();

  const alert = useAlert();

  return (
    <div className={`card__actions ${className || ''}`} css={styles.actions}>
      <div css={styles.actionFullButton}>
        <Button
          variant='secondary'
          trackingId={`${trackingId}--invoice-card--remind`}
          onClick={() => onClickRemind(invoice)}
          iconName='send'
        >
          {t('Remind')}
        </Button>
      </div>
      <div css={styles.actionFullButton}>
        <Button
          variant='secondary'
          trackingId={`${trackingId}--invoice-card--collect`}
          onClick={() => onClickCollect(invoice)}
          iconName='pay'
        >
          {t('Collect')}
        </Button>
      </div>
      <div css={styles.actionMiniButton}>
        <Button variant='secondary' {...getTriggerProps()}>
          <Icon name='more' size={16} />
        </Button>
        <PopoverMenu {...getMenuProps()}>
          <CopyToClipboard
            text={invoice?.links.payment ?? ''}
            onCopy={() => {
              alert.success(t('Payment link copied!'));
            }}
          >
            <PopoverMenuItem
              {...getItemProps({ index: 0, onClick: () => {} })}
              trackingId={`${trackingId}--invoice-card--copy-link`}
              Icon={() => <Icon name='link' size={16} />}
            >
              {t('Copy Request Link')}
            </PopoverMenuItem>
          </CopyToClipboard>
          <PopoverMenuItem
            {...getItemProps({ index: 1, onClick: () => onClickView(invoice) })}
            trackingId={`${trackingId}--invoice-card--view-request`}
            Icon={() => <Icon name='arrow-right-small' size={16} />}
          >
            {t('View Request in Payments')}
          </PopoverMenuItem>
          <PopoverMenuItem
            {...getItemProps({ index: 2, onClick: () => onClickDelete(invoice) })}
            trackingId={`${trackingId}--invoice-card--delete-request`}
            Icon={({ color }) => <Icon name='trash' size={16} color={color as IconProps['color']} />}
            destructive
          >
            {t('Delete Request')}
          </PopoverMenuItem>
        </PopoverMenu>
      </div>
    </div>
  );
}
