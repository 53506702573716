import { useMemo } from 'react';
import { css } from '@emotion/react';
import { PortingQueries } from '@frontend/api-porting';
import { DashboardWidget, DashboardWidgetFC, useDashboardWidget } from '@frontend/grid-dashboard';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useAppScopeStore } from '@frontend/scope';
import { useSettingsNavigate } from '@frontend/settings-routing';
import { theme } from '@frontend/theme';
import { ContentLoader, IconButton, Text } from '@frontend/design-system';
import { PhoneNumbersPageTab } from '../constants';
import { preparePortingStatusTrackerInfo } from '../helper';
import { usePhoneNumbersPageShallowStore } from '../hooks';
import { PortingStatusTrackerInfo } from '../types';

/**
 * @dashboard-widget
 *
 * id: port-requests-widget
 * title: Port Requests
 * description: Displays the number of accepted and pending port requests.
 * icon: porting-status-small
 */
export const PortRequestsWidgets: DashboardWidgetFC = () => {
  const { t } = useTranslation('porting');
  const { currentSize } = useDashboardWidget();
  const { navigate } = useSettingsNavigate();
  const { setSelectedTab } = usePhoneNumbersPageShallowStore('setSelectedTab');
  const { selectedLocationIds } = useAppScopeStore();
  const { data: portStatusByLocationIds, isLoading } =
    PortingQueries.useGetPortStatusByLocationIds(selectedLocationIds);

  const { acceptedCount, pendingCount } = useMemo<PortingStatusTrackerInfo>(
    () => preparePortingStatusTrackerInfo(portStatusByLocationIds?.portingStatusInfo),
    [portStatusByLocationIds]
  );

  const handleInfoIconClick = () => {
    setSelectedTab(PhoneNumbersPageTab.PortRequests);
    navigate({ to: '/organization/phone-numbers' });
  };

  const isTinySlim = currentSize === 'tiny-slim';
  return (
    <DashboardWidget>
      <DashboardWidget.Header />
      <DashboardWidget.Content>
        <ContentLoader show={isLoading} />
        <dl css={detailsStyle}>
          <div>
            <Text as='dt' color='light' size='medium'>
              {t('Accepted')}
            </Text>
            <Text as='dd' weight='bold'>
              {acceptedCount}
            </Text>
          </div>
          {!isTinySlim && (
            <div>
              <Text as='dt' color='light' size='medium'>
                {t('Pending')}
              </Text>
              <Text as='dd' weight='bold'>
                {pendingCount}
              </Text>
            </div>
          )}
        </dl>
        <IconButton
          css={infoButtonStyle}
          size='small'
          showLabelOnHover
          label={t('Click for Details')}
          onClick={handleInfoIconClick}
        >
          <Icon name='info-small' />
        </IconButton>
      </DashboardWidget.Content>
    </DashboardWidget>
  );
};

PortRequestsWidgets.config = {
  size: {
    extraSmall: 'tiny-slim',
    small: 'small-narrow',
    medium: 'small-narrow',
    large: 'small-narrow',
  },
  feature: 'general',
};

const detailsStyle = css({
  display: 'flex',
  gap: theme.spacing(2),
  div: {
    minWidth: 100,
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column-reverse',
    dd: {
      fontSize: theme.fontSize(48),
      lineHeight: 1,
    },
  },
});

const infoButtonStyle = css({
  position: 'absolute',
  bottom: theme.spacing(1),
  right: theme.spacing(1),
  padding: 0,
  minWidth: 24,
  width: 24,
  height: 24,
});
