import { QueryKey, useInfiniteQuery, UseInfiniteQueryOptions, UseInfiniteQueryResult } from 'react-query';
import { useDevToolsStore } from '@frontend/devtools';
import { useLocalizedQueryScoper } from './legacy-location-scoper';
import { useLocationDataShallowStore } from '.';

/**
 * This hook is a wrapper around useInfiniteQuery with the option object signature.
 *
 * This wrapper simply prepends the query keys with the current `locationId` provided by the useLocationDataShallowStore hook.
 * The `locationId` is available to the `queryFn` as the first element in the `queryKey` array.
 */
/**
 * @deprecated This component is deprecated and will be removed as part of the Murder The Portal initiative.
 * This is due to the `useLocationDataShallowStore` also being deprecated.
 * Please use `useScopedInfiniteQuery` instead.
 */
export function useLocalizedInfiniteQuery<
  TQueryFnData,
  TError,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(
  arg: UseInfiniteQueryOptions<TQueryFnData, TError, TData, TQueryFnData, TQueryKey>
): UseInfiniteQueryResult<TData, TError> {
  const scoper = useLocalizedQueryScoper();
  const locationIdFromStore = useLocationDataShallowStore('locationId')?.locationId;
  const locationId = scoper?.locationId ?? locationIdFromStore;
  const { queryKey, queryFn } = arg;

  let key: string[] = [];
  if (typeof queryKey === 'string') {
    key = [queryKey];
  } else if (Array.isArray(queryKey)) {
    key = queryKey;
  }

  const localizedQueryKey: QueryKey = [locationId, ...key];

  const modifiedArguments = {
    ...arg,
    queryKey: localizedQueryKey,
    queryFn: scoper
      ? async (...args) => {
          scoper?.scope();
          let res;
          try {
            res = await queryFn?.(...args);
          } finally {
            scoper?.unscope();
          }
          return res;
        }
      : queryFn,
  } as typeof arg;

  const {
    options: { debugUseLocalizedQuery },
  } = useDevToolsStore();

  if (debugUseLocalizedQuery) {
    console.info('%cInfinite Query params:', 'color: aqua', modifiedArguments);
  }

  return useInfiniteQuery<TQueryFnData, TError, TData, TQueryKey>(modifiedArguments);
}

export function useLocalizedFreshInfiniteQuery<
  TQueryFnData,
  TError,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(
  arg: UseInfiniteQueryOptions<TQueryFnData, TError, TData, TQueryFnData, TQueryKey>
): UseInfiniteQueryResult<TData, TError> {
  const scoper = useLocalizedQueryScoper();
  const locationIdFromStore = useLocationDataShallowStore('locationId')?.locationId;
  const locationId = scoper?.locationId ?? locationIdFromStore;
  const { queryKey, queryFn } = arg;

  let key: string[] = [];
  if (typeof queryKey === 'string') {
    key = [queryKey];
  } else if (Array.isArray(queryKey)) {
    key = queryKey;
  }

  const localizedQueryKey: QueryKey = [locationId, ...key];

  const modifiedArguments = {
    ...arg,
    queryKey: localizedQueryKey,
    staleTime: 0,
    queryFn: scoper
      ? async (...args) => {
          scoper?.scope();
          let res;
          try {
            res = await queryFn?.(...args);
          } finally {
            scoper?.unscope();
          }
          return res;
        }
      : queryFn,
  } as typeof arg;

  const {
    options: { debugUseLocalizedQuery },
  } = useDevToolsStore();

  if (debugUseLocalizedQuery) {
    console.info('%cInfinite Query params:', 'color: aqua', modifiedArguments);
  }

  return useInfiniteQuery<TQueryFnData, TError, TData, TQueryKey>(modifiedArguments);
}
