import { Element, useNode } from '@craftjs/core';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { NakedButton, Text } from '@frontend/design-system';
import { SIDEBAR_BREAKPOINTS } from '../../constants';
import { useContentComposer } from '../content-composer-provider';
import { FooterSection } from '../sections';

type ContainerProps = {
  children?: React.ReactNode;
  canvasEmptyStateTitle?: string;
  canvasEmptyStateText?: string;
  canvasEmptyStateSubtext?: string;
};

export const Designer = ({
  children,
  canvasEmptyStateTitle,
  canvasEmptyStateText,
  canvasEmptyStateSubtext,
}: ContainerProps) => {
  const { t } = useTranslation('content-composer');
  const { deviceSizeProps, setActivePanel, composerMode } = useContentComposer();
  const {
    connectors: { connect, drag },
  } = useNode();

  const hideDefaultFooter = composerMode !== 'marketing';
  const size = deviceSizeProps?.value;

  return (
    <div
      css={[
        css`
          margin: 0 auto;
          width: ${size === 'desktop' ? '100%' : '480px'};
          max-width: 1200px;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
        `,
        !children &&
          css`
            align-items: center;
          `,
      ]}
      ref={(ref) => (ref ? connect(drag(ref)) : null)}
    >
      {children ? (
        children
      ) : (
        <div
          css={css`
            display: flex;
            justify-content: center;
            flex-direction: column;
            max-width: 200px;
            text-align: center;
            height: 100%;
          `}
        >
          <Text
            weight='bold'
            color='disabled'
            css={css`
              margin-bottom: ${theme.spacing(1)};
            `}
          >
            {canvasEmptyStateTitle || t('Design Your Email Here')}
          </Text>
          <Text
            color='disabled'
            css={css`
              @media (max-width: ${SIDEBAR_BREAKPOINTS.mobile}px) {
                display: none;
              }
            `}
          >
            {canvasEmptyStateText || t('Select or drag & drop the options from the left to build your email.')}
          </Text>
          <Text
            color='disabled'
            css={css`
              @media (min-width: ${SIDEBAR_BREAKPOINTS.mobile + 1}px) {
                display: none;
              }
            `}
          >
            {canvasEmptyStateSubtext || t('Start Designing your email')}
          </Text>
          <NakedButton
            css={css`
              display: flex;
              align-items: center;
              justify-content: center;
              margin-top: ${theme.spacing(2)};
              @media (min-width: ${SIDEBAR_BREAKPOINTS.mobile + 1}px) {
                display: none;
              }
            `}
            onClick={() => setActivePanel('toolbox')}
          >
            <Icon name='plus-small' color='primary' />
            <Text as='span' color='primary'>
              {t('Add Element')}
            </Text>
          </NakedButton>
        </div>
      )}
      {hideDefaultFooter ? null : <Element id='footer' is={FooterSection} />}
    </div>
  );
};
