import { useEffect } from 'react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useMediaQuery } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';
import {
  BoldAction,
  Button,
  ColorPickerAction,
  FontPickerAction,
  ItalicAction,
  NakedButton,
  SizePickerAction,
  Text,
  TextField,
  useForm,
  useFormField,
} from '@frontend/design-system';
import { useComposerSection, useLinks } from '../../../hooks';
import { FooterLinkType, FooterLink, FooterSectionProps, SetComposerSectionProps } from '../../../types';
import { FooterMode } from '../footer-section';
import { ExpansionButton, FloatingToolbarCenter, PopoverButtonGroup, ToolbarButtonGroup } from '.';

type NonCustomLinkProps = {
  links?: FooterLink[];
  selectedLink?: FooterLink;
  setMode?: (mode: FooterMode) => void;
  setProps: SetComposerSectionProps<FooterSectionProps>;
};

const NonCustomLink = ({ links, selectedLink, setMode, setProps }: NonCustomLinkProps) => {
  const { t } = useTranslation('content-composer');
  const fieldProps = useFormField(
    {
      type: 'text',
      value: selectedLink?.link,
    },
    [selectedLink]
  );

  const handleUpdate = () => {
    if (!fieldProps.value) return;
    const newLink = { type: selectedLink?.type ?? 'custom', link: fieldProps.value, text: fieldProps.value };
    // the link already exists, so just update the link
    if (selectedLink?.link && (!links || links?.find((link) => link.type === selectedLink.type))) {
      setProps((props) => {
        const index = props.links?.findIndex((link) => link.type === selectedLink?.type) ?? -1;
        if (index === -1) return;
        if (props.links === undefined) {
          props.links = [];
        }
        props.links[index] = newLink;
      });
    } else {
      setProps((props) => (props.links = props.links ? [...props.links, newLink] : [newLink]));
    }
    setMode?.('options');
  };

  return (
    <div css={toolbarStyling}>
      <Icon name='back' css={iconStyling} onClick={() => setMode?.('options')} />
      <TextField
        {...fieldProps}
        name=''
        label=''
        placeholder={
          selectedLink?.type === 'phone' ? t('Phone') : selectedLink?.type === 'email' ? t('Email') : t('Link URL')
        }
        css={{ height: theme.spacing(4) }}
        autoFocus
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleUpdate();
          }
        }}
      />
      <Icon name='check' color={fieldProps.value ? 'primary' : 'disabled'} onClick={handleUpdate} css={iconStyling} />
    </div>
  );
};

type CustomLinkProps = {
  links?: FooterLink[];
  selectedLink?: FooterLink;
  setMode?: (mode: FooterMode) => void;
  setProps: SetComposerSectionProps<FooterSectionProps>;
};

const CustomLink = ({ selectedLink, setMode, setProps }: CustomLinkProps) => {
  const { t } = useTranslation('content-composer');
  const { getFieldProps } = useForm({
    fields: {
      linkText: { type: 'text', value: selectedLink?.text },
      link: { type: 'text', value: selectedLink?.link },
    },
  });

  const linkFieldProps = getFieldProps('link');
  const linkTextFieldProps = getFieldProps('linkText');

  useEffect(() => {
    linkFieldProps.onChange({ ...linkFieldProps, value: selectedLink?.link });
    linkTextFieldProps.onChange({ ...linkTextFieldProps, value: selectedLink?.text });
  }, [selectedLink]);

  const handleUpdate = () => {
    if (linkFieldProps.value || linkTextFieldProps.value) {
      const newLink = { type: 'custom' as FooterLinkType, link: linkFieldProps.value, text: linkTextFieldProps.value };
      if (selectedLink?.link) {
        setProps((props) => {
          const index = props.links?.findIndex((link) => link.link === selectedLink?.link) ?? -1;
          if (index === -1) return;
          if (props.links === undefined) {
            props.links = [];
          }
          props.links[index] = newLink;
        });
      } else {
        setProps((props) => (props.links = props.links ? [...props.links, newLink] : [newLink]));
      }
      setMode?.('options');
    }
  };

  return (
    <div css={toolbarStyling}>
      <Icon name='x' onClick={() => setMode?.('options')} css={iconStyling} />
      <TextField
        {...linkTextFieldProps}
        name='linkText'
        label=''
        placeholder={t('Link Text')}
        autoFocus
        css={{ height: theme.spacing(4) }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleUpdate();
          }
        }}
      />
      <TextField
        {...linkFieldProps}
        name='link'
        label=''
        placeholder={t('Link URL')}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleUpdate();
          }
        }}
        css={{ height: theme.spacing(4) }}
      />
      <Icon
        name='check'
        color={linkFieldProps.value && linkTextFieldProps.value ? 'primary' : 'disabled'}
        onClick={handleUpdate}
        css={iconStyling}
      />
    </div>
  );
};

export type FooterSettings = {
  bold: boolean;
  fontType: string;
  italic: boolean;
  textColor: string;
  textSize: number;
};

type FooterToolbarProps = {
  links?: FooterLink[];
  mode?: FooterMode;
  selectedLink?: FooterLink;
  setMode?: (mode: FooterMode) => void;
  setSelectedLink?: (link?: FooterLink) => void;
  settings?: FooterSettings;
};

export const FooterToolbar = ({
  links,
  mode,
  setMode,
  selectedLink,
  setSelectedLink,
  settings,
}: FooterToolbarProps) => {
  const { t } = useTranslation('content-composer');
  const { email, phone } = useLinks();
  const { setProps } = useComposerSection<FooterSectionProps>();

  const EXTRA_LARGE_BREAKPOINT = 1450;
  const LARGE_BREAKPOINT = 1200;
  const MEDIUM_BREAKPOINT = 850;
  const SMALL_BREAKPOINT = 600;

  const fontPicker = (
    <FontPickerAction
      css={{ fontWeight: theme.font.weight.bold }}
      value={settings?.fontType}
      onChange={(font) => setProps((props) => (props.fontType = font))}
    />
  );

  const sizePicker = (
    <SizePickerAction
      size={settings?.textSize}
      onChange={(size) => setProps((props) => (props.textSize = size))}
      css={{
        button: {
          width: 65,
        },
        span: {
          fontWeight: theme.font.weight.bold,
        },
      }}
    />
  );

  const colorPicker = (
    <ColorPickerAction
      color={settings?.textColor}
      onChange={(color) => setProps((props) => (props.textColor = color))}
      css={{ fontWeight: theme.font.weight.bold }}
    />
  );

  const boldPicker = (
    <BoldAction isActive={settings?.bold} onClick={() => setProps((props) => (props.bold = !settings?.bold))} />
  );

  const italicPicker = (
    <ItalicAction isActive={settings?.italic} onClick={() => setProps((props) => (props.italic = !settings?.italic))} />
  );

  const addLink = (showText: boolean) => (
    <NakedButton
      onClick={() => setMode?.('options')}
      css={{
        display: 'flex',
        alignItems: 'center',
        columnGap: theme.spacing(0.5),
        background: theme.colors.white,
        boxShadow: theme.shadows.floating,
        borderRadius: theme.borderRadius.small,
        padding: theme.spacing(0, 1),
        width: theme.spacing(11),
        fontWeight: theme.font.weight.bold,
      }}
    >
      <Icon name='link-small' />
      {showText && (
        <Text as='span' size='small' weight='bold'>
          {t('Add Link')}
        </Text>
      )}
    </NakedButton>
  );

  const initialState = (
    <>
      {fontPicker}
      <ToolbarButtonGroup breakpoint={SMALL_BREAKPOINT}>{sizePicker}</ToolbarButtonGroup>
      <ToolbarButtonGroup breakpoint={MEDIUM_BREAKPOINT}>
        {colorPicker}
        {boldPicker}
      </ToolbarButtonGroup>
      <ToolbarButtonGroup breakpoint={LARGE_BREAKPOINT}>
        {italicPicker}
        {addLink(true)}
      </ToolbarButtonGroup>
      <ExpansionButton breakpoint={LARGE_BREAKPOINT}>
        <PopoverButtonGroup breakpoint={SMALL_BREAKPOINT}>{sizePicker}</PopoverButtonGroup>
        <PopoverButtonGroup breakpoint={MEDIUM_BREAKPOINT}>
          {colorPicker}
          {boldPicker}
        </PopoverButtonGroup>
        {italicPicker}
        {addLink(false)}
      </ExpansionButton>
    </>
  );

  type OptionType = { type: FooterLinkType; label: string };

  const createLinkButton = (option: OptionType) => (
    <Button
      variant='secondary'
      key={option.type}
      css={{
        padding: theme.spacing(1),
        justifyContent: 'flex-start',
        columnGap: theme.spacing(0.5),
        height: theme.spacing(3),
        width: 'auto',
        fontWeight: theme.font.weight.regular,
        ...(option.type === 'custom' && { minWidth: theme.spacing(14.2) }),
      }}
      onClick={() => {
        if (option.type === 'custom') {
          setMode?.('custom');
        } else if (option.type === 'phone' || option.type === 'email') {
          setMode?.('options');
        } else {
          setMode?.('link');
        }

        if (option.type === 'phone') {
          const phoneOption = { type: option.type, link: phone, text: phone };
          setProps((props) => (props.links = props.links ? [...props.links, phoneOption] : [phoneOption]));
        } else if (option.type === 'email') {
          const emailOption = { type: option.type, link: email, text: email };
          setProps((props) => (props.links = props.links ? [...props.links, emailOption] : [emailOption]));
        } else {
          // TODO: get the other links from the BE and set them here as well
          setSelectedLink?.({ type: option.type, link: '', text: '' });
        }
      }}
    >
      <Icon name='link' css={iconStyling} />
      <Text as='span'>{option.label}</Text>
    </Button>
  );

  const includesLink = (option: OptionType): boolean | undefined => {
    if (option.type === 'custom') return true;
    return !links?.find((link) => link.type === option.type);
  };

  const options: OptionType[] = [
    { type: 'google', label: t('Google') },
    { type: 'facebook', label: t('Facebook') },
    { type: 'phone', label: t('Phone') },
    { type: 'email', label: t('Email') },
    { type: 'custom', label: t('Custom Link') },
  ];

  const filteredOptions = options.filter((option) => includesLink(option));
  const arr1 = filteredOptions.slice(0, 1);
  const arr2 = filteredOptions.filter((option) => !arr1.find((o) => o.type === option.type)).slice(0, 1);
  const arr3 = filteredOptions.filter(
    (option) => !arr1.find((o) => o.type === option.type) && !arr2.find((o) => o.type === option.type)
  );
  const isMedium = useMediaQuery(`(min-width: ${MEDIUM_BREAKPOINT}px) and (max-width: ${LARGE_BREAKPOINT}px)`);

  const optionsState = (
    <div css={toolbarStyling}>
      <Icon name='x' onClick={() => setMode?.('default')} css={iconStyling} />
      {arr1.map(createLinkButton)}
      <ToolbarButtonGroup breakpoint={MEDIUM_BREAKPOINT}>{arr2.map(createLinkButton)}</ToolbarButtonGroup>
      <ToolbarButtonGroup breakpoint={EXTRA_LARGE_BREAKPOINT}>{arr3.map(createLinkButton)}</ToolbarButtonGroup>
      {((arr2.length > 0 && !isMedium) || arr3.length > 0) && (
        <ExpansionButton breakpoint={EXTRA_LARGE_BREAKPOINT} hideBorder>
          {arr2.length > 0 ? (
            <PopoverButtonGroup breakpoint={MEDIUM_BREAKPOINT}>{arr2.map(createLinkButton)}</PopoverButtonGroup>
          ) : null}
          {arr3.length > 0 ? arr3.map(createLinkButton) : null}
        </ExpansionButton>
      )}
    </div>
  );

  const getContent = () => {
    if (mode === 'default') return initialState;
    else if (mode === 'custom') return <CustomLink setMode={setMode} setProps={setProps} selectedLink={selectedLink} />;
    else if (mode === 'link')
      return <NonCustomLink links={links} setMode={setMode} setProps={setProps} selectedLink={selectedLink} />;
    return optionsState;
  };

  return (
    <FloatingToolbarCenter orientation='bottom' css={{ columnGap: theme.spacing(0.5) }}>
      {getContent()}
    </FloatingToolbarCenter>
  );
};

const toolbarStyling = {
  display: 'flex',
  alignItems: 'center',
  columnGap: theme.spacing(1),
  margin: theme.spacing(0, 1),
  padding: theme.spacing(1),
  background: theme.colors.white,
  boxShadow: theme.shadows.light,
  border: 'solid 1px transparent',
  borderRadius: theme.borderRadius.small,
  transform: 'translateY(15%)',
  transition: 'all 250ms ease',
  '&:hover, &:focus': {
    outline: 'none',
    boxShadow: theme.shadows.floating,
    background: theme.colors.neutral5,
  },
};

const iconStyling = {
  cursor: 'pointer',
  width: theme.spacing(2),
  height: theme.spacing(2),
};
