import { Contact } from '@weave/schema-gen-ts/dist/schemas/phone/contacts/contacts/contacts.pb';
import { ModalControlModalProps, Tray } from '@frontend/design-system';
import type { SelectedContactsType } from '../types';
import { ViewContactDetailsModal } from '.';

type Props = ModalControlModalProps & {
  contacts: Contact[] | undefined;
  selectedContacts: SelectedContactsType;
  contactDirectoryId?: string;
};
export const EditContactModal = ({ contacts, selectedContacts, contactDirectoryId, ...modalProps }: Props) => {
  return (
    <Tray width='smallXL' {...modalProps} disableCloseOnEscape>
      <ViewContactDetailsModal isEditModeEnabled {...modalProps} contact={selectedContacts.contacts[0]} />
    </Tray>
  );
};
