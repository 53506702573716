import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { useMatch } from '@tanstack/react-location';
import {
  LOADetails,
  ReadLOAResponse,
  ShippingAddress,
} from '@weave/schema-gen-ts/dist/schemas/agreements/v1/insys_agreements.pb';
import { LoaApi } from '@frontend/api-porting';
import appConfig from '@frontend/env';
import { http, useFetch } from '@frontend/fetch';
import { canadaOptions, usOptions, StateOption } from '@frontend/geography';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useSuppressZendeskWidget } from '@frontend/shared';
import { theme } from '@frontend/theme';
import {
  Heading,
  Text,
  FormRow,
  TextField,
  useForm,
  FormFieldActionTypes,
  PhoneField,
  PostalCodeLocale,
  DropdownField,
  PostalCodeField,
  CheckboxField,
  useFormField,
  useControlledField,
  ValidatorFieldState,
  SpinningLoader,
  useModalControl,
  useAlert,
} from '@frontend/design-system';
import { ErrorComponent } from '../../components/common/error-component';
import { ErrorModal } from '../../components/common/error-modal';
import { PdfViewComponent } from '../../components/loa/pdf-view-component';
import { SignedEditableComponent } from '../../components/loa/signed-editable-component';
import { SuccessModal } from '../../components/loa/success-modal';
import { getAPIErrorMessage } from '../../helper';
import {
  getFormattedNumbers,
  validatePhoneNumbersRegex,
  isAlphaNumericValue,
  isNumericValue,
  validateFullNameRegex,
  formattedVoiceNumberValues,
  formatNumbers,
  convertedToNumbers,
  formatVoiceNumber,
} from '../../helper/index';
import { CountryCodes } from '../../helper/state-codes';

interface Props {
  isPdf?: boolean;
}

export const getErrorMessageFromResponse = (responseText?: string) => {
  const errorData = responseText?.match(/.*"messages":\["(?<message>.*?)"/);
  return errorData && errorData.length > 1 ? errorData[1] : '';
};

export const Loa = ({ isPdf = false }: Props) => {
  const {
    params: { loaId },
  } = useMatch();
  const { t } = useTranslation('portingLoa');
  const [filteredStates, setFilteredStates] = useState<StateOption[]>([]);
  const [filteredBillingStates, setFilteredBillingStates] = useState<StateOption[]>([]);
  const [voiceNumbers, setVoiceNumbers] = useState('');
  const [faxNumbers, setFaxNumbers] = useState('');
  const billingAddressCheckboxValue = useFormField({ type: 'checkbox' });
  const [serviceAddressError, setServiceAddressError] = useState('');
  const [billingAddressError, setBillingAddressError] = useState('');
  const [loaData, setLoaData] = useState<ReadLOAResponse>();
  const [pdfView, setPdfViw] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isError, setIsError] = useState(false);
  const [localeValue, setLocaleValue] = useState(PostalCodeLocale.US);
  const [postalValue, setPostalValue] = useState('');
  const [shippingLocateValue, setShippingLocateValue] = useState(PostalCodeLocale.US);
  const [shippingPostalValue, setShippingPostalValue] = useState('');
  const [duplicateValue, setDuplicateValues] = useState<string[]>([]);
  const [mainTelephoneNumError, setMainTelephoneNumError] = useState('');
  const [faxNumError, setFaxNumError] = useState('');
  const alert = useAlert();

  const handleCloseModal = () => {
    modalProps.openModal();
    return;
  };

  const { closeModal, ...modalProps } = useModalControl({
    onClose() {
      handleCloseModal();
    },
  });

  useFetch({ baseUrl: appConfig.BACKEND_API as string });

  const postalFieldProps = useFormField(
    { type: 'postalCode', value: postalValue, locale: localeValue, required: true },
    [postalValue, localeValue]
  );

  const shippingPostalFieldProps = useFormField(
    { type: 'postalCode', value: shippingPostalValue, locale: shippingLocateValue, required: true },
    [shippingPostalValue, shippingLocateValue]
  );

  const voiceNumbersFieldProps = useControlledField({
    type: 'text',
    value: voiceNumbers,
    onChange: (value) => {
      setVoiceNumbers(getFormattedNumbers(value));
    },
    validator: ({ value, active }: ValidatorFieldState<'text'>) => {
      if (!active) {
        const convertedNumber = formatNumbers(value);
        if (!validatePhoneNumbersRegex(convertedNumber)) {
          const msg = t('Please enter valid phone numbers');
          return msg;
        } else {
          const formattedNumbers = formattedVoiceNumberValues([...value.split(',')]);
          setVoiceNumbers(formattedNumbers.join(', '));
        }
      }
      return '';
    },
  });

  const faxNumbersFieldProps = useControlledField({
    type: 'text',
    value: faxNumbers,
    onChange: (value) => {
      setFaxNumbers(getFormattedNumbers(value));
    },
    validator: ({ value, active }: ValidatorFieldState<'text'>) => {
      if (!active) {
        const convertedNumber = formatNumbers(value);
        if (!validatePhoneNumbersRegex(convertedNumber)) {
          const msg = t('Please enter valid fax numbers');
          return msg;
        } else {
          const formattedNumbers = formattedVoiceNumberValues([...value.split(',')]);
          setFaxNumbers(formattedNumbers.join(', '));
        }
      }
      return '';
    },
  });
  const {
    formProps: portingFormProps,
    getFieldProps,
    seedValues,
    values,
  } = useForm({
    fields: {
      companyName: {
        type: 'text',
        required: true,
      },
      currentCarrier: {
        type: 'text',
        required: true,
      },
      phone: { type: 'phone', required: true },
      mainTelephoneNumber: { type: 'phone' },
      mainFaxNumber: { type: 'phone' },
      serviceAddress: {
        type: 'text',
        required: true,
      },
      billingAddress: {
        type: 'text',
        required: true,
      },
      city: {
        type: 'text',
        required: true,
      },
      billingCity: {
        type: 'text',
        required: true,
      },
      state: {
        type: 'dropdown',
        required: true,
      },
      billingCountry: {
        type: 'dropdown',
        required: true,
      },
      billingState: {
        type: 'dropdown',
        required: true,
      },
      country: {
        type: 'dropdown',
        required: true,
      },
    },
    fieldStateReducer: (state, action) => {
      if (action.type === FormFieldActionTypes.Update) {
        if (billingAddressCheckboxValue.value === true && ['city', 'serviceAddress'].includes(action.payload.name)) {
          return {
            ...state,
            billingCity: {
              ...state.billingCity,
              value: state.city.value,
            },
            billingAddress: {
              ...state.billingAddress,
              value: state.serviceAddress.value,
            },
          };
        }
        switch (action.payload.name) {
          case 'country': {
            action.payload.value === PostalCodeLocale.CA
              ? setFilteredStates(canadaOptions)
              : setFilteredStates(usOptions);
            action.payload.value === PostalCodeLocale.CA
              ? setShippingLocateValue(PostalCodeLocale.CA)
              : setShippingLocateValue(PostalCodeLocale.US);
            setShippingPostalValue('');
            return {
              ...state,
              state: {
                ...state.state,
                value: '',
              },
            };
          }
          case 'mainTelephoneNumber': {
            if (!state.mainFaxNumber.value) {
              return {
                ...state,
                mainTelephoneNumber: {
                  ...state.mainTelephoneNumber,
                  required: true,
                },
                mainFaxNumber: {
                  ...state.mainFaxNumber,
                  required: false,
                },
              };
            } else {
              return {
                ...state,
                mainTelephoneNumber: {
                  ...state.mainTelephoneNumber,
                  required: false,
                },
              };
            }
          }
          case 'mainFaxNumber': {
            if (!state.mainTelephoneNumber.value) {
              return {
                ...state,
                mainTelephoneNumber: {
                  ...state.mainTelephoneNumber,
                  required: false,
                },
                mainFaxNumber: {
                  ...state.mainFaxNumber,
                  required: true,
                },
              };
            } else {
              return {
                ...state,
                mainFaxNumber: {
                  ...state.mainFaxNumber,
                  required: false,
                },
              };
            }
          }

          case 'billingCountry': {
            action.payload.value === PostalCodeLocale.CA
              ? setFilteredBillingStates(canadaOptions)
              : setFilteredBillingStates(usOptions);
            action.payload.value === PostalCodeLocale.CA
              ? setLocaleValue(PostalCodeLocale.CA)
              : setLocaleValue(PostalCodeLocale.US);
            setPostalValue('');
            return {
              ...state,
              billingState: {
                ...state.billingState,
                value: '',
              },
            };
          }
          default:
            return null;
        }
      }
      return null;
    },
  });

  const {
    serviceAddress,
    billingAddress,
    city,
    billingCity,
    country,
    state,
    billingCountry,
    billingState,
    companyName,
    phone,
    currentCarrier,
    mainTelephoneNumber,
    mainFaxNumber,
  } = values;

  const getData = async () => {
    try {
      const data = await LoaApi.readLOA({ loaId });
      setLoaData(data);
    } catch (e) {
      const errorMessage = getAPIErrorMessage(e) || t('Something went wrong. Please try again.');
      setErrorMessage(errorMessage);
      setIsError(true);
    }
    return;
  };
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    const convertedVoiceNumber = voiceNumbers ? convertedToNumbers(voiceNumbers.split(',')) : [];
    const convertedFaxNumber = faxNumbers ? convertedToNumbers(faxNumbers.split(',')) : [];
    const valuesArray = [
      ...convertedVoiceNumber,
      ...convertedFaxNumber,
      mainFaxNumber ?? '',
      mainTelephoneNumber ?? '',
    ];
    const duplicates: string[] = valuesArray.filter((value, index) => valuesArray.indexOf(value) !== index);

    setDuplicateValues(duplicates);

    if (mainTelephoneNumber) {
      setMainTelephoneNumError('');
    }
    if (mainFaxNumber) {
      setFaxNumError('');
    }
    if (!mainTelephoneNumber && convertedVoiceNumber.length) {
      setMainTelephoneNumError(t('Main Telephone number cannot be empty when other Telephone numbers filled'));
    }
    if (!mainFaxNumber && convertedFaxNumber.length) {
      setFaxNumError(t('Main Fax number cannot be empty when other Fax numbers filled'));
    }
  }, [mainTelephoneNumber, mainFaxNumber, voiceNumbers, faxNumbers]);

  useSuppressZendeskWidget('helpCenter');
  let loaDetailsData: LOADetails;
  if (loaData?.loaSummary?.loaDetails) {
    loaDetailsData = loaData?.loaSummary?.loaDetails;
  }
  const stateValue = filteredStates.find((st) => state === st.label || state === st.value);
  const billingStateValue = filteredBillingStates.find((st) => billingState === st.label || billingState === st.value);

  useEffect(() => {
    loaDetailsData?.billingAddress?.shippingCountry === PostalCodeLocale.CA ? (
      <>
        {setLocaleValue(PostalCodeLocale.CA)} {setFilteredBillingStates(canadaOptions)}
      </>
    ) : (
      <>
        {setLocaleValue(PostalCodeLocale.US)} {setFilteredBillingStates(usOptions)}
      </>
    );
    loaDetailsData?.serviceAddress?.shippingCountry === PostalCodeLocale.CA
      ? setShippingLocateValue(PostalCodeLocale.CA)
      : setShippingLocateValue(PostalCodeLocale.US);

    if (loaDetailsData?.serviceAddress?.shippingPostalCode)
      setShippingPostalValue(loaDetailsData.serviceAddress.shippingPostalCode);

    if (loaDetailsData?.billingAddress?.shippingPostalCode)
      setPostalValue(loaDetailsData?.billingAddress?.shippingPostalCode);

    loaDetailsData?.serviceAddress?.shippingCountry === PostalCodeLocale.CA
      ? setFilteredStates(canadaOptions)
      : setFilteredStates(usOptions);
    seedValues({
      companyName: loaDetailsData?.companyName,
      phone: loaDetailsData?.billingTelephoneNumber,
      currentCarrier: loaDetailsData?.currentCarrier,
      serviceAddress:
        `${loaDetailsData?.serviceAddress?.shippingStreet}` + `${loaDetailsData?.serviceAddress?.shippingStreet2}`,
      city: loaDetailsData?.serviceAddress?.shippingCity,
      country: loaDetailsData?.serviceAddress?.shippingCountry,
      state: loaDetailsData?.serviceAddress?.shippingState,
      billingAddress:
        `${loaDetailsData?.billingAddress?.shippingStreet}` + `${loaDetailsData?.billingAddress?.shippingStreet2}`,
      billingCity: loaDetailsData?.billingAddress?.shippingCity,
      billingCountry: loaDetailsData?.billingAddress?.shippingCountry,
      billingState: loaDetailsData?.billingAddress?.shippingState,
      mainTelephoneNumber: loaDetailsData?.mainPhoneNumber,
      mainFaxNumber: loaDetailsData?.mainFaxNumber,
    });
    if (loaDetailsData?.otherPhoneNumbers) {
      const otherPhoneNumbersArray = loaDetailsData?.otherPhoneNumbers?.map((phoneNumber: string) => phoneNumber);
      const formattedNumber = formattedVoiceNumberValues(otherPhoneNumbersArray);
      setVoiceNumbers(formattedNumber.join(', '));
    }
    if (loaDetailsData?.otherFaxNumbers) {
      const otherFaxNumbersArray = loaDetailsData?.otherFaxNumbers?.map((phoneNumber: string) => phoneNumber);
      const formattedNumber = formattedVoiceNumberValues(otherFaxNumbersArray);
      setFaxNumbers(formattedNumber.join(', '));
    }

    if (
      !(
        loaData?.portingData?.portingStatus === 'PORT_STATUS_DRAFT' ||
        loaData?.portingData?.portingStatus === 'PORT_STATUS_UNSPECIFIED'
      )
    ) {
      loaData && setPdfViw(true);
      return;
    }
    loaData?.loaSummary?.signedDetails === null ? setPdfViw(false) : loaData && setPdfViw(true);
  }, [loaData]);
  const initValues = () => {
    const countryValue =
      country === CountryCodes.Canada.Label || country === CountryCodes.Canada.Code
        ? PostalCodeLocale.CA
        : country === ''
        ? ''
        : PostalCodeLocale.US;

    countryValue === PostalCodeLocale.CA ? setLocaleValue(PostalCodeLocale.CA) : setLocaleValue(PostalCodeLocale.US);
    shippingPostalFieldProps.value === '' ? setPostalValue('') : setPostalValue(shippingPostalFieldProps.value);
    seedValues({
      billingAddress: serviceAddress,
      billingCity: city,
      billingCountry: country,
      billingState: stateValue?.label || state,
    });
  };

  useEffect(() => {
    initValues();
  }, [billingAddressCheckboxValue.value === true && state]);

  useEffect(() => {
    initValues();
  }, [billingAddressCheckboxValue.value === true && country]);

  useEffect(() => {
    initValues();
  }, [billingAddressCheckboxValue.value === true && shippingPostalFieldProps.value]);

  useEffect(() => {
    initValues();
  }, [billingAddressCheckboxValue.value === true]);

  const checkForHouseNumber = (address: string): boolean => {
    if (isAlphaNumericValue(address[0]) || isNumericValue(address[0])) return true;
    return false;
  };

  useEffect(() => {
    if (serviceAddress) {
      checkForHouseNumber(serviceAddress)
        ? setServiceAddressError('')
        : setServiceAddressError(t('House number missing'));
    }
    if (billingAddress) {
      checkForHouseNumber(billingAddress)
        ? setBillingAddressError('')
        : setBillingAddressError(t('House number missing'));
    }
  }, [serviceAddress, billingAddress]);

  const disableForFaxNumber = () => {
    if (mainFaxNumber) return false;
    return true;
  };
  const disableForPhoneNumber = () => {
    if (mainTelephoneNumber) return false;
    return true;
  };

  const handleDisableButton = (signDate: string, authorizedUserFullName: string, checkboxValue: boolean): boolean => {
    const fieldValues = !(
      (mainTelephoneNumber || mainFaxNumber) &&
      !serviceAddressError &&
      !billingAddressError &&
      !duplicateValue.length &&
      !mainTelephoneNumError &&
      !faxNumError &&
      serviceAddress &&
      billingAddress &&
      city &&
      billingCity &&
      country &&
      state &&
      billingCountry &&
      billingState &&
      shippingPostalFieldProps.value &&
      postalFieldProps.value &&
      companyName &&
      phone &&
      currentCarrier &&
      signDate &&
      validateFullNameRegex(authorizedUserFullName) &&
      checkboxValue
    );
    return fieldValues;
  };

  if (errorMessage)
    return (
      <ErrorModal
        modalProps={{
          show: true,
          onClose: () => {
            setErrorMessage('');
          },
        }}
        message={errorMessage}
      />
    );

  const getServiceAddress = () => {
    const address: ShippingAddress = {
      shippingCity: city || '',
      shippingCountry: country || '',
      shippingPostalCode: shippingPostalFieldProps.value || '',
      shippingState: stateValue?.value || '',
      shippingStreet: serviceAddress || '',
      shippingStreet2: '',
      shippingCountryCode: '',
      shippingStateCode: '',
    };
    return address;
  };

  const getBillingAddress = () => {
    const address: ShippingAddress = {
      shippingCity: billingCity || '',
      shippingCountry: billingCountry || '',
      shippingCountryCode: '',
      shippingPostalCode: postalFieldProps.value || '',
      shippingState: billingStateValue?.value || '',
      shippingStreet: billingAddress || '',
      shippingStreet2: '',
      shippingStateCode: '',
    };
    return address;
  };

  const dataForLoa: LOADetails = {
    companyName: companyName || '',
    billingTelephoneNumber: phone || '',
    currentCarrier: currentCarrier || '',
    serviceAddress: getServiceAddress(),
    billingAddress: billingAddressCheckboxValue.value ? getServiceAddress() : getBillingAddress(),
    mainPhoneNumber: mainTelephoneNumber || '',
    mainFaxNumber: mainFaxNumber || '',
    otherPhoneNumbers: (voiceNumbers && formatVoiceNumber([voiceNumbers])) || [],
    otherFaxNumbers: (faxNumbers && formatVoiceNumber([faxNumbers])) || [],
  };

  const loaDataProps = {
    isPdf: pdfView || isPdf,
    loaDetails: loaData?.loaSummary?.loaDetails,
    signComponent: loaData?.loaSummary?.signedDetails,
    loaId: loaId,
  };

  if (!loaData)
    return (
      <div css={(theme) => loaderStyle(theme.heightOffset)}>
        <SpinningLoader />
      </div>
    );

  const handleSignedData = async (authorizedUserName: string, signDate: string) => {
    const loaDetails: LOADetails = {
      ...dataForLoa,
      name: authorizedUserName,
      signedDate: signDate,
    };
    try {
      await LoaApi.signLOA({ loaId, loaDetails });
      modalProps.openModal();
    } catch (e) {
      if (http.isHttpError(e)) {
        const httpError = (e?.data as Record<string, string>) ?? {};
        let errorCause = '';
        try {
          const json = JSON.parse(httpError.message) as Record<string, string>;
          errorCause = json?.cause ?? '';
        } catch (error) {
          console.error(error);
        }
        alert.error(t('Failed in Signing LOA {{cause}}', { cause: errorCause }));
      }
    }
  };

  return (
    <div css={isPdf ? pdfViewStyle : viewStyle} id='loa-container'>
      <div css={isError && displayNone}>
        <div css={display}>
          <Icon name='weave-logo' css={{ marginRight: theme.spacing(1) }} />
          <Text weight='bold' css={{ marginTop: theme.spacing(0.5) }}>
            {t('WEAVE')}
          </Text>
        </div>
        <Heading css={headingStyle}>{t('Letter of Authorization (LOA)')}</Heading>
        <Text css={textStyle}>
          {t(
            'This letter authorizes Weave Communications, Inc. (Weave) to initiate a port request. All information must be entered exactly as it appears on the Customer Service Record (CSR) of your current carrier. All fields must be completed. Any missing or invalid information will result in delays or rejected port orders. In addition to  completing this form, you will need to provide a copy of your most recent bill or invoice from your current carrier.'
          )}
        </Text>

        {isPdf || pdfView ? (
          <PdfViewComponent {...loaDataProps} handleSignedData={handleSignedData} />
        ) : (
          <form {...portingFormProps}>
            <div css={loaContainer}>
              <div css={display}>
                <Text weight='bold' css={widthForField}>
                  {t('Customer Account or Company Name:')}
                </Text>

                <FormRow>
                  <TextField
                    {...getFieldProps('companyName')}
                    label={t('Company Name')}
                    css={widthForField}
                    name='companyName'
                  />
                </FormRow>
              </div>

              <div css={display}>
                <Text weight='bold' css={widthForField}>
                  {t('Billing Telephone Number:')}
                </Text>
                <FormRow>
                  <PhoneField css={widthForField} {...getFieldProps('phone')} label={t('Add a phone number')} />
                </FormRow>
              </div>
              <div css={display}>
                <Text weight='bold' css={widthForField}>
                  {t('Current Carrier:')}
                </Text>
                <FormRow>
                  <TextField
                    {...getFieldProps('currentCarrier')}
                    label={t('Current Carrier')}
                    css={widthForField}
                    name='currentCarrier'
                  />
                </FormRow>
              </div>
            </div>
            <div css={[loaContainer, addressContainer]}>
              <div>
                <Text weight='bold'>{t('Service Address:')}</Text>
                <FormRow>
                  <TextField
                    {...getFieldProps('serviceAddress')}
                    label={t('Service Address')}
                    css={widthForAddressFields}
                    name='serviceAddress'
                  />
                </FormRow>
                {
                  <Text color='error' size='small' css={{ marginTop: theme.spacing(-3) }}>
                    {serviceAddressError}
                  </Text>
                }
              </div>
              <div>
                <Text weight='bold'>{t('City:')}</Text>
                <FormRow>
                  <TextField label={t('City')} css={widthForAddressFields} {...getFieldProps('city')} />
                </FormRow>
              </div>
              <div>
                <Text weight='bold'>{t('Country:')}</Text>
                <FormRow>
                  <DropdownField
                    {...getFieldProps('country')}
                    label={t('Country')}
                    name='country'
                    css={widthForAddressFields}
                  >
                    <DropdownField.Option value={CountryCodes.USA.Code}>{CountryCodes.USA.Label}</DropdownField.Option>
                    <DropdownField.Option value={CountryCodes.Canada.Code}>
                      {CountryCodes.Canada.Label}
                    </DropdownField.Option>
                  </DropdownField>
                </FormRow>
              </div>
              <div>
                <Text weight='bold'>{t('State:')}</Text>
                <FormRow>
                  <DropdownField
                    {...getFieldProps('state')}
                    placeholder={t('State')}
                    label={t('State')}
                    name='state'
                    css={widthForAddressFields}
                  >
                    {filteredStates.map((state) => (
                      <DropdownField.Option value={state.value} key={state.value}>
                        {state.label}
                      </DropdownField.Option>
                    ))}
                  </DropdownField>
                </FormRow>
              </div>
              <div>
                <Text weight='bold'>{t('Postal Code:')}</Text>
                <FormRow>
                  <PostalCodeField
                    {...shippingPostalFieldProps}
                    placeholder={t('Postal Code')}
                    label={t('Postal Code')}
                    name='postalCode'
                    css={widthForAddressFields}
                  />
                </FormRow>
              </div>
            </div>
            <div
              css={{
                marginLeft: theme.spacing(4),
              }}
            >
              <CheckboxField
                name='billingAddressCheckbox'
                disabled={isPdf ? true : false}
                label={
                  <Text weight='bold' css={{ marginBottom: theme.spacing(0.5) }}>
                    {t('Billing Address same as shipping address:')}
                  </Text>
                }
                {...billingAddressCheckboxValue}
              />
            </div>
            <div css={[loaContainer, addressContainer]}>
              <div>
                <Text weight='bold'>{t('Billing Address:')}</Text>
                <FormRow>
                  <TextField
                    {...getFieldProps('billingAddress')}
                    label={t('Service Address')}
                    disabled={billingAddressCheckboxValue.value}
                    css={widthForAddressFields}
                    name='billingAddress'
                  />
                </FormRow>
                {
                  <Text
                    color='error'
                    size='small'
                    css={{
                      marginTop: theme.spacing(-3),
                    }}
                  >
                    {billingAddressError}
                  </Text>
                }
              </div>
              <div>
                <Text weight='bold'>{t('City:')}</Text>
                <FormRow>
                  <TextField
                    {...getFieldProps('billingCity')}
                    label={t('City')}
                    disabled={billingAddressCheckboxValue.value}
                    css={widthForAddressFields}
                  />
                </FormRow>
              </div>
              <div>
                <Text weight='bold'>{t('Country:')}</Text>
                <FormRow>
                  <DropdownField
                    {...getFieldProps('billingCountry')}
                    disabled={billingAddressCheckboxValue.value}
                    label={t('Country')}
                    name='billingCountry'
                    css={widthForAddressFields}
                  >
                    <DropdownField.Option value={CountryCodes.USA.Code}>{CountryCodes.USA.Label}</DropdownField.Option>
                    <DropdownField.Option value={CountryCodes.Canada.Code}>
                      {CountryCodes.Canada.Label}
                    </DropdownField.Option>
                  </DropdownField>
                </FormRow>
              </div>
              <div>
                <Text weight='bold'>{t('State:')}</Text>
                <FormRow>
                  <DropdownField
                    {...getFieldProps('billingState')}
                    placeholder={t('State')}
                    disabled={billingAddressCheckboxValue.value}
                    label={t('State')}
                    name='billingState'
                    css={widthForAddressFields}
                  >
                    {filteredBillingStates.map((state: StateOption) => (
                      <DropdownField.Option value={state.value} key={state.value}>
                        {state.label}
                      </DropdownField.Option>
                    ))}
                  </DropdownField>
                </FormRow>
              </div>
              <div>
                <Text weight='bold'>{t('Postal Code:')}</Text>
                <FormRow>
                  <PostalCodeField
                    {...postalFieldProps}
                    disabled={billingAddressCheckboxValue.value}
                    placeholder={t('Postal Code')}
                    label={t('Postal Code')}
                    name='billingPostalCode'
                    css={widthForAddressFields}
                    locale={localeValue}
                  />
                </FormRow>
              </div>
            </div>
            <Text
              weight='bold'
              css={css`
                margin-left: ${theme.spacing(4.2)};
                margin-bottom: ${theme.spacing(0.8)};
              `}
            >
              {t('Telephone numbers to be ported (xxx-xxx-xxxx format):')}
            </Text>
            <div css={loaContainer}>
              <div css={display}>
                <Text weight='bold' css={widthForField}>
                  {t('Main telephone number:')}
                </Text>
                <FormRow css={widthForField}>
                  <PhoneField
                    css={widthForField}
                    {...getFieldProps('mainTelephoneNumber')}
                    label={t('Add a Telephone number')}
                  />
                </FormRow>
              </div>
              {mainTelephoneNumError && <ErrorComponent message={mainTelephoneNumError} />}
              <div css={display}>
                <Text weight='bold' css={widthForField}>
                  {t('Main Fax number:')}
                </Text>
                <FormRow css={widthForField}>
                  <PhoneField {...getFieldProps('mainFaxNumber')} label={t('Add a Fax number')} />
                </FormRow>
              </div>
              {faxNumError && <ErrorComponent message={faxNumError} />}
              <div css={display}>
                <Text weight='bold' css={widthForField}>
                  {t('Other Telephone numbers')}
                  <Text>{t('(separated by commas):')}</Text>
                </Text>
                <FormRow css={widthForField}>
                  <TextField
                    {...voiceNumbersFieldProps}
                    name='Other Phone number'
                    helperText={t('Ex: 1234567890, (987) 654-3456...')}
                    disabled={disableForPhoneNumber()}
                    label={t('Other Phone number')}
                  />
                </FormRow>
              </div>
              <div css={display}>
                <Text weight='bold' css={widthForField}>
                  {t('Other Fax numbers')}
                  <Text>{t('(separated by commas):')}</Text>
                </Text>
                <FormRow css={widthForField}>
                  <TextField
                    {...faxNumbersFieldProps}
                    name='Other Fax Number'
                    disabled={disableForFaxNumber()}
                    helperText={t('Ex: 1234567890, (987) 654-3456...')}
                    label={t('Other Fax number')}
                  />
                </FormRow>
              </div>
              {!!duplicateValue.length && (
                <ErrorComponent
                  message={t('Please remove the duplicate number {{duplicateNumbers}}', {
                    duplicateNumbers: duplicateValue?.join(', '),
                  })}
                />
              )}
            </div>
            <SignedEditableComponent
              isPdf={isPdf}
              handleDisableButton={handleDisableButton}
              handleSignedData={handleSignedData}
            />
          </form>
        )}
      </div>
      <SuccessModal {...modalProps} />
    </div>
  );
};
const loaContainer = css`
  box-shadow: ${theme.shadows.light};
  margin: ${theme.spacing(3.5)};
  margin-top: 0;
  padding: ${theme.spacing(2.5)};
  border-radius: ${theme.borderRadius.medium};
`;

const addressContainer = css`
  display: flex;
  justify-content: space-evenly;
`;

const display = css`
  display: flex;
`;
const widthForField = css`
  width: 300px;
`;
const pdfViewStyle = css`
  width: 950px;
  border: 1px solid rgba(0, 0, 0, 0.8);
  margin: ${theme.spacing(5)} auto;
  padding: ${theme.spacing(5)};
`;

const viewStyle = css`
  width: 900px;
  box-shadow: ${theme.shadows.heavy};
  padding: ${theme.spacing(2.5)};
  margin: ${theme.spacing(5)} auto;
  border-radius: ${theme.borderRadius.medium};
`;

const textStyle = css`
  margin-bottom: ${theme.spacing(2.5)};
  text-align: justify;
  padding: ${theme.spacing(2.5)};
`;

const widthForAddressFields = css`
  margin-top: ${theme.spacing(1.2)};
  width: 150px;
`;
const headingStyle = css`
  text-align: center;
  margin-bottom: ${theme.spacing(4)};
`;
const displayNone = css`
  display: none;
`;
const loaderStyle = (heightOffset: number) => css`
  width: 100vw;
  height: calc(100vh - ${heightOffset}px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
