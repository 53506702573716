import { CampaignType_Enum, SendTestMessageRequest } from '@weave/schema-gen-ts/dist/schemas/messaging/bulk/v2';
import { SchemaBulkMessagingService } from './service';

// TODO: Add support for attachments
export const sendTestEmail = (orgId: string, subject: string, body: string, to: string) => {
  const email: SendTestMessageRequest = {
    orgId,
    subject,
    destination: to,
    messageTemplate: body,
    type: CampaignType_Enum.EMAIL,
  };
  return SchemaBulkMessagingService.SendTestMessage(email);
};
