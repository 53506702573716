import { PercentCrop } from 'react-image-crop';
import { ComposerSection, ComposerSectionProps } from '../types';

export { getImageCropStyles } from './get-image-crop-styles';

const createCrop = ({ width, height, x, y }: { width: number; height: number; x: number; y: number }): PercentCrop => ({
  unit: '%',
  x,
  y,
  width,
  height,
});

export const defaultCrop = createCrop({ width: 100, height: 100, x: 0, y: 0 });

export const isComposerSection = (part: any): part is ComposerSection<ComposerSectionProps> =>
  (part as ComposerSection<ComposerSectionProps>).craft !== undefined;
