import dayjs from 'dayjs';
import { localStorageHelper } from '@frontend/auth-helpers';
import { sentry } from '@frontend/tracking';
import { LOCAL_STORAGE_KEYS } from './constants';

interface LocalStorageInfo {
  locationId: string;
  userId: string;
}

const getLocalStorageKey = ({ locationId, userId }: LocalStorageInfo) => {
  return `${LOCAL_STORAGE_KEYS.INCOMPLETE_TASKS_INTRO_MODAL_SHOWN}.${locationId}.${userId}`;
};

let isAppRefreshed = true;

export const incompleteTasksIntroModalLocalStorageHelper = {
  setShown: ({ locationId, userId }: LocalStorageInfo) => {
    localStorageHelper.create(getLocalStorageKey({ locationId, userId }), dayjs().format());
  },
  setCustomLocalStorageDate: ({ locationId, userId }: LocalStorageInfo, date: string) => {
    localStorageHelper.create(getLocalStorageKey({ locationId, userId }), date);
  },
  checkIsShown: ({ locationId, userId }: LocalStorageInfo) => {
    const lastShownDate = localStorageHelper.get(getLocalStorageKey({ locationId, userId }));
    if (!lastShownDate) {
      if (isAppRefreshed) {
        incompleteTasksIntroModalLocalStorageHelper.setCustomLocalStorageDate(
          { locationId, userId },
          dayjs().add(-1, 'd').format()
        );
        isAppRefreshed = false;
      }
      return true;
    }

    if (!isAppRefreshed) {
      return true;
    }

    const diffInDays = dayjs().diff(dayjs(lastShownDate), 'day');

    if (isNaN(diffInDays)) {
      console.error('Invalid incomplete tasks intro modal shown date', {
        locationId,
        userId,
        lastShownDate,
      });
      sentry.error({
        error: 'Invalid incomplete tasks intro modal shown date',
        topic: 'onboarding',
        addContext: {
          name: 'user-info',
          context: {
            locationId,
            userId,
            lastShownDate,
          },
        },
      });
      incompleteTasksIntroModalLocalStorageHelper.setShown({ locationId, userId });
      isAppRefreshed = false;
      return true;
    }

    if (diffInDays < 1) {
      isAppRefreshed = false;
      // if shown in last 24 hours, don't show again
      return true;
    }

    return false;
  },
  clearShown: (locationId: string) => {
    // remove all keys for given location id irrespective of user id
    localStorageHelper.deleteWithPrefix(getLocalStorageKey({ locationId, userId: '' }));
  },
};
