import { theme } from '@frontend/theme';
import { useTranslation } from '@frontend/i18n';
import { Bar, ComposedChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { NakedUl } from '@frontend/design-system';
import { css } from '@emotion/react';
import { FirstWordBoldText } from '@frontend/grid-dashboard';

export interface ChartProps {
  sentCount: number;
  scheduledCount: number;
  remainingCount: number;
  onClick: () => void;
}

export const SingleBarChart = (props: ChartProps) => {
  const { onClick, ...otherProps } = props;
  return (
    <ResponsiveContainer width='100%' height={40} style={{ borderRadius: theme.borderRadius.small }}>
      <ComposedChart
        layout='vertical'
        data={[{ sent: props.sentCount, scheduled: props.scheduledCount }]}
        barSize={40}
        margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
        onClick={props.onClick}
      >
        <XAxis type='number' domain={[0, `dataMax + ${props.remainingCount}`]} hide />
        <YAxis type='category' scale='point' hide />
        <Tooltip content={<CustomTooltip {...otherProps} />} />
        <Bar
          radius={[4, 0, 0, 4]}
          dataKey='sent'
          stackId='a'
          fill={theme.colors.primary60}
          background={{ fill: theme.colors.neutral10 }}
        />
        <Bar dataKey='scheduled' stackId='a' fill={theme.colors.secondary.seaweed30} />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export const CustomTooltip = ({ remainingCount, scheduledCount, sentCount }: Omit<ChartProps, 'onClick'>) => {
  const { t } = useTranslation('bulk-messaging');

  return (
    <NakedUl css={tooltipContainerStyle}>
      <FirstWordBoldText as='li'>{t('{{sentCount}} Sent', { sentCount })}</FirstWordBoldText>
      <FirstWordBoldText as='li'>{t('{{scheduledCount}} Scheduled', { scheduledCount })}</FirstWordBoldText>
      <FirstWordBoldText as='li'>{t('{{remainingCount}} Remaining', { remainingCount })}</FirstWordBoldText>
    </NakedUl>
  );
};

const tooltipContainerStyle = css({
  minWidth: 200,
  background: theme.colors.white,
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(2, 3, 3, 3),
  border: `1px solid ${theme.colors.neutral10}`,
  borderRadius: theme.borderRadius.medium,
  boxShadow: theme.shadows.light,
  li: {
    borderBottom: `1px solid ${theme.colors.neutral10}`,
    padding: theme.spacing(1, 0),
    ':first-of-type': {
      strong: {
        color: theme.colors.primary60,
      },
    },
    ':nth-of-type(even)': {
      strong: {
        color: theme.colors.secondary.seaweed30,
      },
    },
    ':last-of-type': {
      strong: {
        color: theme.colors.neutral50,
      },
    },
  },
});
