import { ComponentProps, useCallback } from 'react';
import { Segment } from '@weave/schema-gen-ts/dist/schemas/messaging/bulk/v2';
import { FilterOptions } from '@weave/schema-gen-ts/dist/schemas/messaging/shared/v1/bulk.pb';
import { SegmentSelectionForm } from '../segment-selection-form/segment-selection-form';
import { SegmentsListDisplay } from '../segments-list/segments-list-display';
import { Mode } from '../types';

export const SegmentsModalContent = ({
  accessibleLocationIds,
  campaignId,
  filters,
  isCreatingSegment,
  mode,
  segments,
  setFilters,
  setMode,
}: {
  accessibleLocationIds: string[];
  campaignId: string;
  filters: FilterOptions;
  isCreatingSegment: boolean;
  mode: Mode;
  segments?: Segment[];
  setFilters: (filters: FilterOptions) => void;
  setMode: (mode: Mode) => void;
}) => {
  const onChange: ComponentProps<typeof SegmentSelectionForm>['onChange'] = useCallback((data) => {
    setFilters(data);
  }, []);

  return (
    <div css={{ height: '100%' }}>
      {mode === 'form' ? (
        <SegmentSelectionForm
          accessibleLocationIds={accessibleLocationIds}
          selectedFilters={filters}
          onChange={onChange}
        />
      ) : (
        <SegmentsListDisplay
          campaignId={campaignId}
          isCreatingSegment={isCreatingSegment}
          segments={segments}
          selectedLocationIds={accessibleLocationIds}
          setFilters={setFilters}
          setMode={setMode}
        />
      )}
    </div>
  );
};
