import { i18next } from '@frontend/i18n';
import { IconName } from '@frontend/icons';
import { ChipProps, ChipVariants } from '@frontend/design-system';

export type AppointmentStatusType = {
  key?: string; // Weave status for chip and drop down list
  value?: string; // PMS value for api update
  text: string; // PMS status for drop down list
  iconName: IconName;
  variant: ChipProps['variant'];
  id?: string;
  updatedAt?: string;
};

export enum AppointmentStatusEnum {
  Confirmed = 'confirmed',
  Completed = 'completed',
  InOffice = 'inoffice',
  Attempted = 'attempted',
  Canceled = 'canceled',
  Unconfirmed = 'unconfirmed',
  NoShow = 'noshow',
  Unknown = 'unknown',
}

export const APPOINTMENT_STATUS_MAPPING = new Map<
  AppointmentStatusEnum,
  { variant: ChipVariants; iconName: IconName; text: string }
>([
  [
    AppointmentStatusEnum.Unconfirmed,
    { variant: 'warn', iconName: 'pending-small', text: i18next.t('Unconfirmed', { ns: 'scheduleCalendarEvents' }) },
  ],
  [
    AppointmentStatusEnum.InOffice,
    {
      variant: 'seaweed',
      iconName: 'in-progress-small',
      text: i18next.t('In Office', { ns: 'scheduleCalendarEvents' }),
    },
  ],
  [
    AppointmentStatusEnum.Confirmed,
    {
      variant: 'primary',
      iconName: 'calendar-confirmed-small',
      text: i18next.t('Confirmed', { ns: 'scheduleCalendarEvents' }),
    },
  ],
  [
    AppointmentStatusEnum.Completed,
    { variant: 'success', iconName: 'check-small', text: i18next.t('Completed', { ns: 'scheduleCalendarEvents' }) },
  ],
  [
    AppointmentStatusEnum.Attempted,
    { variant: 'warn', iconName: 'phone-missed-small', text: i18next.t('Attempted', { ns: 'scheduleCalendarEvents' }) },
  ],
  [
    AppointmentStatusEnum.Canceled,
    { variant: 'critical', iconName: 'block-small', text: i18next.t('Cancelled', { ns: 'scheduleCalendarEvents' }) },
  ],
  [
    AppointmentStatusEnum.NoShow,
    { variant: 'critical', iconName: 'user-x-small', text: i18next.t('No Show', { ns: 'scheduleCalendarEvents' }) },
  ],
  [
    AppointmentStatusEnum.Unknown,
    { variant: 'warn', iconName: 'help-small', text: i18next.t('Unknown', { ns: 'scheduleCalendarEvents' }) },
  ],
]);

export enum AppointmentStatusV3Enum {
  UNKNOWN_EVENT_STATUS = 'UNKNOWN_EVENT_STATUS',
  UNCONFIRMED = 'UNCONFIRMED',
  ATTEMPTED = 'ATTEMPTED',
  CONFIRMED = 'CONFIRMED',
  IN_OFFICE = 'IN_OFFICE',
  COMPLETED = 'COMPLETED',
  CANCELED = 'CANCELED',
  NO_SHOW = 'NO_SHOW',
}

export const APPOINTMENT_STATUS_MAPPING_V3 = new Map<
  AppointmentStatusV3Enum,
  { variant: ChipVariants; iconName: IconName; text: string }
>([
  [
    AppointmentStatusV3Enum.UNKNOWN_EVENT_STATUS,
    { variant: 'neutral', iconName: 'info-small', text: i18next.t('Unknown', { ns: 'scheduleCalendarEvents' }) },
  ],
  [
    AppointmentStatusV3Enum.UNCONFIRMED,
    { variant: 'warn', iconName: 'pending-small', text: i18next.t('Unconfirmed', { ns: 'scheduleCalendarEvents' }) },
  ],
  [
    AppointmentStatusV3Enum.ATTEMPTED,
    { variant: 'warn', iconName: 'phone-missed-small', text: i18next.t('Attempted', { ns: 'scheduleCalendarEvents' }) },
  ],
  [
    AppointmentStatusV3Enum.CONFIRMED,
    {
      variant: 'primary',
      iconName: 'calendar-confirmed-small',
      text: i18next.t('Confirmed', { ns: 'scheduleCalendarEvents' }),
    },
  ],
  [
    AppointmentStatusV3Enum.IN_OFFICE,
    {
      variant: 'seaweed',
      iconName: 'in-progress-small',
      text: i18next.t('In Office', { ns: 'scheduleCalendarEvents' }),
    },
  ],
  [
    AppointmentStatusV3Enum.COMPLETED,
    { variant: 'success', iconName: 'check-small', text: i18next.t('Completed', { ns: 'scheduleCalendarEvents' }) },
  ],

  [
    AppointmentStatusV3Enum.CANCELED,
    { variant: 'critical', iconName: 'block-small', text: i18next.t('Cancelled', { ns: 'scheduleCalendarEvents' }) },
  ],
  [
    AppointmentStatusV3Enum.NO_SHOW,
    { variant: 'critical', iconName: 'user-x-small', text: i18next.t('No Show', { ns: 'scheduleCalendarEvents' }) },
  ],
]);
