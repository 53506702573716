import { Permission } from '@weave/schema-gen-ts/dist/shared/waccess/acls.pb';
import { hasSchemaACL } from '@frontend/auth-helpers';
import { useAppScopeStore } from '@frontend/scope';

export const useHasAclAccess = (permissions: Permission[], allACLsAccessRequired?: boolean): boolean => {
  const { selectedLocationIds } = useAppScopeStore();

  const hasAclAccess = selectedLocationIds.some((locationId) => {
    if (allACLsAccessRequired) {
      return permissions.every((permission) => hasSchemaACL(locationId, permission));
    } else {
      return permissions.some((permission) => hasSchemaACL(locationId, permission));
    }
  });

  return hasAclAccess;
};
