import React from 'react';
import { css } from '@emotion/react';
import { AnimatePresence } from 'framer-motion';
import { breakpoints } from '@frontend/responsiveness';
import { Button } from '@frontend/design-system';

interface DismissButtonProps {
  show: boolean;
  onDismiss?: () => void;
}

export const DismissButton: React.FC<DismissButtonProps> = ({ show, onDismiss }) => {
  if (!show) return null;

  return (
    <AnimatePresence mode='popLayout'>
      <Button css={dismissButtonStyle} variant='secondary' iconName='x' onClick={onDismiss} aria-label='Dismiss' />
    </AnimatePresence>
  );
};

const dismissButtonStyle = css`
  @container (max-width: ${breakpoints.small.min}px) {
    position: absolute;
    top: 0;
    right: 0;
  }
`;
