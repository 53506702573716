import {
  Campaign,
  CreateCampaignRequest,
  Status_Enum as CampaignStatus,
  UpdateCampaignRequest,
} from '@weave/schema-gen-ts/dist/schemas/messaging/bulk/v2';
import { DaysOfWeek } from '@weave/schema-gen-ts/dist/schemas/messaging/shared/v1/bulk.pb';
import { BulkMessagingMutations } from '@frontend/api-bulk-messaging';
import { useAppScopeStore } from '@frontend/scope';
import { useFormatDynamicFieldAndLabel } from './use-format-dynamic-field-and-label';

const CAN_SAVE_AS_DRAFT_STATUSES = [
  CampaignStatus.UNSPECIFIED,
  CampaignStatus.DRAFT,
  CampaignStatus.HIDDEN_DRAFT,
  CampaignStatus.SCHEDULED,
];

export const useBulkEmailEditorActions = (isCreate = true, originalCampaign?: Campaign) => {
  const { selectedOrgId } = useAppScopeStore();

  const { mutateAsync: createCampaign } = BulkMessagingMutations.useCreateCampaign();
  const { mutateAsync: updateEmailCampaign } = BulkMessagingMutations.useUpdateCampaign();

  const { convertLabelToDynamicField } = useFormatDynamicFieldAndLabel();

  const saveAs = async (state: Campaign, saveAsStatus?: CampaignStatus) => {
    // The subject field contains readable labels and needs to be converted to dynamic fields so the backend can interpolate the values
    const subject = state.subject ? convertLabelToDynamicField(state.subject) : undefined;

    // The first iteration of attachments will only support one file
    const attachmentMediaIds =
      state.locationIds?.length === 1 && state.attachmentMediaIds?.length
        ? state.attachmentMediaIds.slice(0, 1)
        : undefined;

    const currentStatus =
      saveAsStatus ??
      (state.currentStatus !== CampaignStatus.UNSPECIFIED ? state.currentStatus : CampaignStatus.SCHEDULED);

    const request: CreateCampaignRequest | UpdateCampaignRequest = {
      orgId: selectedOrgId,
      campaign: {
        ...state,
        attachmentMediaIds,
        currentStatus,
        subject,
      },
    };

    const getChangedFields = () => {
      const changedFields = [];
      const newCampaign = request.campaign;
      const newSendDays = newCampaign?.sendDays;
      const originalSendDays = originalCampaign?.sendDays;

      for (const key in newCampaign) {
        const formattedKey = key as keyof Campaign;
        const originalCampaignKey = originalCampaign?.[formattedKey];
        const newCampaignKey = newCampaign[formattedKey];
        switch (formattedKey) {
          case 'title':
            if (!originalCampaignKey || newCampaignKey !== originalCampaignKey) changedFields.push('campaign.title');
            break;
          case 'currentStatus':
            // want to prevent unspecified from being sent to the backend
            if (
              (originalCampaignKey === undefined || newCampaignKey !== originalCampaignKey) &&
              newCampaignKey !== CampaignStatus.UNSPECIFIED
            )
              changedFields.push('campaign.currentStatus');
            break;
          case 'startSendAt':
            if (!originalCampaignKey || newCampaignKey !== originalCampaignKey)
              changedFields.push('campaign.startSendAt');
            break;
          case 'sendPerDay':
            if (originalCampaignKey === undefined || newCampaignKey !== originalCampaignKey)
              changedFields.push('campaign.sendPerDay');
            break;
          case 'sendDays':
            for (const day in newSendDays) {
              const formattedDay = day as keyof DaysOfWeek;
              if (newSendDays[formattedDay] !== originalSendDays?.[formattedDay])
                changedFields.push(`campaign.sendDays.${day}`);
            }
            break;
          case 'subject':
            if (!originalCampaignKey || newCampaignKey !== originalCampaignKey) changedFields.push('campaign.subject');
            break;
          case 'messageTemplate':
            if (!originalCampaignKey || newCampaignKey !== originalCampaignKey)
              changedFields.push('campaign.messageTemplate');
            break;
          case 'templateJson':
            if (!originalCampaignKey || newCampaignKey !== originalCampaignKey)
              changedFields.push('campaign.templateJson');
            break;
          // TODO: wait until the BE has the validation available
          // case 'attachmentMediaIds':
          //   if (!originalCampaignKey ||
          //     newCampaign?.attachmentMediaIds?.sort((a, b) => a.localeCompare(b)).join(',') !== originalCampaign?.attachmentMediaIds?.sort((a, b) => a.localeCompare(b)).join(',')
          //   ) changedFields.push('campaign.attachmentMediaIds');
          //   break;
          default:
            break;
        }
      }

      return changedFields;
    };

    const response =
      isCreate && !request.campaign?.campaignId
        ? await createCampaign(request)
        : await updateEmailCampaign({
            ...request,
            updateMask: {
              paths: getChangedFields(),
              toJSON: () => getChangedFields().join(','),
            },
          });

    if (!response.campaignId) {
      throw new Error('A campaign id was not returned from the server');
    }

    return response.campaignId;
  };

  const canSaveAsDraft =
    isCreate || CAN_SAVE_AS_DRAFT_STATUSES.find((status) => status === originalCampaign?.currentStatus);

  return {
    onSaveDraft: canSaveAsDraft ? (state: Campaign) => saveAs(state, CampaignStatus.DRAFT) : undefined,
    onSaveHiddenDraft: isCreate ? (state: Campaign) => saveAs(state, CampaignStatus.HIDDEN_DRAFT) : undefined,
    onSchedule: (state: Campaign) => saveAs(state, CampaignStatus.SCHEDULED),
  };
};

/*
  These are the different cases for the campaign statuses and the actions that can be taken for each status:
  Status => Actions => Create or Update or N/A

  CampaignStatus_Enum.DELETED => can't access this in the UI => N/A
  
  CampaignStatus_Enum.CANCELED => schedule (update title) => Update
  CampaignStatus_Enum.COMPLETED => schedule (update title) => Update
  CampaignStatus_Enum.FAILED => schedule (update title) => Update
  CampaignStatus_Enum.PROCESSING => schedule (update title) => Update
  CampaignStatus_Enum.SCHEDULED => draft, schedule => Update
  
  CampaignStatus_Enum.DRAFT => draft, schedule => Create
  CampaignStatus_Enum.HIDDEN_DRAFT => draft, schedule => Create
  CampaignStatus_Enum.CAMPAIGN_STATUS_UNSPECIFIED => draft, hidden, schedule => Create (but honestly it should never hit this case)
*/
