import { createShallowStore, createStoreWithSubscribe } from '@frontend/store';

export interface LocationPickerStore {
  isLocationsView: boolean;
  setIsLocationsView: (isLocationsView: boolean) => void;
}

const useLocationPickerStore = createStoreWithSubscribe<LocationPickerStore>(
  (set) => ({
    isLocationsView: false,
    setIsLocationsView: (isLocationsView) => set({ isLocationsView }),
  }),
  { name: 'LocationPickerStore', trace: true }
);

export const useLocationPickerShallowStore = createShallowStore<LocationPickerStore>(useLocationPickerStore);
