import { useEffect } from 'react';
import { FeatureFlagQueries } from '@frontend/api-feature-flags';
import { useTranslation } from '@frontend/i18n';
import { PaymentsFeatureFlags, useMerchant } from '@frontend/payments-hooks';
import { useSelectedInvoice } from '@frontend/payments-invoice-controller';
import { MultiStepModal } from '@frontend/payments-multistep-modal';
import { PaymentsFlowType, StripeElementsWrapper } from '@frontend/payments-stripe-wrapper';
import { Text } from '@frontend/design-system';
import { CardOnFile } from './card-on-file-payment';
import { CollectPaymentModalSteps, useCollectPaymentModal } from './hooks';
import { useTerminalFlags } from './hooks/use-terminal-flags';
import { ManualCardEntry } from './manual-card-payment';
import { PaymentFlowList } from './payment-flow-list';
import {
  CollectionStepReaderSelection,
  CollectionStepReaderPayment,
  SelectedReaderProvider,
  devLogger,
} from './reader-payment';
import {
  SelectedReaderProvider as DeprecatedSelectedReaderProvider,
  CollectReaderPayment,
  ReaderSelection,
} from './reader-payment-deprecated';
import { ShareInMessage } from './share-in-message';

export const CollectPaymentModalBody = () => {
  const { t } = useTranslation('payments');
  const { invoice } = useSelectedInvoice();
  const { stripeLocationId } = useMerchant({ locationId: invoice?.locationId });
  const { aggregateValue: enableACHOnFile } = FeatureFlagQueries.useAggregateFeatureFlagQuery({
    flagName: PaymentsFeatureFlags.ACHOnFile,
    locationIds: [invoice?.locationId ?? ''],
  });

  const { activeStep, setDisableCloseOnOverlayClick } = useCollectPaymentModal();

  useEffect(() => {
    const stepsToDisable = [
      CollectPaymentModalSteps.CollectReaderPayment,
      CollectPaymentModalSteps.CardOnFile,
      CollectPaymentModalSteps.ManualCardEntry,
    ] as string[];

    setDisableCloseOnOverlayClick(!!activeStep && stepsToDisable.includes(activeStep));
  }, [activeStep]);

  return (
    <>
      <MultiStepModal.Step id={CollectPaymentModalSteps.PaymentFlowList} title={t('Collect Payment')}>
        <PaymentFlowList />
      </MultiStepModal.Step>
      {stripeLocationId ? (
        <TerminalStepBody t={t} locationId={invoice?.locationId || ''} />
      ) : (
        <MultiStepModal.Step id={CollectPaymentModalSteps.ReaderSelection} title={t('Pay at Terminal')}>
          <Text>{t('No stripe location')}</Text>
        </MultiStepModal.Step>
      )}
      <MultiStepModal.Step id={CollectPaymentModalSteps.ShareInMessage} title={t('Text to Pay')}>
        <ShareInMessage />
      </MultiStepModal.Step>
      <MultiStepModal.Step
        id={CollectPaymentModalSteps.CardOnFile}
        title={enableACHOnFile ? t('Card/ACH on File') : t('Card on File')}
        hideModalHeader
      >
        <StripeElementsWrapper type={PaymentsFlowType.CardOnFilePayment}>
          <CardOnFile />
        </StripeElementsWrapper>
      </MultiStepModal.Step>
      <MultiStepModal.Step id={CollectPaymentModalSteps.ManualCardEntry} title={t('Manual Card Entry')}>
        <StripeElementsWrapper type={PaymentsFlowType.ManualCardEntry}>
          <ManualCardEntry />
        </StripeElementsWrapper>
      </MultiStepModal.Step>
      <MultiStepModal.Step id={CollectPaymentModalSteps.Sunbit} title={t('Sunbit')}>
        <ShareInMessage isBnplRequest />
      </MultiStepModal.Step>
    </>
  );
};

type TerminalStepBodyProps = {
  t: ReturnType<typeof useTranslation>['t'];
  locationId: string;
};

/**
 * Component allows us to switch out the terminal implementation
 */
const TerminalStepBody = ({ t, locationId }: TerminalStepBodyProps) => {
  const { isNwxPaymentTerminalControllerEnabled } = useTerminalFlags(locationId);

  devLogger.log(
    !isNwxPaymentTerminalControllerEnabled ? 'Using the legacy terminal client' : 'Using the new terminal client'
  );

  return !isNwxPaymentTerminalControllerEnabled ? (
    <DeprecatedSelectedReaderProvider>
      <MultiStepModal.Step id={CollectPaymentModalSteps.ReaderSelection} title={t('Pay at Terminal')}>
        <ReaderSelection />
      </MultiStepModal.Step>
      <MultiStepModal.Step
        id={CollectPaymentModalSteps.CollectReaderPayment}
        title={t('Pay at Terminal')}
        disableCloseOnOverlayClick
        disableCloseOnEscape
      >
        <CollectReaderPayment />
      </MultiStepModal.Step>
    </DeprecatedSelectedReaderProvider>
  ) : (
    <SelectedReaderProvider>
      <MultiStepModal.Step id={CollectPaymentModalSteps.ReaderSelection} title={t('Pay at Terminal')}>
        <CollectionStepReaderSelection />
      </MultiStepModal.Step>
      <MultiStepModal.Step
        id={CollectPaymentModalSteps.CollectReaderPayment}
        title={t('Pay at Terminal')}
        disableCloseOnOverlayClick
        disableCloseOnEscape
      >
        <CollectionStepReaderPayment />
      </MultiStepModal.Step>
    </SelectedReaderProvider>
  );
};
