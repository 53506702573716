import { Corners, FrameAlignment } from '../../types';

type FrameColorStyleProps = {
  frameColor?: string;
};

export const frameColorStyle = (props: FrameColorStyleProps) =>
  props.frameColor ? { backgroundColor: props.frameColor } : {};

type FrameAlignmentStyleProps = {
  frameAlignment?: FrameAlignment;
};

export const frameAlignmentStyle = (props: FrameAlignmentStyleProps) => {
  if (props.frameAlignment === 'left') {
    return { marginRight: 'auto' };
  } else if (props.frameAlignment === 'right') {
    return { marginLeft: 'auto' };
  }
  return { margin: '0 auto' };
};

type HeightStyleProps = {
  height?: string;
};

export const heightStyle = (props: HeightStyleProps) => (props.height ? { height: props.height } : {});

type CornerStyleProps = {
  corners?: Corners;
};

export const cornersStyle = (props: CornerStyleProps) =>
  props.corners
    ? { borderRadius: `${props.corners === 'pill' ? '20px' : props.corners === 'rounded' ? '4px' : 0}` }
    : {};
