import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useSettingsNavigate } from '@frontend/settings-routing';
import { theme } from '@frontend/theme';
import { SecondaryButton } from '@frontend/design-system';
import { PhoneNumbersPageTab, trackingIds } from '../../constants';
import { useCreateOrUpdatePortOrder, usePhoneNumbersPageShallowStore } from '../../hooks';
import { usePortOrderCreationStore } from '../../providers';

export const ActionButtons = () => {
  const { t } = useTranslation('porting');
  const { navigate: settingsNavigate } = useSettingsNavigate();
  const { isPortOrderModified, isSaving } = usePortOrderCreationStore(['isPortOrderModified', 'isSaving']);
  const { createOrUpdatePortOrder } = useCreateOrUpdatePortOrder();
  const { setSelectedTab } = usePhoneNumbersPageShallowStore('setSelectedTab');

  const navigateToPhoneNumbersPage = () => {
    // navigate to phone-numbers page's port request tab
    setSelectedTab(PhoneNumbersPageTab.PortRequests);
    settingsNavigate({ to: '/organization/phone-numbers' });
  };
  const handleSaveAsDraftClick = () => {
    createOrUpdatePortOrder();
  };
  const handleSaveAndExistClick = () => {
    if (!isPortOrderModified) {
      navigateToPhoneNumbersPage();
      return;
    }

    createOrUpdatePortOrder().then(navigateToPhoneNumbersPage);
  };

  return (
    <div css={{ display: 'flex', justifyContent: 'space-between', margin: theme.spacing(2, 1, 1, -1) }}>
      <SecondaryButton
        css={exitButtonStyle}
        onClick={handleSaveAndExistClick}
        trackingId={`${trackingIds.submitPortRequest}_save_and_exit_btn`}
        disabled={isSaving}
      >
        {isPortOrderModified ? t('Save and Exit') : t('Exit')}
      </SecondaryButton>
      <SecondaryButton
        css={{ width: 145 }}
        disabled={!isPortOrderModified || isSaving}
        onClick={handleSaveAsDraftClick}
        trackingId={`${trackingIds.submitPortRequest}_save-as-draft_btn`}
      >
        <Icon name='draft-small' css={{ marginRight: theme.spacing(0.5) }} />
        {t('Save as Draft')}
      </SecondaryButton>
    </div>
  );
};

const exitButtonStyle = css({
  border: 'none',
  maxWidth: 'max-content',
  fontWeight: theme.font.weight.bold,
});
