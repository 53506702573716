import { Responsive } from 'react-grid-layout';
import { getUser, localStorageHelper } from '@frontend/auth-helpers';
import { DashboardBreakpoints, dashboardBreakpoints, tidyDashboardLayout } from '@frontend/grid-dashboard';
import { createProvider } from '@frontend/store';
import { WidgetConfig } from './helpers';

export interface GridDashboard {
  isEditMode: boolean;
  currentBreakpoint: DashboardBreakpoints;
  setCurrentBreakpoint: (breakpoint: DashboardBreakpoints) => void;
  setIsEditMode: (isEditMode: boolean) => void;
  isLibraryOpen: boolean;
  openWidgetLibrary: () => void;
  closeWidgetLibrary: () => void;
}

export const { Provider: GridDashboardProvider, useStore: useGridDashboard } = createProvider<GridDashboard>()(
  (set) => ({
    isEditMode: false,
    isLibraryOpen: false,
    openWidgetLibrary: () => set({ isLibraryOpen: true }),
    closeWidgetLibrary: () => set({ isLibraryOpen: false }),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore Responsive is written in flow, so this type error is incorrect
    currentBreakpoint: Responsive.utils.getBreakpointFromWidth(dashboardBreakpoints, window.innerWidth),
    setIsEditMode: (isEditMode) => set({ isEditMode }),
    setCurrentBreakpoint: (breakpoint) => set({ currentBreakpoint: breakpoint }),
  })
);

export const WIDGET_CONFIGS_KEY = 'widgetConfigs';

export const saveWidgetConfigsToLocalStorage = (widgetConfigs: WidgetConfig[]): void => {
  const user = getUser();
  localStorageHelper.create(`${user?.userID}${WIDGET_CONFIGS_KEY}`, widgetConfigs);
};

export const getWidgetConfigsFromLocalStorage = (): WidgetConfig[] => {
  const user = getUser();
  return localStorageHelper.get(`${user?.userID}${WIDGET_CONFIGS_KEY}`) as WidgetConfig[];
};

export interface WidgetStoreConfig {
  widgetConfigs: WidgetConfig[];
  setWidgetConfigs: (widgetConfigs: WidgetConfig[]) => void;
  tidyLayout: () => void;
}

export const { Provider: WidgetConfigProvider, useStore: useWidgetConfig } = createProvider<WidgetStoreConfig>()(
  (set, get) => ({
    widgetConfigs: getWidgetConfigsFromLocalStorage() ?? [],
    setWidgetConfigs: (widgetConfigs: WidgetConfig[]) => {
      set({ widgetConfigs });
      saveWidgetConfigsToLocalStorage(widgetConfigs);
    },
    tidyLayout: () => {
      const currentConfig = get().widgetConfigs;
      const tidiedWidgetConfigs = tidyDashboardLayout(currentConfig);

      set({ widgetConfigs: tidiedWidgetConfigs });
    },
  })
);
