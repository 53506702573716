import { useMutation, useQueryClient } from 'react-query';
import { PhoneNumberSchemaService, SchemaTenDLCCoordinator } from '@frontend/schema';
import { RegisterPhoneNumberPayload, SetLocationSmsPayload } from './types';

export const usePhoneNumbersTableMutations = (locationIds: string[]) => {
  const queryClient = useQueryClient();

  const register = useMutation({
    mutationFn: (payload: RegisterPhoneNumberPayload) => {
      return SchemaTenDLCCoordinator.RegisterPhoneNumber(payload, {
        headers: {
          'Location-Id': payload.locationId,
        },
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries(['phoneNumbers', locationIds]);
    },
  });

  const setLocationSms = useMutation({
    mutationFn: (payload: SetLocationSmsPayload) => {
      return PhoneNumberSchemaService.SetLocationSms(payload);
    },
    onSettled: () => {
      queryClient.invalidateQueries(['phoneNumbers', locationIds]);
    },
  });

  return {
    register,
    setLocationSms,
  };
};
