import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const tableHeader = ({ shouldHaveMarginTop = true }) =>
  css({
    margin: theme.spacing(shouldHaveMarginTop ? 4 : 0, 0, 3, 0),
  });

export const primaryButton = css({
  width: 'fit-content',
  float: 'right',
  margin: theme.spacing(-1, 0, 4, 0),
});

export const maxWidth = css({
  width: '100%',
  maxWidth: '720px',
});

export const addAppointmentText = css({
  margin: theme.spacing(2),
});

export const noApptTypesImg = css({
  width: '500px',
  marginBottom: theme.spacing(5),
  marginTop: theme.spacing(2),
});

export const noApptTypesAddButton = css({
  marginRight: theme.spacing(1),
  width: 'fit-content',
  maxWidth: '300px',
});

export const addNewButton = css({
  marginRight: theme.spacing(1),
});
