import { IconName } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { BannerType } from './types';

export const BANNER_BACKGROUNDS = {
  error: theme.colors.critical5,
  warning: theme.colors.warning5,
  success: theme.colors.success5,
  info: theme.colors.primary5,
} as const;

export const BANNER_ICONS: Record<BannerType, IconName> = {
  error: 'error-badge',
  warning: 'warning-badge',
  success: 'confirm-badge',
  info: 'info-badge',
} as const;

export const BANNER_ICON_COLORS = {
  error: theme.font.colors.error,
  warning: theme.font.colors.warn,
  success: theme.font.colors.success,
  info: theme.font.colors.primary,
} as const;
