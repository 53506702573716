import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

interface IModalContainerStyleConfig {
  minWidth?: string;
  maxWidth?: string;
}

export const modalContainerStyle = ({ minWidth, maxWidth }: IModalContainerStyleConfig) => {
  const styles = [
    css`
      padding: ${theme.spacing(1, 0, 0, 0)} !important;
      width: min(45%, 100vw) !important; // Change for Mobile
    `,
  ];

  if (minWidth) {
    styles.push(css`
      min-width: min(${minWidth}, 100vw) !important;
    `);
  }

  if (maxWidth) {
    styles.push(css`
      max-width: min(${maxWidth}, 100vw) !important;
    `);
  }

  return styles;
};

export const modalHeaderStyle = css`
  display: flex;
  text-align: left !important;
  align-items: center;
  padding: ${theme.spacing(2, 3)};
  border-bottom: 1px solid ${theme.colors.neutral10};
  .header-title {
    margin-left: ${theme.spacing(2)};
  }

  .action-btns {
    display: flex;
    gap: ${theme.spacing(1)};
    margin-left: auto;
  }

  h1,
  h2,
  p {
    margin: 0;
  }
`;

export const headerHrStyle = css`
  width: 100%;
  border: none;
  border-top: 1px solid ${theme.colors.neutral10};
  height: 1px;
`;

export const modalBodyStyle = css`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 0;
`;

export const modalBodyContainerStyle = css`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const modalFooterStyle = css`
  display: flex;
  border-top: 1px solid ${theme.colors.neutral10};
  padding: ${theme.spacing(2, 3)};
  border-bottom-right-radius: ${theme.borderRadius.medium};
  border-bottom-left-radius: ${theme.borderRadius.medium};
  justify-content: flex-end;
  gap: ${theme.spacing(2)};

  button {
    padding-left: ${theme.spacing(2)};
    padding-right: ${theme.spacing(2)};
  }
`;

export const headerTitleWrapperStyle = css`
  display: flex;
`;
