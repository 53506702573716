import { InvoiceModel, InvoiceStatus } from '@frontend/api-invoices';
import { i18next } from '@frontend/i18n';

export const isSuccessfullyPaidInvoice = (invoice: InvoiceModel) =>
  invoice.payment &&
  (invoice.status === InvoiceStatus.Paid ||
    invoice.status === InvoiceStatus.PartiallyPaid ||
    invoice.payment?.status === 'SUCCEEDED');

export const isPaidInvoice = (invoice: InvoiceModel) =>
  isSuccessfullyPaidInvoice(invoice) || invoice.payment?.status === 'PENDING';

export const accountHolderName = (invoice: InvoiceModel) =>
  invoice.payment?.paymentDetails.cardholderName || invoice.payment?.paymentDetails.bankAccountHolderName;

export const isCanceledInvoice = (invoice: InvoiceModel) => invoice.status === InvoiceStatus.Canceled;

export const accountHolderLabel = (invoice: InvoiceModel) => {
  const type = invoice.payment?.paymentDetails?.bankAccountHolderName
    ? i18next.t('Account', { ns: 'payments' })
    : i18next.t('Card', { ns: 'payments' });
  const holderText = i18next.t('Holder', { ns: 'payments' });
  return `${type} ${holderText}`;
};
