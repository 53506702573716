import { IntakeFormApi } from '@frontend/api-intake-form';
import { CountryCodes } from '@frontend/geography';
import { i18next } from '@frontend/i18n';
import { ValidatorFn } from '@frontend/design-system';
import { useAddressForm } from './hooks';
import { AddressInfo, AddressInformationType, SmartyStreetsAddressPrecision } from './types';

const alphaNumericRegex = /^[a-zA-Z0-9]*$/;
export const isAlphaNumericValue = (value: string): boolean => alphaNumericRegex.test(value);

// TODO: confirm about this custom function instead of using query-string package
// Also need to confirm about way of handling string, null and undefined here
export const stringifyObject = (data: Record<string, any>) => {
  return new URLSearchParams(data).toString();
};

export const convertFormValuesToAddressInfo = (
  address: ReturnType<typeof useAddressForm>['values'],
  verified: boolean
): AddressInfo => ({
  address1: address.address1 ?? '',
  address2: address.address2 ?? '',
  city: address.city ?? '',
  state: address.state ?? '',
  postal: address.postal ?? '',
  country: (address.country as CountryCodes) ?? CountryCodes.USA,
  verified,
});

export const verifyAddress = async (values: ReturnType<typeof useAddressForm>['addressFormProps']['values']) => {
  const addressFields = stringifyObject({
    address1: values.address1,
    address2: values.address2,
    locality: values.city,
    administrative_area: values.state,
    postal_code: values.postal,
    country: values.country,
  });
  const data = (await IntakeFormApi.getVerifiedAddress<AddressInformationType>(addressFields)) ?? [];

  const result: AddressInfo[] = [];
  data.forEach(({ analysis, components }) => {
    const precision = analysis?.address_precision;
    if (
      precision === SmartyStreetsAddressPrecision.deliveryPoint ||
      precision === SmartyStreetsAddressPrecision.premise ||
      precision === SmartyStreetsAddressPrecision.thoroughfare
    ) {
      result.push({
        address1: `${components.premise ?? ''} ${components.thoroughfare ?? ''}`,
        address2: `${components.sub_building_type ?? ''} ${components.sub_building_number ?? ''}`.trim(),
        city: components.locality ?? '',
        state: components.administrative_area ?? '',
        country: (values.country as CountryCodes) ?? CountryCodes.USA,
        postal: `${components.postal_code_short ?? ''} ${components.postal_code_extra ?? ''}`.trim(),
        verified: true,
      });
    }
  });
  return result;
};

export const validateUserName: ValidatorFn<'text'> = ({ value }) => {
  // Check if text contains two parts (first and last name) by checking if the trimmed
  // text contains a space character somewhere in between (and not as the last char).
  const text = value.trim();
  const isValid = text.indexOf(' ') !== -1 && text.lastIndexOf(' ') < text.length - 1;

  if (!isValid) {
    return i18next.t('Must include first and last name', { ns: 'phoneProviderInformationForm' });
  }
  return '';
};

export const validateAccountPin: ValidatorFn<'text'> = ({ value }) => {
  if (!value) return '';

  if (value.length < 3) {
    return i18next.t('Must be at least 3 characters', { ns: 'phoneProviderInformationForm' });
  }
  if (value.length > 16) {
    return i18next.t("Can't be more than 16 characters", { ns: 'phoneProviderInformationForm' });
  }

  if (!isAlphaNumericValue(value)) {
    return i18next.t('Must be alphanumeric', { ns: 'phoneProviderInformationForm' });
  }
  return '';
};
