export enum PetImageType {
  Dog = 'dog',
  Cat = 'cat',
  Reptile = 'reptile',
  Bird = 'bird',
  Chameleon = 'chameleon',
  Mouse = 'mouse',
  LargeMammal = 'largeMammal',
  SmallMammal = 'smallMammal',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  Default = 'dog', // Default to dog, lint rule intentionally ignored
  Horse = 'horse',
}

export type Pet = {
  petId: string;
  name: string;
  birthdate: string;
  breed: string;
  gender: string;
  classification: string;
  color: string;
  imageType: PetImageType;
  serviceableId?: string;
};

export type PetExtended = Pet & {
  prevAppointment: PetAppointment;
  nextAppointment: PetAppointment;
  vaccines: PetVaccine[];
};

export type PetAppointment = {
  appointmentId: string;
  date: string;
  length: string;
  status: string;
  pmsStatus: string;
  type: string;
  practitioner: string;
  entryDate: string;
  cancelDate: string;
  amount: string;
};

export type PetVaccine = {
  eventId: string;
  eventTime: string;
  description: string;
};
