import { SegmentedBarTypes } from '@frontend/segmented-bar';
import { LocationUsageAndAllotmentWithLimit } from '../type';
import { CardBody } from './card-body';
import { CardFooter } from './card-footer';
import { CardHeader } from './card-header';

type Props = LocationUsageAndAllotmentWithLimit & {
  contactCount: number;
  items: SegmentedBarTypes.Data[];
  setData: (value: number) => void;
};

export const Card = (data: Props) => (
  <div>
    <CardHeader locationId={data.locationId} />
    <CardBody {...data} />
    <CardFooter contactCount={data.contactCount} />
  </div>
);
