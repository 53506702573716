import { ComponentProps, useEffect } from 'react';
import { css } from '@emotion/react';
import { Setting } from '@frontend/content-composer-settings';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { RadioField } from '@frontend/design-system';
import { useComposerSection } from '../../../hooks';
import { getRadioOptionStyling, overridingRadioGroupStyling, roundedStyling } from '../../../styles';
import { ImageCount, ImageSectionProps } from '../../../types';

type Props = ComponentProps<typeof RadioField> & {
  isLast?: boolean;
};

// TODO: Refactor this file to be moar sexy!
export const CountSetting = ({ isLast, ...fieldProps }: Props) => {
  const { t } = useTranslation('content-composer');

  const { setProps } = useComposerSection<ImageSectionProps>();

  useEffect(() => {
    setProps((props) => {
      props.count = fieldProps.value as ImageCount;

      const newImages = props.images || [];
      const count = parseInt(fieldProps.value);
      switch (count) {
        case 1:
          newImages.splice(1);
          break;
        case 2:
          newImages.splice(2);
          break;
        case 3:
        default:
          break;
      }

      props.images = newImages;
    });
  }, [fieldProps.value]);

  return (
    <Setting title={t('Layout')} isLast={isLast}>
      <RadioField {...fieldProps} css={overridingRadioGroupStyling}>
        <RadioField.Radio value='1' className='hide-radio' css={getRadioOptionStyling(fieldProps.value === '1')}>
          <div css={roundedStyling}></div>
        </RadioField.Radio>
        <RadioField.Radio value='2' className='hide-radio' css={getRadioOptionStyling(fieldProps.value === '2')}>
          <div
            css={css`
              display: flex;
            `}
          >
            <div css={layoutCardStyling}></div>
            <div css={layoutCardStyling}></div>
          </div>
        </RadioField.Radio>
        <RadioField.Radio value='3' className='hide-radio' css={getRadioOptionStyling(fieldProps.value === '3')}>
          <div
            css={css`
              display: flex;
            `}
          >
            <div css={layoutCardStyling}></div>
            <div css={layoutCardStyling}></div>
            <div css={layoutCardStyling}></div>
          </div>
        </RadioField.Radio>
      </RadioField>
    </Setting>
  );
};

const layoutCardStyling = css`
  width: ${theme.spacing(1.75)};
  height: ${theme.spacing(2.5)};
  background: ${theme.colors.neutral90};
  border-radius: ${theme.borderRadius.small};
  &:not(:last-of-type) {
    margin-right: ${theme.spacing(0.5)};
  }
`;
