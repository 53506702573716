export const WeaveLogoSvg = ({ fillColor }: { fillColor?: string }) => (
  <svg
    height={24}
    width={24}
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    version='1.1'
    baseProfile='full'
  >
    <path
      fill={fillColor}
      fillRule='evenodd'
      d='M11.235 11.201v2.782c-.003 2.985 2.469 5.429 5.574 5.51 2.936-.001 5.386-2.158 5.644-4.97.045-.413.061-1.473.061-1.89 0-.418-.016-1.478-.061-1.892-.167-1.966-1.43-3.69-3.3-4.504a5.842 5.842 0 0 0-5.699.585.14.14 0 0 0 0 .182l1.184 1.888a.119.119 0 0 0 .177.019 3.21 3.21 0 0 1 3.11-.511c1.056.385 1.812 1.29 1.974 2.365.063.617.09 1.236.08 1.855.01.613-.016 1.226-.08 1.836-.214 1.478-1.528 2.578-3.08 2.58a3.076 3.076 0 0 1-2.136-.896 2.935 2.935 0 0 1-.881-2.092v-2.782c0-2.99-2.48-5.434-5.59-5.508-2.94.004-5.391 2.167-5.644 4.983-.045.41-.061 1.471-.061 1.888 0 .418 0 1.478.06 1.892.168 1.967 1.433 3.691 3.304 4.504a5.842 5.842 0 0 0 5.702-.591.14.14 0 0 0 0-.183l-1.184-1.888a.119.119 0 0 0-.177-.019 3.21 3.21 0 0 1-3.11.511c-1.056-.385-1.812-1.29-1.974-2.365a16.081 16.081 0 0 1-.078-1.855c-.01-.612.015-1.226.078-1.835.214-1.478 1.528-2.579 3.08-2.581a3.075 3.075 0 0 1 2.136.896 2.93 2.93 0 0 1 .881 2.092l.01-.006'
      clipRule='evenodd'
    ></path>
  </svg>
);
