import { useTranslation } from '@frontend/i18n';
import { BulkEmailPrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import { ButtonBar, PrimaryButton, SecondaryButton } from '@frontend/design-system';

type Props = {
  onSave: () => void;
  onBack: () => void;
};

export const TrayFooter = ({ onSave, onBack }: Props) => {
  const { t } = useTranslation('bulk-messaging');
  return (
    <footer>
      <ButtonBar
        css={{
          justifyContent: 'flex-end',
          marginTop: theme.spacing(2),
          padding: 0,
        }}
      >
        <SecondaryButton
          onClick={onBack}
          css={buttonStyle}
          trackingId={`${BulkEmailPrefixes.Audience}-allotments-filter-back-btn`}
        >
          {t('Back')}
        </SecondaryButton>
        <PrimaryButton
          onClick={onSave}
          css={buttonStyle}
          trackingId={`${BulkEmailPrefixes.Audience}-allotments-filter-btn`}
        >
          {t('Apply')}
        </PrimaryButton>
      </ButtonBar>
    </footer>
  );
};

const buttonStyle = { maxWidth: 'fit-content' };
