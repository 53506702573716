import { useEffect } from 'react';
import { css } from '@emotion/react';
import { FeedbackType } from '@weave/schema-gen-ts/dist/shared/call-intelligence/enums.pb';
import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { CallIntelMutations } from '@frontend/api-call-intel';
import { getUser, isWeaveUser } from '@frontend/auth-helpers';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { StarIcon, TextLink, useModalControl, useAlert, useTooltip } from '@frontend/design-system';
import { trackingIds } from '../tracking-ids';
import { Feedback, FeedbackModal } from '.';

type Props = {
  productCode: Feature;
  showModal?: boolean;
  trackingId?: string;
};

export const FeedbackButton = ({ productCode, showModal = false, trackingId }: Props) => {
  const { t } = useTranslation('analytics');
  const alert = useAlert();
  const { modalProps, triggerProps, openModal } = useModalControl();
  const { selectedLocationIds } = useAppScopeStore();
  const { tooltipProps, triggerProps: toolTipTriggerProps, Tooltip } = useTooltip();

  const { isLoading, mutateAsync: submitFeedback } = CallIntelMutations.useSubmitFeedback({
    onSuccess: () => {
      alert.success(t('Feedback submitted. Thank you!'));
      modalProps.onClose();
    },
    onError: (err) => {
      alert.error(t('Error submitting feedback. Please try again.'));
      console.error('Error submitting feedback', err);
    },
  });

  const productDetails = () => {
    if (productCode === Feature.CALL_INTELLIGENCE) {
      return {
        feedbackId: 'call-intel-2-dot-0-feedback',
        name: t('Call Intelligence'),
      };
    }

    return {
      feedbackId: '',
      name: '',
    };
  };

  const handleSubmit = (feedback: Feedback) => {
    if (selectedLocationIds.length === 0) {
      alert.error(t('Error submitting feedback. Please try again.'));
      return;
    }

    submitFeedback({
      rating: feedback.rating,
      comment: feedback.message,
      allowWeaveToContact: feedback.allowWeaveToContact,
      userId: getUser()?.userID || '',
      feedbackType: FeedbackType.FEEDBACK_GENERAL,
      locationId: selectedLocationIds[0],
    });
  };

  useEffect(() => {
    if (showModal) openModal();
  }, [showModal]);

  return (
    <>
      <div {...toolTipTriggerProps}>
        <TextLink {...triggerProps} disabled={isWeaveUser()} css={styles.button} trackingId={trackingId} weight='bold'>
          <StarIcon size={16} />
          {t('Submit Feedback')}
        </TextLink>
        {isWeaveUser() && (
          <Tooltip {...tooltipProps}>
            {t(
              'This feature is intended solely for Weave customers to provide feedback on AI outputs perceived as wrongly classified. If you are not a Weave customer, we advise you to skip this module.'
            )}
          </Tooltip>
        )}
      </div>
      <FeedbackModal
        isLoading={isLoading}
        modalProps={modalProps}
        productName={productDetails().name}
        onSubmit={handleSubmit}
        trackingIdBase={trackingIds.callIntel.generalFeedback}
      />
    </>
  );
};

const styles = {
  button: css`
    align-items: center;
    display: flex;
    gap: ${theme.spacing(0.5)};
  `,
};
