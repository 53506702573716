import { Media } from '@weave/schema-gen-ts/dist/schemas/messaging/media/public/v1/service.pb';
import { theme } from '@frontend/theme';
import { useFormField, useTabFilter } from '@frontend/design-system';
// import { BrandGalleryPanel } from './brand-gallery-panel';
import { StockImageGalleryPanel } from './stock-image-gallery-panel';
import { LocationIdsFieldProps, SearchFieldProps } from './types';
import { YourImageGalleryPanel } from './your-image-gallery-panel';

export const ImageGallery = ({
  locationIds,
  handleImageSelect,
  imageSize,
  onDoubleClick,
  selectedImages,
  trackingId,
  ...rest
}: {
  locationIds: string[];
  handleImageSelect: (media?: Media) => void;
  imageSize?: 'small' | 'large';
  onDoubleClick?: () => void;
  selectedImages: (Media | undefined)[];
  trackingId: string;
}) => {
  const { activeTab, getTabProps, filterProps, Tab, TabFilter } = useTabFilter('yours');

  // TODO: once the API is ready, uncomment the tab and BrandGalleryPanel
  const tabs = [
    { label: 'Your Images', id: 'yours' },
    // { label: 'Brand', id: 'brand' },
    { label: 'Find Stock Images', id: 'stock' },
  ];

  const locationIdsFieldProps = useFormField({ type: 'checklist', value: locationIds }, [
    [...locationIds]
      .sort((a, b) => {
        return a > b ? 1 : -1;
      })
      .join(','),
  ]);
  const searchFieldProps = useFormField({ type: 'text' });

  const sharedProps = {
    handleImageSelect,
    imageSize,
    locationIds,
    locationIdsFieldProps: { ...locationIdsFieldProps, name: 'locationIds' } as LocationIdsFieldProps,
    onDoubleClick,
    searchFieldProps: { ...searchFieldProps, name: 'search' } as SearchFieldProps,
    selectedImages,
    trackingId,
  };

  return (
    <div {...rest}>
      <TabFilter
        {...filterProps}
        css={{
          height: theme.spacing(5),
          minHeight: 'unset',
          button: {
            flexBasis: 'unset',
            minWidth: 'unset',
          },
          '&:after': {
            display: 'none',
          },
        }}
      >
        {tabs.map(({ label, id }, index) => (
          <Tab
            key={id}
            {...getTabProps(id)}
            css={{
              background: theme.colors.white,
              borderBottom: activeTab === id ? `1px solid ${theme.colors.primary50}` : 'none',
              borderTopLeftRadius: index === 0 ? theme.borderRadius.medium : 0,
              borderTopRightRadius: index === tabs.length - 1 ? theme.borderRadius.medium : 0,
              padding: theme.spacing(1, 2),
              '&[aria-selected="true"]': {
                color: theme.colors.neutral90,
              },
              '&:focus, &:hover': {
                background: theme.colors.white,
              },
            }}
            trackingId={trackingId + '-' + id + '-image-tab'}
          >
            {label}
          </Tab>
        ))}
      </TabFilter>
      {activeTab === 'yours' && <YourImageGalleryPanel {...sharedProps} />}
      {/* {activeTab === 'brand' && <BrandGalleryPanel {...sharedProps} />} */}
      {activeTab === 'stock' && <StockImageGalleryPanel {...sharedProps} />}
    </div>
  );
};
