import { generateCommonTrackingIds } from './tracking';

const autoRecallAnalytics = 'auto-recall-analytics';
const appointmentAnalytics = 'appointment-analytics';
export const callIntel = 'call-intel';
const messagingAnalytics = 'messaging-analytics';
const missedCallText = 'missed-call-text';
const phoneAnalytics = 'phone-analytics';
const practiceAnalytics = 'practice-analytics';
const practiceAnalyticsPLG = 'practice-analytics-plg';
const subscriptions = 'subscriptions';

export const trackingIds = {
  analytics: {
    dashboardTimeFilter: `dashboard-time-filter`,
    exploreAnalytics: `explore-analytics`,
    showMoreAppointmentAnalytics: `show-more-appointment-analytics`,
    showMoreAutoRecallAnalytics: `show-more-auto-recall-analytics`,
    showMoreMessagingAnalytics: `show-more-messaging-analytics`,
    showMorePhoneAnalytics: `show-more-phone-analytics`,
    showMorePracticeAnalytics: `show-more-practice-analytics`,
  },
  appointmentAnalytics: {
    ...generateCommonTrackingIds(appointmentAnalytics),
  },
  autoRecallAnalytics: {
    ...generateCommonTrackingIds(autoRecallAnalytics),
    notReceivedTab: `${autoRecallAnalytics}-not-received-tab`,
    notScheduledTab: `${autoRecallAnalytics}-not-scheduled-tab`,
    scheduledTab: `${autoRecallAnalytics}-scheduled-tab`,
    timePeriodOnTrayFilter: `${autoRecallAnalytics}-time-period-on-tray-filter`,
  },
  callIntel: {
    ...generateCommonTrackingIds(callIntel),
    accurateCallAnalysisFeedback: `${callIntel}-accurate-call-analysis-feedback`,
    aiAdditionalFeedback: `${callIntel}-ai-additional-feedback`,
    allCallRecordingsSearch: `${callIntel}-all-call-recordings-search`,
    applyExploreCharts: `${callIntel}-apply-explore-charts`,
    applyLocationFilter: `${callIntel}-apply-location-filter`,
    appointmentTypesFilter: `${callIntel}-appointment-types-filter`,
    callAnalysisAccordion: `${callIntel}-analysis-accordion`,
    callDetailsAccordion: `${callIntel}-details-accordion`,
    callDirectionFilter: `${callIntel}-call-direction-filter`,
    callSummaryAccordion: `${callIntel}-summary-accordion`,
    callTaskAccordion: `${callIntel}-task-accordion`,
    cancelEditingAIRating: `${callIntel}-cancel-editing-ai-rating`,
    cancelExploreCharts: `${callIntel}-cancel-explore-charts`,
    categoriesFilter: `${callIntel}-categories-filter`,
    changeCallAnalysisRating: `${callIntel}-change-call-analysis-rating`,
    closeExploreLocations: `${callIntel}-close-explore-locations`,
    compareLocationsAppointments: `${callIntel}-compare-locations-appointments`,
    compareLocationsCategories: `${callIntel}-compare-locations-categories`,
    compareLocationsViewAllFollowUps: `${callIntel}-compare-locations-view-all-follow-ups`,
    compareLocationsTaskLocationCardClick: `${callIntel}-compare-locations-task-location-card-click`,
    compareLocationsSentiments: `${callIntel}-compare-locations-sentiments`,
    contactTypesSelector: `${callIntel}-contact-types-selector`,
    contactTypesFilter: `${callIntel}-contact-types-filter`,
    customerSentimentFilter: `${callIntel}-customer-sentiment-filter`,
    demoDataBannerSelfSubscribe: `${callIntel}-demo-data-banner-self-subscribe`,
    exploreLocationData: `${callIntel}-explore-location-data`,
    exploreLocationsRadio: `${callIntel}-explore-locations-radio`,
    followUpPageAccordion: `${callIntel}-follow-up-page-accordion`,
    followUpSortFilter: `${callIntel}-follow-up-sort-filter`,
    followUpPageAccordionViewBtn: `${callIntel}-follow-up-page-accordion-view-btn`,
    followUpSummaryViewAllBtn: `${callIntel}-follow-up-summary-view-all-btn`,
    followUpSummaryViewListBtn: `${callIntel}-follow-up-summary-view-list-btn`,
    followUpSummaryRowClick: `${callIntel}-follow-up-summary-row-click`,
    followUpPageSearch: `${callIntel}-follow-ups-page-search`,
    followUpTaskCardStatusSwitcher: `${callIntel}-follow-up-task-card-status-switcher`,
    generalFeedback: `${callIntel}-general-feedback`,
    generalFeedbackDrillDownPage: `${callIntel}-general-feedback-drill-down-page`,
    generalFeedbackFollowUpsPage: `${callIntel}-general-feedback-follow-ups-page`,
    generalFeedbackMainPage: `${callIntel}-general-feedback-main-page`,
    hideNonAnalyzedCallsFilter: `${callIntel}-hide-non-analyzed-calls-filter`,
    inaccurateCallAnalysisFeedback: `${callIntel}-inaccurate-call-analysis-feedback`,
    locationsDrillDownTab: `${callIntel}-locations-drill-down-tab`,
    locationsFilter: `${callIntel}-locations-filter`,
    locationsSelector: `${callIntel}-locations-selector`,
    officeNumbersFilter: `${callIntel}-office-numbers-filter`,
    officeUsersFilter: `${callIntel}-office-users-filter`,
    officeUsersSelector: `${callIntel}-office-users-selector`,
    openCallTakeAwayTray: `${callIntel}-open-call-take-away-tray`,
    overviewDrillDownTab: `${callIntel}-overview-drill-down-tab`,
    overviewTab: `${callIntel}-overview-tab`,
    recordsTab: `${callIntel}-records-tab`,
    schedulingOpportunitiesFilter: `${callIntel}-scheduling-opportunities-filter`,
    schedulingOpportunitiesIdentifiedFilter: `${callIntel}-schedule-opportunities-identified-filter`,
    schedulingOutcomesFilter: `${callIntel}-scheduling-outcomes-filter`,
    serviceQualityAccordion: `${callIntel}-service-quality-accordion`,
    serviceQualityTab: `${callIntel}-service-quality-tab`,
    showFullTranscript: `${callIntel}-show-full-transcript`,
    showTranscriptSummary: `${callIntel}-show-transcript-summary`,
    showMoreFollowUpListAcrossLocations: `${callIntel}-show-more-follow-up-list-across-locations`,
    sortFilterCallCountHL: `${callIntel}-sort-filter-call-count-high-to-low`,
    sortFilterCallCountLH: `${callIntel}-sort-filter-call-count-low-to-high`,
    sortFilterLastCallLatest: `${callIntel}-sort-filter-last-call-latest`,
    sortFilterLastCallOldest: `${callIntel}-sort-filter-last-call-oldest`,
    taskLinkAllCallRecordings: `${callIntel}-task-link-all-call-recordings`,
    taskCardOpen: `${callIntel}-task-card-open`,
    taskCardStatusSwitcher: `${callIntel}-task-card-status-switcher`,
    taskModalStatusSwitcher: `${callIntel}-task-modal-status-switcher`,
    taskModalDeleteBtn: `${callIntel}-task-modal-delete-button`,
    taskModalPaginateLeft: `${callIntel}-task-modal-paginate-left`,
    taskModalPaginateRight: `${callIntel}-task-modal-paginate-right`,
    taskModalConfirmDelete: `${callIntel}-task-modal-confirm-delete`,
    taskStatusTodo: `${callIntel}-task-status-todo`,
    taskStatusInProgress: `${callIntel}-task-status-in-progress`,
    taskStatusCompleted: `${callIntel}-task-status-completed`,
    timeFilter: `${callIntel}-time-filter`,
    timeSelector: `${callIntel}-time-selector`,
  },
  messagingAnalytics: {
    ...generateCommonTrackingIds(messagingAnalytics),
    exportTableData: `${messagingAnalytics}-export-table-data`,
    exportTableDataCancel: `${messagingAnalytics}-export-table-data-cancel`,
    exportTableDataConfirm: `${messagingAnalytics}-export-table-data-confirm`,
    timePeriodOnTrayFilter: `${messagingAnalytics}-time-period-on-tray-filter`,
  },
  phoneAnalytics: {
    ...generateCommonTrackingIds(phoneAnalytics),
    callAnalyticsPDFExport: `${phoneAnalytics}-call-analytics-pdf-export`,
    callAnalyticsTab: `${phoneAnalytics}-call-analytics-tab`,
    deviceExtensionsCallsAnswered: `${phoneAnalytics}-device-extensions-calls-answered`,
    deviceExtensionsCallsPlaced: `${phoneAnalytics}-device-extensions-calls-placed`,
    deviceExtensionsFilter: `${phoneAnalytics}-device-extensions-filter`,
    durationAnalyticsPDFExport: `${phoneAnalytics}-duration-analytics-pdf-export`,
    durationAnalyticsTab: `${phoneAnalytics}-duration-analytics-tab`,
    exportTableData: `${phoneAnalytics}-export-table-data`,
    exportTableDataCancel: `${phoneAnalytics}-export-table-data-cancel`,
    exportTableDataConfirm: `${phoneAnalytics}-export-table-data-confirm`,
    timePeriodOnTrayFilter: `${phoneAnalytics}-time-period-on-tray-filter`,
  },
  practiceAnalytics: {
    ...generateCommonTrackingIds(practiceAnalytics),
    activePatientsChart: `${practiceAnalytics}-active-patients-chart`,
    activePatientsScheduledChart: `${practiceAnalytics}-active-patients-scheduled-chart`,
    activePatientsTrend: `${practiceAnalytics}-active-patients-trend`,
    activeScheduledPatientsTrend: `${practiceAnalytics}-active-scheduled-patients-trend`,
    addMHUserEmail: `${practiceAnalytics}-add-morning-huddle-user-email`,
    allActivePatientsTab: `${practiceAnalytics}-all-active-patients-tab`,
    allUnscheduledPatientsTab: `${practiceAnalytics}-all-unscheduled-patients-tab`,
    appointmentStatusOpportunityFilter: `${practiceAnalytics}-appointment-status-opportunity-filter`,
    bulkMessagingNoWaitlist: `${practiceAnalytics}-bulk-messaging-no-waitlist`,
    cancelBulkMessage: `${practiceAnalytics}-cancel-bulk-message`,
    cancellationsAllTab: `${practiceAnalytics}-cancellations-all-tab`,
    cancellationsChart: `${practiceAnalytics}-cancellations-chart`,
    cancellationsHygieneTab: `${practiceAnalytics}-cancellations-hygiene-tab`,
    cancellationsRestorativeTab: `${practiceAnalytics}-cancellations-restorative-tab`,
    cancellationsTrend: `${practiceAnalytics}-cancellations-trend`,
    confirmBulkMessage: `${practiceAnalytics}-confirm-bulk-message`,
    deleteMHUserEmail: `${practiceAnalytics}-delete-morning-huddle-user-email`,
    deleteMHUserEmailConfirm: `${practiceAnalytics}-delete-morning-huddle-user-email-confirm`,
    downloadPatientsByInsurance: `${practiceAnalytics}-download-patients-by-insurance`,
    editMHUsersButton: `${practiceAnalytics}-edit-morning-huddle-users-button`,
    exportPractitionerAnalysisPatients: `${practiceAnalytics}-export-practitioner-analysis-patients`,
    hygieneFollowUpAllTab: `${practiceAnalytics}-hygiene-follow-up-all-tab`,
    hygieneFollowUpChart: `${practiceAnalytics}-hygiene-follow-up-chart`,
    hygieneFollowUpSameDayTab: `${practiceAnalytics}-hygiene-follow-up-same-day-tab`,
    hygieneFollowUpTrend: `${practiceAnalytics}-hygiene-follow-up-trend`,
    hygieneFollowUpUnscheduledTab: `${practiceAnalytics}-hygiene-follow-up-unscheduled-tab`,
    hygienePractitionerAnalysisTab: `${practiceAnalytics}-hygiene-practitioner-analysis-tab`,
    hygieneTreatmentDiagnosedTab: `${practiceAnalytics}-hygiene-treatment-diagnosed-tab`,
    hygieneTreatmentPlanChart: `${practiceAnalytics}-hygiene-treatment-plan-chart`,
    hygieneTreatmentPlanTrend: `${practiceAnalytics}-hygiene-treatment-plan-trend`,
    hygieneTreatmentUnscheduledTab: `${practiceAnalytics}-hygiene-treatment-unscheduled-tab`,
    locationsFilter: `${practiceAnalytics}-locations-filter`,
    locationsSelector: `${practiceAnalytics}-locations-selector`,
    morningHuddleTab: `${practiceAnalytics}-morning-huddle-tab`,
    newPatientsAllTab: `${practiceAnalytics}-new-patients-all-tab`,
    newPatientsChart: `${practiceAnalytics}-new-patients-chart`,
    newPatientsHygieneTab: `${practiceAnalytics}-new-patients-hygiene-tab`,
    newPatientsRestorativeTab: `${practiceAnalytics}-new-patients-restorative-tab`,
    newPatientsTrend: `${practiceAnalytics}-new-patients-trend`,
    opportunitiesTab: `${practiceAnalytics}-opportunities-tab`,
    opportunityCardCancelledPatients: `${practiceAnalytics}-opportunity-card-cancelled-patients`,
    opportunityCardHygieneFollowUp: `${practiceAnalytics}-opportunity-card-hygiene-follow-up`,
    opportunityCardHygieneTreatment: `${practiceAnalytics}-opportunity-card-hygiene-treatment`,
    opportunityCardNewPatients: `${practiceAnalytics}-opportunity-card-new-patients`,
    opportunityCardRestorativeTreatment: `${practiceAnalytics}-opportunity-card-restorative-treatment`,
    overduePerioPatientsTab: `${practiceAnalytics}-overdue-perio-patients-tab`,
    overviewTab: `${practiceAnalytics}-overview-tab`,
    patientsByInsuranceAllTab: `${practiceAnalytics}-patients-by-insurance-all-tab`,
    patientsByInsuranceUnscheduledTab: `${practiceAnalytics}-patients-by-insurance-unscheduled-tab`,
    perioPatientsTab: `${practiceAnalytics}-perio-patients-tab`,
    practitionerAnalysisChart: `${practiceAnalytics}-practitioner-analysis-chart`,
    quickFill: `${practiceAnalytics}-quick-fill`,
    recapturedPatientsChart: `${practiceAnalytics}-recaptured-patients-chart`,
    recapturedPatientsTrend: `${practiceAnalytics}-recaptured-patients-trend`,
    restorativePractitionerAnalysisTab: `${practiceAnalytics}-restorative-practitioner-analysis-tab`,
    restorativeTreatmentDiagnosedTab: `${practiceAnalytics}-restorative-treatment-diagnosed-tab`,
    restorativeTreatmentPlanChart: `${practiceAnalytics}-restorative-treatment-plan-chart`,
    restorativeTreatmentPlanTrend: `${practiceAnalytics}-restorative-treatment-plan-trend`,
    restorativeTreatmentUnscheduledTab: `${practiceAnalytics}-restorative-treatment-unscheduled-tab`,
    scheduleBulkText: `${practiceAnalytics}-schedule-bulk-text`,
    timeOpportunityFilter: `${practiceAnalytics}-time-opportunity-filter`,
    timePeriodOnDashboardFilter: `${practiceAnalytics}-time-period-on-dashboard-filter`,
    timePeriodOnTrayFilter: `${practiceAnalytics}-time-period-on-tray-filter`,
    unscheduledActivePatientsTab: `${practiceAnalytics}-unscheduled-active-patients-tab`,
    unscheduledHygienePatientsAllTab: `${practiceAnalytics}-unscheduled-hygiene-patients-all-tab`,
    unscheduledHygienePatientsPerioTab: `${practiceAnalytics}-unscheduled-hygiene-patients-perio-tab`,
  },
  practiceAnalyticsPLG: {
    plgFreeTrialFromDemo: `${practiceAnalyticsPLG}-free-trial-from-demo`,
    plgFreeTrialFromDemoSubPage: `${practiceAnalyticsPLG}-free-trial-from-demo-sub-page`,
    plgUpgradeBanner: `${practiceAnalyticsPLG}-upgrade-banner`,
    plgUpgradeButton: `${practiceAnalyticsPLG}-upgrade-button`,
  },
  roi: {
    missedCallTextAppointmentsScheduledCard: `${missedCallText}-appointments-scheduled-card`,
    missedCallTextLocationPopoverFilter: `${missedCallText}-location-popover-filter`,
    missedCallTextMessageSentCard: `${missedCallText}-message-sent-card`,
    missedCallTextOverviewWidget: `${missedCallText}-overview-widget`,
    missedCallTextPracticeValueCard: `${missedCallText}-practice-value-card`,
    missedCallTextRoiWidget: `${missedCallText}-roi-widget`,
    missedCallTextTimelineViewAppointmentsScheduled: `${missedCallText}-timeline-view-appointments-scheduled`,
    missedCallTextTimelineViewMessageSend: `${missedCallText}-timeline-view-message-send`,
    missedCallTextTimePopoverFilter: `${missedCallText}-time-popover-filter`,
  },
  subscriptions: {
    freeTrialPA: `${subscriptions}-free-trial-practice-analytics`,
    scheduleCallForCallIntel: `${subscriptions}-schedule-call-for-call-intel`,
    selfSubscribeCallIntel: `${subscriptions}-self-subscribe-call-intel`,
    selfSubscribePA: `${subscriptions}-self-subscribe-practice-analytics`,
  },
};
