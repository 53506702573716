import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { CopyToClipboardButton, styles, Text, useTooltip } from '@frontend/design-system';

interface CopyLinkHelperProps {
  textToCopy: string;
  tooltipSuccessText?: string;
  tooltipText?: string;
  disabled?: boolean;
  trackingId?: string;
  buttonText?: string;
}

export const CopyLinkHelper = ({
  textToCopy,
  tooltipText,
  tooltipSuccessText,
  disabled = false,
  trackingId,
  buttonText,
}: CopyLinkHelperProps) => {
  const { t } = useTranslation('schedule');
  const { tooltipProps, triggerProps, Tooltip } = useTooltip();

  return (
    <div css={{ display: 'flex', alignItems: 'center', gap: theme.spacing(1) }}>
      <div {...triggerProps} css={[styles.truncate, linkContainerStyle]}>
        <Text as='span' color={disabled ? 'disabled' : 'default'}>
          {textToCopy}
        </Text>
        <Tooltip {...tooltipProps}>
          <Text color='white'>{textToCopy}</Text>
        </Tooltip>
      </div>
      <CopyToClipboardButton
        variant='secondary'
        hasSuccessTooltip
        textToCopy={textToCopy}
        trackingId={trackingId}
        tooltipSuccessText={tooltipSuccessText ?? t('Link copied')}
        tooltipText={tooltipText ?? t('Link will be copied to your clipboard')}
      >
        {buttonText ?? t('Copy Link')}
      </CopyToClipboardButton>
    </div>
  );
};

const linkContainerStyle = css({
  backgroundColor: theme.colors.neutral5,
  border: `1px solid ${theme.colors.neutral20}`,
  borderRadius: theme.borderRadius.small,
  padding: theme.spacing(0.5, 1),
  // TODO: remove this after confirmation from design to show full width link
  maxWidth: 240,
});
