import { AnalyticsCommonTypes } from '@frontend/api-analytics';

export const formatStatusGroupsData = (
  statusData: Record<string, Record<string, number>>,
  labels: AnalyticsCommonTypes.StringRecord
) => {
  return Object.entries(statusData).map(([name, value]) => {
    // The value tends to have less data points than the sibling values
    // We need to make sure that they are equal to avoid chart render issues
    const missingKeys = Object.keys(labels).reduce((acc, key) => (value[key] ? acc : { ...acc, [key]: 0 }), {});

    return {
      name,
      values: {
        ...Object.entries(value).reduce((acc, [name, value]) => ({ ...acc, [name]: value }), {}),
        ...missingKeys,
      },
    };
  });
};
