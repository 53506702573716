import { KeyboardEvent, useCallback } from 'react';
import { ContentLoader, Text } from '@frontend/design-system';
import { AreaChart, TinyAreaChart } from './area-chart';
import { Header, HorizontalContainer, Legends, SneakPeakBar } from './atoms';
import { BarChart, CategoryBarChart } from './bar-chart';
import { ChartProvider, useChartContext } from './chart.provider';
import { cardStyles, chartWrapperStyles } from './common-styles';
import { GaugeChart } from './gauge-chart';
import { Leaderboard } from './leaderboard';
import { Name } from './leaderboard/name';
import { PieChart } from './pie-chart';
import { RadialChart } from './radial-chart';
import { ChartProps } from './types';

const ChartContent = ({ bottomContentRenderer, children, error, onClick, trackingId, ...rest }: ChartProps) => {
  const { setChartContainer, showLoader } = useChartContext();

  const handleEnterKey = useCallback((event: KeyboardEvent<HTMLDivElement>) => {
    if (onClick && event.key === 'Enter') {
      onClick();
    }
  }, []);

  return (
    <section className={`chart-card ${rest.className ?? ''}`.trim()} css={[chartWrapperStyles]} style={rest.style}>
      <div
        aria-describedby='chart'
        className={typeof onClick === 'undefined' || rest.isLoading ? '' : 'click-enabled'}
        css={cardStyles}
        data-trackingid={onClick ? trackingId : undefined}
        onClick={onClick}
        onKeyDown={handleEnterKey}
        ref={(node) => setChartContainer(node)}
        role={onClick ? 'button' : undefined}
        tabIndex={onClick ? 0 : undefined}
      >
        {children}
        {error && (
          <Text color='error' size='medium' textAlign='center'>
            {error}
          </Text>
        )}
        <ContentLoader className='loader' show={rest.isLoading || showLoader} size='small' />
      </div>
      {bottomContentRenderer && (
        <div className='weave-chart-bottom-content'>
          {typeof bottomContentRenderer === 'function' ? bottomContentRenderer() : bottomContentRenderer}
        </div>
      )}
    </section>
  );
};

export const Chart = (props: ChartProps) => {
  return (
    <ChartProvider {...props}>
      <ChartContent {...props} />
    </ChartProvider>
  );
};

Chart.AreaChart = AreaChart;
Chart.BarChart = BarChart;
Chart.CategoryBarChart = CategoryBarChart;
Chart.GaugeChart = GaugeChart;
Chart.Header = Header;
Chart.HorizontalContainer = HorizontalContainer;
Chart.Leaderboard = Leaderboard;
Chart.LeaderboardRowLabel = Name;
Chart.Legends = Legends;
Chart.PieChart = PieChart;
Chart.RadialBarChart = RadialChart;
Chart.SneakPeakBar = SneakPeakBar;
Chart.TinyAreaChart = TinyAreaChart;

export * as commonChartsStyles from './common-styles';

export { generateAreaGradientDefs } from './area-chart';
export { sequentialChartColors } from './helpers/colors';
export { ScrollControl, XAxisLabelValueTick, YAxisLabelValueTick } from './atoms';

export type { AreaChartData } from './area-chart';
export type {
  BarChartAppearance,
  BarChartData,
  BarChartPattern,
  BarChartProps,
  CategoryBarChartData,
} from './bar-chart';
export type { CategoryBarChartAppearance } from './bar-chart/category-bar-chart-types';
export type { ChartHeaderAction, CustomLegendsData, CustomTooltipTitle } from './atoms';
export type { CustomAxisTickParams, CustomAxisTickProps, FormatValue, XAxisTickFormatValue } from './types';
export type { LeaderboardDataValueClickParams, LeaderboardProps } from './leaderboard';
export type { PDFDetails } from './hooks';
export type { PieChartData } from './pie-chart/types';
export type { RadialBarChartAppearance, RadialBarChartProps } from './radial-chart';
