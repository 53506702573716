import { UseQueryOptions } from 'react-query';
import { SchemaIO, SchemaWGPT } from '@frontend/schema';
import { useAppScopeStore, useScopedQuery } from '@frontend/scope';
import { ContextlessQueryObserverOptions } from '@frontend/react-query-helpers';

const defaultOptions: ContextlessQueryObserverOptions = {
  refetchOnMount: false,
  refetchOnWindowFocus: false,
};

export const queryKeys = {
  base: 'email-assistant',
  assistantCreateSubjects: () => [queryKeys.base, 'assistant-create-subjects'],
  assistantRefinements: () => [queryKeys.base, 'assistant-refinements'],
};

const useGetLocationData = () => {
  const { selectedLocationIdsWithParents, accessibleLocationData } = useAppScopeStore();
  const locationId = selectedLocationIdsWithParents[0];
  return accessibleLocationData[locationId];
};

export const useAssistantCreateSubjects = () => {
  const locationData = useGetLocationData();
  return (context: string[]) => {
    if (context.length === 0) throw 'No context provided';
    return SchemaWGPT.CreateSubjects({ context, vertical: locationData.vertical });
  };
};

const reformatEmail = (input?: string) => {
  if (!input) return '';

  const brTagRegex = /<br\s*\/?>/gi;
  const html = `<p>${input.replace(brTagRegex, '</p><p>')}</p>`;

  const container = document.createElement('div');
  container.innerHTML = html;

  const paragraphs = container.querySelectorAll('p');

  for (const paragraph of paragraphs) {
    paragraph.textContent = paragraph.textContent?.trim() || '';
  }

  return container.innerHTML;
};

export const useAssistantCreateEmail = () => {
  const locationData = useGetLocationData();

  return async (context: string[], subject: string) => {
    if (!subject && context.length === 0) throw 'No subject or context provided';

    const updatedSubject = subject || context[0];
    const updatedContext = subject ? context : context.slice(1);

    const response = await SchemaWGPT.CreateEmail({
      subject: updatedSubject,
      context: updatedContext,
      vertical: locationData.vertical,
    });

    const body = response.response;
    if (body) {
      response.response = reformatEmail(body);
    }

    return response;
  };
};

export const useAssistantRefineEmail = () => {
  const locationData = useGetLocationData();

  return (text: string, refinement: string) => {
    if (!text) throw 'No text provided';
    if (!refinement) throw 'No refinement provided';
    return SchemaWGPT.RefineEmail({ text, refinement, vertical: locationData.vertical });
  };
};

type SchemaGetRefinements = SchemaIO<(typeof SchemaWGPT)['ListRefinements']>;
export const useAssistantGetRefinements = (options: UseQueryOptions<SchemaGetRefinements['output']> = {}) => {
  const request: SchemaGetRefinements['input'] = {};
  return useScopedQuery({
    ...defaultOptions,
    queryKey: queryKeys.assistantRefinements(),
    queryFn: () => SchemaWGPT.ListRefinements(request),
    ...options,
  });
};
