import { useMemo } from 'react';
import { AnalyticsCommonTypes } from '@frontend/api-analytics';
import { Chart, sequentialChartColors } from '@frontend/charts';
import { i18next, useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { formatters } from '../../../utils';
import { DemoChip } from '../../demo-chip';
import { weaveStatusColors } from '../constants';
import { useIsAppointmentDemoAccount } from '../hooks';
import { formatStatusGroupsData } from '../utils/format-appointment-data';
import { AppointmentsChartProps } from './shared-types';

const colors = {
  ...weaveStatusColors,
  other: theme.colors.neutral40,
};

const labels = {
  other: i18next.t('Other', { ns: 'analytics' }),
};

export const AppointmentsPmsStatusChart = ({ appearance, data, isLoading }: AppointmentsChartProps) => {
  const { t } = useTranslation('analytics');
  const isDemoAccount = useIsAppointmentDemoAccount();

  const pmsStatusVolume = useMemo(() => {
    const labels = Object.values(data.pmsStatusCounts).reduce((acc, value) => {
      Object.keys(value).forEach((key) => {
        // Remove multiple consecutive spaces from the key
        const label = key.replace(/\s+/g, ' ');
        if (!acc[key]) {
          acc[key] = label;
        }
      });
      return acc;
    }, {} as AnalyticsCommonTypes.StringRecord);

    if ('other' in labels) {
      labels['other'] = t('Other');
    }

    return {
      data: {
        groups: formatStatusGroupsData(data.pmsStatusCounts, labels),
      },
      labels,
    };
  }, [data.pmsStatusCounts]);

  const pmsStatusConstants = useMemo(() => {
    return {
      colors: Object.keys(pmsStatusVolume.labels).reduce(
        (acc, cur, index) => ({ ...acc, [cur]: sequentialChartColors[index] ?? theme.colors.neutral40 }),
        {}
      ),
      labels: pmsStatusVolume.labels,
    };
  }, [pmsStatusVolume]);

  return (
    <Chart
      colors={{
        ...pmsStatusConstants.colors,
        ...colors,
      }}
      isLoading={isLoading}
      labels={{
        ...pmsStatusConstants.labels,
        ...labels,
      }}
    >
      <Chart.Header
        leftElement={isDemoAccount && <DemoChip />}
        title={t('Appointment Status Count - PMS')}
        trackingIdHelper='appointment-status-pms'
      />
      <Chart.Legends />
      <Chart.BarChart appearance={appearance} data={pmsStatusVolume.data} formatValue={formatters.value.format} />
    </Chart>
  );
};
