import { useMatch, useSearch } from '@tanstack/react-location';
import { NEW_CAMPAIGN_ID } from '../constants';
import { BulkEmailEditorMode, BulkEmailEditorView, BulkMessagingSubNav } from '../types';

export type EmailCampaignUrlParams = {
  seedId?: string;
  copyAudience?: boolean;
};

// Passing the search params and getting them from useSearch as a fallback is ghetto,
// but it is necessary to prevent a weird screen flashing when navigating from the duplicate screen
export const useBulkEmailUrlData = (search?: EmailCampaignUrlParams) => {
  const match = useMatch<{ Params: { id: string; view: BulkEmailEditorView; subNav: BulkMessagingSubNav } }>();
  const { subNav, view, id: campaignId } = match.params;
  const isDraft = subNav === 'drafts';
  const { seedId: seedCampaignId, copyAudience } = search ? search : useSearch<{ Search: EmailCampaignUrlParams }>();
  const mode: BulkEmailEditorMode = campaignId !== NEW_CAMPAIGN_ID ? 'edit' : !!seedCampaignId ? 'duplicate' : 'create';
  return { campaignId, view, isDraft, seedCampaignId, copyAudience, mode };
};
