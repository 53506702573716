import { useState } from 'react';
import { useEditor, useNode } from '@craftjs/core';
import { css } from '@emotion/react';
import { Media } from '@weave/schema-gen-ts/dist/schemas/messaging/media/public/v1/service.pb';
import { PercentCrop } from 'react-image-crop';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Text, useModalControl } from '@frontend/design-system';
import { useComposerSection } from '../../hooks';
import { ComposerSection, ImageSectionProps } from '../../types';
import { defaultCrop } from '../../utils';
import { getPaddingStyles } from '../../utils/get-padding-styles';
import { useContentComposer } from '../content-composer-provider';
import { ImageOutput } from '../outputs';
import { ImageSettings } from '../settings';
import { ImageTool } from '../tools';
import { BaseComposerSection } from './floating-toolbar';
import { AddImageButton } from './image-elements/add-image-button';
import { ImageItem } from './image-elements/image-item';
import { UploadOrSelectImageModal } from './image-elements/upload-or-select-image-modal';

export const ImageSection: ComposerSection<ImageSectionProps> = ({
  count,
  corners,
  crops,
  frameColor,
  paddingTop,
  paddingBottom,
  paddingLeft,
  paddingRight,
  height,
  images = [],
  imageSpacing,
  ...rest
}) => {
  const { deviceSizeProps } = useContentComposer();
  const size = deviceSizeProps?.value || 'desktop';
  const { t } = useTranslation('content-composer');
  const addImageText = t('Add Image');
  const [selectedIndex, setSelectedIndex] = useState<number | undefined>();
  const { setProps } = useComposerSection<ImageSectionProps>();

  const { id } = useNode((state) => ({
    hasSelectedNode: state.events.selected,
    hasDraggedNode: state.events.dragged,
    id: state.id,
  }));

  const { actions, selected } = useEditor((state, query) => {
    const [currentNodeId] = state.events.selected;
    let selected;

    if (currentNodeId) {
      selected = {
        id: currentNodeId,
        isDeletable: query.node(currentNodeId).isDeletable(),
      };
    }

    return { selected };
  });

  const imageSpacingNumber = Number(imageSpacing?.replace('px', '')) || 0;
  const imageCountStyling =
    count === '3'
      ? tripleImageContainerStyling(imageSpacingNumber / 2, size)
      : count === '2'
      ? doubleImageContainerStyling(imageSpacingNumber / 2, size)
      : singleImageContainerStyling;

  const { modalProps, triggerProps } = useModalControl();

  const removeImage = (index: number) => {
    const newImages = [...images];
    newImages[index] = undefined;
    setProps((props) => (props.images = newImages));
  };

  const handleCrop = (index: number, crop: PercentCrop) => {
    setSelectedIndex(undefined);
    setProps((props) => {
      const newCrops = [...(crops || [])];
      newCrops[index] = crop;
      props.crops = newCrops;
    });
  };

  const displayedImages: { condition: boolean }[] = [
    { condition: true },
    { condition: !!(Number(count) > 1) },
    { condition: !!(Number(count) > 2) },
  ];

  return (
    <BaseComposerSection
      sectionType='Image'
      title={t('Image')}
      onDelete={selected?.isDeletable ? () => actions.delete(selected.id) : undefined}
      {...rest}
      id={id}
      css={[
        getPaddingStyles({ paddingTop, paddingRight, paddingBottom, paddingLeft }),
        {
          background: frameColor,
        },
      ]}
      onClick={() => {
        setSelectedIndex(undefined);
      }}
    >
      <Text
        as='div'
        weight='bold'
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: count === '1' ? 'center' : 'space-between',
          width: '100%',
          flexDirection: size === 'desktop' ? 'row' : 'column',
        }}
      >
        {displayedImages
          .filter((item) => item.condition)
          .map((_item, index) => (
            <div
              key={index}
              css={[imageContainerStyling, imageCountStyling, !images?.[0] && imageContainerBorderStyling]}
            >
              {images?.[index] ? (
                <ImageItem
                  height={height}
                  image={images[index] as Media}
                  corners={corners}
                  onRemove={() => removeImage(index)}
                  onReplace={() => {
                    setSelectedIndex(index);
                    triggerProps.onClick();
                  }}
                  selectedImage={selectedIndex === index && selected?.id === id}
                  selectImage={() => setSelectedIndex(index)}
                  crop={crops?.[index]}
                  onCrop={(crop) => handleCrop(index, crop)}
                />
              ) : (
                <AddImageButton
                  text={addImageText}
                  onClick={() => {
                    setSelectedIndex(index);
                    triggerProps.onClick();
                  }}
                />
              )}
            </div>
          ))}
      </Text>
      {typeof selectedIndex === 'number' && (
        <UploadOrSelectImageModal
          selectedImages={images}
          selectedIndex={selectedIndex}
          modalProps={modalProps}
          onSelect={(files: (Media | undefined)[]) => {
            setProps((props) => (props.images = files));
          }}
        />
      )}
    </BaseComposerSection>
  );
};

ImageSection.craft = {
  custom: {
    controlOutputRender: true,
  },
  props: {
    corners: 'square',
    count: '1',
    crops: [defaultCrop, defaultCrop, defaultCrop],
    frameColor: theme.colors.neutral5.toUpperCase(),
    height: 'auto',
    imageSpacing: theme.spacing(1),
    images: [],
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  related: {
    output: ImageOutput,
    settings: ImageSettings,
    tool: ImageTool,
  },
};

const imageContainerStyling = css`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: relative;
`;

const imageContainerBorderStyling = css`
  border: 1px solid ${theme.colors.neutral20};
  min-height: 320px;
`;

const singleImageContainerStyling = css`
  width: 100%;
`;

const doubleImageContainerStyling = (spacing: number, size: 'desktop' | 'mobile') => css`
  width: ${size === 'desktop' ? '50%' : '100%'};
  &:first-of-type {
    ${size === 'desktop' ? `margin-right: ${spacing}px;` : `margin-bottom: ${spacing}px;`};
  }
  &:last-of-type {
    ${size === 'desktop' ? `margin-left: ${spacing}px;` : `margin-top: ${spacing}px;`};
  }
`;

const tripleImageContainerStyling = (spacing: number, size: 'desktop' | 'mobile') => css`
  width: ${size === 'desktop' ? '33.33%' : '100%'};
  &:not(:first-of-type, :last-of-type) {
    ${size === 'desktop'
      ? `
      margin-left: ${spacing}px;
      margin-right: ${spacing}px;
    `
      : `
      margin-top: ${spacing}px;
      margin-bottom: ${spacing}px;
    `};
  }
  &:first-of-type {
    ${size === 'desktop' ? `margin-right: ${spacing}px;` : `margin-bottom: ${spacing}px;`};
  }
  &:last-of-type {
    ${size === 'desktop' ? `margin-left: ${spacing}px;` : `margin-top: ${spacing}px;`};
  }
`;
