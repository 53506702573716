import { ReactElement, ReactNode } from 'react';
import { BaseFieldProps, contextFactory, useFormField } from '@frontend/design-system';
import { useActivePanel, useHtmlRenderer, useInsertSection, useStateManagement } from '../hooks';
import { ComposerDocument, ComposerPanel, ComposerParts, ComposerMode, DeviceSize } from '../types';

type ContentComposerContextState = {
  activePanel: ComposerPanel;
  cancelRenderHtml: () => void;
  deviceSizeProps?: BaseFieldProps<DeviceSize>; // TODO: Replace this with CSS classes
  getValue: () => ComposerDocument | '';
  insertSection: (element: ReactElement, index?: number) => void;
  locationIds: string[];
  promptProps: BaseFieldProps<string>; // TODO: Move this completely out of the content composer, but modify components to support it
  renderHtml: (mode?: string) => Promise<string | undefined>;
  setActivePanel: (panel: Exclude<ComposerPanel, 'settings'>) => void;
  setValue: (document: ComposerDocument) => void;
  composerMode: ComposerMode;
  parts: ComposerParts;
};

export const [ContentComposerContext, useContentComposer] = contextFactory<ContentComposerContextState>(
  'useContentComposer must be used within an ContentComposer'
);

type ContentComposerProviderProps = {
  children: ReactNode;
  deviceSizeProps?: BaseFieldProps<DeviceSize>;
  initialValue?: ComposerDocument;
  locationIds: string[];
  parts: ComposerParts;
  composerMode: ComposerMode;
};

export const ContentComposerProvider = ({
  children,
  deviceSizeProps,
  initialValue,
  locationIds,
  parts,
  composerMode,
}: ContentComposerProviderProps) => {
  const { getValue, setValue } = useStateManagement(initialValue, parts);
  const {
    HtmlRenderer,
    htmlRendererProps,
    startRender: renderHtml,
    cancelRender: cancelRenderHtml,
  } = useHtmlRenderer();
  const { activePanel, setActivePanel } = useActivePanel();
  const insertSection = useInsertSection();
  const promptProps = useFormField({ type: 'text' });

  return (
    <ContentComposerContext.Provider
      value={{
        activePanel,
        cancelRenderHtml,
        deviceSizeProps,
        insertSection,
        getValue,
        promptProps,
        locationIds,
        renderHtml,
        setActivePanel,
        setValue,
        composerMode,
        parts,
      }}
    >
      {children}
      <HtmlRenderer {...htmlRendererProps} />
    </ContentComposerContext.Provider>
  );
};
