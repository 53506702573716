export enum MediaUploadTypes {
  Attachment = 'attachment',
  Gallery = 'gallery',
  Logo = 'logo',
  Marcomm = 'marcomm',
  MMS = 'mms',
  PhoneBill = 'phone_bill',
  PortingPhoneBill = 'MEDIA_TYPE_PHONE_BILL',
}

export type MediaUploadResponse = {
  ID: string;
  UserID: string;
  Type: MediaUploadTypes;
  MediaLocation: string;
  Name: string;
  Path: string;
  LocationID: string;
  EscapedURL: string;
  PublicURL: string;
  SecureURL: string;
  CreatedAt: string;
  DeletedAt: string | null;
};

export type UploadMediaPayload = {
  data: Blob;
  filename: string;
  type?: MediaUploadTypes;
  publicity?: boolean;
};

export type MediaUploadFile = {
  file: File;
  uploaded: boolean;
  mediaId?: string;
  failed?: boolean;
  previewUrl?: string;
};
