import { css } from '@emotion/react';
import { useMatch } from '@tanstack/react-location';
import { useTranslation } from '@frontend/i18n';
import { Page } from '@frontend/page';
import { theme } from '@frontend/theme';
import { URLs } from '../../../constants';
import { MissedCallTextMultiConversionComparisonChart } from '../charts';
import { Filters } from '../components';
import { MultiConversionComparisonOverview } from '../components/missed-call-text-roi';
import { useFetchMissedCallTextConversionTotals, useROIShallowStore } from '../hooks';
import { AttributonTypeWithPracticeValue } from '../hooks/shared-types';

export const MissedCallTextRoiMultiConversionPage = () => {
  const { search } = useMatch();
  const { t } = useTranslation('analytics');
  const { filterHintText: subtitle, filters } = useROIShallowStore('filterHintText', 'filters');
  const attributeType = search['attributeType'] as AttributonTypeWithPracticeValue;
  const isPracticeValueConversion = attributeType === 'practice-value';

  const payload = {
    start_date: filters?.startDate,
    end_date: filters?.endDate,
    location_id: filters?.locationIds,
  };

  const { data, isLoading } = useFetchMissedCallTextConversionTotals(
    isPracticeValueConversion
      ? {
          isPracticeValueConversion: true,
          apiParams: {
            payload,
          },
        }
      : {
          apiParams: {
            attributeType,
            messagingCategory: 'missedcalltext',
            payload,
          },
          isEnabled: attributeType === 'backward' || attributeType === 'forward',
        }
  );

  return (
    <Page>
      <Page.Header>
        <Page.Header.Breadcrumbs
          breadcrumbs={[
            {
              label: t('Analytics'),
              to: URLs.ANALYTICS_DASHBOARD,
            },
            {
              label: t('Missed Call Text Conversion'),
              to: URLs.ROI_MISSED_CALL_TEXT,
            },
            {
              label: t('Conversion'),
            },
          ]}
        />
        <Page.Header.Heading>
          <Page.Header.Title title={t('Conversion')} />
          <Page.Header.Subtitle subtitle={t('Showing results for {{subtitle}}', { subtitle })} />
        </Page.Header.Heading>
      </Page.Header>

      <Page.Body>
        <div css={styles.body}>
          <Filters isLoading={isLoading} />
          <MultiConversionComparisonOverview data={data} isLoading={isLoading} />
          <MissedCallTextMultiConversionComparisonChart
            data={data}
            isLoading={isLoading}
            isPracticeValueConversion={isPracticeValueConversion}
          />
        </div>
      </Page.Body>
    </Page>
  );
};

const styles = {
  body: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(3)};
  `,
};
