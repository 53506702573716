import { css } from '@emotion/react';
import { OnboardingModulesTypes } from '@frontend/api-onboarding-modules';
import { theme } from '@frontend/theme';
import { Accordion, Text } from '@frontend/design-system';
import { ModuleAccordionHeader } from './module-accordion-header';
import { ModuleTaskList } from './module-task-list';

interface Props {
  moduleInfo: OnboardingModulesTypes.TaskTrayModuleInfo;
}

export const ModuleItem = ({ moduleInfo }: Props) => {
  const { id, title, description, tasks, progressText, progressValue } = moduleInfo;

  return (
    <Accordion.Item data-test-id='onboarding-module-item' value={String(id)}>
      <ModuleAccordionHeader title={title} progress={progressValue} statusText={progressText} />
      <Accordion.Body css={{ paddingTop: 0 }}>
        {!!description && (
          <div css={{ margin: theme.spacing(0, 0, 2, 5.5) }}>
            <Text>{description}</Text>
          </div>
        )}
        <hr css={hrStyles} />
        <ModuleTaskList tasks={tasks} />
      </Accordion.Body>
    </Accordion.Item>
  );
};

const hrStyles = css({
  margin: theme.spacing(0, 0, 2, 0),
  border: 'none',
  borderTop: `1px solid ${theme.colors.neutral20}`,
});
