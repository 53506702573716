import { css } from '@emotion/react';
import { SIDEBAR_BREAKPOINTS } from '@frontend/content-composer';
import { Icon } from '@frontend/icons';
import { BulkEmailPrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import { BaseFieldProps, OptionSwitchField } from '@frontend/design-system';

export type DeviceSize = 'desktop' | 'mobile';

export const DeviceSizeField = (props: BaseFieldProps<DeviceSize>) => {
  return (
    <OptionSwitchField
      name='display'
      css={css`
        button:hover {
          background-color: ${theme.colors.neutral5};
        }
        @media (max-width: ${SIDEBAR_BREAKPOINTS.mobile}px) {
          display: none;
        }
      `}
      {...props}
    >
      <OptionSwitchField.Option value='desktop' trackingId={`${BulkEmailPrefixes.Composer}-desktop-view-btn`}>
        <Icon name='computer' color={props.value === 'desktop' ? 'primary' : 'default'} css={iconStyle} />
      </OptionSwitchField.Option>
      <OptionSwitchField.Option value='mobile' trackingId={`${BulkEmailPrefixes.Composer}-mobile-view-btn`}>
        <Icon name='cell-phone' color={props.value === 'mobile' ? 'primary' : 'default'} css={iconStyle} />
      </OptionSwitchField.Option>
    </OptionSwitchField>
  );
};

const iconStyle = css`
  margin: ${theme.spacing(0, 1)};
  pointer-events: none;
`;
