import { http } from '@frontend/fetch';
import { ExportDataReferenceResponse } from '../common/types';
import { BASE_URL_ANALYTICS } from '../constants';
import { interceptDates } from '../helpers';
import { PhoneAnalyticsTypes } from '.';

export const getDatesIntercepted = (filters: PhoneAnalyticsTypes.Filters) => {
  if (!filters.start_date || !filters.end_date) {
    return filters;
  }

  const interceptedDates = {
    startDate: filters.start_date,
    endDate: filters.end_date,
  };
  if (filters.start_date && filters.end_date) {
    const dates = interceptDates({
      endDate: filters.end_date,
      setFullDay: true,
      startDate: filters.start_date,
      timeZone: filters.time_zone,
    });
    interceptedDates.startDate = dates.startDate;
    interceptedDates.endDate = dates.endDate;
  }
  return {
    ...filters,
    start_date: interceptedDates.startDate,
    end_date: interceptedDates.endDate,
  };
};

export const getRawPhoneReport = async (payload: PhoneAnalyticsTypes.Filters, pageSize: number, url?: string) => {
  if (!payload.location_id?.length || !payload.start_date || !payload.end_date) {
    return;
  }

  const { data, meta } = await http.post<PhoneAnalyticsTypes.RawPhoneReportResponse>(
    url || `${BASE_URL_ANALYTICS}/phonereports/v2/getcallreport?limit=${pageSize}`,
    getDatesIntercepted(payload)
  );

  return { data, meta };
};

export const getExportableDetails = async (payload: PhoneAnalyticsTypes.ExportCheckPayload) => {
  const response = await http.post<{ data: ExportDataReferenceResponse[] }>(
    `${BASE_URL_ANALYTICS}/downloadreports/v2/getcsvasync`,
    getDatesIntercepted(payload)
  );
  return response.data;
};
