export const EmailSvg = ({ fillColor }: { fillColor?: string }) => (
  <svg
    height={24}
    width={24}
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    version='1.1'
    baseProfile='full'
  >
    <path
      fill={fillColor}
      fillRule='evenodd'
      d='M18.004 3.986H7.016a4.5 4.5 0 0 0-4.5 4.5v8.287a4.5 4.5 0 0 0 4.5 4.5h10.988a4.5 4.5 0 0 0 4.5-4.5V8.486a4.5 4.5 0 0 0-4.5-4.5ZM5.418 7.283a1.997 1.997 0 0 1 1.598-.797h10.988c.608 0 1.153.271 1.52.7l-6.64 5.406a.75.75 0 0 1-.947 0zm-.402 2.897v6.593a2 2 0 0 0 2 2h10.988a2 2 0 0 0 2-2v-6.755l-5.54 4.513a3.25 3.25 0 0 1-4.106 0z'
      clipRule='evenodd'
    />
  </svg>
);
