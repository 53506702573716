import React from 'react';
import { Accordion, Dot, Text } from '@frontend/design-system';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

type ItemProps = {
  value: string;
  children: React.ReactNode;
};

const Item = ({ value, children, ...rest }: ItemProps) => {
  return (
    <Accordion.Item value={value} {...rest}>
      {children}
    </Accordion.Item>
  );
};

type HeaderProps = {
  label?: string;
  hasFilters?: boolean;
};

const Header = ({ hasFilters = false, label }: HeaderProps) => {
  return (
    <Accordion.Header>
      <Text
        weight='bold'
        css={css`
          display: flex;
          align-items: center;
          gap: ${theme.spacing(1)};
        `}
      >
        {label}{' '}
        {hasFilters && (
          <Dot
            color='primary'
            css={css`
              position: static;
            `}
          />
        )}
      </Text>
    </Accordion.Header>
  );
};

type BodyProps = {
  children: React.ReactNode;
};

const Body = ({ children, ...rest }: BodyProps) => {
  return (
    <Accordion.Body
      css={css`
        display: flex;
        flex-direction: column;
        gap: ${theme.spacing(1)};
        padding: ${theme.spacing(2, 3)};
      `}
      {...rest}
    >
      {children}
    </Accordion.Body>
  );
};

export const FilterAccordion = { Item, Body, Header };
