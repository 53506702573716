import { useQueryClient } from 'react-query';
import { useMutation } from '@frontend/react-query-helpers';
import { deleteInvoice } from './api';
import { cancelInvoiceQueryCache, updateInvoiceQueryCache } from './invoice-cache-helpers';
import { getInvoiceQueryKey } from './query-keys';
import { InvoiceModel, InvoiceStatus } from './types';

export type UseCancelInvoiceMutationProps = {
  paymentsUrl: string | null;
  locationId: string;
  onSuccess?: (invoice: InvoiceModel) => void;
  onError?: (error: any, invoice: InvoiceModel) => void;
};
export const useCancelInvoiceMutation = ({
  paymentsUrl,
  locationId,
  onSuccess,
  onError,
}: UseCancelInvoiceMutationProps) => {
  const queryClient = useQueryClient();

  const { mutate, status, reset } = useMutation(
    async (invoice: InvoiceModel) => {
      if (paymentsUrl) {
        return deleteInvoice(paymentsUrl, invoice);
      }
      return undefined;
    },
    {
      onMutate: async (invoice: InvoiceModel) => {
        queryClient.cancelQueries(getInvoiceQueryKey(locationId, invoice.id));

        const newInvoice: InvoiceModel = {
          ...invoice,
          status: InvoiceStatus.Canceled,
        };

        cancelInvoiceQueryCache(queryClient, newInvoice, getInvoiceQueryKey(locationId, newInvoice.id));

        /**
         * Rollback function
         */
        return () => updateInvoiceQueryCache(queryClient, invoice, getInvoiceQueryKey(locationId, invoice.id));
      },
      onSuccess: (_, invoice) => {
        onSuccess?.(invoice);
      },
      onError: (error, invoice, rollback) => {
        rollback?.();
        onError?.(error, invoice);
      },
    }
  );

  return {
    cancelInvoice: mutate,
    status: status,
    reset: reset,
  };
};
