import { useMemo } from 'react';
import { css } from '@emotion/react';
import { OnboardingModulesTypes } from '@frontend/api-onboarding-modules';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Accordion, Text } from '@frontend/design-system';
import { ModuleTaskItem } from './module-task-item';

interface Props {
  tasks: OnboardingModulesTypes.TaskTrayTaskInfo[];
}

export const ModuleTaskList = ({ tasks }: Props) => {
  const { t } = useTranslation('onboarding-modules');

  const { completedTasks, incompleteTasks } = useMemo(() => {
    return {
      completedTasks: tasks.filter((task) => task.isCompleted),
      incompleteTasks: tasks.filter((task) => !task.isCompleted),
    };
  }, [tasks]);

  return (
    <Accordion
      data-test-id='module-task-list'
      variant='hybrid'
      shouldRenderBody
      chevronIcon={false}
      css={accordionStyle}
    >
      {incompleteTasks.map((task) => (
        <ModuleTaskItem key={task.id} taskInfo={task} />
      ))}
      {!!completedTasks.length && (
        <>
          <Text size='small' color='light' css={{ margin: theme.spacing(incompleteTasks.length ? 2 : 0, 0, 1, 0) }}>
            {t('Completed Tasks')}
          </Text>
          {completedTasks.map((task) => (
            <ModuleTaskItem key={task.id} taskInfo={task} />
          ))}
        </>
      )}
    </Accordion>
  );
};

const accordionStyle = css({
  '> div': {
    border: `1px solid ${theme.colors.neutral20}`,
    ':last-of-type': {
      marginBottom: 0,
    },
    '> section': { border: 'none' },
  },
});
