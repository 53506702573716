import dayjs from 'dayjs';
import { Layout, Layouts } from 'react-grid-layout';
import { WidgetSizes, DashboardBreakpoints, widgetSizes } from '@frontend/grid-dashboard';
import { i18next } from '@frontend/i18n';

export type WidgetConfig = {
  id: string;
  enabled: boolean;
  size: Record<DashboardBreakpoints, WidgetSizes> | WidgetSizes;
  positions?: Record<DashboardBreakpoints, Layout>;
};

export const transformToLayouts = (widgets: WidgetConfig[]): Layouts => {
  const layouts: Layouts = { large: [], medium: [], small: [], extraSmall: [] };

  for (const widget of widgets) {
    if (!widget.enabled) continue;

    const size =
      typeof widget.size === 'string'
        ? { large: widget.size, medium: widget.size, small: widget.size, extraSmall: widget.size }
        : widget.size;

    for (const breakpoint of Object.keys(size) as DashboardBreakpoints[]) {
      const widgetSize = widgetSizes[size[breakpoint]];
      const position = widget.positions?.[breakpoint] || { x: 0, y: 0 };

      layouts[breakpoint].push({
        i: widget.id,
        w: widgetSize.w,
        h: widgetSize.h,
        x: position.x,
        y: position.y,
      });
    }
  }

  return layouts;
};

export function getWelcomeGreeting(name: string) {
  const now = dayjs();
  const hour = now.hour();
  const day = now.day();

  let greeting;
  let punctuation;

  if (hour >= 20) {
    greeting = i18next.t('Late night', { ns: 'dashboard' });
    punctuation = '?';
  } else if (hour < 7) {
    greeting = i18next.t('The early bird gets the worm', { ns: 'dashboard' });
    punctuation = '!';
  } else {
    switch (day) {
      case 1:
        greeting = i18next.t('Happy Monday', { ns: 'dashboard' });
        punctuation = '';
        break;
      case 5:
        greeting = i18next.t('Happy Friday! You made it', { ns: 'dashboard' });
        punctuation = '!';
        break;
      case 2:
      case 3:
      case 4:
        greeting = i18next.t('Welcome back', { ns: 'dashboard' });
        punctuation = '.';
        break;
      case 0:
      case 6:
        greeting = i18next.t('Welcome', { ns: 'dashboard' });
        punctuation = '.';
        break;
      default:
        greeting = i18next.t('Hello', { ns: 'dashboard' });
        punctuation = '.';
    }
  }

  return { greeting, nameWithPunctuation: name ? `, ${name}${punctuation}` : punctuation };
}
