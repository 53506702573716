import React, { useState, forwardRef, ComponentProps } from 'react';
import { css } from '@emotion/react';
import composeRefs from '@seznam/compose-react-refs';
import { useLocation } from '@tanstack/react-location';
import { motion, AnimatePresence } from 'framer-motion';
import { Icon, type IconName } from '@frontend/icons';
import { PopupBarRollupDesktopButton, PopupBarRollupMobileButton, usePopupBarManager } from '@frontend/popup-bar';
import { Breakpoints, breakpoints, useMatchMedia } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';
import {
  NakedButton,
  contextFactory,
  IconButton,
  Dot,
  PopoverMenu,
  PopoverMenuItem,
  usePopoverMenu,
  NotificationBadge,
  useTooltip,
} from '@frontend/design-system';

interface ActionBarContextProps {
  activeAction: string | null;
  onActionClick: (actionId: string) => void;
  actions: Action[];
}

export const [ActionBarContext, useActionBar] = contextFactory<ActionBarContextProps>();

type Action = {
  label: string;
  onClick: (e: React.MouseEvent) => void;
  hide?: boolean;
  disabled?: boolean;
};

interface ActionBarProps {
  children: React.ReactNode;
  getMobileBreakpoint?: (bp: Breakpoints) => number;
  showPopupButton?: boolean;
  actions: Action[];
  isMobileOpen?: boolean;
  setIsMobileOpen?: (isOpen: boolean) => void;
}

export const ActionBar = ({
  children,
  actions,
  getMobileBreakpoint: getCustomMobileBreakpoint,
  showPopupButton = true,
  isMobileOpen: isMobileOpenControl,
  setIsMobileOpen: setIsMobileOpenControl,
}: ActionBarProps) => {
  const getMobileBreakpoint = getCustomMobileBreakpoint ?? ((bp: Breakpoints) => bp.small.max);

  const [activeAction, setActiveAction] = useState<string | null>(null);

  const isMobile = useMatchMedia({ maxWidth: getMobileBreakpoint(breakpoints) });

  const [isMobileOpenInternal, setIsMobileOpenInternal] = useState(false);
  const { popupList } = usePopupBarManager();
  const popupButtonLocation: 'desktop' | 'mobile' | false = !!popupList.length && (!isMobile ? 'desktop' : 'mobile');
  const location = useLocation();
  const isMessageSendPage = location.current.pathname.includes('/inbox/new') || !!location.current.search.personPhone;
  const handleActionClick = (actionId: string) => {
    setActiveAction(actionId === activeAction ? null : actionId);
  };

  const isMobileOpen = typeof isMobileOpenControl === 'boolean' ? isMobileOpenControl : isMobileOpenInternal;
  const setIsMobileOpen =
    typeof setIsMobileOpenControl === 'function' ? setIsMobileOpenControl : setIsMobileOpenInternal;

  return (
    <ActionBarContext.Provider value={{ activeAction, onActionClick: handleActionClick, actions }}>
      <div
        css={[
          css`
            min-width: 57px;
            height: 100%;
            z-index: 1000;
            background: ${theme.colors.white};
            border-top: 1px solid ${theme.colors.neutral20};
            display: flex;
            flex-direction: column;
            align-items: center;
            transition: transform 0.2s;
            gap: ${theme.spacing(1)};
          `,
          isMobile &&
            css`
              position: absolute;
              bottom: 0;
              right: 0;
            `,
          isMobile &&
            isMobileOpen &&
            css`
              transform: translateX(0);
            `,
          isMobile &&
            !isMobileOpen &&
            css`
              transform: translateX(100%);
            `,
        ]}
      >
        <div
          css={[
            css`
              flex-grow: 1;
              width: 100%;
              border-left: 1px solid ${theme.colors.neutral20};
              display: flex;
              flex-direction: column;
              align-items: center;
            `,
            popupButtonLocation === 'desktop' &&
              css`
                border-bottom-left-radius: ${theme.borderRadius.medium};
                border-bottom: 1px solid ${theme.colors.neutral20};
              `,
          ]}
        >
          <PlusActions />
          <div
            css={css`
              margin-top: ${theme.spacing(1.5)};
              width: 100%;
            `}
          >
            {children}
          </div>
          {isMobile && (
            <IconButton
              label='Close Action Bar'
              onClick={() => setIsMobileOpen(false)}
              css={css`
                margin-top: auto;
                margin-bottom: ${theme.spacing(1)};
              `}
            >
              <Icon name='x' size={24} />
            </IconButton>
          )}
        </div>
        {popupButtonLocation === 'desktop' && (
          <PopupBarRollupDesktopButton
            css={{
              width: '100%',
            }}
          />
        )}
      </div>
      <AnimatePresence>
        {popupButtonLocation === 'mobile' && <PopupBarRollupMobileButton count={popupList.length} />}
        {isMobile && !isMobileOpen && !isMessageSendPage && showPopupButton && (
          <MobileToggle
            onClick={() => setIsMobileOpen(true)}
            css={
              popupButtonLocation === 'mobile' && {
                bottom: theme.spacing(6),
              }
            }
          />
        )}
      </AnimatePresence>
    </ActionBarContext.Provider>
  );
};

interface ActionProps extends React.HTMLAttributes<HTMLButtonElement> {
  icon: IconName;
  label: string;
  id: string;
  hasCount?: boolean;
  count?: number;
  trackingId?: string;
  hasDot?: boolean;
  isActive?: boolean;
}

const Action = forwardRef<HTMLButtonElement, ActionProps>(
  ({ icon, id, label, hasCount, hasDot, count, trackingId, isActive, onClick, ...rest }, ref) => {
    const { Tooltip, tooltipProps, triggerProps } = useTooltip({ placement: 'right-end' });
    const { activeAction, onActionClick } = useActionBar();
    const isActionActive = isActive ?? activeAction === id;
    return (
      <>
        <NakedButton
          {...triggerProps}
          ref={composeRefs(ref, triggerProps.ref as React.Ref<HTMLButtonElement>)}
          trackingId={trackingId}
          onClick={(e) => {
            onActionClick(id);
            onClick?.(e);
          }}
          css={[
            css`
              position: relative;
              display: flex;
              align-items: center;
              justify-content: center;
              height: 48px;
              width: 100%;
              color: ${theme.colors.neutral70};
              background-color: ${theme.colors.white};
              transition: background-color 0.2s, color 0.2s, border 0.2s;
              cursor: pointer;
              :hover {
                background-color: ${theme.colors.neutral5};
                color: ${theme.colors.primary50};
                .notification-dot {
                  border: 2px solid ${theme.colors.neutral5};
                }
              }
            `,
          ]}
          {...rest}
        >
          <Icon name={icon} size={24} />
          <AnimatePresence>
            {isActionActive && (
              <motion.span
                key={id}
                initial={{ transform: 'translateX(100%)' }}
                animate={{ transform: 'translateX(0)' }}
                exit={{ transform: 'translateX(100%)' }}
                style={{
                  position: 'absolute',
                  display: 'block',
                  zIndex: 1,
                  top: 0,
                  right: 0,
                  width: 4,
                  height: '100%',
                  backgroundColor: theme.colors.primary50,
                }}
              />
            )}
          </AnimatePresence>
          {hasCount && !!count && (
            <NotificationBadge
              css={{
                position: 'absolute',
                top: `${theme.spacing(1)}`,
                right: theme.spacing(1),
                background: theme.colors.primary50,
              }}
            >
              {count}
            </NotificationBadge>
          )}
          {hasDot && (
            <Dot
              color='critical'
              className='notification-dot'
              css={{
                position: 'absolute',
                right: theme.spacing(1.5),
                top: theme.spacing(1),
                border: '2px solid white',
                height: 12,
                width: 12,
              }}
            />
          )}
        </NakedButton>
        <Tooltip {...tooltipProps}>{label}</Tooltip>
      </>
    );
  }
);

const MotionNakedButton = motion(NakedButton);

const MobileToggle = (props: ComponentProps<typeof MotionNakedButton>) => {
  const { mobileSelectorIsOpen } = usePopupBarManager();

  return (
    <MotionNakedButton
      initial={{ transform: 'translateY(150%)' }}
      animate={{ transform: `translateY(0) translateX(${mobileSelectorIsOpen ? '150%' : '0'})` }}
      exit={{ transform: 'translateY(150%)' }}
      trackingId='action-bar-btn-mobile-toggle'
      css={css`
        width: 48px;
        height: 48px;
        position: fixed;
        bottom: 4px;
        right: 4px;
        z-index: 1000000;
        border-radius: ${theme.borderRadius.full};
        display: flex;
        align-items: center;
        justify-content: center;
        background: ${theme.colors.primary50};
        color: ${theme.colors.white};
        transition: background-color 0.2s, color 0.2s;
        box-shadow: ${theme.shadows.heavy};
        :hover {
          background-color: ${theme.colors.primary5};
          color: ${theme.colors.primary50};
        }
      `}
      {...props}
    >
      <Icon name='action-bar' size={22} />
    </MotionNakedButton>
  );
};

const PlusActions = () => {
  const { getItemProps, getMenuProps, getTriggerProps } = usePopoverMenu({ placement: 'left-start' });
  const { actions = [] } = useActionBar();

  const visibleActions = actions.filter((action) => !action.hide);

  return (
    <>
      <NakedButton
        {...getTriggerProps()}
        css={css`
          width: 40px;
          height: 40px;
          margin-top: ${theme.spacing(1.5)};
          border-radius: ${theme.borderRadius.small};
          display: flex;
          align-items: center;
          justify-content: center;
          background: ${theme.colors.primary50};
          color: ${theme.colors.white};
          transition: background-color 0.2s, color 0.2s;
          :hover {
            background-color: ${theme.colors.primary5};
            color: ${theme.colors.primary50};
          }
        `}
        trackingId='nav-portal-btn-quick-action'
      >
        <Icon name='plus' size={22} />
      </NakedButton>
      <PopoverMenu {...getMenuProps()}>
        {visibleActions.map((action, index) => (
          <PopoverMenuItem
            key={action.label}
            {...getItemProps({
              index,
              onClick: action.onClick,
              disabled: action.disabled,
            })}
          >
            {action.label}
          </PopoverMenuItem>
        ))}
      </PopoverMenu>
    </>
  );
};
ActionBar.Action = Action;
