import { useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import { BarChartAppearance, BarChartData, Chart } from '@frontend/charts';
import { i18next, useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { useLocations } from '../../../hooks';
import { formatDateByTimezone, formatters, getHourlyInsightsLabel, hourlyInsightsLimiter } from '../../../utils';
import { DemoChip } from '../../demo-chip';
import { InfoTipPopover } from '../../info-tip-popover';
import { useFetchAggregatedCallAnswerRate, usePhoneAnalyticsShallowStore } from '../hooks';
import { ChartProps } from './shared-types';

const colors = {
  incoming: theme.colors.secondary.seaweed30,
  weaveAverage: theme.colors.primary20,
};

const labels = {
  incoming: i18next.t('Incoming', { ns: 'analytics' }),
  weaveAverage: i18next.t('Weave Average', { ns: 'analytics' }),
};

export const CallAnswerRateChart = ({
  defaultChartAppearance = {},
  exportPdfProps,
  onFetchStateChange,
}: ChartProps) => {
  const { t } = useTranslation('analytics');
  const { locations } = useLocations();
  const { filterHintText, filters, isDemoAccount } = usePhoneAnalyticsShallowStore(
    'filterHintText',
    'filters',
    'isDemoAccount'
  );
  const { data, isHourlyData, isLoading, isMultiLocation } = useFetchAggregatedCallAnswerRate();

  const chartData: BarChartData = useMemo(() => {
    let groups: BarChartData['groups'] = [];

    if (isMultiLocation) {
      // There will be multiple location objects with single/multiple dates
      groups = Object.entries(data?.multiLocationsDailyRate || {}).map(([key, value]) => ({
        name: locations?.[key] || key,
        values: {
          incoming: value,
        },
      }));
    } else {
      // For hourly data: There will be only one object with single date and hourly data for the given location
      // For daily data: There will be only one object with multiple dates for the given location
      const locationData = Object.values(data?.locations || {})[0] || {};

      if (isHourlyData) {
        // Hourly data is restricted to a single date, hence only one object
        const { hourly } = Object.values(locationData || {})[0] || {};
        const limitedHourlyData = hourlyInsightsLimiter(hourly);
        // Because the default time range is before 7 and after 19, the array will have 13 items
        // And, first and last items are combined as Before 7 and After 19, so they need to be averaged
        const before7 = limitedHourlyData[0] / 8; // 8 represents the count of indexes from 0 to 7
        const after19 = limitedHourlyData[limitedHourlyData.length - 1] / 5; // 5 represents the count of indexes from 19 to 23
        limitedHourlyData[0] = before7;
        limitedHourlyData[limitedHourlyData.length - 1] = after19;

        groups = limitedHourlyData.map((value, i) => ({
          name: getHourlyInsightsLabel(i),
          values: {
            incoming: value,
            // We do not have hourly weave averages, so no need to show it
          },
        }));
      } else {
        groups = Object.entries(locationData).map(([date, { daily }], index) => ({
          name: dayjs(date).isValid() ? formatDateByTimezone(date, filters.start_date, filters.time_zone) : date,
          values: {
            incoming: daily,
            // The weave averages array have everyday's average for the given location in the same order as the dates
            weaveAverage: data?.weaveAverage?.[index] || 0,
          },
        }));
      }
    }

    return {
      areaKeys: isMultiLocation || isHourlyData ? [] : ['weaveAverage'],
      groups,
    };
  }, [data, filters, isHourlyData, isMultiLocation, locations]);

  const appearance: Partial<BarChartAppearance> = {
    ...defaultChartAppearance,
    customYAxisTick: undefined, // TODO :: check why TS is forcing to add this prop
    customYAxisTickFormat: formatters.percent.appendPercent,
  };

  useEffect(() => {
    onFetchStateChange?.(isLoading);
  }, [isLoading]);

  return (
    <Chart colors={colors} isLoading={isLoading} labels={labels}>
      <Chart.Header
        {...exportPdfProps}
        infoTip={<InfoTipPopover>{t('Percentage of total incoming calls answered by the location')}</InfoTipPopover>}
        leftElement={isDemoAccount && <DemoChip />}
        subtitle={filterHintText}
        title={t('Call Answer Rate')}
      />
      <Chart.Legends />
      <Chart.BarChart appearance={appearance} data={chartData} formatValue={formatters.percent.appendPercent} />
    </Chart>
  );
};
