import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { Page } from '@frontend/components';
import { useTranslation } from '@frontend/i18n';
import { Tabs } from '@frontend/design-system';
import { AnalyticsPageViewTabs, DemoDataBanner, HelpMenu, InfoTipPopover, PageHeader } from '../components';
import { PhoneAnalyticsFilters, PhoneReportCharts, PhoneReportTable } from '../components/phone';
import {
  useIsPhoneAnalyticsDemoAccount,
  usePhoneAnalyticsDemoData,
  usePhoneAnalyticsShallowStore,
} from '../components/phone/hooks';
import { URLs } from '../constants';
import { useIsMobile } from '../hooks';
import { trackingIds } from '../tracking-ids';
import { pageStyles } from './common-styles';

const DEFAULT_VIEW = 'charts-view';

export const PhoneAnalytics = () => {
  const { t } = useTranslation('analytics');
  const { filterHintText, setDemoData, setIsDemoAccount } = usePhoneAnalyticsShallowStore(
    'filterHintText',
    'setDemoData',
    'setIsDemoAccount'
  );
  const [activeTab, setActiveTab] = useState<string>(DEFAULT_VIEW);
  const [isFetchingTableData, setIsFetchingTableData] = useState<boolean>();
  const [isFetchingChartsData, setIsFetchingChartsData] = useState<boolean>();
  const isMobile = useIsMobile();
  const isDemoAccount = useIsPhoneAnalyticsDemoAccount();
  const demoData = usePhoneAnalyticsDemoData();

  const pageViewTabsRenderer = () => (
    <>
      <PhoneAnalyticsFilters
        isLoadingData={isFetchingChartsData || isFetchingTableData}
        isTableView={activeTab === 'table-view'}
      />
      <HelpMenu feedbackUrl={URLs.PHONE_ANALYTICS_FEEDBACK} helpUrl={URLs.PHONE_ANALYTICS_HELP} />
    </>
  );

  useEffect(() => {
    setDemoData(demoData);
  }, [demoData]);

  useEffect(() => {
    setIsDemoAccount(isDemoAccount);
  }, [isDemoAccount]);

  return (
    <>
      {isDemoAccount && <DemoDataBanner title={t("You're taking Phone Analytics for a test drive with demo data!")} />}
      <Tabs initialTab={DEFAULT_VIEW} onChange={setActiveTab}>
        <Page
          action={
            !isMobile && (
              <AnalyticsPageViewTabs
                renderContent={pageViewTabsRenderer()}
                trackingIds={trackingIds.phoneAnalytics}
                videoId={URLs.PHONE_ANALYTICS_VIDEO_ID}
              />
            )
          }
          css={styles.page}
          subtitle={t('Showing results for {{filtersInfo}}', {
            filtersInfo: filterHintText,
          })}
          title={
            <PageHeader
              title={t('Phone Analytics')}
              infoTip={
                <InfoTipPopover>
                  {t(
                    'A structured record of phone calls received and placed by all devices at your selected office locations. Filter the dataset by your preferred office locations and date range, and export a CSV of raw data.'
                  )}
                </InfoTipPopover>
              }
            />
          }
        >
          {isMobile && (
            <AnalyticsPageViewTabs
              renderContent={pageViewTabsRenderer()}
              trackingIds={trackingIds.phoneAnalytics}
              videoId={URLs.PHONE_ANALYTICS_VIDEO_ID}
            />
          )}
          <div css={pageStyles.panelsWrapper}>
            <Tabs.Panel controller='table-view' css={pageStyles.tabPanel} id='table-view-panel'>
              <PhoneReportTable onFetchStateChange={setIsFetchingTableData} />
            </Tabs.Panel>
            <Tabs.Panel controller='charts-view' css={pageStyles.tabPanel} id='charts-view-panel'>
              <PhoneReportCharts onFetchStateChange={setIsFetchingChartsData} />
            </Tabs.Panel>
          </div>
        </Page>
      </Tabs>
    </>
  );
};

const styles = {
  page: css`
    max-width: 100%;
  `,
};
