import { FC, useMemo, useRef } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Modal, Text, ModalControlModalProps, Tabs, Heading, styles } from '@frontend/design-system';
import { ChannelSettingsController, MIN_MODAL_WIDTH } from '../../../constants';
import { useTeamChatStore } from '../../../providers';
import { StreamConversation } from '../../../types';
import { ChatAvatar } from '../../common';
import { About, Members } from '../conversation-settings';

// Dynamically calculated the width of the conversation name according to the number of members and the width fo avatar
const CONVERSATION_NAME_WIDTH: { [key: number]: number } = { 0: 500, 1: 477, 2: 440, 3: 420, 4: 400 };

interface ConversationSettingsProps {
  modalProps: ModalControlModalProps;
  conversation: StreamConversation;
  initialTab: ChannelSettingsController;
}

export const ConversationSettings: FC<ConversationSettingsProps> = ({ modalProps, conversation, initialTab }) => {
  const { t } = useTranslation('team-chat');
  const { streamClient, currentUser } = useTeamChatStore(['streamClient', 'currentUser']);
  // TODO: added this as comment because we might wanna add the tooltip again
  // const { Tooltip, tooltipProps, triggerProps } = useTooltip({ hoverDelay: 500 });
  const channel = streamClient?.getChannelById('team', conversation.channelId, {});
  const nameRef = useRef<HTMLParagraphElement>(null);
  const modalHeight = conversation.type === 'DM' ? 460 : 570;

  const updatedMembers = currentUser ? [currentUser, ...conversation.members] : conversation.members;

  const dynamicConversationWidth =
    CONVERSATION_NAME_WIDTH[conversation.members.length > 4 ? '4' : conversation.members.length];

  const actions = useMemo(() => {
    const baseActions = [
      {
        title: t('About'),
        controller: ChannelSettingsController.about,
        component: (
          <About
            isDm={conversation.type === 'DM'}
            createdBy={conversation.createdBy}
            createdAt={conversation.createdAt}
            topic={conversation.topic}
            description={conversation.description}
            channel={channel}
            channelName={conversation.name}
          />
        ),
        controls: 'conversation-settings-about',
      },
      {
        title: t('Members'),
        controller: ChannelSettingsController.members,
        component: <Members channel={channel} members={updatedMembers} />,
        controls: 'conversation-settings-members',
        count: updatedMembers.length,
      },
      // TODO: removing this settings as of now, will take further actions after Product's confirmation
      // {
      //   title: t('Notification Settings'),
      //   controller: ChannelSettingsController.notifications,
      //   component: <NotificationsSettings channel={channel} />,
      //   controls: 'conversation-settings-notifications-settings',
      // },
      // TODO: removing this settings as of now, will take further actions after Product's confirmation
      // {
      //   title: conversation.type === 'DM' ? t('Direct Message Settings') : t('Channel Settings'),
      //   controller: ChannelSettingsController.channel,
      //   component: (
      //     <ChannelSettings
      //       channel={channel}
      //       isArchived={conversation.isArchived}
      //       isDm={conversation.type === 'DM'}
      //       isHidden={conversation.isHidden}
      //     />
      //   ),
      //   controls: 'conversation-settings-channel-settings',
      // },
    ];
    return baseActions;
  }, [conversation.type, conversation.members]);

  return (
    <Modal {...modalProps} css={{ minWidth: MIN_MODAL_WIDTH, height: modalHeight, overflow: 'hidden' }}>
      <Tabs initialTab={initialTab}>
        <Modal.Header onClose={modalProps.onClose} css={conversationSettingsStyles.modalHeader}>
          <span css={modalHeadingStyles}>
            {conversation.type === 'DM' ? (
              <ChatAvatar members={updatedMembers} showGroupAvatars />
            ) : (
              <Heading level={2}>#</Heading>
            )}
            <Text css={[conversationNameStyle, { maxWidth: dynamicConversationWidth }]} as='p' ref={nameRef}>
              {conversation.name}
            </Text>
            {/* {nameRef.current?.offsetWidth && nameRef.current.offsetWidth >= dynamicConversationWidth && (
              <Tooltip color='white' {...tooltipProps}>
                <Text color='white'>{conversation.name}</Text>
              </Tooltip>
            )} */}
          </span>
        </Modal.Header>
        <Modal.Body css={modalBodyStyle}>
          <Tabs.Bar>
            {actions.map(({ title, controls, controller, count }) => (
              <Tabs.Tab id={controller} controls={controls} key={`tab-${controller}-${controls}`}>
                <Text id={title} as='span' css={tabContainerStyle}>
                  {title} {count && <Text css={countStyle}>{count}</Text>}
                </Text>
              </Tabs.Tab>
            ))}
          </Tabs.Bar>
          {actions.map(({ component, controller, controls }) => (
            <Tabs.Panel
              controller={controller}
              id={controls}
              key={`tab-component-${controller}-${controls}`}
              css={tabPanelStyle}
            >
              {component}
            </Tabs.Panel>
          ))}
        </Modal.Body>
      </Tabs>
    </Modal>
  );
};

const conversationSettingsStyles = {
  modalHeader: css({ display: 'flex', justifyContent: 'space-between' }),
};

const modalHeadingStyles = css({
  display: 'flex',
  gap: theme.spacing(1),
  alignItems: 'center',
  marginBottom: theme.spacing(1),
});

const tabPanelStyle = css({ height: '100%', overflow: 'auto', flexGrow: 1 });

const modalBodyStyle = css({ overflow: 'hidden', display: 'flex', height: '100%', flexDirection: 'column' });

/* 
  The width is restricted to 400px as per the calculation. The width of all elements minus the width of modal 
  width that leads to 400px.
*/
const conversationNameStyle = css([styles.truncate, { fontSize: theme.fontSize(30) }]);
const tabContainerStyle = css({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(0.5),
});
const countStyle = css({
  fontSize: theme.font.size.small,
  color: theme.colors.primary70,
  backgroundColor: theme.colors.primary10,
  padding: theme.spacing(0, 0.5),
  borderRadius: theme.borderRadius.small,
});
