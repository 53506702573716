import { useTranslation } from '@frontend/i18n';
import { BulkEmailPrefixes } from '@frontend/tracking-prefixes';
import { useContentComposer } from '../content-composer-provider';
import { CustomCodeSection } from '../sections';
import { ToolboxButton } from '../sidebar';

export const CustomCodeTool = () => {
  const { t } = useTranslation('content-composer');
  const { insertSection } = useContentComposer();

  return (
    <ToolboxButton
      icon='terminal'
      title={t('Custom Code')}
      subtitle={t('Add custom HTML and CSS.')}
      onClick={() => insertSection(<CustomCodeSection />)}
      trackingId={`${BulkEmailPrefixes.Composer}-custom-code-tool-btn`}
    >
      <CustomCodeSection />
    </ToolboxButton>
  );
};
