import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';
import { InfoHoverIcon } from '../../info-hover-icon';

export type StatItemData = {
  label: string;
  value?: number | string;
};

type Props = StatItemData & {
  infoHoverText?: string;
};

export const StatItem = ({ label, value = 0, infoHoverText }: Props) => {
  return (
    <div css={styles.wrapper}>
      <Text as='span' className='value' textAlign='left' weight='bold'>
        {value}
      </Text>
      <div css={styles.labelWithTip}>
        <Text as='span' size='medium' textAlign='left'>
          {label}
        </Text>
        {infoHoverText && <InfoHoverIcon text={infoHoverText} />}
      </div>
    </div>
  );
};

const styles = {
  labelWithTip: css`
    align-items: center;
    display: flex;
    gap: ${theme.spacing(0.5)};
    justify-content: flex-start;
  `,

  wrapper: css`
    span {
      display: block;
    }

    .value {
      font-size: ${theme.font.size.h1};
      line-height: 1;
    }
  `,
};
