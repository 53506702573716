import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { ContentLoader, Modal, NakedButton, SecondaryButton, Text } from '@frontend/design-system';
import { useEmailAssistant } from '../../hooks/use-email-assistant';
import { useContentComposer } from '../content-composer-provider';

const MAX_SUBJECT_COUNT = 15;

export const GeneratedSubjectModalContent = ({
  onClose,
  onComplete,
  setMode,
  previousText,
  setPreviousText,
}: {
  onClose: () => void;
  onComplete: (subject: string) => void;
  setMode: (mode: 'prompt' | 'generated') => void;
  previousText?: string;
  setPreviousText: (text?: string) => void;
}) => {
  const { t } = useTranslation('content-composer');
  const [generatedContent, setGeneratedContent] = useState<string>('');
  const { promptProps } = useContentComposer();

  const { generateSubjects, subjects, isLoading, reset } = useEmailAssistant({
    subject: '',
    body: [],
  });

  const startOver = () => {
    setMode('prompt');
    setPreviousText(undefined);
    reset();
  };

  const generateSubject = () => {
    if (promptProps.value === '') return;
    generateSubjects(promptProps.value);
  };

  useEffect(() => {
    if (!prompt) {
      setMode('prompt');
      return;
    }
    if (!previousText || subjects?.length < 15) {
      generateSubject();
    }
  }, [prompt]);

  return (
    <>
      <Modal.Header onClose={onClose}>
        <Text weight='regular'>
          {t('Alright, I generated some subjects for your email campaign. Please select one:')}
        </Text>
      </Modal.Header>
      <Modal.Body
        css={css`
          padding-top: ${theme.spacing(2)};
          min-height: 120px;
          button {
            margin-right: ${theme.spacing(1)};
            margin-bottom: ${theme.spacing(1)};
          }
        `}
      >
        <ContentLoader show={isLoading} />
        {[...new Set(subjects)]?.map((subject, index) => (
          <SecondaryButton
            key={index}
            size='tiny'
            onClick={() => setGeneratedContent(subject)}
            css={css`
              background: ${generatedContent === subject ? theme.colors.neutral10 : 'transparent'};
            `}
          >
            {subject}
          </SecondaryButton>
        ))}
        {!isLoading && subjects && subjects?.length < MAX_SUBJECT_COUNT && (
          <NakedButton
            css={css`
              color: ${theme.colors.primary50};
            `}
            onClick={generateSubject}
          >
            {t('Load More...')}
          </NakedButton>
        )}
      </Modal.Body>
      <Modal.Actions
        backLabel={
          <NakedButton
            css={css`
              display: flex;
              align-items: center;
              gap: ${theme.spacing(0.5)};
            `}
          >
            <Icon name='send-back-small' color='primary' />
            <Text as='span' color='primary'>
              {t('Start Over')}
            </Text>
          </NakedButton>
        }
        onBackClick={startOver}
        secondaryLabel={t('Cancel')}
        onSecondaryClick={onClose}
        primaryLabel={t('Insert')}
        disablePrimary={!generatedContent || isLoading}
        onPrimaryClick={() => {
          onComplete(generatedContent);
          onClose();
        }}
      />
    </>
  );
};
