import { ReactNode } from 'react';
import { css, SerializedStyles } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Button, ButtonBar, IconButton, Modal, ModalControlModalProps, Text } from '@frontend/design-system';

type Props = ModalControlModalProps & {
  title: string;
  children?: ReactNode;
  bodyStyles?: SerializedStyles;
  trackingId?: string;
};

export const BulkEmailModal = ({ title, bodyStyles, children, trackingId, ...rest }: Props) => (
  <Modal
    css={css`
      min-width: 600px;
    `}
    {...rest}
  >
    <Modal.Header
      css={css`
        margin-bottom: ${theme.spacing(0)};
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: ${theme.spacing(2)};
        `}
      >
        <Text
          css={css`
            font-size: ${theme.fontSize(24)};
            text-align: left;
          `}
        >
          {title}
        </Text>
        <IconButton label='' onClick={rest.onClose} trackingId={`${trackingId}-close-btn`}>
          <Icon
            name='x-small'
            css={css`
              min-width: ${theme.spacing(3)};
              width: ${theme.spacing(3)};
              height: ${theme.spacing(3)};
            `}
          />
        </IconButton>
      </div>
    </Modal.Header>
    <Modal.Body
      css={[
        css`
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        `,
        bodyStyles,
      ]}
    >
      {children}
    </Modal.Body>
  </Modal>
);

type ButtonBarProps = {
  onAction: () => void;
  onCancel: () => void;
  actionText?: string;
  cancelText?: string;
  trackingId?: string;
};

const BulkEmailModalButtonBar = ({ onAction, onCancel, cancelText, actionText, trackingId }: ButtonBarProps) => {
  const { t } = useTranslation('bulk-messaging');
  const cancelLabel = cancelText ?? t('Cancel');
  const actionLabel = actionText ?? t('Confirm');

  return (
    <ButtonBar
      css={css`
        margin-top: ${theme.spacing(2)};
      `}
    >
      <Button
        variant='secondary'
        size='small'
        css={bottomBtn}
        onClick={onCancel}
        trackingId={`${trackingId}-cancel-btn`}
      >
        {cancelLabel}
      </Button>
      <Button size='small' css={[bottomBtn, actionBtn]} onClick={onAction} trackingId={`${trackingId}-action-btn`}>
        {actionLabel}
      </Button>
    </ButtonBar>
  );
};

BulkEmailModalButtonBar.displayName = 'BulkEmailModalButtonBar';
BulkEmailModal.ButtonBar = BulkEmailModalButtonBar;

const bottomBtn = css`
  max-width: fit-content;
`;

const actionBtn = css`
  background: ${theme.colors.critical50};
  border-color: ${theme.colors.critical60};

  &:hover {
    background: ${theme.colors.critical40};
    border-color: ${theme.colors.critical50};
  }
`;

type ContainerProps = {
  children?: ReactNode;
};

const BulkEmailModalTextContainer = ({ children }: ContainerProps) => {
  return (
    <div
      css={css`
        padding: ${theme.spacing(0, 3)};
      `}
    >
      {children}
    </div>
  );
};

BulkEmailModalTextContainer.displayName = 'BulkEmailModalTextContainer';
BulkEmailModal.TextContainer = BulkEmailModalTextContainer;

const BulkEmailModalRecipientsContainer = ({ children }: ContainerProps) => {
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        max-height: 340px;
        overflow-y: auto;
      `}
    >
      {children}
    </div>
  );
};

BulkEmailModalRecipientsContainer.displayName = 'BulkEmailModalRecipientsContainer';
BulkEmailModal.RecipientsContainer = BulkEmailModalRecipientsContainer;

const BulkEmailModalButtonContainer = ({ children }: ContainerProps) => {
  return (
    <div
      css={css`
        display: flex;
        justify-content: flex-end;
      `}
    >
      {children}
    </div>
  );
};

BulkEmailModalButtonContainer.displayName = 'BulkEmailModalButtonContainer';
BulkEmailModal.ButtonContainer = BulkEmailModalButtonContainer;
