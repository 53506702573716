export const GoogleSvg = ({ fillColor }: { fillColor?: string }) => (
  <svg
    height={24}
    width={24}
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    version='1.1'
    baseProfile='full'
  >
    <path
      fill={fillColor}
      fillRule='evenodd'
      d='M18.01 2.63h-11a4.5 4.5 0 0 0-4.5 4.5v11a4.5 4.5 0 0 0 4.5 4.5h11a4.5 4.5 0 0 0 4.5-4.5v-11a4.5 4.5 0 0 0-4.5-4.5m-13 4.5a2 2 0 0 1 2-2h11a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2h-11a2 2 0 0 1-2-2v-11m7.623 1.886c.899 0 1.706.303 2.34.898l1.756-1.721c-1.06-.969-2.446-1.563-4.096-1.563A6.134 6.134 0 0 0 7.16 9.935a5.9 5.9 0 0 0 0 5.39 6.134 6.134 0 0 0 5.472 3.305c1.653 0 3.039-.537 4.052-1.454 1.157-1.044 1.825-2.582 1.825-4.41 0-.425-.039-.834-.111-1.227h-5.766v2.32h3.295a2.758 2.758 0 0 1-1.222 1.812c-.548.36-1.25.573-2.073.573-1.595 0-2.945-1.056-3.426-2.474a3.54 3.54 0 0 1-.192-1.14c0-.396.07-.78.192-1.14.481-1.418 1.831-2.474 3.426-2.474'
      clipRule='evenodd'
    />
  </svg>
);
