import { useQueryClient } from 'react-query';
import { PortingQueries } from '@frontend/api-porting';
import { getUser } from '@frontend/auth-helpers';
import { i18next } from '@frontend/i18n';
import { SchemaInsysAgreementsAPI, SchemaPortingDataService, SchemaAgreementContentService } from '@frontend/schema';
import { useAppScopeStore } from '@frontend/scope';
import { sentry } from '@frontend/tracking';
import { useAlert } from '@frontend/design-system';
import { preparePortingDataRequest, preparePortingRequestsData } from '../helper';
import { usePortOrderCreationStore } from '../providers';
import { StorePortOrderType } from '../types';

export const useCreateOrUpdatePortOrder = () => {
  const alert = useAlert();
  const user = getUser();
  const queryClient = useQueryClient();
  const { selectedLocationIds } = useAppScopeStore();
  const {
    setIsSaving,
    storePortOrder,
    portOrderResponse,
    isPortOrderModified,
    isNumbersModified,
    setPortOrderResponse,
  } = usePortOrderCreationStore([
    'isSaving',
    'setIsSaving',
    'storePortOrder',
    'portOrderResponse',
    'setPortOrderResponse',
    'isPortOrderModified',
    'isNumbersModified',
  ]);

  const invalidatePortRequestsTableData = () => {
    queryClient.invalidateQueries(PortingQueries.queryKeys.portRequests(selectedLocationIds));
  };

  const createOrUpdatePortOrder = async ({ isSubmit = false }: { isSubmit?: boolean } = {}) => {
    if (!isPortOrderModified && !isSubmit) {
      return;
    }

    setIsSaving(true);
    const isDraft = !isSubmit;
    const isCreatePortOrder = !portOrderResponse?.id;
    try {
      const portOrderRequest = preparePortingDataRequest(
        storePortOrder as StorePortOrderType,
        portOrderResponse,
        isDraft
      );
      // create port order (Note: create will always be draft case as per the current design)
      if (isCreatePortOrder) {
        const res = await SchemaPortingDataService.BatchCreate({ portingData: portOrderRequest });
        setPortOrderResponse(res.portingData?.[0]);
        invalidatePortRequestsTableData();
        return;
      }
      // update port order
      if (!isDraft) {
        // If the user is not creating a draft, then this means they are submitting this port order
        // and so we need to create an agreement to associate with this port order.

        // @ts-expect-error: Need to ignore this error. It is a bug in the schema-gen-ts code.
        // The schema expects a number but there is an incorrect check in the schema-gen-ts
        // code where it will treat number 0 as empty and throw an error.
        // Error here --> https://github.com/weave-lab/schema-gen-ts/blob/4f360690cd41e1b20f7d60d6c9374bc5abcce71f/src/fetch.pb.ts#L249
        const agreementContent = await SchemaAgreementContentService.ReadByFeature({ feature: '0' });

        const newAgreement = await SchemaInsysAgreementsAPI.Create({
          agreementContentId: agreementContent.id,
          firstName: user?.firstName ?? '',
          lastName: user?.lastName ?? '',
          userEmail: user?.username ?? '',
          userId: user?.userID ?? '',
        });

        portOrderRequest.agreementId = newAgreement.id;
      }

      // Update the porting data.
      // NOTE: When updating the porting data, do not send any new phone numbers (in the
      // portingRequests array). Those need to be updated through the processPortingRequestsByPhoneNumber
      // API call.
      const res = await SchemaPortingDataService.Update({
        portingData: portOrderRequest,
        portingDataId: portOrderRequest.id,
      });

      if (isNumbersModified) {
        const newPortingRequests = preparePortingRequestsData({
          storePortOrder: storePortOrder as StorePortOrderType,
          portingData: res.portingData,
          isDraft,
          isPutCall: false,
        });
        // We need to call this API to process any changes to the porting data with any
        // phone numbers that were added or removed. If the port request status is changed,
        // which occurs when submitting the port request, then we also need to call this endpoint.
        const portingRequestsRes = await SchemaPortingDataService.ProcessPortingRequestsByPhoneNumber({
          portingRequests: newPortingRequests,
        });

        // newPortingData is a combination of the porting data from the backend and the
        // changes made to the porting requests numbers through the processPortingRequestsByPhoneNumber
        // API call.
        setPortOrderResponse({
          ...res.portingData,
          portingRequests: portingRequestsRes.portingRequests,
        });
        invalidatePortRequestsTableData();
        return;
      }

      if (storePortOrder.isSMSHosting && !isDraft) {
        // If the user is not creating a draft (meaning they are submitting this port order)
        // and this is an sms hosting order, then we need to call the SMS hosting API to
        // submit the order to the SMS hosting provider.
        await SchemaPortingDataService.PortInSMSCreateByPortingDataID({
          portingDataId: portOrderRequest.id,
        });
      }

      setPortOrderResponse(res.portingData);
      invalidatePortRequestsTableData();
    } catch (error) {
      alert.error(
        i18next.t('Error occur while {{operation}} port order as draft', {
          ns: 'porting',
          operation: isCreatePortOrder ? 'creating' : 'updating',
        })
      );
      sentry.error({
        error: 'Error occur while creating/updating port order',
        topic: 'onboarding',
        addContext: {
          name: 'details',
          context: {
            error,
            isCreatePortOrder,
          },
        },
      });
      // Note: need to throw error to handle it on stepper component to keep user on the same step
      throw error;
    } finally {
      setIsSaving(false);
    }
  };

  return { createOrUpdatePortOrder };
};
