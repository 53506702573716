import { css } from '@emotion/react';
import { CallIntelligenceTypes } from '@frontend/api-analytics';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Accordion, emptyStateGraphics, Text, TextLink } from '@frontend/design-system';
import { analyzingCallLottie } from '../../assets';
import { generateTrackingId } from '../../tracking';
import { AnalyzingCallLoader } from './analyzing-call-loader';
import { CallAnalyzeStatus } from './call-analyze-status';
import { useCallIntelShallowStore } from './hooks';
import { ServiceQualityBadge } from './service-quality-badge';

// Utility functions
export const formatServiceQualityTime = (timeString: string) => {
  return timeString.split(':').slice(0, 2).join(':');
};

type CallStatus = {
  isFailedCall?: boolean;
  isPoorAudio?: boolean;
  isSkippedCall?: boolean;
};
type ServiceQualityCardProps = {
  flagData: CallIntelligenceTypes.ServiceQualityFlagData[];
  isDemoAccount?: boolean;
  label: string;
  onSelectingCitationTime?: (citationText: string, citationTime: string) => void;
  type: CallIntelligenceTypes.ServiceQualityFlagEnum;
};
type ServiceQualityProps = {
  call: CallIntelligenceTypes.Call;
  callStatus: CallStatus;
  isDemoAccount?: boolean;
  isLoading: boolean;
  onSelectingCitationTime?: (citationText: string, citationTime: string) => void;
  serviceQuality?: CallIntelligenceTypes.ServiceQualityResponse;
};

export const serviceQualityMapping: Record<
  keyof CallIntelligenceTypes.ServiceQualityResponse,
  Exclude<CallIntelligenceTypes.ServiceQualityFlagEnum, CallIntelligenceTypes.ServiceQualityFlagEnum.FLAG_UNKNOWN>
> = {
  resolution: CallIntelligenceTypes.ServiceQualityFlagEnum.FLAG_EXCELLENT_RESOLUTION,
  issues: CallIntelligenceTypes.ServiceQualityFlagEnum.FLAG_UNRESOLVED_ISSUE,
};

const EmptyServiceQuality = ({ isFailedCall, isPoorAudio, isSkippedCall }: CallStatus) => {
  const { t } = useTranslation('analytics');
  const EmptyStateGraphicNoTasks = emptyStateGraphics.no_service_quality;

  return (
    <div css={styles.emptyContainer}>
      <CallAnalyzeStatus
        isFailedCall={isFailedCall}
        isPoorAudio={isPoorAudio}
        isSkippedCall={isSkippedCall}
        type='analysis'
      />
      <EmptyStateGraphicNoTasks height={200} width={200} />
      <Text css={styles.emptyStateText}>{t('Nothing to review or spotlight')}</Text>
    </div>
  );
};

const ServiceQualityCard = ({
  flagData,
  isDemoAccount,
  label,
  onSelectingCitationTime,
  type,
}: ServiceQualityCardProps) => {
  const { t } = useTranslation('analytics');

  return (
    <div css={styles.serviceQualityCard}>
      <ServiceQualityBadge label={label} size='large' type={type} weight='bold' />
      <Text color='subdued' css={styles.detailsText}>
        {t('Details')}
      </Text>
      <div>
        {flagData.map((item, index) => {
          // Removing the final period from the summary if it exists
          const summaryWithoutPeriod = item.summary.replace(/\.$/, '');

          return (
            <Text as='span' key={index}>
              {summaryWithoutPeriod}{' '}
              <span css={styles.timeText}>
                (
                <TextLink
                  css={styles.textLink}
                  onClick={() => {
                    !!onSelectingCitationTime && onSelectingCitationTime('', formatServiceQualityTime(item.tsStart));
                  }}
                  trackingId={generateTrackingId({
                    component: 'service-quality-card',
                    context: 'timestamp-click',
                    isDemoAccount,
                  })}
                >
                  {formatServiceQualityTime(item.tsStart)}
                </TextLink>
                )
              </span>
              .{' '}
            </Text>
          );
        })}
      </div>
      <div className='service-quality-divider' css={styles.divider} />
    </div>
  );
};

export const ServiceQuality = ({
  call,
  callStatus,
  isDemoAccount,
  isLoading,
  onSelectingCitationTime,
  serviceQuality,
}: ServiceQualityProps) => {
  const { t } = useTranslation('analytics');
  const { dataLabels } = useCallIntelShallowStore('dataLabels');

  return (
    <>
      <Accordion.Header title={t('Service Quality')} />
      <Accordion.Body>
        {isLoading ? (
          <AnalyzingCallLoader
            lottie={analyzingCallLottie}
            message={t('Analyzing call recording...')}
            messageCss={styles.loadingMessage}
          />
        ) : call.serviceQualityFlags?.length === 0 ? (
          <EmptyServiceQuality {...callStatus} />
        ) : (
          !!serviceQuality &&
          Object.entries(serviceQualityMapping).map(([dataKey, serviceQualityFlag]) => {
            const flagData = serviceQuality[dataKey as keyof CallIntelligenceTypes.ServiceQualityResponse];
            if (!flagData || flagData.length === 0) return null;
            return (
              <ServiceQualityCard
                isDemoAccount={isDemoAccount}
                key={serviceQualityFlag}
                label={dataLabels.serviceQualityFlag?.[serviceQualityFlag] || serviceQualityFlag}
                type={serviceQualityFlag as CallIntelligenceTypes.ServiceQualityFlagEnum}
                flagData={flagData}
                onSelectingCitationTime={onSelectingCitationTime}
              />
            );
          })
        )}
      </Accordion.Body>
    </>
  );
};

const styles = {
  serviceQualityCard: css`
    &:last-child .service-quality-divider {
      display: none;
    }
  `,
  divider: css`
    height: ${theme.spacing(0.125)};
    background-color: ${theme.colors.neutral20};
    margin: ${theme.spacing(2, 0)};
  `,
  detailsText: css`
    margin-top: ${theme.spacing(1)};
    margin-bottom: ${theme.spacing(0.5)};
  `,
  emptyContainer: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `,
  emptyStateText: css`
    color: ${theme.colors.neutral50};
  `,
  loadingMessage: css`
    margin-top: ${theme.spacing(-3)};
  `,
  timeText: css`
    text-wrap: nowrap;
  `,
  textLink: css`
    cursor: pointer;
  `,
};
