import { ReactElement } from 'react';
import { useEditor } from '@craftjs/core';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { Icon, IconName } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Chip, ListRow } from '@frontend/design-system';
import { useContentComposer } from '../content-composer-provider';

export type ToolboxButtonProps = {
  icon?: IconName;
  title?: string;
  subtitle?: string;
  showBetaLabel?: boolean;
  onClick: () => void;
  children?: ReactElement;
  trackingId?: string;
};

export const ToolboxButton = ({
  icon,
  title,
  subtitle,
  onClick,
  children,
  showBetaLabel,
  ...rest
}: ToolboxButtonProps) => {
  const { connectors } = useEditor();
  const { composerMode } = useContentComposer();
  const isFeedbackMode = composerMode == 'feedback';

  const { t } = useTranslation('content-composer');

  return (
    <ListRow
      as='button'
      onClick={onClick}
      css={css`
        cursor: pointer;
        font-size: initial;
        align-items: center;
        text-align: left;
        padding: ${theme.spacing(1, 2)};
        background: ${theme.colors.white};
        box-shadow: ${theme.shadows.light};
        border: solid 1px transparent;
        border-radius: ${theme.borderRadius.small};
        transition: all 250ms ease;

        &:hover,
        &:focus {
          outline: none;
          box-shadow: ${theme.shadows.floating};
          background: ${theme.colors.neutral5};
        }
      `}
      {...rest}
      ref={(ref) => (ref && children ? connectors.create(ref, children) : null)}
    >
      {icon && (
        <ListRow.Lead>
          <Icon
            name={icon}
            css={css`
              max-width: initial;
              color: ${isFeedbackMode ? theme.colors.neutral50 : 'inherit'};
            `}
          />
        </ListRow.Lead>
      )}
      {(title || subtitle) && (
        <ListRow.Content>
          {title && <ListRow.Content.Title>{title}</ListRow.Content.Title>}
          {subtitle && <ListRow.Content.Subtitle>{subtitle}</ListRow.Content.Subtitle>}
        </ListRow.Content>
      )}
      {showBetaLabel && (
        <Chip
          variant='seaweed'
          css={{
            justifySelf: 'flex-end',
            width: '49px',
            height: '32px',
            padding: theme.spacing(0.5),
          }}
        >
          {t('Beta')}
        </Chip>
      )}
    </ListRow>
  );
};
