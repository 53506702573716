import { GetCoverageStatusCountsByOrgRequest } from '@weave/schema-gen-ts/dist/schemas/insurance-verification/v2/insurance_verification_adapter.pb';
import { useQuery, UseQueryOptions } from 'react-query';
import { SchemaInsuranceVerificationAdapterServiceV2 } from '@frontend/schema';
import { useGetInsuranceAlertsByOrgIdOptions } from './types';

const queryKeys = {
  base: ['insurance'] as const,
  insuranceAlertsCount: (request: GetCoverageStatusCountsByOrgRequest) => [
    ...queryKeys.base,
    'insurance-alerts-count',
    request,
  ],
};

export const useGetInsuranceAlertsByOrgId = (
  request: GetCoverageStatusCountsByOrgRequest,
  options: UseQueryOptions<useGetInsuranceAlertsByOrgIdOptions>
) => {
  const { orgId, locationIds, startDate, endDate } = request;
  return useQuery({
    queryKey: queryKeys.insuranceAlertsCount({ orgId, locationIds, startDate, endDate }),
    queryFn: () =>
      SchemaInsuranceVerificationAdapterServiceV2.GetCoverageStatusCountsByOrg({
        orgId,
        locationIds,
        startDate,
        endDate,
      }),
    enabled: !!options.enabled,
  });
};
