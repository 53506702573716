import {
  InboxLocationSetting,
  SettingType_Enum,
} from '@weave/schema-gen-ts/dist/schemas/messaging/settings/v1/settings.pb';
import { useQueryClient } from 'react-query';
import { useAppScopeStore } from '@frontend/scope';
import { useSetMutation } from '../mutations/use-set';
import { useGetQuery, getGetQueryKey } from '../queries/use-get';

export const useGetActionableTabSetting = (enabled = true) => {
  const { selectedLocationIds, selectedOrgId } = useAppScopeStore();

  const { data, isLoading } = useGetQuery(
    {
      settingType: SettingType_Enum.INBOX_LOCATION_SETTING,
      orgId: selectedOrgId,
      locationId: selectedLocationIds[0],
    },
    {
      enabled: enabled,
    }
  );

  const setting = data?.setting as { inboxLocationSetting: InboxLocationSetting } | undefined;
  return {
    data: !!setting?.inboxLocationSetting.enableActionableTab,
    isLoading: isLoading,
  };
};

export const useSetActionableTabSetting = () => {
  const queryClient = useQueryClient();
  const { selectedLocationIds, selectedOrgId } = useAppScopeStore();

  const { mutate: setEnableActionableTab } = useSetMutation({
    options: {
      onSuccess: (_, requestData) => {
        const { setting, ...getRequestData } = requestData;
        queryClient.invalidateQueries({ queryKey: getGetQueryKey(getRequestData) });
      },
    },
  });

  return (enableActionableTab: boolean) => {
    setEnableActionableTab({
      settingType: SettingType_Enum.INBOX_LOCATION_SETTING,
      setting: {
        // @ts-ignore  // TODO: remove after BE supports sending only enableActionableTab field
        inboxLocationSetting: {
          enableActionableTab,
        },
      },
      orgId: selectedOrgId,
      locationId: selectedLocationIds[0],
    });
  };
};
