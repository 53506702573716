import { css } from '@emotion/react';

export const containerStyle = css`
  display: flex;
  align-items: center;
`;

export const textStyle = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
