import { Options } from '@frontend/fetch';
import { SchemaIO, SchemaTenDLCCoordinator } from '@frontend/schema';

export const getLocationTcrBrand = (
  req: SchemaIO<(typeof SchemaTenDLCCoordinator)['GetLocationTCRBrand']>['input'],
  opts?: Options
) =>
  SchemaTenDLCCoordinator.GetLocationTCRBrand(req, opts) satisfies Promise<
    SchemaIO<(typeof SchemaTenDLCCoordinator)['GetLocationTCRBrand']>['output']
  >;

export const putConnectLocationTcrBrand = (
  req: SchemaIO<(typeof SchemaTenDLCCoordinator)['ConnectLocationToTCRBrand']>['input'],
  opts?: Options
) =>
  SchemaTenDLCCoordinator.ConnectLocationToTCRBrand(req, opts) satisfies Promise<
    SchemaIO<(typeof SchemaTenDLCCoordinator)['ConnectLocationToTCRBrand']>['output']
  >;

export const putAssignLocationTcrBrand = (
  req: SchemaIO<(typeof SchemaTenDLCCoordinator)['AssignTCRBrandLocation']>['input'],
  opts?: Options
) =>
  SchemaTenDLCCoordinator.AssignTCRBrandLocation(req, opts) satisfies Promise<
    SchemaIO<(typeof SchemaTenDLCCoordinator)['AssignTCRBrandLocation']>['output']
  >;

export const postPrivateProfitAsync = (
  req: SchemaIO<(typeof SchemaTenDLCCoordinator)['RegisterPrivateProfitBrandAsync']>['input'],
  opts?: Options
) =>
  SchemaTenDLCCoordinator.RegisterPrivateProfitBrandAsync(req, opts) satisfies Promise<
    SchemaIO<(typeof SchemaTenDLCCoordinator)['RegisterPrivateProfitBrandAsync']>['output']
  >;

export const postSoleProprietor = (
  req: SchemaIO<(typeof SchemaTenDLCCoordinator)['RegisterSoleProprietorBrand']>['input'],
  opts?: Options
) =>
  SchemaTenDLCCoordinator.RegisterSoleProprietorBrand(req, opts) satisfies Promise<
    SchemaIO<(typeof SchemaTenDLCCoordinator)['RegisterSoleProprietorBrand']>['output']
  >;

export const updateBusinessInfo = (
  req: SchemaIO<(typeof SchemaTenDLCCoordinator)['UpdateTCRBrand']>['input'],
  opts?: Options
) =>
  SchemaTenDLCCoordinator.UpdateTCRBrand(req, opts) satisfies Promise<
    SchemaIO<(typeof SchemaTenDLCCoordinator)['UpdateTCRBrand']>['output']
  >;

export const getEinLookup = (req: SchemaIO<(typeof SchemaTenDLCCoordinator)['SearchEIN']>['input']) =>
  SchemaTenDLCCoordinator.SearchEIN(req) satisfies Promise<
    SchemaIO<(typeof SchemaTenDLCCoordinator)['SearchEIN']>['output']
  >;

export const getReverseEinLookup = (payload: { company: string; state?: string; city?: string; zipCode?: string }) =>
  SchemaTenDLCCoordinator.SearchEIN({
    company: payload.company.replace(/&/g, '%26'),
    state: payload.state,
    city: payload.city,
    zipCode: payload.zipCode,
  }) satisfies Promise<SchemaIO<(typeof SchemaTenDLCCoordinator)['SearchEIN']>['output']>;

export const getLockStatus = (req: SchemaIO<(typeof SchemaTenDLCCoordinator)['GetTCRBrandLockStatus']>['input']) =>
  SchemaTenDLCCoordinator.GetTCRBrandLockStatus(req) satisfies Promise<
    SchemaIO<(typeof SchemaTenDLCCoordinator)['GetTCRBrandLockStatus']>['output']
  >;
