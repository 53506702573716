import { memo, useEffect } from 'react';
import { css } from '@emotion/react';
import { useNavigate } from '@tanstack/react-location';
import { Permission } from '@weave/schema-gen-ts/dist/shared/waccess/acls.pb';
import { AnalyticsCommonTypes } from '@frontend/api-analytics';
import { Chart } from '@frontend/charts';
import { useTranslation } from '@frontend/i18n';
import { useHasFeatureFlag } from '@frontend/shared';
import { theme } from '@frontend/theme';
import { AnalyticsTrackingIds } from '../..';
import { URLs } from '../../constants';
import { featureFlags } from '../../feature-flags';
import { useHasAclAccess } from '../../hooks';
import { appendTime, formatters } from '../../utils';
import { DemoChip } from '../demo-chip';
import { useFetchAggregatedCallsSummary, useIsPhoneAnalyticsDemoAccount } from '../phone/hooks';
import { Insights } from './insights';
import { PhoneSummary } from './types';

type Props = {
  filters?: AnalyticsCommonTypes.AnalyticsSummaryFilters;
  onLoadData?: (summary: PhoneSummary) => void;
  onLoadingStateChange?: (isLoading?: boolean) => void;
};

export const PhoneIncomingCategoriesOnDashboard = memo(({ filters, onLoadData, onLoadingStateChange }: Props) => {
  const { t } = useTranslation('analytics');
  const navigate = useNavigate();
  const phoneAnalyticsEnabled = useHasFeatureFlag(featureFlags.phoneAnalytics);
  const isDemoAccount = useIsPhoneAnalyticsDemoAccount();
  const hasAclAccess = useHasAclAccess([Permission.ANALYTICS_READ]);

  const { data, isLoading } = useFetchAggregatedCallsSummary({
    end_date: appendTime(filters?.endDate),
    location_id: filters?.locationIds,
    start_date: appendTime(filters?.startDate),
    time_zone: filters?.timeZone,
  });

  const incomingCalls = {
    abandoned: data?.answerRate?.abandoned || 0,
    answered: data?.answerRate?.answered || 0,
    missed: data?.answerRate?.missed || 0,
    total: data?.totalCalls?.inbound || 0,
  };

  const forwardedCalls =
    incomingCalls.total - (incomingCalls.abandoned + incomingCalls.answered + incomingCalls.missed);

  useEffect(() => {
    onLoadingStateChange?.(isLoading);
  }, [isLoading]);

  useEffect(() => {
    onLoadData?.({
      answered: data?.answerRate?.answered || 0,
      incoming: data?.totalCalls?.inbound || 0,
      total: data?.totalCalls?.total || 0,
    });
  }, [data]);

  if (!phoneAnalyticsEnabled) {
    return null;
  }

  return (
    <Chart
      colors={{
        abandoned: theme.colors.warning50,
        answered: theme.colors.primary40,
        forwarded: theme.colors.secondary.seaweed30,
        missed: theme.colors.critical30,
      }}
      commonTooltipLabel={t('Calls')}
      isLoading={isLoading}
      labels={{
        abandoned: t('Abandoned'),
        answered: t('Answered'),
        forwarded: t('Forwarded'),
        missed: t('Missed'),
        total: t('Total'),
      }}
    >
      <Chart.Header
        actions={[
          {
            label: t('Show More'),
            omit: !hasAclAccess,
            onClick: () => {
              navigate({
                to: URLs.PHONE_ANALYTICS,
              });
            },
            trackingId: AnalyticsTrackingIds.analytics.showMorePhoneAnalytics,
          },
        ]}
        leftElement={isDemoAccount && <DemoChip />}
        title={t('Incoming Phone Calls')}
      />
      <Chart.HorizontalContainer css={styles.chartWrapper} alignItems='center'>
        <Chart.PieChart
          data={{
            centerMetric: formatters.value.format(incomingCalls.total),
            groups: [
              {
                name: 'forwarded',
                value: forwardedCalls,
              },
              {
                name: 'abandoned',
                value: incomingCalls.abandoned,
              },
              {
                name: 'missed',
                value: incomingCalls.missed,
              },
              {
                name: 'answered',
                value: incomingCalls.answered,
              },
            ],
          }}
        />
        {!!incomingCalls.total && (
          <Chart.Legends
            layout='vertical'
            values={{
              answered: formatters.value.format(incomingCalls.answered),
              forwarded: formatters.value.format(forwardedCalls),
              missed: formatters.value.format(incomingCalls.missed),
              abandoned: formatters.value.format(incomingCalls.abandoned),
            }}
          />
        )}
      </Chart.HorizontalContainer>
      <Insights title={t('Call Answer Rate')} value={`${(data?.answerRate?.rate || 0).toFixed(2)}%`} />
    </Chart>
  );
});

PhoneIncomingCategoriesOnDashboard.displayName = 'PhoneIncomingCategoriesOnDashboard';

const styles = {
  chartWrapper: css`
    flex-direction: row;
  `,
};
