import { css } from '@emotion/react';
import { Form, Packet } from '@weave/schema-gen-ts/dist/schemas/forms-digital/weave_digital_forms.pb';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import {
  Heading,
  Modal,
  ModalControlModalProps,
  NakedButton,
  RadioField,
  SearchField,
  Text,
  useForm,
} from '@frontend/design-system';

interface Props {
  modalProps: ModalControlModalProps;
  documents: (Form | Packet)[];
  documentId?: string;
  updateDocumentId: (id: string) => void;
}

export const DocumentSelectorModal = ({ modalProps, documents, documentId, updateDocumentId }: Props) => {
  const { t } = useTranslation('content-composer');

  const { getFieldProps } = useForm({
    fields: {
      document: { type: 'radio', value: documentId },
      search: { type: 'text', value: '' },
    },
  });

  const searchFieldProps = getFieldProps('search');
  const documentFieldProps = getFieldProps('document');

  return (
    <Modal
      {...modalProps}
      css={css`
        min-width: ${theme.spacing(71)};
        height: 600px;
      `}
    >
      <Modal.Header>
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
          `}
        >
          <div>
            <Heading
              level={2}
              css={css`
                margin-bottom: ${theme.spacing(1)};
              `}
            >
              {t('Select a Form')}
            </Heading>
            <Text
              weight='regular'
              css={css`
                color: ${theme.colors.neutral50};
              `}
            >
              {t('Choose a form to attach')}
            </Text>
          </div>
          <NakedButton onClick={modalProps.onClose}>
            <Icon name='x-small' />
          </NakedButton>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div
          css={css`
            margin-top: ${theme.spacing(4)};
            width: 100%;
          `}
        >
          <SearchField {...searchFieldProps} id='search' name='search' placeholder={t('Search')} />
          <RadioField
            {...documentFieldProps}
            label=''
            name='document'
            css={css`
              overflow-y: auto;
              margin: ${theme.spacing(2, 0, 4)};
              & > label {
                display: none;
              }
              // targets the radio buttons
              & > div {
                padding: ${theme.spacing(2)};
                margin-bottom: 0;
              }
            `}
          >
            {documents
              .filter((document) => document.name?.toLowerCase()?.includes(searchFieldProps.value.toLowerCase()))
              .map((document, index) => (
                <RadioField.Radio key={index} value={document.id ?? ''}>
                  {document.name}
                </RadioField.Radio>
              ))}
          </RadioField>
        </div>
      </Modal.Body>
      <Modal.Actions
        primaryLabel={t('Attach')}
        secondaryLabel={t('Cancel')}
        onPrimaryClick={() => updateDocumentId(documentFieldProps.value)}
        onSecondaryClick={modalProps.onClose}
      />
    </Modal>
  );
};
