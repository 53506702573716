import { FrameSetting, ImageSetting, PaddingSetting, SettingsPanel } from '@frontend/content-composer-settings';
import { useTranslation } from '@frontend/i18n';
import { useComposerSection, useSettingsForm } from '../../hooks';
import { ImageSectionProps } from '../../types';
import { CountSetting } from './atoms';

export const ImageSettings = () => {
  const { t } = useTranslation('content-composer');

  const { props } = useComposerSection<ImageSectionProps>();

  const { getFieldProps } = useSettingsForm<ImageSectionProps>({
    corners: { type: 'radio' },
    count: { type: 'radio' },
    frameColor: { type: 'text' },
    height: { type: 'text' },
    imageSpacing: { type: 'text' },
    paddingBottom: { type: 'text' },
    paddingLeft: { type: 'text' },
    paddingRight: { type: 'text' },
    paddingTop: { type: 'text' },
  });

  return (
    <SettingsPanel title={t('Image Styling')}>
      <CountSetting {...getFieldProps('count')} />
      <ImageSetting cornersFieldProps={getFieldProps('corners')} heightFieldProps={getFieldProps('height')} />
      <FrameSetting {...getFieldProps('frameColor')} />
      <PaddingSetting
        paddingTopFieldProps={getFieldProps('paddingTop')}
        paddingBottomFieldProps={getFieldProps('paddingBottom')}
        paddingLeftFieldProps={getFieldProps('paddingLeft')}
        paddingRightFieldProps={getFieldProps('paddingRight')}
        imageSpacingFieldProps={{ ...getFieldProps('imageSpacing'), disabled: Number(props.count) < 2 }}
        isLast
      />
    </SettingsPanel>
  );
};
