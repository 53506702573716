import { useCallback, useEffect, useMemo, useState } from 'react';
import { cloneDeep } from 'lodash-es';
import { theme } from '@frontend/theme';
import { SpinningLoader, styles } from '@frontend/design-system';
import { defaultScheduleHours } from '../../constants';
import { ScheduleAvailabilityHoursContext } from '../../context/ScheduleAvailabilityHoursContext';
import { ScheduleAvailabilityByDayOfWeek, DayOfWeek, DisplayScheduleAvailabilityHour } from '../../types';
import { DaySchedule } from '../DaySchedule';
import { DaysOfTheWeekSelector } from '../DaysOfTheWeekSelector';

interface ScheduleAvailabilityHours {
  scheduleAvailabilityOfWeek?: ScheduleAvailabilityByDayOfWeek;
  updateScheduleAvailabilityOfWeek: (scheduleAvailabilityOfWeek: ScheduleAvailabilityByDayOfWeek | null) => void;
  setIsError: (isError: boolean) => void;
  isLoading?: boolean;
  closedOfficeText?: string;
  daysOfTheWeek?: DayOfWeek[];
  timeRangeJoiningText?: string;
}

export const ScheduleAvailabilityHours = ({
  scheduleAvailabilityOfWeek,
  updateScheduleAvailabilityOfWeek,
  setIsError,
  isLoading,
  closedOfficeText,
  daysOfTheWeek,
  timeRangeJoiningText,
}: ScheduleAvailabilityHours) => {
  const [errorsByDay, setErrorsByDay] = useState<Record<string, boolean>>();

  /**
   * @desc: this method is used to manage the working hours of the selected day
   *        if selected day is not in the schedule object, add the default schedule hours else remove the selected day
   */
  const onToggleDay = useCallback(
    (dayOfWeek: DayOfWeek) => {
      const hasScheduleForSelectedDayOfWeek = !!scheduleAvailabilityOfWeek?.[dayOfWeek]?.length;
      const scheduleClone = { ...scheduleAvailabilityOfWeek } as ScheduleAvailabilityByDayOfWeek;
      if (hasScheduleForSelectedDayOfWeek) {
        scheduleClone[dayOfWeek] = [];
      } else {
        scheduleClone[dayOfWeek] = [defaultScheduleHours];
      }

      updateScheduleAvailabilityOfWeek(scheduleClone);
    },
    [scheduleAvailabilityOfWeek]
  );

  /**
   * @desc: this method is used to update the working hours of the selected day
   */
  const onUpdateDay = useCallback(
    (dayOfWeek: string, scheduleAvailabilityDetails: DisplayScheduleAvailabilityHour, index: number) => {
      let days = {};

      if (Object.keys(scheduleAvailabilityDetails).length !== 0) {
        const scheduleAvailabilityOfWeekClone = cloneDeep(
          scheduleAvailabilityOfWeek
        ) as ScheduleAvailabilityByDayOfWeek;
        scheduleAvailabilityOfWeekClone[dayOfWeek][index] = scheduleAvailabilityDetails;
        days = { [dayOfWeek]: scheduleAvailabilityOfWeekClone[dayOfWeek] };
      }

      const scheduleClone = { ...scheduleAvailabilityOfWeek, ...days };
      updateScheduleAvailabilityOfWeek(scheduleClone);
    },
    [scheduleAvailabilityOfWeek]
  );

  const updateErrorsByDay = useCallback((day: string, hasError: boolean) => {
    setErrorsByDay((prevErrors) => {
      return { ...prevErrors, [day]: hasError };
    });
  }, []);

  const selectedDays = useMemo(() => {
    if (!scheduleAvailabilityOfWeek || !Object.keys(scheduleAvailabilityOfWeek).length) return [];
    return Object.keys(scheduleAvailabilityOfWeek || {}).filter(
      (day) => scheduleAvailabilityOfWeek?.[day]?.length
    ) as DayOfWeek[];
  }, [scheduleAvailabilityOfWeek]);

  useEffect(() => {
    if (errorsByDay && Object.keys(errorsByDay).length) {
      const hasError = Object.values(errorsByDay).some((error) => error);
      setIsError(hasError);
    }
  }, [errorsByDay]);

  const providerValue = useMemo(
    () => ({
      onToggleDay,
      onUpdateDay,
      selectedDays,
      closedOfficeText,
      updateErrorsByDay,
      timeRangeJoiningText,
    }),
    [onToggleDay, onUpdateDay, selectedDays, closedOfficeText, timeRangeJoiningText]
  );

  if (isLoading) {
    return (
      <div css={[styles.flexCenter, { height: '50vh' }]}>
        <SpinningLoader />
      </div>
    );
  }

  return (
    <ScheduleAvailabilityHoursContext.Provider value={providerValue}>
      <DaysOfTheWeekSelector />
      <section css={{ marginTop: theme.spacing(4) }}>
        {daysOfTheWeek?.map((dayOfWeek) => {
          if (scheduleAvailabilityOfWeek?.[dayOfWeek]?.length) {
            return scheduleAvailabilityOfWeek?.[dayOfWeek]?.map((schedule, index) => (
              <DaySchedule index={index} key={dayOfWeek + index} day={dayOfWeek} displaySchedule={schedule} />
            ));
          }
          return (
            <DaySchedule
              key={dayOfWeek}
              day={dayOfWeek}
              displaySchedule={scheduleAvailabilityOfWeek?.[dayOfWeek]?.[0]}
            />
          );
        })}
      </section>
    </ScheduleAvailabilityHoursContext.Provider>
  );
};
