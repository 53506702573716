import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import { http } from '@frontend/fetch';
import { BASE_URL_ANALYTICS } from '../constants';
import { AppointmentReportResponse, AggregateAppointment, Filters } from './types';

dayjs.extend(timezone);

type DateRange = {
  startDate: string;
  endDate: string;
};

type GetDateTime = {
  datesRangeMap: Record<string, DateRange>;
  filters: Filters;
};

const getDateRangeStartEnd = ({ datesRangeMap, filters }: GetDateTime) => {
  if (!filters.TimeFrame) {
    return {
      StartTime: '',
      EndTime: '',
    };
  }

  const dateTime = datesRangeMap[filters.TimeFrame];
  return {
    StartTime: dayjs(dateTime.startDate).tz(filters.TimeZone).format('YYYY-MM-DDT00:00:00.000'),
    EndTime: dayjs(dateTime.endDate).tz(filters.TimeZone).format('YYYY-MM-DDT23:59:59.999'),
  };
};

const getCalculatedDateTime = ({ datesRangeMap, filters }: GetDateTime) => {
  let payload = {
    StartTime: '',
    EndTime: '',
  };

  if (!filters.TimeZone) {
    return payload;
  }

  const localDateTime = dayjs();
  const locationDateTime = localDateTime.tz(filters.TimeZone);

  if (filters.IsPastData) {
    const yesterdayLocalDateTime = localDateTime.date(localDateTime.date() - 1);
    const yesterdayLocationDateTime = yesterdayLocalDateTime.tz(filters.TimeZone);
    const currentTime = locationDateTime.format('YYYY-MM-DDTHH:mm:ss.999');

    switch (filters.TimeFrame) {
      case 'today-past-appointments':
        payload = {
          StartTime: locationDateTime.format('YYYY-MM-DDT00:00:00.000'),
          EndTime: currentTime,
        };
        break;

      case 'yesterday-appointments':
        payload = {
          StartTime: yesterdayLocationDateTime.format('YYYY-MM-DDT00:00:00.000'),
          EndTime: yesterdayLocationDateTime.format('YYYY-MM-DDT23:59:59.999'),
        };
        break;

      case 'last-7-days':
      case 'last-14-days':
      case 'last-30-days': {
        payload = getDateRangeStartEnd({ datesRangeMap, filters });
        break;
      }
    }
  } else {
    const tomorrowLocalDateTime = localDateTime.date(localDateTime.date() + 1);
    const tomorrowLocationDateTime = tomorrowLocalDateTime.tz(filters.TimeZone);
    const currentTime = locationDateTime.format('YYYY-MM-DDTHH:mm:ss.000');

    switch (filters.TimeFrame) {
      case 'today-upcoming-appointments':
        payload = {
          StartTime: currentTime,
          EndTime: locationDateTime.format('YYYY-MM-DDT23:59:59.999'),
        };
        break;

      case 'tomorrow-appointments':
        payload = {
          StartTime: tomorrowLocationDateTime.format('YYYY-MM-DDT00:00:00.000'),
          EndTime: tomorrowLocationDateTime.format('YYYY-MM-DDT23:59:59.999'),
        };
        break;

      case 'next-7-days':
      case 'next-14-days':
      case 'next-30-days': {
        payload = getDateRangeStartEnd({ datesRangeMap, filters });
        break;
      }
    }
  }

  return payload;
};

export const getAppointmentReport = async (
  payload: Filters,
  pageSize: number,
  datesRangeMap: Record<string, DateRange>,
  url?: string
) => {
  if (!payload.LocationID?.length) return;
  const { Status } = payload;
  const { StartTime, EndTime } = getCalculatedDateTime({ datesRangeMap, filters: payload });
  if (!StartTime || !EndTime) return;

  const { data, meta } = await http.post<AppointmentReportResponse>(
    url || `${BASE_URL_ANALYTICS}/getappointments?limit=${pageSize}`,
    {
      EndTime,
      LocationID: payload.LocationID,
      OrderByAsc: payload.OrderByAsc,
      OrderByField: payload.OrderByField,
      StartTime,
      TimeZone: payload.TimeZone,
      ...(Status ? { Status } : {}),
    }
  );

  return { data, meta };
};

export const getAppointmentChartsData = async (payload: Filters, datesRangeMap?: Record<string, DateRange>) => {
  let StartTime = payload.StartDate;
  let EndTime = payload.EndDate;

  const { Status } = payload;

  if (datesRangeMap) {
    const { StartTime: calculatedStartTime, EndTime: calculatedEndTime } = getCalculatedDateTime({
      datesRangeMap,
      filters: payload,
    });

    if (!calculatedStartTime || !calculatedEndTime) {
      return;
    }

    StartTime = calculatedStartTime;
    EndTime = calculatedEndTime;
  }

  const { data } = await http.post<{ data: AggregateAppointment[] }>(`${BASE_URL_ANALYTICS}/getaggregateappointments`, {
    EndTime,
    LocationID: payload.LocationID,
    StartTime,
    TimeZone: payload.TimeZone,
    ...(Status ? { Status } : {}),
  });
  return data;
};
