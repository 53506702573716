import { DeleteMappingsIO } from '../types';
import { UseSchedulerV3MutationEndpointArgs, useSchedulerV3Mutation } from './use-scheduler-v3-mutation';

type MutationContext<C = unknown> = {
  // Add internal mutation context here if needed (useful for optimistic updates)
  otherContext?: C;
};

/**
 * A hook that returns a mutation for the `DeleteMappings` mutation endpoint.
 * It handles query invalidation for the relevant query endpoints internally.
 * @param options (optional) The options to pass to `useMutation`.
 * @param httpOptions (optional) The http options to pass to the schema function.
 * @param optimisticUpdate (optional) Whether to perform an optimistic update. Defaults to `false`.
 */
export const useDeleteMappingsMutation = <
  E = unknown,
  C = unknown,
  OtherOptions extends object = never,
  RequestOverride extends DeleteMappingsIO['input'] = DeleteMappingsIO['input']
>({
  options,
  optimisticUpdate = false,
  ...args
}: UseSchedulerV3MutationEndpointArgs<'DeleteMappings', E, C | undefined, OtherOptions, RequestOverride> = {}) =>
  useSchedulerV3Mutation<'DeleteMappings', E, MutationContext<C>, OtherOptions, RequestOverride>({
    endpointName: 'DeleteMappings',
    ...args,
    options: {
      ...options,
      onMutate: async (request) => {
        if (optimisticUpdate) {
          // Implement optimistic updates here
        }

        return {
          // Add custom context type here (if exists in `MutationContext`)
          otherContext: await options?.onMutate?.(request),
        };
      },
      onSuccess: (response, request, context) => {
        // Implement query updates or invalidation for affected queries here

        return options?.onSuccess?.(response, request, context?.otherContext);
      },
      onError: (error, request, context) => {
        // Revert optimistic update here (if implemented)

        return options?.onError?.(error, request, context?.otherContext);
      },
      onSettled: (response, error, request, context) => {
        // Only pass context of type C into provided `onSettled` option
        return options?.onSettled?.(response, error, request, context?.otherContext);
      },
    },
  });
