import { useEffect, useState } from 'react';
import { BulkMessagingQueries, BulkMessagingTypes } from '@frontend/api-bulk-messaging';
import { useDebouncedValue } from '@frontend/design-system';

export const useCountSegmentRecipients = (campaignId: string, filters: BulkMessagingTypes.AudienceFilters) => {
  const debouncedFilters = useDebouncedValue(filters, 2000);
  const [recipientCount, setRecipientCount] = useState<number | undefined>();

  useEffect(() => {
    setRecipientCount(undefined);

    BulkMessagingQueries.countSegmentRecipients({
      campaignId,
      segment: {
        segmentId: '', // TODO: See if the backend can make this optional
        filterOptions: debouncedFilters,
      },
    }).then((response) => {
      setRecipientCount(response.recipientCount ?? 0);
    });
  }, [debouncedFilters]);

  return recipientCount;
};
