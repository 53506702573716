type Zendesk = {
  appendScript: ({
    apiKey,
    domTag,
    scriptElementId,
  }: {
    apiKey: string;
    domTag?: string;
    scriptElementId?: string;
  }) => void;
  removeScriptById: (scriptElementId: string) => void;
  suppressWidget: (widgetName: ZendeskWidgetType) => string;
  removeSuppressWidgetSettingById: (id: string) => void;
};

export type ZendeskWidgetType = 'chat' | 'contactForm' | 'helpCenter' | 'talk' | 'answerBot';

/**
 * integrate zendesk knowledge base articles into the Pendo Resource Center
 */
export const zendesk: Zendesk = {
  appendScript: ({ scriptElementId = 'ze-snippet', apiKey, domTag = 'body' }) => {
    const snippet = document.getElementById('ze-snippet');
    const snippetAlreadyExists = !!snippet;
    if (snippetAlreadyExists) return;

    const scriptElement = document.createElement('script');
    scriptElement.id = scriptElementId;
    scriptElement.src = `https://static.zdassets.com/ekr/snippet.js?key=${apiKey}`;
    const tag = document.getElementsByTagName(domTag)?.[0];
    tag?.appendChild(scriptElement);
  },
  removeScriptById: (scriptElementId) => {
    const element = document.getElementById(scriptElementId);
    element?.remove();
  },
  suppressWidget: (widgetName: ZendeskWidgetType) => {
    const id = `suppress-${widgetName}`;
    const suppressWidgetScriptElement = document.createElement('script');
    suppressWidgetScriptElement.id = id;
    suppressWidgetScriptElement.type = 'text/javascript';
    const zendDeskSettingsScript = `window.zESettings = {${widgetName}: {suppress: true}}`;
    suppressWidgetScriptElement.innerText = zendDeskSettingsScript;
    const bodyElement = document.getElementsByTagName('body')?.[0];
    bodyElement?.appendChild(suppressWidgetScriptElement);

    return id;
  },
  removeSuppressWidgetSettingById: (id: string) => {
    const suppressWidgetScriptElement = document.getElementById(id);
    const suppressWidgetScriptElementExist = !!suppressWidgetScriptElement;
    if (suppressWidgetScriptElementExist) {
      suppressWidgetScriptElement.remove();
    }
  },
};
