import { useEffect } from 'react';
import { useEditor, useNode } from '@craftjs/core';
import ContentEditable from 'react-contenteditable';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { useFormField } from '@frontend/design-system';
import { useComposerSection } from '../../hooks/use-composer-section';
import { cornersStyling, frameAlignmentStyling } from '../../styles';
import { ButtonSectionProps, ComposerSection } from '../../types';
import { getPaddingStyles } from '../../utils/get-padding-styles';
import { ButtonOutput } from '../outputs';
import { ButtonSettings } from '../settings';
import { ButtonTool } from '../tools';
import { BaseComposerSection } from './floating-toolbar';

export const ButtonSection: ComposerSection<ButtonSectionProps> = ({
  backgroundColor,
  corners,
  frameAlignment,
  frameColor,
  link,
  linkType,
  paddingBottom,
  paddingLeft,
  paddingRight,
  paddingTop,
  text,
  textColor,
  ...rest
}) => {
  const { t } = useTranslation('content-composer');
  const { setProps } = useComposerSection<ButtonSectionProps>();
  const { id } = useNode((state) => ({ id: state.id }));
  const { actions, selected } = useEditor((state, query) => {
    const [currentNodeId] = state.events.selected;
    let selected;

    if (currentNodeId) {
      selected = {
        id: currentNodeId,
        settings: state.nodes[currentNodeId]?.related?.settings,
        isDeletable: query.node(currentNodeId).isDeletable(),
      };
    }

    return {
      selected,
    };
  });

  const fieldProps = useFormField({
    type: 'text',
    value: text,
  });

  useEffect(() => {
    setProps((props) => (props.text = fieldProps.value));
  }, [fieldProps.value]);

  return (
    <BaseComposerSection
      sectionType='Button'
      title={t('Button')}
      onDelete={selected?.isDeletable ? () => actions.delete(selected.id) : undefined}
      {...rest}
      id={id}
      link={link}
      linkType={linkType}
      css={[
        frameAlignmentStyling(frameAlignment),
        getPaddingStyles({ paddingTop, paddingRight, paddingBottom, paddingLeft }),
        {
          background: frameColor,
        },
      ]}
    >
      <ContentEditable
        id={fieldProps.id}
        html={fieldProps.value}
        onChange={fieldProps.onChange}
        css={[
          cornersStyling(corners),
          {
            background: backgroundColor,
            color: textColor,
            padding: theme.spacing(1, 2),
            fontWeight: theme.font.weight.bold,
            outline: 'none',
            height: theme.spacing(5),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '&:hover, &:focus': {
              boxShadow: theme.shadows.floating,
            },
          },
        ]}
      />
    </BaseComposerSection>
  );
};

// TODO: Localize the text prop somehow
ButtonSection.craft = {
  props: {
    backgroundColor: theme.colors.primary50.toUpperCase(),
    corners: 'pill',
    frameAlignment: 'center',
    frameColor: theme.colors.neutral5.toUpperCase(),
    link: '',
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(3),
    text: 'Click to Edit Button',
    textColor: theme.colors.white.toUpperCase(),
  },
  related: {
    output: ButtonOutput,
    settings: ButtonSettings,
    tool: ButtonTool,
  },
};
