import { useTranslation } from '@frontend/i18n';
import { ConfirmationModal, ModalControlModalProps, Text } from '@frontend/design-system';

type Props = {
  modalProps: ModalControlModalProps;
  onDelete: () => void;
};

export const DeleteListModal = ({ modalProps, onDelete }: Props) => {
  const { t } = useTranslation('bulk-messaging');

  return (
    <ConfirmationModal
      {...modalProps}
      title={t('Are You Sure?')}
      onConfirm={onDelete}
      destructive
      modalCss={{ minWidth: 600 }}
    >
      <Text>{t('Are you sure you want to delete this list?')}</Text>
    </ConfirmationModal>
  );
};
