import { FC, ReactNode } from 'react';
import { PersonHelpers } from '@frontend/api-person';
import { useBreakpoint } from '@frontend/responsiveness';
import { Modal, ModalControlModalProps, Heading, Text, SpinningLoader, Avatar, Button } from '@frontend/design-system';
import { pendoTags } from '../../../../../shared/constants';
import { footerContainerStyle } from '../../../../templates/components/templates-usage-terms-modal/template-usage-terms-modal.styles';
import { modalContainerStyle, modalHeaderStyle, modalBodyStyle, modalBodyContainerStyle } from './custom-modal.styles';
import { CustomModalProps } from './modal.types';

type FormsModalProps = Pick<ModalControlModalProps, 'onClose'> & {
  children: ReactNode;
};

const FormsModalContent = ({ children }: FormsModalProps) => <>{children}</>;

const CustomModal: FC<React.PropsWithChildren<CustomModalProps>> = ({
  firstName,
  lastName,
  title,
  modalProps,
  children,
  modalDataOptions,
  primaryButtonText,
  secondaryButtonText,
  modalNavigator,
  minWidth,
  maxWidth,
  showSpinner,
  primaryButtonTrackingId,
  secondaryButtonTrackingId,
  disablePrimaryButton,
  disableSecondaryButton,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  onCloseModal,
  ...rest
}) => {
  const breakpoints = useBreakpoint();
  const fullName = PersonHelpers.getFullName({ FirstName: firstName, LastName: lastName });
  function closeModalHandler() {
    modalProps.onClose();

    if (onCloseModal) {
      onCloseModal();
    }
  }

  return (
    <Modal
      onClose={closeModalHandler}
      show={modalProps.show}
      css={modalContainerStyle({ minWidth, maxWidth })}
      disableCloseOnOverlayClick
      disableCloseOnEscape
      {...rest}
    >
      <Modal.Header css={{ padding: 0 }}>
        <div css={modalHeaderStyle}>
          {breakpoints !== 'xsmall' && <Avatar name={fullName} size='medium' />}
          <div className='header-title'>
            <Heading level={2}>
              {firstName} {lastName}
            </Heading>
            <Text color='light' weight='semibold'>
              {title}
            </Text>
          </div>
          <div className='action-btns'>
            {modalNavigator && (
              <>
                {modalNavigator.previous.show && (
                  <Button
                    iconName='caret-left'
                    variant='secondary'
                    label='Previous'
                    trackingId={pendoTags.formNavigator}
                    onClick={modalNavigator.previous.onClick}
                  />
                )}

                {modalNavigator.home.show && (
                  <Button
                    iconName='home'
                    variant='secondary'
                    label='Home'
                    trackingId={pendoTags.formNavigator}
                    disabled={modalNavigator.home.disabled}
                    onClick={modalNavigator.home.onClick}
                  />
                )}

                {modalNavigator.next.show && (
                  <Button
                    iconName='caret-right'
                    variant='secondary'
                    label='Next'
                    trackingId={pendoTags.formNavigator}
                    disabled={modalNavigator.next.disabled}
                    onClick={modalNavigator.next.onClick}
                  />
                )}
              </>
            )}

            <Button
              iconName='x'
              variant='secondary'
              className='close-btn'
              label='close'
              onClick={closeModalHandler}
              trackingId={pendoTags.modalExitButton}
            />
          </div>
        </div>
      </Modal.Header>

      <Modal.Body css={modalBodyStyle}>
        <div css={modalBodyContainerStyle}>
          {modalDataOptions}
          <FormsModalContent onClose={closeModalHandler}>{children}</FormsModalContent>
        </div>
      </Modal.Body>

      {(primaryButtonText || secondaryButtonText) && (
        <Modal.Footer css={footerContainerStyle}>
          {showSpinner && <SpinningLoader size='small' />}
          {secondaryButtonText && (
            <Button
              variant='secondary'
              onClick={onSecondaryButtonClick}
              disabled={!!disableSecondaryButton || showSpinner}
              {...(secondaryButtonTrackingId && {
                trackingId: secondaryButtonTrackingId,
              })}
            >
              {secondaryButtonText}
            </Button>
          )}
          {primaryButtonText && (
            <Button
              variant='primary'
              onClick={onPrimaryButtonClick}
              disabled={!!disablePrimaryButton || showSpinner}
              {...(primaryButtonTrackingId && { pendoTag: primaryButtonTrackingId })}
            >
              {primaryButtonText}
            </Button>
          )}
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default CustomModal;
