import { css } from '@emotion/react';
import { breakpoints } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';
import { BANNER_BACKGROUNDS } from '../constants';
import { BannerType } from '../types';

export const styles = {
  banner: css`
    display: flex;
    width: 100%;
    align-items: center;
    gap: ${theme.spacing(3)};
  `,
  content: css`
    flex: 1;
    min-width: 0;
  `,
  title: css`
    font-weight: 600;
    margin-bottom: ${theme.spacing(1)};
  `,
  carouselNavigation: css`
    display: flex;
    align-items: center;
    gap: ${theme.spacing(1)};
  `,
  navigationButton: css`
    background: none;
    border: none;
    padding: ${theme.spacing(1)};
    color: ${theme.colors.neutral60};
    cursor: pointer;
    display: flex;
    align-items: center;

    &:hover {
      color: ${theme.colors.neutral80};
    }
  `,
};

export const getBaseStyles = css`
  display: flex;
  width: 100%;
  align-items: center;
  gap: ${theme.spacing(3)};
  @container (max-width: ${breakpoints.small.min}px) {
    flex: 1;
    align-self: flex-start;
    position: relative;
    gap: ${theme.spacing(2)};
  }
`;

export const getContentStyles = css`
  display: flex;
  flex: 1;
  align-items: center;
  min-width: 0;
  gap: ${theme.spacing(5)};

  @container (max-width: ${breakpoints.small.min}px) {
    display: initial;
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
  }
`;

export const getActionContainerStyles = css`
  display: flex;
  align-items: center;
  margin-left: auto;
  gap: ${theme.spacing(2)};
  justify-content: flex-start;

  @container (max-width: ${breakpoints.small.min}px) {
    margin-left: ${theme.spacing(-1)};
  }
`;

export const getManagerContainerStyles = (type?: BannerType) =>
  !!type
    ? css`
        position: relative;
        display: flex;
        flex-direction: column;
        z-index: 1;
        padding: ${theme.spacing(2)};
        background-color: ${BANNER_BACKGROUNDS[type]};
        transition: 0.5s ease;
        box-shadow: ${theme.shadows.light};
        width: 100%;
      `
    : null;
