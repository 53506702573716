import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

type FloatingToolProps = {
  children?: React.ReactNode;
};

export const FloatingTool = ({ children }: FloatingToolProps) => {
  return (
    <span
      css={css`
        padding: ${theme.spacing(1)};
        background: ${theme.colors.white};
        box-shadow: ${theme.shadows.light};
        border-radius: ${theme.borderRadius.small};
        max-width: ${theme.spacing(20)};
        max-height: ${theme.spacing(5)};
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      `}
    >
      {children}
    </span>
  );
};
