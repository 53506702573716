import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';

type Props = {
  contactCount: number;
  totalAvailable: number;
  totalUsed: number;
};

export const InsufficientMessage = ({ contactCount, totalAvailable, totalUsed }: Props) => {
  const { t } = useTranslation('bulk-messaging');

  const excess = totalUsed - totalAvailable;

  if (totalUsed > contactCount && !(totalUsed > totalAvailable)) {
    return (
      <Text color='error' css={{ marginTop: theme.spacing(1) }}>
        {t(
          'Not enough contacts available at this location; Message spend will default to maximum amount of contacts available'
        )}
      </Text>
    );
  } else if (totalUsed > totalAvailable) {
    return (
      <Text color='error' css={{ marginTop: theme.spacing(1) }}>
        {t(
          'Insufficient allotment; Message spend will default to maximum messages available ({{remaining}} contacts cannot be reached)',
          { remaining: excess.toLocaleString() }
        )}
      </Text>
    );
  }
  return null;
};
