import { useNode } from '@craftjs/core';
import {
  ComposerSectionMeta,
  ComposerSectionProps,
  UsableComposerSectionProps,
  SetComposerSectionMeta,
  SetComposerSectionProps,
} from '../types';

export const useComposerSection = <T extends ComposerSectionProps>() => {
  const {
    actions: { setProp: setProps, setCustom: setMeta },
    props: props,
    meta,
    sectionHTML,
  } = useNode((node) => ({
    props: node.data.props as UsableComposerSectionProps<T>,
    meta: node.data.custom as ComposerSectionMeta,
    sectionHTML: node.dom,
  }));

  return {
    props,
    setProps: setProps as SetComposerSectionProps<T>,
    meta,
    setMeta: setMeta as SetComposerSectionMeta,
    sectionHTML,
  };
};
