import { BarChartAppearance } from '@frontend/charts';
import { useTranslation } from '@frontend/i18n';
import { formatters } from '../../../../utils';
import { MissedCallTextROICohortChart, ROIConversionChart } from '../../charts';
import {
  useFetchMissedCallTextConversionTotals,
  useROIShallowStore,
  useShowMissedCallTextRoiWidget,
} from '../../hooks';
import { getDateRangeText } from '../../utils/date-range-text';
import { MessagesSentDescription } from '../message-sent-description';

interface MessagesSentTabProps {
  appearance: BarChartAppearance;
  conversionColors: Record<string, string>;
  conversionLabels: Record<string, string>;
  messagesSentDetails?: ReturnType<typeof useFetchMissedCallTextConversionTotals>;
}

export const MessagesSentTab = ({
  appearance,
  conversionColors,
  conversionLabels,
  messagesSentDetails,
}: MessagesSentTabProps) => {
  const { t } = useTranslation('analytics');
  const { filters } = useROIShallowStore('filters');
  const { phase3Enabled } = useShowMissedCallTextRoiWidget();

  const totalMessagesSent = messagesSentDetails?.data?.totalMessagesCount ?? 0;
  const totalMessagesSentConversion = messagesSentDetails?.data?.totalAppointmentsCount ?? 0;

  return (
    <>
      <ROIConversionChart
        achievedValue={totalMessagesSentConversion}
        appearance={appearance}
        attributeType='forward'
        colors={conversionColors}
        description={
          <MessagesSentDescription
            achievedValue={formatters.value.format(totalMessagesSentConversion)}
            totalValue={formatters.value.format(totalMessagesSent)}
          />
        }
        infoTip={t('Conversion of appointments resulting from missed call texts sent {{date}}', {
          date: getDateRangeText(filters?.startDate, filters?.endDate),
        })}
        isLoading={messagesSentDetails?.isLoading}
        labels={conversionLabels}
        title={t('Conversion')}
        totalValue={totalMessagesSent}
      />

      {phase3Enabled && <MissedCallTextROICohortChart attributeType='forward' />}
    </>
  );
};
