import { breakpoints } from '@frontend/responsiveness';

export const SIDEBAR_BREAKPOINTS = {
  mobile: 650,
  tablet: breakpoints.medium.min,
  desktop: breakpoints.medium.max,
};

export const DEFAULT_PALETTE = [
  '#4D0505',
  '#BD0F0F',
  '#FF584C',
  '#FF837A',
  '#FFB8B3',
  '#FFE3E0',
  '#3F0250',
  '#9D30BC',
  '#C467DE',
  '#D88EEC',
  '#E5AFF3',
  '#F8E3FD',
  '#002152',
  '#0254CF',
  '#3F8BFD',
  '#6BA6FF',
  '#9EC5FF',
  '#DCEBFE',
  '#002533',
  '#006B62',
  '#018479',
  '#22CBC1',
  '#89E1DB',
  '#C2F4F1',
  '#4B2D00',
  '#9A6D16',
  '#BB8A2C',
  '#D3A650',
  '#E3C07D',
  '#F0DBB3',
  '#000000',
  '#5F6771',
  '#8E959E',
  '#A1A8B0',
  '#CED3DA',
  '#FFFFFF',
];
