import { css } from '@emotion/react';
import { breakpoints } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';

export const containerStyle = css`
  display: flex;
  flex-flow: column;
  width: 25%;

  @media (max-width: ${breakpoints.xsmall.max}px) {
    width: 40%;
  }

  pre {
    white-space: pre-wrap;
  }
`;

export const optionValueStyle = css`
  display: flex;
  gap: ${theme.spacing(0.5)};
`;

export const labelStyle = css`
  margin-bottom: 0;
  margin-top: 0;
`;

export const valueStyle = css`
  margin-top: 0;
  word-wrap: break-word;

  &.highlight {
    background-color: yellow;
    max-width: 25px;
  }
`;

export const eSignFontStyle = (fontType: string) => [
  css`
    font-family: '${fontType}', cursive;
    user-select: none;
    margin-bottom: 0;
  `,
  valueStyle,
];

export const eSignTimestampStyle = [
  css`
    font-size: ${theme.fontSize(12)};
  `,
  valueStyle,
];
