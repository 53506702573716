import { FC } from 'react';
import { SuccessGraphic } from '@frontend/assets';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Modal, Text, ModalControlModalProps, Heading } from '@frontend/design-system';

interface Props {
  modalProps: ModalControlModalProps;
}

export const SuccessModal: FC<React.PropsWithChildren<Props>> = ({ modalProps }) => {
  const { t } = useTranslation('portingLoa');
  return (
    <Modal maxWidth={600} {...modalProps}>
      <Modal.Body>
        <SuccessGraphic
          width={200}
          css={{ margin: `${theme.spacing(2)} auto ${theme.spacing(3)} auto`, display: 'block' }}
        />
        <Heading>{t("You've Finished Signing")}</Heading>
        <Text>{t('A copy of this document will be sent to your email for future reference. Thank you!')}</Text>
      </Modal.Body>
      <Modal.Footer primary={{ label: t('View Document'), onClick: () => window.location.reload() }} />
    </Modal>
  );
};
