import { FilterOptions } from '@weave/schema-gen-ts/dist/schemas/messaging/shared/v1/bulk.pb';
import { MessagingEtlAmpQueries } from '@frontend/api-messaging-etl-amp';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { Chip, Text, formatDate } from '@frontend/design-system';

const FilterItem = ({ heading, values }: { heading: string; values: string[] }) => (
  <div css={{ display: 'flex', flexDirection: 'column', rowGap: theme.spacing(1) }}>
    <Text weight='bold' css={{ color: theme.colors.neutral70 }}>
      {heading}
    </Text>
    <div css={{ display: 'flex', gap: theme.spacing(1), flexWrap: 'wrap' }}>
      {values.map((value) => (
        <Chip css={{ background: theme.colors.primary50, color: theme.colors.white, maxWidth: 'fit-content' }}>
          {value}
        </Chip>
      ))}
    </div>
  </div>
);

export const FiltersList = ({ filterOptions }: { filterOptions?: FilterOptions }) => {
  const { t } = useTranslation('bulk-messaging');
  const { selectedOrgId: orgId } = useAppScopeStore();
  const { data: organizationData = [] } = MessagingEtlAmpQueries.useGetLocation({ orgId });

  const getLocationName = (locationId: string) => {
    const location = organizationData.find((location) => location.locationId === locationId);
    return location?.name ?? '';
  };

  const filterItemsData: { heading: string; values: string[]; show: boolean }[] = [
    {
      heading: t('Appointment Qualifier'),
      values: filterOptions?.appointmentQualifier ? [filterOptions?.appointmentQualifier] : [],
      show: !!filterOptions?.appointmentQualifier,
    },
    {
      heading: t('Appointment Details'),
      values: [
        t('Appt. Begin Date: {{date}}', { date: formatDate(filterOptions?.appointmentBeginDate, 'M/D/YY') }),
        t('Appt. End Date: {{date}}', { date: formatDate(filterOptions?.appointmentEndDate, 'M/D/YY') }),
      ],
      show: !!filterOptions?.appointmentBeginDate || !!filterOptions?.appointmentEndDate,
    },
    {
      heading: t('Appointment Type'),
      values: filterOptions?.appointmentTypes ?? [],
      show: !!filterOptions?.appointmentTypes?.length,
    },
    {
      heading: t('Appointment Status'),
      values: filterOptions?.appointmentStatuses ?? [],
      show: !!filterOptions?.appointmentStatuses?.length,
    },
    {
      heading: t('Recall Details'),
      values: [
        t('Recall Begin Date: {{date}}', { date: filterOptions?.recallBeginDate }),
        t('Recall End Date: {{date}}', { date: filterOptions?.recallEndDate }),
      ],
      show: !!filterOptions?.recallBeginDate || !!filterOptions?.recallEndDate,
    },
    {
      heading: t('Recall Type'),
      values: filterOptions?.recallTypes ?? [],
      show: !!filterOptions?.recallTypes?.length,
    },
    {
      heading: t('Locations'),
      values: filterOptions?.locationIds?.map((locationId) => getLocationName(locationId)) ?? [],
      show: !!filterOptions?.locationIds?.length,
    },
    {
      heading: t('Insurance'),
      values: filterOptions?.primaryInsurances ?? [],
      show: !!filterOptions?.primaryInsurances?.length,
    },
    {
      heading: t('Recipients Details'),
      values: [
        t('Min Age: {{age}}', { age: filterOptions?.minAge }),
        t('Max Age: {{age}}', { age: filterOptions?.maxAge }),
      ],
      show: !!filterOptions?.minAge || !!filterOptions?.maxAge,
    },
    {
      heading: t('Provider Details'),
      values: filterOptions?.appointmentPractitioners ?? [],
      show: !!filterOptions?.appointmentPractitioners?.length,
    },
    // TODO: Add these filters
    // !!filterOptions?.minDaysSinceLastMarketingMessage
    // filterOptions.sourceTenantIds.length > 0

    // should look like this:
    // {
    //     heading: 'Last Received Bulk Message',
    //     values: [
    //         t('Min Days Since Last Marketing Message: {{days}}', { days: filterOptions?.minDaysSinceLastMarketingMessage }),
    //     ],
    //     show: !!filterOptions?.minDaysSinceLastMarketingMessage,
    // },
  ];

  return filterItemsData
    .filter((item) => item.show)
    .map((item, index) => <FilterItem key={index} heading={item.heading} values={item.values} />);
};
