import React from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { breakpoints } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';
import {
  Text,
  TextLink,
  NakedButton,
  useContentOverflow,
  useModalControl,
  Modal,
  Button,
  styles as dsStyles,
} from '@frontend/design-system';
import { BannerAction as BannerActionType } from '../types';

export const styles = {
  messageContainer: css`
    display: flex;
    @container (max-width: ${breakpoints.small.min}px) {
      padding-right: ${theme.spacing(6.25)};
    }
  `,

  modalFooter: css`
    display: flex;
    gap: ${theme.spacing(2)};
    margin-left: auto;
  `,
};

interface TruncatedMessageProps {
  message: React.ReactNode;
  modalTriggerRef: React.Ref<HTMLButtonElement>;
  onModalTrigger: (e: React.MouseEvent) => void;
}

export const TruncatedMessage = ({ message, modalTriggerRef, onModalTrigger }: TruncatedMessageProps) => {
  const [ref, isTruncated] = useContentOverflow();
  const { t } = useTranslation('global-info-banner');

  return (
    <div css={styles.messageContainer}>
      <Text ref={ref} size='medium' css={[{ margin: 0 }, dsStyles.truncate]}>
        {message}
      </Text>
      {isTruncated && (
        <TextLink ref={modalTriggerRef} onClick={onModalTrigger} as={NakedButton} size='medium'>
          {t('Show More')}
        </TextLink>
      )}
    </div>
  );
};

interface BannerContentProps {
  title: string;
  message: React.ReactNode;
  action?: BannerActionType;
  onModalClose?: () => void;
  onModalOpen?: () => void;
}

export const BannerContent = ({ title, message, action, onModalClose, onModalOpen }: BannerContentProps) => {
  const { t } = useTranslation('global-info-banner');

  const { modalProps, triggerProps, closeModal } = useModalControl({
    onClose: onModalClose,
  });

  const handleModalTrigger = (e: React.MouseEvent) => {
    triggerProps.onClick(e);
    onModalOpen?.();
  };

  return (
    <>
      <div css={dsStyles.truncate}>
        <Text as='h3' weight='bold' css={{ margin: 0 }}>
          {title}
        </Text>
        <TruncatedMessage message={message} modalTriggerRef={triggerProps.ref} onModalTrigger={handleModalTrigger} />
      </div>
      <Modal {...modalProps}>
        <Modal.Header>{title}</Modal.Header>
        <Modal.Body>
          <Text>{message}</Text>
        </Modal.Body>
        <Modal.Footer>
          <div css={styles.modalFooter}>
            <Button onClick={closeModal} variant='secondary'>
              {t('Close')}
            </Button>
            {action && 'label' in action ? <Button onClick={action.onClick}>{action.label}</Button> : action?.element}
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};
