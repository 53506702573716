import { useMemo } from 'react';
import { isWeaveUser } from '@frontend/auth-helpers';
import { useAppScopeStore } from '@frontend/scope';

export const usePhoneScopeStore = () => {
  const { selectedLocationIds, ...rest } = useAppScopeStore();

  const selectedLocationIdsExcludingParent = useMemo(
    () =>
      isWeaveUser()
        ? /**
           * Don't filter out parent location for weave users - they can pick their locations
           * We also don't have `accessibleLocationData` for weave users
           */
          selectedLocationIds
        : selectedLocationIds.filter((id) => {
            const location = rest.accessibleLocationData[id];
            return location?.children?.length === 0;
          }),
    [selectedLocationIds, rest.accessibleLocationData]
  );

  return {
    selectedLocationIds,
    selectedLocationIdsExcludingParent,
    ...rest,
  };
};
