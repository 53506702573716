const HeaderBarBase = 'schedule-calendar-header-bar';
const HeaderBarFilterBase = 'schedule-calendar-header-bar-filter';

export const HeaderBarTrackingIds = {
  allAppointmentsCountButton: `${HeaderBarBase}-summary-box-button-all-appointments-count`,
  unconfirmedAppointmentsCount: `${HeaderBarBase}-summary-box-button-unconfirmed-appointments-count`,
  insuranceAlertsCount: `${HeaderBarBase}-summary-box-button-insurance-alerts-count`,
  dateNavigationTodayButton: `${HeaderBarBase}-date-navigation-today-button`,
  dateNavigationPreviousDayButton: `${HeaderBarBase}-date-navigation-previous-day-button`,
  dateNavigationNextDayButton: `${HeaderBarBase}-date-navigation-next-day-button`,
};

export const HeaderBarFiltersTrackingIds = {
  openFiltersButton: `${HeaderBarFilterBase}-open-filters-button`,
  applyFiltersButton: `${HeaderBarFilterBase}-apply-filters-button`,
  resetFiltersButton: `${HeaderBarFilterBase}-reset-filters-button`,
  selectAllProvidersButton: `${HeaderBarFilterBase}-select-all-providers-button`,
  clearAllProvidersButton: `${HeaderBarFilterBase}-clear-all-providers-button`,
  unconfirmedOnlyAppointmentsSwitch: `${HeaderBarFilterBase}-unconfirmed-only-appointments-switch`,
  selectAllInsuranceStatusesFilterButton: `${HeaderBarFilterBase}-select-all-insurance-statuses-filter-button`,
  clearAllInsuranceStatusesFilterButton: `${HeaderBarFilterBase}-clear-all-insurance-statuses-filter-button`,
};
