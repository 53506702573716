import { useEffect, useState } from 'react';
import { useEditor } from '@craftjs/core';
import { useTranslation } from '@frontend/i18n';
import { useContentComposer } from '../content-composer-provider';
import { FooterSection } from '../sections';
import { Divider, ToolboxButton } from '../sidebar';

export const FooterTool = () => {
  const { t } = useTranslation('content-composer');
  const { insertSection, composerMode } = useContentComposer();
  const isMarketingMode = composerMode === 'marketing';
  const [includesFooter, setIncludesFooter] = useState<boolean>(false);
  const { nodes } = useEditor((state) => ({
    nodes: state.nodes,
  }));

  const selectedNodes = Object.values(nodes)
    .map((node) => node.data.name)
    .sort((a, b) => {
      if (a === b) return 0;
      return a < b ? -1 : 1;
    });

  useEffect(() => {
    setIncludesFooter(selectedNodes.includes('FooterSection'));
  }, [selectedNodes]);

  // Hide sidebar tool in marketing mode or if it has been added to canvas already
  return isMarketingMode || includesFooter ? null : (
    <>
      <ToolboxButton
        icon='footer'
        title={t('Footer')}
        subtitle={t('Add a branded footer.')}
        onClick={() => insertSection(<FooterSection />)}
      >
        <FooterSection />
      </ToolboxButton>
      <Divider />
    </>
  );
};
