import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const downloadSoftwareContainer = css({
  fontFamily: 'dm sans',
  '.download-software-is-mobile-section': {
    alignItems: 'flex-start',
    gap: '8px',
    button: {
      maxWidth: 'fit-content',
    },
  },

  '.download-software-is-beta-section': {
    display: 'flex',
    height: '172px',
    border: `1px solid ${theme.colors.secondary.eggplant20}`,
    borderRadius: `${theme.borderRadius.medium}`,
    backgroundColor: ` ${theme.colors.secondary.eggplant10}`,
    padding: '16px',
    div: {
      fontStyle: 'normal',
      justifyContent: 'center',
      alignContent: 'center',
      p: {
        fontSize: '14px',
        fontWeight: '700',
        lineHeight: '20px',
        paddingBottom: '8px',
        color: `${theme.colors.secondary.eggplant90}`,
      },
      ul: {
        fontSize: '12px',
        fontWeight: '400',
        lineHeight: '16px',
        paddingLeft: '24px',
        color: `${theme.colors.secondary.eggplant90}`,
        li: {
          paddingBottom: '8px',
        },
        span: {
          fontWeight: `${theme.font.weight.bold}`,
        },
      },
      'div:last-child': {
        padding: `8px 0 0 8px`,
      },
    },
  },

  '.download-software-mobile-app-section': {
    gap: '8px',
    img: {
      width: `${theme.spacing(17)}`,
    },
  },

  '.download-software-footer-section': {
    display: 'flex',
    alignItems: 'center',
    padding: `${theme.spacing(2)}`,
    border: `1px solid ${theme.colors.primary50}`,
    borderRadius: `${theme.spacing(0.5)}`,
    backgroundColor: `${theme.colors.primary5}`,
    svg: {
      marginRight: ` ${theme.spacing(2)}`,
    },
  },

  /* Independent classes */
  '.flex-column': {
    display: 'flex',
    flexDirection: 'column',
  },

  '.margin-divider': {
    marginTop: `${theme.spacing(3)}`,
  },

  '.medium-margin-tb': {
    margin: `${theme.spacing(2, 0)}`,
  },

  '.light-text': {
    color: `${theme.colors.neutral50}`,
  },
  '.mobile': {
    display: 'flex',
    justifyContent: 'center',
    svg: {
      display: 'none',
    },
  },
});
