import { useCallback, useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { ValueType } from '@weave/schema-gen-ts/dist/schemas/schedule/v3/mapping.pb';
import { CreatePractitionerRequest, Practitioner } from '@weave/schema-gen-ts/dist/schemas/schedule/v3/practitioner.pb';
import { SchedulerV3 } from '@frontend/api-schedule-v3';
import { useTranslation } from '@frontend/i18n';
import { breakpoints, useMatchMedia } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';
import {
  FormRow,
  TextareaField,
  useForm,
  Text,
  SpinningLoader,
  ButtonBar,
  useAlert,
  Stepper,
  Button,
  styles,
} from '@frontend/design-system';
import { useAppointmentTypeCheckListDropdown } from '../../../components/AppointmentTypeCheckListDropdown';
import { ManagePractitionerProfilePhotoContext } from '../../../context/ManagePractitionerProfilePhotoContext';
import { useGetIntegrationDetails, useSchedulingLocationInfo } from '../../../hooks';
import { PractitionerPhotoContainer } from './PractitionerPhoto';
import { PractitionerTextField } from './PractitionerTextField';

type PractitionerDetailsV3Props = {
  locationId: string;
  practitionerId?: string;
  practitionerDetails: Practitioner;
  primaryButtonLabel?: string;
  secondaryButtonLabel?: string;
  isLoading?: boolean;
  shouldRenderSecondaryButton?: boolean;
  isStepperComponent?: boolean;
  onSave?: () => void;
  onCancel?: () => void;
  onErrors?: (err: string) => void;
  setPractitionerDetailsOnCreate?: (practitionerDetails: Practitioner) => void;
};

export const PractitionerDetailsV3 = ({
  locationId,
  practitionerId,
  practitionerDetails,
  onSave,
  onCancel,
  onErrors,
  primaryButtonLabel,
  secondaryButtonLabel,
  isLoading,
  shouldRenderSecondaryButton = true,
  isStepperComponent = false,
  setPractitionerDetailsOnCreate,
}: PractitionerDetailsV3Props) => {
  const alert = useAlert();
  const { t } = useTranslation('schedule');
  const isSmall = useMatchMedia({ maxWidth: breakpoints.small.max });
  const { selectedLocationIds } = useSchedulingLocationInfo();
  const selectedLocationId = selectedLocationIds.length === 1 ? selectedLocationIds[0] : locationId || '';

  const [profilePhotoSrc, setProfilePhotoSrc] = useState<string>('');

  const { isIntegratedOffice, isLoading: isLoadingIntegrationDetails } = useGetIntegrationDetails({
    selectedLocationId: locationId,
  });

  const practitionerFullName = useMemo(() => {
    if (practitionerDetails?.firstName || practitionerDetails?.lastName) {
      return `${practitionerDetails?.firstName || ''} ${practitionerDetails?.lastName || ''}`.trim();
    }
    return '';
  }, [practitionerDetails]);

  const updateProviderMutation = SchedulerV3.Mutations.useUpdateProviderMutation();
  const createProviderMutation = SchedulerV3.Mutations.useCreateProviderMutation();
  const { createAndDeleteMappingsAsync, isLoading: isUpdatingMappings } =
    SchedulerV3.Mutations.useCreateAndDeleteMappings();

  const { formProps, getFieldProps, values, seedValues, isComplete } = useForm({
    fields: {
      fullName: { type: 'text', required: true, value: practitionerFullName },
      displayName: {
        type: 'text',
        required: true,
        value: practitionerDetails?.displayName || practitionerFullName || '',
      },
      bio: { type: 'text', required: false, value: practitionerDetails?.profile?.bio ?? '' },
    },
  });

  const {
    AppointmentTypeCheckListDropdownElement,
    getAppointmentTypeSelectedUnselectedValues,
    hasAppointmentTypeCheckListDropdownError,
  } = useAppointmentTypeCheckListDropdown({
    locationId: selectedLocationId,
    providerId: practitionerId ?? '',
  });

  // Seed values on practitioner details change
  useEffect(() => {
    seedValues({
      fullName: practitionerFullName,
      displayName: practitionerDetails?.displayName || practitionerFullName || '',
      bio: practitionerDetails?.profile?.bio ?? '',
    });
  }, [practitionerFullName, practitionerDetails?.displayName, practitionerDetails?.profile?.bio]);

  // Seed profile photo on practitioner details change
  useEffect(() => {
    if (practitionerDetails?.profile?.picUrl) {
      setProfilePhotoSrc(practitionerDetails?.profile?.picUrl);
    }
  }, [practitionerDetails?.profile?.picUrl]);

  const getFirstNameLastNameMiddleName = (name: string) => {
    if (!name) return ['', '', ''];

    const namesList = name.split(' ');
    if (namesList.length === 1) return [namesList[0], '', ''];
    if (namesList.length === 2) return [namesList[0], '', namesList[1]];
    return namesList;
  };

  // Method to get payload for update practitioner
  const getUpdatePractitionerPayload = useCallback(
    (profilePhotoUrl?: string): SchedulerV3.Types.UpdateProviderIO['input'] => {
      const [firstName, middleName, lastName] = getFirstNameLastNameMiddleName(
        values?.fullName || practitionerFullName || ''
      );
      return {
        id: practitionerId || '',
        firstName: firstName,
        lastName: lastName,
        ...(middleName && { middleName: middleName }),
        displayName: values?.displayName,
        hidden: practitionerDetails?.hidden,
        profile: {
          ...practitionerDetails?.profile,
          bio: values?.bio,
          picUrl: profilePhotoUrl ?? profilePhotoSrc,
        },
      };
    },
    [values, profilePhotoSrc, practitionerDetails, practitionerFullName]
  );

  // Method to get payload for create practitioner
  const getCreatePractitionerPayload = (): CreatePractitionerRequest => {
    const [firstName, middleName, lastName] = getFirstNameLastNameMiddleName(values?.fullName || '');
    return {
      locationId: selectedLocationId,
      sourceTenantId: selectedLocationId || '',
      firstName: firstName,
      lastName: lastName,
      ...(middleName && { middleName: middleName }),
      displayName: values?.displayName,
      hidden: true,
      profile: {
        act: true,
        bio: values?.bio,
        picUrl: profilePhotoSrc,
      },
    };
  };

  // Method to handle update practitioner
  const handleUpdatePractitioner = (profilePhotoUrl?: string) => {
    const changedAppointmentTypes = getAppointmentTypeSelectedUnselectedValues();

    const payload = getUpdatePractitionerPayload(profilePhotoUrl);

    const updatePractitionerMethod = () => updateProviderMutation.mutateAsync(payload);

    const updatePractitionerAppointmentTypesMapping = () =>
      createAndDeleteMappingsAsync({
        locationId: selectedLocationId,
        providerId: practitionerId ?? '',
        values: changedAppointmentTypes.newSelectedAppointmentTypeIds,
        valueType: ValueType.VT_APPOINTMENT_TYPE,
        mappingIdsForDelete: changedAppointmentTypes.mappingIdsForDelete,
      });

    return Promise.all([updatePractitionerMethod(), updatePractitionerAppointmentTypesMapping()])
      .then(() => {
        alert.success(t('Practitioner details updated successfully'));
        onSave?.();
      })
      .catch((err) => {
        alert.error(t('Failed to update Practitioner'));
        onErrors?.(err);
      });
  };

  const handlePostSavePractitioner = (practitioner: Practitioner) => {
    alert.success(t('Practitioner created successfully'));
    setPractitionerDetailsOnCreate?.(practitioner);
    onSave?.();
  };

  // Method to handle create practitioner
  const handleCreatePractitioner = async () => {
    const changedAppointmentTypes = getAppointmentTypeSelectedUnselectedValues();
    const payload = getCreatePractitionerPayload();

    createProviderMutation
      .mutateAsync(payload)
      .then((res) => {
        createAndDeleteMappingsAsync({
          locationId: selectedLocationId,
          providerId: res.provider.id,
          values: changedAppointmentTypes.newSelectedAppointmentTypeIds,
          valueType: ValueType.VT_APPOINTMENT_TYPE,
        })
          .then(() => {
            handlePostSavePractitioner(res.provider);
          })
          .catch((err) => {
            alert.error(t('Failed to create practitioner'));
            onErrors?.(err);
          });
      })
      .catch((err) => {
        alert.error(t('Failed to create practitioner'));
        onErrors?.(err);
      });
  };

  const handleOnSave = () => {
    if (practitionerId) {
      handleUpdatePractitioner();
    } else {
      handleCreatePractitioner();
    }
  };

  const handleOnCancel = () => {
    onCancel?.();
  };

  const managePractitionerProfilePhotoProviderValue = useMemo(() => {
    return {
      practitionerDetails,
      profilePhotoSrc,
      setProfilePhotoSrc,
      updatePractitionerDetails: handleUpdatePractitioner,
      name: values?.fullName || practitionerFullName,
    };
  }, [practitionerDetails, profilePhotoSrc, practitionerId, values?.fullName]);

  const isSaving = createProviderMutation.isLoading || updateProviderMutation.isLoading || isUpdatingMappings;

  if (isLoading || isLoadingIntegrationDetails) {
    return (
      <div css={styles.flexCenter}>
        <SpinningLoader size='small' />
      </div>
    );
  }

  return (
    <>
      <form {...formProps}>
        <ManagePractitionerProfilePhotoContext.Provider value={managePractitionerProfilePhotoProviderValue}>
          <PractitionerPhotoContainer />
        </ManagePractitionerProfilePhotoContext.Provider>

        <FormRow cols={[100, 100]} css={practitionerTextFieldContainerStyles(isSmall)}>
          <PractitionerTextField
            props={getFieldProps('fullName')}
            label={t('Provider Name')}
            helperText={t("The provider's name as it appears in your Practice Management System.")}
            value={getFieldProps('fullName').value}
            disabled={isIntegratedOffice}
            isStepperComponent={isStepperComponent}
          />
          <PractitionerTextField
            props={getFieldProps('displayName')}
            label={t('Display Name')}
            helperText={t('This name will be visible to customers when booking online, and on your Weave calendar')}
            value={getFieldProps('displayName').value}
            isStepperComponent={isStepperComponent}
          />
        </FormRow>
        {AppointmentTypeCheckListDropdownElement}
        <FormRow cols={[100]} css={textAreaFieldContainerStyles}>
          <TextareaField label={t('Provider Biography')} {...getFieldProps('bio')} />
        </FormRow>
        <Text size={isStepperComponent ? 'small' : 'medium'} color='light'>
          {t(
            "Enter a brief description of the provider's background & expertise. Patients will see this when booking appointments online."
          )}
        </Text>
      </form>
      {!isStepperComponent ? (
        <ButtonBar>
          {shouldRenderSecondaryButton && (
            <Button
              disabled={isSaving}
              variant='secondary'
              onClick={handleOnCancel}
              css={{ width: 'max-content' }}
              size='large'
              type='button'
            >
              {secondaryButtonLabel}
            </Button>
          )}
          <Button
            disabled={!isComplete || hasAppointmentTypeCheckListDropdownError}
            loading={isSaving}
            onClick={handleOnSave}
            css={{ width: 'max-content' }}
            size='large'
            type='button'
          >
            {primaryButtonLabel}
          </Button>
        </ButtonBar>
      ) : (
        <Stepper.ButtonBar>
          <Stepper.NextButton isValid={isComplete && !hasAppointmentTypeCheckListDropdownError} onClick={handleOnSave}>
            {primaryButtonLabel}
          </Stepper.NextButton>
        </Stepper.ButtonBar>
      )}
    </>
  );
};

const textAreaFieldContainerStyles = css({
  margin: theme.spacing(2, 0, 0, 0),
  padding: theme.spacing(2, 0, 2, 0),
  flexDirection: 'column',
});

const practitionerTextFieldContainerStyles = (isSmall: boolean) =>
  css({
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: isSmall ? 'column' : 'row',
  });
