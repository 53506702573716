import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { Permission } from '@weave/schema-gen-ts/dist/shared/waccess/acls.pb';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { capitalize } from 'lodash-es';
import { AnalyticsCommonTypes, PhoneAnalyticsApi, PhoneAnalyticsTypes } from '@frontend/api-analytics';
import { CallRecordingApi } from '@frontend/api-call-recordings';
import { getWeaveToken, hasSchemaACL } from '@frontend/auth-helpers';
import appConfig from '@frontend/env';
import { http } from '@frontend/fetch';
import { useTranslation } from '@frontend/i18n';
import { useLocalizedInfiniteQuery } from '@frontend/location-helpers';
import { formatPhoneNumber } from '@frontend/phone-numbers';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import {
  ConfirmationModal,
  ContentLoader,
  DownloadIcon,
  SortingValue,
  Table,
  TrashIcon,
  useModalControl,
  useAlert,
} from '@frontend/design-system';
import { ColumnHeaderInfoTip, ExportDataModal, InfoTipPopover, Status } from '..';
import { useLocations } from '../../hooks';
import { largeDownloadsKeys } from '../../large-downloads-keys';
import { queryKeys } from '../../query-keys';
import { trackingIds } from '../../tracking-ids';
import { getTimeZoneDateTime } from '../../utils';
import { pageStyles, tableStyles } from '../../views/common-styles';
import { PhoneNumberSelector } from '../filter-selectors';
import { largeDownloadsRef } from '../large-downloads';
import { usePhoneAnalyticsShallowStore } from './hooks';

dayjs.extend(duration);

interface Props {
  onFetchStateChange: (isFetching: boolean) => void;
}

const DEFAULT_PAGE_SIZE = 25;

export const PhoneReportTable: FC<React.PropsWithChildren<Props>> = ({ onFetchStateChange }) => {
  const { t } = useTranslation('analytics');
  const alert = useAlert();
  const { locations } = useLocations();
  const { demoData, filters, isDemoAccount, setFilters } = usePhoneAnalyticsShallowStore(
    'demoData',
    'filters',
    'isDemoAccount',
    'setFilters'
  );
  const weaveToken = getWeaveToken();
  const deleteCallRecordRef = useRef<{ id: string; callerNumber: string } | undefined>(undefined);
  const { selectedLocationIdsWithParents } = useAppScopeStore();
  const [errorChannelIds, setErrorChannelIds] = useState<Record<string, boolean>>({});
  const [isManagingMedia, setIsManagingMedia] = useState<boolean>(false);
  const deleteCallRecordingControls = useModalControl();
  const exportModalControl = useModalControl();
  const locationId = selectedLocationIdsWithParents[0];
  const hasAccessToCallRecordings = locationId && hasSchemaACL(locationId, Permission.CALL_RECORDING_READ);

  const [pageConfig, setPageConfig] = useState<AnalyticsCommonTypes.PageConfig>({
    pageNumber: 1,
    pageSize: DEFAULT_PAGE_SIZE,
  });

  const queryString = useMemo(
    () => `${JSON.stringify({ ...filters })}-${pageConfig.pageSize}-${isDemoAccount}`,
    [filters, pageConfig.pageSize, isDemoAccount]
  );

  const { data, fetchNextPage, hasNextPage, hasPreviousPage, isFetching, refetch } = useLocalizedInfiniteQuery({
    queryKey: queryKeys.phoneAnalyticsReport(queryString),
    queryFn: ({ pageParam }) =>
      isDemoAccount
        ? { data: [], meta: { links: {} } }
        : PhoneAnalyticsApi.getRawPhoneReport(filters, pageConfig.pageSize, pageParam),
    getNextPageParam: (lastPage: PhoneAnalyticsTypes.RawPhoneReportResponse | undefined) => {
      const nextLink = lastPage?.meta?.links?.next || '';
      return nextLink?.substring(nextLink.indexOf('/') + 1);
    },
    getPreviousPageParam: (lastPage: PhoneAnalyticsTypes.RawPhoneReportResponse | undefined) => {
      const prevLink = lastPage?.meta?.links?.previous || '';
      return prevLink?.substring(prevLink.indexOf('/') + 1);
    },
    onError: () => {
      alert.error(t("Couldn't load the phone reports. Please try again."));
    },
    retry: false,
    refetchOnWindowFocus: false,
    select: (data) => {
      return isDemoAccount
        ? {
            pageParams: [],
            pages: [demoData?.tableData],
          }
        : data;
    },
    staleTime: 5 * 60 * 1000, // 5 minutes
  });

  const getHasNext = () => {
    const pageData = data?.pages[pageConfig.pageNumber - 1]?.data || [];
    return hasNextPage && !!pageData.length && pageData.length == pageConfig.pageSize;
  };

  const handleSorting = (sortValues: SortingValue<string>[]) => {
    const { id, value } = sortValues[0] || {};
    if (id && value) {
      setFilters({
        order_by_asc: value === 'asc',
        order_by_field: id,
      });
    }
  };

  const handleDownloadCallRecording = async ({ ID }: PhoneAnalyticsTypes.Phone) => {
    const mediaUrl = `${appConfig.BACKEND_API}/portal/v1/phone/callRecordings/${ID}?location_id=${locationId}&token=${weaveToken}`;
    try {
      setIsManagingMedia(true);
      const blob = await CallRecordingApi.download(mediaUrl);
      // Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob as BlobPart]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${url.split('/')[3]}.mp3`);
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
      setIsManagingMedia(false);
    } catch (e) {
      alert.error(t('Unable to download the call recording'));
      setIsManagingMedia(false);
    }
  };

  const handleDeleteCallRecording = async () => {
    const { id, callerNumber } = deleteCallRecordRef.current || {};
    if (!id || !callerNumber) {
      return;
    }

    try {
      setIsManagingMedia(true);
      await CallRecordingApi.deleteMedia(id, callerNumber);
      refetch();
      setIsManagingMedia(false);
    } catch (error) {
      if (http.isHttpError(error) && error.status === 403) {
        const httpError = error?.data as Record<string, string>;
        const displayMessage = httpError.error
          ? httpError.error.charAt(0).toUpperCase() + httpError.error.slice(1).toLowerCase()
          : t('Unable to delete the call recording');
        alert.error(displayMessage);
      } else {
        alert.error(t('Unable to delete the call recording'));
      }
      setIsManagingMedia(false);
    }
  };

  useEffect(() => {
    setPageConfig({ ...pageConfig, pageNumber: 1 });
  }, [filters]);

  useEffect(() => {
    if (!data?.pages[pageConfig.pageNumber - 1]) {
      fetchNextPage();
    }
  }, [pageConfig.pageNumber]);

  useEffect(() => {
    onFetchStateChange(isFetching);
  }, [isFetching]);

  return (
    <div css={styles.wrapper}>
      <Table
        colConfig={[
          {
            Header: (
              <ColumnHeaderInfoTip
                columnTitle={t('Location')}
                infoTip={<InfoTipPopover>{t('Location name of the office')}</InfoTipPopover>}
              />
            ),
            headerLabel: t('Location'),
            accessor: ({ LocationName }) => LocationName || '-',
            disableSortBy: true,
            id: 'LocationName',
            omit: filters.location_id?.length === 1,
            width: 250,
          },
          {
            Header: (
              <ColumnHeaderInfoTip
                columnTitle={t('Calling Number')}
                infoTip={<InfoTipPopover>{t('Phone number from which call is originating')}</InfoTipPopover>}
              />
            ),
            headerLabel: t('Calling Number'),
            accessor: ({ CallerNumber }) => formatPhoneNumber(CallerNumber),
            id: 'CallerNumber',
            width: 180,
          },
          {
            Header: (
              <ColumnHeaderInfoTip
                columnTitle={t('Called Number')}
                infoTip={<InfoTipPopover>{t('Phone number which is being called')}</InfoTipPopover>}
              />
            ),
            headerLabel: t('Called Number'),
            accessor: ({ DialedNumber }) => formatPhoneNumber(DialedNumber),
            id: 'DialedNumber',
            width: 180,
          },
          {
            Header: (
              <ColumnHeaderInfoTip
                columnTitle={t('Date / Time')}
                infoTip={
                  <InfoTipPopover>{t('Time and date when call originated. It is in Local Time')}</InfoTipPopover>
                }
              />
            ),
            headerLabel: t('Date / Time'),
            accessor: ({ StartTime, TimeZone }) =>
              StartTime && TimeZone ? getTimeZoneDateTime(TimeZone, StartTime) : '-',
            id: 'StartTime',
            width: 370,
            startingSortBy: 'desc',
          },
          {
            Header: (
              <ColumnHeaderInfoTip
                columnTitle={t('Call Type')}
                infoTip={<InfoTipPopover>{t('Whether it is an outgoing or incoming call')}</InfoTipPopover>}
              />
            ),
            headerLabel: t('Call Type'),
            accessor: ({ CallDirection }) =>
              CallDirection ? (CallDirection.toLowerCase() === 'inbound' ? t(`Incoming`) : t('Outgoing')) : '-',
            id: 'CallDirection',
            width: 140,
          },
          {
            Header: (
              <ColumnHeaderInfoTip
                columnTitle={t('Contact Type')}
                infoTip={
                  <InfoTipPopover>
                    {t(
                      'It gives more information about the contact and their appointment history. Refer Weave Help for more information'
                    )}
                  </InfoTipPopover>
                }
              />
            ),
            headerLabel: t('Contact Type'),
            accessor: ({ AppointmentCategory }) => AppointmentCategory || '-',
            disableSortBy: true,
            id: 'AppointmentCategory',
            width: 200,
          },
          {
            Header: t('Department'),
            headerLabel: t('Department'),
            accessor: ({ Department }) => Department || '-',
            disableSortBy: true,
            id: 'Department',
            width: 200,
          },
          {
            Header: (
              <ColumnHeaderInfoTip
                columnTitle={t('Result')}
                infoTip={<InfoTipPopover>{t('Tells whether call was answered, missed, or abandoned')}</InfoTipPopover>}
              />
            ),
            headerLabel: t('Result'),
            accessor: ({ CallStatus, ScheduleMatchOpen }) => ({
              CallStatus,
              ScheduleMatchOpen,
            }),
            cellRenderer: ({ CallStatus, ScheduleMatchOpen }) => (
              <Status
                status={
                  CallStatus === 'missed' ? `${CallStatus}-${ScheduleMatchOpen ? 'Open' : 'Closed'} Hours` : CallStatus
                }
              />
            ),
            id: 'CallStatus',
            width: 200,
          },
          {
            Header: (
              <ColumnHeaderInfoTip
                columnTitle={t('Patient Name')}
                infoTip={
                  <InfoTipPopover>
                    {t(
                      "Name of the patient in the location's management software. Value is blank if data isn't in the management software for the phone number"
                    )}
                  </InfoTipPopover>
                }
              />
            ),
            headerLabel: t('Patient Name'),
            accessor: ({ PatientName }) => PatientName || '-',
            disableSortBy: true,
            id: 'PatientName',
            width: 250,
          },
          {
            Header: (
              <ColumnHeaderInfoTip
                columnTitle={t('Duration')}
                infoTip={<InfoTipPopover>{t('Duration of the call. It includes ring duration')}</InfoTipPopover>}
              />
            ),
            headerLabel: t('Duration'),
            accessor: ({ DurationInSec }) => dayjs.duration(DurationInSec || 0, 'seconds').format('HH:mm:ss'),
            id: 'DurationInSec',
            width: 150,
          },
          {
            Header: (
              <ColumnHeaderInfoTip
                columnTitle={t('Source Type')}
                infoTip={<InfoTipPopover>{t('Tells whether call is with patient or not')}</InfoTipPopover>}
              />
            ),
            headerLabel: t('Source Type'),
            accessor: ({ SourceType }) => (SourceType ? capitalize(SourceType.replaceAll('_', ' ')) : '-'),
            id: 'SourceType',
            width: 175,
          },
          {
            Header: (
              <ColumnHeaderInfoTip
                columnTitle={t('Action')}
                infoTip={<InfoTipPopover>{t('Tells about the last action taken during the call')}</InfoTipPopover>}
              />
            ),
            headerLabel: t('Action'),
            accessor: ({ ActionType }) => (ActionType ? capitalize(ActionType) : '-'),
            id: 'ActionType',
            width: 140,
          },
          {
            Header: t('Call Recording'),
            headerLabel: t('Call Recording'),
            accessor: ({ CallStatus, ID }) => ({ ID, CallStatus }),
            cellRenderer: ({ CallStatus, ID }) => (
              <>
                {!isDemoAccount && ID && CallStatus === 'answered' && !errorChannelIds[ID] ? (
                  <audio
                    controls
                    controlsList='nodownload'
                    css={styles.audio}
                    onError={() => setErrorChannelIds({ ...errorChannelIds, [ID]: true })}
                    preload='metadata'
                    src={`${appConfig.BACKEND_API}/portal/v1/phone/callRecordings/${ID}?location_id=${locationId}&token=${weaveToken}`}
                  >
                    {t('Your browser does not support the audio tag.')}
                  </audio>
                ) : (
                  <span css={styles.disabled}>
                    {isDemoAccount ? t('Recording not available for demo accounts') : t('Recording not available')}
                  </span>
                )}
              </>
            ),
            id: 'CallRecording',
            omit: !hasAccessToCallRecordings,
            width: 350,
          },
          {
            Header: (
              <ColumnHeaderInfoTip
                columnTitle={t('Office User')}
                infoTip={
                  <InfoTipPopover>
                    {t(
                      'The user at the location who picked up or dialed the number. The office needs to link their desk phone through the Weave Desktop App sign-in page'
                    )}
                  </InfoTipPopover>
                }
              />
            ),
            headerLabel: t('Office User'),
            accessor: ({ OfficeUser }) => OfficeUser || '-',
            disableSortBy: true,
            id: 'OfficeUser',
            width: 250,
          },
        ]}
        customToolbarRender={(_tableInstance, isMobile) => (
          <div css={pageStyles.numberSearch(isMobile)}>
            <PhoneNumberSelector
              css={isMobile ? styles.fullWidth : undefined}
              disabled={isFetching || isManagingMedia}
              onChange={(value) => setFilters({ number_search: value })}
              value={filters.number_search}
            />
          </div>
        )}
        data={data?.pages[pageConfig.pageNumber - 1]?.data || []}
        disableMultiSort
        emptyStateConfig={{
          type: 'sync_your_phone',
        }}
        tableInstanceId='phone-report'
        hasFilterColumns
        hasResizeColumns
        hasResponsiveColWidths
        isLoading={isFetching}
        isPaginated
        manualPaginationConfig={{
          handleChange: (action: 'next' | 'prev') => {
            if (action === 'next') {
              setPageConfig({ ...pageConfig, pageNumber: pageConfig.pageNumber + 1 });
            } else {
              setPageConfig({ ...pageConfig, pageNumber: pageConfig.pageNumber - 1 });
            }
          },
          hasNext: getHasNext(),
          hasPrevious: hasPreviousPage,
          page: pageConfig.pageNumber,
          defaultRowsPerPage: pageConfig.pageSize,
          onNumRowsChange: (num) => {
            setPageConfig({ pageNumber: 1, pageSize: num });
          },
          rowsPerPageOptions: [25, 50, 75, 100],
        }}
        manualSortBy
        onSortChange={handleSorting}
        rowActions={
          hasAccessToCallRecordings
            ? {
                actions: [
                  {
                    hide: ({ ID, CallStatus }) =>
                      !isDemoAccount && (!ID || CallStatus !== 'answered' || !!errorChannelIds[ID]),
                    Icon: DownloadIcon,
                    label: t('Download call recording'),
                    onClick: handleDownloadCallRecording,
                  },
                  {
                    hide: ({ ID, CallStatus }) =>
                      !isDemoAccount && (!ID || CallStatus !== 'answered' || !!errorChannelIds[ID]),
                    Icon: TrashIcon,
                    label: t('Delete call recording'),
                    onClick: ({ CallerNumber, ID }: PhoneAnalyticsTypes.Phone) => {
                      if (!CallerNumber || !ID) {
                        return;
                      }

                      deleteCallRecordRef.current = {
                        id: ID,
                        callerNumber: CallerNumber,
                      };
                      deleteCallRecordingControls.openModal();
                    },
                  },
                ],
              }
            : {}
        }
        tableActions={[
          {
            disabled:
              isFetching || largeDownloadsRef.current?.downloadsWaitState[largeDownloadsKeys.phoneAnalytics]?.isWaiting,
            Icon: DownloadIcon,
            label: t('Download Data'),
            onClick: exportModalControl.openModal,
            trackingId: trackingIds.phoneAnalytics.exportTableData,
            type: 'icon',
          },
        ]}
        wrapperStyle={tableStyles}
      />
      <ConfirmationModal
        {...deleteCallRecordingControls.modalProps}
        destructive
        message={t('Are you sure you want to delete this call recording?')}
        onConfirm={handleDeleteCallRecording}
        title={t('Delete Confirmation')}
      />
      <ExportDataModal<PhoneAnalyticsTypes.ExportCheckPayload>
        apiCall={PhoneAnalyticsApi.getExportableDetails}
        downloadDataUrl='downloadreports/v2/call_report'
        downloadKey={largeDownloadsKeys.phoneAnalytics}
        endDate={filters.end_date}
        fileName='Weave_Call_Report'
        generatePayload={({ currentLocationId, parentLocationId }) => ({
          ...filters,
          current_location_id: currentLocationId,
          parent_location_id: parentLocationId,
          report_type: 'call_report',
          call_reports_filters: {
            call_type: filters.call_type,
            result: filters.result,
            source_type: filters.source_type,
          },
        })}
        locationIds={filters.location_id}
        locationNames={locations}
        modalProps={exportModalControl.modalProps}
        primaryButtonTrackingId={trackingIds.phoneAnalytics.exportTableDataConfirm}
        referenceCheckUrl='downloadreports/v2/getmediaurl'
        secondaryButtonTrackingId={trackingIds.phoneAnalytics.exportTableDataCancel}
        startDate={filters.start_date}
      />
      <ContentLoader show={isManagingMedia} />
    </div>
  );
};

const styles = {
  wrapper: css`
    height: 100%;
    position: relative;

    .table.sticky {
      min-height: auto !important;
    }
  `,

  audio: css`
    height: ${theme.spacing(4)};
    width: 100%;
  `,

  disabled: css`
    color: ${theme.colors.neutral30};
    display: inline-block;
    text-align: center;
    width: 100%;
  `,

  fullWidth: css`
    max-width: 100%;
  `,
};
