import { css } from '@emotion/react';
import appConfig from '@frontend/env';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Heading, Tabs, Text } from '@frontend/design-system';
import { CopyLinkHelper } from '../../components/CopyLinkHelper';

enum TabEnum {
  EmbedLink = 'embedLink',
  WebLink = 'webLink',
}

type WidgetSettingsTabProps = {
  locationId: string;
};

export const WidgetSettingsTab = ({ locationId }: WidgetSettingsTabProps) => {
  const { t } = useTranslation('scheduleGlobalSettings');

  return (
    <>
      <section css={sectionStyle}>
        <Tabs initialTab={TabEnum.EmbedLink}>
          <Tabs.Bar>
            <Tabs.Tab id={TabEnum.EmbedLink} controls={`${TabEnum.EmbedLink}-panel`}>
              {t('Embed Widget')}
            </Tabs.Tab>
            <Tabs.Tab id={TabEnum.WebLink} controls={`${TabEnum.WebLink}-panel`}>
              {t('Add Link')}
            </Tabs.Tab>
          </Tabs.Bar>
          <Tabs.Panel css={tabPanelStyle} id={`${TabEnum.EmbedLink}-panel`} controller={TabEnum.EmbedLink}>
            <Heading level={3}>{t("Your location's snippet")}</Heading>
            <Text>
              {t(
                'Copy and paste the code snippet below into the <head> section of the webpage where you want the Text Connect widget to appear:'
              )}
            </Text>
            <CopyLinkHelper
              tooltipText={t('Snippet will be copied to clipboard')}
              tooltipSuccessText={t('Snippet copied')}
              buttonText={t('Copy Snippet')}
              textToCopy={`<script defer src="${appConfig.APP_BOOK_BASE_URL}/text-connect/${locationId}/widget.js"></script>`}
            />
            <Text css={{ marginTop: theme.spacing(1) }}>
              {t(
                'This will add a small expandable widget to your webpage that customers can use to initiate a text conversation.'
              )}
            </Text>
          </Tabs.Panel>
          <Tabs.Panel css={tabPanelStyle} id={`${TabEnum.WebLink}-panel`} controller={TabEnum.WebLink}>
            <Heading level={3}>{t("Your location's URL")}</Heading>
            <Text>
              {t(
                'Insert this unique URL to add a Text Connect link anywhere on your website, social media, Google Business page, or other online platforms'
              )}
            </Text>
            <CopyLinkHelper textToCopy={`${appConfig.APP_BOOK_BASE_URL}/${locationId}/text-connect/contact-info`} />
            <Text css={{ marginTop: theme.spacing(1) }}>
              {t(
                'When customers click this link, they will be directed to a web portal where they can send a text message directly to your office.'
              )}
            </Text>
          </Tabs.Panel>
        </Tabs>
      </section>
    </>
  );
};

const sectionStyle = css({
  padding: theme.spacing(0, 2, 2, 2),
});

const tabPanelStyle = css({
  marginTop: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
});
