import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyle = css`
  > li {
    list-style: none;
  }
`;

export const bannerNotificationStyle = css`
  margin-top: ${theme.spacing(2)};
`;

export const innerContainerStyle = css`
  padding: ${theme.spacing(8, 0)};
`;
