import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { BulkEmailPrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import { BaseFieldProps, NumberField, Text, useTooltip } from '@frontend/design-system';

type Props = {
  fieldProps: BaseFieldProps<string>;
  setData: (value: number) => void;
};

export const MessageSpend = ({ fieldProps, setData }: Props) => {
  const { t } = useTranslation('bulk-messaging');
  const { Tooltip, tooltipProps, triggerProps } = useTooltip({ placement: 'top-end' });

  return (
    <div css={{ position: 'relative', width: 160 }}>
      <NumberField
        {...fieldProps}
        onChange={(e) => {
          fieldProps.onChange(e);
          // @ts-ignore - DS type is incorrect, { name: '', value: '' } is what this returns
          setData(Number(e?.value));
        }}
        name=''
        label=''
        endAdornment='messages'
        css={{
          padding: theme.spacing(1),
          ['input']: {
            textAlign: 'right',
            ['&:not(:last-child)']: {
              marginRight: theme.spacing(0.5),
            },
          },
        }}
        data-trackingid={`${BulkEmailPrefixes.Audience}-message-spend-input`}
      />
      {/* To always show this "label" and the tooltip even if the field is empty (and not within the text field) */}
      <div
        css={{
          position: 'absolute',
          top: -8,
          left: 8,
          display: 'flex',
          alignItems: 'center',
          background: theme.colors.white,
          padding: theme.spacing(0, 0.5),
          gap: theme.spacing(0.5),
        }}
        {...triggerProps}
      >
        <Text size='small' css={{ color: theme.colors.neutral50 }}>
          {t('Message Spend')}
        </Text>
        <Icon name='info-small' css={{ color: theme.colors.neutral70 }} />
      </div>
      <Tooltip {...tooltipProps}>
        {t('Message spend directly relates to the amount of contacts that will receive a message from this location.')}
      </Tooltip>
    </div>
  );
};
