import { useEffect } from 'react';
import { css } from '@emotion/react';
import { BulkEmailPrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import { NumberField, useFormField } from '@frontend/design-system';

type Props = {
  minAgeLabel: string;
  maxAgeLabel: string;
  minAge: number | undefined;
  maxAge: number | undefined;
  onChangeMinAge: (value: number | undefined) => void;
  onChangeMaxAge: (value: number | undefined) => void;
};

export const AgeFilter = ({ maxAge, minAge, minAgeLabel, maxAgeLabel, onChangeMinAge, onChangeMaxAge }: Props) => {
  const minTrackingId = `${minAgeLabel.toLowerCase().split(' ').join('-')}-filter-input`;
  const maxTrackingId = `${maxAgeLabel.toLowerCase().split(' ').join('-')}-filter-input`;

  // TODO: Add validation so the minimum is always less than the maximum
  const minAgeField = useFormField({ type: 'number', min: 0, max: 99, value: minAge?.toString() });
  const maxAgeField = useFormField({ type: 'number', min: 0, max: 99, value: maxAge?.toString() });

  useEffect(() => {
    const minValue = minAgeField.value;
    onChangeMinAge(minValue ? +minValue : undefined);
  }, [minAgeField.value]);

  useEffect(() => {
    const maxValue = maxAgeField.value;
    onChangeMaxAge(maxValue ? +maxValue : undefined);
  }, [maxAgeField.value]);

  return (
    <div
      css={[
        css`
          display: grid;
          gap: ${theme.spacing(2)};
          grid-template-columns: auto auto;
        `,
      ]}
    >
      <NumberField
        name='minimum-age'
        {...minAgeField}
        label={minAgeLabel}
        data-trackingid={`${BulkEmailPrefixes.Audience}-${minTrackingId}`}
      />
      <NumberField
        name='maximum-age'
        {...maxAgeField}
        label={maxAgeLabel}
        data-trackingid={`${BulkEmailPrefixes.Audience}-${maxTrackingId}`}
      />
    </div>
  );
};
