import { useMemo } from 'react';
import { BASE_URL_ANALYTICS } from '@frontend/api-analytics';
import { http } from '@frontend/fetch';
import { useTranslation } from '@frontend/i18n';
import { ConversionType, ROIFetchParams } from './shared-types';
import { useFetchRoiData } from './use-fetch-roi-data';

type CategorizedConversions = {
  conversion_type: ConversionType;
  count: number;
}[];

type UseFetchMissedCallTextAttributionProps = {
  categorizedConversions?: Partial<Record<ConversionType, number>>;
  isLoading?: boolean;
  sortedConversionTypes?: ConversionType[];
};

type Props = {
  apiParams: ROIFetchParams;
  isEnabled?: boolean;
};

const api = async ({ attributeType, messagingCategory, payload }: ROIFetchParams) => {
  const response = await http.post<{ data: CategorizedConversions }>(
    `${BASE_URL_ANALYTICS}/roi/v2/${messagingCategory}/chartview/${attributeType}`,
    payload
  );
  return response.data;
};

const allConversionTypes: ConversionType[] = ['Same Day', '7 Days', '14 Days', '21 Days', '28 Days'];

export const useFetchMissedCallTextAttribution = (props: Props): UseFetchMissedCallTextAttributionProps => {
  const { t } = useTranslation('analytics');

  const { data, isLoading } = useFetchRoiData<ROIFetchParams, CategorizedConversions>({
    ...props,
    api,
    errorMessage: t("Couldn't fetch the missed call text attribution. Please try again."),
    uniqueId: 'mct-attribution',
  });

  const categoryConversions = useMemo(() => {
    const categorizedConversionsMap: Partial<Record<ConversionType, number>> = {};
    const keysInData: ConversionType[] = [];

    data?.forEach((item) => {
      categorizedConversionsMap[item.conversion_type] = item.count;
      if (!keysInData.includes(item.conversion_type)) {
        keysInData.push(item.conversion_type);
      }
    });

    const filteredKeys = allConversionTypes.filter((key) => keysInData.includes(key));
    const sortedConversionTypes = props.apiParams.attributeType === 'forward' ? filteredKeys : filteredKeys.reverse();

    return {
      categorizedConversions: categorizedConversionsMap,
      sortedConversionTypes,
    };
  }, [data, props.apiParams.attributeType]);

  return {
    isLoading,
    ...categoryConversions,
  };
};
