import { useMemo } from 'react';
import { css } from '@emotion/react';
import { useNavigate } from '@tanstack/react-location';
import { PortingQueries } from '@frontend/api-porting';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useLocationDataShallowStore } from '@frontend/location-helpers';
import { theme } from '@frontend/theme';
import { Chip, Heading, NakedButton, Text, TextButton, useModalControl } from '@frontend/design-system';
import { PortStatusInfoModal } from '../components/common';
import { PhoneNumbersPageTab } from '../constants';
import { getPortRequestStatusChipColor, getSoonerUpcomingPortingRequest } from '../helper';
import { trackingIds } from '../tracking-ids';

export const UpcomingPortRequest = () => {
  const { t } = useTranslation('porting');
  const { locationId, isParentLocation } = useLocationDataShallowStore('locationId', 'isParentLocation');
  const navigate = useNavigate();
  const portStatusInfoModalControl = useModalControl();

  const { data } = PortingQueries.useGetPortStatusByLocationIds([locationId], {
    enabled: !!locationId && !isParentLocation,
  });

  const upcomingPortingInfo = useMemo(
    () => (data ? getSoonerUpcomingPortingRequest(data.portingStatusInfo) : undefined),
    [data]
  );

  const handleViewRequestClick = () => {
    navigate({
      to: `/portal/account/phone-numbers?tab=${PhoneNumbersPageTab.PortRequests}&portingId=${
        upcomingPortingInfo?.portRequestId ?? ''
      }`,
    });
  };

  const handleInfoIconClick = () => {
    portStatusInfoModalControl.openModal();
  };

  if (!upcomingPortingInfo) return null;
  return (
    <section
      data-test-id='upcoming-port-request-card'
      css={wrapperStyle}
      data-trackingid={trackingIds.landingPagePortRequest.portStatusCard}
    >
      <Heading level={3}>{t('Porting Status')}</Heading>
      <div css={contentContainerStyle}>
        <div>
          <div css={{ display: 'flex', gap: theme.spacing(1) }}>
            <Text size='small' color='light'>
              {t('Porting Status')}
            </Text>
            <NakedButton onClick={handleInfoIconClick} trackingId={trackingIds.landingPagePortRequest.infoIconButton}>
              <Icon name='info-small' color='light' />
            </NakedButton>
          </div>
          <Chip variant={getPortRequestStatusChipColor(upcomingPortingInfo.portRequestStatus)}>
            {upcomingPortingInfo.portRequestStatus}
          </Chip>
        </div>
        <div>
          <Text size='small' color='light'>
            {t('Requested Date')}
          </Text>
          <Text>{upcomingPortingInfo.requestedFOCDate}</Text>
        </div>
        <div>
          <Text size='small' color='light'>
            {t('Accepted Date')}
          </Text>
          <Text>{upcomingPortingInfo.acceptedFOCDate}</Text>
        </div>
        <div>
          <TextButton
            css={buttonStyle}
            onClick={handleViewRequestClick}
            trackingId={trackingIds.landingPagePortRequest.viewRequestButton}
          >
            {t('View Request')}
          </TextButton>
        </div>
      </div>
      <PortStatusInfoModal modalProps={portStatusInfoModalControl.modalProps} />
    </section>
  );
};

const wrapperStyle = css({
  position: 'relative',
  marginBottom: theme.spacing(4),
  padding: theme.spacing(2),
  boxShadow: theme.shadows.heavy,
  borderRadius: theme.borderRadius.medium,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  maxWidth: 700,
});

const contentContainerStyle = css({
  display: 'flex',
  gap: theme.spacing(2),
  flexWrap: 'wrap',
  '> div': {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    ':last-of-type': {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
  },
});
const buttonStyle = css({
  maxWidth: 'fit-content',
  color: theme.colors.primary50,
  fontWeight: theme.font.weight.bold,
  padding: theme.spacing(1),
});
