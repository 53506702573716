import { css } from '@emotion/react';
import { PortingTypes } from '@frontend/api-porting';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { ChecklistField, FormRow, ModalControlModalProps, TableFilters, useForm } from '@frontend/design-system';

interface Props extends ModalControlModalProps {
  onChange: (numberTypes: PortingTypes.PhoneNumberType[]) => void;
}

export const TableFilterPanel = ({ onChange, ...modalProps }: Props) => {
  const { t } = useTranslation('porting');
  const { formProps, getFieldProps, reset } = useForm({
    fields: {
      filterByNumberType: { type: 'checklist' },
    },
  });
  const filterByNumberTypeFieldProps = getFieldProps('filterByNumberType');

  const handleApplyClick = () => {
    onChange(filterByNumberTypeFieldProps.value as PortingTypes.PhoneNumberType[]);
    modalProps.onClose();
  };
  const handleClearAllClick = () => {
    onChange([]);
    reset();
    modalProps.onClose();
  };

  return (
    <TableFilters
      {...modalProps}
      width='small'
      applyLabel={t('Apply')}
      clearAllLabel={t('Clear')}
      onApplyClick={handleApplyClick}
      onClearAllClick={handleClearAllClick}
    >
      <TableFilters.Section sectionHeaderLabel={''}>
        <form {...formProps}>
          <FormRow>
            <ChecklistField {...filterByNumberTypeFieldProps} css={headingStyle} label={t('Number Type')}>
              {Object.values(PortingTypes.PhoneNumberType).map((numberType) => (
                <ChecklistField.Option key={numberType} name={numberType}>
                  {numberType}
                </ChecklistField.Option>
              ))}
            </ChecklistField>
          </FormRow>
        </form>
      </TableFilters.Section>
    </TableFilters>
  );
};

const headingStyle = css`
  > label {
    font-weight: ${theme.font.weight.semibold};
    font-size: ${theme.fontSize(18)};
  }
`;
