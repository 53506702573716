import { forwardRef } from 'react';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

const styles = {
  actionItem: css`
    background: ${theme.colors.white};
    padding: ${theme.spacing(2)};
    display: flex;
    column-gap: ${theme.spacing(1)};
    align-items: center;
    color: ${theme.colors.neutral90};
    font-weight: ${theme.font.weight.bold};
    border-radius: ${theme.borderRadius.medium};
    box-shadow: ${theme.shadows.light};
    user-select: none;
    cursor: pointer;
  `,
  actionItemSuffixIcon: css`
    margin-left: auto;
  `,
  actionItemSuffixText: css`
    margin-left: auto;
    font-size: ${theme.font.size.small};
    font-weight: ${theme.font.weight.regular};
    color: ${theme.colors.neutral50};
  `,
};
export type ActionItemProps = React.PropsWithChildren<{
  className?: string;
  prefix?: React.ReactNode;
  suffixIcon?: React.ReactNode;
  suffixText?: string;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  trackingId?: string;
}>;

export const ActionItem = forwardRef<HTMLDivElement, ActionItemProps>(
  ({ children, className, prefix, suffixIcon, suffixText, onClick, trackingId }, ref) => {
    return (
      <div ref={ref} data-trackingid={trackingId} className={className} css={styles.actionItem} onClick={onClick}>
        {prefix && <div>{prefix}</div>}
        {children}
        {suffixText && <div css={styles.actionItemSuffixText}>{suffixText}</div>}
        {suffixIcon && <div css={styles.actionItemSuffixIcon}>{suffixIcon}</div>}
      </div>
    );
  }
);

ActionItem.displayName = 'ActionItem';
