import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Text, Modal, ModalControlModalProps, Button } from '@frontend/design-system';
import { ScheduleRequestsTrackingIds } from '../../trackingIds';

type ScheduleRequestNoWritebacksMessageSentModalProps = {
  modalProps: ModalControlModalProps;
  fullName: string;
  onClose: () => void;
};

export const ScheduleRequestNoWritebacksMessageSentModal = ({
  modalProps,
  fullName,
  onClose,
}: ScheduleRequestNoWritebacksMessageSentModalProps) => {
  const { t } = useTranslation('scheduleCalendarRequest');

  return (
    <Modal {...modalProps}>
      <Modal.Header onClose={onClose}>{t('Appointment Approved')}</Modal.Header>
      <Modal.Body>
        <Text css={{ marginBottom: theme.spacing(2) }}>
          {t(
            'Your text message has been sent to {{fullName}}. Remember to add the appointment details to your practice management software.',
            { fullName: fullName || 'the customer' }
          )}
        </Text>
        <Button
          onClick={onClose}
          trackingId={ScheduleRequestsTrackingIds.noWritebacksApproveConfirmationModalConfirmBtn}
        >
          {t('Got it')}
        </Button>
      </Modal.Body>
    </Modal>
  );
};
