import { css } from '@emotion/react';
import { SettingsPanel } from '@frontend/content-composer-settings';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { TextareaField } from '@frontend/design-system';
import { SIDEBAR_BREAKPOINTS } from '../../constants';
import { useSettingsForm } from '../../hooks';
import { CustomCodeSectionProps } from '../../types';

export const CustomCodeSettings = () => {
  const { t } = useTranslation('content-composer');

  const { getFieldProps } = useSettingsForm<CustomCodeSectionProps>({
    code: { type: 'text' },
  });

  return (
    <SettingsPanel
      title={t('Custom Code')}
      css={css`
        height: 100%;
        @media (min-width: ${SIDEBAR_BREAKPOINTS.mobile + 1}px) {
          margin-bottom: 0;
        }
        // TODO: need to add a class or something to target in the DS's TextareaField wrapper component
        & > div {
          height: 100%;
        }
      `}
    >
      <TextareaField
        {...getFieldProps('code')}
        css={css`
          height: 100%;
          padding: ${theme.spacing(1)};
        `}
      />
    </SettingsPanel>
  );
};
