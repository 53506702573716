import { useEffect } from 'react';
import { BulkEmailPrefixes } from '@frontend/tracking-prefixes';
import { MultiselectField, useFormField } from '@frontend/design-system';

type Props = {
  name: string;
  options: string[];
  value: string[] | undefined;
  label: string;
  onChange: (value: string[] | undefined) => void;
};

export const MultiselectFilter = ({ label, options, value, onChange, name }: Props) => {
  const trackingId = `${label.toLowerCase().split(' ').join('-')}-filter-input`;
  const selectionField = useFormField({ type: 'multiselect', value: value });

  useEffect(() => {
    if (selectionField.value !== value) {
      const filterValue = selectionField.value.length ? selectionField.value : undefined;
      onChange(filterValue);
    }
  }, [selectionField.value]);

  return (
    <MultiselectField
      {...selectionField}
      name={name}
      label={label}
      data-trackingid={`${BulkEmailPrefixes.Audience}-${trackingId}`}
    >
      {options?.map((option, index) => {
        return (
          <MultiselectField.Option key={index} value={option}>
            {option}
          </MultiselectField.Option>
        );
      })}
    </MultiselectField>
  );
};
