import { FC } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Modal, Text, ModalControlModalProps } from '@frontend/design-system';

interface Props {
  modalProps: ModalControlModalProps;
}

export const PortStatusInfoModal: FC<React.PropsWithChildren<Props>> = ({ modalProps }) => {
  const { t } = useTranslation('porting', { keyPrefix: 'phoneNumbers' });
  return (
    <Modal maxWidth={640} {...modalProps}>
      <Modal.Header onClose={modalProps.onClose}>{t('Port Status')}</Modal.Header>
      <Modal.Body>
        <Text css={textStyle}>{t('See below for different port statuses and their meaning.')}</Text>
        <Text weight='bold'>{t('Processing')}</Text>
        <Text css={textStyle}>
          {t(
            'Your order is queued and preparing to be sent to your current provider. Orders usually only stay in this status for 3-14 business days.'
          )}
        </Text>
        <Text weight='bold'>{t('Accepted')}</Text>
        <Text css={textStyle}>
          {t(
            'Your number has a confirmed port date from your losing service provider. This date may be different from your accepted port date.'
          )}
        </Text>
        <Text weight='bold'>{t('Completed')}</Text>
        <Text css={textStyle}>{t('The port has completed and the number is now owned by weave.')}</Text>
        <Text weight='bold'>{t('Canceled')}</Text>
        <Text css={textStyle}>{t('The port has been canceled and is not currently being processed.')}</Text>
      </Modal.Body>
      <Modal.Footer primary={{ label: t('Close'), onClick: modalProps.onClose }} />
    </Modal>
  );
};

const textStyle = css({
  marginBottom: theme.spacing(2),
});
