import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { BulkEmailPrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import { NakedButton, Text } from '@frontend/design-system';

export const AddSegmentButton = ({ handleAddSegment, ...rest }: { handleAddSegment: () => void }) => {
  const { t } = useTranslation('bulk-messaging');

  return (
    <NakedButton
      css={{ display: 'flex', marginTop: theme.spacing(1) }}
      onClick={handleAddSegment}
      trackingId={`${BulkEmailPrefixes.Audience}-create-segment-btn`}
      {...rest}
    >
      <Icon name='plus' color='primary' css={{ marginRight: theme.spacing(0.5) }} />
      <Text color='primary'>{t('Create Segment')}</Text>
    </NakedButton>
  );
};
