import { FC, useCallback } from 'react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { PrimaryButton, NakedButton, SecondaryButton } from '@frontend/design-system';
import { pendoTags } from '../../constants';
import { useSteps, useSendDocument } from '../../hooks';
import { useSendFormsStore, useSendFormsContext } from '../../providers';
import { SendFormStep } from '../../types';
import { containerStyle, backButtonStyle, buttonGroupStyle } from './footer.styles';

const SendFormsFooter: FC = () => {
  const { t } = useTranslation('forms');
  const { sendFormsModalControls, origin } = useSendFormsContext();
  const {
    currentStep,
    isSending,
    isGeneratingSolicitedLink,
    solicitedLink,
    email,
    emailBody,
    emailSubject,
    messageMode,
    clearStore,
    isUploadInProgress,
    formOrPacketToSend,
    areFormsEnabledLocationIdsSet,
  } = useSendFormsStore([
    'currentStep',
    'isSending',
    'isGeneratingSolicitedLink',
    'solicitedLink',
    'email',
    'emailBody',
    'emailSubject',
    'messageMode',
    'clearStore',
    'isUploadInProgress',
    'formOrPacketToSend',
    'areFormsEnabledLocationIdsSet',
  ]);
  const { sendDocument } = useSendDocument();
  const { goToNextStep, goBackAStep, isLastStep, isFirstStep } = useSteps();
  const isSelectAppointmentStep = currentStep === SendFormStep.SELECT_APPOINTMENT;
  const _isUploadInProgress = false;

  function goToNextStepHandler() {
    if (isSending) {
      return;
    }

    switch (currentStep) {
      case SendFormStep.SELECTED_MESSAGE_MODE:
        sendDocument();
        return;

      default:
        goToNextStep();
        break;
    }
  }

  function goBackAStepHandler() {
    if (isSending) {
      return;
    }

    switch (currentStep) {
      case SendFormStep.SELECT_APPOINTMENT:
        goBackAStep();
        break;

      case SendFormStep.SELECT_DELIVERY_METHOD:
        if (solicitedLink) {
          // Prevent user from going back to the appointment step if solicited link is generated
          return;
        }

        goBackAStep();
        break;

      default:
        goBackAStep();
        break;
    }
  }

  function cancelClickHandler() {
    if (isSending) {
      return;
    }

    sendFormsModalControls.closeModal();
    clearStore(origin);
  }

  function shouldDisableBackButton() {
    if (currentStep === SendFormStep.SELECT_DELIVERY_METHOD) {
      // Prevent user from going back to the appointment step if solicited link is generated
      return !!solicitedLink;
    }

    return isGeneratingSolicitedLink || isSending || _isUploadInProgress;
  }

  function shouldDisableCancelButton() {
    if (isUploadInProgress) {
      return true;
    }

    return isGeneratingSolicitedLink || isSending;
  }

  function shouldDisableNextButton() {
    if (isUploadInProgress || !areFormsEnabledLocationIdsSet) {
      return true;
    }

    switch (currentStep) {
      case SendFormStep.SELECTED_MESSAGE_MODE:
        return !(email && emailBody && emailSubject);

      case SendFormStep.SELECT_DOCUMENT:
        return !formOrPacketToSend;

      default:
        return false;
    }
  }

  const shouldShowBackButton = useCallback(() => {
    if (isFirstStep()) {
      return false;
    }

    return true;
  }, []);

  const shouldShowNextButton = useCallback((step: SendFormStep, msgMode: string) => {
    if (step === SendFormStep.SELECT_DELIVERY_METHOD) {
      return false;
    }

    if (step === SendFormStep.SELECTED_MESSAGE_MODE) {
      if (msgMode === 'kiosk') {
        return false;
      }
    }

    return true;
  }, []);

  const getPendoTagForNextButton = useCallback(
    (step: SendFormStep) => {
      if (isLastStep()) {
        return pendoTags.send;
      }

      switch (step) {
        case SendFormStep.SELECT_LOCATION:
          return pendoTags.steps.location.next;

        case SendFormStep.SELECT_APPOINTMENT:
          return pendoTags.steps.appointments.next;

        case SendFormStep.SELECT_DOCUMENT:
          return pendoTags.steps.documentSelection.next;

        default:
          return '';
      }
    },
    [isLastStep]
  );

  const getPendoTagForBackButton = useCallback((step: SendFormStep) => {
    switch (step) {
      case SendFormStep.SELECT_APPOINTMENT:
        return pendoTags.steps.appointments.back;

      case SendFormStep.SELECT_DELIVERY_METHOD:
        return pendoTags.steps.deliveryMethod.back;

      case SendFormStep.SELECT_DOCUMENT:
        return pendoTags.steps.documentSelection.back;

      default:
        return '';
    }
  }, []);

  return (
    <footer css={containerStyle}>
      {shouldShowBackButton() && (
        <NakedButton
          css={backButtonStyle}
          onClick={goBackAStepHandler}
          disabled={shouldDisableBackButton()}
          trackingId={getPendoTagForBackButton(currentStep)}
        >
          <Icon name='back-small' color={shouldDisableBackButton() ? 'disabled' : 'primary'} />
          {t('Back')}
        </NakedButton>
      )}

      <div css={buttonGroupStyle}>
        {!isSelectAppointmentStep && (
          <SecondaryButton
            size='tiny'
            onClick={cancelClickHandler}
            disabled={shouldDisableCancelButton()}
            trackingId={isLastStep() && messageMode === 'kiosk' ? pendoTags.close : pendoTags.cancel}
          >
            {isLastStep() && messageMode === 'kiosk' ? t('Close') : t('Cancel')}
          </SecondaryButton>
        )}

        {shouldShowNextButton(currentStep, messageMode) && (
          <PrimaryButton
            size='tiny'
            onClick={goToNextStepHandler}
            disabled={shouldDisableNextButton()}
            trackingId={getPendoTagForNextButton(currentStep)}
          >
            {isLastStep() ? t('Send') : t('Next')}
          </PrimaryButton>
        )}
      </div>
    </footer>
  );
};

export default SendFormsFooter;
