import { useState } from 'react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { BulkEmailPrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import { Button, NakedButton, Text } from '@frontend/design-system';
import { useBulkEmailEditorStore, useBulkMessagingNavigator } from '../../hooks';
import { CustomListTemplates } from './custom-list-templates';
import { DefaultListTemplates } from './default-list-templates';

export const BulkEmailTemplates = () => {
  const { t } = useTranslation('bulk-messaging');
  const navigateTo = useBulkMessagingNavigator(true);

  const [selectedView, setSelectedView] = useState<'custom' | 'default'>('custom');

  const navigateToNewTemplate = () => {
    useBulkEmailEditorStore.reset();
    navigateTo.emailComposer();
  };

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        rowGap: theme.spacing(3),
      }}
    >
      <div
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          alignItems: 'center',
          flexWrap: 'wrap',
          marginTop: theme.spacing(2),
          gap: theme.spacing(2),
        }}
      >
        <div css={{ display: 'flex', gap: theme.spacing(1), flexWrap: 'wrap' }}>
          <Button
            variant='secondary'
            css={[buttonStyle, selectedView === 'custom' && selectedStyle]}
            onClick={() => setSelectedView('custom')}
          >
            {t('Your Templates')}
          </Button>
          <Button
            variant='secondary'
            css={[buttonStyle, selectedView === 'default' && selectedStyle]}
            onClick={() => setSelectedView('default')}
          >
            {t('Default Templates')}
          </Button>
        </div>
        <NakedButton
          css={{ display: 'flex', columnGap: theme.spacing(1) }}
          onClick={navigateToNewTemplate}
          trackingId={`${BulkEmailPrefixes.Templates}-new-template-btn`}
        >
          <Icon name='plus' color='primary' />
          <Text color='primary'>{t('Create New Template')}</Text>
        </NakedButton>
      </div>
      {selectedView === 'custom' ? <CustomListTemplates /> : <DefaultListTemplates />}
    </div>
  );
};

const buttonStyle = {
  border: `2px solid ${theme.colors.neutral50}`,
  '&:hover': {
    border: `2px solid ${theme.colors.neutral50}`,
    backgroundColor: theme.colors.white,
  },
};

const selectedStyle = {
  border: `2px solid ${theme.colors.primary50}`,
  backgroundColor: theme.colors.primary5,
  '&:hover': {
    border: `2px solid ${theme.colors.primary50}`,
    backgroundColor: theme.colors.primary5,
  },
};
