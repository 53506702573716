import { useEffect } from 'react';
import { DigitalFormScopeHooks } from '@frontend/digital-forms-scope';
import { SEND_FORM_STEPS } from '../../constants';
import { SendFormsOrigin } from '../../types';
import { useSendFormsStore } from '../store';

interface UseStepsConfigProps {
  origin: SendFormsOrigin;
  locationIds: string[];
}

const useStepsConfig = ({ origin, locationIds }: UseStepsConfigProps) => {
  const { setCurrentStep, setSteps, setFormsEnabledLocationIds, setLocationId } = useSendFormsStore([
    'setCurrentStep',
    'setSteps',
    'setFormsEnabledLocationIds',
    'setLocationId',
  ]);
  const { isLoadingFormsEnabledOrgLocations, formsEnabledLocalLocationIds } =
    DigitalFormScopeHooks.useFormsEnabledOrgLocations({ localLocationIds: locationIds });

  useEffect(() => {
    if (isLoadingFormsEnabledOrgLocations) {
      return;
    }

    switch (origin) {
      case SendFormsOrigin.FORMS: {
        const steps =
          formsEnabledLocalLocationIds.length > 1
            ? SEND_FORM_STEPS.formsOriginWithMultipleLocations
            : SEND_FORM_STEPS.formsOrigin;
        setSteps(steps);
        setCurrentStep(steps[0]);
        break;
      }

      case SendFormsOrigin.CONTACTS: {
        const steps =
          formsEnabledLocalLocationIds.length > 1
            ? SEND_FORM_STEPS.contactsOriginWithMultipleLocations
            : SEND_FORM_STEPS.contactsOrigin;
        setSteps(steps);
        setCurrentStep(steps[0]);
        break;
      }

      case SendFormsOrigin.COMPLETE: {
        const steps =
          formsEnabledLocalLocationIds.length > 1
            ? SEND_FORM_STEPS.completeOriginWithMultipleLocations
            : SEND_FORM_STEPS.completeOrigin;
        setSteps(steps);
        setCurrentStep(steps[0]);
        break;
      }
    }

    setFormsEnabledLocationIds(formsEnabledLocalLocationIds);
    if (formsEnabledLocalLocationIds.length) {
      setLocationId(formsEnabledLocalLocationIds[0]);
    }
  }, [origin, isLoadingFormsEnabledOrgLocations, formsEnabledLocalLocationIds]);
};

export default useStepsConfig;
