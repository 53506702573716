import { useCallback } from 'react';
import { ScheduleQueries } from '@frontend/api-schedule';
import { InfinitePaginatedList } from '@frontend/components';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { useAlert, Text, Button } from '@frontend/design-system';
import { useAddToQuickFillListDialog } from '../../../../../../component-hooks';
import { QuickfillListItem } from './QuickfillListItem';
import { PlusIcon } from './UtilsComponent';

interface Props {
  locationId: string;
}

export const QuickfillList = ({ locationId }: Props) => {
  const alert = useAlert();
  const { t } = useTranslation('scheduleQuickfill');

  const { mutateAsync: deleteRecipientAsync } = ScheduleQueries.useMutateDeleteQuickfillRecipient(locationId);

  const infiniteQuery = ScheduleQueries.useListQuickfillRecipients(locationId);

  const { customContactDialogProps, AddToListQuickFillListDialog } = useAddToQuickFillListDialog({
    customContactFormFields: {
      firstName: { value: '', required: true },
      lastName: { value: '', required: true },
      phoneNumber: { value: '', required: true },
      notes: { value: '' },
    },
    defaultSelectedLocationId: locationId,
    onAddQuickFillSuccess: infiniteQuery.refetch,
  });

  const deleteRecipient = useCallback((personId: string) => {
    deleteRecipientAsync(personId)
      .then((res) => {
        if (res) {
          infiniteQuery.refetch();
          alert.success('Quick Fill Recipient Deleted');
        }
      })
      .catch((err) => {
        if (err) {
          alert.error('Error Deleting Quick Fill Recipient');
        }
      });
  }, []);

  return (
    <>
      <InfinitePaginatedList
        height={240}
        endOfListText=''
        emptyStateConfig={{
          header: t('No Quick Fill Recipients found'),
          disableGraphic: true,
          textProps: { color: 'subdued' },
        }}
        infiniteQueryProps={infiniteQuery}
        renderListItem={({ listItem }) => {
          return (
            <QuickfillListItem
              key={listItem.personId}
              quickfillRecipient={listItem}
              deleteRecipient={deleteRecipient}
            />
          );
        }}
      />
      <div css={{ padding: theme.spacing(1, 2, 1, 3), borderTop: `1px solid ${theme.colors.neutral20}` }}>
        <Button variant='tertiary' {...customContactDialogProps.getTriggerProps()}>
          <PlusIcon />
          <Text as='span' weight='bold' size='medium'>
            {t('Add to list')}
          </Text>
        </Button>
        {AddToListQuickFillListDialog}
      </div>
    </>
  );
};
