import { css } from '@emotion/react';
import { Trans, useTranslation } from '@frontend/i18n';
import { breakpoints } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';
import { ContentLoader, Heading, Text } from '@frontend/design-system';
import { formatters } from '../../../../utils/value-formatters';
import { useFetchMissedCallTextConversionTotals, useROIShallowStore } from '../../hooks';
import { getDateRangeText } from '../../utils/date-range-text';
import { StatItem } from '../stat-item';

export const MultiConversionComparisonOverview = ({
  data,
  isLoading,
}: ReturnType<typeof useFetchMissedCallTextConversionTotals>) => {
  const { t } = useTranslation('analytics');
  const { filters } = useROIShallowStore('filters');

  return (
    <div css={styles.wrapper}>
      <Heading level={3}>{t('Overview')}</Heading>
      <div className='overview-stat-items'>
        <StatItem label={t('Messages Sent')} value={formatters.value.format(data?.totalMessagesCount)} />
        <StatItem label={t('Appointments Scheduled')} value={formatters.value.format(data?.totalAppointmentsCount)} />
        <Text className='overview-message'>
          <Trans t={t}>
            <Text as='span' color='subdued' size='medium'>
              {getDateRangeText(filters?.startDate, filters?.endDate, true)},
            </Text>{' '}
            <Text as='strong' size='medium' weight='bold'>
              {formatters.value.format(data?.totalMessagesCount)}
            </Text>{' '}
            <Text as='span' color='subdued' size='medium'>
              messages were sent out of which
            </Text>{' '}
            <Text as='strong' size='medium' weight='bold'>
              {formatters.value.format(data?.totalAppointmentsCount)}
            </Text>{' '}
            {(filters?.locationIds?.length || 0) > 1 ? (
              <>
                <Text as='span' color='subdued' size='medium'>
                  appointments were scheduled across
                </Text>{' '}
                <Text as='strong' size='medium' weight='bold'>
                  {formatters.value.format(filters?.locationIds?.length)}
                </Text>{' '}
                <Text as='span' color='subdued' size='medium'>
                  locations.
                </Text>{' '}
              </>
            ) : (
              <Text as='span' color='subdued' size='medium'>
                appointments were scheduled.
              </Text>
            )}
          </Trans>
        </Text>
      </div>
      <ContentLoader show={isLoading} size='small' />
    </div>
  );
};

const styles = {
  wrapper: css`
    border-radius: ${theme.borderRadius.medium};
    border: 1px solid ${theme.colors.neutral20};
    overflow: hidden;
    padding: ${theme.spacing(3)};
    position: relative;

    .overview-stat-items {
      align-items: center;
      column-gap: ${theme.spacing(6)};
      display: flex;
      flex-wrap: wrap;
      margin-top: ${theme.spacing(2)};
      row-gap: ${theme.spacing(3)};

      .overview-message {
        @media screen and (min-width: ${breakpoints.large.min}px) {
          flex: 1;
        }
      }
    }
  `,
};
