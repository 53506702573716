import React from 'react';
import { Button } from '@frontend/design-system';
import { BannerAction as BannerActionType } from '../types';

export const BannerAction: React.FC<{ action?: BannerActionType }> = ({ action }) => {
  if (!action) return null;

  if ('element' in action) {
    return action.element;
  }

  return (
    <Button variant='tertiary' size='large' onClick={action.onClick}>
      {action.label}
    </Button>
  );
};
