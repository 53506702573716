import { css } from '@emotion/react';
import { OnboardingModulesTypes } from '@frontend/api-onboarding-modules';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { ContentLoader, IconButton, NakedUl, Text } from '@frontend/design-system';
import { NWXDashboardTaskInfo, trackingIds } from '../constants';

const buttonTrackingIdTaskIdMap: Partial<Record<OnboardingModulesTypes.TaskIds, string>> = {
  [OnboardingModulesTypes.TaskIds.EINSetup]: trackingIds.nwxDashboardTasksSection.verifyBusinessInformationButton,
  [OnboardingModulesTypes.TaskIds.UserInviteSetup]: trackingIds.nwxDashboardTasksSection.inviteUserToWeaveButton,
  [OnboardingModulesTypes.TaskIds.ScheduleCallWithOnboarder]:
    trackingIds.nwxDashboardTasksSection.scheduleTimeNowButton,
  [OnboardingModulesTypes.TaskIds.PaymentsStripeSetup]: trackingIds.nwxDashboardTasksSection.setupPaymentsButton,
};

interface Props {
  isLoading?: boolean;
  taskList?: NWXDashboardTaskInfo[];
  onTaskClick: (task: NWXDashboardTaskInfo) => void;
}

export const NWXDashboardTaskList = ({ taskList = [], isLoading, onTaskClick }: Props) => {
  return (
    <NakedUl css={ulStyle}>
      <ContentLoader
        size='medium'
        show={isLoading}
        backgroundOpacity={0.5}
        css={{ borderRadius: theme.borderRadius.medium }}
      />
      {taskList.map((task) => (
        <li key={task.id} css={liStyle}>
          <Text color='light' size='medium'>
            {task.description}
          </Text>
          <IconButton
            label={task.title}
            showLabelAlways
            css={iconButtonStyle}
            trackingId={buttonTrackingIdTaskIdMap[task.id]}
            onClick={() => onTaskClick(task)}
          >
            <Icon name={task.icon} color='primary' />
          </IconButton>
        </li>
      ))}
    </NakedUl>
  );
};

const ulStyle = css({
  position: 'relative',
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(330px, 1fr))',
  gap: theme.spacing(1),
  margin: theme.spacing(0, 1),
});

const liStyle = css({
  background: theme.colors.white,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  justifyContent: 'space-between',
  padding: theme.spacing(2),
  minWidth: 330,
  flex: '1 1 330px',
  borderRadius: theme.borderRadius.medium,
});

const iconButtonStyle = css({
  height: 32,
  maxWidth: 'max-content',
  color: theme.colors.primary50,
  fontWeight: theme.font.weight.bold,
  fontSize: theme.font.size.medium,
});
