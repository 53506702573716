import { useEditor, useNode } from '@craftjs/core';
import { css } from '@emotion/react';
import DOMPurify from 'dompurify';
import { useTranslation } from '@frontend/i18n';
import { ComposerSection, CustomCodeSectionProps } from '../../types';
import { CustomCodeOutput } from '../outputs';
import { CustomCodeSettings } from '../settings';
import { CustomCodeTool } from '../tools';
import { BaseComposerSection } from './floating-toolbar';

export const CustomCodeSection: ComposerSection<CustomCodeSectionProps> = ({ code, ...rest }) => {
  const { t } = useTranslation('content-composer');
  const { id } = useNode((state) => ({
    hasSelectedNode: state.events.selected,
    hasDraggedNode: state.events.dragged,
    id: state.id,
  }));
  const { actions, selected } = useEditor((state, query) => {
    const [currentNodeId] = state.events.selected;
    let selected;

    if (currentNodeId) {
      selected = {
        id: currentNodeId,
        settings: state.nodes[currentNodeId]?.related?.settings,
        isDeletable: query.node(currentNodeId).isDeletable(),
      };
    }

    return {
      selected,
    };
  });

  const sanitizedCode = DOMPurify.sanitize(code ?? '');

  return (
    <BaseComposerSection
      sectionType='CustomCode'
      title={t('Custom Code')}
      onDelete={selected?.isDeletable ? () => actions.delete(selected.id) : undefined}
      {...rest}
      id={id}
    >
      <div
        css={css`
          width: 100%;
        `}
        dangerouslySetInnerHTML={{ __html: sanitizedCode }}
      />
    </BaseComposerSection>
  );
};

CustomCodeSection.craft = {
  props: {
    code: '',
  },
  related: {
    output: CustomCodeOutput,
    settings: CustomCodeSettings,
    tool: CustomCodeTool,
  },
};
