import { Practitioner } from '@weave/schema-gen-ts/dist/schemas/schedule/v3/practitioner.pb';
import { contextFactory } from '@frontend/design-system';

export type ManagePractitionerProfilePhotoContextType = {
  profilePhotoSrc: string;
  setProfilePhotoSrc: (src: string) => void;
  practitionerDetails: Practitioner;
  name: string;
  updatePractitionerDetails: (profilePhotoUrl?: string) => Promise<void>;
};

export const [ManagePractitionerProfilePhotoContext, useManagePractitionerProfilePhoto] =
  contextFactory<ManagePractitionerProfilePhotoContextType>(
    'useManagePractitionerProfilePhoto must be used within a ManagePractitionerProfilePhotoContext'
  );
