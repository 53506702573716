import { useState, useEffect, useCallback } from 'react';
import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { CustomizationFlagQueries } from '@frontend/api-customization-flags';
import { useAppScopeStore } from '@frontend/scope';

export interface FormsEnabledLocation {
  id: string;
  name: string;
}

type FormsEnabledLocationMap = Record<string, FormsEnabledLocation>;

interface SearchPayload {
  searchTerm: string;
  searchList?: string[];
}

export type SearchFormsEnabledLocationIdsFn = (payload: SearchPayload) => string[];

interface UseFormsEnabledOrgLocationsProps {
  localLocationIds?: string[]; // Make sure to pass in the memoized value if you're using this in a component
}

interface UseFormsEnabledOrgLocationsResults {
  isLoadingFormsEnabledOrgLocations: boolean;
  /**
   * Location IDs from the passed in locations that are Forms enabled
   */
  formsEnabledLocalLocationIds: string[];
  /**
   * Location IDs from the Global Location Selector that are Forms enabled
   */
  formsEnabledGLSLocationIds: string[];
  /**
   * Location IDs from the organization that are Forms enabled
   */
  formsEnabledOrgLocationIds: string[];
  /**
   * Location data for locations that are Forms enabled in the organization
   */
  formsEnabledOrgLocations: FormsEnabledLocationMap;
  /**
   * Search for locations in the organization that have Forms enabled
   */
  searchFormsEnabledOrgLocationIds: SearchFormsEnabledLocationIdsFn;
  /**
   * Search for locations from the Global Location Selector that have Forms enabled
   */
  searchFormsEnabledGLSLocationIds: SearchFormsEnabledLocationIdsFn;
  /**
   * Search for locations from the passed in location IDs that have Forms enabled
   */
  searchFormsEnabledLocalLocationIds: SearchFormsEnabledLocationIdsFn;
}

const EMPTY_LOCAL_LOCATION_IDS: string[] = [];

export const useFormsEnabledOrgLocations = (
  props: UseFormsEnabledOrgLocationsProps = {}
): UseFormsEnabledOrgLocationsResults => {
  const { localLocationIds = EMPTY_LOCAL_LOCATION_IDS } = props;
  const [isLoadingFormsEnabledOrgLocations, setIsLoadingFormsEnabledOrgLocations] = useState(true);
  const [formsEnabledOrgLocationIds, setFormsEnabledOrgLocationIds] = useState<string[]>([]);
  const [formsEnabledLocalLocationIds, setFormsEnabledLocalLocationIds] = useState<string[]>([]);
  const [formsEnabledGLSLocationIds, setFormsEnabledGLSLocationIds] = useState<string[]>([]);
  const [formsEnabledOrgLocations, setFormsEnabledOrgLocations] = useState<FormsEnabledLocationMap>({});
  const { accessibleLocationIds, accessibleLocationData, selectedLocationIds, orgIdMap, selectedOrgId } =
    useAppScopeStore();
  const { locationIdWiseCustomizationFlagDetails, isLoading } =
    CustomizationFlagQueries.useAggregateCustomizationFlagDetails({
      locationIds: accessibleLocationIds,
      enabled: true,
      customizationFlag: Feature.FORMS,
    });

  /**
   * Search for locations in the organization that have Forms enabled
   */
  const searchFormsEnabledOrgLocationIds = useCallback<SearchFormsEnabledLocationIdsFn>(
    ({ searchTerm, searchList }) => {
      const search = searchTerm.toLowerCase().trim();

      return (!!searchList ? searchList : formsEnabledOrgLocationIds).filter((locationId) => {
        const location = formsEnabledOrgLocations[locationId];
        return location.name.toLowerCase().trim().includes(search);
      });
    },
    [formsEnabledOrgLocationIds, formsEnabledOrgLocations]
  );

  /**
   * Search for locations from the Global Location Selector that have Forms enabled
   */
  const searchFormsEnabledGLSLocationIds = useCallback<SearchFormsEnabledLocationIdsFn>(
    ({ searchTerm, searchList }) => {
      const search = searchTerm.toLowerCase().trim();

      return (!!searchList ? searchList : formsEnabledGLSLocationIds).filter((locationId) => {
        const location = formsEnabledOrgLocations[locationId];
        return location.name.toLowerCase().trim().includes(search);
      });
    },
    [formsEnabledGLSLocationIds, formsEnabledOrgLocations]
  );

  /**
   * Search for locations from the passed in location IDs that have Forms enabled
   */
  const searchFormsEnabledLocalLocationIds = useCallback<SearchFormsEnabledLocationIdsFn>(
    ({ searchTerm, searchList }) => {
      const search = searchTerm.toLowerCase().trim();

      return (!!searchList ? searchList : formsEnabledLocalLocationIds).filter((locationId) => {
        const location = formsEnabledOrgLocations[locationId];
        return location.name.toLowerCase().trim().includes(search);
      });
    },
    [formsEnabledLocalLocationIds, formsEnabledOrgLocations]
  );

  /**
   * Set the Forms enabled locations
   */
  useEffect(() => {
    if (isLoading) {
      setIsLoadingFormsEnabledOrgLocations(true);
      return;
    }

    const enabledOrgLocationIds: string[] = [];
    const enabledGLSLocationIds: string[] = [];
    const enabledLocalLocationIds: string[] = [];
    const enabledLocations: FormsEnabledLocationMap = {};
    const accessibleLocationsInCurrentOrg =
      orgIdMap[selectedOrgId]?.locations.map((location) => location.locationID) || [];

    // Loop through all accessible locations (across all the accessible orgs for the user)
    for (const locationId of accessibleLocationIds) {
      // Check if the location has Forms enabled
      if (locationIdWiseCustomizationFlagDetails[locationId]) {
        const locationData = accessibleLocationData[locationId];
        enabledLocations[locationId] = {
          id: locationId,
          name: locationData?.name || '',
        };

        // Check if the location is part of the current org
        if (accessibleLocationsInCurrentOrg.includes(locationId)) {
          enabledOrgLocationIds.push(locationId);
        }

        // Check if the location is part of the selected locations in GLS.
        if (selectedLocationIds.includes(locationId)) {
          enabledGLSLocationIds.push(locationId);

          // Check if the location is part of the passed in local locations
          if (localLocationIds.includes(locationId)) {
            enabledLocalLocationIds.push(locationId);
          }
        }
      }
    }

    setFormsEnabledOrgLocationIds(enabledOrgLocationIds);
    setFormsEnabledGLSLocationIds(enabledGLSLocationIds);
    setFormsEnabledLocalLocationIds(enabledLocalLocationIds);
    setFormsEnabledOrgLocations(enabledLocations);
    setIsLoadingFormsEnabledOrgLocations(false);
  }, [isLoading, locationIdWiseCustomizationFlagDetails, localLocationIds]);

  return {
    isLoadingFormsEnabledOrgLocations,
    formsEnabledLocalLocationIds,
    formsEnabledGLSLocationIds,
    formsEnabledOrgLocationIds,
    formsEnabledOrgLocations,
    searchFormsEnabledOrgLocationIds,
    searchFormsEnabledGLSLocationIds,
    searchFormsEnabledLocalLocationIds,
  };
};
