import { Trans, useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Modal, Text, ModalControlModalProps, useFormField, DatePickerField } from '@frontend/design-system';
import { getPortDateFieldProps } from '../../helper';

interface Props extends ModalControlModalProps {
  onConfirm: (date: string) => void;
}

export const RequestDateChangeModal = ({ onConfirm, ...modalProps }: Props) => {
  const { t } = useTranslation('porting', { keyPrefix: 'phoneNumbers' });

  const dateFieldOptions = getPortDateFieldProps();
  const dateFormField = useFormField(
    {
      type: 'datePicker',
      value: '',
      ...dateFieldOptions,
    },
    [modalProps.show]
  );

  return (
    <Modal maxWidth={600} {...modalProps}>
      <Modal.Header onClose={modalProps.onClose}>{t('Request for Port Date Change')}</Modal.Header>
      <Modal.Body css={{ margin: theme.spacing(2, 0, 1, 0) }}>
        <Text css={{ marginBottom: theme.spacing(3) }}>
          <Trans t={t}>
            We strongly advise against changing porting dates once a port request is accepted Submitting your request
            does <strong>not guarantee</strong> that your port date will change.
          </Trans>
        </Text>

        <DatePickerField name='port-date' label={t('Date')} {...dateFormField} css={{ maxWidth: 'none' }} />
      </Modal.Body>
      <Modal.Footer
        primary={{
          label: t('Submit Change Request'),
          disabled: !dateFormField.value || !!dateFormField.error,
          onClick: () => onConfirm(dateFormField.value),
        }}
      />
    </Modal>
  );
};
