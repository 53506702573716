import { theme } from '@frontend/theme';
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import { ChartProps, CustomTooltip } from './single-bar-chart';

export const SingleDoughnutChart = (props: ChartProps) => {
  const { onClick, ...otherProps } = props;
  return (
    <ResponsiveContainer height='100%'>
      <PieChart onClick={props.onClick}>
        <Pie
          data={[{ value: props.sentCount }, { value: props.scheduledCount }, { value: props.remainingCount }]}
          dataKey='value'
          innerRadius='80%'
          outerRadius='110%'
          fill={theme.colors.neutral20}
          startAngle={90}
          endAngle={-270}
          blendStroke
        >
          <Cell fill={theme.colors.primary60} />
          <Cell fill={theme.colors.secondary.seaweed30} />
          <Cell fill={theme.colors.neutral10} />
        </Pie>
        <Tooltip content={<CustomTooltip {...otherProps} />} />
      </PieChart>
    </ResponsiveContainer>
  );
};
