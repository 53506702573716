import { ReactNode } from 'react';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';

interface Props {
  children: ReactNode;
  isLast?: boolean;
  title: string;
}

export const Setting = ({ children, isLast = false, title }: Props) => {
  return (
    <div
      css={css`
        ${!isLast ? `border-bottom: 1px solid ${theme.colors.neutral20};` : ''};
        padding-bottom: ${theme.spacing(2)};
      `}
    >
      <Text
        weight='bold'
        css={css`
          padding: ${theme.spacing(1.5, 0)};
        `}
      >
        {title}
      </Text>
      {children}
    </div>
  );
};
