import { Fragment, ReactNode, createContext, useContext, useMemo, useState } from 'react';
import { usePollForUpdates } from '@frontend/automatic-updates';
import appConfig from '@frontend/env';

type ContextValue = {
  currentVersion: string | undefined;
  updateAvailable: boolean;
  shouldPreventUpdate: boolean;
  setShouldPreventUpdate: (value: boolean) => void;
  refresh: () => void;
};
const AppContext = createContext<ContextValue>({
  currentVersion: undefined,
  updateAvailable: false,
  shouldPreventUpdate: false,
  setShouldPreventUpdate: () => {},
  refresh: () => {},
});
export const AppProvider = ({ children }: { children: ReactNode }) => {
  const [shouldPreventUpdate, setShouldPreventUpdate] = useState(false);

  const { updateAvailable, currentVersion } = usePollForUpdates(
    60000,
    appConfig.PUBLISH_URL ? 'https://' + appConfig.PUBLISH_URL + '/_m/version' : undefined
  );

  const [key, setKey] = useState(Math.random());
  const value = useMemo(
    () => ({
      currentVersion,
      updateAvailable,
      shouldPreventUpdate,
      setShouldPreventUpdate,
      refresh: () => setKey(Math.random()),
    }),
    [currentVersion, updateAvailable]
  );
  return (
    <AppContext.Provider value={value}>
      <Fragment key={key}>{children}</Fragment>
    </AppContext.Provider>
  );
};

export const useApp = () => {
  return useContext(AppContext);
};
