import { ReactNode } from 'react';
import { theme } from '@frontend/theme';

export const ImageGalleryContentWrapper = ({ children }: { children: ReactNode }) => (
  <div
    className='image-gallery-content'
    css={{
      background: theme.colors.white,
      borderRadius: theme.borderRadius.medium,
    }}
  >
    {children}
  </div>
);
