import { useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import { PhoneAnalyticsTypes } from '@frontend/api-analytics';
import { BarChartData, Chart } from '@frontend/charts';
import { i18next, useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { useLocations } from '../../../hooks';
import { formatDateByTimezone, getHourlyInsightsLabel, toHHmmss } from '../../../utils';
import { DemoChip } from '../../demo-chip';
import { InfoTipPopover } from '../../info-tip-popover';
import { useFetchAggregatedCallsAndDurationReport, usePhoneAnalyticsShallowStore } from '../hooks';
import { ChartProps } from './shared-types';

const colors = {
  patient: theme.colors.primary20,
  unknown: theme.colors.secondary.eggplant30,
};

const labels = {
  patient: i18next.t('Patient', { ns: 'analytics' }),
  unknown: i18next.t('Unknown', { ns: 'analytics' }),
};

export const PatientsVsUnknownCallDurationChart = ({
  defaultChartAppearance = {},
  exportPdfProps,
  onFetchStateChange,
}: ChartProps) => {
  const { t } = useTranslation('analytics');
  const { locations } = useLocations();
  const { filterHintText, filters, isDemoAccount } = usePhoneAnalyticsShallowStore(
    'filterHintText',
    'filters',
    'isDemoAccount'
  );
  const { data, isHourlyData, isLoading, isMultiLocation } =
    useFetchAggregatedCallsAndDurationReport<PhoneAnalyticsTypes.SourceTypeAggregationKey>('source_type');

  const chartData = useMemo(() => {
    let groups: BarChartData['groups'] = [];

    if (isMultiLocation) {
      // There will be multiple location objects with single/multiple dates
      groups = Object.entries(data?.aggregatedMultiLocations || {}).map(([key, { duration = {} }]) => {
        return {
          name: locations?.[key] || key,
          values: {
            patient: duration?.current_patient || 0,
            unknown: duration?.unknown || 0,
          },
        };
      });
    } else {
      // For hourly data: There will be only one object with single date and hourly data for the given location
      // For daily data: There will be only one object with multiple dates for the given location
      const locationData = Object.values(data?.locations || {})[0] || {};

      if (isHourlyData) {
        // Hourly data is restricted to a single date, hence only one object
        const { current_patient, unknown } = Object.values(locationData || {})[0] || {};
        const limitedPatients = current_patient?.duration?.hourly || [];
        const limitedUnknown = unknown?.duration?.hourly || [];

        groups = limitedPatients.map((patient, i) => ({
          name: getHourlyInsightsLabel(i),
          values: {
            patient,
            unknown: limitedUnknown[i],
          },
        }));
      } else {
        groups = Object.entries(locationData).map(([date, { current_patient, unknown }]) => {
          return {
            name: dayjs(date).isValid() ? formatDateByTimezone(date, filters.start_date, filters.time_zone) : date,
            values: {
              patient: current_patient?.duration?.daily || 0,
              unknown: unknown?.duration?.daily || 0,
            },
          };
        });
      }
    }

    return {
      groups,
    };
  }, [data, filters, isHourlyData, isMultiLocation, locations]);

  useEffect(() => {
    onFetchStateChange?.(isLoading);
  }, [isLoading]);

  return (
    <Chart colors={colors} isLoading={isLoading} labels={labels}>
      <Chart.Header
        {...exportPdfProps}
        infoTip={
          <InfoTipPopover>
            {t(
              'Refers to total time duration office spent while talking to patients vs unknown phone numbers. Value is in hh:mm:ss'
            )}
          </InfoTipPopover>
        }
        leftElement={isDemoAccount && <DemoChip />}
        subtitle={filterHintText}
        title={t('Patient vs Unknown')}
      />
      <Chart.Legends />
      <Chart.BarChart appearance={defaultChartAppearance} data={chartData} formatValue={(value) => toHHmmss(value)} />
    </Chart>
  );
};
