export const FacebookNoBorderSvg = ({ fillColor, innerColor }: { fillColor?: string; innerColor?: string }) => (
  <svg
    height={16}
    width={16}
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    version='1.1'
    baseProfile='full'
  >
    <circle cx='8.5' cy='8.001' r='8' fill={fillColor}></circle>
    <mask id='a' width='17' height='17' x='0' y='0' maskUnits='userSpaceOnUse' style={{ maskType: 'alpha' }}>
      <circle cx='8.5' cy='8.001' r='8' fill={innerColor}></circle>
    </mask>
    <g mask='url(#a)'>
      <path
        fill={innerColor}
        fillRule='evenodd'
        d='M16.499 8.048C16.499 3.603 12.917 0 8.498 0s-8 3.604-8 8.05c0 4.017 2.925 7.347 6.75 7.95v-5.624H5.217V8.048h2.031V6.275c0-2.017 1.194-3.132 3.022-3.132.875 0 1.79.157 1.79.157v1.981h-1.008c-.994 0-1.304.62-1.304 1.257v1.51h2.219l-.355 2.327H9.748V16c3.825-.604 6.75-3.934 6.75-7.952'
        clipRule='evenodd'
      ></path>
    </g>
  </svg>
);
