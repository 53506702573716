import { isEqual } from 'lodash-es';
import { sortItems } from './sort-items';

export const getUniqueItems = (originalItems: string[], newItems: string[]) => {
  const sortedOriginalItems = sortItems([...originalItems]).filter((item) => item);
  const sortedNewItems = sortItems([...newItems]).filter((item) => item);

  if (!sortedOriginalItems.length && !isEqual(sortedOriginalItems, sortedNewItems)) {
    return sortedNewItems;
  } else if (!isEqual(sortedOriginalItems, sortedNewItems)) {
    return sortedOriginalItems?.filter((item) => !sortedOriginalItems.includes(item));
  }
  return undefined;
};
