import { FrameSetting, PaddingSetting, SettingsPanel } from '@frontend/content-composer-settings';
import { useTranslation } from '@frontend/i18n';
import { useSettingsForm } from '../../hooks';
import { TextSectionProps } from '../../types';

export const TextSettings = () => {
  const { t } = useTranslation('content-composer');

  const { getFieldProps } = useSettingsForm<TextSectionProps>({
    frameAlignment: { type: 'radio' },
    frameColor: { type: 'text' },
    paddingTop: { type: 'text' },
    paddingBottom: { type: 'text' },
    paddingLeft: { type: 'text' },
    paddingRight: { type: 'text' },
  });

  return (
    <SettingsPanel title={t('Text Styling')}>
      <FrameSetting {...getFieldProps('frameColor')} />
      <PaddingSetting
        paddingTopFieldProps={getFieldProps('paddingTop')}
        paddingBottomFieldProps={getFieldProps('paddingBottom')}
        paddingLeftFieldProps={getFieldProps('paddingLeft')}
        paddingRightFieldProps={getFieldProps('paddingRight')}
        isLast
      />
    </SettingsPanel>
  );
};
