import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const requestDigitizationContainerStyle = css`
  display: flex;
  margin-bottom: ${theme.spacing(3)};
  justify-content: flex-end;
`;

export const addMoreFormsButtonStyle = css`
  align-self: flex-end;
  font-weight: ${theme.font.weight.semibold};
  border: none;
  margin-top: ${theme.spacing(2)};
  padding: ${theme.spacing(1)};
`;
