import React, { useMemo } from 'react';
import { Provider } from '@weave/schema-gen-ts/dist/schemas/schedule/settings/v2/settings.pb';
import type { ScheduleTypes } from '@frontend/api-schedule';
import { contextFactory } from '@frontend/design-system';
import {
  useGetPractitionersV3Data,
  useGetProvidersListForMultipleLocationIds,
  useGetSchedulerV3FeatureFlagDetails,
  useSchedulingLocationInfo,
} from '../hooks';
import type { CalendarEventContextType } from '../types';
import { calendarViewV3ApiQueryLimit, transformV3ResponsesForReviewRequest } from '../utils';

// context
export const [CalendarEventContext, useCalendarEvent] = contextFactory<CalendarEventContextType>();

// provider
interface CalendarEventProviderProps {
  children: React.ReactNode;
  customSelectedLocationIds?: string[];
  customProvidersList?: ScheduleTypes.SchemaProvider[];
}

export const CalendarEventProvider = React.memo(
  ({ children, customSelectedLocationIds, customProvidersList }: CalendarEventProviderProps) => {
    const { selectedLocationIds } = useSchedulingLocationInfo();
    const { isScheduleV3FlagEnabled } = useGetSchedulerV3FeatureFlagDetails();
    const locationIds = customSelectedLocationIds || selectedLocationIds || [];
    const { data: providers } = useGetProvidersListForMultipleLocationIds({ locationIds }, !isScheduleV3FlagEnabled);

    const { data: practitioners } = useGetPractitionersV3Data({
      selectedLocationId: locationIds[0],
      selectedLocationIds: locationIds,
      pageConfig: {
        page: 1,
        limit: calendarViewV3ApiQueryLimit,
      },
      showOnCalendar: true,
    });

    const providersListData = useMemo(
      () =>
        transformV3ResponsesForReviewRequest({
          practitionerDataV3: practitioners?.providers,
        }).transformedPractitioners as Provider[],
      [practitioners]
    );

    const providersData = isScheduleV3FlagEnabled ? providersListData : providers;

    const providersList = customProvidersList || providersData || [];

    const value = useMemo(
      () => ({
        selectedLocationIds: locationIds,
        providersList: providersList,
      }),
      [locationIds, providersList]
    );

    return <CalendarEventContext.Provider value={value}>{children}</CalendarEventContext.Provider>;
  }
);

CalendarEventProvider.displayName = 'CalendarEventProvider';
