import { useTranslation } from '@frontend/i18n';
import { ConfirmationModal, ModalControlModalProps } from '@frontend/design-system';
import { ScheduleRequestsTrackingIds } from '../../trackingIds';

type ScheduleRequestNoWritebacksErrorProps = {
  modalProps: ModalControlModalProps;
  onConfirm: (override?: boolean) => void;
};

export const ScheduleRequestNoWritebacksErrorModal = ({
  modalProps,
  onConfirm,
}: ScheduleRequestNoWritebacksErrorProps) => {
  const { t } = useTranslation('scheduleCalendarRequest');
  return (
    <ConfirmationModal
      {...modalProps}
      title={t('Attention')}
      message={t(
        'We failed to confirm this appointment. Would you like to approve this request anyway? If so, please add this to your schedule manually.'
      )}
      onConfirm={onConfirm}
      cancelTrackingId={ScheduleRequestsTrackingIds.noWritebacksRequestErrorConfirmationModalCancelBtn}
      confirmTrackingId={ScheduleRequestsTrackingIds.noWritebacksRequestErrorConfirmationModalConfirmBtn}
    />
  );
};
