import { useEffect, useMemo } from 'react';
import { css } from '@emotion/react';
import { InvoiceModel, InvoiceStatus } from '@frontend/api-invoices';
import { PersonTypes } from '@frontend/api-person';
import { AdaptoActions } from '@frontend/adapto-actions';
import { formatCentsToCurrency } from '@frontend/currency';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { CollectPaymentModalSteps } from '@frontend/payments-collection-flow';
import { useInvoiceShallowStore } from '@frontend/payments-invoice-controller';
import { usePersonActiveInvoiceList } from '@frontend/person-invoice-list';
import { PersonInvoiceModalSteps } from '@frontend/person-invoice-payment';
import { ContactActionPrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import {
  ActionButton,
  Button,
  formatDate,
  PopoverMenuItem,
  PopoverMenu,
  PopoverMenuDivider,
  PopoverMenuItemDropdown,
  useNestedPopoverMenu,
} from '@frontend/design-system';
import { usePaymentAction } from './use-payment-action';

export type PaymentButtonProps = {
  label?: string;
  context: {
    person?: PersonTypes.Person;
    personId: string;
    locationId: string;
  };
  trackingIdSuffix?: string;
};

export const PaymentButton = ({ label, context, trackingIdSuffix }: PaymentButtonProps) => {
  const { triggerProps, Modal } = usePaymentAction({ context });

  return (
    <div>
      <ActionButton
        {...triggerProps}
        trackingId={`${ContactActionPrefixes.Payments}:${trackingIdSuffix}`}
        label={label}
      >
        <Icon name='dollar-sign' size={16} />
      </ActionButton>
      {Modal}
    </div>
  );
};

export type PaymentActionProps = {
  label: string;
  context: {
    person?: PersonTypes.Person;
    personId: string;
    locationId: string;
  };
  trackingIdSuffix?: string;
};

const getFormattedDate = (date: string) => {
  const currentYear = new Date().getFullYear();
  const d = new Date(date);

  if (d.getFullYear() === currentYear) {
    return formatDate(date, 'MMMM D', true);
  }
  return formatDate(date, 'MMM D, YYYY', true);
};

export const PaymentAction = ({ label, context, trackingIdSuffix }: PaymentActionProps) => {
  const { triggerProps: paymentTriggerProps, Modal: PaymentModal } = usePaymentAction({ context });

  return (
    <>
      <AdaptoActions.Action
        {...paymentTriggerProps}
        trackingId={`${ContactActionPrefixes.Payments}:${trackingIdSuffix}`}
        icon='dollar-sign'
        label={label}
      />
      {PaymentModal}
    </>
  );
};

const PAYMENT_SUCCESS_TIMEOUT = 2000;

export type ContactPaymentActionProps = {
  context: {
    person?: PersonTypes.Person;
    personId: string;
    locationId: string;
  };
  trackingIdSuffix?: string;
};
export const ContactPaymentAction = ({ context, trackingIdSuffix }: ContactPaymentActionProps) => {
  const { t } = useTranslation('contacts');

  /**
   * For manual card entry, 2s isn't quiiiiittteee enough.
   * So, either change to a 3s or 4s timeout for everything, or have an optional check to query a 2nd time, if needed.
   */
  const smartRefetchOnTimeout = (attempts = 0) => {
    if (attempts > 1) {
      return;
    }

    setTimeout(async () => {
      const currentInvoiceIdsStr = invoices?.map((invoice) => invoice.id).join(',');
      const refetched = await refetchInvoices();

      const updatedInvoiceIdsStr = (refetched?.data?.data?.invoices || []).map((invoice) => invoice.id).join(',');

      if (currentInvoiceIdsStr === updatedInvoiceIdsStr) {
        smartRefetchOnTimeout(attempts + 1);
      }
    }, PAYMENT_SUCCESS_TIMEOUT);
  };

  const {
    triggerProps: paymentTriggerProps,
    Modal: PaymentModal,
    person,
    setInitialStep,
  } = usePaymentAction({
    context,
    onPaymentSuccess: () => {
      smartRefetchOnTimeout();
    },
    onInvoiceCreated: () => {
      smartRefetchOnTimeout();
    },
  });

  const {
    getTriggerProps: getMenuTriggerProps,
    getMenuProps,
    getItemProps: getMenuItemProps,
    close: closeMenu,
  } = useNestedPopoverMenu({
    placement: 'bottom-start',
  });

  const { invoices, refetch: refetchInvoices } = usePersonActiveInvoiceList(person.PersonID);

  const totalOutstandingAmount = useMemo(() => {
    if (!invoices || !invoices.length) {
      return 0;
    }

    return invoices.reduce((acc, invoice) => {
      if (invoice.status !== InvoiceStatus.Unpaid) {
        return acc;
      }

      return acc + invoice.billedAmount;
    }, 0);
  }, [invoices, person]);

  const formattedAmount = useMemo(() => {
    return formatCentsToCurrency(totalOutstandingAmount, false);
  }, [totalOutstandingAmount]);

  const getLabel = () => {
    return totalOutstandingAmount > 0 ? t('Unpaid Requests') : t('Create Payment Request');
  };

  useEffect(() => {
    /**
     * For the default button to open already on the create invoice step. Skip list step.
     */

    setInitialStep(PersonInvoiceModalSteps.CreateInvoice);
  }, []);

  const computedLabel = getLabel();
  const { setSelectedInvoiceId } = useInvoiceShallowStore('setSelectedInvoiceId');

  const onClickCreateNewInvoice = () => {
    setInitialStep(PersonInvoiceModalSteps.CreateInvoice);
    paymentTriggerProps.onClick();
  };

  const onClickCollectPaymentFromInvoice = (invoice: InvoiceModel) => {
    setSelectedInvoiceId(invoice.id);
    setInitialStep(CollectPaymentModalSteps.PaymentFlowList);
    closeMenu();
    paymentTriggerProps.onClick();
  };

  const onClickSendReminder = (invoice: InvoiceModel) => {
    setSelectedInvoiceId(invoice.id);
    setInitialStep(CollectPaymentModalSteps.ShareInMessage);
    closeMenu();
    paymentTriggerProps.onClick();
  };

  return (
    <>
      {totalOutstandingAmount > 0 ? (
        <div style={{ position: 'relative' }}>
          <Button
            trackingId={`${ContactActionPrefixes.Payments}:${trackingIdSuffix}:unpaid--trigger`}
            variant='secondary'
            hoverLabel={computedLabel}
            iconName='pay'
            className='payment-action-button'
            {...getMenuTriggerProps()}
            css={css`
              line-height: 1;
              background-color: ${theme.colors.tangerine5};
              border-color: ${theme.colors.warning30};
              color: ${theme.colors.neutral90};

              svg {
                fill: ${theme.colors.warning40};
                color: ${theme.colors.warning40};
              }

              &:hover,
              &:focus {
                background-color: ${theme.colors.tangerine10};
              }
            `}
          >
            {formattedAmount}
          </Button>
          <PopoverMenu {...getMenuProps()}>
            {invoices?.map((invoice, index) => {
              return (
                <PopoverMenuItemDropdown
                  index={index}
                  key={invoice.id}
                  trackingId={`${ContactActionPrefixes.Payments}:${trackingIdSuffix}`}
                  prefixIcon='document'
                  showOnHover={true}
                  subMenuItems={({ menu: { getItemProps } }) => (
                    <>
                      <PopoverMenuItem
                        {...getItemProps({
                          index: 0,
                          onClick: () => onClickSendReminder(invoice),
                        })}
                        trackingId={`${ContactActionPrefixes.Payments}:${trackingIdSuffix}:unpaid--inoice--send-reminder`}
                        Icon={() => <Icon name='send' size={16} color='subdued' />}
                      >
                        {t('Send Reminder')}
                      </PopoverMenuItem>
                      <PopoverMenuItem
                        {...getItemProps({
                          index: 1,
                          onClick: () => onClickCollectPaymentFromInvoice(invoice),
                        })}
                        trackingId={`${ContactActionPrefixes.Payments}:${trackingIdSuffix}:unpaid--inoice--send-reminder`}
                        Icon={() => <Icon name='pay' size={16} color='subdued' />}
                      >
                        {t('Collect Payment')}
                      </PopoverMenuItem>
                    </>
                  )}
                >
                  <div>{getFormattedDate(invoice.billedAt)}</div>
                  <div
                    className='invoice__amount'
                    css={css`
                      font-size: ${theme.font.size.small};

                      padding-right: ${theme.spacing(1)};
                      margin-left: auto;
                    `}
                  >
                    {formatCentsToCurrency(invoice.billedAmount)}
                  </div>
                </PopoverMenuItemDropdown>
              );
            })}
            <PopoverMenuDivider />
            <PopoverMenuItem
              trackingId={`${ContactActionPrefixes.Payments}:${trackingIdSuffix}:unpaid--create`}
              Icon={() => <Icon name='plus' color='subdued' size={16} />}
              {...getMenuItemProps({
                index: (invoices?.length || 0) + 1,
                onClick: onClickCreateNewInvoice,
              })}
            >
              {t('New Payment Request')}
            </PopoverMenuItem>
          </PopoverMenu>
        </div>
      ) : (
        <AdaptoActions.Action
          {...paymentTriggerProps}
          trackingId={`${ContactActionPrefixes.Payments}:${trackingIdSuffix}`}
          className='payment-action-button'
          icon='dollar-sign'
          label={computedLabel}
        />
      )}
      {PaymentModal}
    </>
  );
};
