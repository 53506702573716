import { useMemo, useState } from 'react';
import { BulkMessagingTypes } from '@frontend/api-bulk-messaging';
import { BulkMessagesQueries } from '@frontend/api-messaging';
import { MessagingEtlAmpQueries } from '@frontend/api-messaging-etl-amp';
import { Divider } from '@frontend/divider';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { Accordion, SpinningLoader, Text } from '@frontend/design-system';
import { AgeFilter } from '../filters/age-filter';
import { AppointmentDateFilter } from '../filters/appointment-date-filter';
import { DateFilter, formatRange } from '../filters/date-filter';
import { MultiselectFilter } from '../filters/multiselect-filter';
import { FilterAccordion } from './accordion';
// import { DropdownFilter } from '../filters/dropdown-filter';

type Props = {
  accessibleLocationIds: string[];
  onChange: (updates: BulkMessagingTypes.AudienceFilters) => void;
  selectedFilters: BulkMessagingTypes.AudienceFilters;
};

const defaultDropdownValue: any[] = [];
export const SegmentSelectionForm = ({ accessibleLocationIds, selectedFilters: filters, onChange }: Props) => {
  const { t } = useTranslation('messages');
  const { data: filterOptions = [], isLoading: isFiltersLoading } = BulkMessagesQueries.useRecipientFilterOptions();
  const { selectedOrgId: orgId } = useAppScopeStore();
  const { data: organizationsData = [] } = MessagingEtlAmpQueries.useGetLocation({ orgId });
  const locations = accessibleLocationIds.map((locationId) =>
    organizationsData.find((location) => location.locationId === locationId)
  );

  const data = useMemo(() => {
    const options = {
      primaryInsurance: filterOptions.find((option) => option.filterType === 'PRIMARY_INSURANCE'),
      appointmentStatus: filterOptions.find((option) => option.filterType === 'APPOINTMENT_STATUS'),
      appointmentType: filterOptions.find((option) => option.filterType === 'APPOINTMENT_TYPE'),
      recipientStatus: filterOptions.find((option) => option.filterType === 'RECIPIENT_STATUS'),
      serviceProvider: filterOptions.find((option) => option.filterType === 'APPOINTMENT_PRACTITIONER'),
      recallType: filterOptions.find((option) => option.filterType === 'RECALL_TYPE'),
      lastReceivedBulkMessageOptions: ['30+ days', '60+ days', '90+ days', '120+ days', '180+ days'],
      // sourceTenant: filterOptions.find((option) => option.filterType === 'SOURCE_TENANT'),
    };

    return {
      ...options,
      locations,
      appointmentTypeOptions: Object.values(options.appointmentType?.filterOptions ?? {}).filter((item) => item) ?? [],
      appointmentStatusOptions:
        Object.values(options.appointmentStatus?.filterOptions ?? {}).filter((item) => item) ?? [],
      recallTypeOptions: Object.values(options.recallType?.filterOptions ?? {}).filter((item) => item) ?? [],
      recipientStatusOptions: Object.values(options.recipientStatus?.filterOptions ?? {}).filter((item) => item) ?? [],
      primaryInsuranceOptions:
        Object.values(options.primaryInsurance?.filterOptions ?? {}).filter((item) => item) ?? [],

      numberOfPrimaryInsurances: Object.keys(options.primaryInsurance?.filterOptions ?? {}).length,
      numberOfAppointmentStatuses: Object.keys(options.appointmentStatus?.filterOptions ?? {}).length,
      numberOfAppointmentTypes: Object.keys(options.appointmentType?.filterOptions ?? {}).length,
      numberOfRecipientStatuses: Object.keys(options.recipientStatus?.filterOptions ?? {}).length,
      serviceProviderOptions: Object.values(options.serviceProvider?.filterOptions ?? {}),
      numberOfRecallTypes: Object.keys(options.recallType?.filterOptions ?? {}).length,
      // numberOfServiceProviders: Object.keys(serviceProvider?.filterOptions ?? {}).length,
      // numberOfIntegrations: Object.keys(sourceTenant?.filterOptions ?? {}).length,
    };
  }, [filterOptions, locations]);

  //these don't get saved on the audience filters yet, so we save them locally
  const [locationsFilter, setLocationsFilter] = useState<typeof data.locations>([]);
  const [serviceProvidersFilter, setServiceProvidersFilter] = useState<typeof data.serviceProviderOptions>([]);

  return (
    <>
      {isFiltersLoading ? (
        <div css={{ textAlign: 'center', padding: theme.spacing(4, 4, 0) }}>
          <SpinningLoader />
        </div>
      ) : (
        <div css={{ paddingTop: theme.spacing(4) }}>
          <Accordion
            variant='hybrid'
            color={theme.colors.neutral10}
            //TODO: fix up the accordion component in the design system so you can access the styles easier
            //TODO: maybe some of this visibility stuff could be used in the base component
            css={{
              '> div[data-active="true"]': {
                overflow: 'visible',
                'section[role="region"]': {
                  overflow: 'visible',
                },
              },
              '> div > h3 > button': {
                border: `1px solid ${theme.colors.neutral10}`,
                backgroundColor: theme.colors.white,
                borderRadius: theme.borderRadius.medium,
              },
              '> div[data-active="true"] > h3 > button': {
                backgroundColor: theme.colors.neutral10,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                border: `1px solid ${theme.colors.neutral20}`,
              },
            }}
          >
            {data.numberOfAppointmentStatuses || data.numberOfAppointmentTypes ? (
              <FilterAccordion.Item value='by-appointment-accordion'>
                <FilterAccordion.Header
                  hasFilters={
                    !!filters.appointmentQualifier ||
                    !!filters?.appointmentTypes?.length ||
                    !!filters.appointmentStatuses ||
                    !!filters.appointmentBeginDate ||
                    !!filters.appointmentEndDate
                  }
                  label={'Appointment Details'}
                />
                <FilterAccordion.Body>
                  <AppointmentDateFilter
                    appointmentDateRange={[filters.appointmentBeginDate ?? '', filters.appointmentEndDate ?? '']}
                    onChangeAppointmentDateRange={(range) => {
                      const formattedRange = formatRange(range, 'YYYY-MM-DD');
                      onChange({
                        ...filters,
                        appointmentBeginDate: formattedRange?.[0],
                        appointmentEndDate: formattedRange?.[1],
                      });
                    }}
                    appointmentQualifier={filters.appointmentQualifier}
                    onChangeAppointmentQualifier={(appointmentQualifier) =>
                      onChange({ ...filters, appointmentQualifier })
                    }
                  />
                  {data.numberOfAppointmentTypes ? (
                    <>
                      <MultiselectFilter
                        name='appointment-type'
                        value={filters.appointmentTypes}
                        label={t('Appointment Type')}
                        options={data.appointmentTypeOptions}
                        onChange={(appointmentTypes) => onChange({ ...filters, appointmentTypes })}
                      />
                      <Divider margin={theme.spacing(1)} />
                    </>
                  ) : null}

                  {data.numberOfAppointmentStatuses ? (
                    <MultiselectFilter
                      name='appointment-status'
                      value={filters.appointmentStatuses}
                      label={t('Appointment Status')}
                      options={data.appointmentStatusOptions}
                      onChange={(appointmentStatuses) => onChange({ ...filters, appointmentStatuses })}
                    />
                  ) : null}
                </FilterAccordion.Body>
              </FilterAccordion.Item>
            ) : null}
            {data.numberOfRecallTypes ? (
              <FilterAccordion.Item value='by-recall-accordion'>
                <FilterAccordion.Header
                  hasFilters={!!filters?.recallTypes?.length || !!filters.recallBeginDate || !!filters.recallEndDate}
                  label={t('By Recall Details')}
                />
                <FilterAccordion.Body>
                  <MultiselectFilter
                    name='recall-type'
                    value={filters.recallTypes}
                    label={t('Recall Type')}
                    options={data.recallTypeOptions}
                    onChange={(recallTypes) => onChange({ ...filters, recallTypes })}
                  />
                  <Text>{t('Recall Date')}</Text>
                  <DateFilter
                    name='recall-date'
                    value={[filters.recallBeginDate ?? '', filters.recallEndDate ?? '']}
                    onChange={(range) => {
                      const formattedRange = formatRange(range, 'YYYY-MM-DD');
                      return onChange({
                        ...filters,
                        recallBeginDate: formattedRange?.[0],
                        recallEndDate: formattedRange?.[1],
                      });
                    }}
                  />
                </FilterAccordion.Body>
              </FilterAccordion.Item>
            ) : null}
            {data.locations.length ? (
              <FilterAccordion.Item value='by-location'>
                <FilterAccordion.Header hasFilters={!!locationsFilter.length} label={t('Locations')} />
                <FilterAccordion.Body>
                  <MultiselectFilter
                    name='locations'
                    label={t('Locations')}
                    options={data.locations
                      .map((location) => location?.name)
                      .filter((loc): loc is NonNullable<typeof loc> => !!loc)}
                    value={locationsFilter
                      .map((loc) => loc?.name)
                      .filter((loc): loc is NonNullable<typeof loc> => !!loc)}
                    onChange={(locationNames) =>
                      setLocationsFilter(
                        locationNames
                          ? locationNames
                              .map((name) => data.locations.find((loc) => loc?.name === name))
                              .filter((v): v is NonNullable<typeof v> => !!v)
                          : []
                      )
                    }
                  />
                </FilterAccordion.Body>
              </FilterAccordion.Item>
            ) : null}
            {data.numberOfPrimaryInsurances ? (
              <FilterAccordion.Item value='by-insurance-accordion'>
                <FilterAccordion.Header
                  hasFilters={!!filters?.primaryInsurances?.length}
                  label={t('Insurance Details')}
                />
                <FilterAccordion.Body>
                  <MultiselectFilter
                    name='insurance-type'
                    label={t('Insurance Type')}
                    options={data.primaryInsuranceOptions}
                    value={filters.primaryInsurances}
                    onChange={(primaryInsurances) => onChange({ ...filters, primaryInsurances })}
                  />
                </FilterAccordion.Body>
              </FilterAccordion.Item>
            ) : null}
            {data.numberOfRecipientStatuses ? (
              <FilterAccordion.Item value='by-recipient-accordion'>
                <FilterAccordion.Header
                  hasFilters={!!filters?.recipientStatuses?.length || !!filters.minAge || !!filters.maxAge}
                  label={t('By Recipient Details')}
                />
                <FilterAccordion.Body>
                  <MultiselectFilter
                    name='recipient-status'
                    label={t('Recipient Status')}
                    options={data.recipientStatusOptions}
                    value={filters.recipientStatuses}
                    onChange={(recipientStatuses) => onChange({ ...filters, recipientStatuses })}
                  />
                  <Divider margin={theme.spacing(1)} />
                  <Text size='small'>{t('Recipient Age')}</Text>
                  <AgeFilter
                    minAgeLabel={t('Minimum Age')}
                    maxAgeLabel={t('Maximum Age')}
                    minAge={filters.minAge}
                    maxAge={filters.maxAge}
                    onChangeMaxAge={(maxAge) => onChange({ ...filters, maxAge })}
                    onChangeMinAge={(minAge) => onChange({ ...filters, minAge })}
                  />
                </FilterAccordion.Body>
              </FilterAccordion.Item>
            ) : null}
            {/* <FilterAccordion.Item value='by-provider-details'>
              <FilterAccordion.Header label={t('Provider Details')} />
              <FilterAccordion.Body>TODO:</FilterAccordion.Body>
            </FilterAccordion.Item> */}
            <FilterAccordion.Item value='by-service-provider'>
              <FilterAccordion.Header hasFilters={!!serviceProvidersFilter.length} label={t('Service Provider')} />
              <FilterAccordion.Body>
                <MultiselectFilter
                  name='service-provider'
                  label={t('Service Provider')}
                  options={data.serviceProviderOptions}
                  value={serviceProvidersFilter ?? defaultDropdownValue}
                  onChange={(selectedServiceProviders) => setServiceProvidersFilter(selectedServiceProviders ?? [])}
                />
                {/* TODO: provider locations doesn't seem to be available in filter options */}
                {/* <MultiselectFilter
                  name='provider-locations'
                  label={t('Provider Locations')}
                  options={serviceProviderOptions}
                  value={serviceProvidersFilter ?? defaultDropdownValue
                  onChange={(selectedServiceProviders) => setServiceProvidersFilter(selectedServiceProviders ?? [])}
                /> */}
              </FilterAccordion.Body>
            </FilterAccordion.Item>

            {/* <FilterAccordion.Item value='by-last-received-bulk-message'>
              <FilterAccordion.Header
                hasFilters={!!filters.minDaysSinceLastMarketingMessage}
                label={t('Last Received Bulk Message')}
              />
              <FilterAccordion.Body>
                <DropdownFilter
                  name='last-received-bulk-message'
                  label={t('Last Received Bulk Message')}
                  options={data.lastReceivedBulkMessageOptions}
                  value={filters.minDaysSinceLastMarketingMessage?.toString()}
                  onChange={(selection) =>
                    onChange({
                      ...filters,
                      minDaysSinceLastMarketingMessage: selection ? +selection.replace(/\D/g, '') : undefined,
                    })
                  }
                />
              </FilterAccordion.Body>
            </FilterAccordion.Item> */}
          </Accordion>
        </div>
      )}
    </>
  );
};
