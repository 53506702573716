import { useTranslation } from '@frontend/i18n';
import { useContentComposer } from '../content-composer-provider';
import { GoogleReviewButtonSection } from '../sections';
import { ToolboxButton } from '../sidebar';

export const GoogleReviewButtonTool = () => {
  const { t } = useTranslation('content-composer');
  const { insertSection } = useContentComposer();

  return (
    <ToolboxButton
      icon='social-google'
      title={t('Google Review Button')}
      subtitle={t(`Link to a location's Google Reviews`)}
      onClick={() => insertSection(<GoogleReviewButtonSection />)}
    >
      <GoogleReviewButtonSection />
    </ToolboxButton>
  );
};
