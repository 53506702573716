import { Segment } from '@weave/schema-gen-ts/dist/schemas/messaging/bulk/v2';
import { FilterOptions } from '@weave/schema-gen-ts/dist/schemas/messaging/shared/v1/bulk.pb';
import { BulkMessagingMutations } from '@frontend/api-bulk-messaging';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { Accordion, SpinningLoader, Text } from '@frontend/design-system';
import { Mode } from '../types';
import { AddSegmentButton } from './add-segment-button';
import { FiltersList } from './filters-list';
import { SegmentItem } from './segment-item';

type Props = {
  campaignId: string;
  segments?: Segment[];
  selectedLocationIds: string[];
  setFilters: (filters: FilterOptions) => void;
  setMode: (mode: Mode) => void;
  isCreatingSegment: boolean;
};

export const SegmentsListDisplay = ({
  campaignId,
  isCreatingSegment,
  segments,
  selectedLocationIds,
  setFilters,
  setMode,
}: Props) => {
  const { t } = useTranslation('bulk-messaging');
  const { selectedOrgId: orgId } = useAppScopeStore();
  const { mutate: deleteSegment } = BulkMessagingMutations.useDeleteSegment();

  const handleAddSegment = () => {
    setMode('form');
    setFilters({});
  };

  if (segments?.length) {
    return (
      <div
        css={{
          height: '100%',
          padding: selectedLocationIds.length === 1 ? theme.spacing(4, 0, 0) : theme.spacing(4, 4, 0),
          display: 'flex',
          flexDirection: 'column',
          rowGap: theme.spacing(2),
          alignItems: 'center',
        }}
      >
        <Accordion css={{ width: '100%' }}>
          {segments?.map((segment, index) => (
            <Accordion.Item key={segment.segmentId + '-' + index} value={segment.segmentId ?? ''}>
              <Accordion.Header>
                <SegmentItem
                  name={t('Segment {{index}}', { index: index + 1 })}
                  segment={segment}
                  onDelete={() => deleteSegment({ campaignId, segmentId: segment.segmentId, orgId })}
                />
              </Accordion.Header>
              <Accordion.Body css={{ display: 'flex', flexDirection: 'column', rowGap: theme.spacing(2) }}>
                <FiltersList filterOptions={segment.filterOptions} />
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
        {isCreatingSegment && (
          <Text css={{ display: 'flex', alignItems: 'center', marginTop: theme.spacing(2) }}>
            <SpinningLoader size='xs' css={{ marginRight: theme.spacing(0.5) }} />
            {t('Creating Segment...')}
          </Text>
        )}
        <AddSegmentButton handleAddSegment={handleAddSegment} css={{ marginTop: theme.spacing(3) }} />
      </div>
    );
  }

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        padding: theme.spacing(0, 4),
        rowGap: theme.spacing(2),
      }}
    >
      {isCreatingSegment && (
        <Text
          color='subdued'
          weight='bold'
          css={{ display: 'flex', alignItems: 'center', marginTop: theme.spacing(2) }}
        >
          <SpinningLoader size='xs' css={{ marginRight: theme.spacing(0.5) }} />
          {t('Creating Segment...')}
        </Text>
      )}
      {!isCreatingSegment && (
        <Text color='subdued' weight='bold'>
          {t('No Segments')}
        </Text>
      )}
      <Text color='subdued' css={{ textAlign: 'center' }}>
        {t('Target multiple types of people in your audience at once with segments.')}
      </Text>
      <AddSegmentButton handleAddSegment={handleAddSegment} />
    </div>
  );
};
