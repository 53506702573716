import { ElementType, HTMLAttributes, Ref, forwardRef, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { ROOT_NODE, useEditor } from '@craftjs/core';
import composeRefs from '@seznam/compose-react-refs';
import { DOCTYPE, HtmlTemplate, useContentComposer } from '../components';
import { ComposerSectionOutputProps } from '../types';

export const useHtmlRenderer = () => {
  const [resolves, setResolves] = useState<((html?: string) => void)[]>([]);
  const [rendererEnabled, setRendererEnabled] = useState(false);
  const [renderMode, setRenderMode] = useState<string | undefined>();

  const addResolve = (resolve: (html?: string) => void) => {
    setResolves((resolves) => (resolves ? [...resolves, resolve] : [resolve]));
  };

  // TODO: Figure out how to handle multiple render modes concurrently. (e.g. "preview", "send-test", "complete", etc.)
  // Currently, the last passed render mode will be used for all resolves because it calls setRenderMode last.
  const startRender = (mode?: string) =>
    new Promise<string | undefined>((resolve) => {
      setRendererEnabled(true);
      addResolve(resolve);
      setRenderMode(mode);
    });

  const finishRender = (html: string) => {
    reset();
    resolves.forEach((resolve) => resolve(html));
  };

  const cancelRender = () => {
    reset();
    resolves.forEach((resolve) => resolve(undefined));
  };

  const reset = () => {
    setRendererEnabled(false);
    setResolves([]);
    setRenderMode(undefined);
  };

  const htmlRendererProps: HtmlRendererProps = {
    enabled: rendererEnabled,
    onRender: finishRender,
    renderMode,
  };

  return { HtmlRenderer, htmlRendererProps, startRender, cancelRender };
};

type HtmlRendererProps = {
  enabled: boolean;
  onRender: (html: string) => void;
  renderMode?: string;
};

const HtmlRenderer = ({ enabled, onRender, renderMode }: HtmlRendererProps) =>
  enabled ? <Outputs onRender={onRender} renderMode={renderMode} /> : null;

const useContentComposerOutputs = () => {
  const { outputs: editorOutputs } = useEditor((state, query) => {
    const root = query.node(ROOT_NODE);
    const nodes = [...root.childNodes(), ...root.linkedNodes()];

    const outputs = nodes.reduce((acc, id) => {
      const node = state.nodes[id];
      const Output = node?.related?.output as ElementType<ComposerSectionOutputProps>;
      if (!node || !Output) return acc;

      const controlOutputRender = !!node.data?.custom?.controlOutputRender;
      acc.push({ controlOutputRender, Output });

      return acc;
    }, [] as { controlOutputRender: boolean; Output: ElementType<ComposerSectionOutputProps> }[]);

    return { outputs };
  });

  const [outputLoadingStates, setOutputLoadingStates] = useState<boolean[]>(() =>
    editorOutputs.map((output) => output.controlOutputRender)
  );

  const markOutputAsLoaded = (index: number) => {
    setOutputLoadingStates((oldOutputLoadingStates) => {
      const newOutputLoadingStates = [...oldOutputLoadingStates];
      newOutputLoadingStates[index] = false;
      return newOutputLoadingStates;
    });
  };

  const createOnRenderReadyProp = (index: number) =>
    editorOutputs[index]?.controlOutputRender ? () => markOutputAsLoaded(index) : undefined;

  const outputs = editorOutputs.map(({ Output }, index) => ({ onRenderReady: createOnRenderReadyProp(index), Output }));
  const isLoading = outputLoadingStates.some((isOutputLoading) => isOutputLoading);
  return { outputs, isLoading };
};

type OutputsProps = {
  onRender: (html: string) => void;
  renderMode?: string;
};

const Outputs = ({ onRender, renderMode }: OutputsProps) => {
  const { outputs, isLoading } = useContentComposerOutputs();
  const { composerMode } = useContentComposer();
  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    const iframeDocument = iframeRef.current?.contentWindow?.document || iframeRef.current?.contentDocument;

    if (isLoading || !iframeDocument) return;

    setTimeout(() => {
      const html = iframeDocument.body.innerHTML;
      if (composerMode === 'feedback' && renderMode !== 'preview') {
        onRender(html || '');
      } else {
        onRender(html ? DOCTYPE + html : ''); // innerHTML strips out the doctype. This adds it back.
      }
    });
  }, [isLoading, !!iframeRef.current]);

  // TODO: Add preview text back into the HtmlTemplate when we've figured out how we want to handle it.
  return (
    <HiddenIframe ref={iframeRef}>
      <HtmlTemplate renderMode={renderMode}>
        {outputs.map(({ onRenderReady, Output }, index) => (
          <Output key={index} onRenderReady={onRenderReady} renderMode={renderMode} />
        ))}
      </HtmlTemplate>
    </HiddenIframe>
  );
};

type HiddenIframeProps = HTMLAttributes<HTMLIFrameElement> & {
  children: React.ReactNode;
};

const HiddenIframe = forwardRef<HTMLIFrameElement, HiddenIframeProps>(({ children, ...rest }, ref) => {
  const [contentRef, setContentRef] = useState<HTMLIFrameElement | null>(null);
  const mountNode = contentRef?.contentWindow?.document.body;

  // TODO: Figure out how to suppress invalid HTML errors in the console.
  // These errors happen because we are embedding an entire HTML document into the iframe's body.
  return (
    <iframe
      {...rest}
      css={{ visibility: 'hidden', height: 0, width: 0, position: 'absolute' }}
      ref={composeRefs(ref, setContentRef as Ref<HTMLIFrameElement>)}
    >
      {mountNode && createPortal(children, mountNode)}
    </iframe>
  );
});

HiddenIframe.displayName = 'HiddenIframe';
