import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyle = css`
  display: flex;
`;

export const dragHandleStyle = css`
  cursor: move;
  margin-top: ${theme.spacing(3)};
`;
