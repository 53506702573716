import { Permission } from '@weave/schema-gen-ts/dist/shared/waccess/acls.pb';
import { FeatureFlagQueries } from '@frontend/api-feature-flags';
import { hasSchemaACL } from '@frontend/auth-helpers';
import { useAppScopeStore } from '@frontend/scope';

export const useFeatureAccess = (flagName: string, acl: Permission) => {
  const { selectedLocationIds: locationIds } = useAppScopeStore();

  // Feature flag check
  const { flagsByGroupId, isLoading, isError } = FeatureFlagQueries.useAggregateFeatureFlagQuery({
    flagName,
    locationIds,
    aggregationStrategy: FeatureFlagQueries.AggregationStrategy.ANY,
  });
  const locationsWithEnabledFlag = Object.entries(flagsByGroupId)
    .filter(([_, enabled]) => enabled)
    .map(([locationId]) => locationId);
  const hasEnabledFlag = locationsWithEnabledFlag.length > 0; // this means that at least one of their selected locations has it enabled

  // ACL check
  const locationsWithACLAccess = locationsWithEnabledFlag.filter((locationId) => hasSchemaACL(locationId, acl));
  const hasACLAccess = locationsWithACLAccess.length > 0; // this means that the user has access to manage the feature for at least one location

  const hasAccess = hasACLAccess && hasEnabledFlag;

  // visually they should be able to see the feature if they have access to at least one location
  // but they should only be able to edit the ones they have access to
  return { locationsWithACLAccess, locationsWithEnabledFlag, hasAccess, isLoading, isError };
};
