import { useTranslation } from '@frontend/i18n';
import { Stepper } from '@frontend/design-system';
import { usePractitionerDetails } from '../../../component-hooks';
import { useAddPractitionerV3Context } from '../../../context/AddPractitionerV3Context';

type AddPractitionerStepperFirstStepProps = {
  setIsCreatePractitionerError: (isError: boolean) => void;
};

export const AddPractitionerStepperFirstStep = ({
  setIsCreatePractitionerError,
}: AddPractitionerStepperFirstStepProps) => {
  const { t } = useTranslation('schedule');

  const { onClose, locationId, setPractitionerDetails, practitionerDetails } = useAddPractitionerV3Context();

  const { PractitionerDetails } = usePractitionerDetails({
    practitionerId: practitionerDetails?.id || '',
    locationId,
    onCancel: onClose,
    primaryButtonLabel: t('Continue'),
    secondaryButtonLabel: t('Cancel'),
    isStepperComponent: true,
    setPractitionerDetailsOnCreate: setPractitionerDetails,
    onErrors: (err) => {
      setIsCreatePractitionerError(!!err);
      onClose();
    },
  });

  return <Stepper.Content>{PractitionerDetails}</Stepper.Content>;
};
