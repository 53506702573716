export const GoogleNoBorderSvg = ({ fillColor }: { fillColor?: string }) => (
  <svg
    height={16}
    width={16}
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    version='1.1'
    baseProfile='full'
  >
    <path
      fill={fillColor}
      fillRule='evenodd'
      d='M16 8.182c0-.567-.052-1.112-.149-1.636H8.163V9.64h4.393a3.677 3.677 0 0 1-1.628 2.415v2.007h2.638C15.109 12.67 16 10.62 16 8.182'
      clipRule='evenodd'
    ></path>
    <path
      fill={fillColor}
      fillRule='evenodd'
      d='M8.163 16c2.204 0 4.052-.717 5.403-1.939l-2.639-2.007c-.73.48-1.666.764-2.764.764-2.126 0-3.926-1.408-4.568-3.298H.868v2.072C2.211 14.207 4.972 16 8.163 16'
      clipRule='evenodd'
    ></path>
    <path
      fill={fillColor}
      fillRule='evenodd'
      d='M3.596 9.52A4.722 4.722 0 0 1 3.34 8c0-.527.092-1.04.256-1.52V4.407H.868a7.867 7.867 0 0 0 0 7.186z'
      clipRule='evenodd'
    ></path>
    <path
      fill={fillColor}
      fillRule='evenodd'
      d='M8.163 3.182c1.199 0 2.275.403 3.12 1.196l2.342-2.294C12.211.793 10.364 0 8.163 0 4.973 0 2.211 1.793.868 4.407L3.595 6.48c.642-1.89 2.442-3.298 4.568-3.298'
      clipRule='evenodd'
    ></path>
  </svg>
);
