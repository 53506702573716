import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyle = css`
  display: flex;
  gap: ${theme.spacing(1)};
  flex: 1;
  min-width: calc((100% / 3) - ${theme.spacing(8)}); // 3 columns and 2 gaps (32px each)
`;

interface GetContainerStyleConfig {
  shouldAnimate: boolean;
}

export function getContainerStyle({ shouldAnimate }: GetContainerStyleConfig) {
  if (!shouldAnimate) {
    return containerStyle;
  }

  return [
    containerStyle,
    css`
      animation-name: appear;
      animation-duration: 1.5s;
      animation-fill-mode: forwards;

      @keyframes appear {
        from {
          opacity: 0;
        }

        to {
          opacity: 1;
        }
      }
    `,
  ];
}

export const dragHandleStyle = css`
  align-self: baseline;
  padding: ${theme.spacing(1, 0)};
  cursor: move !important;
`;

export const innerContainerStyle = css`
  flex: 1;
`;

export const displayContainerStyle = css`
  display: flex;
  align-items: center;
  gap: ${theme.spacing(1)};
  border-bottom: 1px solid ${theme.colors.neutral20};
  margin-bottom: ${theme.spacing(1)};
`;

export const removeOptionButtonStyle = css`
  margin-inline-start: auto;
  flex-shrink: 0;
`;
