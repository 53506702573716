import {
  DeleteMediaRequest,
  ListMediaRequest,
  ListMediaResponse,
  Media,
  MediaCollectionName_Enum as MediaCollectionName,
} from '@weave/schema-gen-ts/dist/schemas/messaging/media/public/v1/service.pb';
import { MutationOptions, useMutation, useQueryClient } from 'react-query';
import { http } from '@frontend/fetch';
import { SchemaPublicMedia } from './service';

export type QueryKeys = (string | ListMediaRequest)[];
export type InfiniteMediaData = { pagesParams: []; pages: ListMediaResponse[] };

type UploadedFile = {
  data: Blob;
  filename: string;
  selectedLocationIds: string[];
  type: MediaCollectionName;
};

const getUploadMediaUrl = (collectionType: MediaCollectionName) =>
  `/messaging/media/public/v1/collections/${collectionType}/media`;

export const useUploadMedia = (
  options: MutationOptions<Media, unknown, UploadedFile, { previousMedia?: InfiniteMediaData; url: string }> = {}
) =>
  useMutation({
    mutationKey: 'uploadMedia',
    mutationFn: async (request: UploadedFile) => {
      const { data, filename, selectedLocationIds, type } = request;
      const dataToUpload = new FormData();
      dataToUpload.append('file', data, filename);
      selectedLocationIds.forEach((id) => dataToUpload.append('business_group_ids', id));
      dataToUpload.append('type', type);

      return await http.post<Media>(getUploadMediaUrl(type), dataToUpload);
    },
    ...options,
  });

export const useDeleteMedia = (queryKeys: QueryKeys) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: 'deleteMedia',
    mutationFn: (request: DeleteMediaRequest) => SchemaPublicMedia.DeleteMedia(request),
    onMutate: (request) => {
      queryClient.cancelQueries(queryKeys);
      const previousMedia = queryClient.getQueryData<InfiniteMediaData>(queryKeys);
      queryClient.setQueryData(queryKeys, () => {
        // there should only ever be one page of media at a time
        const firstPage = previousMedia?.pages?.[0];
        const newMedia = firstPage?.media.filter((media) => media.mediaId !== request.mediaId);
        return { ...previousMedia, pages: [{ ...firstPage, media: newMedia }] };
      });
      return { previousMedia };
    },
    onError: (_err, _req, context) => {
      const previousMedia = context?.previousMedia;
      queryClient.setQueryData(queryKeys, previousMedia);
    },
  });
};
