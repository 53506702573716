import { memo } from 'react';
import { Handle, Position } from '@xyflow/react';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';
import { DataPayload } from '../data';
import { BaseRoutingNode } from './routing-node';

export const CallGroupNode = memo(({ data }: { data: DataPayload }) => {
  return (
    <BaseRoutingNode
      icon='call-park'
      title='Call Group'
      label={data.label}
      link={data.id}
      onClickDelete={(id) => {
        console.log('delete', id);
      }}
      onClickEdit={(id) => {
        console.log('edit', id);
      }}
    >
      {/* This is just an example */}
      <div>
        <div>
          <Text size='small' color='subdued'>
            Call Queue
          </Text>
          <Text>Something</Text>
        </div>
        <div>
          <Text size='small' color='subdued'>
            Call Queue
          </Text>
          <Text>Something</Text>
        </div>
        <div>
          <Text size='small' color='subdued'>
            Call Queue
          </Text>
          <Text>Something</Text>
        </div>
      </div>
    </BaseRoutingNode>
  );
});
CallGroupNode.displayName = 'CallGroupNode';

export const CallQueueNode = memo(({ data }: { data: DataPayload }) => {
  return (
    <BaseRoutingNode
      icon='call-intel'
      title='Call Queue'
      label={data.label}
      link={data.id}
      onClickDelete={() => {}}
      onClickEdit={() => {}}
    ></BaseRoutingNode>
  );
});
CallQueueNode.displayName = 'CallQueueNode';

export const ForwardingDeviceNode = memo(({ data }: { data: DataPayload }) => {
  return (
    <BaseRoutingNode
      icon='cell-phone'
      title='Forwarding Device'
      label={data.label}
      link={data.id}
      onClickDelete={() => {}}
      onClickEdit={() => {}}
    />
  );
});
ForwardingDeviceNode.displayName = 'ForwardingDeviceNode';

export const ForwardingNumberNode = memo(({ data }: { data: DataPayload }) => {
  return (
    <BaseRoutingNode
      icon='send-forward'
      title='Forwarding Number'
      label={data.label}
      link={data.id}
      onClickDelete={() => {}}
      onClickEdit={() => {}}
    />
  );
});
ForwardingNumberNode.displayName = 'ForwardingNumberNode';

export const OfficeHoursNode = memo(({ data }: { data: DataPayload }) => {
  return (
    <BaseRoutingNode
      icon='clock'
      title='Office Hours'
      label={data.label}
      link={data.id}
      onClickDelete={() => {}}
      onClickEdit={() => {}}
    />
  );
});
OfficeHoursNode.displayName = 'OfficeHoursNode';

export const PhoneTreeNode = memo(({ data }: { data: DataPayload }) => {
  return (
    <BaseRoutingNode
      icon='phone'
      title='Phone Tree'
      label={data.label}
      link={data.id}
      onClickDelete={() => {}}
      onClickEdit={() => {}}
    />
  );
});
PhoneTreeNode.displayName = 'PhoneTreeNode';

export const TreeOptionNode = memo(({ data }: { data: DataPayload }) => {
  const handles = {
    top: { id: 'top' },
    bottom: { id: 'bottom' },
  };
  return (
    <>
      <Handle
        type='target'
        position={Position.Top}
        id={handles.top.id}
        isConnectable={true}
        style={{ visibility: 'hidden' }}
      />
      <div
        style={{
          width: 24,
          height: 24,
          backgroundColor: theme.colors.black,
          borderRadius: '50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Text as='span' color='white' weight='bold' size='small'>
          {data.label}
        </Text>
      </div>
      <Handle
        type='source'
        position={Position.Bottom}
        id={handles.bottom.id}
        isConnectable={true}
        style={{ visibility: 'hidden' }}
      />
    </>
  );
});
TreeOptionNode.displayName = 'TreeOptionNode';

export const PlayNode = memo(({ data }: { data: DataPayload }) => {
  return (
    <BaseRoutingNode
      icon='play'
      title='Play'
      label={data.label}
      link={data.id}
      onClickDelete={() => {}}
      onClickEdit={() => {}}
    ></BaseRoutingNode>
  );
});
PlayNode.displayName = 'PlayNode';

export const VoicemailNode = memo(({ data }: { data: DataPayload }) => {
  return (
    <BaseRoutingNode
      icon='voicemail'
      title={'Voicemail'}
      label={data.label}
      link={data.id}
      onClickDelete={() => {}}
      onClickEdit={() => {}}
    />
  );
});
VoicemailNode.displayName = 'VoicemailNode';

export const RepeatNode = memo(({ data }: { data: DataPayload }) => {
  return (
    <BaseRoutingNode
      icon='sync' // PT-TODO: use the new repeat icon
      title={'Repeat'}
      label={data.label}
      link={data.id}
      onClickDelete={() => {}}
      onClickEdit={() => {}}
    />
  );
});
RepeatNode.displayName = 'RepeatNode';
