import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme-adapter';
import { Text, useFormField, CheckboxField } from '@frontend/design-system';

interface Props {
  authorizedSignatureData: string;
  dateData: string;
}
export const SignedComponent = ({ authorizedSignatureData, dateData }: Props) => {
  const { t } = useTranslation('portingLoa');
  const checkboxValue = useFormField({ type: 'checkbox' });

  return (
    <>
      <Text css={mainContainer} color='disabled'>
        <CheckboxField
          {...checkboxValue}
          name='agreement'
          disabled={true}
          css={{ marginLeft: theme.spacing(0.5) }}
          value={true}
          label={<Text css={{ marginLeft: theme.spacing(0.5) }}>{t('loaVerifyMessage')}</Text>}
        />

        {t('loaVerifyMessageExtended')}
      </Text>
      <div css={authorizationStyle}>
        <div
          css={css`
            border: 1px solid ${theme.colors.black};
            display: flex;
          `}
        >
          <Text weight='bold' css={fieldStyle}>
            {t('Authorized Signature:')}
          </Text>
          <Text css={{ padding: theme.spacing(1) }}>{authorizedSignatureData}</Text>
        </div>
        <div css={signStyle}>
          <Text weight='bold' css={fieldStyle}>
            {t('Date:')}
          </Text>
          <Text css={{ padding: theme.spacing(1) }}>{dateData}</Text>
        </div>
      </div>
    </>
  );
};

const fieldStyle = css`
  width: 300px;
  border-right: 1px solid ${theme.colors.black};
  padding: ${theme.spacing(1)};
`;

const mainContainer = css`
  text-align: justify;
  margin-top: ${theme.spacing(4)};
  margin-bottom: ${theme.spacing(4)};
`;

const authorizationStyle = css`
  display: grid;
  grid-template-columns: auto;
  padding: ${theme.spacing(1)};
`;
const signStyle = css`
  border: 1px solid ${theme.colors.black};
  display: flex;
  border-top: 0;
`;
