import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

interface GetContainerStyleConfig {
  isActive?: boolean;
  disabled?: boolean;
}

const commonContainerStyle = css`
  padding: ${theme.spacing(1, 1.5)};
`;

export function getContainerStyle({ isActive, disabled }: GetContainerStyleConfig) {
  if (disabled) {
    return [
      commonContainerStyle,
      css`
        cursor: not-allowed;
        color: ${theme.colors.text.disabled};
      `,
    ];
  }

  return [
    commonContainerStyle,
    css`
      cursor: pointer;
      background-color: ${isActive ? theme.colors.neutral5 : 'initial'};

      &:hover {
        background-color: ${theme.colors.neutral5};
      }
    `,
  ];
}
