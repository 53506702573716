import { memo, useMemo } from 'react';
import { AnalyticsCommonTypes, PracticeAnalyticsAggregations, PracticeAnalyticsTypes } from '@frontend/api-analytics';
import { BarChartData, Chart, YAxisLabelValueTick } from '@frontend/charts';
import { theme } from '@frontend/theme';
import { useAnalyticsOrgLocations } from '../../hooks';
import { formatters } from '../../utils';
import { LocationChip } from '../location-chip';
import { usePracticeAnalyticsShallowStore } from './hooks';

type Props = PracticeAnalyticsTypes.MetricProps & {
  colors: AnalyticsCommonTypes.StringRecord;
  data?: ReturnType<typeof PracticeAnalyticsAggregations.recapturedPatientsSummary>;
  isLoading?: boolean;
  labels: AnalyticsCommonTypes.StringRecord;
};

export const RecapturedMetricMulti = memo(({ colors, data, isLoading, labels }: Props) => {
  const { isDemoAccount } = usePracticeAnalyticsShallowStore('isDemoAccount');
  const { locationNames } = useAnalyticsOrgLocations({ isDemoAccount, module: 'PA' });

  const chartsData = useMemo(() => {
    const entries = Object.entries(data?.rawData ?? {});

    const patients: BarChartData = {
      groups: entries.map(([locationId, locationDetails]) => ({
        name: locationNames[locationId] || locationId,
        values: {
          patients: locationDetails?.location.recapturedPatients.totals?.patients ?? 0,
          leadTime: locationDetails?.location.recapturedPatients.totals?.leadTime ?? 0,
        },
      })),
    };

    const production: BarChartData = {
      groups: entries.map(([locationId, locationDetails]) => ({
        name: locationNames[locationId] || locationId,
        values: {
          production: locationDetails?.location.recapturedPatients.totals?.production ?? 0,
        },
      })),
    };

    return {
      patients,
      production,
    };
  }, [locationNames, data?.rawData]);

  const renderChart = (data: BarChartData, isProductionChart?: boolean) => (
    <Chart colors={colors} isLoading={isLoading} labels={labels}>
      <Chart.Legends style={{ marginBottom: theme.spacing(2) }} />
      <Chart.BarChart
        appearance={{
          collectiveTooltip: true,
          customTooltipTitle: ({ groupName }) => <LocationChip locationName={groupName} noTruncating />,
          customXAxisTickFormat: isProductionChart ? formatters.currency.shortenNumber : undefined,
          customYAxisTick: ({ labels, groupName, ...rest }) => {
            return <YAxisLabelValueTick {...rest} clipLength={25} label={labels?.[groupName] || groupName} />;
          },
          groupsGap: 24,
          layout: 'vertical',
          margin: { left: 114 },
          mode: isProductionChart ? 'grouped' : 'stacked',
          showGridLines: true,
          showXAxis: true,
          showYAxis: true,
        }}
        data={data}
        formatValue={isProductionChart ? formatters.currency.format : formatters.value.format}
      />
    </Chart>
  );

  return (
    <>
      {renderChart(chartsData.patients)}
      {renderChart(chartsData.production, true)}
    </>
  );
});

RecapturedMetricMulti.displayName = 'RecapturedMetricMulti';
