import { Section } from '@react-email/components';
import DOMPurify from 'dompurify';
import { useComposerSection } from '../../hooks';
import { CustomCodeSectionProps } from '../../types';

export const CustomCodeOutput = () => {
  const { props } = useComposerSection<CustomCodeSectionProps>();

  if (!props.code) return null;
  const sanitizedCode = DOMPurify.sanitize(props.code);

  return (
    <Section>
      <div dangerouslySetInnerHTML={{ __html: sanitizedCode }} />
    </Section>
  );
};
