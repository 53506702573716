import { useCallback } from 'react';
import { NotificationQueries } from '@frontend/api-notifications';
import { allowedNotificationTypes } from '@frontend/settings-routing';
import { GetWeavePopNotificationByType, WeavePopNotification } from '@frontend/types';
import { createDesktopNotification } from '../create-desktop-notification';
import { useNotificationContext } from '../notification-provider';
import { useNotificationQueue } from '../notification-queue';

export type CreateNotification<T extends WeavePopNotification['type']> = (
  notification: GetWeavePopNotificationByType<T>,
  options?: {
    os?: Parameters<typeof createDesktopNotification>[0] | undefined;
    track?: boolean;
  }
) => void;

export const useNotification = <T extends WeavePopNotification['type']>() => {
  const { data } = NotificationQueries.useNotificationSettingsQuery();
  const { maxLocationsSelected } = useNotificationContext();
  const { add, remove, update } = useNotificationQueue();
  // map user's notification settings to the allowed notification types
  const mappedNotificationSettings = allowedNotificationTypes.map((allowedType) => ({
    type: allowedType.type,
    canNotify: data?.find((notificationType) => {
      return notificationType.id === allowedType.id;
    })?.sendNotification,
  }));

  const create: CreateNotification<T> = useCallback(
    (notification, _options) => {
      if (maxLocationsSelected) {
        return;
      }
      const notificationType = mappedNotificationSettings.find((item) => item.type === notification.type);

      if (!notificationType?.canNotify) {
        return;
      }
      add(notification);
      // I think this is commented out because we handle create the desktop notifications manually in the websocket listeners now
      // if (options?.os) {
      //   createDesktopNotification(options.os);
      // }
    },
    [add, maxLocationsSelected, allowedNotificationTypes, mappedNotificationSettings]
  );

  const updateNotification = useCallback(
    (notification: GetWeavePopNotificationByType<T>) => {
      update(notification);
    },
    [update]
  );

  const removeNotification = useCallback(
    (id: string) => {
      remove(id);
    },
    [remove]
  );

  return {
    create,
    remove: removeNotification,
    update: updateNotification,
  };
};
