import { useEffect, useState } from 'react';
import { EmailTemplate } from '@weave/schema-gen-ts/dist/schemas/messaging/bulk/email-template/v1/emailtemplate.pb';
import { EmailTemplateQueries } from '@frontend/api-email-template';
import { DefaultTemplateCard } from './default-template-card';
import { ListTemplates } from './list-templates';

export const DefaultListTemplates = () => {
  const [pageToken, setPageToken] = useState<string | undefined>(undefined);
  const [templates, setTemplates] = useState<EmailTemplate[]>([]);

  const listRequest = { pageToken };

  const { data, fetchNextPage, hasNextPage, isFetched, isFetchingNextPage } =
    EmailTemplateQueries.useListSystemTemplates(listRequest);

  useEffect(() => {
    const newData = data?.pages?.[0]?.templates ?? [];
    const newTemplates = [...newData, ...templates];
    const filteredTemplates = newTemplates.filter(
      (template, index, arr) => arr.findIndex((t) => t.id === template.id) === index
    );
    setTemplates(filteredTemplates);
    return () => {
      setTemplates([]);
    };
  }, [pageToken, isFetched]);

  const getNextPage = () => {
    if (hasNextPage && !isFetchingNextPage) {
      setPageToken(data?.pages?.[0]?.nextPageToken);
      fetchNextPage();
    }
  };

  return (
    <ListTemplates
      DisplayedCard={(_index, template) => <DefaultTemplateCard key={template.id} {...template} />}
      getNextPage={getNextPage}
      hasNextPage={hasNextPage}
      isFetched={isFetched}
      isFetchingNextPage={isFetchingNextPage}
      templates={templates}
    />
  );
};
