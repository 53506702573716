import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';

interface ReadOnlyFieldProps extends React.HTMLAttributes<HTMLDivElement> {
  label: string;
  value: string | boolean | React.ReactNode;
}

export const ReadOnlyField = ({ label, value, ...rest }: ReadOnlyFieldProps) => {
  const { t } = useTranslation('scheduleCalendarRequest');
  const valueType = typeof value;

  const getValueJSXElement = () => {
    switch (valueType) {
      case 'boolean':
        return <Text>{value ? t('Yes') : t('No')}</Text>;
      case 'string':
        return <Text size='medium'>{value}</Text>;
      default:
        return value;
    }
  };

  return (
    <div css={{ marginBottom: theme.spacing(1) }} {...rest}>
      <Text size='small' color='subdued'>
        {label}
      </Text>
      {getValueJSXElement()}
    </div>
  );
};
