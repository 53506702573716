import { createProvider } from '@frontend/store';
import { BUILDER_FIELD_DIVISIONS } from '../constants';
import { FormBuilderStore, FormBuilderState } from './types';

const INITIAL_STATE: FormBuilderState = {
  isBuilderReady: false,
  primaryFieldElements: undefined,
  usedPrimaryFieldElements: {},
  paymentsFieldElements: undefined,
  otherFieldElements: undefined,
  sectionTemplates: undefined,
  sectionTemplatesOrder: [],
  usedSectionTemplates: {},
  shouldShowMedicalHistoryFields: false,
  medicalConditions: {},
  activeBuilderDivision: BUILDER_FIELD_DIVISIONS[0].key,
  isCreatingForm: false,
  isUpdatingForm: false,
  searchFieldTerm: '',
  isDownloadingPDF: false,
  formsEnabledLocationIds: [],
  paymentsEnabledLocationIdsMap: {},
  sourceIdForMedicalHistoryFields: '',
};

/**
 * This store (context) is used to hold the state of the FormBuilder.
 */
export const { Provider: FormBuilderStoreProvider, useStore: useFormBuilderStore } = createProvider<FormBuilderStore>()(
  (set, get) => ({
    ...INITIAL_STATE,
    resetBuilderState: () =>
      set({
        usedSectionTemplates: {},
        usedPrimaryFieldElements: {},
      }),

    setIsBuilderReady: (value) => set({ isBuilderReady: value }),

    setPrimaryFieldElements: (value) => set({ primaryFieldElements: value }),
    setUsedPrimaryFieldElement: (key, value) =>
      set((state) => ({
        usedPrimaryFieldElements: {
          ...state.usedPrimaryFieldElements,
          [key]: value,
        },
      })),

    batchUpdateUsedPrimaryFieldElements: (value, reset = false) => {
      if (reset) {
        set({ usedPrimaryFieldElements: value });
      } else {
        set((state) => ({
          usedPrimaryFieldElements: {
            ...state.usedPrimaryFieldElements,
            ...value,
          },
        }));
      }
    },

    setPaymentsFieldElements: (value) => set({ paymentsFieldElements: value }),
    setOtherFieldElements: (value) => set({ otherFieldElements: value }),

    setSectionTemplates: (value) => set({ sectionTemplates: value }),
    setSectionTemplatesOrder: (value) => set({ sectionTemplatesOrder: value }),
    setUsedSectionTemplate: (key, value) =>
      set((state) => ({
        usedSectionTemplates: {
          ...state.usedSectionTemplates,
          [key]: value,
        },
      })),

    batchUpdateUsedSectionTemplates: (value, reset = false) => {
      if (reset) {
        set({ usedSectionTemplates: value });
      } else {
        set((state) => ({
          usedSectionTemplates: {
            ...state.usedSectionTemplates,
            ...value,
          },
        }));
      }
    },

    setShouldShowMedicalHistoryFields: (value) => set({ shouldShowMedicalHistoryFields: value }),
    setMedicalConditions: (value) => set({ medicalConditions: value }),
    setActiveBuilderDivision: (value) => set({ activeBuilderDivision: value }),

    setIsCreatingForm: (value) => set({ isCreatingForm: value }),
    setIsUpdatingForm: (value) => set({ isUpdatingForm: value }),
    setSearchFieldTerm: (value) => set({ searchFieldTerm: value }),
    setIsDownloadingPDF: (value) => set({ isDownloadingPDF: value }),

    setFormsEnabledLocationIds: (value) => set({ formsEnabledLocationIds: value }),
    setPaymentsEnabledLocationIdsMap: (value) => set({ paymentsEnabledLocationIdsMap: value }),
    checkIfPaymentsAreEnabled: (locationId) => get().paymentsEnabledLocationIdsMap[locationId] ?? false,

    getFormBuilderStoreState: () => {
      const { formsEnabledLocationIds, paymentsEnabledLocationIdsMap } = get();
      return {
        formsEnabledLocationIds,
        paymentsEnabledLocationIdsMap,
      };
    },

    setSourceIdForMedicalHistoryFields: (value) => set({ sourceIdForMedicalHistoryFields: value }),
    getSourceIdForMedicalHistoryFields: () => {
      const { sourceIdForMedicalHistoryFields } = get();
      return sourceIdForMedicalHistoryFields;
    },
  })
);
