import { Options, http } from '@frontend/fetch';
import { Setting } from './types';

const baseUrl = 'client-api/settings';

export const querySetKeys = {
  sets: {
    email: 'email',
    dayOfWeek: 'messaging',
    showUnrepliedStatus: 'messaging',
    enableActionableTab: 'messaging',
    markAsRead: 'notifications',
    autoMessageInboxVisibility: 'messaging',
    paymentsUrl: 'client',
    stripeExpressPublicKey: 'client',
  },
  keys: {
    email: 'automated',
    dayOfWeek: 'day_of_week_preferences',
    showUnrepliedStatus: 'show_unreplied_status',
    enableActionableTab: 'enable_actionable_tab',
    markAsRead: 'auto_confirmed_mark_read',
    autoMessageInboxVisibility: 'auto_message_inbox_visibility',
    paymentsUrl: 'paymentsUrl',
    stripeExpressPublicKey: 'stripeExpressPublicKey',
  },
};

export const get = async (set: string, key: string, options?: Options) =>
  await http.get<Setting[]>(`${baseUrl}?set=${set}&key=${key}`, options).then((res) => {
    if (res && res.length > 0) {
      return res[0].value;
    }
    return undefined;
  });

export const put = async (set: string, key: string, value: string, options?: Options) =>
  await http.put<Setting, Setting>(`${baseUrl}`, { key, set, value }, options);
