import { useEffect, useMemo } from 'react';
import { getUser, localStorageHelper } from '@frontend/auth-helpers';
import { WidgetConfig, addWidgetToLayout, isEnabled, tidyDashboardLayout } from '@frontend/grid-dashboard';
import { WIDGET_CONFIGS_KEY, useWidgetConfig } from './store';
import { DashboardWidget, defaultWidgets, useWidgets } from './use-dashboard-widgets';

export function useAccessibleWidgetConfig() {
  const { widgets } = useWidgets();
  const { widgetConfigs: prefilteredWidgets, setWidgetConfigs } = useWidgetConfig([
    'widgetConfigs',
    'setWidgetConfigs',
  ]);

  //This should run only once for the
  useEffect(() => {
    if (!!prefilteredWidgets.length) return;
    const user = getUser();

    if (!localStorageHelper.get(`${user?.userID}${WIDGET_CONFIGS_KEY}`)) {
      const defaultWidgets = convertToWidgetConfig(widgets);
      setWidgetConfigs(tidyDashboardLayout(defaultWidgets));
    }
  }, [prefilteredWidgets, widgets]);

  const accessibleWidgets = useMemo(
    () =>
      prefilteredWidgets.filter((widget) => {
        const widgetId = widgets?.[widget.id];
        if (!widgetId) return false;

        return isEnabled(widgets?.[widget.id]?.hasAccess);
      }),
    [widgets, prefilteredWidgets]
  );

  return { accessibleWidgets, setWidgetConfigs };
}

const convertToWidgetConfig = (widgets: { [id: string]: DashboardWidget }): WidgetConfig[] => {
  let widgetConfigs: WidgetConfig[] = [];

  defaultWidgets.forEach((id) => {
    const widget = widgets[id];
    if (!widget) {
      console.warn(`Widget with id ${id} not found`);
      return;
    }

    const size = widget.component.config?.size;
    if (!size) {
      console.warn(`Size configuration not found for widget ${id}`);
      return;
    }

    const newWidget = {
      id,
      size,
    };

    widgetConfigs = addWidgetToLayout(widgetConfigs, newWidget);
  });

  return widgetConfigs;
};
