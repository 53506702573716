import React from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { Button } from '@frontend/design-system';
import { styles } from './styles';

interface GlobalBannerControlsProps {
  show: boolean;
  onPrevious?: () => void;
  onNext?: () => void;
}

export const GlobalBannerControls: React.FC<GlobalBannerControlsProps> = ({ show, onPrevious, onNext }) => {
  const { t } = useTranslation('global-info-banner');
  if (!show) return null;

  return (
    <motion.div css={styles.carouselNavigation}>
      <Button
        variant='secondary'
        iconName='caret-left'
        css={styles.navigationButton}
        onClick={onPrevious}
        aria-label={t('Previous banner')}
      />
      <Button
        variant='secondary'
        iconName='caret-right'
        css={styles.navigationButton}
        onClick={onNext}
        aria-label={t('Next banner')}
      />
    </motion.div>
  );
};
