import { useState } from 'react';
import { css } from '@emotion/react';
import { format } from 'date-fns';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import {
  Text,
  FormRow,
  TextField,
  CheckboxField,
  useFormField,
  PrimaryButton,
  DatePickerField,
  ValidatorFieldState,
  useControlledField,
} from '@frontend/design-system';
import { validateFullNameRegex } from '../../helper/index';

interface Props {
  handleDisableButton: (signDate: string, authorizedUserFullName: string, checkboxValue: boolean) => boolean;
  isPdf: boolean;
  handleSignedData: (authorizedUserName: string, signDate: string) => void;
}
export const SignedEditableComponent = ({ handleDisableButton, isPdf, handleSignedData }: Props) => {
  const { t } = useTranslation('portingLoa');

  const minDate = format(new Date(), 'MM/dd/yyyy');
  const today = new Date();
  const maxDate = format(today.setMonth(today.getMonth() + 1), 'MM/dd/yyyy');
  /* min date to be today and max day to be today's date one month ahead */
  const checkboxValue = useFormField({ type: 'checkbox' });

  const signDate = useFormField({
    type: 'datePicker',
    maxDate: maxDate,
    minDate: minDate,
  });
  const [authorizedUserName, setAuthorizedUserName] = useState<string>('');

  const authorizedUserFullName = useControlledField({
    type: 'text',
    value: authorizedUserName,
    onChange: (value) => {
      setAuthorizedUserName(value);
    },
    validator: ({ value, active }: ValidatorFieldState<'text'>) => {
      if (!active) {
        if (!validateFullNameRegex(value)) {
          const msg = t('Please enter your Full Name.');
          return msg;
        } else {
          setAuthorizedUserName(value);
        }
      }
      return '';
    },
  });

  return (
    <>
      <div /* need to use isPdf thats why  keeping it here */
        css={css`
          margin-left: ${!isPdf && theme.spacing(2.5)};
          margin-top: ${theme.spacing(2.5)};
          display: flex;
        `}
      >
        <Text css={{ textAlign: 'justify' }}>
          <CheckboxField
            name='agreement'
            css={{ marginLeft: theme.spacing(0.8) }}
            label={
              <Text
                css={
                  isPdf &&
                  css`
                    margin-left: ${theme.spacing(0.5)};
                  `
                }
              >
                {t('loaVerifyMessage')}
              </Text>
            }
            {...checkboxValue}
          />

          {t('loaVerifyMessageExtended')}
        </Text>
      </div>

      <div css={authorizationStyle}>
        <div css={{ display: 'flex' }}>
          <Text weight='bold' css={{ width: '300px' }}>
            {t('Authorized Signature:')}
          </Text>
          <FormRow>
            <TextField
              css={{ width: '236px' }}
              {...authorizedUserFullName}
              label={t('Authorized Signature')}
              disabled={!checkboxValue.value}
              name='authorizedUserFullName'
            />
          </FormRow>
        </div>
        <div css={{ display: 'flex' }}>
          <Text weight='bold' css={{ width: '300px' }}>
            {t('Date:')}
          </Text>
          <FormRow>
            <DatePickerField disabled={!checkboxValue.value} {...signDate} name='signDate' label='Date' />
          </FormRow>
        </div>
      </div>

      <div css={signButton}>
        <div style={{ width: '160px' }}>
          <PrimaryButton
            onClick={() => handleSignedData(authorizedUserName, signDate.value)}
            disabled={handleDisableButton(signDate.value, authorizedUserName, checkboxValue.value)}
          >
            {t('SIGN')}
          </PrimaryButton>
        </div>
      </div>
    </>
  );
};
const signButton = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const authorizationStyle = css`
  box-shadow: ${theme.shadows.light};
  margin: ${theme.spacing(3.5)};
  padding: ${theme.spacing(2.5)};
  border-radius: ${theme.borderRadius.medium};
`;
