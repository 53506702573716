import { FieldConfigUnion, FormConfig, useForm } from '@frontend/design-system';
import { ComposerSectionProps } from '../types';
import { useComposerSection } from './use-composer-section';

export const useSettingsForm = <T extends ComposerSectionProps>(fields: {
  [K in Exclude<keyof T, 'type'>]?: FieldConfigUnion & { value?: T[K] };
}) => {
  const { setProps, props } = useComposerSection<T>();
  const formConfig = fields as FormConfig;
  const updatedFields: FormConfig = {};

  for (const key in formConfig) {
    updatedFields[key] = {
      ...(formConfig[key] as any),
      value: formConfig[key]?.value ? formConfig[key]?.value : (props as any)[key],
    };
  }

  const form = useForm({
    fields: updatedFields,
  });

  const getFieldProps = (fieldName: Exclude<keyof T, 'type'>) => {
    const fieldProps = form.getFieldProps(fieldName as string);
    return {
      ...fieldProps,
      onChange: (e: any) => {
        fieldProps.onChange(e);
        setProps((props) => (props[fieldName] = e.target?.value ?? e.value));
      },
      name: fieldName,
    } as any; // TODO: Fix this!
  };

  return { getFieldProps };
};
