import { BASE_URL_ANALYTICS } from '@frontend/api-analytics';
import { http } from '@frontend/fetch';
import { useTranslation } from '@frontend/i18n';
import { useScopedQuery } from '@frontend/scope';
import { useAlert } from '@frontend/design-system';
import { queryKeys } from '../../../query-keys';

type CallType = 'inbound' | 'outbound';
type Result = 'abandoned' | 'answered' | 'forwarded' | 'missed';
type SourceType = 'current_patient' | 'unknown';

type Response = {
  CallType: CallType[];
  Result: Result[];
  SourceType: SourceType[];
};

const api = async () => {
  const response = await http.get<{ data: Response }>(`${BASE_URL_ANALYTICS}/phonereports/v2/getfilters`);
  return response.data;
};

export const useFetchPhoneFiltersOptions = () => {
  const alert = useAlert();
  const { t } = useTranslation('analytics');

  const { data: filterOptionsValues, isLoading } = useScopedQuery({
    queryKey: queryKeys.phoneFilterOptions(),
    queryFn: api,
    onError: () => {
      alert.error(t('Unable to load the filters options'));
    },
    retry: false,
    refetchOnWindowFocus: false,
    staleTime: 5 * 60 * 1000, // 5 minutes
  });

  return {
    filterOptionsValues,
    isLoading,
  };
};
