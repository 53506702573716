import { useEffect, useState, useMemo, useCallback } from 'react';
import { css } from '@emotion/react';
import { useQueryClient } from 'react-query';
import { PhoneSyncApi, queryDevices, queryKeys } from '@frontend/api-phone-sync';
import { Chat } from '@frontend/chat';
import { useTranslation } from '@frontend/i18n';
import { useLocationDataShallowStore } from '@frontend/location-helpers';
import { usePhoneConfigShallowStore } from '@frontend/phone-config';
import {
  DeviceSelectModal,
  LosingAccessModal,
  PhoneSyncButton,
  PhoneSyncModal,
  usePhonePairSuccessHandler,
  usePhoneSyncControl,
  usePhoneSyncMutations,
} from '@frontend/phone-sync';
import { usePopupBarManager } from '@frontend/popup-bar';
import { useAppScopeStore, type OrgIdMap } from '@frontend/scope';
import { useContactPanelStore } from '@frontend/shared';
import { useSlidePanelStore } from '@frontend/slide-panel';
import { useTeamChatStore } from '@frontend/team-chat';
import { useSoftphoneManager } from '@frontend/weave-softphone-manager';
import { theme } from '@frontend/theme';
import {
  Heading,
  useFormField,
  Text,
  ChecklistField,
  TextLink,
  useModalControl,
  RadioField,
  BackIcon,
  CheckIcon,
  BannerNotification,
  SpinningLoader,
  useAlert,
  Button,
} from '@frontend/design-system';
import { useConfigureLocation } from '../../utils/configure-location';
import { useLocationPickerShallowStore } from '../use-location-picker';
import { SoftphoneIsConnectedTooltip, SoftphoneCallActiveError, usePhoneConfigInfo } from './shared';

/**
 * DO NOT add any side effects to this. This should remain a pure component.
 */
export const Picker = ({
  closeModal,
  searchTerm,
  clearSearch,
  onClickApply,
  onClickOrgRadio,
  onClickLocationCheckbox,
  onApplyError,
  selectedLocationIds,
  selectedOrgId,
  orgIdMap,
  getLocationName,
  renderOrgInfo,
  renderLocationInfo,
  renderBannerInfo,
}: {
  closeModal?: () => void;
  searchTerm: string;
  clearSearch: () => void;
  onClickApply: ({ locationIds, orgId }: { locationIds: string[]; orgId: string }) => Promise<void>;
  onClickOrgRadio?: ({ orgId }: { orgId: string }) => void;
  onClickLocationCheckbox?: ({ locationId }: { locationId: string }) => void;
  onApplyError?: (error: unknown) => void;
  selectedLocationIds: string[];
  selectedOrgId: string;
  orgIdMap: OrgIdMap;
  getLocationName: (orgId: string) => string;
  renderOrgInfo?: (orgId: string) => JSX.Element | null;
  renderLocationInfo?: (locationId: string) => JSX.Element | null;
  renderBannerInfo?: () => JSX.Element | null;
}) => {
  const { t } = useTranslation('base');

  const [filterLoading, setFilterLoading] = useState(false);
  const { isLocationsView, setIsLocationsView } = useLocationPickerShallowStore(
    'isLocationsView',
    'setIsLocationsView'
  );

  const orgIds = useMemo(() => Object.keys(orgIdMap), [orgIdMap]);
  const hasOnlyOneOrg = orgIds?.length === 1;
  const [inFlightOrgId, setInFlightOrgId] = useState(selectedOrgId);
  const [inFlightLocationIds, setInFlightLocationIds] = useState(selectedLocationIds);

  const orgRadioFieldProps = useFormField({ type: 'radio', value: inFlightOrgId }, [inFlightOrgId]);
  const locationsChecklistFieldProps = useFormField({ type: 'checklist', value: inFlightLocationIds }, [
    inFlightLocationIds,
  ]);

  useEffect(() => {
    const selectedOrgLocations = orgIdMap?.[selectedOrgId]?.locations;
    setIsLocationsView(!!selectedOrgId && selectedOrgLocations?.length > 1);
  }, []);

  const handleOrgChange = useCallback(
    (orgRadioFieldValue: string) => {
      const newOrgId = orgRadioFieldValue;
      onClickOrgRadio?.({ orgId: newOrgId });
      if (inFlightOrgId !== newOrgId) {
        const selectedOrgLocations = orgIdMap?.[newOrgId]?.locations;
        setInFlightOrgId(newOrgId);

        const shouldUseSelectedLocationIds = selectedOrgId === newOrgId;
        const locationIds = shouldUseSelectedLocationIds
          ? selectedLocationIds
          : selectedOrgLocations?.map((location) => location['locationID']) ?? [];

        setInFlightLocationIds(locationIds);
      }
    },
    [inFlightOrgId, orgIdMap, selectedOrgId, selectedLocationIds]
  );

  useEffect(() => {
    if (inFlightLocationIds.length !== locationsChecklistFieldProps.value.length) {
      setInFlightLocationIds(locationsChecklistFieldProps.value);
    }
  }, [locationsChecklistFieldProps.value]);

  const handleApplySelection = async () => {
    try {
      setFilterLoading(true);
      await onClickApply({
        locationIds: inFlightLocationIds,
        orgId: inFlightOrgId,
      });
      closeModal?.();
    } catch (error) {
      if (onApplyError) {
        onApplyError(error);
      } else {
        console.error(error);
      }
    } finally {
      setFilterLoading(false);
    }
  };

  const filteredOrgs = useMemo(() => {
    if (!searchTerm) {
      return orgIds;
    }

    return orgIds?.filter((orgId) => {
      const orgName = getLocationName(orgId)?.toLowerCase();
      return orgName?.includes(searchTerm);
    });
  }, [orgIds, searchTerm, getLocationName]);

  const filteredLocations = useMemo(() => {
    if (!searchTerm) {
      return orgIdMap?.[inFlightOrgId]?.locations;
    }
    return orgIdMap?.[inFlightOrgId]?.locations.filter((location) => {
      const locationName = location.name?.toLowerCase();
      return locationName?.includes(searchTerm);
    });
  }, [orgIdMap, inFlightOrgId, searchTerm]);

  const returnLocationSelectedString = () => {
    if (inFlightLocationIds.length < orgIdMap?.[inFlightOrgId]?.locations.length) {
      return t(`{{few}} of {{all}} selected`, {
        few: inFlightLocationIds.length,
        all: orgIdMap?.[inFlightOrgId]?.locations.length,
      });
    }
    return t(`{{locations}}  Selected`, {
      locations: orgIdMap?.[inFlightOrgId]?.locations.length,
    });
  };

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        overflow: hidden;
        flex: 1;
        margin-top: ${theme.spacing(3)};
      `}
    >
      <div
        css={css`
          display: flex;
          justify-content: space-between;
        `}
      >
        <Heading level={3}>
          {isLocationsView ? getLocationName(inFlightOrgId) ?? t('Unknown') : t('Organizations')}
        </Heading>
      </div>
      <span css={{ display: 'flex', justifyContent: 'space-between' }}>
        <Text
          size='large'
          color='subdued'
          css={css`
            margin-bottom: ${theme.spacing(2)};
          `}
        >
          {isLocationsView
            ? t(`{{count}} locations selected`, { count: inFlightLocationIds.length })
            : t(`{{count}} Organizations`, { count: orgIds.length })}
        </Text>
        {isLocationsView && (
          <SelectDeselectLink
            orgIdMap={orgIdMap}
            inFlightOrgId={inFlightOrgId}
            inFlightLocationIds={inFlightLocationIds}
            setInFlightLocationIds={setInFlightLocationIds}
          />
        )}
      </span>
      <div
        css={css`
          overflow: auto;
          flex: 1;
        `}
        data-test-id='location-picker-radio-field'
      >
        {!isLocationsView && (
          <RadioField
            {...orgRadioFieldProps}
            onChange={(e) => {
              // @ts-ignore - ds event is not properly typed
              handleOrgChange(e?.target?.value);
              orgRadioFieldProps.onChange(e);
            }}
            name='org-id'
            css={css`
              margin: ${theme.spacing(0.5, 0, 0, 0.5)};
            `}
          >
            {filteredOrgs?.map((orgIdInstance) => (
              <RadioField.Radio
                css={css`
                  align-items: flex-start;
                  width: 100%;
                  label {
                    width: 100%;
                    min-width: 0;
                  }
                `}
                value={orgIdInstance}
                key={orgIdInstance}
              >
                <div
                  css={{
                    display: 'flex',
                    alignSelf: 'flex-start',
                    marginTop: -2,
                    flex: 1,
                  }}
                  data-test-id={`org-${orgIdInstance}`}
                >
                  <div css={{ width: '100%' }}>
                    <div css={{ display: 'flex', alignItems: 'center' }}>
                      <Text
                        weight='semibold'
                        size='large'
                        css={{
                          display: 'flex',
                          margin: 0,
                          minWidth: 0,
                          marginRight: theme.spacing(2),
                          gap: theme.spacing(1),
                        }}
                      >
                        {getLocationName(orgIdInstance) || t('Unknown')}
                        {renderOrgInfo?.(orgIdInstance)}
                        {selectedOrgId === orgIdInstance && <CheckIcon color='primary' />}
                      </Text>
                      <Text size='small' color='light' css={{ margin: 0, flexShrink: 0, marginLeft: 'auto' }}>
                        {inFlightOrgId === orgIdInstance
                          ? returnLocationSelectedString()
                          : `${orgIdMap?.[orgIdInstance]?.locations.length} ${t('Locations', {
                              count: orgIdMap?.[orgIdInstance]?.locations.length,
                            })}`}
                      </Text>
                    </div>
                    {orgIdInstance === inFlightOrgId && orgIdMap?.[inFlightOrgId]?.locations.length > 1 && (
                      <TextLink
                        size='medium'
                        onClick={() => {
                          setIsLocationsView(true);
                          if (searchTerm) {
                            clearSearch();
                          }
                        }}
                      >
                        {t('Refine Selection') + ' >'}
                      </TextLink>
                    )}
                  </div>
                </div>
              </RadioField.Radio>
            ))}
          </RadioField>
        )}
        {isLocationsView && (
          <ChecklistField
            name='multi-location'
            label=''
            {...locationsChecklistFieldProps}
            css={{ padding: theme.spacing(0.5) }}
            onChange={(e) => {
              locationsChecklistFieldProps.onChange(e);
              onClickLocationCheckbox?.({
                // @ts-ignore - ds event is not properly typed
                locationId: e.value,
              });
            }}
          >
            {filteredLocations?.map((location) => {
              return (
                <ChecklistField.Option name={location.locationID} key={location.locationID}>
                  <div
                    css={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: theme.spacing(1),
                      color: theme.font.colors.default,
                      fontWeight: locationsChecklistFieldProps.value.includes(location.locationID)
                        ? theme.font.weight.semibold
                        : theme.font.weight.regular,
                    }}
                  >
                    <span>{location.name}</span>
                    {renderLocationInfo?.(location.locationID)}
                  </div>
                </ChecklistField.Option>
              );
            })}
          </ChecklistField>
        )}
      </div>
      {!inFlightLocationIds.length && (
        <BannerNotification message={t('Please select at least one location.')} status='info' />
      )}
      {renderBannerInfo?.()}
      <section
        css={css`
          display: flex;
          justify-content: end;
          gap: ${theme.spacing(2)};
          padding-top: ${theme.spacing(1)};
        `}
      >
        {isLocationsView && !hasOnlyOneOrg && (
          <TextLink
            size='medium'
            weight='bold'
            onClick={() => {
              setIsLocationsView(false);
              if (searchTerm) {
                clearSearch();
              }
            }}
            css={{ display: 'flex', alignItems: 'center', gap: 4, marginRight: 'auto' }}
          >
            <BackIcon size={16} /> <span>{t('Select An Organization')}</span>
          </TextLink>
        )}

        <Button
          variant='secondary'
          size='large'
          onClick={() => {
            setInFlightLocationIds(selectedLocationIds);
            setInFlightOrgId(selectedOrgId);
            setIsLocationsView(!!selectedOrgId && orgIdMap?.[selectedOrgId]?.locations.length > 1);
            closeModal?.();
          }}
        >
          {t('Close')}
        </Button>
        {filterLoading ? (
          <div css={{ width: 112, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <SpinningLoader size='medium' />
          </div>
        ) : (
          <Button
            size='large'
            disabled={inFlightLocationIds.length === 0}
            onClick={handleApplySelection}
            trackingId='locpick-2.0-tray-button-apply-filter-unprivileged'
          >
            {t(`Apply Filter`)}
          </Button>
        )}
      </section>
    </div>
  );
};

const ERRORS = {
  SOFTPHONE_CALL_ACTIVE: 'softphone call active',
  OPENING_DEVICE_SELECT_MODAL: 'opening device select modal',
};

const WrappedPicker = ({
  closeModal,
  searchTerm,
  clearSearch,
}: {
  closeModal?: () => void;
  searchTerm: string;
  clearSearch: () => void;
}) => {
  const { t } = useTranslation('base');
  const deviceSelectModal = useModalControl();
  const losingAccessModal = useModalControl();
  const { setMultiSelectedIds } = useLocationDataShallowStore('setMultiSelectedIds');
  const {
    setSelectedLocationIds,
    selectedLocationIds,
    accessibleLocationData,
    orgIdMap,
    selectedOrgId,
    setSelectedOrgId,
    getLocationName,
    setSelectedParentsIds,
  } = useAppScopeStore();
  const { configureLocationData } = useConfigureLocation();
  const alerts = useAlert();

  // Revamped Team chat related, for closing the tray before switching location
  const { setTrayOpen, isTrayOpen } = useTeamChatStore(['setTrayOpen', 'isTrayOpen']);
  const { setPopupList } = usePopupBarManager<Chat>();

  const handleLocationChange = async ({ locationIds, orgId }: { locationIds: string[]; orgId: string }) => {
    const symDiff = locationIds
      .filter((x) => !selectedLocationIds.includes(x))
      .concat(selectedLocationIds.filter((x) => !locationIds.includes(x)));

    if (orgId === selectedOrgId && symDiff.length === 0) {
      console.log('No changes');
      return;
    }

    // reset popup list to close any open chat windows
    setPopupList([]);
    if (isTrayOpen) {
      setTrayOpen(false);
    }
    setMultiSelectedIds(locationIds);
    setSelectedOrgId(orgId);
    setSelectedLocationIds(locationIds);
    setSelectedParentsIds(orgIdMap?.[orgId]?.parents?.map((parent) => parent.locationID) ?? []);
    useContactPanelStore.reset();
    useSlidePanelStore.reset();

    configureLocationData({
      // This is to satisfy the old single location paradigm
      newLocationId: locationIds[0],
      onSuccess() {
        if (locationIds[0]) {
          alerts.success(
            t('Successfully switched to {{name}}', {
              name: getLocationName(orgId),
              interpolation: { escapeValue: false },
            })
          );
        }
      },
      onError() {
        alerts.error({
          autoDismissAfter: 10000,
          message: t(
            'Failed to load location information. Please make sure you have no unpaid bills or contact support at (888) 579-5668 option 2, then option 3'
          ),
        });
      },
    });
  };

  // Phones related
  const { phoneConfig } = usePhoneConfigShallowStore('phoneConfig', 'setPhoneConfig');
  const { disconnectDevice } = usePhoneSyncMutations();
  const { modalProps, buttonProps } = usePhoneSyncControl();
  const onSyncSuccess = usePhonePairSuccessHandler()(selectedLocationIds);

  /// Softphone related
  const [showActivePhoneCallError, setShowActivePhoneCallError] = useState(false);
  const isSoftphoneCallActive = useSoftphoneManager((ctx) => ctx.hasActiveCalls);

  const phoneConfigInfo = usePhoneConfigInfo({ phoneConfig, accessibleLocationData });

  // Get whether or not we can update the location filters when a softphone call is active.
  const getCanUpdateWhenSoftphoneActive = ({ orgId, locationIds }: { orgId: string; locationIds: string[] }) => {
    if (!isSoftphoneCallActive || !phoneConfigInfo?.isSoftphone) return true;

    const softphoneOrgIdInFlight = orgId === phoneConfigInfo.orgId;
    const softphoneLocationIdInFlight = locationIds.includes(phoneConfigInfo.locationId);

    // We can update if the orgId & locationId associated w/ the softphone are 'in flight'
    return softphoneOrgIdInFlight && softphoneLocationIdInFlight;
  };

  const queryClient = useQueryClient();
  const [hasFutureAvailableDevices, setHasFutureAvailableDevices] = useState(false);

  const onClickApply = async ({ locationIds, orgId }: { locationIds: string[]; orgId: string }) => {
    if (!getCanUpdateWhenSoftphoneActive({ orgId, locationIds })) {
      setShowActivePhoneCallError(true);
      // do we even need this? We can just disable the button
      // handleSoftphoneActiveRevertFilters();

      return Promise.reject(ERRORS.SOFTPHONE_CALL_ACTIVE);
    }

    // Get the new list of devices that are available within the selected location ids
    const devices = await queryClient.fetchQuery({
      queryKey: [locationIds, ...queryKeys.listDevices()],
      queryFn: () => queryDevices(locationIds),
    });

    // These are devices that are currently in context (available within the selected location ids)
    const availableDevices = devices?.filter((device) => device.availableInContext);
    const isCurrentDeviceWithinContext =
      phoneConfig && availableDevices?.some((device) => phoneConfig.sipProfileId === device.sipProfileId);

    const movingAwayFromCurrentConfig = phoneConfig && !isCurrentDeviceWithinContext;

    const hasAvailableDevices = availableDevices ? availableDevices.length > 0 : false;
    setHasFutureAvailableDevices(hasAvailableDevices);
    // The SelectDeviceModal should open if the location with the connected device is deselected (or if no device is connected),
    // and the newly selected location has available devices to be connected.

    if (movingAwayFromCurrentConfig) {
      if (hasAvailableDevices) {
        deviceSelectModal.openModal();
      } else {
        // No available devices to connect to - warn the user
        losingAccessModal.openModal();
      }
      return Promise.reject(ERRORS.OPENING_DEVICE_SELECT_MODAL);
    }

    handleLocationChange({ locationIds, orgId });
  };

  const onApplyError = (error: unknown) => {
    if (error === ERRORS.SOFTPHONE_CALL_ACTIVE) {
      return;
    }

    if (error === ERRORS.OPENING_DEVICE_SELECT_MODAL) {
      return;
    }

    console.error(error);
  };

  const onClickOrgRadio = () => {
    setShowActivePhoneCallError(false);
  };

  const renderOrgInfo = (orgId: string) => {
    if (phoneConfigInfo?.orgId === orgId) {
      return <SoftphoneIsConnectedTooltip deviceName={phoneConfig?.name} />;
    }

    return null;
  };

  const renderLocationInfo = (locationId: string) => {
    if (phoneConfigInfo?.locationId === locationId) {
      return <SoftphoneIsConnectedTooltip deviceName={phoneConfig?.name} />;
    }

    return null;
  };

  const renderBannerInfo = () => {
    if (showActivePhoneCallError && phoneConfigInfo) {
      return <SoftphoneCallActiveError locationName={accessibleLocationData[phoneConfigInfo.locationId].name || ''} />;
    }

    return null;
  };

  return (
    <>
      <Picker
        closeModal={closeModal}
        searchTerm={searchTerm}
        clearSearch={clearSearch}
        selectedLocationIds={selectedLocationIds}
        selectedOrgId={selectedOrgId}
        orgIdMap={orgIdMap}
        getLocationName={getLocationName}
        onClickApply={onClickApply}
        onApplyError={onApplyError}
        onClickOrgRadio={onClickOrgRadio}
        renderOrgInfo={renderOrgInfo}
        renderLocationInfo={renderLocationInfo}
        renderBannerInfo={renderBannerInfo}
      />
      {hasFutureAvailableDevices ? (
        <>
          <DeviceSelectModal
            variant='location-picker'
            locationIds={selectedLocationIds}
            deviceSelectModal={deviceSelectModal}
            closeModal={() => {
              handleLocationChange({ orgId: selectedOrgId, locationIds: selectedLocationIds });
              closeModal?.();
            }}
            pairDeviceButton={
              <PhoneSyncButton
                {...buttonProps}
                variant='tertiary'
                onClick={() => {
                  deviceSelectModal.closeModal();
                }}
              />
            }
          />
          <PhoneSyncModal
            {...modalProps}
            locationIds={selectedLocationIds}
            onBack={() => {
              deviceSelectModal.openModal();
              modalProps.onClose();
            }}
            onSyncSuccess={(config) => {
              onSyncSuccess(config);
              handleLocationChange({ orgId: selectedOrgId, locationIds: selectedLocationIds });
              closeModal?.();
            }}
            syncFn={PhoneSyncApi.pairDevice}
          />
        </>
      ) : (
        <>
          <LosingAccessModal
            modal={losingAccessModal}
            onContinue={() => {
              handleLocationChange({ orgId: selectedOrgId, locationIds: selectedLocationIds });
              disconnectDevice();
              modalProps.onClose();
              closeModal?.();
            }}
            pairDeviceButton={
              <PhoneSyncButton
                {...buttonProps}
                variant='secondary'
                btnLabel={t('Pair New Device')}
                onClick={() => {
                  losingAccessModal.closeModal();
                }}
              />
            }
          />
          <PhoneSyncModal
            {...modalProps}
            locationIds={selectedLocationIds}
            onBack={() => {
              modalProps.onClose();
            }}
            onSyncSuccess={(config) => {
              onSyncSuccess(config);
              handleLocationChange({ orgId: selectedOrgId, locationIds: selectedLocationIds });
              closeModal?.();
            }}
            syncFn={PhoneSyncApi.pairDevice}
          />
        </>
      )}
    </>
  );
};

/**
 * This picker has no side effects, and is to be used for the location picker page only
 */
const SimpleWrappedPicker = ({
  closeModal,
  searchTerm,
  clearSearch,
}: {
  closeModal?: () => void;
  searchTerm: string;
  clearSearch: () => void;
}) => {
  const { t } = useTranslation('base');
  const { setMultiSelectedIds } = useLocationDataShallowStore('setMultiSelectedIds');
  const {
    setSelectedLocationIds,
    selectedLocationIds,
    orgIdMap,
    selectedOrgId,
    setSelectedOrgId,
    getLocationName,
    setSelectedParentsIds,
  } = useAppScopeStore();
  const { configureLocationData } = useConfigureLocation();
  const alerts = useAlert();

  const handleLocationChange = async ({ locationIds, orgId }: { locationIds: string[]; orgId: string }) => {
    // reset popup list to close any open chat windows
    setMultiSelectedIds(locationIds);
    setSelectedOrgId(orgId);
    setSelectedLocationIds(locationIds);
    setSelectedParentsIds(orgIdMap?.[orgId]?.parents?.map((parent) => parent.locationID) ?? []);
    useContactPanelStore.reset();
    useSlidePanelStore.reset();

    configureLocationData({
      // This is to satisfy the old single location paradigm
      newLocationId: locationIds[0],
      onSuccess() {
        if (locationIds[0]) {
          alerts.success(
            t('Successfully switched to {{name}}', {
              name: getLocationName(orgId),
              interpolation: { escapeValue: false },
            })
          );
        }
      },
      onError() {
        alerts.error({
          autoDismissAfter: 10000,
          message: t(
            'Failed to load location information. Please make sure you have no unpaid bills or contact support at (888) 579-5668 option 2, then option 3'
          ),
        });
      },
    });
  };

  const onClickApply = async ({ locationIds, orgId }: { locationIds: string[]; orgId: string }) => {
    handleLocationChange({ locationIds, orgId });
  };

  return (
    <Picker
      closeModal={closeModal}
      searchTerm={searchTerm}
      clearSearch={clearSearch}
      selectedLocationIds={selectedLocationIds}
      selectedOrgId={selectedOrgId}
      orgIdMap={orgIdMap}
      getLocationName={getLocationName}
      onClickApply={onClickApply}
    />
  );
};

export const UnprivilegedMultiLocationSelector = ({
  closeModal,
  searchTerm,
  clearSearch,
  simple = false,
}: {
  closeModal?: () => void;
  searchTerm: string;
  clearSearch: () => void;
  simple?: boolean;
}) => {
  return simple ? (
    <SimpleWrappedPicker closeModal={closeModal} searchTerm={searchTerm} clearSearch={clearSearch} />
  ) : (
    <WrappedPicker closeModal={closeModal} searchTerm={searchTerm} clearSearch={clearSearch} />
  );
};

type SelectDeselectLinkProps = {
  orgIdMap: OrgIdMap;
  inFlightOrgId: string;
  inFlightLocationIds: string[];
  setInFlightLocationIds: (locationIds: string[]) => void;
};

const SelectDeselectLink = ({
  orgIdMap,
  inFlightOrgId,
  inFlightLocationIds,
  setInFlightLocationIds,
}: SelectDeselectLinkProps) => {
  const { t } = useTranslation('base');

  const selectedLocations = inFlightLocationIds;

  const currentOrgLocationIds = useMemo(
    () => orgIdMap?.[inFlightOrgId]?.locations.map((location) => location['locationID']),
    [orgIdMap, inFlightOrgId]
  );

  const showSelectAllButton = useMemo(() => selectedLocations.length === 0, [selectedLocations]);

  const handleClick = useCallback(() => {
    if (showSelectAllButton) {
      setInFlightLocationIds(currentOrgLocationIds);
    } else {
      setInFlightLocationIds([]);
    }
  }, [showSelectAllButton, setInFlightLocationIds, currentOrgLocationIds, inFlightLocationIds, inFlightOrgId]);

  return (
    <TextLink
      css={css`
        margin-bottom: ${theme.spacing(2)};
      `}
      onClick={handleClick}
      size='medium'
      weight='bold'
    >
      {showSelectAllButton
        ? `${t('Select')} (${
            currentOrgLocationIds.includes(inFlightOrgId)
              ? currentOrgLocationIds.length - 1
              : currentOrgLocationIds.length
          })`
        : `${t('Deselect')} (${selectedLocations.length})`}
    </TextLink>
  );
};
