import { useMemo, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { last } from 'lodash-es';
import {
  StaticSettingsRoutes,
  settingsRouteLookup,
  useInterRouter,
  useSettingsNavigate,
  useSettingsSearchAccessRoutePaths,
} from '@frontend/settings-routing';
import { theme } from '@frontend/theme';
import {
  useFormField,
  TextField,
  SearchIcon,
  ComboboxBase,
  usePopoverMenu,
  Text,
  PopoverMenuItem,
  CaretRightIconSmall,
  styles,
} from '@frontend/design-system';

export const SettingsSearch = () => {
  const { navigate } = useSettingsNavigate();
  const { isSettingsMenuOpen, setIsSettingsMenuOpen } = useInterRouter(['isSettingsMenuOpen', 'setIsSettingsMenuOpen']);
  const sectionRef = useRef<HTMLElement | null>(null);
  const { onChange: searchFieldOnChange, onFocus, onBlur, ...searchParams } = useFormField({ type: 'text' });
  const { getMenuProps, getItemProps, getTriggerProps, open, close, activeIndex, setActiveIndex, refs } =
    usePopoverMenu<HTMLInputElement>({
      placement: 'bottom',
      middlewareOptions: {
        offset: {
          mainAxis: 9,
          crossAxis: -12,
        },
      },
      interactionOptions: { listNavigation: { virtual: true }, typeahead: { enabled: false } },
    });
  const [focused, setFocused] = useState(false);

  const closeSettingsMenu = () => {
    if (isSettingsMenuOpen) {
      setIsSettingsMenuOpen(false);
    }
  };

  const searchedRoutePathsPreFiltered = useMemo(
    () => settingsRouteLookup.searchKeyword(searchParams.value).map((item) => item.item),
    [searchParams.value]
  );

  const searchedRoutePaths = useSettingsSearchAccessRoutePaths(searchedRoutePathsPreFiltered);

  const { ref, ...triggerProps } = getTriggerProps({
    onKeyDown: (event) => {
      if (event.key === 'Enter' && activeIndex != null) {
        navigate({
          to: searchedRoutePaths[activeIndex]?.path.slice(0, -1).replace('settings/', '') as StaticSettingsRoutes,
        });
        setActiveIndex(null);
        closeSettingsMenu();
        close();
      }
    },
    onChange: (e) => {
      searchFieldOnChange(e);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore our change events are still messed up :(
      if (e.target.value) {
        open();
      } else {
        close();
      }
    },
  });

  const getComboboxListContainerWidth = sectionRef.current ? sectionRef.current?.getBoundingClientRect().width : 200;
  const searchInputTextColor = focused ? theme.colors.neutral90 : theme.colors.neutral20;

  return (
    <section
      css={[
        css`
          padding: ${theme.spacing(3)};
          border-bottom: 1px solid ${theme.colors.neutral60};
        `,
      ]}
    >
      <ComboboxBase
        menuProps={getMenuProps()}
        menuStyles={css`
          width: ${getComboboxListContainerWidth}px;
          border-radius: 0 0 ${theme.borderRadius.small} ${theme.borderRadius.small};
          padding-bottom: 0;
          max-height: 400px !important;
          overflow-y: auto;
          padding-top: 0;
        `}
        Input={
          <section ref={sectionRef}>
            <TextField
              aria-autocomplete='list'
              role='combobox'
              startAdornment={<SearchIcon />}
              {...triggerProps}
              containerCss={css`
                background: ${focused ? theme.colors.white : theme.colors.neutral80};
                border-radius: ${theme.borderRadius.small};
                transition: 0.2s ease;
                caret-color: ${searchInputTextColor};

                svg {
                  fill: ${searchInputTextColor};
                }

                & input {
                  color: ${searchInputTextColor} !important;
                }
                & input::placeholder {
                  color: ${searchInputTextColor};
                }
              `}
              fieldComponentProps={{ ref: refs.setReference }}
              name=''
              label=''
              placeholder='Search'
              onFocus={(e) => {
                setFocused(true);
                onFocus(e);
              }}
              onBlur={(e) => {
                setFocused(false);
                onBlur(e);
              }}
              {...searchParams}
            />
          </section>
        }
      >
        {searchedRoutePaths.map((item, index) => {
          return (
            <PopoverMenuItem
              key={item.path}
              {...getItemProps({
                index,
                onClick: () => {
                  navigate({ to: item.path.slice(0, -1).replace('settings/', '') as StaticSettingsRoutes });
                  setActiveIndex(null);
                  closeSettingsMenu();
                  close();
                },
              })}
              css={css`
                height: auto;
                padding: ${theme.spacing(3, 2)};
                border-bottom: 1px solid ${theme.colors.neutral10};
              `}
            >
              <div
                css={css`
                  display: flex;
                  flex-flow: row;
                  justify-content: space-between;
                  gap: ${theme.spacing(1)};
                  align-items: center;
                  width: 100%;
                  cursor: pointer;
                `}
              >
                <SettingsSearchItem item={item} />
              </div>
            </PopoverMenuItem>
          );
        })}
      </ComboboxBase>
    </section>
  );
};

const SettingsSearchBreadcrumbItem = ({ crumb, isLastItem }: { crumb: string; isLastItem: boolean }) => (
  <>
    <span
      css={css`
        color: ${isLastItem ? 'black' : theme.colors.neutral50};
        flex: 0 1 auto !important;
      `}
      aria-hidden='true'
    >
      {crumb}
    </span>
    {!isLastItem && <CaretRightIconSmall size={8} aria-hidden='true' />}
  </>
);

export const SettingsSearchItem = ({ item }: { item: { labelPath: string; path: string } }) => {
  const breadcrumb = item.labelPath.split('/');

  return (
    <>
      <div
        role='text'
        aria-label={`Path: ${item.labelPath}`}
        css={css`
          width: 100%;
          position: relative;
        `}
      >
        <Text textAlign='left' css={styles.truncate}>
          {last(breadcrumb)}
        </Text>
        <div
          css={css`
            display: flex;
            align-items: center;
            width: 100%;
            gap: ${theme.spacing(1)};
            font-size: 12px;
            justify-content: flex-start;
          `}
        >
          {breadcrumb.map((crumb, idx) => (
            <SettingsSearchBreadcrumbItem key={idx} crumb={crumb} isLastItem={idx + 1 === breadcrumb.length} />
          ))}
        </div>
      </div>
    </>
  );
};

export default SettingsSearchItem;
