import { css } from '@emotion/react';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Text, useTooltip } from '@frontend/design-system';

interface GroupNameProps {
  highestValueColor?: string;
  highestValueText?: string | null;
  title: string;
}

const truncateMiddle = (text: string, maxLength: number): string => {
  if (text.length <= maxLength) {
    return text;
  }

  const halfLength = Math.floor(maxLength / 2);
  return `${text.slice(0, halfLength)}...${text.slice(-halfLength)}`;
};

export const GroupName = ({ highestValueColor, highestValueText, title }: GroupNameProps) => {
  const { Tooltip, triggerProps, tooltipProps } = useTooltip({ placement: 'top' });
  const truncatedTitle = truncateMiddle(title, 24);

  return (
    <div>
      <Text {...triggerProps} size='medium' weight='bold'>
        {truncatedTitle}
      </Text>

      {highestValueText && (
        <div css={highestCrownStyle} style={{ color: highestValueColor }}>
          <Icon name='crown-small' />
          <Text size='small' style={{ color: 'inherit' }} weight='semibold'>
            {highestValueText}
          </Text>
        </div>
      )}

      {truncatedTitle?.includes('...') && (
        <Tooltip {...tooltipProps}>
          <Text size='medium' color='white'>
            {title}
          </Text>
        </Tooltip>
      )}
    </div>
  );
};

const highestCrownStyle = css`
  align-items: center;
  display: flex;
  gap: ${theme.spacing(0.5)};
`;
