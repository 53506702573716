import { ComponentProps } from 'react';
import { useTranslation } from '@frontend/i18n';
import { ColorSetting, CornersSetting, Setting } from '.';

type Props = {
  backgroundColorFieldProps: Omit<ComponentProps<typeof ColorSetting>, 'label' | 'isLast'>;
  cornersFieldProps: Omit<ComponentProps<typeof CornersSetting>, 'isLast'>;
  isLast?: boolean;
  textColorFieldProps: Omit<ComponentProps<typeof ColorSetting>, 'label' | 'isLast'>;
};

export const ButtonSetting = ({ backgroundColorFieldProps, cornersFieldProps, isLast, textColorFieldProps }: Props) => {
  const { t } = useTranslation('content-composer-settings');

  return (
    <Setting title={t('Button')} isLast={isLast}>
      <CornersSetting {...cornersFieldProps} />
      <ColorSetting {...backgroundColorFieldProps} label={t('Button Color')} />
      <ColorSetting {...textColorFieldProps} label={t('Text Color')} isLast />
    </Setting>
  );
};
