import { useOrgData } from '@frontend/get-org-data';
import { MultiLocationSelector } from '@frontend/multi-location-selector';
import { theme } from '@frontend/theme';
import { SearchField } from '@frontend/design-system';
import { LocationIdsFieldProps, SearchFieldProps } from './types';

export const ImageGalleryHeader = ({
  locationIdsFieldProps,
  searchFieldProps,
  searchSize = 'small',
  showLocation,
  showSearch = false, // TODO: remove the showSearch prop and update the below logic once the API supports searching
  showSort,
}: {
  locationIdsFieldProps: LocationIdsFieldProps;
  searchFieldProps: SearchFieldProps;
  searchSize?: 'small' | 'large';
  showLocation?: boolean;
  showSearch?: boolean;
  showSort?: boolean;
}) => {
  const { locationIdsWithoutParent } = useOrgData();
  const showLeftContainer = showLocation || showSort;

  // TODO: remove this condition once the API supports searching
  if (!showLocation && !showSearch) return null;

  return (
    <div
      css={{
        display: 'flex',
        justifyContent: showLeftContainer ? 'space-between' : 'flex-end',
        width: '100%',
        marginBottom: theme.spacing(2),
        flexWrap: 'wrap',
        rowGap: theme.spacing(1.5),
      }}
    >
      {showLeftContainer && (
        <div css={{ display: 'flex', columnGap: theme.spacing(1) }}>
          {showLocation && (
            <MultiLocationSelector
              accessibleLocations={locationIdsWithoutParent} // TODO: get accessible locations
              displayedLocations={locationIdsWithoutParent}
              hideLabel
              fieldProps={{
                locationIds: locationIdsFieldProps,
              }}
              labelType='count'
              trackingIdPrefix='ImageGallery'
              width='175px'
            />
          )}
          {showSort && <div>Sort</div>}
        </div>
      )}

      {/* TODO: remove the showSearch conditional once the API supports search */}
      {showSearch && (
        <div
          css={{
            width: searchSize === 'large' ? '100%' : 'unset',
            '& > div': {
              width: searchSize === 'large' ? '100%' : 300,
            },
          }}
        >
          <SearchField {...searchFieldProps} />
        </div>
      )}
    </div>
  );
};
