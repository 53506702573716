export const formatTime = (time: string, includeSeconds = false): string => {
  const splitTime = time.split(':');

  let hours = splitTime[0];
  const minutes = splitTime[1];
  const seconds = splitTime[2];
  const period = Number(hours) >= 12 ? 'pm' : 'am';

  if (Number(hours) > 12) {
    hours = String(Number(hours) - 12);
  }

  return hours + ':' + minutes + (includeSeconds ? ':' + seconds : '') + period;
};
