import { useState } from 'react';
import { useEditor } from '@craftjs/core';
import { ComposerPanel } from '../types';

export const useActivePanel = () => {
  const {
    actions: { clearEvents },
    isSettingsOpen,
  } = useEditor((state) => ({
    isSettingsOpen: state.events.selected.size > 0,
  }));

  const [isToolboxActive, setIsToolboxActive] = useState(false);
  const activePanel: ComposerPanel = isSettingsOpen ? 'settings' : isToolboxActive ? 'toolbox' : 'unset';
  const setActivePanel = (panel: Exclude<ComposerPanel, 'settings'>) => {
    clearEvents();
    setIsToolboxActive(panel === 'toolbox');
  };

  return { activePanel, setActivePanel };
};
