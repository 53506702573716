import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from '@tanstack/react-location';
import { LocationsApi } from '@frontend/api-locations';
import { authnClient } from '@frontend/auth';
import {
  clearLastVisitedPage,
  clearLoginData,
  getDecodedWeaveToken,
  getLoginData,
  getUser,
  getWeaveToken,
  isSessionTokenActive,
  isWeaveTokenActive,
  isWeaveTokenBufferActive,
  localStorageHelper,
  LOGIN_DATA_KEY,
  onWeaveTokenChange,
} from '@frontend/auth-helpers';
import { useFetch } from '@frontend/fetch';
import { useSyncShellHistory } from '@frontend/history';
import { useTranslation } from '@frontend/i18n';
import { useLocationDataShallowStore } from '@frontend/location-helpers';
import { useOnboardingRedirect } from '@frontend/onboarding';
import { useMerchant } from '@frontend/payments-hooks';
import { useInitializePhones } from '@frontend/phone';
import { useAppScopeStore } from '@frontend/scope';
import { useSettingsNavigate } from '@frontend/settings-routing';
import { useHasFeatureFlag } from '@frontend/shared';
import { sentry } from '@frontend/tracking';
import { useAlert } from '@frontend/design-system';
import { signOut } from '../helpers/sign-out';
import { getPortalRedirectRoutes } from '../providers/routes.provider/portal-redirects';
import { useConfigureLocation, determineOrgID } from '../utils/configure-location';
import { useMatchUser } from '../utils/routes/use-match-user';
import { setCookie } from '../utils/set-cookie';
import { getShellThemeFromLocalStorage } from './shell-theme-switcher/helper';
import { usePrivilegedQueryLocations } from './use-privileged-query-locations';
import { useQueryLocations } from './use-query-locations';
import { useSetLocation } from './use-set-location';

export type AppStateType =
  | {
      state: 'ONBOARDING';
      meta: {
        onboardingRedirectUrl: string;
      };
    }
  | {
      state: 'LOADING';
    }
  | {
      state: 'LOCATION_SELECTION';
    }
  | {
      state: 'INITIALIZED';
    }
  | {
      state: 'REDIRECTING';
    };

const useRegisterLocations = ({ ids }: { ids: string[] }) => {
  const { setRegisteredLocationIds } = useLocationDataShallowStore('setRegisteredLocationIds');
  const { setAccessibleLocationIds } = useAppScopeStore();
  const decodedWeaveToken = getDecodedWeaveToken();
  const user = getUser();

  const { isInitialized: isPrivilegedInitialized } = usePrivilegedQueryLocations();
  const { isInitialized } = useQueryLocations({ user: user!, locationIds: ids });
  useEffect(() => {
    if (decodedWeaveToken?.type !== 'weave') {
      setRegisteredLocationIds(ids);
      setAccessibleLocationIds(ids);
    }
  }, [ids]);

  return { isRegistered: isInitialized || isPrivilegedInitialized };
};

export const useHttpSetup = ({
  token,
  locationId,
  isSessionTokenEnabled,
}: {
  token?: string;
  locationId: string;
  isSessionTokenEnabled: boolean;
}) => {
  const [isHttpReady, setIsHttpReady] = useState(false);
  const isSignOutTriggered = useRef(false);
  const { t } = useTranslation();
  const alerts = useAlert();
  const user = useMatchUser();
  const previousLocationIdRef = useRef('');
  const orgIdRef = useRef('');
  const isUserAddedToOrg = useRef(true);
  const isSessionTokenEnabledRef = useRef(false);
  const [chaosBlockedURLS, setChaosBlockedURLS] = useState<string[]>([]);
  const [chaosAllowedURLS, setChaosAllowedURLS] = useState<string[]>([]);

  const { setAuthorizationHeader, useMiddleware, clearMiddleware, useErrorMiddleware, clearErrorMiddleware } =
    useFetch();
  const { configureLocationData } = useConfigureLocation();

  const triggerInactiveSignOut = () => {
    if (!isSignOutTriggered.current) {
      alerts.error(t('You have been signed out due to inactivity. Please sign in again.'));
      isSignOutTriggered.current = true;
      setTimeout(() => {
        clearLastVisitedPage();
        signOut();
        isSignOutTriggered.current = false;
      }, 2000);
    }
  };

  const refreshTokenMiddleware = async (req: Request) => {
    // If under test, skip this middleware
    if (import.meta.env.MODE === 'test') {
      return req;
    }

    // If this request does not need a token, just continue with the request
    if (!req.headers.has('Authorization')) {
      return req;
    }

    /**
     *
     * OKTA
     * If okta and weave are active, then follow through with request
     *
     * If okta active and weave inactive, then renew weave token
     *
     * If okta inactive and weave active - sign out
     *
     * If okta inactive and weave inactive - sign out
     *
     *
     * LEGACY
     *
     * if weave token active - follow through
     * if weave token inactive - renew
     */

    if (!authnClient.isUserAuthenticated() && !isWeaveTokenBufferActive()) {
      triggerInactiveSignOut();
    }

    // If the token has expired, refresh it and update the request header and http instance
    if (!isWeaveTokenActive() && isWeaveTokenBufferActive()) {
      try {
        const newToken = await authnClient.refreshWeaveToken();
        req.headers.set('Authorization', `Bearer ${newToken}`);
        setAuthorizationHeader(newToken);
      } catch (error) {
        alerts.error(t("You've been signed out"));
        clearLastVisitedPage();
        return signOut().then(() => req);
      }
    }

    const currAuthHeader = req.headers.get('Authorization');
    const localStorageWeaveToken = getWeaveToken();
    if (currAuthHeader !== `Bearer ${localStorageWeaveToken}`) {
      req.headers.set('Authorization', `Bearer ${localStorageWeaveToken}`);
    }

    if (!isSessionTokenActive() && orgIdRef.current !== '' && isUserAddedToOrg.current) {
      try {
        // check for this feature flag
        if (isSessionTokenEnabledRef.current) {
          authnClient
            .assureSessionToken(orgIdRef.current)
            .then((res) => {
              if (res.includes('403')) {
                //it means the user is not part of this organization so do not call session api for this organization
                isUserAddedToOrg.current = false;
              }
            })
            .catch((error) => {
              sentry.error({
                error: 'Failed to create a new session token',
                topic: 'session',
              });
              console.error('Failed to get session token', error);
            });
        }
      } catch (error) {
        sentry.error({
          error: 'Failed to create a new session token',
          topic: 'session',
        });
        console.error('Failed to get session token', error);
      }
    }
    return req;
  };

  async function handle401Error(response: Response): Promise<Response> {
    if (!response.ok) {
      if (response.status === 401) {
        triggerInactiveSignOut();
        return response;
      }
    }
    return Promise.resolve(response);
  }

  async function chaosMonkeyMiddleware(req: Request): Promise<Request> {
    // Give a 50% chance of blocking a network request
    if (Math.random() > 0.5) {
      console.error('Chaos Monkey Throwing Error', req.url);
      throw new Error('Chaos Monkey');
    }
    return req;
  }

  async function chaosMonkeyWithMemoryMiddleware(req: Request): Promise<Request> {
    // Give a 50% chance of blocking a url for the duration of the session
    // it maintains the blocked and allowed urls in state so we don't grind the network requests to a halt
    if (chaosBlockedURLS.includes(req.url) || (!chaosAllowedURLS.includes(req.url) && Math.random() > 0.5)) {
      if (!chaosBlockedURLS.includes(req.url)) {
        setChaosBlockedURLS((prev: string[]) => [...prev, req.url]);
      }
      console.error('Chaos Monkey Throwing Error', req.url);
      throw new Error('Chaos Monkey');
    } else {
      setChaosAllowedURLS((prev: string[]) => [...prev, req.url]);
    }
    return req;
  }

  // turns on chaos monkey if the feature flag is on or if the app is in dev
  function shouldUseChaosMonkey(): boolean {
    // This commented out version is how we can turn on chaos monkey for dev or local for all developers.
    // I kept it commented out because it is a bit too aggressive for most developers.

    // const isDevOrLocal =
    //     import.meta.env.VITE_WEAVE_APP_URL.startsWith('https://app.weavedev.net') ||
    //     import.meta.env.VITE_WEAVE_APP_URL.startsWith('http://localhost:3001');
    // return localStorage.getItem('chaosMonkey') === 'true' ||
    //   (isDevOrLocal && localStorage.getItem('chaosMonkey') !== 'false');

    return localStorage.getItem('chaosMonkey') === 'true';
  }

  useEffect(() => {
    if (locationId && locationId !== '' && previousLocationIdRef.current !== locationId) {
      previousLocationIdRef.current = locationId;
      LocationsApi.getLocation(locationId)
        .then((locationInfo) => {
          const newOrgId = determineOrgID(locationInfo);
          if (orgIdRef.current !== newOrgId) {
            orgIdRef.current = newOrgId;
            isUserAddedToOrg.current = true;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [locationId]);

  useEffect(() => {
    useMiddleware(refreshTokenMiddleware);
    if (shouldUseChaosMonkey()) {
      useMiddleware(
        localStorage.getItem('chaosMemory') === 'true' ? chaosMonkeyWithMemoryMiddleware : chaosMonkeyMiddleware
      );
    }
    setAuthorizationHeader(token ?? '');
    useErrorMiddleware(handle401Error);
    const clearTokenChangeEvent = onWeaveTokenChange((newToken) => {
      setAuthorizationHeader(newToken);
    });

    return () => {
      clearMiddleware();
      clearErrorMiddleware();
      clearTokenChangeEvent();
    };
  }, []);

  useEffect(() => {
    isSessionTokenEnabledRef.current = isSessionTokenEnabled;
  }, [isSessionTokenEnabled]);

  // On first load, initialize http component
  useEffect(() => {
    const lastLocationId = user ? getLoginData(user.userID)?.lastLocationIds?.[0] : undefined;
    // configure location data if an id exists
    if (lastLocationId) {
      configureLocationData({
        newLocationId: lastLocationId,
        onSuccess: () => {
          setIsHttpReady(true);
        },
        onError: () => {
          // If the location is not found, clear the login data and refresh the page
          const loginData: string | undefined = localStorageHelper.get(LOGIN_DATA_KEY);
          if (loginData) {
            // we don't want a user to be stuck in a loop of refreshing the page
            clearLoginData();
            location.reload();
          }
        },
      });
    } else {
      setIsHttpReady(true);
    }
  }, [user?.userID]);

  return isHttpReady;
};

export const useInitializeApp = (): AppStateType => {
  const { selectedLocationIdsWithParents } = useAppScopeStore();
  const decodedWeaveToken = getDecodedWeaveToken();
  const keys = Object.keys(decodedWeaveToken?.ACLS ?? {});

  const locationId = selectedLocationIdsWithParents[0];
  const isSessionTokenEnabled = useHasFeatureFlag('use-session-token');
  const weaveToken = getWeaveToken();

  const isHttpReady = useHttpSetup({ token: weaveToken, locationId, isSessionTokenEnabled });
  const { isReady: isLocationReady, parentLocationProcessed } = useSetLocation(isHttpReady);

  useSyncShellHistory();
  const { isRegistered } = useRegisterLocations({
    ids: decodedWeaveToken?.type === 'weave' ? selectedLocationIdsWithParents : keys,
  });
  useMerchant();
  useInitializePhones({ ready: isLocationReady });
  setCookie({ name: 'isWeaveCustomer', value: 'true' });
  const { shouldShowOnboardingLoader, onboardingRedirectUrl } = useOnboardingRedirect({ isLocationReady });
  const { willRedirect } = useMurderThePortalRedirect();

  useLayoutEffect(() => {
    getShellThemeFromLocalStorage();
  }, []);

  useEffect(() => {
    document.querySelectorAll('.app-loader').forEach((loader) => loader.remove());
  }, []);

  if (willRedirect) {
    return {
      state: 'REDIRECTING',
    };
  } else if (shouldShowOnboardingLoader || onboardingRedirectUrl) {
    return {
      state: shouldShowOnboardingLoader ? 'LOADING' : 'ONBOARDING',
      meta: {
        onboardingRedirectUrl,
      },
    };
  } else if (isLocationReady && !locationId) {
    return {
      state: 'LOCATION_SELECTION',
    };
  } else if (isLocationReady && parentLocationProcessed && locationId && isRegistered) {
    return {
      state: 'INITIALIZED',
    };
  }
  return {
    state: 'LOADING',
  };
};

const useMurderThePortalRedirect = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const settingsNavigate = useSettingsNavigate().navigate;
  const hasMurderThePortalFlag = useHasFeatureFlag('murder-the-portal');
  const routes = useMemo(
    () => (hasMurderThePortalFlag ? getPortalRedirectRoutes(location.current.pathname) : null),
    [location.current.pathname, hasMurderThePortalFlag]
  );

  useEffect(() => {
    if (
      (routes?.nwx || routes?.settings) &&
      hasMurderThePortalFlag &&
      location.current.pathname?.startsWith('/portal')
    ) {
      if (routes?.nwx) {
        navigate({ to: routes.nwx as any, replace: true });
      }
      if (routes?.settings) {
        settingsNavigate({ to: routes.settings as any, params: {} as any });
      }
    }
  }, [routes, location.current.pathname, hasMurderThePortalFlag]);
  return {
    willRedirect: !!(routes?.nwx || routes?.settings),
  };
};
