import { ScheduleRequest } from '@weave/schema-gen-ts/dist/schemas/schedule/api/v2/api.pb';
import { ContentLoader } from '@frontend/design-system';
import { useManageAppointmentWriteback, useGetIntegrationDetails } from '../../../../../../hooks';
import { ScheduleRequestModalContext, ScheduleRequestModalContextType } from '../Context/ScheduleRequestModalContext';
import { ScheduleRequestModal } from '../ScheduleRequestModal';
import { ScheduleReadOnlyRequestModal } from '../ScheduleRequestModal/ScheduleReadOnlyRequestModal/ScheduleReadOnlyRequestModal';

type ScheduleRequestFormManagerProps = {
  scheduleRequestModalContextValue: ScheduleRequestModalContextType;
  selectedScheduleRequest: ScheduleRequest;
};
export const ScheduleRequestFormManager = ({
  scheduleRequestModalContextValue,
  selectedScheduleRequest,
}: ScheduleRequestFormManagerProps) => {
  const locationId = selectedScheduleRequest.locationId ?? '';

  const { isIntegratedOffice, isLoading: isLoadingIntegrationDetails } = useGetIntegrationDetails({
    selectedLocationId: locationId,
    isEnabled: !!locationId,
  });

  const { appointmentWritebackSourceTenants, isLoadingDataSources, isLoadingSourceTenants } =
    useManageAppointmentWriteback({
      isEnabled: isIntegratedOffice,
      locationId: locationId,
    });

  const isLoading = isLoadingIntegrationDetails || isLoadingDataSources || isLoadingSourceTenants;

  const isIntegratedWithoutWritebacks = isIntegratedOffice && !appointmentWritebackSourceTenants.length;

  return (
    <>
      {isLoading ? (
        <ContentLoader />
      ) : (
        <ScheduleRequestModalContext.Provider value={scheduleRequestModalContextValue}>
          {isIntegratedWithoutWritebacks ? <ScheduleReadOnlyRequestModal /> : <ScheduleRequestModal />}
        </ScheduleRequestModalContext.Provider>
      )}
    </>
  );
};
