import React, { useState } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { useBreakpoint } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';
import { AlertType, XIcon, Text, IconButton } from '@frontend/design-system';

type Props = {
  type: AlertType;
  icon: React.ReactNode;
  header?: React.ReactNode;
  message: React.ReactNode;
  action?: React.ReactNode;
  isCloseable: boolean;
  trackingId?: string;
  onClose?: () => void;
};

export const Ribbon = ({ type, icon, header, message, action, isCloseable, trackingId, onClose }: Props) => {
  const size = useBreakpoint();
  const { t } = useTranslation('ribbon');
  const [isClosed, setIsClosed] = useState(false);
  const color = (() => {
    switch (type) {
      case 'error':
        return theme.colors.status.critical;
      case 'warning':
        return theme.colors.warning5;
      case 'success':
        return theme.colors.status.success;
      case 'info':
        return theme.colors.primary5;
    }
  })();

  const handleClose = () => {
    setIsClosed(true);
    if (onClose) {
      onClose();
    }
  };

  if (isClosed) {
    return null;
  }

  return (
    <div
      css={css`
        padding: ${theme.spacing(2)};
        background-color: ${color};
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: ${theme.spacing(2)};
      `}
      data-trackingid={trackingId}
    >
      {icon}
      <div
        css={css`
          flex-grow: 1;
        `}
      >
        {header && <RibbonHeader>{header}</RibbonHeader>}
        {message && <RibbonMessage>{message}</RibbonMessage>}
        {(size === 'small' || size === 'xsmall') && action}
      </div>
      {(size === 'medium' || size === 'mobile' || size === 'large' || size === 'xlarge') && action}
      {isCloseable && (
        <IconButton
          label={t('Close Ribbon')}
          onClick={handleClose}
          css={css`
            margin-left: ${theme.spacing(2)};
          `}
        >
          <XIcon />
        </IconButton>
      )}
    </div>
  );
};

const RibbonHeader = ({ children }: { children: React.ReactNode }) => {
  return (
    <Text
      size='large'
      weight='bold'
      css={css`
        max-width: 700px;
      `}
    >
      {children}
    </Text>
  );
};

const RibbonMessage = ({ children }: { children: React.ReactNode }) => {
  return (
    <Text
      size='medium'
      css={css`
        max-width: 700px;
        margin-bottom: ${theme.spacing(1)};
      `}
    >
      {children}
    </Text>
  );
};
