import { useMemo } from 'react';
import { BASE_URL_ANALYTICS } from '@frontend/api-analytics';
import { http } from '@frontend/fetch';
import { useTranslation } from '@frontend/i18n';
import { ROIFetchParams } from './shared-types';
import { useFetchRoiData } from './use-fetch-roi-data';

type LocationDetail =
  | {
      appointments_count?: number;
      location_id: string;
      location_name?: string;
      messages_count?: number;
    }
  | undefined;

type Response = LocationDetail[] | undefined;

type ConditionalProps =
  | {
      apiParams: ROIFetchParams;
      isPracticeValueConversion?: false;
    }
  | {
      apiParams: Pick<ROIFetchParams, 'payload'>;
      isPracticeValueConversion: true;
    };

type Props = ConditionalProps & {
  isEnabled?: boolean;
};

type ApiRequest<T extends boolean | undefined> = T extends true ? Pick<ROIFetchParams, 'payload'> : ROIFetchParams;

type DataState = {
  details: {
    [locationId: string]: LocationDetail;
  };
  totalAppointmentsCount?: number;
  totalMessagesCount?: number;
};

interface UseFetchMissedCallTextConversionTotals {
  data?: DataState;
  isLoading?: boolean;
}

const attributionApi = async ({ attributeType, messagingCategory, payload }: ApiRequest<false>) => {
  const response = await http.post<{ data: Response }>(
    `${BASE_URL_ANALYTICS}/roi/v3/${messagingCategory}/totals/${attributeType}`,
    payload
  );
  return response.data;
};

const practiceValueApi = async ({ payload }: ApiRequest<true>) => {
  const response = await http.post<{ data: Response }>(
    `${BASE_URL_ANALYTICS}/roi/getmissedcalltextapptscompletedmulti`,
    payload
  );
  return response.data;
};

export const useFetchMissedCallTextConversionTotals = ({
  isPracticeValueConversion,
  ...rest
}: Props): UseFetchMissedCallTextConversionTotals => {
  const { t } = useTranslation('analytics');

  const { data, isLoading } = useFetchRoiData<ApiRequest<typeof isPracticeValueConversion>, Response>({
    ...rest,
    api: isPracticeValueConversion ? practiceValueApi : attributionApi,
    errorMessage: t("Couldn't fetch the missed call text conversion values. Please try again."),
    uniqueId: 'mct-conversion-totals',
  });

  const processedData: DataState = useMemo(() => {
    let totalAppointmentsCount = 0;
    let totalMessagesCount = 0;
    const details =
      data?.reduce<DataState['details']>((acc, detail) => {
        if (acc && detail) {
          acc[detail.location_id] = detail;
          totalAppointmentsCount += detail.appointments_count || 0;
          totalMessagesCount += detail.messages_count || 0;
        }
        return acc;
      }, {}) || {};

    return {
      details,
      totalAppointmentsCount,
      totalMessagesCount,
    };
  }, [data]);

  return {
    data: processedData,
    isLoading,
  };
};
