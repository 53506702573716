import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyle = css`
  display: flex;
  flex-flow: row;
  max-height: 700px;
  min-height: 275px;
  align-content: space-between;
  padding: ${theme.spacing(0, 2)};
  overflow: scroll;
`;

export const innerContainerStyle = css`
  display: flex;
  flex-flow: column;
  flex: 2;
  margin-bottom: ${theme.spacing(3)};
`;
