import { FC, useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { capitalize } from 'lodash-es';
import { AnalyticsCommonTypes, AppointmentAnalyticsApi, AppointmentAnalyticsTypes } from '@frontend/api-analytics';
import { useTranslation } from '@frontend/i18n';
import { useLocalizedInfiniteQuery } from '@frontend/location-helpers';
import { formatPhoneNumber } from '@frontend/phone-numbers';
import { SortingValue, Table, TableColumnConfig, useAlert } from '@frontend/design-system';
import { ColumnHeaderInfoTip, InfoTipPopover, Status } from '..';
import { queryKeys } from '../../query-keys';
import { defaultDateRangeMap, getTimeZoneDateTime } from '../../utils';
import {
  useAppointmentAnalyticsShallowStore,
  useAppointmentAnalyticsDemoData,
  useIsAppointmentDemoAccount,
} from './hooks';

interface Props {
  onFetchStateChange: (isFetching: boolean) => void;
}

const DEFAULT_PAGE_SIZE = 25;

const getLastStatus = (data?: AppointmentAnalyticsTypes.AppointmentStatusWithModifiedTime) => {
  let latestStatus = '-';
  let latestTimestamp = null;

  for (const entry of data || []) {
    if (entry.Status && entry.ModifiedTime) {
      const timestamp = new Date(entry.ModifiedTime).getTime();
      if (!latestTimestamp || timestamp > latestTimestamp) {
        latestTimestamp = timestamp;
        latestStatus = entry.Status.trim();
      }
    }
  }

  return latestStatus;
};

export const AppointmentTableView: FC<React.PropsWithChildren<Props>> = ({ onFetchStateChange }) => {
  const { t } = useTranslation('analytics');
  const alert = useAlert();
  const { filters, setFilters } = useAppointmentAnalyticsShallowStore('filters', 'setFilters');
  const demoData = useAppointmentAnalyticsDemoData();
  const isDemoAccount = useIsAppointmentDemoAccount();

  const [pageConfig, setPageConfig] = useState<AnalyticsCommonTypes.PageConfig>({
    pageNumber: 1,
    pageSize: DEFAULT_PAGE_SIZE,
  });

  const queryString = useMemo(
    () => `${JSON.stringify(filters)}-${pageConfig.pageSize}-${isDemoAccount}`,
    [filters, pageConfig.pageSize, isDemoAccount]
  );

  const { data, fetchNextPage, hasNextPage, hasPreviousPage, isFetching } = useLocalizedInfiniteQuery({
    queryKey: queryKeys.appointmentAnalyticsReport(queryString),
    queryFn: ({ pageParam }) =>
      isDemoAccount
        ? { data: { Data: [] }, meta: { links: {} } }
        : AppointmentAnalyticsApi.getAppointmentReport(filters, pageConfig.pageSize, defaultDateRangeMap, pageParam),
    getNextPageParam: (lastPage: AppointmentAnalyticsTypes.AppointmentReportResponse | undefined) => {
      const nextLink = lastPage?.meta.links.next;
      return nextLink?.substring(nextLink.indexOf('/') + 1);
    },
    getPreviousPageParam: (lastPage: AppointmentAnalyticsTypes.AppointmentReportResponse | undefined) => {
      const prevLink = lastPage?.meta.links.previous;
      return prevLink?.substring(prevLink.indexOf('/') + 1);
    },
    onError: () => {
      alert.error(t("Couldn't load the appointment reports."));
    },
    refetchOnWindowFocus: false,
    retry: false,
    select: (data) => {
      return isDemoAccount
        ? {
            pageParams: [],
            pages: [demoData?.tableData],
          }
        : data;
    },
    staleTime: 10000,
  });

  const getHasNext = () => {
    const pageData = data?.pages[pageConfig.pageNumber - 1]?.data?.Data || [];
    return hasNextPage && !!pageData.length && pageData.length === pageConfig.pageSize;
  };

  const handleSorting = (sortValues: SortingValue<string>[]) => {
    const { id, value } = sortValues[0] || {};
    setFilters({
      OrderByField: id || filters.OrderByField,
      OrderByAsc: value === 'asc',
    });
  };

  const colConfig: TableColumnConfig<AppointmentAnalyticsTypes.Appointment>[] = [
    {
      Header: (
        <ColumnHeaderInfoTip
          columnTitle={t('Location')}
          infoTip={<InfoTipPopover>{t('Location name of the office')}</InfoTipPopover>}
        />
      ),
      headerLabel: t('Location'),
      accessor: ({ LocationName }: AppointmentAnalyticsTypes.Appointment) => LocationName || '-',
      disableSortBy: true,
      id: 'Location',
      omit: filters.LocationID?.length === 1,
      width: 350,
    },
    {
      Header: (
        <ColumnHeaderInfoTip
          columnTitle={t('Patient Name')}
          infoTip={<InfoTipPopover>{t('Name of the patient stored in the PMS or EHR')}</InfoTipPopover>}
        />
      ),
      headerLabel: t('Patient Name'),
      accessor: ({ PatientName }: AppointmentAnalyticsTypes.Appointment) => PatientName || '-',
      disableSortBy: true,
      id: 'PatientName',
      width: 250,
    },
    {
      Header: (
        <ColumnHeaderInfoTip
          columnTitle={t('Phone Number')}
          infoTip={<InfoTipPopover>{t('Phone number of the patient')}</InfoTipPopover>}
        />
      ),
      headerLabel: t('Phone Number'),
      accessor: ({ PhoneNumber }: AppointmentAnalyticsTypes.Appointment) => formatPhoneNumber(PhoneNumber) || '-',
      disableSortBy: true,
      id: 'PhoneNumber',
      width: 160,
    },
    {
      Header: (
        <ColumnHeaderInfoTip
          columnTitle={t('Appointment Date')}
          infoTip={<InfoTipPopover>{t('Date on which the appointment takes place')}</InfoTipPopover>}
        />
      ),
      headerLabel: t('Appointment Date'),
      accessor: ({ AppointmentDate, TimeZone }: AppointmentAnalyticsTypes.Appointment) =>
        AppointmentDate ? getTimeZoneDateTime(TimeZone, AppointmentDate) : '-',
      id: 'AppointmentDate',
      width: 370,
      startingSortBy: 'desc',
    },
    {
      Header: (
        <ColumnHeaderInfoTip
          columnTitle={t('Contact Type')}
          infoTip={
            <InfoTipPopover>
              {t(
                'It gives more information about the contact and their appointment history. Refer Weave Help for more information'
              )}
            </InfoTipPopover>
          }
        />
      ),
      headerLabel: t('Contact Type'),
      accessor: ({ AppointmentCategory }: AppointmentAnalyticsTypes.Appointment) => AppointmentCategory || '-',
      id: 'AppointmentCategory',
      width: 200,
    },
    {
      Header: (
        <ColumnHeaderInfoTip
          columnTitle={t('Practitioner Name')}
          infoTip={<InfoTipPopover>{t('Name of the practitioner')}</InfoTipPopover>}
        />
      ),
      headerLabel: t('Practitioner Name'),
      accessor: ({ Provider }: AppointmentAnalyticsTypes.Appointment) => (Provider ? capitalize(Provider) : '-'),
      id: 'Provider',
      width: 250,
    },
    {
      Header: (
        <ColumnHeaderInfoTip
          columnTitle={t('Appointment Type')}
          infoTip={<InfoTipPopover>{t('Type of the appointment like surgery, checkup etc')}</InfoTipPopover>}
        />
      ),
      headerLabel: t('Appointment Type'),
      accessor: ({ TypePM }: AppointmentAnalyticsTypes.Appointment) => TypePM || '-',
      id: 'TypePM',
      width: 400,
    },
    {
      Header: (
        <ColumnHeaderInfoTip
          columnTitle={t('Entry Date')}
          infoTip={<InfoTipPopover>{t('Date when the appointment was created in the PMS')}</InfoTipPopover>}
        />
      ),
      headerLabel: t('Entry Date'),
      accessor: ({ EntryDate, TimeZone }: AppointmentAnalyticsTypes.Appointment) =>
        EntryDate ? getTimeZoneDateTime(TimeZone, EntryDate) : '-',
      id: 'EntryDate',
      width: 370,
    },
    {
      Header: (
        <ColumnHeaderInfoTip
          columnTitle={t('Appointment Status - Weave')}
          infoTip={
            <InfoTipPopover>
              {t('This is the status value that exists in Weave. It may be different from the status in PMS/EHR')}
            </InfoTipPopover>
          }
        />
      ),
      headerLabel: t('Appointment Status - Weave'),
      accessor: ({ StatusWithModifiedTime }: AppointmentAnalyticsTypes.Appointment) => (
        <Status status={getLastStatus(StatusWithModifiedTime)} />
      ),
      disableSortBy: true,
      id: 'AppointmentStatusWeave',
      width: 250,
    },
    {
      Header: (
        <ColumnHeaderInfoTip
          columnTitle={t('Appointment Status - PMS')}
          infoTip={<InfoTipPopover>{t('This is the status value that exists in the PMS/EHR')}</InfoTipPopover>}
        />
      ),
      headerLabel: t('Appointment Status - PMS'),
      accessor: ({ StatusPMWithModifiedTime }: AppointmentAnalyticsTypes.Appointment) =>
        getLastStatus(StatusPMWithModifiedTime),
      disableSortBy: true,
      id: 'AppointmentStatusPMS',
      width: 250,
    },
  ];

  useEffect(() => {
    setPageConfig({ ...pageConfig, pageNumber: 1 });
  }, [filters]);

  useEffect(() => {
    if (!data?.pages[pageConfig.pageNumber - 1]) {
      fetchNextPage();
    }
  }, [pageConfig.pageNumber]);

  useEffect(() => {
    onFetchStateChange(isFetching);
  }, [isFetching]);

  return (
    <Table
      colConfig={colConfig}
      data={data?.pages[pageConfig.pageNumber - 1]?.data?.Data || []}
      disableMultiSort
      emptyStateConfig={{
        type: 'appointment_requests',
      }}
      fullHeight
      fullHeightConfig={{
        offset: isDemoAccount ? 410 : 320,
      }}
      hasFilterColumns
      hasResizeColumns
      hasResponsiveColWidths
      isLoading={isFetching}
      isPaginated
      manualPaginationConfig={{
        handleChange: (action: 'next' | 'prev') => {
          if (action === 'next') {
            setPageConfig({ ...pageConfig, pageNumber: pageConfig.pageNumber + 1 });
          } else {
            setPageConfig({ ...pageConfig, pageNumber: pageConfig.pageNumber - 1 });
          }
        },
        hasNext: getHasNext(),
        hasPrevious: hasPreviousPage,
        page: pageConfig.pageNumber,
        defaultRowsPerPage: pageConfig.pageSize,
        onNumRowsChange: (num) => {
          setPageConfig({ pageNumber: 1, pageSize: num });
        },
        rowsPerPageOptions: [25, 50, 75, 100],
      }}
      manualSortBy
      onSortChange={handleSorting}
      tableInstanceId='appointments-reports'
      wrapperStyle={css`
        height: 100%;
      `}
    />
  );
};
