import { useEffect } from 'react';
import { css } from '@emotion/react';
import { ExtensionNumberAvailability } from '@weave/schema-gen-ts/dist/schemas/phone-devices/lines/line_api.pb';
import { useMutation } from 'react-query';
import { DevicesApi } from '@frontend/api-devices';
import { useTranslation } from '@frontend/i18n';
import { PickerLocation } from '@frontend/scope';
import { Button } from '@frontend/design-system';
import { ExtensionInput } from './extension-input';

export type AddedExtension = { id: number; value: string; isValid: boolean };

type ExtensionInputProps = {
  initialExtensions: string[];
  extensions: AddedExtension[];
  setExtensions: React.Dispatch<React.SetStateAction<AddedExtension[]>>;
  tenantLocation: PickerLocation;
  trackingIdBase?: string;
};

export const ExtensionsAdder = ({
  extensions,
  setExtensions,
  tenantLocation,
  initialExtensions,
  trackingIdBase,
}: ExtensionInputProps) => {
  const { t } = useTranslation('phone');

  const { mutateAsync: mutateExtensionAvailability } = useMutation((extensionNumber: string) =>
    DevicesApi.checkExtensionAvailability({ extensionNumber, tenantId: tenantLocation.phoneTenantId ?? '' })
  );

  useEffect(() => {
    setExtensions(
      initialExtensions.length
        ? initialExtensions.map((extension, index) => ({
            id: index + 1,
            value: String(extension),
            isValid: true,
          }))
        : [{ id: 1, value: '', isValid: false }]
    );
  }, []);

  const handleExtensionChange = (value: string, id: number, isValid: boolean) => {
    setExtensions((extensions) =>
      extensions.map((extension) => (extension.id === id ? { ...extension, value, isValid } : extension))
    );
  };

  /**
   * Handler function that gets called when the user clicks the "Add Extension" button. This function
   * will add a new extension to the list of extensions which means a new input field will be rendered.
   * It will try to pre-fill the value of the input field with the next available extension number if
   * possible, otherwise it will just add an empty extension.
   */
  const handleAddExtension = async () => {
    let nextNumber = 7000;

    if (extensions.length > 1 || (extensions.length === 1 && extensions[0].value !== '')) {
      const numbs = extensions.map((extension) => Number(extension.value)).sort((a, b) => a - b); // Sort the array in ascending order
      nextNumber = numbs[numbs.length - 1] + 1; // return the next highest integer to check as the next available extension
    }

    // We can't add extensions higher than 7999 so don't try presetting the extension number
    // if the next available extension is higher than 7999.
    if (nextNumber > 7999) {
      // Just add an empty extension
      setExtensions([...extensions, { id: extensions.length + 1, value: '', isValid: false }]);
      return;
    }

    mutateExtensionAvailability(String(nextNumber))
      .then((resp) => {
        nextNumber = resp.availability === ExtensionNumberAvailability.AVAILABLE ? nextNumber : resp.nextAvailable;
        setExtensions([...extensions, { id: extensions.length + 1, value: String(resp.nextAvailable), isValid: true }]);
      })
      .catch(() => {
        // If the API call fails, just add an empty extension
        setExtensions([...extensions, { id: extensions.length + 1, value: '', isValid: false }]);
      });
  };

  const handleRemoveExtension = (id: number) => {
    if (extensions.length > 1) {
      setExtensions(extensions.filter((extension) => extension.id !== id));
    }
  };

  return (
    <div>
      {extensions.map((extension) => (
        <ExtensionInput
          key={extension.id}
          id={extension.id}
          tenantId={tenantLocation.phoneTenantId ?? ''}
          isLast={extensions.length === 1}
          initialValue={extension.value}
          onExtensionChange={handleExtensionChange}
          onRemoveExtension={handleRemoveExtension}
          trackingIdBase={trackingIdBase}
        />
      ))}
      <Button
        iconName='plus'
        variant='tertiary'
        css={css`
          padding-left: 0;
        `}
        onClick={handleAddExtension}
        trackingId={trackingIdBase ? `${trackingIdBase}::add-extension-btn` : undefined}
      >
        {t('Add Extension')}
      </Button>
    </div>
  );
};
