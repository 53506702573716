import { useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { OnboardingModulesQueries, OnboardingModulesTypes } from '@frontend/api-onboarding-modules';
import { SubscriptionsQueries } from '@frontend/api-subscription';
import { getUser } from '@frontend/auth-helpers';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { pendo } from '@frontend/tracking';
import { theme } from '@frontend/theme';
import { ContentLoader, Modal, ModalControlModalProps, Text, useAlert } from '@frontend/design-system';
import { trackingIds } from '../constants';

type ChilliPiperEvent = {
  action?:
    | 'booked'
    | 'booking-confirmed'
    | 'availability-loaded'
    | 'rescheduled'
    | 'prospect_cancel'
    | 'no-free-slots'
    | 'close'
    | 'phone-selected'
    | 'meeting-selected'
    | 'video-selected'
    | 'phoneClose'
    | 'timeslot-selected';
};

const SCHEDULE_DEMO_CHILLI_PIPER_URL = 'https://getweave.chilipiper.com/book/welcome-call-software-only-plg1';
const modalTrackingIds = trackingIds.nwxDashboardTasksSection.scheduleCallDialog;

interface Props extends ModalControlModalProps {
  locationSlug: string;
  onSuccess: () => void;
}

export const ScheduleCallWithOnboarderModal = ({ locationSlug, onSuccess, ...modalProps }: Props) => {
  const { t } = useTranslation('onboarding-modules');
  const [isCloseButtonVisible, setIsCloseButtonVisible] = useState<boolean>();
  const alert = useAlert();
  const userInfo = getUser();

  const markLocationTaskCompleteMutation = OnboardingModulesQueries.useMarkLocationTaskCompletedMutation();
  const sfAccountInfoQuery = SubscriptionsQueries.useGetSalesforceAccountBySlug({
    enabled: modalProps.show && !!locationSlug,
    slug: locationSlug,
  });

  useEffect(() => {
    if (markLocationTaskCompleteMutation.isError) {
      alert.error(t('An error occurred while marking the task as complete.'));
    }
    if (markLocationTaskCompleteMutation.isSuccess) {
      onSuccess();
    }
  }, [markLocationTaskCompleteMutation.isError, markLocationTaskCompleteMutation.isSuccess]);

  useEffect(() => {
    const chilliPiperOrigin = new URL(SCHEDULE_DEMO_CHILLI_PIPER_URL).origin;
    const messageListener = (event?: MessageEvent<ChilliPiperEvent>) => {
      if (event?.origin !== chilliPiperOrigin) {
        return;
      }

      switch (event?.data?.action) {
        case 'close':
          pendo.track(modalTrackingIds.closeButton);
          modalProps.onClose();
          break;
        case 'booking-confirmed':
          markLocationTaskCompleteMutation.mutate(OnboardingModulesTypes.TaskIds.ScheduleCallWithOnboarder);
          break;
        case 'availability-loaded':
          // hide close button once chilli-piper iframe is loaded
          setIsCloseButtonVisible(false);
      }
    };
    window.addEventListener('message', messageListener);

    return () => {
      window.removeEventListener('message', messageListener);
    };
  }, []);

  useEffect(() => {
    if (modalProps.show) {
      setIsCloseButtonVisible(true);
    }
  }, [modalProps.show]);

  const scheduleCallIFrameUrl = useMemo(() => {
    // avoid loading iframe content if modal is not shown
    if (!modalProps.show) {
      return '';
    }
    const scheduleCallUrl = new URL(SCHEDULE_DEMO_CHILLI_PIPER_URL);
    scheduleCallUrl.searchParams.set('email', userInfo?.username ?? '');
    scheduleCallUrl.searchParams.set('firstname', userInfo?.firstName ?? '');
    scheduleCallUrl.searchParams.set('lastname', userInfo?.lastName ?? '');
    scheduleCallUrl.searchParams.set('phone', userInfo?.mobileNumber ?? '');
    scheduleCallUrl.searchParams.set('accountId', sfAccountInfoQuery.data?.account?.id ?? '');
    return scheduleCallUrl.toString();
  }, [userInfo, sfAccountInfoQuery.data, modalProps.show]);

  return (
    <Modal {...modalProps} maxWidth={600} css={{ padding: theme.spacing(2, 0), height: '90vh', maxHeight: 800 }}>
      <Modal.Header
        closeBtnTrackingId={modalTrackingIds.closeButton}
        onClose={isCloseButtonVisible ? modalProps.onClose : undefined}
        css={{ button: { height: 32 } }}
      >
        <div css={headerContainerStyle}>
          <Icon name='phone-call' />
          <Text size='large' weight='bold'>
            {t('Schedule With a Weave Onboarder')}
          </Text>
        </div>
      </Modal.Header>
      <Modal.Body>
        <ContentLoader show={sfAccountInfoQuery.isLoading} />
        <iframe src={scheduleCallIFrameUrl} css={iframeStyle} />
      </Modal.Body>
    </Modal>
  );
};

const headerContainerStyle = css({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  marginBottom: theme.spacing(1),
});

const iframeStyle = css({
  width: '100%',
  minWidth: 400,
  height: 'calc(100% - 5px)',
  border: `1px solid ${theme.colors.neutral10}`,
});
