import { PortingData } from '@weave/schema-gen-ts/dist/schemas/phone/porting/porting-data/v1/porting_data_service.pb';
import { createProvider } from '@frontend/store';
import { checkIsPortOrderModified, getStorePortOrderFromPortingData } from './helper';
import { StorePortOrderType } from './types';

export type PortOrderCreationStore = {
  isSaving: boolean;
  setIsSaving: (value: boolean) => void;
  isPortOrderModified: boolean;
  isNumbersModified?: boolean;

  // this is converted port order info from portOrderResponse
  // which used to check if port order is modified or not by comparing with storePortOrder
  initialPortOrder?: StorePortOrderType;
  storePortOrder: Partial<StorePortOrderType>;
  setStorePortOrder: (storePortOrder: Partial<StorePortOrderType>) => void;
  updateStorePortOrder: (storePortOrder: Partial<StorePortOrderType>) => void;

  portOrderResponse?: PortingData;
  setPortOrderResponse: (portOrderResponse?: PortingData) => void;
};

const defaultPortOrder: StorePortOrderType = getStorePortOrderFromPortingData({});

export const { Provider: PortOrderCreationStoreProvider, useStore: usePortOrderCreationStore } =
  createProvider<PortOrderCreationStore>()((set, get) => ({
    isSaving: false,
    setIsSaving: (value) => set({ isSaving: value }),

    isPortOrderModified: false,
    storePortOrder: {},
    setStorePortOrder: (storePortOrder) => {
      set(() => ({ storePortOrder: { ...storePortOrder }, isPortOrderModified: false, isNumbersModified: false }));
    },
    updateStorePortOrder: (partialStorePortOrder) => {
      const { initialPortOrder, storePortOrder } = get();
      const newStorePortOrder = { ...storePortOrder, ...partialStorePortOrder };
      const { isPortOrderModified, isNumbersModified } = checkIsPortOrderModified(
        newStorePortOrder,
        initialPortOrder ?? defaultPortOrder
      );
      set({ storePortOrder: newStorePortOrder, isPortOrderModified, isNumbersModified });
    },
    setPortOrderResponse: (portOrderResponse) => {
      const storePortOrder = getStorePortOrderFromPortingData(portOrderResponse ?? {});
      set(() => ({
        portOrderResponse,
        storePortOrder,
        initialPortOrder: storePortOrder,
        isPortOrderModified: false,
        isNumbersModified: false,
      }));
    },
  }));
