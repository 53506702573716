import '@justifi/webcomponents/dist/module/justifi-payment-provisioning.js';

import { useEffect, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { BannerNotification } from '@frontend/design-system';
import {
  JustifiPaymentProvisioningComponentProps,
  JustifiPaymentProvisioningSubmitEvent,
  JustifiPaymentProvisioningErrorEvent,
} from '../types';
import { styles, justifiInlineStyles, justifiOverrideStyles } from './justifi-payment-provisioning.styles';

export type JustifiPaymentProvisioningProps = {
  justifiProps: JustifiPaymentProvisioningComponentProps;
  className?: string;
  onSubmitted?: (event: JustifiPaymentProvisioningSubmitEvent) => void;
  onError?: (error: JustifiPaymentProvisioningErrorEvent) => void;

  /**
   * Decide if an error is, in fact, an error.
   * For example, the "already been submitted" error will get a custom pending state.
   *
   * @returns `true` if error logic should be called, `false` if not.
   */
  customErrorHandler?: (error: JustifiPaymentProvisioningErrorEvent) => boolean;
  showErrorMessages?: boolean;
  showSuccessMessage?: boolean;
  successMessage?: string;
};

function publisCustomCss(parent: HTMLElement | null) {
  if (!parent) {
    return;
  }

  const style = document.createElement('style');
  style.setAttribute('id', 'justifi-custom-css');

  style.innerText = justifiOverrideStyles;

  try {
    const existing = parent.shadowRoot?.querySelector('#justifi-custom-css');
    if (parent.shadowRoot && !existing) {
      parent.shadowRoot?.appendChild(style);
    } else if (existing) {
      parent.shadowRoot?.replaceChild(style, existing);
    }
  } catch (err) {
    console.warn('Error appending custom css to shadow root', err);
  }
}

export function JustifiPaymentProvisioning({
  onError,
  onSubmitted,
  customErrorHandler: customErrorHandlerControl,
  justifiProps,
  className,
  showErrorMessages,
  showSuccessMessage = true,
  successMessage = 'Business information submitted successfully',
}: JustifiPaymentProvisioningProps) {
  const formRef = useRef<HTMLJustifiPaymentProvisioningCoreElement>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [submittedData, setSubmittedData] = useState<JustifiPaymentProvisioningSubmitEvent['detail'] | null>(null);

  const { businessId, authToken, formTitle = 'Business Info', allowOptionalFields } = justifiProps;

  const customErrorHandler =
    customErrorHandlerControl ||
    ((error: JustifiPaymentProvisioningErrorEvent) => {
      if (error.detail.message.includes('already been submitted')) {
        setIsSubmitted(true);
        return false;
      }

      return true;
    });

  useEffect(() => {
    const form = formRef.current;
    if (!form) {
      return;
    }

    // Handle the submit event
    const handleSubmit = (event: JustifiPaymentProvisioningSubmitEvent) => {
      /**
       * Handle submit fires on every step.
       */

      const detail = event.detail;

      const type = detail.data?.submittedData?.type || detail.data?.type;
      const previousType = submittedData?.data?.submittedData?.type || submittedData?.data?.type;

      if (detail.data?.response) {
        /**
         * response key seems to only be present on final submit
         */
        setErrorMessage(null);
        setIsSubmitted(true);
        onSubmitted?.(event);
      } else {
        /**
         * If a submission error occurs, the error event will fire first, and then the submit event.
         * So we must check if the step has changed before clearing.
         * Otherwise we'll clear a newly set error.
         */
        if (type !== previousType) {
          setErrorMessage(null);
        }
      }

      /**
       * Set submitted data for current step, so on next step, we can compare previous step data.
       */
      setSubmittedData(event.detail);
    };

    const handleError = (event: JustifiPaymentProvisioningErrorEvent) => {
      const isError = customErrorHandler(event);
      if (!isError) {
        return;
      }

      // Handle the error event
      if (showErrorMessages) {
        setErrorMessage(event.detail.message);
      }
      onError?.(event);
    };

    form.addEventListener('submitted', handleSubmit);
    form.addEventListener('error-event', handleError);

    let id: NodeJS.Timeout | null = null;

    const attemptToPublishCustomCss = () => {
      const rootElement = form.querySelector('justifi-payment-provisioning-core');

      if (rootElement) {
        publisCustomCss(rootElement);
      } else {
        id = setTimeout(() => {
          attemptToPublishCustomCss();
        }, 250);
      }
    };

    attemptToPublishCustomCss();

    // Cleanup
    return () => {
      form.removeEventListener('submitted', handleSubmit);
      form.removeEventListener('error-event', handleError);

      id && clearTimeout(id);
    };
  }, [businessId, authToken, isSubmitted]);

  if (!businessId || !authToken) {
    return <div>Loading...</div>;
  }

  return (
    <div className={`Justifi-wrapper ${className}`} key={isSubmitted ? 'submitted' : 'not-submitted'}>
      <style>
        {/* These styles are done the way justifi wants */}
        {justifiInlineStyles}
      </style>

      {showSuccessMessage && isSubmitted && (
        <BannerNotification
          css={css`
            margin-bottom: ${theme.spacing(2)};
          `}
          status='success'
          message={successMessage}
        />
      )}

      <div className='form-wrapper' css={styles.formWrapper}>
        <justifi-payment-provisioning
          business-id={businessId}
          auth-token={authToken}
          form-title={formTitle}
          allow-optional-fields={allowOptionalFields}
          ref={formRef}
        />

        {isSubmitted && <div className='form-wrapper__pending-overlay' css={styles.pendingOverlay}></div>}
      </div>

      {showErrorMessages && !!errorMessage && (
        <BannerNotification
          css={css`
            margin-top: ${theme.spacing(2)};
          `}
          status='error'
          message={errorMessage}
        />
      )}
    </div>
  );
}
