import { useCallback, useEffect, useRef } from 'react';
import { useQuery } from 'react-query';
import { StreamChat } from 'stream-chat';
import { getUser } from '@frontend/auth-helpers';
import { http } from '@frontend/fetch';
import { useAppScopeStore } from '@frontend/scope';
import { CHAT_TOKEN_URL, TEAM_CHAT_API_INVALIDATION_KEY } from '../constants';
import { useTeamChatStore } from '../providers';
import { formatUser, getStreamUserId } from '../utils';

interface ApiAndToken {
  apiKey: string;
  token: string;
}

interface UseTeamChatConnect {
  connect: (orgId: string) => Promise<{ client: StreamChat | null }>;
  isTokenReady: boolean;
}

export const useTeamChatConnect = (): UseTeamChatConnect => {
  const { selectedOrgId } = useAppScopeStore();
  const {
    isTrayOpen,
    isConnected,
    streamClient,
    isInitializing,
    reset,
    setTeamChatOrgId,
    initialize,
    setTotalUnreadCount,
    setIsConnected,
    userStatus,
    conversations,
    setIsFetchingChats,
  } = useTeamChatStore([
    'isTrayOpen',
    'initialize',
    'isConnected',
    'reset',
    'setTeamChatOrgId',
    'streamClient',
    'teamChatOrgId',
    'isInitializing',
    'setTotalUnreadCount',
    'setIsConnected',
    'userStatus',
    'conversations',
    'setIsFetchingChats',
  ]);
  const isInitialUnreadCountSet = useRef(false);

  const { data: apiAndToken, isLoading } = useQuery(
    [TEAM_CHAT_API_INVALIDATION_KEY, selectedOrgId],
    // If it is not updated on org switch then stream throws error that userToken does not have a user_id or is not matching with user.id
    async () => {
      // When fetching the new token, reset the store, set the new selected org id and fetch the new token
      // This will mean a fresh new start for the store provider for the new location
      reset();
      setTeamChatOrgId(selectedOrgId);
      return await http.get<ApiAndToken>(CHAT_TOKEN_URL);
    },
    {
      enabled: !!selectedOrgId,
    }
  );

  const connect = useCallback(
    // Before making any connect/disconnect we check if the client is in initializing stage, so that it does not runs in
    // race condition, where it disconnects the client before fetching the chats and users
    async (orgId: string) => {
      // If already connected to the chat client, return the client but check if the the current connected client is
      // for the selected org
      if (isConnected && streamClient?.clientID?.includes(selectedOrgId)) {
        console.info('Already connected to the chat client');
        return { client: streamClient };
      }

      if (!apiAndToken) {
        console.error('API key and token not available');
        return { client: null };
      }

      const userId = getUser()?.userID;

      if (!userId) {
        console.error('User ID not available');
        return { client: null };
      }

      const client = StreamChat.getInstance(apiAndToken.apiKey);
      // Adding org id to the user id to make it compatible with the stream chat
      const streamUserId = getStreamUserId({ orgId, userId });

      // Initialize current user with userId
      await client.connectUser({ id: streamUserId }, apiAndToken.token);

      if (!client.user) {
        console.error('Failed to connect to the chat client');
        return { client: null };
      }
      const formattedUser = formatUser(client.user);
      userStatus[formattedUser.userID] = formattedUser.status;

      initialize({
        currentUser: formattedUser,
        isConnected: !!client.user,
        streamClient: client,
        userStatus,
        isInitializing: true,
      });

      return { client };
    },
    [apiAndToken, initialize, isConnected, streamClient]
  );

  // add a state when we are initializing the client, when initializing nothing should happen otherwise keep doing what this code does

  useEffect(() => {
    (async function () {
      if (!isTrayOpen && !isInitializing && !isInitialUnreadCountSet.current && streamClient?.user) {
        // get unread count on initial load and disconnect user
        const totalUnreadCount = [...conversations.groups].reduce(
          (acc, conversation) => acc + conversation.unreadCount,
          0
        );
        setTotalUnreadCount(totalUnreadCount);
        isInitialUnreadCountSet.current = true;
        setIsFetchingChats(true);
        streamClient.disconnectUser();
        setIsConnected(false);
      } else if (isTrayOpen && !isInitializing && streamClient && !streamClient.user) {
        // connect on tray open
        connect(selectedOrgId).then(() => {
          initialize({ isInitializing: false });
        });
        setIsConnected(true);
      } else if (!isTrayOpen && streamClient?.user && isInitialUnreadCountSet.current && !isInitializing) {
        // disconnect on tray close
        setIsFetchingChats(true);
        streamClient.disconnectUser();
        setIsConnected(false);
      }
    })();
  }, [isTrayOpen, streamClient, isInitializing, selectedOrgId, conversations]);

  return {
    connect,
    isTokenReady: !isLoading && !!apiAndToken?.token,
  };
};
