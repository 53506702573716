import { useMemo } from 'react';
import dayjs from 'dayjs';
import { PhoneAnalyticsTypes } from '@frontend/api-analytics';
import { queryKeys as AnalyticsQueryKeys } from '../../../query-keys';
import { usePhoneAnalyticsShallowStore } from './use-phone-analytics-store';

export const useGetChartPayloadAndConfig = (filters: PhoneAnalyticsTypes.Filters, uniqueId: string) => {
  const { isDemoAccount } = usePhoneAnalyticsShallowStore('isDemoAccount');

  // Pick only required charts applicable filters
  const applicableFilters: Partial<PhoneAnalyticsTypes.Filters> = {
    end_date: filters?.end_date,
    location_id: filters?.location_id,
    open_office: filters?.open_office,
    start_date: filters?.start_date,
    time_zone: filters?.time_zone,
  };

  // While generating query string, we need to make sure that we ignore the time string
  // It's required to avoid calling the API multiple times at the same time when the hook is invoked from multiple components at the same time
  // We have fixed stale time as 5 minutes so that we can avoid multiple API calls for the small timestamp changes
  const queryKey = useMemo(() => {
    const startDate = dayjs(applicableFilters.start_date).format('YYYY-MM-DD');
    const endDate = dayjs(applicableFilters.end_date).format('YYYY-MM-DD');
    const filtersString = JSON.stringify({
      ...applicableFilters,
      start_date: startDate,
      end_date: endDate,
    });

    return AnalyticsQueryKeys.phoneAnalyticsCharts(`aggregated-${uniqueId}-${filtersString}`);
  }, [applicableFilters, uniqueId]);

  return {
    applicableFilters,
    config: {
      enabled:
        !isDemoAccount &&
        !!applicableFilters.location_id?.length &&
        !!applicableFilters.start_date &&
        !!applicableFilters.end_date,
      queryKey,
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 5 * 60 * 1000, // 5 minutes
    },
  };
};
