import { CSSProperties, ReactNode, memo, useMemo } from 'react';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { NakedButton, Text } from '@frontend/design-system';
import { useChartContext } from '../chart.provider';
import { defaultColor } from '../helpers';

export type CustomLegendData = {
  color?: string;
  label?: string | ReactNode;
  value?: number | string;
};

export type Size = 'small' | 'medium' | 'large';

type LegendProps = {
  customData?: CustomLegendData;
  isHovered?: boolean | null;
  legendId: string;
  legendValue?: number | string;
  onClick?: ((legendId: string) => void) | null;
  size: Size;
  labelTransform?: CSSProperties['textTransform'];
};

export const Legend = memo(
  ({ customData = {}, isHovered, labelTransform, legendId, legendValue, onClick, size }: LegendProps) => {
    const { activeLegends, colors = {}, labels = {} } = useChartContext();

    const { color, label, showFadeEffect, value } = useMemo(() => {
      return {
        label: customData.label ?? labels[legendId] ?? legendId,
        color: customData.color ?? colors[legendId] ?? defaultColor,
        value: customData.value ?? legendValue,
        showFadeEffect: isHovered === null ? false : !isHovered,
      };
    }, [customData, labels, legendId, legendValue, isHovered]);

    return (
      <NakedButton
        className={`${activeLegends.includes(legendId) ? 'active' : 'hidden'} ${onClick ? 'clickable' : 'read-only'}`}
        css={styles.button}
        onClick={(e) => {
          e.stopPropagation();
          onClick?.(legendId);
        }}
        style={{
          opacity: showFadeEffect ? 0.5 : 1,
        }}
        trackingId={`legend-${legendId?.toLowerCase().replaceAll('_', '-')}`}
      >
        <span className='label-wrapper'>
          <span
            className='indicator small'
            style={{
              backgroundColor: color,
            }}
          />
          {typeof label === 'string' ? (
            <Text as='span' className='label' size={size} style={{ textTransform: labelTransform }}>
              {label}
            </Text>
          ) : (
            label
          )}
        </span>
        {/* Value can be 0 too */}
        {value !== undefined && value !== null && (
          <Text as='span' className='value' size={size} weight='bold' style={{ marginLeft: theme.spacing(0.5) }}>
            {value}
          </Text>
        )}
      </NakedButton>
    );
  }
);

Legend.displayName = 'Legend';

const styles = {
  button: css`
    align-items: center;
    color: ${theme.colors.neutral60};
    cursor: default;
    display: flex;
    gap: ${theme.spacing(0.5)};

    &.clickable {
      cursor: pointer;

      &:hover {
        color: ${theme.colors.neutral90};
      }
    }

    &.clickable.hidden {
      text-decoration: line-through;
    }

    .label-wrapper {
      align-items: center;
      display: flex;
      flex-wrap: nowrap;
      gap: ${theme.spacing(0.5)};
    }

    .indicator {
      display: inline-block;
      border-radius: 50%;

      &.small {
        height: ${theme.spacing(1.2)};
        min-height: ${theme.spacing(1.2)};
        min-width: ${theme.spacing(1.2)};
        width: ${theme.spacing(1.2)};
      }
    }

    .label {
      color: inherit;
    }
  `,
};
