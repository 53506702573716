import { HTMLAttributes, MouseEventHandler, forwardRef } from 'react';
import { ROOT_NODE, useEditor, useNode } from '@craftjs/core';
import { css } from '@emotion/react';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { DragIconSmall, Text, useTooltip } from '@frontend/design-system';
import { SIDEBAR_BREAKPOINTS } from '../../../constants';
import { useComposerSection } from '../../../hooks';
import { emailAssistantGradientStyling } from '../../../styles';
import { ButtonLinkType, FooterLink, ReviewButtonLinkType } from '../../../types';
import { useContentComposer } from '../../content-composer-provider';
import { FooterMode } from '../footer-section';
import {
  ReviewButtonsToolbar,
  ButtonsToolbar,
  FloatingButton,
  FloatingTool,
  FloatingToolbarLeft,
  FloatingToolbarRight,
  FooterSettings,
  FooterToolbar,
  RTEToolbar,
} from '.';

type Props = {
  title: string;
  children: React.ReactNode;
  onDelete?: () => void;
  id: string;
  disableDrag?: boolean;

  // TODO: Move the following props out of the composer
  initialHTML?: string;
  link?: string;
  linkType?: ButtonLinkType;
  reviewLinkType?: ReviewButtonLinkType;
  sectionType: 'Text' | 'Button' | 'Image' | 'Footer' | 'Logo' | 'CustomCode' | 'ReviewButton';
  openAssistantModal?: () => void;

  // Footer specific props
  links?: FooterLink[];
  mode?: FooterMode;
  setMode?: (mode: FooterMode) => void;
  selectedLink?: FooterLink;
  setSelectedLink?: (link?: FooterLink) => void;
  settings?: FooterSettings;

  // Image and Logo specific props
  onClick?: MouseEventHandler<HTMLElement>;
};

export const BaseComposerSection = ({ disableDrag = false, ...rest }: Props & HTMLAttributes<HTMLDivElement>) => {
  const {
    connectors: { connect, drag },
  } = useNode();
  const { meta } = useComposerSection();

  return (
    <BaseComposerSectionContent
      {...rest}
      ref={(ref) => (ref && !disableDrag && meta.draggable ? connect(drag(ref)) : ref ? connect(ref) : null)}
    />
  );
};

const BaseComposerSectionContent = forwardRef<HTMLDivElement, Omit<Props, 'disableDrag'>>(
  (
    {
      title,
      children,
      onDelete,
      id,
      initialHTML,
      link,
      links,
      linkType,
      reviewLinkType,
      mode,
      openAssistantModal,
      setMode,
      selectedLink,
      setSelectedLink,
      sectionType,
      settings,
      ...rest
    },
    ref
  ) => {
    const { nodes, selectedId } = useEditor((state) => ({
      nodes: state.nodes[ROOT_NODE]?.data.nodes,
      selectedId: state.events.selected.entries()?.next()?.value?.[0],
    }));
    const { composerMode, insertSection, parts } = useContentComposer();
    const isMarketingMode = composerMode === 'marketing';

    const { setMeta, props } = useComposerSection();

    const { Tooltip, triggerProps, tooltipProps } = useTooltip();

    return (
      <section
        id={id}
        css={css`
          display: flex;
          justify-content: center;
          position: relative;
          padding: ${theme.spacing(4)};
          background: ${theme.colors.neutral5};
          border: ${id === selectedId ? `solid 2px ${theme.colors.primary50}` : `solid 2px ${theme.colors.neutral20}`};
          &:not(:first-of-type) {
            ${id !== selectedId && `border-top: none;`}
          }
          .editor-container {
            margin-top: 0 !important;
            margin-bottom: 0 !important;
            background: transparent !important;
            position: static !important;
            display: flex;
            flex-direction: column;
            align-items: center;
          }
          .editor-scroller {
            min-height: unset !important;
            resize: none;
          }
          .editor-inner {
            background: transparent !important;
            height: 100%;
            align-self: flex-start;
            width: 100%;
          }
          .editor-input {
            min-height: 24px !important;
          }
          .floating-toolbar {
            display: ${selectedId === id ? 'inline-flex' : 'none'};
            @media (max-width: ${SIDEBAR_BREAKPOINTS.mobile}px) {
              display: none;
            }
          }
          &:hover {
            .floating-toolbar {
              display: ${selectedId === ROOT_NODE || selectedId === id ? 'inline-flex' : 'none'};
              @media (max-width: ${SIDEBAR_BREAKPOINTS.mobile}px) {
                display: none;
              }
            }
          }
        `}
        {...rest}
        ref={ref}
        onClick={(event) => {
          event.stopPropagation(); // Stop click event propagation the canvas container as onClick on the canvas closes section settings
          rest.onClick?.(event);
        }}
      >
        <FloatingToolbarLeft>
          <FloatingButton
            onMouseEnter={() => setMeta((meta) => (meta.draggable = true))}
            onMouseLeave={() => setMeta((meta) => (meta.draggable = false))}
            css={{
              cursor: 'grab',
              display: sectionType === 'Footer' ? 'none' : 'block',
              fontWeight: theme.font.weight.bold,
            }}
          >
            <DragIconSmall />
          </FloatingButton>
          <FloatingTool>
            <Text as='span' weight='bold' {...triggerProps}>
              {title}
            </Text>
            <Tooltip {...tooltipProps}> {title} </Tooltip>
          </FloatingTool>
        </FloatingToolbarLeft>
        <FloatingToolbarRight>
          {openAssistantModal && isMarketingMode && (
            <FloatingButton
              onClick={openAssistantModal}
              css={[
                emailAssistantGradientStyling,
                {
                  border: '1px solid transparent',
                  width: theme.spacing(5.25),
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontWeight: theme.font.weight.bold,
                },
              ]}
            >
              <Icon name='aiassistant-small' />
            </FloatingButton>
          )}
          {!['Footer', 'Logo', 'ReviewButton'].includes(sectionType) && (
            <FloatingButton
              onClick={() => {
                // sectionType looks like 'Text' and 'Button' while the key is the component name like 'TextSection' and 'ButtonSection'.
                const foundPart = Object.entries(parts).find(([key]) => key === sectionType + 'Section');
                if (foundPart) {
                  const [_key, Part] = foundPart;
                  const index = nodes?.indexOf(id) ?? (nodes ? nodes.length - 1 : -1);
                  insertSection(<Part {...props} />, index + 1);
                }
              }}
              css={{ fontWeight: theme.font.weight.bold }}
            >
              <Icon name='copy' />
            </FloatingButton>
          )}
          {/* footer cannot be deleted in marketing mode */}
          {(!isMarketingMode || sectionType !== 'Footer') && (
            <FloatingButton onClick={onDelete} css={{ fontWeight: theme.font.weight.bold }}>
              <Icon name='trash' />
            </FloatingButton>
          )}
        </FloatingToolbarRight>
        {sectionType === 'Text' ? (
          <RTEToolbar selectedId={selectedId} id={id} initialHTML={initialHTML}>
            {children}
          </RTEToolbar>
        ) : (
          children
        )}
        {sectionType === 'Button' && selectedId === id && <ButtonsToolbar link={link} linkType={linkType} />}
        {sectionType === 'ReviewButton' && selectedId === id && (
          <ReviewButtonsToolbar link={link} reviewLinkType={reviewLinkType} />
        )}
        {sectionType === 'Footer' && selectedId === id && (
          <FooterToolbar
            links={links}
            mode={mode}
            setMode={setMode}
            selectedLink={selectedLink}
            setSelectedLink={setSelectedLink}
            settings={settings}
          />
        )}
      </section>
    );
  }
);

BaseComposerSectionContent.displayName = 'ContentSection';
