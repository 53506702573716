import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useQuery, useMutation, UseMutateAsyncFunction, useQueryClient } from 'react-query';
import { FormsDigitization, FormsQueryKeys } from '@frontend/api-forms';
import { useTranslation } from '@frontend/i18n';
import { useAlert } from '@frontend/design-system';

interface LoaderData {
  display: boolean;
  message: string;
}

interface UseFormsUploadProps {
  locationId: string;
}

interface UseFormsUploadResults {
  uploadedForms: FormsDigitization.Types.UploadedForm[];
  isLoadingUploadedForms: boolean;
  formsFinishedStatus: FormsDigitization.Types.FormsSubmissionResponse | undefined;
  isLoadingFormsFinishedStatus: boolean;
  showDefaultUploader: boolean;
  isErrorFetchingUploadedForms: boolean;
  formsSubmissionFinished: boolean;
  loader: LoaderData;
  setLoader: Dispatch<SetStateAction<LoaderData>>;
  setFormsSubmissionFinished: Dispatch<SetStateAction<boolean>>;
  uploadForms: UseMutateAsyncFunction<any, unknown, FormsDigitization.Types.UploadFormRequest, unknown>;
  deleteForms: UseMutateAsyncFunction<
    FormsDigitization.Types.DeleteFormResponse,
    unknown,
    FormsDigitization.Types.DeleteFormRequest,
    unknown
  >;
}

const EMPTY_UPLOADED_FORMS: FormsDigitization.Types.UploadedForm[] = [];

/**
 * @deprecated
 */
export const useFormsUpload = ({ locationId }: UseFormsUploadProps): UseFormsUploadResults => {
  const alert = useAlert();
  const queryClient = useQueryClient();
  const { t } = useTranslation('forms');

  const queryKeys = {
    uploadedForms: [FormsQueryKeys.digitization.uploadedForms, locationId],
    formsFinishedStatus: [FormsQueryKeys.digitization.digitizationStatus, locationId],
  };

  const [loader, setLoader] = useState<LoaderData>({
    display: true,
    message: t('Loading...'),
  });
  const [showDefaultUploader, toggleShowDefaultUploader] = useState<boolean>(false);
  const [formsSubmissionFinished, setFormsSubmissionFinished] = useState<boolean>(false);

  const {
    data: uploadedForms,
    isLoading: isLoadingUploadedForms,
    isError: isErrorFetchingUploadedForms,
  } = useQuery(queryKeys.uploadedForms, () => FormsDigitization.API.getUploadedForms(locationId), {
    onSuccess: (response) => {
      if (response.length === 0) {
        toggleShowDefaultUploader(true);
      } else {
        toggleShowDefaultUploader(false);
      }
    },
    onError: () => {
      /**
       * If the user hasn't uploaded any forms, there won't be a folder
       * in the drive, so we will get a 400 error.
       */
      alert.error(t('Failed to retrieve form list'));
      toggleShowDefaultUploader(true);
    },
    cacheTime: 0,
    staleTime: 0,
  });

  const { data: formsFinishedStatus, isLoading: isLoadingFormsFinishedStatus } = useQuery(
    queryKeys.formsFinishedStatus,
    () => FormsDigitization.API.getFormsFinishedStatus(locationId),
    {
      onError: () => {
        alert.warning(t('Something went wrong. Please try again'));
      },
      cacheTime: 0,
      staleTime: 0,
    }
  );

  const { mutateAsync: uploadForms } = useMutation(FormsDigitization.API.uploadForm, {
    onSuccess: () => {
      return queryClient.invalidateQueries(queryKeys.uploadedForms);
    },
  });

  const { mutateAsync: deleteForms } = useMutation(FormsDigitization.API.deleteUploadedForm, {
    onSuccess: (response, payload) => {
      if (response.success) {
        queryClient.setQueryData(
          queryKeys.uploadedForms,
          (oldData: FormsDigitization.Types.UploadedForm[] | undefined) => {
            if (!oldData) {
              return [];
            }

            return oldData.filter((form) => !payload.file_ids.includes(form.id));
          }
        );
      }
    },
  });

  const _uploadedForms = uploadedForms ?? EMPTY_UPLOADED_FORMS;

  // Reset state when the forms submission status is fetched
  useEffect(() => {
    if (isLoadingFormsFinishedStatus && isLoadingUploadedForms) {
      resetState();
      return;
    }

    if (!isLoadingFormsFinishedStatus && !isLoadingUploadedForms) {
      setLoader({ display: false, message: '' });
    }
  }, [isLoadingFormsFinishedStatus, isLoadingUploadedForms]);

  // Set the forms submission finished state
  useEffect(() => {
    if (isLoadingFormsFinishedStatus || !formsFinishedStatus) {
      return;
    }

    if (['1', 'true'].includes(formsFinishedStatus.status)) {
      setFormsSubmissionFinished(true);
      toggleShowDefaultUploader(false);
    }
  }, [formsFinishedStatus, isLoadingFormsFinishedStatus]);

  function resetState() {
    toggleShowDefaultUploader(false);
    setFormsSubmissionFinished(false);
  }

  return {
    uploadedForms: _uploadedForms,
    isLoadingUploadedForms,
    formsFinishedStatus,
    isLoadingFormsFinishedStatus,
    showDefaultUploader,
    isErrorFetchingUploadedForms,
    formsSubmissionFinished,
    loader,
    setLoader,
    setFormsSubmissionFinished,
    uploadForms,
    deleteForms,
  };
};
