import React, { forwardRef, useRef, useState } from 'react';
import { css } from '@emotion/react';
import dayjs from 'dayjs';
import { AnimatePresence, motion, useInView } from 'framer-motion';
import { getUser, localStorageHelper } from '@frontend/auth-helpers';
import { Page } from '@frontend/components';
import { WidgetLibraryProvider, WidgetizedDashboardTrackingIds } from '@frontend/grid-dashboard';
import { useTranslation, Trans } from '@frontend/i18n';
import { Icon, type IconName } from '@frontend/icons';
import { NWXDashboardTasksSection } from '@frontend/onboarding-modules';
import { breakpoints, useMatchMedia } from '@frontend/responsiveness';
import { OnlineSchedulingDashboardPromoCard } from '@frontend/self-upgrade';
import { useHasFeatureFlag } from '@frontend/shared';
import { useShell } from '@frontend/shell-utils';
import { theme } from '@frontend/theme';
import { Heading, Accordion, Text, Button, type ButtonProps } from '@frontend/design-system';
import { PortalPageLayout } from '../../../src/layout';
import { AllAppsModule } from '../dashboard/all-apps-module';
import { DownloadBanner } from './download-banner';
import { GridDashboard } from './grid-dashboard';
import { getWelcomeGreeting } from './helpers';
import { useGridDashboard, useWidgetConfig } from './store';
import { useAccessibleWidgetConfig } from './use-accesible-widgets-config';
import { DashboardWidgetLibrary } from './widget-library';

const DOWNLOAD_WEAVE_APP_BANNER = 'new-dashboard-download-weave-app';

export const GridDashboardPage = () => {
  const portalPageRef = useRef<HTMLDivElement | null>(null);
  const { t } = useTranslation('dashboard');
  const { isShell } = useShell();
  const user = getUser();

  return (
    <>
      <PortalPageLayout
        ref={portalPageRef}
        css={css`
          background-color: ${theme.colors.neutral5};
        `}
      >
        <NWXDashboardTasksSection />
        <Page
          maxWidth={1200}
          css={css`
            position: relative;
            background-color: ${theme.colors.neutral5};
          `}
          title={<GridDashboardTitle />}
          customHeader={
            <HeaderComponent ref={portalPageRef}>
              <GridDashboardTitle />
              <GridDashboardPageAction />
            </HeaderComponent>
          }
        >
          <SimpleViewAccordionWrapper />
          <GridDashboard />
        </Page>
        <div css={{ display: 'flex', flexDirection: 'column', alignItems: 'end', position: 'sticky', bottom: 0 }}>
          <OnlineSchedulingDashboardPromoCard
            cardStyles={{ position: 'relative', bottom: theme.spacing(2), right: theme.spacing(2) }}
          />
          {!isShell && (
            <DownloadBanner
              css={css`
                position: relative;
                bottom: 0;
                border-radius: 0;
              `}
              actionLabel={t('Download Desktop App')}
              shouldShowBanner={() => shouldShowBanner(user?.userID)}
              onCloseClick={(shouldReappear) => {
                localStorageHelper.create(
                  `${DOWNLOAD_WEAVE_APP_BANNER}-${user?.userID}`,
                  !shouldReappear ? `${false}` : dayjs().toISOString()
                );
              }}
            >
              <Trans t={t}>
                <strong>Psst, Down here!</strong> Have you tried our desktop app? Download our desktop app for faster
                performance, more features, native notifications, and enhanced personalization!
              </Trans>
            </DownloadBanner>
          )}
        </div>
      </PortalPageLayout>
      <WidgetLibraryProvider>
        <DashboardWidgetLibrary />
      </WidgetLibraryProvider>
    </>
  );
};

function shouldShowBanner(userId?: string): boolean {
  const storedDate = localStorageHelper.get(`${DOWNLOAD_WEAVE_APP_BANNER}-${userId}`) as string | false;
  if (storedDate === false) return false;

  if (!storedDate) return true;

  const closedDate = dayjs(storedDate);
  const currentDate = dayjs();
  return currentDate.diff(closedDate, 'day') >= 3;
}

const GridDashboardTitle = () => {
  const user = getUser();
  const { greeting, nameWithPunctuation } = getWelcomeGreeting(user?.firstName ?? '');
  const { isEditMode } = useGridDashboard(['isEditMode']);

  return (
    <AnimatePresence>
      {isEditMode ? (
        <EditModeActions />
      ) : (
        <Heading
          css={css`
            @media screen and (max-width: ${breakpoints.small.max}px) {
              font-size: ${theme.font.size.h2};
            }

            @media screen and (max-width: ${breakpoints.small.max}px) {
              margin-top: ${theme.spacing(1)};
            }
          `}
        >
          <span
            css={css`
              font-weight: ${theme.font.weight.regular};
            `}
          >
            {greeting}
          </span>
          <span>{nameWithPunctuation}</span>
        </Heading>
      )}
    </AnimatePresence>
  );
};

interface HeaderComponentProps {
  children: React.ReactNode;
}

const HeaderComponent = forwardRef<HTMLElement, HeaderComponentProps>(({ children }, ref) => {
  const headerRef = useRef(null);
  const { isEditMode } = useGridDashboard(['isEditMode']);
  const isInView = useInView(headerRef, { root: ref as React.RefObject<HTMLElement>, amount: 'all', margin: '-10px' });

  return (
    <motion.header
      ref={headerRef}
      css={[
        css`
          display: flex;
          justify-content: space-between;
          padding: ${theme.spacing(1, 1.5)};
          border-radius: ${theme.borderRadius.medium};
          z-index: ${theme.zIndex.highest};
          margin-bottom: ${theme.spacing(1.5)};
          transition: 0.2s ease;
        `,
        isEditMode &&
          css`
            align-items: center;
            position: sticky;
            top: ${theme.spacing(1)};
          `,
        isEditMode &&
          !isInView &&
          css`
            box-shadow: ${theme.shadows.floating};
            background: ${theme.colors.white};
          `,
      ]}
    >
      {children}
    </motion.header>
  );
});

HeaderComponent.displayName = 'HeaderComponent';

const GridDashboardPageAction = () => {
  const { t } = useTranslation('dashboard');
  const { isEditMode, setIsEditMode } = useGridDashboard(['isEditMode', 'setIsEditMode']);
  const isSmallBreakpoint = useMatchMedia({ maxWidth: breakpoints.small.max });

  return (
    <div
      css={css`
        display: flex;
        height: 54px; //to prevent layout shifting
        align-items: center;
        gap: ${theme.spacing(1)};
      `}
    >
      {isEditMode ? (
        <SaveCancelActions />
      ) : isSmallBreakpoint ? (
        <Button
          iconName='edit'
          css={css`
            margin-left: ${theme.spacing(1)};
          `}
          onClick={() => setIsEditMode(true)}
          hoverLabel={t('Edit Dashboard')}
          aria-label={t('Edit Dashboard')}
          trackingId={WidgetizedDashboardTrackingIds.editDashboard}
        />
      ) : (
        <Button
          css={[
            dashboardActionStyle,
            css`
              margin-left: ${theme.spacing(1)};
            `,
          ]}
          onClick={() => setIsEditMode(true)}
          trackingId={WidgetizedDashboardTrackingIds.editDashboard}
        >
          {t('Edit Dashboard')}
        </Button>
      )}
    </div>
  );
};

const BROWSER_MINIMUM_DESKTOP_WIDTH = 500;

const SimpleViewAccordionWrapper = () => {
  const isSmall = useMatchMedia({ maxWidth: BROWSER_MINIMUM_DESKTOP_WIDTH });
  const enableAllAppsViewFF = useHasFeatureFlag('enable-all-apps-view');

  if (!enableAllAppsViewFF || !isSmall) return null;

  return (
    <div
      css={css`
        padding: 0 8px;
      `}
    >
      <SimpleViewAccordion />
    </div>
  );
};

const SIMPLE_VIEW_ACCORDION_OPEN_STATE = 'new-dashboard-simple-view-open-state';

const SimpleViewAccordion = () => {
  const { t } = useTranslation('dashboard');
  const user = getUser();
  const [isOpen, setIsOpen] = useState(() =>
    !!localStorageHelper.get(`${user?.userID}-${SIMPLE_VIEW_ACCORDION_OPEN_STATE}`) ? 'all-apps' : null
  );

  return (
    <Accordion
      controlledValue={isOpen}
      onChange={(value) => {
        localStorageHelper.create(`${user?.userID}-${SIMPLE_VIEW_ACCORDION_OPEN_STATE}`, value as string);
        setIsOpen(value);
      }}
      chevronSize={16}
      showBoxShadow={false}
    >
      <Accordion.Item value='all-apps' trackingId={WidgetizedDashboardTrackingIds.allAppsTrigger(!!isOpen)}>
        <Accordion.Header>
          <span
            css={css`
              display: flex;
              align-items: center;
              gap: ${theme.spacing(0.5)};
            `}
          >
            <Icon
              name='computer-small'
              css={css`
                color: ${theme.colors.neutral70};
              `}
            />
            <Text size='medium' weight='bold'>
              {t('All Apps')}
            </Text>
          </span>
        </Accordion.Header>
        <Accordion.Body
          css={css`
            background: ${theme.colors.white};
          `}
        >
          <AllAppsModule showHeader={false} />
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export const EditModeActions = () => {
  const { openWidgetLibrary } = useGridDashboard(['openWidgetLibrary']);
  const { t } = useTranslation('dashboard');
  const { tidyLayout } = useWidgetConfig(['tidyLayout']);

  return (
    <div
      css={css`
        display: flex;
        gap: ${theme.spacing(2)};
        height: 54px; // to prevent layout shifting when switching between the header
        align-items: center;
        @media screen and (max-width: ${breakpoints.small.max}px) {
          height: 36px;
        }
      `}
    >
      <SecondaryButtonAction
        trackingId={WidgetizedDashboardTrackingIds.addWidgets}
        onClick={openWidgetLibrary}
        icon='add-small'
      >
        {t('Add Widget')}
      </SecondaryButtonAction>
      <SecondaryButtonAction
        trackingId={WidgetizedDashboardTrackingIds.tidyLayout}
        onClick={tidyLayout}
        icon='tidy-small'
      >
        {t('Tidy Layout')}
      </SecondaryButtonAction>
    </div>
  );
};

interface SecondaryButtonActionProps extends ButtonProps {
  icon?: IconName;
}

export const SecondaryButtonAction = ({ icon, children, ...rest }: SecondaryButtonActionProps) => {
  const isMobile = useMatchMedia({ maxWidth: breakpoints.small.max });

  if (icon && isMobile) {
    return <Button {...(rest as ButtonProps)} size='large' iconName={icon} variant='secondary' />;
  }

  return (
    <Button
      {...rest}
      variant='secondary'
      css={css`
        font-weight: ${theme.font.weight.bold};
        white-space: nowrap;
        color: ${theme.colors.neutral70};
        border: 1px solid ${theme.colors.neutral70};
        gap: ${theme.spacing(0.5)};
        :hover,
        :focus {
          background-color: ${theme.colors.neutral10};
        }
      `}
    >
      {icon && <Icon name={icon} />}
      <span>{children}</span>
    </Button>
  );
};

export const SaveCancelActions = () => {
  const { t } = useTranslation('dashboard');
  const { setIsEditMode } = useGridDashboard(['setIsEditMode']);
  const { accessibleWidgets: widgetConfigs, setWidgetConfigs } = useAccessibleWidgetConfig();
  const [currentWidgetConfig] = useState(widgetConfigs);

  return (
    <>
      <Button
        variant='secondary'
        css={[
          dashboardActionStyle,
          css`
            border: none;
            font-weight: ${theme.font.weight.bold};
            color: ${theme.colors.neutral70};
            :hover,
            :focus {
              background-color: ${theme.colors.neutral10};
            }
          `,
        ]}
        onClick={() => {
          setWidgetConfigs(currentWidgetConfig);
          setIsEditMode(false);
        }}
        trackingId={WidgetizedDashboardTrackingIds.cancelLayout}
      >
        {t('Cancel')}
      </Button>
      <Button
        trackingId={WidgetizedDashboardTrackingIds.saveLayout}
        css={dashboardActionStyle}
        onClick={() => setIsEditMode(false)}
      >
        {t('Save')}
      </Button>
    </>
  );
};

const dashboardActionStyle = css`
  width: 144px;
  @media screen and (max-width: ${breakpoints.small.max}px) {
    width: auto;
  }
  height: 40px;
  font-size: ${theme.font.size.large};
  white-space: nowrap;
`;
