import { useCallback, useState } from 'react';
import { css } from '@emotion/react';
import { useDropzone } from 'react-dropzone';
import { logOnboardingSentryError } from '@frontend/api-intake-form';
import { MediaApi, MediaTypes } from '@frontend/api-media';
import { useDevToolsStore } from '@frontend/devtools';
import { Trans, i18next, useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { SchemaPortingDataService } from '@frontend/schema';
import { theme } from '@frontend/theme';
import {
  BannerNotification,
  Heading,
  NakedButton,
  SpinningLoader,
  Stepper,
  Text,
  TextLink,
  useAlert,
} from '@frontend/design-system';
import { trackingIds } from '../../constants';
import { CountryCodes } from '../../helper/state-codes';
import { usePortOrderCreationStore } from '../../providers';
import { PhoneBillInfo } from '../../types';
import { smallBannerStyle } from '../styles';

const MAX_FILES = 5;
const MAX_FILE_SIZE = 15; // 15MB
const ACCEPTED_FILE_TYPE = {
  'application/pdf': ['.pdf'],
  // 'image/*': ['.jpeg', '.jpg', '.png'], // Note: temporary remove support of image files
};

const validateFiles = (files: File[], alreadyUploadedFilesCount: number): string => {
  const invalidLargeFiles = files.filter((file) => file.size >= MAX_FILE_SIZE * 1e6);
  if (files.length <= 0) {
    return i18next.t('Invalid file format, only image files allowed.', { ns: 'porting' });
  } else if (invalidLargeFiles.length) {
    return i18next.t('Files size is too big, must be smaller than {{maxFileSize}} MB.', {
      ns: 'porting',
      maxFileSize: MAX_FILE_SIZE,
    });
  } else if (files.length + alreadyUploadedFilesCount > MAX_FILES) {
    return i18next.t('Max number of files exceeded. Can only upload {{maxFiles}} files.', {
      ns: 'porting',
      maxFiles: MAX_FILES,
    });
  }
  return '';
};

export const UploadPhoneBillsStep = () => {
  const { t } = useTranslation('porting');
  const alert = useAlert();
  const isDebugModeOn = useDevToolsStore((state) => state.options.isDebugModeOn);
  const { storePortOrder, portOrderResponse, updateStorePortOrder } = usePortOrderCreationStore([
    'storePortOrder',
    'portOrderResponse',
    'updateStorePortOrder',
  ]);
  const portDataId = portOrderResponse?.id ?? '';
  const uploadedFiles = storePortOrder.phoneBills ?? [];

  const isCanadianOffice = portOrderResponse?.serviceCountry === CountryCodes.Canada.Code;
  const [isUploading, setUploading] = useState(false);
  const [isDeleting, setDeleting] = useState(false);

  const onFileDrop = useCallback(
    (files: File[]) => {
      const errorMessage = validateFiles(files, uploadedFiles.length);
      if (errorMessage) {
        alert.warning(errorMessage);
        return;
      }
      handleUploadPhoneBill(files);
    },
    [uploadedFiles, portDataId]
  );

  const {
    getRootProps,
    getInputProps,
    open: openFileUploadDialog,
    isDragActive,
  } = useDropzone({
    accept: ACCEPTED_FILE_TYPE,
    noClick: true,
    noKeyboard: true,
    onDrop: onFileDrop,
    preventDropOnDocument: true,
    noDragEventsBubbling: true,
    multiple: true,
    disabled: isUploading || isDeleting,
  });

  const handleUploadPhoneBill = async (files: File[]) => {
    try {
      setUploading(true);
      const newUploadedFiles: PhoneBillInfo[] = [...uploadedFiles];
      await Promise.allSettled(
        files.map(async (file) => {
          try {
            const formData = new FormData();
            formData.append('files', file);

            const uploadMediaResponse = await MediaApi.uploadMedia({
              data: file,
              filename: file.name,
              type: MediaTypes.MediaUploadTypes.PortingPhoneBill,
              publicity: false,
            });
            const res = await SchemaPortingDataService.UploadPortOrderDocument({
              contentType: file.type,
              mediaData: {
                portingDataId: portDataId,
                mediaId: uploadMediaResponse.ID,
                fileName: file.name,
                mediaType: MediaTypes.MediaUploadTypes.PortingPhoneBill,
              },
            });

            newUploadedFiles.push({
              fileName: file.name,
              mediaDataId: res.mediaData?.id ?? '',
            });
          } catch (error) {
            alert.error(t('Failed to upload file: {{fileName}} ', { fileName: file.name }));
            logOnboardingSentryError('Error uploading phone bill in Port additional numbers page.', error);
          }
        })
      );

      updateStorePortOrder({
        phoneBills: newUploadedFiles,
      });
    } catch (error) {
      alert.error(t('Failed to upload file(s).'));
      logOnboardingSentryError('Error uploading phone bill(s) in Port additional numbers page.', error);
    } finally {
      setUploading(false);
    }
  };

  const handleRemovePhoneBill = async (mediaDataId: string) => {
    try {
      setDeleting(true);

      await SchemaPortingDataService.DeletePortOrderDocument({
        mediaDataId: mediaDataId,
        portingDataId: portDataId,
      });

      updateStorePortOrder({
        phoneBills: uploadedFiles.filter((phoneBill) => phoneBill.mediaDataId !== mediaDataId),
      });
    } catch (error) {
      logOnboardingSentryError('Error removing phone bill in port order creation.', error);
    } finally {
      setDeleting(false);
    }
  };

  const isFormValid = isDebugModeOn || (!isUploading && !isDeleting && (!isCanadianOffice || uploadedFiles.length > 0));
  const shouldShowSkipButton = isFormValid && uploadedFiles.length === 0;
  return (
    <>
      <Stepper.Title>{t('Upload Phone Bill')}</Stepper.Title>
      <Stepper.Content>
        <Heading level={3} css={{ fontSize: theme.fontSize(16), marginBottom: theme.spacing(1) }}>
          {t('Upload your most recent phone bill')}
        </Heading>
        <BannerNotification
          status='warn'
          css={[smallBannerStyle, { marginBottom: theme.spacing(isCanadianOffice ? 1 : 3) }]}
          message={t(
            'Weave will need a copy of your most recent phone bill dated within 30 days that shows your account number, authorized user, all phone numbers (main, fax, rollovers), and service address.'
          )}
        />
        {isCanadianOffice && (
          <BannerNotification
            status='warn'
            css={[smallBannerStyle, { marginBottom: theme.spacing(3) }]}
            message={t('Phone bill is required for all offices in Canada.')}
          />
        )}
        <section {...getRootProps()} css={{ opacity: isUploading || isDeleting ? 0.25 : 1 }}>
          <input {...getInputProps()} />
          <div css={fileUploadContainer(isDragActive)}>
            <Text textAlign='center' size='medium' color='light'>
              <Trans t={t}>
                Drag PDF file here or <TextLink onClick={openFileUploadDialog}>click to upload</TextLink>
              </Trans>
            </Text>
            <Text css={{ marginTop: theme.spacing(1) }} textAlign='center' size='small' color='light'>
              {t('Note: File must be no larger than 15MB.')}
            </Text>
          </div>
        </section>
        {(isUploading || isDeleting) && (
          <div css={loaderStyle}>
            <SpinningLoader size='small' />
            <Text color='light'>{isUploading ? t('Uploading phone bill...') : t('Deleting phone bill...')}</Text>
          </div>
        )}
        <ul css={listStyle}>
          {uploadedFiles.map(({ fileName, mediaDataId }) => (
            <li key={mediaDataId}>
              <Icon name='folder-check' color='light' />
              <Text css={{ flexGrow: 1, alignSelf: 'center' }}>{fileName}</Text>
              <NakedButton
                css={{ marginRight: theme.spacing(1) }}
                onClick={() => handleRemovePhoneBill(mediaDataId)}
                disabled={isUploading || isDeleting}
              >
                <Icon name='x-small' color='light' />
              </NakedButton>
            </li>
          ))}
        </ul>
      </Stepper.Content>
      <Stepper.ButtonBar>
        {shouldShowSkipButton && <Stepper.SkipButton css={{ fontWeight: theme.font.weight.semibold }} />}
        <Stepper.NextButton
          isValid={isFormValid}
          trackingId={`${trackingIds.submitPortRequest}_upload-phone-bill_next_btn`}
        >
          {t('Next')}
        </Stepper.NextButton>
      </Stepper.ButtonBar>
    </>
  );
};

const fileUploadContainer = (isDragActive: boolean) =>
  css({
    border: `dashed ${!isDragActive ? theme.colors.neutral20 : theme.colors.primary50} 2px`,
    backgroundColor: theme.colors.neutral5,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    borderRadius: theme.borderRadius.small,
    cursor: 'pointer',
    display: 'block',
    padding: theme.spacing(3, 12, 3),
    margin: theme.spacing(1),
  });

const loaderStyle = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: theme.spacing(1, 0),
  gap: theme.spacing(1),
});

const listStyle = css({
  maxHeight: 250,
  margin: theme.spacing(5, 0, 3, 0),
  overflow: 'auto',
  listStyle: 'none',
  padding: 0,
  display: 'flex',
  flexDirection: 'column',
  rowGap: theme.spacing(2),
  '> li': {
    display: 'flex',
    gap: theme.spacing(1),
  },
});
