import { BarChartAppearance } from '@frontend/charts';
import { useTranslation } from '@frontend/i18n';
import { formatters } from '../../../../utils';
import { MissedCallTextROICohortChart, ROIConversionChart } from '../../charts';
import {
  useFetchMissedCallTextConversionTotals,
  useROIShallowStore,
  useShowMissedCallTextRoiWidget,
} from '../../hooks';
import { getDateRangeText } from '../../utils/date-range-text';
import { AppointmentsScheduledDescription } from '../appointments-scheduled-description';

interface AppointmentsScheduledTabProps {
  appearance: BarChartAppearance;
  appointmentsScheduledDetails?: ReturnType<typeof useFetchMissedCallTextConversionTotals>;
  conversionColors: Record<string, string>;
  conversionLabels: Record<string, string>;
}

export const AppointmentsScheduledTab = ({
  appearance,
  appointmentsScheduledDetails,
  conversionColors,
  conversionLabels,
}: AppointmentsScheduledTabProps) => {
  const { t } = useTranslation('analytics');
  const { filters } = useROIShallowStore('filters');
  const { phase3Enabled } = useShowMissedCallTextRoiWidget();

  const totalAppointmentsScheduled = appointmentsScheduledDetails?.data?.totalMessagesCount ?? 0;
  const totalAppointmentsScheduledConversion = appointmentsScheduledDetails?.data?.totalAppointmentsCount ?? 0;

  return (
    <>
      <ROIConversionChart
        achievedValue={totalAppointmentsScheduledConversion}
        appearance={appearance}
        attributeType='backward'
        colors={conversionColors}
        description={
          <AppointmentsScheduledDescription
            achievedValue={formatters.value.format(totalAppointmentsScheduledConversion)}
            totalValue={formatters.value.format(totalAppointmentsScheduled)}
          />
        }
        infoTip={t('Conversion of appointments scheduled {{date}} as a result of previously sent missed call texts', {
          date: getDateRangeText(filters?.startDate, filters?.endDate),
        })}
        isLoading={appointmentsScheduledDetails?.isLoading}
        labels={conversionLabels}
        title={t('Conversion')}
        totalValue={totalAppointmentsScheduled}
      />

      {phase3Enabled && <MissedCallTextROICohortChart attributeType='backward' />}
    </>
  );
};
