import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const sectionStyle = css`
  max-width: 100%;
  margin-top: ${theme.spacing(3)};
`;

export const innerContainerStyle = css`
  height: 250px;
`;

export const getFileUploaderStyle = (isDragging: boolean) => css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-radius: ${theme.borderRadius.small};
  border: dashed ${!isDragging ? theme.colors.neutral20 : theme.colors.primary50} 1px;
  background-color: ${theme.colors.neutral5};
  cursor: pointer;
  display: block;
  img {
    width: 100%;
  }
`;

export const fileUploaderInnerContainerStyle = css`
  padding: ${theme.spacing(4, 12, 3)};
  text-align: center;
`;

export const iconContainerStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const textStyle = css`
  margin-bottom: ${theme.spacing(1)};
`;

export const buttonStyle = css`
  margin-top: ${theme.spacing(1)};
`;
