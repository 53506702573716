import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Modal, ModalControlModalProps, Text } from '@frontend/design-system';

export const DeleteImageModal = ({
  modalProps,
  onDelete,
}: {
  modalProps: ModalControlModalProps;
  onDelete: () => void;
}) => {
  const { t } = useTranslation('messages');

  return (
    <Modal {...modalProps} minWidth={600}>
      <Modal.Header onClose={modalProps.onClose}>{t('Are You Sure?')}</Modal.Header>
      <Modal.Body>
        <Text css={{ marginBottom: theme.spacing(3) }}>{t('Are you sure you want to delete this image?')}</Text>
      </Modal.Body>
      <Modal.Actions
        secondaryLabel={t('Cancel')}
        primaryLabel={t('Delete Image')}
        onSecondaryClick={modalProps.onClose}
        onPrimaryClick={onDelete}
        destructive
      />
    </Modal>
  );
};
