// TODO: We can break up this component into smaller components to make it easier to manage e.g. EmojiPicker, DynamicFieldPicker, etc.
import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
// TODO: Leaving here for a reference and will revisit the one styling logic for hiding the subject line on mobile when there is an element selected
// import { ROOT_NODE, useEditor } from '@craftjs/core';
import EmojiPicker, { EmojiClickData } from 'emoji-picker-react';
import { DynamicFieldTag, SIDEBAR_BREAKPOINTS, useDynamicField, useContentComposer } from '@frontend/content-composer';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import {
  BaseFieldProps,
  Button,
  DynamicFieldIconSmall,
  PopoverDialog,
  Text,
  TextField,
  useModalControl,
  usePopoverDialog,
} from '@frontend/design-system';
import { DeviceSize, DeviceSizeField } from './device-size-field';
import { EmailAssistantModal } from './email-assistant-modal';
import { PreviewButton } from './preview-button';
import { SendTestEmailButton } from './send-test-email-button';

const SUBJECT_FIELD_NAME = 'subject';

type Props = {
  deviceSizeProps: BaseFieldProps<DeviceSize>;
  promptProps: BaseFieldProps<string>;
  subjectProps?: BaseFieldProps<string>;
};

export const Header = ({ deviceSizeProps, promptProps, subjectProps }: Props) => {
  const { t } = useTranslation('email-composer');
  const { setActivePanel } = useContentComposer();
  const [subject, setSubject] = useState(subjectProps?.value ?? '');
  // TODO: Leaving here for a reference and will revisit the one styling logic for hiding the subject line on mobile when there is an element selected
  // const { actions, selectedId } = useEditor((state) => {
  //   const [currentNodeId] = state.events.selected;
  //   return {
  //     selectedId: currentNodeId,
  //   };
  // });

  // WEAVE ASSISTANT LOGIC
  const { modalProps, triggerProps } = useModalControl();

  // DYNAMIC FIELD LOGIC
  const { rteDynamicFields: fields } = useDynamicField('marketing', true);
  const {
    getDialogProps: getDynamicFieldsDialogProps,
    getTriggerProps: getDynamicFieldTriggerProps,
    isOpen: dynamicFieldDialogIsOpen,
    close: closeDynamicFieldDialog,
  } = usePopoverDialog({
    placement: 'bottom-start',
    initialOffset: {
      x: -170,
      y: 0,
    },
  });
  const onSelect = (field: DynamicFieldTag) => {
    const newSubject = subject + '[' + field.label + ']';
    subjectProps?.onChange({ ...subjectProps, value: newSubject, name: 'subject' });
    setSubject(newSubject);
    closeDynamicFieldDialog();
  };

  // EMOJI PICKER LOGIC
  const EMOJI_PICKER_WIDTH = 350;
  const EMOJI_PICKER_HEIGHT = 450;
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const {
    getDialogProps: getEmojiDialogProps,
    getTriggerProps: getEmojiTriggerProps,
    isOpen: emojiDialogIsOpen,
    close: closeEmojiDialog,
  } = usePopoverDialog({
    placement: 'bottom-start',
    initialOffset: {
      x: -252,
      y: 0,
    },
  });
  const handleSelect = (emoji: EmojiClickData) => {
    // using the subject instead of subjectProps.value because the value gets trimmed when the focus is removed from the input field
    const newSubject = subject + emoji.emoji;
    subjectProps?.onChange({ ...subjectProps, value: newSubject, name: 'subject' });
    setShowEmojiPicker(false);
    setSubject(newSubject);
    closeEmojiDialog();
  };
  useEffect(() => {
    let id: ReturnType<typeof setTimeout>;

    if (!emojiDialogIsOpen) {
      setShowEmojiPicker(false);
    }

    if (emojiDialogIsOpen) {
      id = setTimeout(() => setShowEmojiPicker(true), 400);
    }
    return () => {
      if (id) {
        clearTimeout(id);
      }
    };
  }, [emojiDialogIsOpen]);

  const deviceProps = { ...deviceSizeProps, value: deviceSizeProps?.value as DeviceSize };

  return (
    <>
      <section
        css={css`
          grid-area: header;
          display: grid;
          grid-template-columns: 1fr auto auto;
          align-items: center;
          padding: ${theme.spacing(2)};
          column-gap: ${theme.spacing(1)};
          border-bottom: solid 1px ${theme.colors.neutral20};
          max-width: 100vw;
          @media (max-width: ${SIDEBAR_BREAKPOINTS.mobile}px) {
            grid-template-areas: 'subject' 'bottom';
            grid-template-columns: auto;
            align-items: unset;
            row-gap: ${theme.spacing(2)};
            // TODO: cannot use the editor state here, re-add the escape JSX interpolation
            /* display: selectedId && selectedId !== ROOT_NODE ? 'none' : 'grid'; */

            transition: display 0.3s ease-in-out;
          }
        `}
      >
        <TextField
          label={t('Subject')}
          name={SUBJECT_FIELD_NAME}
          {...(subjectProps as BaseFieldProps<string>)}
          onChange={(e) => {
            // @ts-ignore DS TextField onChange event type
            const value = e.target.value;
            setSubject(value);
            subjectProps?.onChange({ ...subjectProps, value, name: 'subject' });
          }}
          css={css`
            padding-right: ${theme.spacing(1)};
            .subject-end-adornment {
              display: ${dynamicFieldDialogIsOpen || emojiDialogIsOpen ? 'flex' : 'none'};
            }
            &:focus-within {
              .subject-end-adornment {
                display: flex;
              }
            }
            @media (max-width: ${SIDEBAR_BREAKPOINTS.mobile}px) {
              grid-area: subject;
            }
          `}
          endAdornment={
            <div
              className='subject-end-adornment'
              css={css`
                align-items: center;
                column-gap: ${theme.spacing(1)};
                button {
                  height: auto;
                }
              `}
            >
              <Button
                variant='secondary'
                {...getDynamicFieldTriggerProps()}
                css={css`
                  display: flex;
                  align-items: center;
                  padding: ${theme.spacing(0.5, 1)};
                  width: ${theme.spacing(15.5)};
                  column-gap: ${theme.spacing(1)};
                `}
              >
                <DynamicFieldIconSmall />
                <Text
                  as='span'
                  size='small'
                  css={css`
                    width: fit-content;
                  `}
                >
                  {t('Dynamic Field')}
                </Text>
              </Button>
              <Button
                variant='secondary'
                {...getEmojiTriggerProps()}
                css={css`
                  padding: ${theme.spacing(0.125, 1)};
                  width: ${theme.spacing(5)};
                `}
              >
                <Icon name='emoji' />
              </Button>
              <Button
                variant='secondary'
                onClick={triggerProps.onClick}
                css={[
                  emailAssistantGradientStyling,
                  css`
                    padding: ${theme.spacing(0.625, 1)};
                    width: ${theme.spacing(5)};
                    border: 1px solid transparent;
                  `,
                ]}
              >
                <Icon name='aiassistant-small' />
              </Button>
            </div>
          }
        />
        {/* since this is being moved out to the consumer component this casting will not be necessary */}
        <DeviceSizeField {...(deviceProps as BaseFieldProps<DeviceSize>)} />
        <div
          css={css`
            height: 100%;
            max-height: 40px;
            display: flex;
            column-gap: ${theme.spacing(1)};
            align-items: center;

            @media (max-width: ${SIDEBAR_BREAKPOINTS.mobile}px) {
              grid-area: bottom;
              justify-content: space-between;
            }
          `}
        >
          <div
            css={css`
              display: flex;
              align-items: center;
              column-gap: ${theme.spacing(1)};
              height: 100%;
            `}
          >
            <Button
              variant='secondary'
              onClick={() => {
                setActivePanel('toolbox');
              }}
              css={css`
                height: 100%;
                width: fit-content;
                @media (min-width: ${SIDEBAR_BREAKPOINTS.mobile + 1}px) {
                  display: none;
                }
              `}
            >
              <Icon name='plus-small' />
            </Button>
            <PreviewButton {...(deviceProps as BaseFieldProps<DeviceSize>)} />
            <SendTestEmailButton subject={subjectProps?.value ?? ''} />
          </div>
          {/* TODO: connect the undo and redo logic here */}
          {/* <div
            css={css`
              display: flex;
              align-items: center;
              column-gap: ${theme.spacing(1)};
              height: 100%;
            `}
          >
            <Button
              variant='secondary'
              css={css`
                height: 100%;
                width: fit-content;
              `}
            >
              <Icon name='send-back-small' />
            </Button>
            <Button
              variant='secondary'
              css={css`
                height: 100%;
                width: fit-content;
                transform: rotate(180deg);
              `}
            >
              <Icon name='send-back-small' />
            </Button>
          </div> */}
        </div>
      </section>
      <EmailAssistantModal
        modalProps={modalProps}
        onComplete={(value: string) => subjectProps?.onChange({ ...subjectProps, value, name: 'subject' })}
        type='subject'
        previousText={subjectProps?.value}
        promptProps={promptProps}
      />
      <PopoverDialog
        {...getDynamicFieldsDialogProps()}
        returnFocus={false}
        css={css`
          width: 400px;
          border-radius: ${theme.borderRadius.medium};
        `}
      >
        {fields.map((field) => (
          <Button
            variant='secondary'
            key={field.symbol}
            size='small'
            onClick={() => onSelect(field)}
            css={css`
              margin-right: ${theme.spacing(1)};
              margin-bottom: ${theme.spacing(1)};
              height: auto;
              width: auto;
              padding: ${theme.spacing(0.5, 1)};
            `}
          >
            {field.label}
          </Button>
        ))}
      </PopoverDialog>
      <PopoverDialog
        {...getEmojiDialogProps()}
        css={css`
          width: ${EMOJI_PICKER_WIDTH}px;
          max-height: ${EMOJI_PICKER_HEIGHT};
          padding: 0;
          border-radius: ${theme.borderRadius.medium};
        `}
        returnFocus={false}
      >
        <div
          css={css`
            width: ${EMOJI_PICKER_WIDTH}px;
            height: ${EMOJI_PICKER_HEIGHT}px;
            display: flex;
            justify-content: center;
            align-items: center;
          `}
        >
          {showEmojiPicker && (
            <EmojiPicker
              css={css`
                font-family: ${theme.font.family};
              `}
              onEmojiClick={handleSelect}
              lazyLoadEmojis
              height={EMOJI_PICKER_HEIGHT}
              width={EMOJI_PICKER_WIDTH}
            />
          )}
        </div>
      </PopoverDialog>
    </>
  );
};

export const emailAssistantGradientStyling = css`
  background: linear-gradient(
    -90deg,
    ${theme.colors.secondary.eggplant20},
    ${theme.colors.primary20},
    ${theme.colors.secondary.eggplant20},
    ${theme.colors.primary20}
  );
  background-size: 400% 400%;
  animation: gradient 5s ease infinite;

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    background: linear-gradient(
      -90deg,
      ${theme.colors.secondary.eggplant20},
      ${theme.colors.primary20},
      ${theme.colors.secondary.eggplant20},
      ${theme.colors.primary20}
    );
    background-size: 400% 400%;
    animation: gradient 5s ease infinite;
  }

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;
