import { useQuery } from 'react-query';
import { Compulsory } from 'ts-toolbelt/out/Object/Compulsory';
import { LimitedSchemaQueryOptions } from '@frontend/react-query-helpers';
import { SchemaDigitalForms } from '../../service';
import { GetProviderMappingsIO } from '../../types';
import { defaultOptions } from '../config';
import { requestKeys } from '../keys';

export const useGetProviderMapping = <E = unknown, T = GetProviderMappingsIO['output']>(
  req: Compulsory<GetProviderMappingsIO['input'], 'companyId'>,
  options?: LimitedSchemaQueryOptions<GetProviderMappingsIO, E, T>
) => {
  const queryKey = requestKeys.providerMappings(req);
  const query = useQuery({
    queryKey,
    queryFn: () => SchemaDigitalForms.GetProviderMappings(req),
    ...defaultOptions,
    ...options,
  });

  return {
    queryKey,
    ...query,
  };
};
