import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Tabs } from '@frontend/design-system';
import { trackingIds } from '../../tracking-ids';
import { pageStyles } from '../../views/common-styles';
import { CallAnalyticsPanel, CallReportSummary, DurationAnalyticsPanel } from '.';

const DEFAULT_TAB_VIEW = 'call-analytics-view';

const defaultChartAppearance = {
  collectiveTooltip: true,
  margin: { left: 20 },
  showGridLines: true,
  showXAxis: true,
  showYAxis: true,
};

interface Props {
  onFetchStateChange: (isFetching?: boolean) => void;
}

type ChartPanelsLoadingState = {
  callAnalytics?: boolean;
  durationAnalytics?: boolean;
};

export const PhoneReportCharts = ({ onFetchStateChange }: Props) => {
  const { t } = useTranslation('analytics');
  const [chartPanelsLoadingState, setChartPanelsLoadingState] = useState<ChartPanelsLoadingState>({});

  useEffect(() => {
    const isFetching = Object.values(chartPanelsLoadingState).some((isLoading) => isLoading);
    onFetchStateChange(isFetching);
  }, [chartPanelsLoadingState]);

  return (
    <div css={styles.wrapper}>
      <CallReportSummary />

      <Tabs initialTab={DEFAULT_TAB_VIEW}>
        <div css={styles.tabsWrapper}>
          <Tabs.Bar css={pageStyles.customTabSelect}>
            <Tabs.Tab
              id='call-analytics-view'
              controls='call-analytics-view-panel'
              trackingId={trackingIds.phoneAnalytics.callAnalyticsTab}
            >
              {t('Call Analytics')}
            </Tabs.Tab>
            <Tabs.Tab
              id='duration-analytics-view'
              controls='duration-analytics-view-panel'
              trackingId={trackingIds.phoneAnalytics.durationAnalyticsTab}
            >
              {t('Duration Analytics')}
            </Tabs.Tab>
          </Tabs.Bar>
        </div>

        <Tabs.Panel css={styles.tabPanel} controller='call-analytics-view' id='call-analytics-view-panel'>
          <CallAnalyticsPanel
            defaultChartAppearance={defaultChartAppearance}
            onFetchStateChange={(value) => {
              setChartPanelsLoadingState((prevState) => ({ ...prevState, callAnalytics: value }));
            }}
          />
        </Tabs.Panel>
        <Tabs.Panel css={styles.tabPanel} controller='duration-analytics-view' id='duration-analytics-view-panel'>
          <DurationAnalyticsPanel
            defaultChartAppearance={defaultChartAppearance}
            onFetchStateChange={(value) => {
              setChartPanelsLoadingState((prevState) => ({ ...prevState, durationAnalytics: value }));
            }}
          />
        </Tabs.Panel>
      </Tabs>
    </div>
  );
};

const styles = {
  wrapper: css`
    overflow: auto;
  `,

  tabPanel: css`
    height: 100%;
    overflow: auto;
  `,

  tabsWrapper: css`
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: ${theme.spacing(1)};
    padding-bottom: ${theme.spacing(2)};
    top: ${theme.spacing(-2)};
    z-index: 1;
  `,
};
