import {
  CampaignType_Enum as CampaignType,
  List,
  ListListsRequest,
  Recipient,
} from '@weave/schema-gen-ts/dist/schemas/messaging/bulk/v2';
import { BulkMessagingMutations } from '@frontend/api-bulk-messaging';
import { useTranslation } from '@frontend/i18n';
import { BulkEmailPrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import { Button, Text, TextField, useFormField } from '@frontend/design-system';

type Props = {
  onAdd: (listId: List) => void;
  queryKey: (string | ListListsRequest)[];
  recipientsToUpload: Recipient[];
  setRecipientsToUpload: (recipients: Recipient[]) => void;
};

export const NewSavedListItem = ({ onAdd, queryKey, recipientsToUpload, setRecipientsToUpload }: Props) => {
  const { t } = useTranslation('bulk-messaging');
  const textFieldProps = useFormField({ type: 'text' });

  const baseListItem = recipientsToUpload[0];
  const campaignId = baseListItem.campaignId;
  const locationId = baseListItem.locationId;
  // TODO: this should be a prop
  const type = CampaignType.EMAIL;
  const newListItem = { campaignId, locationId, type };

  const { mutateAsync: uploadList } = BulkMessagingMutations.useCreateList(queryKey);

  return (
    <div
      css={{
        padding: theme.spacing(2),
        marginBottom: theme.spacing(1),
        border: `1px solid ${theme.colors.neutral10}`,
        borderRadius: theme.borderRadius.medium,
      }}
      data-trackingid={`${BulkEmailPrefixes.Audience}-contact-list-item`}
    >
      <Text size='large' weight='bold' css={{ marginBottom: theme.spacing(2) }}>
        {t('New List')}
      </Text>
      <div>
        {/* TODO: Make this save when enter is pressed. Cancelled when escape is pressed. */}
        <TextField
          {...textFieldProps}
          name='list-name'
          label={t('List Name')}
          css={{ marginBottom: theme.spacing(1) }}
          autoFocus
        />
        <Button
          variant='secondary'
          css={{ marginRight: theme.spacing(2) }}
          size='small'
          onClick={() => setRecipientsToUpload([])}
        >
          {t('Cancel')}
        </Button>
        <Button
          size='small'
          disabled={!textFieldProps.value}
          onClick={async () => {
            const newList = {
              ...newListItem,
              name: textFieldProps.value,
              recipients: recipientsToUpload,
            };
            const response = await uploadList({ list: newList });
            setRecipientsToUpload([]);
            if (response.listId) onAdd(response);
          }}
        >
          {t('Save')}
        </Button>
      </div>
    </div>
  );
};
