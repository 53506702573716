import { FC } from 'react';
import { css } from '@emotion/react';
import { CreditCardBrand, PaymentType } from '@frontend/api-invoices';
import { PaymentMethodStatus } from '@frontend/api-payment-plans';
import { Icon, IconName } from '@frontend/icons';
import { CardBrand, getExpirationDate } from '@frontend/payments-card-brand';
import { theme } from '@frontend/theme';
import { Radio, useControlledField, Text, Chip, IconButton, useTooltip, SkeletonLoader } from '@frontend/design-system';
import { getCardStatusLabel } from '../utils';

interface PaymentMethodCardUIProps {
  actions: {
    icon: IconName;
    label: string;
    onClick: () => void;
    disabled?: boolean;
  }[];
  brand?: CreditCardBrand | PaymentType;
  description?: string;
  disabled?: boolean;
  expMonth?: number;
  expYear?: number;
  last4: string;
  methodId: string;
  selected: boolean;
  status?: PaymentMethodStatus;
  title?: string;
  tooltipMessage?: string;
  onClick?: () => void;
}

export const PaymentMethodCardUI: FC<PaymentMethodCardUIProps> = ({
  actions,
  brand,
  description,
  disabled,
  expMonth,
  expYear,
  last4,
  methodId,
  selected,
  status,
  title,
  tooltipMessage,
  onClick,
}) => {
  const { Tooltip, tooltipProps, triggerProps } = useTooltip();

  const radioProps = useControlledField({
    type: 'radio',
    value: methodId,
    onChange: () => null,
  });

  const statusLabel = status ? getCardStatusLabel(status) : '';

  return (
    <div css={paymentCardStyles({ selected, disabled })} {...triggerProps} onClick={!disabled ? onClick : undefined}>
      <Radio tabIndex={0} {...radioProps} checked={selected} name={methodId} disabled={disabled} />
      <CardBrand brand={brand} css={{ opacity: disabled ? 0.5 : 1 }} />
      <div css={contentStyles({ disabled })}>
        <Text weight='bold' size='large'>
          {title}
        </Text>
        <div css={{ display: 'flex', gap: theme.spacing(0.5), alignItems: 'center' }}>
          {!!last4 && (
            <Text size='medium' weight='bold'>
              *{last4}
            </Text>
          )}
          {!!expMonth && !!expYear && <Text size='medium'>{getExpirationDate(expYear, expMonth)}</Text>}
          <Text size='medium'>{description}</Text>
        </div>
      </div>
      <div css={{ display: 'flex', alignItems: 'center', gap: theme.spacing(0.5) }}>
        {!!statusLabel && (
          <Chip variant={'warn'}>
            <div css={{ display: 'flex', alignItems: 'center', gap: theme.spacing(0.5) }}>
              <Icon name='star' size={14} css={{ display: 'inline-block' }} />
              {statusLabel}
            </div>
          </Chip>
        )}
        {actions?.map(({ disabled, icon, label, onClick }) => (
          <IconButton
            showLabelOnHover
            key={label}
            label={label}
            onClick={(e) => {
              e.stopPropagation();
              onClick();
            }}
            disabled={disabled}
          >
            <Icon name={icon} />
          </IconButton>
        ))}
      </div>
      {!!tooltipMessage && <Tooltip {...tooltipProps}>{tooltipMessage}</Tooltip>}
    </div>
  );
};

export const PaymentMethodCardLoader = () => {
  return (
    <div css={{ width: '100%', height: 82, flexShrink: 0 }}>
      <SkeletonLoader />
    </div>
  );
};

const paymentCardStyles = ({ selected, disabled }: { selected?: boolean; disabled?: boolean }) =>
  css({
    border: `1px solid ${selected ? theme.colors.primary50 : theme.colors.neutral20}`,
    borderRadius: theme.borderRadius.medium,
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    cursor: disabled ? 'default' : 'pointer',
    minHeight: theme.spacing(10),
  });

const contentStyles = ({ disabled }: { disabled?: boolean }) =>
  css({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5),
    flex: 1,
    opacity: disabled ? 0.5 : 1,
  });
