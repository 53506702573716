import { useMemo } from 'react';
import { useAppScopeStore } from '@frontend/scope';
import { appointmentsDemoData } from '../demo-data';
import { useIsAppointmentDemoAccount } from './use-is-appointment-demo-account';

export const useAppointmentAnalyticsDemoData = () => {
  const { selectedLocationIds } = useAppScopeStore();
  const isDemoAccount = useIsAppointmentDemoAccount();

  const demoData = useMemo(() => {
    if (typeof isDemoAccount === 'undefined' || !isDemoAccount) {
      return null;
    }

    return {
      chartsData: appointmentsDemoData.chartsData,
      tableData: appointmentsDemoData.tableData,
    };
  }, [selectedLocationIds.sort().join('-'), isDemoAccount]);

  return demoData;
};
