import { DashboardWidget, DashboardWidgetFC, FirstWordBoldText, useDashboardWidget } from '@frontend/grid-dashboard';
import { theme } from '@frontend/theme';
import { useTranslation } from '@frontend/i18n';
import { ContentLoader, numeric } from '@frontend/design-system';
import { css } from '@emotion/react';
import { SingleBarChart } from './single-bar-chart';
import { SingleDoughnutChart } from './single-doughnut-chart';
import { useAppScopeStore } from '@frontend/scope';
import { useMemo } from 'react';
import { useNavigate } from '@tanstack/react-location';
import { BulkMessagingQueries } from '@frontend/api-bulk-messaging';

/**
 * @dashboard-widget
 *
 * id: bulk-email-widget
 * title: Bulk Email
 * description: Track your email marketing allotment with a real-time progress bar and remaining message count display.
 * icon: email-small
 */
export const BulkEmailWidget: DashboardWidgetFC = () => {
  const { t } = useTranslation('bulk-messaging');
  const navigate = useNavigate();
  const { selectedLocationIds, selectedOrgId } = useAppScopeStore();
  const { currentSize } = useDashboardWidget();

  const year = new Date().getFullYear();
  const month = new Date().getMonth() + 1;
  const { isLoading, emailUsageInfoMap } = BulkMessagingQueries.useGetMultiBulkEmailUsageInfo({
    orgId: selectedOrgId,
    locationIds: selectedLocationIds,
    year,
    month,
  });
  const { totalCount, sentAndFailedCount, scheduledCount } = useMemo(() => {
    let totalCount = 0,
      sentAndFailedCount = 0,
      scheduledCount = 0;
    for (const { allotment, sentAndFailed, scheduled } of emailUsageInfoMap.values()) {
      totalCount += allotment;
      sentAndFailedCount += sentAndFailed;
      scheduledCount += scheduled;
    }
    return { totalCount, sentAndFailedCount, scheduledCount };
  }, [emailUsageInfoMap]);
  const remaining = totalCount - sentAndFailedCount - scheduledCount;

  const chartClickHandler = () => {
    navigate({ to: '/bulk/all-campaigns/email' });
  };

  const isTinySlimSize = currentSize === 'tiny-slim';
  return (
    <DashboardWidget>
      <DashboardWidget.Header />
      <DashboardWidget.Content css={[contentContainerStyle, { gap: theme.spacing(isTinySlimSize ? 0.5 : 2) }]}>
        <ContentLoader show={isLoading} />
        {isTinySlimSize ? (
          <SingleDoughnutChart
            remainingCount={remaining}
            scheduledCount={scheduledCount}
            sentCount={sentAndFailedCount}
            onClick={chartClickHandler}
          />
        ) : (
          <SingleBarChart
            remainingCount={remaining}
            scheduledCount={scheduledCount}
            sentCount={sentAndFailedCount}
            onClick={chartClickHandler}
          />
        )}
        <FirstWordBoldText
          css={{ strong: { fontSize: theme.fontSize(24), lineHeight: 1, color: theme.colors.neutral90 } }}
          color='light'
          size='medium'
        >
          {t('{{remainingCount}} Remaining', { remainingCount: numeric(remaining, { decimals: 0 }) })}
        </FirstWordBoldText>
      </DashboardWidget.Content>
    </DashboardWidget>
  );
};

BulkEmailWidget.config = {
  size: {
    extraSmall: 'tiny-slim',
    small: 'small-narrow',
    medium: 'small-wide',
    large: 'small-wide',
  },
  feature: 'email',
};

const contentContainerStyle = css({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
});
