import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyle = css`
  border: 1px solid ${theme.colors.neutral20};
  border-radius: ${theme.borderRadius.medium};
  padding: ${theme.spacing(1, 2)};
  margin-left: ${theme.spacing(2)};
  position: relative;
`;

export const textStyle = css`
  font-size: inherit;
`;

export const switchContainerStyle = css`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(1)};
  margin-top: ${theme.spacing(2)};
`;
