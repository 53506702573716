import { memo, useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { EmptyStateConfig, InfinitePaginatedList } from '@frontend/components';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { InfiniteQueryResult, UnpackArray } from '@frontend/types';
import { theme } from '@frontend/theme';
import {
  BaseFieldProps,
  ChipOptional,
  NakedButton,
  PopoverDialog,
  SearchField,
  SpinningLoader,
  Text,
  usePopoverDialog,
} from '@frontend/design-system';
import { DropdownOption, OptionType } from './DropdownOption';

export type CustomMultiSelectInfiniteDropdownOptionType = OptionType;

type InfiniteListData = Record<'rows', any>;
interface CustomMultiSelectInfiniteDropdownProps<T extends InfiniteListData> {
  infiniteQueryResult: InfiniteQueryResult<T>;
  getOptionInfo: (value: UnpackArray<T['rows']>) => OptionType;
  searchFieldProps: BaseFieldProps<string, '', HTMLInputElement>;
  emptyStateConfig?: EmptyStateConfig;
  label: string;
  placeholder?: string;
  isRequired?: boolean;
  selectedItems: OptionType[];
  setSelectedItems: (items: OptionType[]) => void;
  setHasError?: (hasError: boolean) => void;
  isLoading?: boolean;
}

const MultiSelectInfiniteDropdown = <T extends InfiniteListData>({
  infiniteQueryResult,
  getOptionInfo,
  searchFieldProps,
  emptyStateConfig,
  label,
  placeholder,
  isRequired = false,
  setHasError,
  selectedItems,
  setSelectedItems,
  isLoading,
}: CustomMultiSelectInfiniteDropdownProps<T>) => {
  const { t } = useTranslation('schedule');
  const popover = usePopoverDialog({ placement: 'bottom-start' });
  const [isTouched, setTouched] = useState(false);
  const onBlur = () => !isTouched && setTouched(true);
  const selectedValues = useMemo(() => selectedItems.map((item) => item.value), [selectedItems]);
  const hasError = isRequired && selectedItems.length === 0 && isTouched;

  const onSelect = (item: OptionType) => {
    const isSelected = selectedItems.some((i) => i.value === item.value);
    setSelectedItems(isSelected ? selectedItems.filter((i) => i.value !== item.value) : [...selectedItems, item]);

    if (!isTouched) setTouched(true);
  };

  useEffect(() => {
    const hasError = isRequired && selectedItems.length === 0 && isTouched;
    setHasError?.(hasError);
  }, [isRequired, selectedItems.length, isTouched]);

  return (
    <div>
      <Text size='small' color='light' css={{ marginBottom: theme.spacing(0.5) }}>
        {label}
      </Text>
      <NakedButton
        aria-haspopup='true'
        aria-expanded={popover.isOpen}
        aria-controls='dropdown-menu'
        css={fieldButtonStyle(popover.isOpen, hasError)}
        {...popover.getTriggerProps()}
        onBlur={onBlur}
      >
        {isLoading ? (
          <div css={{ display: 'flex', alignItems: 'center' }}>
            <SpinningLoader size='xs' />
            <Text size='small' color='light'>
              {t('Loading...')}
            </Text>
          </div>
        ) : (
          <>
            {!selectedItems.length && placeholder && (
              <Text css={{ color: hasError && !popover.isOpen ? theme.colors.critical50 : theme.colors.neutral30 }}>
                {placeholder}
              </Text>
            )}
            {selectedItems.map((item) => (
              <SelectedItemChip key={item.value} item={item} onRemove={onSelect} />
            ))}
          </>
        )}
        <Icon name='caret-down-small' css={dropdownIconStyle(popover.isOpen)} />
      </NakedButton>

      <PopoverDialog {...popover.getDialogProps()} css={{ width: 400 }}>
        <SearchField {...searchFieldProps} name='search-field' css={{ marginBottom: theme.spacing(1) }} />
        <InfinitePaginatedList
          height={300}
          infiniteQueryProps={infiniteQueryResult}
          renderListItem={({ listItem }) => {
            const option = getOptionInfo(listItem);
            return (
              <DropdownOption
                key={option.value}
                option={option}
                selected={selectedValues.includes(option.value)}
                onChange={onSelect}
              />
            );
          }}
          emptyStateConfig={emptyStateConfig ?? { type: 'schedule', header: t('No matches found') }}
          endOfListText={t('— End of List —')}
        />
      </PopoverDialog>
      {hasError && (
        <Text size='small' color='error'>
          {t('* This field is required')}
        </Text>
      )}
    </div>
  );
};
export const CustomMultiSelectInfiniteDropdown = memo(
  MultiSelectInfiniteDropdown
) as typeof MultiSelectInfiniteDropdown;

interface SelectedItemChipProps {
  item: OptionType;
  onRemove: (item: OptionType) => void;
}

const SelectedItemChip = ({ item, onRemove }: SelectedItemChipProps) => {
  return (
    <ChipOptional
      variant='outline'
      key={item.value}
      css={{ background: theme.colors.neutral5, button: { minWidth: 10 } }}
      size='default'
      rightElement={
        <NakedButton
          onClick={(e) => {
            e.stopPropagation();
            onRemove(item);
          }}
        >
          <Icon name='x-tiny' color='light' />
        </NakedButton>
      }
    >
      {item.label}
    </ChipOptional>
  );
};

const fieldButtonStyle = (isActive: boolean, hasError: boolean) =>
  css({
    position: 'relative',
    outline: isActive ? `1px solid ${theme.colors.primary50}` : 'none',
    border: isActive
      ? `1px solid ${theme.colors.primary50}`
      : `1px solid${hasError ? theme.colors.critical50 : theme.colors.neutral30}`,
    borderRadius: theme.borderRadius.small,
    padding: theme.spacing(1),
    display: 'flex',
    gap: theme.spacing(0.5),
    minHeight: 42,
    flexWrap: 'wrap',
    width: '100%',
    overflow: 'hidden',
    paddingRight: theme.spacing(4),
    ':focus': {
      outline: `1px solid ${theme.colors.primary50}`,
      border: `1px solid ${theme.colors.primary50}`,
    },
  });

const dropdownIconStyle = (active = false) =>
  css({
    cursor: 'default',
    pointerEvents: 'none',
    position: 'absolute',
    top: '50%',
    right: theme.spacing(2),
    transform: 'translateY(-50%)',
    transition: 'transform 250ms ease-out',
    ...(active && {
      transform: 'translateY(-50%) rotate(180deg)',
    }),
  });
