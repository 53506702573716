import { ListProvidersMultiIO } from '../types';
import {
  UseSchedulerV3InfiniteQueryEndpointArgs,
  useSchedulerV3InfiniteQuery,
} from './use-scheduler-v3-infinite-query';

type ListProvidersMultiPageParams = number;
/**
 * A hook that returns an infinite query for the `ListProvidersMulti` endpoint.
 * @param request The request object to pass to the query. Overriding pagination fields on the request is handled by the hook.
 * @param options (optional) The options to pass to `useInfiniteQuery`.
 * @param httpOptions (optional) The http options to pass to the schema function.
 * @param requestKeysToOmitFromQueryKey (optional) - An array of keys to omit from the query key. This is useful for cases where the request object contains keys that cause issues
 * when included in the query key, such as the current timestamp.
 */
export const useListProvidersMultiInfiniteQuery = <E = unknown, D = ListProvidersMultiIO['output']>({
  options,
  request,
  ...args
}: UseSchedulerV3InfiniteQueryEndpointArgs<'ListProvidersMulti', E, D, ListProvidersMultiPageParams>) =>
  useSchedulerV3InfiniteQuery<'ListProvidersMulti', E, D, ListProvidersMultiPageParams>({
    endpointName: 'ListProvidersMulti',
    ...args,
    request: ({ pageParam = 1 }) => {
      const requestWithPageParams: ListProvidersMultiIO['input'] = {
        ...request,
        page: pageParam,
      };
      return requestWithPageParams;
    },
    options: {
      ...options,
      getNextPageParam: (lastPage, allPages) => {
        const totalPages = Math.ceil((lastPage.totalCount ?? 0) / (request.limit ?? 1));
        return allPages.length < totalPages ? allPages.length + 1 : undefined;
      },
    },
  });
