import { useMemo } from 'react';
import { css } from '@emotion/react';
import { Chart } from '@frontend/charts';
import { formatCentsToCurrency } from '@frontend/currency';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Button } from '@frontend/design-system';

interface ChartDataInfo {
  key: string;
  label: string;
  value: number;
}

interface Props<T extends ChartDataInfo[]> {
  data: T;
}

const chartStyle = css({
  marginBottom: 0,
  height: 144,
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),

  '[aria-describedby="chart"]': {
    padding: 0,
    border: 'none !important',
  },

  // width: '100%',
  // height: '144px',
});

const chartColors = {
  paid: theme.colors.success40,
  outstanding: theme.colors.warning50,
  empty: theme.colors.neutral10,
};

export const InvoiceSummaryChart = <T extends ChartDataInfo[]>({ data }: Props<T>) => {
  const { t } = useTranslation();

  const { labels, groups } = useMemo(() => {
    return data.reduce<{
      labels: Record<string, string>;
      groups: { name: string; value: number }[];
      legendValues: Record<string, string | number>;
    }>(
      (acc, item) => {
        acc.labels[item.key] = item.label;
        acc.groups.push({
          name: item.key,
          value: item.value / 100,
        });
        acc.legendValues[item.key] = (formatCentsToCurrency(item.value, false) || 0).toString();
        return acc;
      },
      {
        labels: {},
        groups: [],
        legendValues: {},
      }
    );
  }, [data]);

  return (
    <div style={{ height: 144 }}>
      <Chart colors={chartColors} labels={labels} css={chartStyle}>
        <Chart.PieChart
          appearance={{
            innerRadius: '75%',
            outerRadius: '100%',
            borderRadius: 25,
            paddingAngle: 0,
            hideTooltip: false,
            startAngle: 180,
            endAngle: 0,
            animationDuration: 1000,
          }}
          formatValue={(value) => {
            return formatCentsToCurrency(value * 100, false) || '$0.00';
          }}
          data={{
            responsiveCenterLabels: {
              primaryText: {
                label: (totalValue) => (formatCentsToCurrency(totalValue * 100, false) || 0).toString(),
              },
              secondaryText: {
                label: t('Total Requested'),
              },
              textVerticalOffset: -18,
            },
            groups,
          }}
        />
      </Chart>
    </div>
  );
};

const emptyChartStyle = {
  wrapper: css({ position: 'relative' }),
  overlay: css({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
  overlayButton: css({ paddingLeft: theme.spacing(1), paddingRight: theme.spacing(1) }),
};

type EmptyInvoiceChartProps = {
  onClickOverlayBtn: () => void;
  trackingId: string;
};
export const EmptyInvoiceChart = ({ onClickOverlayBtn, trackingId }: EmptyInvoiceChartProps) => {
  const { t } = useTranslation();

  const labels = {
    empty: t('Request Payment'),
  };
  const groups = [
    {
      name: 'empty',
      value: 1,
    },
  ];

  return (
    <div css={emptyChartStyle.wrapper}>
      <Chart colors={chartColors} labels={labels} css={chartStyle}>
        <Chart.PieChart
          appearance={{
            innerRadius: '75%',
            outerRadius: '100%',
            borderRadius: 25,
            paddingAngle: 0,
            hideTooltip: true,
            startAngle: 180,
            endAngle: 0,
            animationDuration: 0,
          }}
          data={{
            responsiveCenterLabels: {
              primaryText: {
                label: '',
              },
              secondaryText: {
                label: t('Request Payment'),
              },
              textVerticalOffset: -18,
            },
            groups,
          }}
        />
      </Chart>
      <div className='button-overlay' css={emptyChartStyle.overlay}>
        <div css={{ marginTop: theme.spacing(3) }}>
          <Button
            variant='secondary'
            trackingId={`${trackingId}--empty-chart--payment-request`}
            css={emptyChartStyle.overlayButton}
            onClick={onClickOverlayBtn}
          >
            <Icon name='plus' size={16} />
          </Button>
        </div>
      </div>
    </div>
  );
};
