import { AppointmentTypesTypes } from '@frontend/api-appointment-types';
import { PersonTypes } from '@frontend/api-person';
import { ReducedAppointmentType } from '../hooks/useReviewRequest';

export type CreateAppointmentRequestParamsType = {
  selectedPerson: PersonTypes.Person | null;
  selectedProviderId: string;
  selectedApptType: AppointmentTypesTypes.AppointmentType | ReducedAppointmentType | undefined;
  selectedWorkstationId: string | undefined;
  startDate: string;
  sourceId: string;
  selectedProviderName: string;
};

export type CreateWritebackAppointmentV3PayloadArgs = {
  selectedPerson: PersonTypes.Person;
  selectedApptType: ReducedAppointmentType | undefined;
  selectedProviderId: string;
  selectedWorkstationId: string | undefined;
  startDate: string;
  sourceId: string;
  selectedProviderName: string;
};

export enum ScheduleRequestModalTypeEnum {
  WRITEBACK_ERROR = 'WRITEBACK_ERROR',
  MESSAGE_SENT = 'MESSAGE_SENT',
  ERROR = 'ERROR',
  MESSAGE_ERROR = 'MESSAGE_ERROR',
  NO_WRITEBACKS_ERROR = 'NO_WRITEBACKS_ERROR',
  NO_WRITEBACKS_MESSAGE_SENT = 'NO_WRITEBACKS_MESSAGE_SENT',
}

export type SourceTenantListItem = {
  sourceTenantId?: string;
  parentSourceTenantId?: string;
  sourceId?: string;
  name?: string;
  pmsName?: string;
};
