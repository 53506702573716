import { PropsWithChildren, useMemo } from 'react';
import { css } from '@emotion/react';
import { Permission } from '@weave/schema-gen-ts/dist/shared/waccess/acls.pb';
import { FeatureLocked } from '@frontend/empty-states#svg';
import { useTranslation } from '@frontend/i18n';
import { Page } from '@frontend/page';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';
import { useIsAppointmentDemoAccount } from '../components/appointment/hooks';
import { useIsAutoRecallAnalyticsDemoAccount } from '../components/auto-recall/hooks';
import { useIsPhoneAnalyticsDemoAccount } from '../components/phone/hooks';
import { useIsPADemoAccount } from '../components/practice/hooks';
import { useIsMessagingAnalyticsDemoAccount } from '../components/sms/hooks';
import { useHasAclAccess } from '../hooks';

type Feature = 'appointment' | 'auto-recall' | 'dashboard' | 'messaging' | 'phone' | 'practice' | 'reviews';

interface AccessPageProps extends PropsWithChildren {
  feature: Feature;
  title: string;
}

export const AnalyticsAccessPage = ({ children, feature, title }: AccessPageProps) => {
  const { t } = useTranslation('analytics');
  const hasAclAccess = useHasAclAccess([Permission.ANALYTICS_READ]);
  const isPhoneAnalyticsDemoAccount = useIsPhoneAnalyticsDemoAccount();
  const isMessagingAnalyticsDemoAccount = useIsMessagingAnalyticsDemoAccount();
  const isAutoRecallAnalyticsDemoAccount = useIsAutoRecallAnalyticsDemoAccount();
  const isPracticeAnalyticsDemoAccount = useIsPADemoAccount();
  const isAppointmentAnalyticsDemoAccount = useIsAppointmentDemoAccount();

  const hasDemoDataAccess = useMemo(() => {
    switch (feature) {
      case 'dashboard':
        return (
          isPhoneAnalyticsDemoAccount ||
          isMessagingAnalyticsDemoAccount ||
          isAutoRecallAnalyticsDemoAccount ||
          isPracticeAnalyticsDemoAccount
        );

      case 'practice':
        return isPracticeAnalyticsDemoAccount;

      case 'auto-recall':
        return isAutoRecallAnalyticsDemoAccount;

      case 'phone':
        return isPhoneAnalyticsDemoAccount;

      case 'messaging':
        return isMessagingAnalyticsDemoAccount;

      case 'appointment':
        return isAppointmentAnalyticsDemoAccount;

      default:
        return false;
    }
  }, [
    isAppointmentAnalyticsDemoAccount,
    isAutoRecallAnalyticsDemoAccount,
    isMessagingAnalyticsDemoAccount,
    isPhoneAnalyticsDemoAccount,
    isPracticeAnalyticsDemoAccount,
    feature,
  ]);

  if (hasAclAccess || hasDemoDataAccess) {
    return children;
  } else {
    return (
      <Page maxWidth='100%'>
        <Page.Header>
          <Page.Header.Heading>
            <Page.Header.Title title={title} />
          </Page.Header.Heading>
        </Page.Header>

        <Page.Body>
          <div css={styles.noAccessWrapper}>
            <FeatureLocked style={{ maxWidth: 400, marginBottom: theme.spacing(5) }} />
            <Text color='subdued' textAlign='center'>
              {t('You do not have permission to view {{feature}}.', { feature: title })}
            </Text>
            <Text color='subdued' textAlign='center'>
              {t('Please contact your admin to upgrade your access.')}
            </Text>
          </div>
        </Page.Body>
      </Page>
    );
  }
};

const styles = {
  noAccessWrapper: css`
    align-items: center;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    padding-top: ${theme.spacing(8)};
  `,
};
