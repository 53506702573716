import { SchemaDigitalForms } from '@frontend/api-digital-forms';
import { SchemaIO, SchemaScheduleService } from '@frontend/schema';
import { useScopedQuery } from '@frontend/scope';

export const queryKeys = {
  base: 'email-composer',
  formList: () => [queryKeys.base, 'form-list'],
  packetList: () => [queryKeys.base, 'packet-list'],
  onlineScheduleLink: () => [queryKeys.base, 'online-scheduling-link'],
};

type SchemaGetFormList = SchemaIO<(typeof SchemaDigitalForms)['GetFormList']>;
export const useGetFormList = (request: SchemaGetFormList['input']) =>
  useScopedQuery({
    refetchOnMount: false,
    queryKey: queryKeys.formList(),
    queryFn: () => SchemaDigitalForms.GetFormList(request),
    enabled: !!request.companyId,
    select: (response) => response.data?.map((form) => ({ ...form, documentType: 'form' })),
  });

type SchemaGetPacketList = SchemaIO<(typeof SchemaDigitalForms)['GetPacketList']>;
export const useGetPacketList = (request: SchemaGetPacketList['input']) =>
  useScopedQuery({
    refetchOnMount: false,
    queryKey: queryKeys.packetList(),
    queryFn: () => SchemaDigitalForms.GetPacketList(request),
    enabled: !!request.companyId,
    select: (response) => response.data?.map((packet) => ({ ...packet, documentType: 'packet' })),
  });

type SchemaGetOnlineSchedulingLink = SchemaIO<(typeof SchemaScheduleService)['GetOnlineSchedulingLink']>;
export const useGetOnlineSchedulingLink = (request: SchemaGetOnlineSchedulingLink['input']) =>
  useScopedQuery({
    refetchOnMount: false,
    queryKey: queryKeys.onlineScheduleLink(),
    queryFn: () => SchemaScheduleService.GetOnlineSchedulingLink(request),
    enabled: !!request.locationId,
    select: (response) => response.url,
  });
