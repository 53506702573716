import { FC } from 'react';
import { css } from '@emotion/react';
import { Device, useQueryDevices } from '@frontend/api-phone-sync';
import { Trans, useTranslation } from '@frontend/i18n';
import { usePhoneConfigShallowStore } from '@frontend/phone-config';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import {
  Button,
  ButtonBar,
  CaretLeftIconSmall,
  ConfirmationModal,
  Modal,
  ModalControlResponse,
  SearchField,
  Text,
  TextLink,
  useFormField,
  useModalControl,
} from '@frontend/design-system';
import { usePhoneSyncMutations } from '../use-phone-sync-mutations';
import { isLocationGroup } from '../utils';
import { DeviceSection } from './device-section';

const VARIANTS = {
  DIALPAD: 'dialpad',
  LOCATION_PICKER: 'location-picker',
  CLICK_TO_CALL: 'click-to-call',
} as const;
interface ModalProps {
  closeModal?: () => void;
  deviceSelectModal: ModalControlResponse;
  locationIds: string[];
  pairDeviceButton?: React.ReactNode;
  variant: (typeof VARIANTS)[keyof typeof VARIANTS];
}

export const DeviceSelectModal: FC<React.PropsWithChildren<ModalProps>> = ({
  closeModal,
  deviceSelectModal,
  locationIds,
  variant,
  pairDeviceButton,
}) => {
  const { t } = useTranslation('phoneSync');
  const fieldProps = useFormField({ type: 'text' });
  const withoutDeviceModalProps = useModalControl();
  const { accessibleLocationData } = useAppScopeStore();
  const { phoneConfig } = usePhoneConfigShallowStore('phoneConfig', 'setPhoneConfig');
  const { connectDevice, disconnectDevice } = usePhoneSyncMutations();
  const { data: devices } = useQueryDevices(locationIds);

  const filteredAvailableDevices = devices?.filter((device) => {
    return (
      device.sipProfileId !== phoneConfig?.sipProfileId &&
      device.availableInContext &&
      device.name?.toLowerCase().includes(fieldProps.value.toLowerCase())
    );
  });

  const handleConnectDeviceModal = (data: Device) => {
    connectDevice({ sipProfileId: data.sipProfileId ?? '' });
    deviceSelectModal?.closeModal();
    closeModal?.();
  };

  let actionComponent;

  if (variant === VARIANTS.LOCATION_PICKER) {
    actionComponent = (
      <Modal.Footer
        tertiary={{
          label: (
            <span style={{ display: 'flex', alignItems: 'center', gap: theme.spacing(0.5) }}>
              <CaretLeftIconSmall /> {t('Go Back')}
            </span>
          ),
          onClick: () => {
            deviceSelectModal.closeModal();
          },
          trackingId: 'phn-portal-selectDevice-btn-goBack',
        }}
        secondary={{
          label: t('Continue Without Device'),
          onClick: () => {
            withoutDeviceModalProps.openModal();
            deviceSelectModal.closeModal();
          },
          trackingId: 'phn-portal-selectDevice-btn-continueWithoutDevice',
        }}
      />
    );
  } else if (variant === VARIANTS.DIALPAD) {
    actionComponent = (
      <Modal.Footer
        secondary={{
          label: t('Cancel'),
          onClick: () => {
            deviceSelectModal.closeModal();
          },
          trackingId: 'phn-portal-selectDevice-btn-continueWithoutDevice',
        }}
      />
    );
  } else if (variant === VARIANTS.CLICK_TO_CALL) {
    actionComponent = (
      <Modal.Footer
        css={css`
          grid-template-columns: 1fr auto;
          justify-items: end;
        `}
      >
        <TextLink
          weight='bold'
          style={{
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            gap: theme.spacing(0.5),
          }}
          onClick={() => {
            deviceSelectModal.closeModal();
          }}
          trackingId='phn-portal-selectDevice-lnk-goBack'
        >
          {t('Cancel')}
        </TextLink>
        {pairDeviceButton}
      </Modal.Footer>
    );
  }

  return (
    <>
      <Modal {...deviceSelectModal.modalProps} maxWidth={600}>
        <Modal.Header textAlign='left'>{t('Select Device')}</Modal.Header>
        <Modal.Body>
          <div css={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(2) }}>
            <Text css={css`margin: 0;}`}>
              {t(
                'The location you selected belongs to different phone systems. Since you can be connected to only one device within one phone system at a time, please select the device you would like to use to make or receive calls.',
                { count: locationIds.length }
              )}
            </Text>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <SearchField
                name='search'
                css={css`
                  width: ${theme.spacing(40)};
                `}
                {...fieldProps}
              />
              {variant === VARIANTS.LOCATION_PICKER && pairDeviceButton}
            </div>
            <div css={{ paddingBottom: theme.spacing(2) }}>
              {filteredAvailableDevices?.map((item, index) => (
                <DeviceSection
                  key={item.sipProfileId}
                  item={item}
                  index={index}
                  deviceSelectModal={deviceSelectModal}
                  handleConnectDeviceModal={handleConnectDeviceModal}
                  isLocationGroup={isLocationGroup(item, accessibleLocationData)}
                />
              ))}
            </div>
          </div>
        </Modal.Body>
        {actionComponent}
      </Modal>
      <ConfirmationModal
        {...withoutDeviceModalProps.modalProps}
        onConfirm={() => {
          if (phoneConfig) {
            disconnectDevice();
          }
          closeModal?.();
        }}
        onCancel={() => {
          deviceSelectModal.openModal();
        }}
        title={t('Continue Without Device?')}
        message={t(
          'Continuing without connecting a device will prevent you from placing or receiving calls within the Weave app.'
        )}
        cancelLabel={t('No, Go Back')}
        confirmLabel={t('Yes, Continue')}
        confirmTrackingId='phn-portal-selectDevice-btn-yesContinue'
        cancelTrackingId='phn-portal-selectDevice-btn-NoGoBack'
      />
    </>
  );
};

export const LosingAccessModal = ({
  modal,
  pairDeviceButton,
  onContinue,
}: {
  modal: ModalControlResponse;
  pairDeviceButton?: React.ReactNode;
  onContinue: () => void;
}) => {
  const { t } = useTranslation('phoneSync');

  return (
    <Modal {...modal.modalProps} maxWidth={600}>
      <Modal.Header textAlign='left'>{t('Losing Device Access')}</Modal.Header>
      <Modal.Body>
        <Trans t={t}>
          <Text css={{ marginBlock: '1em' }}>
            Based on your location selection, you will no longer have access to a device. As a result, you will not be
            able to place or receive calls within the Weave app.
          </Text>
          <Text css={{ marginBlock: '1em' }}>
            To resume device access, either update your location selection or pair a new device here.
          </Text>
        </Trans>
      </Modal.Body>
      <Modal.Footer>
        <ButtonBar
          removeSpacing
          css={css`
            display: flex;
            gap: ${theme.spacing(2)};
            justify-content: space-between;
          `}
        >
          <TextLink
            weight='bold'
            style={{
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              gap: theme.spacing(0.5),
              width: 'auto',
            }}
            onClick={() => {
              modal.closeModal();
            }}
            trackingId='phn-portal-losingAccess-btn-continue'
            data-test-id='loseAccess-goBack-btn'
          >
            <span style={{ display: 'flex', alignItems: 'center', gap: theme.spacing(0.5) }}>
              <CaretLeftIconSmall /> {t('Go Back')}
            </span>
          </TextLink>

          <div style={{ display: 'flex', gap: theme.spacing(1) }}>
            {pairDeviceButton}

            <Button
              css={{ width: 'auto' }}
              size='large'
              onClick={() => {
                modal.closeModal();
                onContinue();
              }}
              trackingId='phn-portal-losingAccess-btn-continue'
            >
              {t('Continue')}
            </Button>
          </div>
        </ButtonBar>
      </Modal.Footer>
    </Modal>
  );
};
