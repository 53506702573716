import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Text, Modal, ModalControlModalProps } from '@frontend/design-system';
import { useScheduleRequestModalFormContext } from '../../Context/ScheduleRequestModalFormContext';
import { ScheduleRequestsTrackingIds } from '../../trackingIds';

type ScheduleRequestMessageSentModalProps = {
  modalProps: ModalControlModalProps;
  fullName: string;
  onClose: () => void;
};

export const ScheduleRequestMessageSentModal = ({
  modalProps,
  fullName,
  onClose,
}: ScheduleRequestMessageSentModalProps) => {
  const { t } = useTranslation('scheduleCalendarRequest');
  const { hasAppointmentWritebacks } = useScheduleRequestModalFormContext();

  const nonWritebackOfficeMessage = !hasAppointmentWritebacks
    ? t('Make sure to add the appointment to your schedule.')
    : '';

  return (
    <Modal {...modalProps}>
      <Modal.Header>{t('Confirmation')}</Modal.Header>
      <Modal.Body>
        <div css={{ display: 'flex', alignItems: 'center', marginTop: theme.spacing(2) }}>
          <Icon name='check' size={40} color='success' />
          <Text css={{ marginLeft: theme.spacing(2) }}>
            {t('Confirmation sent to {{fullName}}', { fullName })}. {nonWritebackOfficeMessage}
          </Text>
        </div>
      </Modal.Body>
      <Modal.Actions
        primaryTrackingId={ScheduleRequestsTrackingIds.approveConfirmationModalConfirmBtn}
        primaryLabel={t('Done')}
        onPrimaryClick={onClose}
      />
    </Modal>
  );
};
