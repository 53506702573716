import { useEffect, useMemo } from 'react';
import { SalesDevApi } from '@frontend/api-sales-dev';
import { useLocationDataShallowStore } from '@frontend/location-helpers';
import { useLocalizedQuery } from '@frontend/location-helpers';
import { useFeatureFlagShallowStore } from '@frontend/shared';
import { useModalControl } from '@frontend/design-system';
import {
  BillingAlertBanner,
  BillingAlertModal,
  OverdueBy30DaysMessage,
  OverdueBy40DaysMessage,
  OverdueBy45DaysMessage,
} from '../components';

const CACHE_AND_STALE_TIME_IN_HR = 1000 * 60 * 60; // 1 hour

type AlertMessageContentType = {
  isTerminating: boolean;
  messageContent: React.ReactNode;
};

export const LandingPageBillingAlertBannerAndModal = () => {
  const billingAlertModalProps = useModalControl();
  const { locationId } = useLocationDataShallowStore('locationId');
  const { getFlag } = useFeatureFlagShallowStore('getFlag');
  const isBillingEnabled = getFlag('Update Credit Card & Subscriber Info');

  const { isLoading: isInvoiceBalanceLoading, data: invoiceBalance } = useLocalizedQuery({
    queryKey: ['invoice-balance', 'billing-alerts'],
    queryFn: () => SalesDevApi.getInvoiceBalance(),
    enabled: !!locationId,
    retry: false,
    cacheTime: 12 * CACHE_AND_STALE_TIME_IN_HR, // 12 hours
    staleTime: 12 * CACHE_AND_STALE_TIME_IN_HR, // 12 hours
  });

  const alertMessageContent: AlertMessageContentType = useMemo(() => {
    let isTerminating = false;
    let messageContent = null;

    if (invoiceBalance && !isInvoiceBalanceLoading) {
      const isOverDueBy30Days = Number(invoiceBalance?.overdue30 ?? '0') > 0;
      const isOverDueBy40Days = Number(invoiceBalance?.overdue40 ?? '0') > 0;
      const isOverDueBy45Days = Number(invoiceBalance?.overdue45 ?? '0') > 0;

      const isOverdueBy30DaysOnly = isOverDueBy30Days && !isOverDueBy40Days && !isOverDueBy45Days;
      const isOverdueBy40DaysOnly = isOverDueBy40Days && !isOverDueBy45Days;
      const isOverdueBy45DaysOnly = isOverDueBy45Days;

      if (isOverdueBy30DaysOnly) {
        messageContent = <OverdueBy30DaysMessage />;
      } else if (isOverdueBy40DaysOnly && invoiceBalance?.warningDayCount) {
        isTerminating = true;
        messageContent = <OverdueBy40DaysMessage warningDayCount={invoiceBalance?.warningDayCount ?? 0} />;
      } else if (isOverdueBy45DaysOnly) {
        isTerminating = true;
        messageContent = <OverdueBy45DaysMessage />;
      }
    }
    return {
      isTerminating,
      messageContent,
    };
  }, [isInvoiceBalanceLoading, invoiceBalance]);

  useEffect(() => {
    if (alertMessageContent.messageContent) {
      billingAlertModalProps.openModal();
    }
  }, [alertMessageContent.messageContent]);

  if (!isBillingEnabled || !alertMessageContent.messageContent) return null;

  return (
    <>
      <BillingAlertBanner
        billingAlertBannerMessageContent={alertMessageContent.messageContent}
        isTerminating={alertMessageContent.isTerminating}
      />
      <BillingAlertModal
        billingAlertMessageContent={alertMessageContent.messageContent}
        isTerminating={alertMessageContent.isTerminating}
        primaryTrackingId='billing-alerts-modal-close-primary-btn'
        {...billingAlertModalProps.modalProps}
      />
    </>
  );
};
