import dayjs from 'dayjs';
import { isEqual } from 'lodash-es';
import { PhoneAnalyticsTypes } from '@frontend/api-analytics';
import { createShallowStore, createStoreWithSubscribe } from '@frontend/store';
import { usePhoneAnalyticsDemoData } from './use-phone-analytics-demo-data';

interface PhoneAnalyticsStore {
  defaultFilters: PhoneAnalyticsTypes.Filters;
  demoData: ReturnType<typeof usePhoneAnalyticsDemoData>;
  filterHintText: string;
  filters: PhoneAnalyticsTypes.Filters;
  hasCustomFilters: boolean;
  isDemoAccount?: boolean;
  locationId: string;

  setDefaultFilters: (defaultFilters: Partial<PhoneAnalyticsTypes.Filters>) => void;
  setDemoData: (demoData: ReturnType<typeof usePhoneAnalyticsDemoData>) => void;
  setFilterHintText: (filterHintText: string) => void;
  setFilters: (filters: Partial<PhoneAnalyticsTypes.Filters>) => void;
  setIsDemoAccount: (isDemoAccount: boolean) => void;
  setLocationId: (locationId: string) => void;

  resetStore: () => void;
}

const usePhoneAnalyticsStore = createStoreWithSubscribe<PhoneAnalyticsStore>(
  (set, get) => ({
    defaultFilters: {},
    demoData: null,
    filterHintText: '',
    filters: {},
    hasCustomFilters: false,
    isDemoAccount: undefined,
    locationId: '',

    setDefaultFilters: (defaultFilters) => {
      set({ defaultFilters });
    },
    setDemoData: (demoData) => {
      set({ demoData });
    },
    setFilterHintText: (filterHintText) => {
      set({ filterHintText });
    },
    setFilters: (filters) => {
      const { defaultFilters = {}, filters: currentFilters } = get();
      const newFilters: PhoneAnalyticsTypes.Filters = { ...currentFilters, ...filters };

      const { start_date: defaultStartDate, end_date: defaultEndDate, ...otherDefaults } = defaultFilters;

      const areDatesEqual =
        dayjs(newFilters.start_date).isSame(dayjs(defaultStartDate), 'date') &&
        dayjs(newFilters.end_date).isSame(dayjs(defaultEndDate), 'date');

      set({
        filters: newFilters,
        hasCustomFilters:
          !areDatesEqual ||
          Object.keys(otherDefaults).some(
            (key) =>
              !isEqual(
                newFilters[key as keyof PhoneAnalyticsTypes.Filters],
                defaultFilters[key as keyof PhoneAnalyticsTypes.Filters]
              )
          ),
      });
    },
    setIsDemoAccount: (isDemoAccount) => {
      set({ isDemoAccount });
    },
    setLocationId: (locationId) => {
      set({ locationId });
    },

    resetStore: () => {
      set({
        defaultFilters: {},
        demoData: null,
        filterHintText: '',
        filters: {},
        hasCustomFilters: false,
        isDemoAccount: undefined,
        locationId: '',
      });
    },
  }),
  {
    name: 'PhoneAnalyticsStore',
    trace: true,
  }
);

export const usePhoneAnalyticsShallowStore = createShallowStore<PhoneAnalyticsStore>(usePhoneAnalyticsStore);
