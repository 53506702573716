import { useEffect, useMemo, useRef } from 'react';
import { Img } from '@react-email/components';
import { useComposerSection } from '../../hooks';
import { ComposerSectionOutputProps, LogoSectionProps, UsableComposerSectionProps } from '../../types';
import { getImageCropStyles } from '../../utils';
import { getPaddingStyles } from '../../utils/get-padding-styles';
import { frameAlignmentStyle, frameColorStyle } from './styles';

export const LogoOutput = ({ onRenderReady }: ComposerSectionOutputProps) => {
  const imgRef = useRef<HTMLImageElement>(null);
  const { props, sectionHTML } = useComposerSection<LogoSectionProps>();

  const logo = props.images?.[0];
  const crop = props.crops?.[0];

  // to get the image element inside the Image section.
  const imageElement = useMemo(() => sectionHTML?.querySelector('img[data-type="src-image"]'), [sectionHTML]);

  const logoStyles = getImageCropStyles(imageElement?.clientWidth, imageElement?.clientHeight, crop);

  useEffect(() => {
    // if (logo?.url) {
    //   imgRef.current?.setAttribute('src', logo?.url);
    // // }
    onRenderReady?.();
  }, [logo]);

  return logo ? (
    <div style={createFrameStyle(props)}>
      <img
        ref={imgRef}
        src={logo?.url}
        alt={logo?.fileName}
        style={{
          height: props.height,
          display: 'none',
          visibility: 'hidden',
          position: 'absolute',
        }}
      />
      <Img
        style={{
          width: imageElement?.clientWidth,
          height: imageElement?.clientHeight,
          maxWidth: '100%',
          ...frameAlignmentStyle(props),
          ...logoStyles,
        }}
        src={logo?.url}
      />
    </div>
  ) : null;
};

const createFrameStyle = (props: UsableComposerSectionProps<LogoSectionProps>) => ({
  ...getPaddingStyles(props),
  ...frameColorStyle(props),
});
