import { useMemo } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { humanizeSeconds } from '@frontend/shared';
import { theme } from '@frontend/theme';
import { formatters } from '../../utils';
import { SummaryChart, SummaryTrend } from './charts';
import { CallsSummaryDataState, useFetchAggregatedCallsSummary, usePhoneAnalyticsShallowStore } from './hooks';

const generateTrendLine = (
  { data, isHourlyData }: ReturnType<typeof useFetchAggregatedCallsSummary>,
  key: keyof CallsSummaryDataState
): SummaryTrend[] => {
  if (isHourlyData) {
    // Only one object for the given date will be present in case of hourly insights
    const dataObject = Object.values(data?.[key]?.dates || {})[0];
    return dataObject?.hourly?.map((value) => ({ value })) || [];
  } else {
    return Object.values(data?.[key]?.dates || {}).map(({ daily }) => ({ value: daily })) || [];
  }
};

export const CallReportSummary = () => {
  const { t } = useTranslation('analytics');
  const { filters } = usePhoneAnalyticsShallowStore('filters');
  const isOpenHoursOnly = filters?.open_office;
  const { data, isHourlyData, isLoading } = useFetchAggregatedCallsSummary();

  const callAnswerRateTrendLine = useMemo(() => {
    return generateTrendLine({ data, isHourlyData }, 'answerRate');
  }, [data, isHourlyData]);

  const callVolumeTrendLine = useMemo(() => {
    return generateTrendLine({ data, isHourlyData }, 'totalCalls');
  }, [data, isHourlyData]);

  const averageCallDurationTrendLine = useMemo(() => {
    return generateTrendLine({ data, isHourlyData }, 'callDuration');
  }, [data, isHourlyData]);

  return (
    <div css={styles.wrapper}>
      <SummaryChart isLoading={isLoading}>
        <SummaryChart.Header
          title={t('Call Volume')}
          value={t('{{value}} Calls', {
            value: formatters.value.format(data?.totalCalls?.total),
          })}
        />
        <SummaryChart.Stats
          values={
            isOpenHoursOnly
              ? [{ label: t('Incoming'), value: formatters.value.format(data?.totalCalls?.inbound) }]
              : [
                  { label: t('Incoming'), value: formatters.value.format(data?.totalCalls?.inbound) },
                  { label: t('Outgoing'), value: formatters.value.format(data?.totalCalls?.outbound) },
                ]
          }
        />
        <SummaryChart.Graph
          color={theme.colors.warning50}
          uniqueGradientKey='phone-call-volume'
          values={callVolumeTrendLine}
        />
      </SummaryChart>

      <SummaryChart isLoading={isLoading}>
        <SummaryChart.Header title={t('Call Answer Rate')} value={`${(data?.answerRate?.rate || 0).toFixed(2)}%`} />
        <SummaryChart.Stats
          values={[
            { label: t('Answered'), value: formatters.value.format(data?.answerRate?.answered) },
            { label: t('Missed'), value: formatters.value.format(data?.answerRate?.missed) },
            { label: t('Abandoned'), value: formatters.value.format(data?.answerRate?.abandoned) },
          ]}
        />
        <SummaryChart.Graph
          color={theme.colors.secondary.seaweed50}
          uniqueGradientKey='phone-call-answered'
          values={callAnswerRateTrendLine}
        />
      </SummaryChart>

      <SummaryChart isLoading={isLoading}>
        <SummaryChart.Header title={t('Avg Call Duration')} value={humanizeSeconds(data?.callDuration?.avg)} />
        <SummaryChart.Stats
          values={[
            {
              label: t('Long Duration Calls'),
              value: formatters.value.format(data?.callDuration?.totalLongCalls),
            },
          ]}
        />
        <SummaryChart.Graph
          color={theme.colors.secondary.eggplant50}
          uniqueGradientKey='phone-avg-duration'
          values={averageCallDurationTrendLine}
        />
      </SummaryChart>
    </div>
  );
};

const styles = {
  wrapper: css`
    display: flex;
    flex-wrap: wrap;
    gap: ${theme.spacing(2)};
    margin-bottom: ${theme.spacing(4)};
  `,
};
