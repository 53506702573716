import { useQuery, useQueries } from 'react-query';
import { Options } from '@frontend/fetch';
import { LimitedSchemaQueryOptions } from '@frontend/react-query-helpers';
import { SchemaMessagingSettingsV1Service } from '../service';
import { GetIO } from '../types';

export const getGetQueryKey = (req: GetIO['input']) => ['MessagingSettingsV1', 'Get', req] as const;
type QueryKey = ReturnType<typeof getGetQueryKey>;

/**
 * A hook that returns a query for the `Get` endpoint.
 * @param req The request object to pass to the query.
 * @param options (optional) The options to pass to `useQuery`.
 * @param httpOptions (optional) The http options to pass to the schema function.
 */
export const useGetQuery = <E = unknown, D = GetIO['output']>(
  req: GetIO['input'],
  options?: LimitedSchemaQueryOptions<GetIO, E, D, QueryKey>,
  httpOptions?: Options
) =>
  useQuery<GetIO['output'], E, D, QueryKey>({
    queryKey: getGetQueryKey(req),
    queryFn: () => SchemaMessagingSettingsV1Service.Get(req, httpOptions),
    ...options,
  });

/**
 * A hook that returns an array of queries for the `Get` endpoint.
 * It takes an array of objects with the following properties:
 * @param req The request object to pass to the query.
 * @param options (optional) The options to pass to `useQuery`.
 * @param httpOptions (optional) The http options to pass to the schema function.
 */
export const useGetQueries = <E = unknown, D = GetIO['output']>(
  argsArr: {
    req: GetIO['input'];
    options?: LimitedSchemaQueryOptions<GetIO, E, D, QueryKey>;
    httpOptions?: Options;
  }[]
) =>
  useQueries(
    argsArr.map(({ req, options, httpOptions }) => ({
      queryKey: getGetQueryKey(req),
      queryFn: () => SchemaMessagingSettingsV1Service.Get(req, httpOptions),
      ...options,
    }))
  ) as ReturnType<typeof useGetQuery<E, D>>[];
