export const FacebookSvg = ({ fillColor }: { fillColor?: string }) => (
  <svg
    height={24}
    width={24}
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    version='1.1'
    baseProfile='full'
  >
    <path
      fill={fillColor}
      fillRule='evenodd'
      d='M18.01 2.63h-11a4.5 4.5 0 0 0-4.5 4.5v11a4.5 4.5 0 0 0 4.5 4.5h11a4.5 4.5 0 0 0 4.5-4.5v-11a4.5 4.5 0 0 0-4.5-4.5m-13 4.5a2 2 0 0 1 2-2h11a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2h-11a2 2 0 0 1-2-2v-11m7.499-.501c3.314 0 6 2.702 6 6.037 0 3.013-2.194 5.51-5.062 5.963v-4.218h1.398l.266-1.745h-1.665v-1.133c0-.477.233-.943.978-.943h.757V9.105s-.687-.118-1.343-.118c-1.37 0-2.267.836-2.267 2.349v1.33h-1.523v1.745h1.523v4.218c-2.868-.453-5.062-2.95-5.062-5.963 0-3.335 2.686-6.037 6-6.037'
      clipRule='evenodd'
    />
  </svg>
);
