import { useAppScopeStore } from '@frontend/scope';
import { useIsIntegratedOffice } from '../../../hooks';

export const useHasAppointmentAnalyticsFeature = () => {
  const { selectedLocationIdsWithParents, getSelectedLocationData } = useAppScopeStore();
  const locationId = selectedLocationIdsWithParents[0];
  const { isIntegratedOffice } = useIsIntegratedOffice();

  const selectedLocation = getSelectedLocationData()[locationId];
  const isMultiLocation = !!selectedLocation?.children?.length || !!selectedLocation?.parentId;

  if (isMultiLocation) {
    // Appointment analytics is not supported for multi-location yet
    return false;
  }

  return !!isIntegratedOffice;
};
