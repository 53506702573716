import { EventType } from '@weave/schema-gen-ts/dist/schemas/phone-exp/phone-call/v1/call_pops.pb';
import { MessageAction_index } from '@weave/schema-gen-ts/dist/schemas/websocket-director/v1/websocket.pb';
import { getDecodedWeaveToken } from '@frontend/auth-helpers';
import TempoTracing from '@frontend/tempo-tracing';
import {
  ConnectProps,
  WebsocketState,
  HeartbeatState,
  ServerMessageActionIndex,
  WebsocketWithSubscriptions,
} from './types';
import {
  sendPing,
  sendPong,
  INITIAL_RECONNECT_WAIT,
  HEALTHY_HEARTBEAT_THRESHOLD,
  WAIT_FOR_PONG_THRESHOLD,
} from './utils';

const THIRTY_SECONDS = 1000 * 30;
const WEBSOCKET_SPAN_DELAY_IN_SECONDS = 2;

export const connect = async ({
  url,
  onMessage,
  heartbeatState,
  userId,
}: ConnectProps): Promise<WebsocketWithSubscriptions> => {
  const onOpen = (ws: WebsocketWithSubscriptions, resolve: (val: WebsocketWithSubscriptions) => void) => () => {
    resolve(ws);
    console.log('[open] WS 2.0 connection open!');
    sendPing(ws);
    setLastHeartbeat(heartbeatState);
  };

  const _onMessage = (ws: WebsocketWithSubscriptions) => (event: MessageEvent<string>) => {
    if (!event.data) {
      return console.warn('Received websocket event with no data');
    }
    let message;
    try {
      message = JSON.parse(event.data) as ServerMessageActionIndex;
    } catch (e) {
      console.error('Error parsing message from websocket', event.data);
      return;
    }
    let shouldTrace = !!message.trace_id;
    if (shouldTrace) {
      TempoTracing.continueTrace(message.trace_id as string, TempoTracing.spanNameGenerators.websocketSpan(), {
        spanAttributes: [
          {
            key: 'websocket_message',
            value: message.payload,
          },
          {
            key: 'user_id',
            value: userId,
          },
          {
            key: 'selected_location_ids',
            value: ws.subscribedLocations?.join(','),
          },
        ],
      });
    }
    if (message.action === MessageAction_index.MESSAGE_ACTION_CLOSE) {
      if (message.payload === 'TOKEN_NO_LONGER_VALID') {
        console.info(
          'Received close message with payload TOKEN_NO_LONGER_VALID, connection should automatically close and then reconnect.'
        );
      }
    }
    // check if there is a ping event in the payload's action.
    // if there is, send a pong and return.
    if (message?.action === MessageAction_index.MESSAGE_ACTION_PING) {
      sendPong(ws);
      setLastHeartbeat(heartbeatState);
      if (!!message.trace_id) {
        TempoTracing.endTrace(message.trace_id);
      }
      return;
    }
    if (message?.action === MessageAction_index.MESSAGE_ACTION_PONG) {
      setLastHeartbeat(heartbeatState);
      if (!!message.trace_id) {
        TempoTracing.endTrace(message.trace_id);
      }
      return;
    }
    // Otherwise we'll assume it's a notification type that should be forwarded on to any subscribers
    if (!!message.payload && !!onMessage) {
      try {
        const parsedPayload = JSON.parse(message.payload);
        // Today we're only going to trace call pop events
        shouldTrace = shouldTrace && parsedPayload.method === 'PhoneSystemEventsV2';
        if (shouldTrace) {
          parsedPayload.trace_id = message.trace_id;
          TempoTracing.addEvent(message.trace_id as string, TempoTracing.spanNameGenerators.websocketSpan(), {
            eventMessage: 'Successfully parsed payload from websocket message',
            eventType: EventType.EVENT_TYPE_INFO,
            timestamp: new Date().toISOString(),
          });
        }
        // HERE'S WHERE WE ACTUALLY BROADCAST OUT THE MESSAGE TO ALL SUSCRIBED HANDLERS
        onMessage(parsedPayload);
        if (shouldTrace) {
          // calling onMessage emit.publishes the event but does not wait for each handler to finish, so we need to delay the end of the span
          TempoTracing.endSpanWithDelaySkipChildren(
            message.trace_id as string,
            TempoTracing.spanNameGenerators.websocketSpan(),
            WEBSOCKET_SPAN_DELAY_IN_SECONDS
          );
        }
      } catch (e) {
        console.error('Error parsing payload from websocket message', message, e);
        if (!!message.trace_id) {
          TempoTracing.addEvent(message.trace_id, TempoTracing.spanNameGenerators.websocketSpan(), {
            eventMessage: 'Error parsing payload from websocket message',
            eventType: EventType.EVENT_TYPE_ERROR,
            timestamp: new Date().toISOString(),
          });
          TempoTracing.endTrace(message.trace_id);
        }
      }
    } else {
      if (shouldTrace) {
        TempoTracing.addEvent(message.trace_id as string, TempoTracing.spanNameGenerators.websocketSpan(), {
          eventMessage: !onMessage
            ? 'Received message. No onMessage handler defined.'
            : 'Received message with no payload',
          eventType: EventType.EVENT_TYPE_ERROR,
          timestamp: new Date().toISOString(),
        });
        TempoTracing.endTrace(message.trace_id as string);
      }
    }
  };

  const onClose = (event: CloseEvent) => {
    if (event.wasClean) {
      console.log(`[close] 2.0 connection closed cleanly, code=${event.code} reason=${event.reason}`);
    } else {
      // Some error occurred
      // event.code is usually 1006 in this case
      console.log('[close] 2.0 connection died');
    }
    heartbeatState.lastHeartbeatAt = null;
  };

  // Clear any heartbeat state from the previous connection
  heartbeatState.connectAttemptStartedAt = Date.now();
  heartbeatState.lastHeartbeatAt = null;
  heartbeatState.lastPingSentAt = null;

  /**
   * can't await `new WebSocket(url)`, so we need to wrap it in a promise
   * this allows `connect` to return an error if the connection fails
   */
  return new Promise<WebsocketWithSubscriptions>((resolve, reject) => {
    const ws = new WebSocket(url) as WebsocketWithSubscriptions;
    ws.onmessage = _onMessage(ws);
    ws.onclose = onClose;
    ws.onopen = onOpen(ws, resolve);
    ws.onerror = function (error) {
      reject(error);
    };
  });
};

/**
 * This should be called any time a message from the server is received. We will treat any message
 *   received from the server as a heartbeat that indicates the connection is still alive. Because
 *   the connection is healthy, we can reset other pieces of state that track potential failure metrics.
 * @param ref The heartbeat state to update
 */
function setLastHeartbeat(state: HeartbeatState) {
  state.lastHeartbeatAt = Date.now();
  state.lastPingSentAt = null;
  state.connectAttemptStartedAt = null;
  state.reconnectWaitInterval = INITIAL_RECONNECT_WAIT;
  state.inReconnectLoop = false;
}

/**
 * This takes health metrics of the websocket to determine the current state and possible actions or
 *   next steps that need to be taken for the websocket connection.
 * @param url Current url used to connect to the websocket
 * @param heartbeatState The pieces of state that track the health of the connection
 * @returns A single value indicating the current state of the websocket connection
 */
export function getWebsocketState(url: string, heartbeatState: HeartbeatState): WebsocketState {
  const decodedWeaveToken = getDecodedWeaveToken();
  if (!url) {
    return WebsocketState.WAITING_FOR_URL;
  }
  const nowInMs = Date.now();
  const decodedWeaveTokenExpInMs = (decodedWeaveToken?.exp || 0) * 1000; // token exp is in seconds
  const {
    connectAttemptStartedAt,
    lastHeartbeatAt,
    lastPingSentAt,
    reconnectWaitInterval,
    inReconnectLoop,
    refreshTokenStartedAt,
  } = heartbeatState;

  // If the current token is going to expire in the next 30 seconds, we need to refresh it
  if (!!decodedWeaveToken && decodedWeaveTokenExpInMs - nowInMs < THIRTY_SECONDS) {
    if (refreshTokenStartedAt === null) {
      return WebsocketState.NEED_TO_REFRESH_TOKEN;
    } else {
      return WebsocketState.WAITING_FOR_URL;
    }
  }

  if (inReconnectLoop) {
    if (connectAttemptStartedAt === null) {
      // We are in a reconnect loop, but we haven't started a new connection attempt yet
      return WebsocketState.NEED_TO_RECONNECT;
    } else if (nowInMs - connectAttemptStartedAt < reconnectWaitInterval) {
      // We are in a reconnect loop and we are still WAITING to reconnect (not TOO much time has passed)
      return WebsocketState.RECONNECTING;
    } else {
      // The last reconnect attempt was TOO LONG ago, we need to try another reconnect
      return WebsocketState.NEED_TO_RECONNECT;
    }
  }

  if (lastHeartbeatAt === null) {
    if (connectAttemptStartedAt === null) {
      // If we have a url but no heartbeat have started a connection attempt, we need to connect
      return WebsocketState.NEED_TO_RECONNECT;
    }
    // No heartbeat received yet, check how long we've been waiting to connect
    if (nowInMs - connectAttemptStartedAt < HEALTHY_HEARTBEAT_THRESHOLD) {
      return WebsocketState.CONNECTING;
    } else {
      return WebsocketState.NEED_TO_RECONNECT;
    }
  }

  // We have a heartbeat, check how long it's been since the last one
  if (nowInMs - lastHeartbeatAt < HEALTHY_HEARTBEAT_THRESHOLD) {
    return WebsocketState.HEALTHY_CONNECTION;
  }

  // We have a heartbeat, but it's been TOO LONG since the last one
  if (lastPingSentAt === null) {
    return WebsocketState.NEED_TO_SEND_PING;
  } else if (nowInMs - lastPingSentAt < WAIT_FOR_PONG_THRESHOLD) {
    // We sent a ping, now we just need to wait for a pong
    return WebsocketState.WAITING_FOR_PONG;
  }
  // We are in an unknown state, we need to reconnect
  return WebsocketState.NEED_TO_RECONNECT;
}
