import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyle = css`
  display: flex;
  min-width: max-content;
  flex-flow: row;
  align-content: center;
  padding: 0 ${theme.spacing(2)};
  padding-bottom: ${theme.spacing(1)};
  border-bottom: 1px solid ${theme.colors.neutral10};
`;

export const modalLinkContainerStyle = css`
  display: flex;
  min-width: max-content;
  align-items: center;
`;

export const modalLinkStyle = css`
  display: flex;
  align-items: center;
  font-size: ${theme.fontSize(16)};
  padding: ${theme.spacing(1, 1)};
  gap: ${theme.spacing(1.5)};

  :disabled {
    .attachment-icon {
      color: ${theme.colors.neutral20};
    }
  }

  .attachment-icon {
    color: ${theme.colors.neutral60};
  }

  .form-icon {
    color: ${theme.colors.neutral60};
  }
`;

export const actionButtonsContainerStyle = css`
  display: flex;
  margin-left: auto;
  align-items: center;

  button {
    :disabled {
      svg {
        opacity: 0.2;
      }
    }
  }
`;
