import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const requestDigitizationBannerStyle = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const buttonStyle = css`
  margin-top: ${theme.spacing(3)};
`;

export const bannerNotificationStyle = css`
  max-width: none;
  margin-bottom: ${theme.spacing(3)};
`;
