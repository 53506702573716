import { createShallowStore, createStoreWithPersistAndSubscribe } from '@frontend/store';

type TeamChatNotificationStore = {
  showNotificationWhenTeamChatActive: boolean;
  showNotificationWhenActiveConversation: boolean;
  setShowNotificationWhenTeamChatActive: (value: boolean) => void;
  setShowNotificationWhenActiveConversation: (value: boolean) => void;
};

export const useNotificationLocalStorageStore = createShallowStore(
  createStoreWithPersistAndSubscribe<TeamChatNotificationStore>(
    (set) => {
      return {
        showNotificationWhenTeamChatActive: true,
        showNotificationWhenActiveConversation: true,
        setShowNotificationWhenTeamChatActive: (value: boolean) =>
          set((state) => ({ ...state, showNotificationWhenTeamChatActive: value })),
        setShowNotificationWhenActiveConversation: (value: boolean) =>
          set((state) => ({ ...state, showNotificationWhenActiveConversation: value })),
      };
    },
    { name: 'team-chat-notification-settings' },
    { name: 'team-chat-notification-settings', trace: true }
  )
);
