import { CSSProperties, ReactNode, useEffect } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { IconName } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Button, ButtonVariants, Heading, Text, TextLink, useTooltip } from '@frontend/design-system';
import { useChartContext } from '../chart.provider';
import { PDFDetails, useExportChartToPDF } from '../hooks';

export type ChartHeaderAction = {
  buttonVariant?: ButtonVariants;
  disabled?: boolean;
  label: string;
  omit?: boolean;
  onClick: () => void;
  trackingId?: string;
} & (
  | {
      hoverText?: never;
      iconName?: IconName;
      showLabel?: boolean;
    }
  | {
      hoverText?: string;
      iconName?: never;
      showLabel?: never;
    }
);

type HeaderProps = {
  actions?: ChartHeaderAction[];
  bottomElement?: ReactNode | null;
  infoTip?: ReactNode;
  leftElement?: ReactNode | null | false;
  style?: CSSProperties;
  subtitle?: string | ReactNode;
  title: string;
  trackingIdHelper?: string;
} & (
  | {
      allowExportToPdf?: boolean;
      pdfDetails?: PDFDetails;
    }
  | {
      allowExportToPdf?: never;
      pdfDetails?: never;
    }
);

export const Header = ({
  actions = [],
  allowExportToPdf,
  bottomElement,
  infoTip,
  leftElement,
  pdfDetails = [],
  subtitle,
  title,
  ...rest
}: HeaderProps) => {
  const { t } = useTranslation('analytics');
  const { chartContainer, setShowLoader } = useChartContext();
  const { exportToPDF, isExportingPDF } = useExportChartToPDF(pdfDetails);
  const {
    Tooltip,
    tooltipProps,
    triggerProps: tooltipTriggerProps,
  } = useTooltip({ placement: 'top', trigger: 'hover' });

  if (allowExportToPdf) {
    actions.push({
      iconName: 'download',
      label: t('Export to PDF'),
      onClick: () => exportToPDF(title, chartContainer),
    });
  }

  useEffect(() => {
    setShowLoader(isExportingPDF);
  }, [isExportingPDF]);

  return (
    <div {...rest}>
      <header css={styles.header} style={bottomElement ? { marginBottom: 0 } : {}}>
        <div className='title-wrapper'>
          {leftElement}
          <Heading className='heading' level={3}>
            {title}
            {infoTip}
          </Heading>
        </div>
        {(actions.length || subtitle) && (
          <div className='actions-wrapper'>
            {subtitle &&
              (typeof subtitle === 'string' ? (
                <Text color='light' weight='bold'>
                  {subtitle}
                </Text>
              ) : (
                subtitle
              ))}
            {actions.map(({ buttonVariant = 'tertiary', hoverText, iconName, omit, onClick, showLabel, ...rest }) =>
              omit ? null : iconName ? (
                <Button
                  {...rest}
                  iconName={iconName}
                  key={rest.label}
                  onClick={(e) => {
                    e.stopPropagation();
                    onClick();
                  }}
                  variant={buttonVariant}
                >
                  {showLabel ? rest.label : null}
                </Button>
              ) : (
                <div key={rest.label} {...tooltipTriggerProps}>
                  <TextLink
                    {...rest}
                    as='button'
                    onClick={(e) => {
                      e.stopPropagation();
                      onClick();
                    }}
                    weight='bold'
                  >
                    {rest.label}
                  </TextLink>
                  {hoverText && <Tooltip {...tooltipProps}>{hoverText}</Tooltip>}
                </div>
              )
            )}
          </div>
        )}
      </header>
      {bottomElement}
    </div>
  );
};

const styles = {
  header: css`
    align-items: center;
    column-gap: ${theme.spacing(2)};
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: ${theme.spacing(2)};

    .title-wrapper {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      gap: ${theme.spacing(0.5)};
    }

    .heading {
      align-items: center;
      display: flex;
      gap: ${theme.spacing(0.5)};

      button {
        margin-top: ${theme.spacing(0.25)};
      }
    }

    .actions-wrapper {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      gap: ${theme.spacing(1)};
    }
  `,
};
