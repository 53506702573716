import { useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { BarChartAppearance } from '@frontend/charts';
import { i18next, useTranslation } from '@frontend/i18n';
import { Page } from '@frontend/page';
import { theme } from '@frontend/theme';
import { URLs } from '../../../constants';
import { trackingIds } from '../../../tracking-ids';
import { formatters } from '../../../utils';
import { Filters, Highlight, SummaryCards, SummaryCardsData } from '../components';
import { AppointmentsScheduledTab, MessagesSentTab, PracticeValueTab } from '../components/missed-call-text-roi';
import { API_URLs } from '../constants';
import {
  useFetchMissedCallTextConversionSummary,
  useFetchMissedCallTextConversionTotals,
  useROIShallowStore,
} from '../hooks';
import { getDateRangeText } from '../utils/date-range-text';

const conversionColors = {
  achievedValue: theme.colors.tangerine40,
  totalValue: theme.colors.primary50,
};

const conversionLabels = {
  achievedValue: i18next.t('Appointments Scheduled', { ns: 'analytics' }),
  totalValue: i18next.t('Missed Call Texts Sent', { ns: 'analytics' }),
};

const appearance: BarChartAppearance = {
  alwaysApplyBarBorders: true,
  barSize: 100,
  barBorderWidth: 0.5,
  hideTooltipTitle: true,
  groupsGap: 30,
  labelConfig: {
    customStyles: () => ({
      fontSize: theme.font.size.h1,
      fontWeight: theme.font.weight.bold,
      fill: theme.font.colors.default,
    }),
    distanceFromBar: 16,
    placement: 'end',
    show: true,
  },
  margin: { top: 60 },
  patterns: {
    achievedValue: {
      name: 'gradient',
      colors: {
        start: theme.colors.tangerine40,
        end: '#F7B47D', // Color is not defined in the theme
      },
      hoverColors: {
        start: theme.colors.tangerine30,
        end: theme.colors.tangerine5,
      },
    },
    totalValue: {
      name: 'gradient',
      colors: {
        start: theme.colors.primary50,
        end: '#9EC5FF', // Color is not defined in the theme
      },
      hoverColors: {
        start: theme.colors.primary30,
        end: theme.colors.primary5,
      },
    },
  },
};

export const MissedCallTextRoiPage = () => {
  const { t } = useTranslation('analytics');
  const [activeCardId, setActiveCardId] = useState<string>('messagesSent');
  const { filterHintText: subtitle, filters } = useROIShallowStore('filterHintText', 'filters');

  const payload = {
    start_date: filters?.startDate,
    end_date: filters?.endDate,
    location_id: filters?.locationIds,
  };

  const messagesSentDetails = useFetchMissedCallTextConversionTotals({
    apiParams: {
      attributeType: 'forward',
      messagingCategory: 'missedcalltext',
      payload,
    },
  });

  const messagesSentCard = useMemo((): SummaryCardsData => {
    return {
      cardId: 'messagesSent',
      data: [
        {
          label: t('Messages Sent'),
          value: formatters.value.format(messagesSentDetails?.data?.totalMessagesCount ?? 0),
        },
      ],
      infoHoverText: t(
        '{{date}} {{totalValue}} Missed Call Texts were sent which led to {{achievedValue}} appointments being scheduled later.',
        {
          achievedValue: formatters.value.format(messagesSentDetails?.data?.totalAppointmentsCount ?? 0),
          date: getDateRangeText(filters?.startDate, filters?.endDate, true),
          totalValue: formatters.value.format(messagesSentDetails?.data?.totalMessagesCount ?? 0),
        }
      ),
      isLoading: messagesSentDetails?.isLoading,
      title: t('Messages Sent'),
      trackingId: trackingIds.roi.missedCallTextMessageSentCard,
    };
  }, [messagesSentDetails, subtitle]);

  const appointmentsScheduledDetails = useFetchMissedCallTextConversionTotals({
    apiParams: {
      attributeType: 'backward',
      messagingCategory: 'missedcalltext',
      payload,
    },
  });

  const appointmentsScheduledCard = useMemo((): SummaryCardsData => {
    return {
      cardId: 'appointmentsScheduled',
      data: [
        {
          label: t('Appointments Scheduled'),
          value: formatters.value.format(appointmentsScheduledDetails?.data?.totalAppointmentsCount ?? 0),
        },
      ],
      infoHoverText: t(
        '{{date}}, a total of {{achievedValue}} appointments were scheduled as a result of previously sent {{totalValue}} missed call texts.',
        {
          achievedValue: formatters.value.format(appointmentsScheduledDetails?.data?.totalAppointmentsCount ?? 0),
          date: getDateRangeText(filters?.startDate, filters?.endDate, true),
          totalValue: formatters.value.format(appointmentsScheduledDetails?.data?.totalMessagesCount ?? 0),
        }
      ),
      isLoading: appointmentsScheduledDetails?.isLoading,
      title: t('Appointments Scheduled'),
      trackingId: trackingIds.roi.missedCallTextAppointmentsScheduledCard,
    };
  }, [appointmentsScheduledDetails, subtitle]);

  const {
    conversionValue: totalAppointmentsCompletedConversion,
    isLoading: isLoadingAppointmentsCompleted,
    totalValue: totalAppointmentsCompleted,
  } = useFetchMissedCallTextConversionSummary({
    apiParams: {
      payload,
      url: API_URLs.missedCallText.practiceValueSummary,
    },
    totalCountKey: 'appointments_count',
  });

  const practiceValueCard = useMemo((): SummaryCardsData => {
    return {
      cardId: 'practiceValue',
      data: [
        {
          label: t('Appointments Completed'),
          value: totalAppointmentsCompletedConversion,
        },
      ],
      infoHoverText: t(
        '{{date}}, a total of {{achievedValue}} appointments were completed as a result of previously sent {{totalValue}} missed call texts.',
        {
          achievedValue: formatters.value.format(totalAppointmentsCompletedConversion),
          date: getDateRangeText(filters?.startDate, filters?.endDate, true),
          totalValue: formatters.value.format(totalAppointmentsCompleted),
        }
      ),
      isLoading: isLoadingAppointmentsCompleted,
      title: t('Practice Value'),
      trackingId: trackingIds.roi.missedCallTextPracticeValueCard,
    };
  }, [isLoadingAppointmentsCompleted, subtitle, totalAppointmentsCompleted, totalAppointmentsCompletedConversion]);

  const summaryCardsData: SummaryCardsData[] = useMemo(() => {
    return [{ ...messagesSentCard }, { ...appointmentsScheduledCard }, { ...practiceValueCard }];
  }, [appointmentsScheduledCard, messagesSentCard, practiceValueCard]);

  return (
    <Page>
      <Page.Header>
        <Page.Header.Breadcrumbs
          breadcrumbs={[
            {
              label: t('Analytics'),
              to: URLs.ANALYTICS_DASHBOARD,
            },
            {
              label: t('Missed Call Text Conversion'),
            },
          ]}
        />
        <Page.Header.Heading>
          <Page.Header.Title title={t('Missed Call Text Conversion')} />
          <Page.Header.Subtitle subtitle={t('Showing results for {{subtitle}}', { subtitle })} />
        </Page.Header.Heading>
      </Page.Header>

      <Page.Body css={styles.body}>
        <div css={styles.body}>
          <Filters
            isLoading={
              appointmentsScheduledDetails.isLoading || messagesSentDetails.isLoading || isLoadingAppointmentsCompleted
            }
          />
          <Highlight count={appointmentsScheduledDetails.data?.totalAppointmentsCount ?? 0} />
          <SummaryCards activeCardId={activeCardId} data={summaryCardsData} onClick={setActiveCardId} />

          {activeCardId === 'messagesSent' ? (
            <MessagesSentTab
              appearance={appearance}
              conversionColors={conversionColors}
              conversionLabels={conversionLabels}
              messagesSentDetails={messagesSentDetails}
            />
          ) : activeCardId === 'appointmentsScheduled' ? (
            <AppointmentsScheduledTab
              appearance={appearance}
              appointmentsScheduledDetails={appointmentsScheduledDetails}
              conversionColors={conversionColors}
              conversionLabels={conversionLabels}
            />
          ) : (
            <PracticeValueTab
              appearance={appearance}
              isLoadingConversion={isLoadingAppointmentsCompleted}
              totalAppointmentsCompleted={totalAppointmentsCompleted}
              totalAppointmentsCompletedConversion={totalAppointmentsCompletedConversion}
            />
          )}
        </div>
      </Page.Body>
    </Page>
  );
};

const styles = {
  body: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(3)};
  `,
};
