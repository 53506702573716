import { createContext, useContext, ReactNode, useMemo } from 'react';
import { useTranslation } from '@frontend/i18n';
import { useNotificationContext } from '@frontend/notifications';
import { WebsocketState } from '@frontend/websocket';
import { useWebsocketContext } from '../websocket-subscriptions/websocket-subscriptions-provider';

type State = 'healthy' | 'loading' | 'error' | 'unknown' | 'not_applicable';
type Status = {
  state: State;
  reason: string | undefined;
  latency: number | undefined;
  retry: () => void;
};

type ContextValue = {
  notifications: Status;
  websocket: Status & { isSubscribed: boolean; subscribedLocations: string[]; resubscribe: () => void };
};

const AppStatusContext = createContext<ContextValue>({
  notifications: {
    state: 'unknown',
    reason: undefined,
    latency: undefined,
    retry: () => {},
  },
  websocket: {
    state: 'unknown',
    reason: undefined,
    latency: undefined,
    retry: () => {},
    subscribedLocations: [],
    isSubscribed: false,
    resubscribe: () => {},
  },
});
export const AppStatusProvider = ({ children }: { children: ReactNode }) => {
  const { t } = useTranslation('settings');
  const notifications = useNotificationContext();
  const websockets = useWebsocketContext();

  const notificationsStatus = ((status: ReturnType<typeof useNotificationContext>['status']) => {
    switch (status) {
      case 'received':
        return 'healthy';
      case 'waiting':
        return 'loading';
      case 'failed':
      case 'timeout':
        return 'error';
      case 'idle':
        return 'unknown';
      case 'not_applicable':
      default:
        return 'not_applicable';
    }
  })(notifications.status);

  const websocketsStatus = ((status: ReturnType<typeof useWebsocketContext>['websocketState']) => {
    switch (status) {
      case WebsocketState.HEALTHY_CONNECTION:
        return 'healthy';
      case WebsocketState.CONNECTING:
      case WebsocketState.RECONNECTING:
      case WebsocketState.WAITING_FOR_PONG:
      case WebsocketState.NEED_TO_RECONNECT:
      case WebsocketState.NEED_TO_REFRESH_TOKEN:
      case WebsocketState.NEED_TO_SEND_PING:
        return 'loading';
      case WebsocketState.WAITING_FOR_URL:
        return 'error';
      default:
        return 'unknown';
    }
  })(websockets.websocketState);

  const value = useMemo(
    () =>
      ({
        notifications: {
          state: notificationsStatus,
          reason: notifications.error
            ? notifications.error
            : notificationsStatus === 'error'
            ? t('Unable to display notifications')
            : undefined,
          latency: notifications.latency,
          retry: notifications.replay,
        },
        websocket: {
          state: websocketsStatus,
          reason: undefined,
          latency: undefined,
          retry: websockets.retry,
          isSubscribed: websockets.isSubscribed,
          subscribedLocations: websockets.subscribedLocations,
          resubscribe: websockets.subscribe,
        },
      } satisfies ContextValue),
    [
      notificationsStatus,
      notifications.error,
      notifications.latency,
      notifications.replay,
      websocketsStatus,
      websockets.retry,
      websockets?.isSubscribed,
      websockets.subscribe,
    ]
  );
  return <AppStatusContext.Provider value={value}>{children}</AppStatusContext.Provider>;
};

export const useAppStatus = () => {
  return useContext(AppStatusContext);
};
