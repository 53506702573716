import { memo, useCallback, useEffect, useMemo } from 'react';
import { css } from '@emotion/react';
import { TaskStatus } from '@weave/schema-gen-ts/dist/schemas/insys/onboarding/v1/onboarding-tasks/onboarding_tasks.pb';
import {
  OnboardingEvent,
  OnboardingProgressStatus,
} from '@weave/schema-gen-ts/dist/shared/insys/activation/onboarding.pb';
import { Permission } from '@weave/schema-gen-ts/dist/shared/waccess/acls.pb';
import { motion } from 'framer-motion';
import {
  OnboardingModulesApi,
  OnboardingModulesQueries,
  OnboardingModulesTypes,
} from '@frontend/api-onboarding-modules';
import { getUser, hasSchemaACL } from '@frontend/auth-helpers';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useAppScopeStore } from '@frontend/scope';
import { useSettingsNavigate } from '@frontend/settings-routing';
import { useHasFeatureFlag } from '@frontend/shared';
import { sentry } from '@frontend/tracking';
import { theme } from '@frontend/theme';
import { BannerNotification, Heading, useModalControl } from '@frontend/design-system';
import { IncompleteTasksIntroModal, NWXDashboardTaskList, ScheduleCallWithOnboarderModal } from '../components';
import {
  NEW_SINGLE_SOFTWARE_ONLY_FLOW_FEATURE_FLAG,
  NWXDashboardTaskInfo,
  NWX_DASHBOARD_TASK_LIST,
} from '../constants';
import { incompleteTasksIntroModalLocalStorageHelper } from '../helpers';

const RunActionOnMount = ({ action }: { action: () => void | Promise<void> }) => {
  useEffect(() => {
    if (typeof action === 'function') {
      action();
    }
  }, []);
  return null;
};

// This component will be used to display the tasks on the NWX dashboard
// for only single software only locations
export const NWXDashboardTasksSection = memo(() => {
  const { t } = useTranslation('onboarding-modules');
  const { navigate } = useSettingsNavigate();
  const userInfo = getUser();
  const userId = userInfo?.userID || '';
  const { selectedLocationIdsWithParents, accessibleLocationData } = useAppScopeStore();
  const selectedLocationId = selectedLocationIdsWithParents?.[0] ?? '';
  const selectedLocationSlug = accessibleLocationData?.[selectedLocationId]?.slug ?? '';
  const isAdminUser = hasSchemaACL(selectedLocationId, Permission.USER_WRITE);
  const isSelfActivationSSOLocationFFEnabled = useHasFeatureFlag(NEW_SINGLE_SOFTWARE_ONLY_FLOW_FEATURE_FLAG);
  const latestOnboardingProgressQuery =
    OnboardingModulesQueries.useGetLatestOnboardingProgressByLocationID(selectedLocationId);
  const isSelfActivationSSOLocation =
    !latestOnboardingProgressQuery.isLoading &&
    !!latestOnboardingProgressQuery.data &&
    latestOnboardingProgressQuery.data !== OnboardingProgressStatus.ONBOARDING_PROGRESS_STATUS_UNSPECIFIED;
  const scheduleCallModalControl = useModalControl();

  const tasksQuery = OnboardingModulesQueries.useGetModulesAndTasksByLocationId(selectedLocationId, {
    enabled: isSelfActivationSSOLocationFFEnabled && isSelfActivationSSOLocation,
    // refetch on mount every time to get the latest tasks
    refetchOnMount: true,
    staleTime: 0,
  });

  const { requiredTasks, incompleteTasks, hasIncompleteTasks, hasTasks, isAllRequiredTaskCompleted } = useMemo(() => {
    const taskStatusList = (tasksQuery.data ?? []).flatMap((module) =>
      module.tasks.map(({ id, status }) => ({ id, isCompleted: status === TaskStatus.COMPLETE }))
    );

    const allTasks = NWX_DASHBOARD_TASK_LIST.reduce<NWXDashboardTaskInfo[]>((acc, task) => {
      const taskStatus = taskStatusList.find((taskStatus) => taskStatus.id === task.id);
      if (taskStatus) {
        acc.push({ ...task, isCompleted: taskStatus.isCompleted });
      }
      return acc;
    }, []);

    const hasTasks = !!allTasks.length;
    const incompleteTasks = allTasks.filter((task) => !task.isCompleted);
    const requiredTasks = allTasks.filter((task) => task.isRequiredTask);
    const hasIncompleteTasks = !!incompleteTasks.length;
    const isAllRequiredTaskCompleted = requiredTasks.every((task) => task.isCompleted);

    return { requiredTasks, incompleteTasks, hasTasks, hasIncompleteTasks, isAllRequiredTaskCompleted };
  }, [tasksQuery.data]);

  // Clear the shown flag from local storage if all required tasks are completed
  const shouldClearIncompleteTasksIntroModalLocalStorage =
    isAdminUser &&
    hasTasks &&
    isAllRequiredTaskCompleted &&
    isSelfActivationSSOLocationFFEnabled &&
    isSelfActivationSSOLocation;
  useEffect(() => {
    if (shouldClearIncompleteTasksIntroModalLocalStorage) {
      incompleteTasksIntroModalLocalStorageHelper.clearShown(selectedLocationId);
    }
  }, [shouldClearIncompleteTasksIntroModalLocalStorage, selectedLocationId]);

  const handleStartTask = useCallback((taskInfo: NWXDashboardTaskInfo) => {
    // If this is the "Schedule a call with your onboarder" task, open the modal
    if (taskInfo.id === OnboardingModulesTypes.TaskIds.ScheduleCallWithOnboarder) {
      scheduleCallModalControl.openModal();
      return;
    }

    // Else, navigate to the task's URL using the pendo guide redirect URL which will
    // open the guide.
    if (!taskInfo.settingsRedirectConfig) {
      console.error('Pendo guide url not found for onboarding task', taskInfo.id);
      sentry.error({
        error: 'Pendo guide url not found for onboarding task',
        topic: 'onboarding',
        addContext: {
          name: 'task-url',
          context: { taskId: taskInfo.id },
        },
      });
    } else {
      navigate(taskInfo.settingsRedirectConfig);
    }
  }, []);

  const handleScheduleCallSuccess = useCallback(async () => {
    await OnboardingModulesApi.publishOnboardingEvent({
      locationId: selectedLocationId,
      slug: selectedLocationSlug,
      onboardingEvent: OnboardingEvent.ONBOARDING_EVENT_ONBOARDER_CALL_SCHEDULED,
    });
    tasksQuery.refetch();
  }, []);

  const logFirstTimeAppLoadEvent = () => {
    OnboardingModulesApi.publishOnboardingEvent({
      locationId: selectedLocationId,
      slug: selectedLocationSlug,
      onboardingEvent: OnboardingEvent.ONBOARDING_EVENT_FIRST_TIME_APP_LOAD,
    });
  };

  const shouldShowComponent = hasIncompleteTasks && isSelfActivationSSOLocationFFEnabled && isSelfActivationSSOLocation;
  if (!shouldShowComponent) {
    return null;
  }

  // Show warning banner if user is not an admin user and there are incomplete tasks
  if (!isAdminUser) {
    return (
      <section css={[containerStyle, { margin: theme.spacing(0, 1) }]}>
        <BannerNotification
          status='warn'
          fullWidth
          message={t(
            'Weave setup must be completed for some features to work properly. Reach out to an admin to complete the setup process.'
          )}
        />
      </section>
    );
  }

  return (
    <motion.section initial={{ opacity: 0, y: -5 }} animate={{ opacity: 1, y: 0 }} css={containerStyle}>
      <header css={{ display: 'flex', alignItems: 'center', gap: theme.spacing(1) }}>
        <Icon name='graduation-cap' />
        <Heading level={3} css={{ fontSize: theme.fontSize(16) }}>
          {t("Let's finish setting Weave up")}
        </Heading>
      </header>
      <NWXDashboardTaskList
        taskList={incompleteTasks}
        isLoading={tasksQuery.isFetching}
        onTaskClick={handleStartTask}
      />
      <IncompleteTasksIntroModal
        taskList={requiredTasks}
        locationId={selectedLocationId}
        userId={userId}
        onTaskClick={handleStartTask}
      />
      <ScheduleCallWithOnboarderModal
        {...scheduleCallModalControl.modalProps}
        onSuccess={handleScheduleCallSuccess}
        locationSlug={selectedLocationSlug}
      />
      <RunActionOnMount action={logFirstTimeAppLoadEvent} />
    </motion.section>
  );
});

NWXDashboardTasksSection.displayName = 'NWXDashboardTasksSection';

const containerStyle = css({
  backgroundColor: theme.colors.neutral5,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  padding: theme.spacing(4, 4, 0, 4),
  '> ul, > section': {
    maxWidth: 1125,
  },
});
