import { useEffect, useRef, useState } from 'react';
import { CountryCodes } from '@frontend/geography';
import { useTranslation } from '@frontend/i18n';
import { sentry } from '@frontend/tracking';
import { FormFieldActionTypes, PostalCodeLocale, useForm, useModalControl, useAlert } from '@frontend/design-system';
import { defaultAddressInfo } from '../constants';
import { convertFormValuesToAddressInfo, verifyAddress } from '../helpers';
import { AddressInfo } from '../types';

type Props = {
  initialAddress?: AddressInfo;
  initialIsVerified?: boolean;
  compulsoryVerification?: boolean;
  onAddressSelect?: (address: AddressInfo) => void;
  onVerifyModalClose?: () => void;
  isShowEditViewForInvalidAddress?: boolean;
};

/**
 * Custom hook meant to be used with the AddressInputForm component. Returns back form
 * values as well as addressFormProps which is meant to be passed down as props to the
 * AddressInputForm component.
 *
 * @param initialAddress Address used to seed the initial values of the address form.
 * @param initialIsVerified Boolean used to seed the initial value of isVerified stare var.
 */
export const useAddressForm = ({
  initialAddress,
  initialIsVerified,
  compulsoryVerification = false,
  onAddressSelect,
  onVerifyModalClose,
  isShowEditViewForInvalidAddress,
}: Props) => {
  const { t } = useTranslation('phoneProviderInformationForm');
  const [isVerified, setIsVerified] = useState(initialIsVerified);
  const prevCountryValue = useRef((initialAddress ?? defaultAddressInfo).country || CountryCodes.USA);
  const prevFormState = useRef<any>({});
  const [error, setError] = useState('');
  const [isEditMode, setIsEditMode] = useState(false);
  const [searching, setSearching] = useState(false);
  const [addressOptions, setAddressOptions] = useState<AddressInfo[]>([]);
  const [initialSetupComplete, setInitialSetupComplete] = useState(false);
  const alert = useAlert();

  // Modal Control for verification modal
  const { modalProps, triggerProps } = useModalControl();

  const locale = initialAddress?.country === CountryCodes.Canada ? PostalCodeLocale.CA : PostalCodeLocale.US;

  const {
    getFieldProps,
    isComplete,
    values,
    seedValues,
    reset,
    validate: validateForm,
  } = useForm({
    fields: {
      address1: { type: 'text', required: true },
      address2: { type: 'text' },
      city: { type: 'text', required: true },
      state: { type: 'dropdown', required: true },
      country: { type: 'dropdown', required: true },
      postal: {
        type: 'postalCode',
        locale,
        required: true,
      },
    },
    fieldStateReducer: (state, action) => {
      setIsVerified(false);
      if (
        action.type === FormFieldActionTypes.Update &&
        action.payload.name === 'country' &&
        action.payload.value !== prevCountryValue.current
      ) {
        prevCountryValue.current = action.payload.value;
        // If the country value is changed, clear the postal and state field values and
        // also change the postal locale to that of the country value (country code).
        const newState = {
          ...state,
          postal: { ...state.postal, value: '', locale: action.payload.value },
          state: { ...state.state, value: '' },
          city: { ...state.city, value: '' },
          address1: { ...state.address1, value: '' },
          address2: { ...state.address2, value: '' },
        };
        prevFormState.current = newState;
        return newState;
      }
      // @ts-ignore
      if (state.postal.locale !== prevFormState.current?.postal?.locale) {
        return prevFormState.current;
      }
      return state;
    },
  });
  useEffect(() => {
    handleAddressChange(initialAddress ?? defaultAddressInfo, initialIsVerified ?? false);
    setInitialSetupComplete(true);
  }, []);
  const handleAddressChange = (newAddress: AddressInfo, verified: boolean) => {
    const { address1, address2, city, state, country, postal } = newAddress;

    reset();
    setError('');

    seedValues({
      address1,
      address2,
      city,
      state,
      country: country || CountryCodes.USA,
      postal,
    });

    prevCountryValue.current = country;
    setIsVerified(verified);
  };

  const isValid = !!values?.address1 && !!values?.city && !!values?.postal && !!values?.state && !!values?.country;

  const validate = () => {
    if (!isValid) {
      setError(t('Select or enter an address'));
      validateForm();
      return false;
    }
    return true;
  };

  useEffect(() => {
    // Clear the error if form is now valid.
    if (error && isValid) {
      setError('');
    }
  }, [isValid, error]);

  useEffect(() => {
    if (!isShowEditViewForInvalidAddress || (!initialSetupComplete && initialAddress?.address1)) return;

    if (!isValid) {
      setIsEditMode(true);
    }
  }, [isValid]);

  const searchAddress = async () => {
    try {
      setSearching(true);

      triggerProps.onClick();

      sentry.log({
        message: 'Searching for verified address',
        data: values,
      });
      const result = await verifyAddress(values);
      setAddressOptions(result);

      if (!result.length) {
        sentry.warn({
          error: 'No results when searching for verified address. ID: ONB-jd847s',
          topic: 'onboarding',
        });
      }
    } catch (e: any) {
      const errMsg = e?.response?.data?.message;
      alert.error(
        errMsg ? t('Address search: {{errorMessage}}', { errorMessage: errMsg }) : t('Error searching address')
      );
      sentry.error({
        error: 'Error when searching for verified address. ID: ONB-837dox',
        topic: 'onboarding',
      });
    } finally {
      setSearching(false);
    }
  };

  // value arg can be the number index of the address selected in the addressOptions array
  // (as a string) (currently we only allow one option in the array so will be just "0")
  // or it can be "manual-option" which means the manual unverified address option was
  // selected.
  const handleAddressSelected = (value: any) => {
    let selectedAddress = addressOptions[value];
    if (!selectedAddress) {
      selectedAddress = convertFormValuesToAddressInfo(values, false);
    }
    const { verified, ...rest } = selectedAddress;
    handleAddressChange(rest, !!verified);
    setIsEditMode(false);
    setAddressOptions([]);
    modalProps.onClose();

    onAddressSelect?.(selectedAddress);
  };

  const handleOnVerify = async () => {
    await searchAddress();
  };
  const handleOnSave = async () => {
    modalProps.onClose();
    await searchAddress();
  };

  const cancelEditMode = () => {
    const address: AddressInfo = {
      address1: initialAddress?.address1 ?? '',
      address2: initialAddress?.address2 ?? '',
      city: initialAddress?.city ?? '',
      postal: initialAddress?.postal ?? '',
      state: initialAddress?.state ?? '',
      country: initialAddress?.country ?? CountryCodes.USA,
    };
    handleAddressChange(address, initialIsVerified ?? false);
    setIsEditMode(false);
  };

  const closeVerifyModal = (isEditAddressClicked: boolean) => {
    modalProps.onClose();
    setAddressOptions([]);
    if (isEditAddressClicked && !isEditMode) {
      setIsEditMode(true);
    }

    if (onVerifyModalClose) {
      onVerifyModalClose();
    }
  };

  return {
    values,
    isValid,
    isVerified: isVerified ?? false,
    validate,
    reset,
    verifyAddress: handleOnVerify,
    setAddress: handleAddressChange,
    setIsEditMode,
    addressFormProps: {
      values,
      isComplete,
      getFieldProps: getFieldProps,
      handleAddressChange,
      error,
      isEditMode,
      setIsEditMode,
      compulsoryVerification,
      searchAddress,
      searching,
      setSearching,
      modalProps,
      addressOptions,
      setAddressOptions,
      handleAddressSelected,
      handleOnSave,
      cancelEditMode,
      closeVerifyModal,
    },
  };
};
