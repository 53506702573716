import { Icon } from '@frontend/icons';
import { Chip } from '@frontend/design-system';
import {
  APPOINTMENT_STATUS_MAPPING,
  AppointmentStatusEnum,
} from '../../../components/schedule-calendar-components/appointment-event-card/types';

type AppointmentBadgeStatusProps = {
  status:
    | AppointmentStatusEnum.Confirmed
    | AppointmentStatusEnum.Completed
    | AppointmentStatusEnum.InOffice
    | AppointmentStatusEnum.Attempted
    | AppointmentStatusEnum.Canceled
    | AppointmentStatusEnum.Unconfirmed
    | AppointmentStatusEnum.NoShow
    | AppointmentStatusEnum.Unknown
    | string
    | undefined;
};

export const AppointmentBadgeStatus = ({ status }: AppointmentBadgeStatusProps) => {
  const formatStatus = status?.toLowerCase().replaceAll(' ', '') ?? '';
  const appointmentStatus = APPOINTMENT_STATUS_MAPPING.get(formatStatus as AppointmentStatusEnum);

  if (!appointmentStatus) {
    return null;
  }

  return (
    <Chip
      variant={appointmentStatus.variant}
      css={{ minWidth: 'fit-content' }}
      leftElement={
        appointmentStatus.iconName && <Icon name={appointmentStatus.iconName} css={{ color: 'currentcolor' }} />
      }
    >
      {appointmentStatus.text}
    </Chip>
  );
};
