import { useEffect, useState } from 'react';
import { useTranslation } from '@frontend/i18n';
import { BulkEmailPrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import { Button, NakedButton, Stepper, Text } from '@frontend/design-system';
import { useBulkEmailEditorShallowStore, usePreviewModal } from '../../hooks';
import { DeviceSize } from '../bulk-email-editor-composer/header/device-size-field';
import { PreviewModal } from '../preview-modal';
import { StepId } from './bulk-email-editor-stepper';
import EmailPlaceholder from './email-placeholder.svg';
import { stepValueContainerStyles } from './styles';

type EmailThumbnailProps = {
  src?: string;
  onClick?: () => void;
};

const EmailThumbnail = ({ onClick, src, ...rest }: EmailThumbnailProps) => {
  const { t } = useTranslation('bulk-messaging');
  return (
    <NakedButton
      onClick={onClick}
      css={{
        width: 200,
        height: src ? 200 : undefined,
        borderRadius: src ? theme.spacing(0.5) : undefined,
        border: src ? `solid 1px ${theme.colors.neutral20}` : undefined,
        overflow: 'hidden',
      }}
    >
      <img src={src ? src : EmailPlaceholder} alt={t('Edit Email')} {...rest} />
    </NakedButton>
  );
};

type UseEmailContentStepParams = {
  emailError: string;
  next: (stepId: StepId) => void;
  openComposer: () => void;
  previous: (stepId: StepId) => void;
};

export const useEmailContentStep = ({ emailError, next, openComposer, previous }: UseEmailContentStepParams) => {
  const { t } = useTranslation('bulk-messaging');
  const store = useBulkEmailEditorShallowStore(
    'setSubject',
    'setTemplateHtml',
    'setTemplateJson',
    'templateHtml',
    'templateJson',
    'thumbnailImage',
    'thumbnailUrl'
  );

  const [previewHtml, setPreviewHtml] = useState<string>();
  const { deviceSizeProps, generatePreview, previewModalProps, transformedText } = usePreviewModal(previewHtml);

  const handlePreviewModalOpen = () => setPreviewHtml(store.templateHtml);

  useEffect(() => {
    if (transformedText) {
      generatePreview();
    }
  }, [previewHtml, transformedText]);

  const deleteEmail = () => {
    store.setSubject('');
    store.setTemplateHtml('');
    store.setTemplateJson('');
  };

  useEffect(() => {
    if (!previewModalProps.show) {
      setPreviewHtml(undefined);
    }
  }, [previewModalProps.show]);

  const id: StepId = 'email-content';

  return {
    id,
    Component: (
      <>
        <Stepper.Title>{t('Email Content')}</Stepper.Title>

        <Stepper.Content>
          <Text css={{ marginBottom: theme.spacing(2) }}>{t('Build or use a template to construct your email.')}</Text>
          {!!store.templateJson ? (
            <>
              <EmailThumbnail
                src={store.thumbnailImage ? store.thumbnailImage : store.thumbnailUrl}
                onClick={handlePreviewModalOpen}
                css={{ marginBottom: theme.spacing(2) }}
              />
              {emailError && (
                <Text color='error' css={{ marginBottom: theme.spacing(1) }}>
                  {emailError}
                </Text>
              )}
              <div css={{ display: 'flex', columnGap: theme.spacing(1), marginTop: theme.spacing(3) }}>
                <Button
                  variant='secondary'
                  onClick={openComposer}
                  trackingId={`${BulkEmailPrefixes.Editor}-edit-email-btn`}
                >
                  {t('Edit Email')}
                </Button>
                <Button
                  variant='tertiary'
                  onClick={deleteEmail}
                  trackingId={`${BulkEmailPrefixes.Editor}-delete-email-btn`}
                  destructive
                >
                  {t('Delete')}
                </Button>
              </div>
            </>
          ) : (
            <>
              {emailError && (
                <Text color='error' css={{ marginBottom: theme.spacing(1) }}>
                  {emailError}
                </Text>
              )}
              <Button
                onClick={openComposer}
                css={{ marginTop: theme.spacing(2) }}
                trackingId={`${BulkEmailPrefixes.Editor}-compose-email-btn`}
              >
                {t('Compose Email')}
              </Button>
            </>
          )}
        </Stepper.Content>

        <Stepper.ButtonBarAlternate>
          <Stepper.ButtonAlternate onClick={() => previous(id)} position='secondary' type='none'>
            {t('Previous')}
          </Stepper.ButtonAlternate>
          <Stepper.ButtonAlternate onClick={() => next(id)} position='primary' type='none'>
            {t('Next')}
          </Stepper.ButtonAlternate>
        </Stepper.ButtonBarAlternate>
        {previewModalProps.show && (
          <PreviewModal
            deviceSizeProps={{ ...deviceSizeProps, value: deviceSizeProps?.value as DeviceSize }}
            modalProps={previewModalProps}
            previewHtml={transformedText}
            setPreviewHtml={setPreviewHtml}
          />
        )}
      </>
    ),
    collapsedValue: (
      <div css={{ ...stepValueContainerStyles, flexDirection: 'column' }}>
        <Text>{t('Email Content')}</Text>
        {store.thumbnailImage || store.thumbnailUrl ? (
          <EmailThumbnail src={store.thumbnailImage ? store.thumbnailImage : store.thumbnailUrl} />
        ) : (
          <EmailThumbnail />
        )}
      </div>
    ),
    readValue: (
      <div css={{ ...stepValueContainerStyles, flexDirection: 'column' }}>
        <Text>{t('Email Content')}</Text>
        <EmailThumbnail
          src={store.thumbnailImage ? store.thumbnailImage : store.thumbnailUrl}
          onClick={handlePreviewModalOpen}
        />
        <div css={{ display: 'flex', columnGap: theme.spacing(1) }}>
          <Button variant='secondary' onClick={handlePreviewModalOpen}>
            {t('View')}
          </Button>
        </div>
        {previewModalProps.show && (
          <PreviewModal
            deviceSizeProps={{ ...deviceSizeProps, value: deviceSizeProps?.value as DeviceSize }}
            modalProps={previewModalProps}
            previewHtml={transformedText}
            setPreviewHtml={setPreviewHtml}
          />
        )}
      </div>
    ),
  };
};
