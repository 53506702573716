import { BarChartAppearance } from '@frontend/charts';
import { i18next, useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { formatters } from '../../../../utils';
import { ROIConversionChart } from '../../charts';
import { useROIShallowStore } from '../../hooks';
import { getDateRangeText } from '../../utils/date-range-text';
import { PracticeValueDescription } from '../practice-value-description';

interface PracticeValueTabProps {
  appearance: BarChartAppearance;
  isLoadingConversion?: boolean;
  totalAppointmentsCompleted?: number;
  totalAppointmentsCompletedConversion?: number;
}

const conversionColors = {
  achievedValue: theme.colors.success50,
  totalValue: theme.colors.primary50,
};

const conversionLabels = {
  achievedValue: i18next.t('Appointments Completed', { ns: 'analytics' }),
  totalValue: i18next.t('Missed Call Texts Sent', { ns: 'analytics' }),
};

export const PracticeValueTab = ({
  appearance,
  isLoadingConversion,
  totalAppointmentsCompleted = 0,
  totalAppointmentsCompletedConversion = 0,
}: PracticeValueTabProps) => {
  const { t } = useTranslation('analytics');
  const { filters } = useROIShallowStore('filters');

  return (
    <ROIConversionChart
      achievedValue={totalAppointmentsCompletedConversion}
      appearance={{
        ...appearance,
        patterns: {
          ...appearance.patterns,
          achievedValue: {
            name: 'gradient',
            colors: {
              start: theme.colors.success50,
              end: theme.colors.success20,
            },
            hoverColors: {
              start: theme.colors.success30,
              end: theme.colors.success5,
            },
          },
        },
      }}
      attributeType='practice-value'
      colors={conversionColors}
      description={
        <PracticeValueDescription
          achievedValue={formatters.value.format(totalAppointmentsCompletedConversion)}
          totalValue={formatters.value.format(totalAppointmentsCompleted)}
        />
      }
      infoTip={t('Appointments completed {{date}}', {
        date: getDateRangeText(filters?.startDate, filters?.endDate),
      })}
      isLoading={isLoadingConversion}
      labels={conversionLabels}
      title={t('Conversion')}
      totalValue={totalAppointmentsCompleted}
    />
  );
};
