import { ContextlessQueryObserverOptions, useSchemaInfiniteQuery } from '@frontend/react-query-helpers';
import { SchemaIO } from '@frontend/schema';
import { SchemaSchedulerV3Service, serviceName } from '../service';
import { InfiniteQueryEndpointName, ServiceInfiniteQueries } from '../types';

const DEFAULT_OPTIONS: ContextlessQueryObserverOptions = {
  refetchOnWindowFocus: false,
};

export type UseSchedulerV3InfiniteQueryArgs<
  EndpointName extends InfiniteQueryEndpointName,
  E = unknown,
  D = SchemaIO<ServiceInfiniteQueries[EndpointName]>['output'],
  PageParam = any
> = Omit<
  Parameters<typeof useSchemaInfiniteQuery<ServiceInfiniteQueries, EndpointName, E, D, PageParam>>[0],
  'service' | 'serviceName'
>;
/**
 * A wrapper around useSchemaInfiniteQuery that uses the SchedulerV3 service and serviceName.
 * This is intended to only be used internally in the `queries` directory of the library and not exported.
 */
export const useSchedulerV3InfiniteQuery = <
  EndpointName extends InfiniteQueryEndpointName,
  E = unknown,
  D = SchemaIO<ServiceInfiniteQueries[EndpointName]>['output'],
  PageParam = any
>({
  options,
  ...args
}: UseSchedulerV3InfiniteQueryArgs<EndpointName, E, D, PageParam>) =>
  useSchemaInfiniteQuery<ServiceInfiniteQueries, EndpointName, E, D, PageParam>({
    service: SchemaSchedulerV3Service,
    serviceName,
    ...args,
    options: {
      ...DEFAULT_OPTIONS,
      ...options,
    },
  });

export type UseSchedulerV3InfiniteQueryEndpointArgs<
  EndpointName extends InfiniteQueryEndpointName,
  E = unknown,
  D = SchemaIO<ServiceInfiniteQueries[EndpointName]>['output'],
  PageParam = any,
  Request = SchemaIO<ServiceInfiniteQueries[EndpointName]>['input']
> = Omit<UseSchedulerV3InfiniteQueryArgs<EndpointName, E, D, PageParam>, 'endpointName' | 'request'> & {
  request: Request;
};
