import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyle = css`
  padding: ${theme.spacing(1)};
  position: relative;
  height: 100%;
`;

export const innerContainerStyle = css`
  width: 75%;
  padding: ${theme.spacing(2)};
`;
