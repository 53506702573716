import { FC } from 'react';
import { Interpolation, Theme } from '@emotion/react';
import { FeatureNotificationTypes } from '@frontend/api-feature-notification';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { OnlineSchedulingPromoToastCard } from './OnlineSchedulingPromoToastCard';

export const OnlineSchedulingDashboardPromoCard: FC<{ cardStyles?: Interpolation<Theme> }> = ({ cardStyles }) => {
  const { selectedLocationIds } = useAppScopeStore();
  return (
    <OnlineSchedulingPromoToastCard
      locationIds={selectedLocationIds}
      source={FeatureNotificationTypes.FeatureNotificationSource.SOURCE_DASHBOARD_TEASER_BANNER}
      styles={[{ position: 'absolute', bottom: theme.spacing(2), right: 0 }, cardStyles]}
    />
  );
};
