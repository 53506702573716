import { useState } from 'react';
import { css } from '@emotion/react';
import { useContentComposer } from '@frontend/content-composer';
import { useTranslation } from '@frontend/i18n';
import { BulkEmailPrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import { BaseFieldProps, Button, ContentLoader, useModalControl } from '@frontend/design-system';
import { PreviewModal } from '../../preview-modal';
import { DeviceSize } from './device-size-field';

export const PreviewButton = (deviceSizeProps: BaseFieldProps<DeviceSize>) => {
  const { t } = useTranslation('email-composer');
  const { renderHtml, cancelRenderHtml } = useContentComposer();
  const [previewHtml, setPreviewHtml] = useState<string | undefined>('');
  const [loading, setLoading] = useState(false);
  const previewModalProps = useModalControl();

  const generatePreview = async () => {
    setLoading(true);
    const html = await renderHtml('preview');
    setLoading(false);
    if (!html) return;
    previewModalProps.openModal();
    setPreviewHtml(html);
  };

  return (
    <>
      <Button
        variant='secondary'
        css={css`
          height: 100%;
          width: fit-content;
        `}
        onClick={generatePreview}
        trackingId={`${BulkEmailPrefixes.Composer}-preview-btn`}
      >
        {t('Preview')}
      </Button>
      <ContentLoader show={loading} message={t('Creating Preview...')} css={{ position: 'fixed' }}>
        <Button css={{ marginTop: theme.spacing(4) }} onClick={cancelRenderHtml}>
          {t('Cancel')}
        </Button>
      </ContentLoader>
      <PreviewModal
        deviceSizeProps={deviceSizeProps}
        modalProps={previewModalProps.modalProps}
        previewHtml={previewHtml}
        setPreviewHtml={setPreviewHtml}
      />
    </>
  );
};
