import { useEffect } from 'react';
import { useEditor, useNode } from '@craftjs/core';
import ContentEditable from 'react-contenteditable';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { useFormField } from '@frontend/design-system';
import { useComposerSection } from '../../hooks/use-composer-section';
import { cornersStyling, frameAlignmentStyling } from '../../styles';
import { ButtonSectionProps, ComposerSection } from '../../types';
import { getPaddingStyles } from '../../utils/get-padding-styles';
import { WeaveLogoSvg } from '../assets/weave-logo-svg';
import { ReviewButtonOutput } from '../outputs';
import { ButtonSettings } from '../settings';
import { PrivateReviewButtonTool } from '../tools';
import { BaseComposerSection } from './floating-toolbar';

export const PrivateReviewButtonSection: ComposerSection<ButtonSectionProps> = ({
  backgroundColor,
  corners,
  frameAlignment,
  frameColor,
  link,
  reviewLinkType,
  paddingBottom,
  paddingLeft,
  paddingRight,
  paddingTop,
  text,
  textColor,
  ...rest
}) => {
  const { t } = useTranslation('content-composer');
  const { setProps } = useComposerSection<ButtonSectionProps>();
  const { id } = useNode((state) => ({ id: state.id }));
  const { actions, selected } = useEditor((state, query) => {
    const [currentNodeId] = state.events.selected;
    let selected;

    if (currentNodeId) {
      selected = {
        id: currentNodeId,
        settings: state.nodes[currentNodeId]?.related?.settings,
        isDeletable: query.node(currentNodeId).isDeletable(),
      };
    }

    return {
      selected,
    };
  });

  const fieldProps = useFormField({
    type: 'text',
    value: text,
  });

  useEffect(() => {
    setProps((props) => (props.text = fieldProps.value));
  }, [fieldProps.value]);

  return (
    <BaseComposerSection
      sectionType='ReviewButton'
      title={t('Private Review Button')}
      onDelete={selected?.isDeletable ? () => actions.delete(selected.id) : undefined}
      {...rest}
      id={id}
      link={link}
      reviewLinkType={reviewLinkType}
      css={[
        frameAlignmentStyling(frameAlignment),
        getPaddingStyles({ paddingTop, paddingRight, paddingBottom, paddingLeft }),
        {
          background: frameColor,
        },
      ]}
    >
      <div
        css={[
          cornersStyling(corners),
          {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: theme.spacing(0.75),
            background: backgroundColor,
            color: textColor,
            padding: theme.spacing(1, 2),
            fontWeight: theme.font.weight.bold,
            outline: 'none',
            height: theme.spacing(4.5),
            '&:hover, &:focus': {
              boxShadow: theme.shadows.floating,
            },
          },
        ]}
      >
        <WeaveLogoSvg fillColor={textColor} />
        <ContentEditable
          id={fieldProps.id}
          html={fieldProps.value}
          onChange={fieldProps.onChange}
          css={{
            outline: 'none',
          }}
        />
      </div>
    </BaseComposerSection>
  );
};

PrivateReviewButtonSection.craft = {
  props: {
    backgroundColor: theme.colors.primary50.toUpperCase(),
    corners: 'pill',
    frameAlignment: 'center',
    frameColor: theme.colors.neutral5.toUpperCase(),
    link: 'weave-private-review.com',
    reviewLinkType: 'private',
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(3),
    text: 'Write a Private Review',
    textColor: theme.colors.white.toUpperCase(),
  },
  related: {
    output: ReviewButtonOutput,
    settings: ButtonSettings,
    tool: PrivateReviewButtonTool,
  },
};
