import { isWeaveUser } from '@frontend/auth-helpers';
import { createShallowStore, createStoreWithSubscribe } from '@frontend/store';
import { PrepareIntakeFormStoreInfoFromLocationList, prepareIntakeFormStoreInfoFromLocationList } from './helpers';
import { getMockLocations } from './mocks';
import { IntakeFormLocation, INTAKE_FORM_DEMO_MODE_KEYS, SectionInfo } from './types';

type SetIntakeFormLocationsParams = {
  intakeFormLocations: IntakeFormLocation[];
  userEmail: string;
  isParentLocation: boolean;
};

export interface IntakeFormStore {
  intakeFormLocations: IntakeFormLocation[];
  setIntakeFormLocationListInfo: (listInfo: ReturnType<PrepareIntakeFormStoreInfoFromLocationList>) => void;
  setIntakeFormLocations: (info: SetIntakeFormLocationsParams) => void;
  setDemoIntakeFormLocations: () => void; // used for debug mode

  isParentLocation: boolean;
  ownIntakeFormLocations: IntakeFormLocation[];
  isShowIntakeForm: boolean;
  isSingleSoftwareOnlyLocation?: boolean;

  selectedIntakeFormLocationId?: string;
  setSelectedIntakeFormLocationId: (locationId?: string) => void;
  sections: SectionInfo[];
  setSections: (sections: SectionInfo[]) => void;

  // intake-form dashboard component updates intakeForm once and this flag is use to track it.
  isIntakeFormUpdated?: boolean;
  setIntakeFormUpdated: (value: boolean) => void;
  hasRanInitialAddressVerification?: boolean;
  setHasRanInitialAddressVerification: (value: boolean) => void;
}

export const useIntakeFormStore = createStoreWithSubscribe<IntakeFormStore>(
  (set) => ({
    intakeFormLocations: [],
    selectedIntakeFormLocationId: undefined,
    isIntakeFormUpdated: false,
    sections: [],

    // these 4 fields will be derived from 'locations' field
    ownIntakeFormLocations: [],
    isShowIntakeForm: false,
    isSingleSoftwareOnlyLocation: false,
    isParentLocation: false,

    setSelectedIntakeFormLocationId: (locationId) => set({ selectedIntakeFormLocationId: locationId }),
    setSections: (sections) => set({ sections }),
    setIntakeFormUpdated: (value) => set({ isIntakeFormUpdated: value }),
    setHasRanInitialAddressVerification: (value) => set({ hasRanInitialAddressVerification: value }),

    setIntakeFormLocationListInfo: (listInfo) => set(listInfo),
    setIntakeFormLocations: ({ intakeFormLocations, userEmail, isParentLocation }) => {
      const listInfo = prepareIntakeFormStoreInfoFromLocationList({
        intakeFormLocations,
        userEmail,
        isParentLocation,
        isWeaveUser: isWeaveUser(),
      });
      set({ ...listInfo, isParentLocation });
    },
    setDemoIntakeFormLocations: () => {
      const intakeFormLocations = getMockLocations(
        localStorage.getItem(INTAKE_FORM_DEMO_MODE_KEYS.IS_MULTI_FORM) === 'true'
      );
      set({ intakeFormLocations, ownIntakeFormLocations: intakeFormLocations, isShowIntakeForm: true });
    },
  }),
  {
    name: 'IntakeFormStore',
    trace: true,
  }
);

export const useIntakeFormShallowStore = createShallowStore<IntakeFormStore>(useIntakeFormStore);
