import { LocationAudience } from '@weave/schema-gen-ts/dist/schemas/messaging/bulk/v2/service.pb';
import { SegmentedBarTypes } from '@frontend/segmented-bar';
import { theme } from '@frontend/theme';
import { LocationUsageAndAllotmentWithLimit } from '../type';
import { Card } from './card';

type Props = {
  audienceCount?: LocationAudience[];
  chartData: Record<string, SegmentedBarTypes.Data[]>;
  data?: LocationUsageAndAllotmentWithLimit[];
  setData: (value: LocationUsageAndAllotmentWithLimit[]) => void;
};

export const Cards = ({ data, chartData, audienceCount, setData }: Props) => (
  <div css={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(3) }}>
    {data?.map((item, index) => (
      <Card
        key={index}
        {...item}
        contactCount={audienceCount?.find((location) => location.locationId === item.locationId)?.count ?? 0}
        setData={(value) => {
          const newData = data.map((i) => {
            if (i.locationId === item.locationId) {
              return {
                ...item,
                limit: value,
              };
            }
            return item;
          });
          setData(newData);
        }}
        items={chartData?.[item.locationId!]}
      />
    ))}
  </div>
);
