import { createElement } from 'react';
import { isComposerSection } from '../../utils';
import { useContentComposer } from '../content-composer-provider';

export const Toolbox = () => {
  const { parts } = useContentComposer();

  return (
    <>
      {Object.entries(parts).map(([key, Part]) => {
        if (isComposerSection(Part)) {
          return Part.craft.related.tool ? createElement(Part.craft.related.tool, { key }) : null;
        }
        return <Part key={key} />;
      })}
    </>
  );
};
