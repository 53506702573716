import { useState } from 'react';
import { css } from '@emotion/react';
import { SIDEBAR_BREAKPOINTS } from '@frontend/content-composer';
import { theme } from '@frontend/theme';
import { BaseFieldProps, Modal, ModalControlModalProps } from '@frontend/design-system';
import { GeneratedSubjectModalContent } from './generated-subject-modal-content';
import { GeneratedTextModalContent } from './generated-text-modal-content';
import { PromptModal } from './prompt-modal';

interface Props {
  modalProps: ModalControlModalProps;
  onComplete: (value: string) => void;
  previousText?: string;
  promptProps: BaseFieldProps<string>;
  type: 'subject' | 'text';
}

export const EmailAssistantModal = ({ modalProps, onComplete, previousText, promptProps, type }: Props) => {
  const [localPreviousText, setLocalPreviousText] = useState<string | undefined>(previousText);
  const [mode, setMode] = useState<'prompt' | 'generated'>(promptProps.value ? 'generated' : 'prompt');

  const generatedProps = {
    onClose: modalProps.onClose,
    onComplete,
    previousText: localPreviousText,
    promptProps,
    setPreviousText: setLocalPreviousText,
    setMode,
  };

  const getChildren = () => {
    switch (mode) {
      case 'prompt':
        return (
          <PromptModal onClick={() => setMode('generated')} onClose={modalProps.onClose} promptProps={promptProps} />
        );
      case 'generated':
        // TODO: currently not supporting other types of generated content
        // we can add to the types as we get more supported content types
        if (type === 'text') return <GeneratedTextModalContent {...generatedProps} />;
        return <GeneratedSubjectModalContent {...generatedProps} />;
      default:
        return null;
    }
  };

  return (
    <Modal
      {...modalProps}
      minWidth={700}
      css={css`
        @media (max-width: ${SIDEBAR_BREAKPOINTS.desktop}px) {
          min-width: 95%;
        }
        &.wv-modal-box {
          padding-top: ${theme.spacing(1.5)};
          border: 0px solid ${theme.colors.secondary.eggplant20};
          border-top: ${theme.spacing(1.5)} solid ${theme.colors.secondary.eggplant20};
          animation: gradient-top 5s ease-in-out infinite;
          @keyframes gradient-top {
            0% {
              border-top: ${theme.spacing(1.5)} solid ${theme.colors.secondary.eggplant20};
            }
            50% {
              border-top: ${theme.spacing(1.5)} solid ${theme.colors.primary20};
            }
            100% {
              border-top: ${theme.spacing(1.5)} solid ${theme.colors.secondary.eggplant20};
            }
          }
        }
      `}
    >
      {getChildren()}
    </Modal>
  );
};
