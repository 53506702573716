import { useEffect } from 'react';
import {
  CheckboxField,
  ChecklistField,
  FieldProps,
  PopoverDialog,
  SearchField,
  styles,
  Text,
  usePopoverDialog,
} from '@frontend/design-system';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { useLocationName } from './hooks';

type Props = {
  accessibleLocations: string[];
  dialogProps: ReturnType<typeof usePopoverDialog>['getDialogProps'];
  displayedLocations: string[];
  fieldProps: {
    locationIds: FieldProps<
      {
        locationIds: {
          type: 'checklist';
        };
      },
      'locationIds'
    >;
    search: FieldProps<
      {
        search: {
          type: 'text';
        };
      },
      'search'
    >;
    selectAll: FieldProps<
      {
        selectAll: {
          type: 'checkbox';
        };
      },
      'selectAll'
    >;
  };
  readOnly: boolean;
  width: string;
};

export const PopoverDrawer = ({
  accessibleLocations,
  displayedLocations,
  dialogProps,
  fieldProps,
  readOnly,
  width,
}: Props) => {
  const { t } = useTranslation('messages');
  const searchFieldProps = fieldProps.search;
  const locationFieldProps = fieldProps.locationIds;
  const selectAllFieldProps = fieldProps.selectAll;

  const { getLocationName } = useLocationName();

  const filteredLocations = displayedLocations
    .sort((a, b) => getLocationName(a)?.localeCompare(getLocationName(b)))
    .filter(
      (locationId) =>
        !searchFieldProps.value ||
        getLocationName(locationId)?.toLowerCase().includes(searchFieldProps.value.toLowerCase())
    );

  const accessibleDisplayedList = filteredLocations.filter((locationId) =>
    displayedLocations.find((l) => getLocationName(locationId) === getLocationName(l))
  );

  const isAllSelected =
    locationFieldProps.value.filter((id) => {
      if (!searchFieldProps.value && accessibleLocations.find((locationId) => locationId === id)) return true;
      const locationId = accessibleLocations.find((locationId) => locationId === id);
      const name = locationId ? getLocationName(locationId)?.toLowerCase() : '';
      return name?.includes(searchFieldProps.value.toLowerCase());
    }).length === filteredLocations.length && filteredLocations.length > 0;

  useEffect(() => {
    if (isAllSelected) {
      selectAllFieldProps.onChange({ ...selectAllFieldProps, value: true });
    } else {
      selectAllFieldProps.onChange({ ...selectAllFieldProps, value: false });
    }
  }, [isAllSelected, searchFieldProps.value]);

  return (
    <PopoverDialog {...dialogProps()} css={{ width, padding: 0 }}>
      <div css={{ margin: theme.spacing(2, 2, 1) }}>
        <SearchField {...searchFieldProps} label='' name='search' css={{ marginBottom: theme.spacing(2) }} />
        <CheckboxField
          {...selectAllFieldProps}
          label={selectAllFieldProps.value ? t('Deselect All') : t('Select All')}
          name='selectAll'
          disabled={readOnly}
          onChange={(e) => {
            searchFieldProps.onChange(e);
            // @ts-ignore event type in DS is incorrect
            if (e.value) {
              const locationsToSelect = accessibleDisplayedList.filter(
                (locationId) => !locationFieldProps.value.includes(locationId!)
              );
              if (locationsToSelect.length === 0) return;
              locationFieldProps.onChange({ ...locationFieldProps, value: locationsToSelect });
            } else {
              const locationsToDeselect = accessibleDisplayedList.filter((locationId) =>
                locationFieldProps.value.includes(locationId!)
              );
              if (locationsToDeselect.length === 0) return;
              locationFieldProps.onChange({ ...locationFieldProps, value: locationsToDeselect });
            }
          }}
        />
      </div>
      <Text
        css={{
          padding: theme.spacing(2),
          borderTop: `1px solid ${theme.colors.neutral10}`,
          maxHeight: 300,
          overflowY: 'auto',
        }}
      >
        <ChecklistField {...locationFieldProps} label=''>
          {filteredLocations.map((locationId, index) => (
            <ChecklistField.Option
              name={locationId}
              key={index}
              disabled={readOnly || !accessibleLocations.find((l) => locationId === l)}
              css={{
                '&:last-of-type': {
                  marginBottom: theme.spacing(1),
                },
                label: styles.truncate,
              }}
            >
              {getLocationName(locationId)}
            </ChecklistField.Option>
          ))}
        </ChecklistField>
      </Text>
    </PopoverDialog>
  );
};
