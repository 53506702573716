import { useMemo } from 'react';
import dayjs from 'dayjs';
import { useAppScopeStore, useScopedQuery } from '@frontend/scope';
import { useAlert } from '@frontend/design-system';
import { queryKeys as AnalyticsQueryKeys } from '../../../query-keys';
import { ApiPayload } from './shared-types';
import { useShowMissedCallTextRoiWidget } from './use-show-missed-call-text-roi-widget';

type ApiParams<Request extends object> = Request & {
  payload: ApiPayload;
};

type Props<Request extends object, Response> = {
  api: (args: ApiParams<Request>) => Promise<Response>;
  apiParams: ApiParams<Request>;
  errorMessage: string;
  isEnabled?: boolean;
  uniqueId: string;
};

export const useFetchRoiData = <Request extends object, Response>({
  api,
  apiParams,
  errorMessage,
  isEnabled = true,
  uniqueId,
}: Props<Request, Response>) => {
  const { payload, ...rest } = apiParams;
  const alert = useAlert();
  const { getSelectedLocationData, selectedLocationIds = [], selectedLocationIdsWithParents = [] } = useAppScopeStore();
  const { isFeatureEligibleLocation } = useShowMissedCallTextRoiWidget();
  const stringifiedRest = JSON.stringify(rest || {});

  let orgId: string | undefined = '';
  let isMulti = false;

  // For org id, we need to send the current location id if it is a single location and parent id when it's a multi
  // the selectedOrgId from the useAppScopeStore is not always equal to the parent id of the selected location, hence can't use it
  // When user uses new GLS, selectedLocationIdsWithParents is missing the parent id of the selected location, hence need conditional logic
  if (selectedLocationIdsWithParents.length !== selectedLocationIds.length) {
    // User used old location picker
    const selectedLocationData = getSelectedLocationData()[selectedLocationIdsWithParents[0]];
    const parentId = selectedLocationData?.parentId || selectedLocationData?.parentID;
    const hasChildren = !!selectedLocationData?.children?.length;
    isMulti = selectedLocationIdsWithParents.length > 1 || !!parentId || hasChildren;
    orgId = parentId ?? selectedLocationIdsWithParents[0];
  } else {
    // User used new GLS
    // If non active locations are in the selected list, they are also missing on parent id details which is causing data issues
    // Hence, iterating though the locations to get the parent id of the selected location
    const locationWithParentId = Object.values(getSelectedLocationData() || {}).find(
      (location) => !!(location?.parentId || location?.parentID)
    );
    const parentId = locationWithParentId?.parentId || locationWithParentId?.parentID;
    isMulti = selectedLocationIdsWithParents.length > 1 || !!parentId;
    orgId = parentId ?? selectedLocationIdsWithParents[0];
  }

  const isApiEnabled =
    isEnabled &&
    isFeatureEligibleLocation &&
    !!payload.location_id?.length &&
    !!payload.start_date &&
    !!payload.end_date &&
    !!orgId;

  const queryKey = useMemo(() => {
    const startDate = dayjs(payload.start_date).format('YYYY-MM-DD');
    const endDate = dayjs(payload.end_date).format('YYYY-MM-DD');

    const filtersString = JSON.stringify({
      ...payload,
      start_date: startDate,
      end_date: endDate,
    });

    return AnalyticsQueryKeys.roiCharts(`aggregated-${uniqueId}-${filtersString}-${stringifiedRest}`);
  }, [payload, stringifiedRest, uniqueId]);

  const { data, isLoading } = useScopedQuery({
    queryKey,
    queryFn: () =>
      api({
        ...apiParams,
        payload: {
          ...payload,
          start_date: payload.start_date ? `${dayjs(payload.start_date).format('YYYY-MM-DD')}T00:00:00.000` : '',
          end_date: payload.end_date ? `${dayjs(payload.end_date).format('YYYY-MM-DD')}T23:59:59.999` : '',
          org_id: orgId,
          is_multi: isMulti,
        },
      }),
    onError: () => {
      alert.error(errorMessage);
    },
    enabled: isApiEnabled,
    refetchOnWindowFocus: false,
    retry: false,
    staleTime: 5 * 60 * 1000, // 5 minutes
  });

  return {
    data,
    isLoading,
  };
};
