import { MessagingEtlAmpQueries } from '@frontend/api-messaging-etl-amp';
import { useAppScopeStore } from '@frontend/scope';

export const useLocationName = () => {
  const { selectedOrgId } = useAppScopeStore();
  const { data: organizationsData = [] } = MessagingEtlAmpQueries.useGetLocation({ orgId: selectedOrgId });
  const getLocationName = (locationId: string) =>
    organizationsData.find((location) => location.locationId === locationId)?.name ?? '';

  return {
    getLocationName,
  };
};
