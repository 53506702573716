import { CountryCodes } from '@frontend/geography';

export interface PhoneProviderAndAddressInfo {
  addressInfo: AddressInfo;
  providerInfo: PhoneProviderInfo;
}

export type HandleSubmitFnType = () => PhoneProviderAndAddressInfo | undefined;

export interface FormRefType {
  handleSubmit?: HandleSubmitFnType;
}

export interface AddressInfo {
  address1: string;
  address2: string;
  city: string;
  state: string;
  country: CountryCodes;
  postal: string;
  verified?: boolean;
}

export interface PhoneProviderInfo {
  businessName: string;
  name: string;
  accountPin: string;
  accountNumber: string;
  authorizedUser: string;
  infoCorrect: boolean;
  officeEmail: string;
}

export type AddressInformationType = {
  components: {
    premise: string | undefined;
    thoroughfare: string | undefined;
    locality: string | undefined;
    administrative_area: string | undefined;
    sub_building_type: string | undefined;
    sub_building_number: string | undefined;
    postal_code_short: string | undefined;
    postal_code_extra: string | undefined;
  };
  analysis: {
    address_precision: string | undefined;
  };
};

// See https://www.smartystreets.com/docs/cloud/international-street-api#addressprecision
export enum SmartyStreetsAddressPrecision {
  none = 'None',
  administrativeArea = 'AdministrativeArea',
  locality = 'Locality',
  thoroughfare = 'Thoroughfare',
  premise = 'Premise',
  deliveryPoint = 'DeliveryPoint',
}
