import { useOrgData } from '@frontend/get-org-data';
import { useTranslation } from '@frontend/i18n';
import { MultiLocationSelector } from '@frontend/multi-location-selector';
import { ImageGalleryPrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import { Modal, ModalControlModalProps, Text } from '@frontend/design-system';
import { LocationIdsFieldProps } from './types';

export const LocationAccessModal = ({
  locationIdsFieldProps,
  modalProps,
  onSave,
  trackingId,
}: {
  locationIdsFieldProps: LocationIdsFieldProps;
  modalProps: ModalControlModalProps;
  onSave: () => void;
  trackingId: string;
}) => {
  const { t } = useTranslation('messages');
  const { locationIdsWithoutParent } = useOrgData();

  return (
    <Modal {...modalProps} minWidth={500}>
      <Modal.Header onClose={modalProps.onClose} css={{ marginBottom: theme.spacing(1) }}>
        {t('Assign to Location(s)')}
      </Modal.Header>
      <Modal.Body>
        <Text size='medium' css={{ color: theme.colors.neutral70 }}>
          {t('Select which locations will have access to this image.')}
        </Text>
        <MultiLocationSelector
          // TODO: which access rules should be passed here?
          accessibleLocations={locationIdsWithoutParent}
          displayedLocations={locationIdsWithoutParent}
          dropdownLabel={t('Assigned Locations')}
          fieldProps={{ locationIds: { ...locationIdsFieldProps, name: 'locationIds' } }}
          isRequired
          trackingIdPrefix={ImageGalleryPrefixes.LocationAccessModal + '-' + trackingId}
        />
      </Modal.Body>
      <Modal.Actions
        onPrimaryClick={onSave}
        primaryLabel={t('Confirm')}
        secondaryLabel={t('Cancel')}
        onSecondaryClick={modalProps.onClose}
      />
    </Modal>
  );
};
