import { useTranslation } from '@frontend/i18n';
import { useSettingsNavigate } from '@frontend/settings-routing';
import { theme } from '@frontend/theme';
import {
  CheckboxField,
  ConfirmationModal,
  Stepper,
  Text,
  TextLink,
  useFormField,
  useModalControl,
  useAlert,
} from '@frontend/design-system';
import { PhoneNumbersPageTab, trackingIds } from '../../constants';
import { useCreateOrUpdatePortOrder, usePhoneNumbersPageShallowStore } from '../../hooks';
import { usePortOrderCreationStore } from '../../providers';
import { StepLoader } from './step-loader';

const LOA_LINK = 'https://www.getweave.com/legal/letter-of-authorization';

export const TermsAndConditionsStep = () => {
  const { t } = useTranslation('porting');
  const alert = useAlert();
  const { navigate: settingsNavigate } = useSettingsNavigate();
  const termsAndConditionsProps = useFormField({
    type: 'checkbox',
  });
  const submitConfirmationModalControl = useModalControl();
  const { setSelectedTab } = usePhoneNumbersPageShallowStore('setSelectedTab');
  const { isSaving } = usePortOrderCreationStore(['isSaving']);

  const { createOrUpdatePortOrder } = useCreateOrUpdatePortOrder();

  const handleSubmitClick = async () => {
    await createOrUpdatePortOrder({ isSubmit: true });

    alert.success(t('Success! Your port request is submitted.'));
    // navigate to phone-numbers page's port request tab
    setSelectedTab(PhoneNumbersPageTab.PortRequests);
    settingsNavigate({ to: '/organization/phone-numbers' });
  };

  return (
    <>
      <Stepper.Title>{t('Terms and Conditions')}</Stepper.Title>
      <Stepper.Content css={{ marginBottom: theme.spacing(3) }}>
        <StepLoader show={isSaving} />
        <Text size='large' weight='bold' css={{ marginBottom: theme.spacing(3) }}>
          {t('Review our Terms and Conditions')}
        </Text>
        <Text weight='semibold' css={{ marginBottom: theme.spacing(0.5) }}>
          {t('Porting Terms and Conditions')}
        </Text>
        <Text css={{ marginBottom: theme.spacing(2) }}>
          {t(
            'By continuing, you are agree to the Weave Porting Terms of Service ("Terms"), which includes but is not limited to the following: you authorize Weave Communications Inc. or its designated agent to act on your behalf and notify your current telephone service provider(s) to change the primary service telephone provider(s) for the listed telephone number(s), and obtain any information Weave or its designated agent deems necessary to make the telephone service provider change(s).'
          )}
        </Text>
        <TextLink
          to={LOA_LINK}
          size='large'
          target='_blank'
          rel='noopener noreferrer'
          trackingId={`${trackingIds.submitPortRequest}_terms-and-conditions_view-terms_text-link`}
        >
          {t('View complete terms and conditions')}
        </TextLink>
      </Stepper.Content>
      <Stepper.ButtonBar css={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <CheckboxField
          {...termsAndConditionsProps}
          name='terms-and-conditions'
          label={t('I accept the terms and conditions')}
          trackingId={`${trackingIds.submitPortRequest}_terms-and-conditions_checkbox`}
        />
        <Stepper.NextButton
          onSubmit={submitConfirmationModalControl.openModal}
          isValid={!isSaving && termsAndConditionsProps.value}
          trackingId={`${trackingIds.submitPortRequest}_terms-and-conditions_submit_btn`}
        >
          {t('Save and Submit')}
        </Stepper.NextButton>
      </Stepper.ButtonBar>
      <ConfirmationModal
        {...submitConfirmationModalControl.modalProps}
        maxWidth={530}
        message={t(
          'You will not be able to edit or change your port request if you proceed. Any changes or edits will need to go through your Onboarder. Depending on where your request is at in the process, future changes may not be able to occur.'
        )}
        title={t('Are You Sure?')}
        confirmLabel={t("Yes, I'm sure")}
        onConfirm={handleSubmitClick}
        cancelTrackingId={`${trackingIds.submitPortRequest}_terms-and-conditions_submit-confirmation-modal_cancel_btn`}
        confirmTrackingId={`${trackingIds.submitPortRequest}_terms-and-conditions_submit-confirmation-modal_confirm_btn`}
      />
    </>
  );
};
