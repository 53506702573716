import { QueryKey } from 'react-query';
import { serviceKey } from '../service';
import {
  GetFormListIO,
  GetPacketListIO,
  GetFormListV2IO,
  GetFormListV3IO,
  GetFormDetailsIO,
  GetPacketListV2IO,
  GetPacketListV3IO,
  GetPacketDetailsIO,
  SearchCustomUserIO,
  GetSubmissionsListIO,
  KioskFormsIO,
  KioskPacketsIO,
  GetFormTemplateIO,
  GetFormElementsIO,
  TemplatesQueryRequest,
  GetPMSMedicalConditionsIO,
  GetAnalyticsStats,
  GetFormPDFAuthedIO,
  CanUploadFormsIO,
  GetDigitizationFeesCheckIO,
  GetFormSubmitFlagIO,
  GetProviderMappingsIO,
} from '../types';

export const endpointKeys = {
  analyticsStats: [...serviceKey, 'analytics-stats'],
  formElements: [...serviceKey, 'form-elements'],
  formList: [...serviceKey, 'form-list'],
  formListV2: [...serviceKey, 'form-list-v2'],
  formListV3: [...serviceKey, 'form-list-v3'],
  formDetails: [...serviceKey, 'form-details'],
  formTemplate: [...serviceKey, 'form-template'],
  nonEditableForms: [...serviceKey, 'non-editable-forms'],
  eaglesoftMedicalHistoryForms: [...serviceKey, 'eaglesoft-medical-history-forms'],
  kioskForms: [...serviceKey, 'kiosk/forms'],
  kioskPackets: [...serviceKey, 'kiosk/packets'],
  medicalConditions: [...serviceKey, 'medical-conditions'],
  packetList: [...serviceKey, 'packet-list'],
  packetListV2: [...serviceKey, 'packet-list-v2'],
  packetListV3: [...serviceKey, 'packet-list-v3'],
  packetDetails: [...serviceKey, 'packet-details'],
  searchCustomUser: [...serviceKey, 'search-custom-user'],
  submissionsList: [...serviceKey, 'submissions-list'],
  templatesList: [...serviceKey, 'templates-list'],
  submissionPDF: [...serviceKey, 'submission-pdf'],
  formPDF: [...serviceKey, 'form-pdf'],
  packetPDF: [...serviceKey, 'packet-pdf'],
  canUploadForms: [...serviceKey, 'can-upload-forms'],
  digitizationFeesCheck: [...serviceKey, 'digitization-fees-check'],
  formSubmitFlag: [...serviceKey, 'form-submit-flag'],
  providerMappings: [...serviceKey, 'provider-mappings'],
};

export const requestKeys = {
  analyticsStats: (req: GetAnalyticsStats['input'] & { type?: 'concise' | 'brief' }): QueryKey => [
    ...endpointKeys.analyticsStats,
    req,
  ],
  formElements: (req: GetFormElementsIO['input']): QueryKey => [...endpointKeys.formElements, req],
  formList: (req: GetFormListIO['input']): QueryKey => [...endpointKeys.formList, req],
  formListV2: (req: GetFormListV2IO['input']): QueryKey => [...endpointKeys.formListV2, req],
  formListV3: (req: GetFormListV3IO['input']): QueryKey => [...endpointKeys.formListV3, req],
  formDetails: (req: GetFormDetailsIO['input']): QueryKey => [...endpointKeys.formDetails, req],
  formTemplate: (req: GetFormTemplateIO['input']): QueryKey => [...endpointKeys.formTemplate, req],
  nonEditableForms: (req: GetFormListIO['input']): QueryKey => [...endpointKeys.nonEditableForms, req],
  eaglesoftMedicalHistoryForms: (req: GetFormListIO['input']): QueryKey => [
    ...endpointKeys.eaglesoftMedicalHistoryForms,
    req,
  ],
  kioskForms: (req: KioskFormsIO['input']): QueryKey => [...endpointKeys.kioskForms, req],
  kioskPackets: (req: KioskPacketsIO['input']): QueryKey => [...endpointKeys.kioskPackets, req],
  medicalConditions: (req: GetPMSMedicalConditionsIO['input']): QueryKey => [...endpointKeys.medicalConditions, req],
  packetList: (req: GetPacketListIO['input']): QueryKey => [...endpointKeys.packetList, req],
  packetListV2: (req: GetPacketListV2IO['input']): QueryKey => [...endpointKeys.packetList, req],
  packetListV3: (req: GetPacketListV3IO['input']): QueryKey => [...endpointKeys.packetListV3, req],
  packetDetails: (req: GetPacketDetailsIO['input']): QueryKey => [...endpointKeys.packetDetails, req],
  searchCustomUser: (req: SearchCustomUserIO['input']): QueryKey => [...endpointKeys.searchCustomUser, req],
  submissionsList: (req: GetSubmissionsListIO['input']): QueryKey => [...endpointKeys.submissionsList, req],
  templatesList: (req: TemplatesQueryRequest): QueryKey => [...endpointKeys.templatesList, req],
  submissionPDF: (req: GetFormPDFAuthedIO['input']): QueryKey => [...endpointKeys.submissionPDF, req],
  formPDF: (req: GetFormPDFAuthedIO['input']): QueryKey => [...endpointKeys.formPDF, req],
  packetPDF: (req: GetFormPDFAuthedIO['input']): QueryKey => [...endpointKeys.packetPDF, req],
  canUploadForms: (req: CanUploadFormsIO['input']): QueryKey => [...endpointKeys.canUploadForms, req],
  digitizationFeesCheck: (req: GetDigitizationFeesCheckIO['input']): QueryKey => [
    ...endpointKeys.digitizationFeesCheck,
    req,
  ],
  formSubmitFlag: (req: GetFormSubmitFlagIO['input']): QueryKey => [...endpointKeys.formSubmitFlag, req],
  providerMappings: (req: GetProviderMappingsIO['input']): QueryKey => [...endpointKeys.providerMappings, req],
};
