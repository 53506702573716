import { css } from '@emotion/react';
import { kebabCase } from 'lodash-es';
import { Dashboard, DashboardTrackingIds } from '@frontend/dashboard';
import { DashboardWidget, DashboardWidgetFC, useDashboardWidget } from '@frontend/grid-dashboard';
import { i18next, useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Heading, NakedA, SlideCarousel, Text, styles } from '@frontend/design-system';
import KnowNewWeaveImage from './assets/getting-to-know-new-weave.svg';
import KnowNWX from './assets/getting-to-know-nwx.svg?react';
import NewWeaveTrainingCampImage from './assets/new-weave-training-camp.svg';
import NWXTrainingCamp from './assets/nwx-training-camp.svg?react';

const trainingDashboardTrackingIds = DashboardTrackingIds.featureModule('training');

export const TrainingTabContent = () => {
  const { t } = useTranslation('onboarding-modules');

  const trainingLinks = [
    {
      title: t('Your Training Drills'),
      description: t('Complete your customized learning path to help you get up to speed with Weave.'),
      link: 'https://trainingcamp.weavehelp.com/page/your-training-drills',
    },
    {
      title: t('Quick Videos'),
      description: t('Level up your Weave skills with these videos, ranging from 30 seconds to 3 minutes.'),
      link: 'https://trainingcamp.weavehelp.com/page/quick-tips',
    },
    {
      title: t('Weave Training Workshops'),
      description: t(
        'Register for 45-minute, live workshops to deepen your knowledge of the product or watch past sessions on-demand.'
      ),
      link: 'https://trainingcamp.weavehelp.com/page/live-sessions',
    },
    {
      title: t('All Training Courses'),
      description: t('Spend 5 to 15 minutes learning about a specific feature in these hands-on courses.'),
      link: 'https://trainingcamp.weavehelp.com/page/all-courses',
    },
  ];
  return (
    <section>
      <Heading level={2} css={{ marginBottom: theme.spacing(5) }}>
        {t('Weave Training')}
      </Heading>
      <div css={videoContainer}>
        <iframe
          src='https://fast.wistia.net/embed/iframe/55x420qcmr?wmode=opaque'
          title={t('Admin Controls and User Management')}
          allowFullScreen
        ></iframe>
      </div>
      <Heading level={3} css={css({ marginBottom: theme.spacing(3) })}>
        {t('Weave Training Camp Courses')}
      </Heading>
      <div css={cardContainerStyle}>
        {trainingLinks.map(({ title, description, link }) => (
          <NakedA key={title} css={cardStyle} href={link} rel='noopener noreferrer' target='_blank'>
            <header>
              <Text>{title}</Text>
              <Icon name='external-link' />
            </header>
            <Text size='medium'>{description}</Text>
          </NakedA>
        ))}
      </div>
    </section>
  );
};

const videoContainer = css({
  aspectRatio: '16/9',
  position: 'relative',
  width: '100%',
  maxWidth: 267,
  marginBottom: theme.spacing(5),
  iframe: {
    border: 'none',
    width: '100%',
    height: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
  },
});

const cardContainerStyle = css`
  display: flex;
  row-gap: ${theme.spacing(3)};
  column-gap: ${theme.spacing(2)};
  flex-wrap: wrap;
`;

const cardStyle = css`
  max-width: calc(50% - ${theme.spacing(2)});
  min-width: 300px;
  box-shadow: ${theme.shadows.light};
  border-radius: ${theme.borderRadius.medium};
  flex-grow: 1;
  padding: ${theme.spacing(2)};
  transition: box-shadow 300ms;
  header {
    font-weight: ${theme.font.weight.bold};
    margin-bottom: ${theme.spacing(1)};
    display: flex;
    justify-content: space-between;
  }
  svg {
    fill: ${theme.colors.neutral60};
  }
  &:hover {
    box-shadow: ${theme.shadows.heavy};
  }
`;

export const TrainingDashboardModule = () => {
  const { t } = useTranslation('onboarding-modules');

  const trainingLinks = [
    {
      title: t('Getting Around the New Weave Experience'),
      description: t(
        'With the new Weave experience, you can communicate with customers, see call history, and more within a browser on your computer or a new desktop app.'
      ),
      link: 'https://www.weavehelp.com/weavehelp/article/getting-around-the-weave-app',
      image: KnowNWX,
      color: theme.colors.white,
      stretch: true,
    },
    {
      title: t('New Weave Experience Courses'),
      description: t(
        'Find training courses on the New Weave Experience here. Courses are listed by the corresponding navigation icons on the left side of your New Weave Experience App.'
      ),
      link: 'https://trainingcamp.weavehelp.com/page/new-weave-experience-training',
      image: NWXTrainingCamp,
      color: theme.colors.white,
      stretch: true,
    },
  ];

  return (
    <>
      <Dashboard.Module.Header title='Weave Training' />
      <Dashboard.Module.Content
        css={css`
          margin-top: ${theme.spacing(1)};
        `}
      >
        <Dashboard.Module.Scrollable
          fullHeight
          title={t('Courses ({{count}})', { count: 2 })}
          css={css`
            padding: ${theme.spacing(1.5, 2)};
          `}
        >
          {trainingLinks.map(({ title, description, link, image: Image, color, stretch }) => (
            <NakedA
              trackingId={trainingDashboardTrackingIds('item')}
              css={css`
                padding: ${theme.spacing(2)};
                border: 1px solid ${theme.colors.neutral20};
                border-radius: ${theme.borderRadius.small};
                min-width: 250px;
                max-width: 250px;
                overflow: hidden;
                align-self: stretch;
                transition: box-shadow 0.3s ease, transform 0.3s ease;
                :hover,
                :focus {
                  transform: translateY(-2px);
                  box-shadow: ${theme.shadows.light};
                }
              `}
              key={title}
              href={link}
              rel='noopener noreferrer'
              target='_blank'
            >
              <div>
                <figure
                  css={css`
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: ${color};
                    margin-bottom: ${theme.spacing(2)};
                    border-radius: ${theme.borderRadius.small};
                    max-height: 100px;
                    min-height: 100px;
                  `}
                >
                  <Image
                    preserveAspectRatio='xMinYMin slice'
                    css={[
                      css`
                        height: 50px;
                        width: 50px;
                      `,
                      stretch &&
                        css`
                          width: 100%;
                          height: 100%;
                        `,
                    ]}
                  />
                </figure>
                <Text
                  size='medium'
                  weight='bold'
                  css={css`
                    margin-bottom: ${theme.spacing(1)};
                  `}
                >
                  {title}
                </Text>
                <Text size='medium' css={styles.multilineTruncate(2)}>
                  {description}
                </Text>
              </div>
            </NakedA>
          ))}
        </Dashboard.Module.Scrollable>
      </Dashboard.Module.Content>
    </>
  );
};

const TrainingLinks = [
  {
    title: i18next.t('Getting Around the New Weave Experience', { ns: 'onboarding-modules' }),
    description: i18next.t(
      'With the new Weave experience, you can communicate with customers, see call history, and more within a browser on your computer or a new desktop app.',
      { ns: 'onboarding-modules' }
    ),
    link: 'https://www.weavehelp.com/weavehelp/article/getting-around-the-weave-app',
    image: KnowNewWeaveImage,
    altText: i18next.t('New Weave Experience Image', { ns: 'onboarding-modules' }),
  },
  {
    title: i18next.t('New Weave Courses', { ns: 'onboarding-modules' }),
    description: i18next.t(
      'Find training courses for New Weave here. Take courses in recommended order by opening Training Drills, or search for specific courses by clicking See All Courses.',
      { ns: 'onboarding-modules' }
    ),
    link: 'https://trainingcamp.weavehelp.com/',
    image: NewWeaveTrainingCampImage,
    altText: i18next.t('New Weave Experience Courses Image', { ns: 'onboarding-modules' }),
  },
];

/**
 * @dashboard-widget
 *
 * id: training-articles
 * title: Training
 * description: See helpful resources as you learn all about Weave's app.
 * icon: graduation-cap-small
 */
export const TrainingDashboardWidget: DashboardWidgetFC = () => {
  const { widgetTrackingId } = useDashboardWidget();

  return (
    <DashboardWidget>
      <DashboardWidget.Header />
      <DashboardWidget.Content css={{ display: 'flex', flexDirection: 'column', flex: 1, marginTop: theme.spacing(1) }}>
        <SlideCarousel slides={TrainingLinks.map(({ title }) => title)}>
          <SlideCarousel.Container>
            {TrainingLinks.map(({ altText, title, description, image, link }) => (
              <SlideCarousel.Panel key={title} id={title}>
                <NakedA
                  trackingId={widgetTrackingId(kebabCase(title))}
                  href={link}
                  rel='noopener noreferrer'
                  target='_blank'
                  css={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(1) }}
                >
                  <img
                    src={image}
                    width='100%'
                    height='auto'
                    alt={altText}
                    css={{ maxHeight: 136, objectFit: 'cover' }}
                  />
                  <Text size='large' weight='bold'>
                    {title}
                  </Text>
                  <Text size='medium' css={styles.multilineTruncate(4)}>
                    {description}
                  </Text>
                </NakedA>
              </SlideCarousel.Panel>
            ))}
          </SlideCarousel.Container>
          <SlideCarousel.Navigation />
        </SlideCarousel>
      </DashboardWidget.Content>
    </DashboardWidget>
  );
};

TrainingDashboardWidget.config = {
  size: 'large-narrow',
  feature: 'general',
};
