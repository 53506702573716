import dayjs from 'dayjs';
import { ChatListItemTrackingIds } from '../constants';
import { StreamUser, StreamConversation, Conversations } from '../types';

interface GenerateStreamUserParams {
  orgId: string;
  userId: string;
}

export const getTeamId = (orgId: string) => `ORG_ID_${orgId}`;

export const getStreamUserId = ({ orgId, userId }: GenerateStreamUserParams) => `ORG_ID_${orgId}_${userId}`;

export const getFullName = (user: StreamUser) => `${user.firstName} ${user.lastName}`;

export const getFirstAndLastName = (name: string) => {
  // Just in case to handle bad data
  if (!name || !name.includes(' ')) {
    return { firstName: name ?? '', lastName: '' };
  }

  const [firstName, lastName] = name.split(' ');
  return { firstName, lastName };
};

export const getChatListItemTrackingId = (chat: StreamConversation) => {
  if (chat.channelId.startsWith('!members')) {
    if (chat.members.length === 2) {
      return ChatListItemTrackingIds.DM;
    }
    return ChatListItemTrackingIds.GROUP_DM;
  }
  return ChatListItemTrackingIds.CHANNEL;
};

const getLastMessageEpoch = (chat: StreamConversation) => {
  if (chat.messages.length) {
    return dayjs(chat.messages[chat.messages.length - 1].lastUpdated).valueOf();
  }
  return 0;
};

export const sortChannelsByName = (channels: StreamConversation[]) => {
  return channels.sort((c1, c2) => (!c1.name || !c2.name ? 0 : c1.name.localeCompare(c2.name)));
};

const filterAndSortChannel = (channels: StreamConversation[]): StreamConversation[] => {
  const { unreadChannels, readChannels } = channels.reduce<{
    unreadChannels: StreamConversation[];
    readChannels: StreamConversation[];
  }>(
    (acc, channel) => {
      if (channel.unreadCount > 0) {
        acc.unreadChannels.push(channel);
      } else {
        acc.readChannels.push(channel);
      }
      return acc;
    },
    { unreadChannels: [], readChannels: [] }
  );

  const sortedReadChannels = sortChannelsByName(readChannels);

  return [...unreadChannels.sort((c1, c2) => getLastMessageEpoch(c2) - getLastMessageEpoch(c1)), ...sortedReadChannels];
};

export const getSortedChannels = (conversations: Conversations): Conversations => {
  const newConversations: Conversations = {
    dm: filterAndSortChannel(conversations.dm),
    groups: filterAndSortChannel(conversations.groups),
  };

  return newConversations;
};
