import { useEffect, FC, PropsWithChildren, useState, useCallback } from 'react';
import { StreamChat } from 'stream-chat';
import { useAppScopeStore } from '@frontend/scope';
import { useNotifications, useTeamChatClientEvents, useTeamChatConnect, useWeavePresence } from '../hooks';
import { fetchUsers, getChannels, getTeamId, formatUser, getSortedChannels } from '../utils';
import { useTeamChatStore } from './team-chat.store';
import { UserStatusMapping } from './types';

export const TeamChatWrapper: FC<PropsWithChildren> = ({ children }) => {
  const { isTrayOpen, initialize, setIsConnected, setIsFetchingChats } = useTeamChatStore([
    'isTrayOpen',
    'initialize',
    'setIsConnected',
    'setIsFetchingChats',
  ]);
  const { selectedOrgId } = useAppScopeStore();
  const { isTokenReady, connect } = useTeamChatConnect();
  const [__, setFailedToInitialize] = useState(false);

  useWeavePresence(getTeamId(selectedOrgId));
  useTeamChatClientEvents();
  useNotifications();

  const loadData = useCallback(
    async (client: StreamChat, selectedOrgId: string) => {
      try {
        setIsFetchingChats(true);
        const [users, conversations] = await Promise.all([
          fetchUsers(client, selectedOrgId),
          getChannels(client, selectedOrgId),
        ]);

        const { userID, status } = formatUser(client.user);
        const userStatus: UserStatusMapping = users.reduce((acc, user) => ({ ...acc, [user.userID]: user.status }), {});
        userStatus[userID] = status;

        // TODO: initialization should be done in a single call
        initialize({
          conversations: getSortedChannels(conversations),
          users,
          isInitializing: false,
          userStatus,
        });
        setIsFetchingChats(false);
      } catch (error) {
        // TODO: if client fails to initialize then show a retry button on the UI to trigger the initialization again
        setFailedToInitialize(true);
      }
    },
    [isTrayOpen, initialize]
  );

  useEffect(() => {
    let streamClient: StreamChat | null = null;

    if (isTokenReady) {
      connect(selectedOrgId).then(({ client }) => {
        streamClient = client;
        if (!!client) {
          loadData(client, selectedOrgId);
        }
      });
    }

    return () => {
      if (!!streamClient?.user) {
        setIsConnected(false);
        streamClient.disconnectUser();
      }
    };
  }, [isTokenReady, selectedOrgId]);

  return children;
};
