import { theme } from '@frontend/theme';
import { Heading, Text } from '@frontend/design-system';

type Props = {
  title: string;
  instructions: string;
  details: string;
  children?: React.ReactNode;
};

export const UploadStep = ({ title, instructions, details, children }: Props) => {
  return (
    <section
      className='upload-step'
      css={{
        padding: theme.spacing(4, 2),
        borderBottom: `solid 1px ${theme.colors.neutral20}`,
        textAlign: 'center',
      }}
    >
      <Heading level={3} css={{ textAlign: 'center' }}>
        {title}
      </Heading>
      <Text weight='bold' css={{ marginTop: theme.spacing(1) }}>
        {instructions}
      </Text>
      <Text size='small' color='light'>
        {details}
      </Text>
      {children ? <div css={{ marginTop: theme.spacing(2) }}>{children}</div> : null}
    </section>
  );
};
