import { useEffect } from 'react';
import { InvoiceModel } from '@frontend/api-invoices';
import { useTranslation } from '@frontend/i18n';
import { CollectPaymentModalSteps, useCollectPaymentModal } from '@frontend/payments-collection-flow';
import { useInvoiceShallowStore, useSelectedInvoice } from '@frontend/payments-invoice-controller';
import { PersonUnpaidInvoiceList } from '@frontend/person-invoice-list';
import { Modal } from '@frontend/design-system';
import { PersonInvoiceModalSteps, usePersonInvoicePaymentModal } from '../use-person-invoice-payment-modal';

export const SelectInvoice = ({ personId }: { personId: string }) => {
  const { t } = useTranslation('payments');
  const { invoice } = useSelectedInvoice();
  const { setSelectedInvoiceId } = useInvoiceShallowStore('setSelectedInvoiceId');
  const { goToStep: goToCollectionStep } = useCollectPaymentModal();
  const { goToStep: goToInvoiceStep } = usePersonInvoicePaymentModal();

  const handlePreviewClick = (invoice: InvoiceModel) => {
    setSelectedInvoiceId(invoice.id);
    goToInvoiceStep(PersonInvoiceModalSteps.PreviewInvoice);
  };

  useEffect(() => {
    if (invoice && invoice.person.id !== personId) {
      /**
       * Avoid bug where user A has a selected invoice.
       * Open user B's select invoice step. Primary button is enabled, no invoice appears
       * to be selected, but if you click "select", will show details from User A's invoice.
       */
      setSelectedInvoiceId(null);
    }
  }, []);

  return (
    <>
      <Modal.Body>
        <PersonUnpaidInvoiceList
          onInvoiceClick={(invoice: InvoiceModel) => setSelectedInvoiceId(invoice.id)}
          onPreviewClick={handlePreviewClick}
          createInvoiceOnClick={() => goToInvoiceStep(PersonInvoiceModalSteps.CreateInvoice)}
          personId={personId}
        />
      </Modal.Body>
      <Modal.Footer
        css={{ button: { width: 'fit-content' }, justifyContent: 'end' }}
        primary={{
          onClick: () => goToCollectionStep(CollectPaymentModalSteps.PaymentFlowList),
          label: t('Select'),
          disabled: !invoice,
        }}
      />
    </>
  );
};
