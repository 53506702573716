import {
  Campaign,
  Status_Enum as CampaignStatus,
  CampaignType_Enum as CampaignType,
  Usage,
} from '@weave/schema-gen-ts/dist/schemas/messaging/bulk/v2';
import dayjs from 'dayjs';
import { BulkMessagingQueries, BulkMessagingUtils } from '@frontend/api-bulk-messaging';
import { FeatureAccessFlags, FeatureAccessPermissions, useFeatureAccess } from '@frontend/feature-access';
import { useAppScopeStore } from '@frontend/scope';
import { LIMITED_EDIT_CAMPAIGN_STATUSES } from '../constants';

export const useMonthUsageBreakdown = (selectedDate: string, campaign?: Campaign, audienceCount?: number) => {
  const { selectedOrgId: orgId } = useAppScopeStore();
  const {
    hasAccess,
    locationsWithEnabledFlag,
    isLoading: isHasAccessLoading,
    isError: isHasAccessError,
  } = useFeatureAccess(FeatureAccessFlags.emailMarketing, FeatureAccessPermissions.emailMarketing);

  // dates for the allotment data and determining if it should be deducted from the month's usage
  const selectedMonth = dayjs(selectedDate).month() + 1;
  const selectedYear = dayjs(selectedDate).year();
  const existingCampaignMonth = campaign?.startSendAt ? dayjs(campaign.startSendAt).month() + 1 : undefined;
  const existingCampaignYear = campaign?.startSendAt ? dayjs(campaign.startSendAt).year() : undefined;
  const currentMonth = dayjs().month() + 1;
  const currentYear = dayjs().year();
  // selectedMonth/selectedYear = what is selected in the date picker in the stepper (but should be defaulted to the existingCampaignMonth/existingCampaignYear)
  // existingCampaignMonth/existingCampaignYear = pulled from an already scheduled campaign (edit/duplicate campaign view)
  // currentMonth/currentYear = fallback value (create campaign view)
  const displayedMonth = selectedMonth || existingCampaignMonth || currentMonth;
  const displayedYear = selectedYear || existingCampaignYear || currentYear;

  const {
    data: locationUsages,
    isLoading: isUsageLoading,
    isError: isUsageError,
  } = BulkMessagingQueries.useGetUsage(
    {
      type: CampaignType.EMAIL,
      orgId,
      locationIds: locationsWithEnabledFlag,
      year: displayedYear,
    },
    displayedMonth,
    {
      enabled: hasAccess,
    }
  );

  const usage = BulkMessagingUtils.flattenLocationUsages(locationUsages).reduce<Usage>(
    (acc, locationUsage) => {
      if (locationUsage.month !== acc.month) return acc;
      return {
        month: acc.month,
        allotment: acc.allotment + locationUsage.allotment,
        error: acc.error + locationUsage.error,
        scheduled: acc.scheduled + locationUsage.scheduled,
        sent: acc.sent + locationUsage.sent,
      };
    },
    { month: displayedMonth, allotment: 0, error: 0, scheduled: 0, sent: 0 }
  );

  const isLoading = isHasAccessLoading || isUsageLoading;
  const isError = isHasAccessError || isUsageError;

  // total usage data for the viewed month
  const monthAllotment = usage.allotment;
  const monthSent = usage.sent;
  const monthFailed = usage.error;
  const monthUsed = monthSent + monthFailed;
  const monthPending = usage.scheduled;

  // campaign data
  const campaignPlanned = (audienceCount || campaign?.messageCounts?.total) ?? 0;
  const campaignSent = campaign?.messageCounts?.delivered ?? 0;
  const campaignFailed = campaign?.messageCounts?.failed ?? 0;
  const campaignUsed = campaignSent + campaignFailed;
  const campaignPending = campaignPlanned - campaignSent - campaignFailed;

  // determine if the campaign should deduct from the total month's usage
  const campaignStatus = campaign?.currentStatus || CampaignStatus.UNSPECIFIED;
  const sameDatePeriod = existingCampaignMonth === selectedMonth && existingCampaignYear === selectedYear;
  const shouldDeductFromMonth = !!LIMITED_EDIT_CAMPAIGN_STATUSES.includes(campaignStatus) && sameDatePeriod;

  // other campaigns data
  const getNumber = (value: number) => (value < 0 ? 0 : value);
  const otherCampaignsUsed = shouldDeductFromMonth ? getNumber(monthUsed - campaignUsed) : getNumber(monthUsed);
  const otherCampaignsPending = shouldDeductFromMonth
    ? getNumber(monthPending - campaignPending)
    : getNumber(monthPending);
  const otherCampaignsTotal = otherCampaignsUsed + otherCampaignsPending;

  const remainingCampaigns = monthAllotment - otherCampaignsTotal - campaignPlanned;

  return {
    campaignPlanned,
    displayedMonth,
    monthAllotment,
    otherCampaignsPending,
    otherCampaignsUsed,
    remainingCampaigns,
    isLoading,
    isError,
  };
};
