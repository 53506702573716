import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const centerH = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const actionPanel = (isLargeDevice: boolean) =>
  css({
    display: 'flex',
    alignItems: 'center',
    ...(isLargeDevice && { justifyContent: 'flex-end' }),
  });

export const unifyLocationNames = css({
  marginBottom: theme.spacing(2),
});
