import { useEffect } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Button, Combobox, FieldChangeEvent, PhoneField, useFormField } from '@frontend/design-system';

export const ContactTextField = ({
  value,
  label,
  removeable,
  onUpdate,
  onRemove,
  onError,
  index,
}: {
  value: string;
  label: string;
  index: string;
  removeable: boolean;
  onUpdate: ({ label, number }: { label: string; number: string; index: string }) => void;
  onRemove: (index: string) => void;
  onError: (message: string) => void;
}) => {
  const { t } = useTranslation('phone');
  const phoneNumberField = useFormField(
    { type: 'phone', value, format: 'hyphenated', required: !removeable, validateOnChange: true },
    [value]
  );
  const labelField = useFormField({ type: 'text', value: label, required: true, validateOnChange: true }, []);

  const onChange = ({ value, fieldName }: { value: string; fieldName: string }) => {
    onUpdate({
      label: fieldName === 'labelField' ? value : labelField.value,
      number: fieldName === 'phoneField' ? value : phoneNumberField.value,
      index,
    });
  };

  const labelFieldProps = {
    ...labelField,
    onChange: (e?: FieldChangeEvent) => {
      labelField.onChange(e);
      onChange({
        // @ts-expect-error TODO: Fix types involved with this onChange.
        value: e?.target?.value ?? e.value,
        fieldName: 'labelField',
      });
    },
  };

  const phoneNumberFieldProps = {
    ...phoneNumberField,
    onChange: (e?: FieldChangeEvent) => {
      phoneNumberField.onChange(e);
      onChange({
        // @ts-expect-error TODO: Fix types involved with this onChange.
        value: e.value,
        fieldName: 'phoneField',
      });
    },
  };

  const onDelete = () => {
    onRemove(index);
  };

  useEffect(() => {
    onError(labelField.error || phoneNumberField.error);
  }, [labelField.error, phoneNumberField.error]);

  return (
    <div css={{ display: 'flex', gap: theme.spacing(1), marginTop: theme.spacing(2) }}>
      {removeable && (
        <Button
          destructive
          onClick={onDelete}
          variant='secondary'
          css={{ minWidth: theme.spacing(5) }}
          iconName='remove-small'
        />
      )}
      <Combobox
        options={[t('Main'), t('Work'), t('Office'), t('Mobile'), t('Other')]}
        name='label'
        label={t('Label')}
        fieldProps={labelFieldProps}
        itemWidth={150}
      />
      <PhoneField
        containerCss={css`
          width: 100%;
        `}
        {...phoneNumberFieldProps}
        label='Phone Number'
        name='phone-number'
      />
    </div>
  );
};
