import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { Heading, Text } from '@frontend/design-system';

interface Props {
  title: string;
  statusText: string;
}

export const SectionHeader = ({ title, statusText }: Props) => (
  <header css={headerStyles}>
    <Heading level={3}>{title}</Heading>
    <Text as='span' size='small' color='light'>
      {statusText}
    </Text>
  </header>
);

const headerStyles = css({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: theme.spacing(1),
  marginBottom: theme.spacing(2),
});
