import { Device } from '@weave/schema-gen-ts/dist/schemas/phone/user/v1/user_service.pb';
import { VoicemailBox } from '@weave/schema-gen-ts/dist/schemas/phone-exp/phone-records/v1/voicemail.pb';
import { VoicemailApi } from '@frontend/api-voicemails';
import { useScopedQuery } from '@frontend/scope';
import { queryKeys } from '../query-keys';

interface UseVoicemailBoxListProps {
  phoneConfig: Device | undefined;
  locationIds: string[];
}

export const useVoicemailBoxList = ({ phoneConfig, locationIds }: UseVoicemailBoxListProps) => {
  const { data, isLoading } = useScopedQuery({
    queryKey: queryKeys.listVoicemailBox(phoneConfig?.sipProfileId ?? ''),
    queryFn: async () => {
      const { voicemailBoxes: data } = await VoicemailApi.listVoicemailBox({
        locationIds: locationIds,
        sipProfileId: phoneConfig?.sipProfileId,
        includeNonSharedMailboxes: true,
      });
      return data ?? [];
    },
    select: (data: VoicemailBox[]) => {
      return (
        data?.sort((a, b) => {
          return a?.mailbox?.name.localeCompare(b?.mailbox?.name ?? '') || 0;
        }) ?? []
      );
    },
    refetchOnMount: true,
  });

  return { data, isLoading };
};
