export const pendoTags = {
  activitiesPage: 'app-forms-client-pg-activities',
  builderPage: 'app-forms-client-pg-builder',
  trainingPage: 'app-forms-client-pg-training',
  navigateToAppFromDashboard: 'app-forms-client-navigate-to-app-from-dashboard',
  kioskPage: {
    _: 'app-forms-client-pg-kiosk',
    staffCheckIn: {
      start: 'app-forms-kiosk-staff-check-in-start',
      appointmentDate: {
        selected: 'app-forms-kiosk-appointment-date-selected',
        revisited: 'app-forms-kiosk-appointment-date-revisited',
        skippped: 'app-forms-kiosk-appointment-date-skipped',
      },
      recipient: {
        selected: 'app-forms-kiosk-recipient-selected',
        revisited: 'app-forms-kiosk-recipient-revisited',
      },
      form: {
        selected: 'app-forms-kiosk-form-or-packet-selected',
      },
      kioskOpened: 'app-forms-kiosk-solicited-link-opened',
    },
    patronCheckIn: {
      start: 'app-forms-kiosk-patron-check-in-start',
      kioskOpened: 'app-forms-client-btn-launchkiosk',
      downloadKioskQr: 'app-forms-kiosk-download-qr',
      confirmGoToMyForms: 'app-forms-kiosk-go-to-my-forms-confirm',
      cancelGoToMyForms: 'app-forms-kiosk-go-to-my-forms-cancel',
    },
    customiseOrder: {
      start: 'app-forms-kiosk-customise-kiosk-form',
      sort: 'app-forms-kiosk-customise-kiosk-form-sort',
      save: 'app-forms-kiosk-customise-kiosk-form-save',
      togglePin: 'app-forms-kiosk-customise-kiosk-form-toggle-pin',
      toggleVisibility: 'app-forms-kiosk-customise-kiosk-form-toggle-visibility',
    },
  },
  // submissions page starts
  submissionCategories: {
    inbox: 'app-forms-client-btn-inbox-tab',
    sent: 'app-forms-client-btn-sent-tab',
    archive: 'app-forms-client-btn-archived-tab',
  },
  submissionSubCategories: {
    inbox: {
      all: 'app-forms-client-btn-allinprog',
      needsReview: 'app-forms-client-btn-needsreview',
      retrySync: 'app-forms-client-btn-failedsync',
      syncManually: 'app-forms-client-btn-approvalneeded',
      reviewedSubmissions: 'app-forms-btn-reviewed',
      completed: 'app-forms-client-btn-completed',
    },
  },
  sortOrder: 'app-forms-client-btn-sort-order',
  searchbarField: 'app-forms-client-btn-searchbar',
  bulkSelection: 'app-forms-client-btn-bulk-selection',
  submissionFilters: {
    filterBtn: 'app-forms-client-btn-filter',
    applyFilters: 'app-forms-client-submissions-applyfilters',
    allSubmissions: 'app-forms-client-btn-allsubmissions-filter',
    last7Days: 'app-forms-client-btn-last7days-filter',
    newestFirst: 'app-forms-client-btn-newestfirst-filter',
    oldestFirst: 'app-forms-client-btn-oldestfirst-filter',
  },
  // submissions page ends
  settingsPage: 'app-forms-client-pg-settings',
  profileButton: 'app-forms-client-btn-profile',
  companyChangeButton: 'app-forms-client-btn-changecompany',
  recentSubmitButton: 'app-forms-client-btn-recentsubmit',
  pendingButton: 'app-forms-client-btn-pending',
  eaglesoftMedicalHistorySyncButton: 'app-forms-client-btn-custom-med-history-form',
  viewFormData: 'app-forms-client-btn-viewformdata',
  nextForm: 'app-forms-client-btn-viewright',
  previousForm: 'app-forms-client-btn-viewleft',
  viewFormPreview: 'app-forms-client-btn-viewform',
  viewAttachmentsPreview: 'app-forms-client-btn-viewattach',
  attachmentDownload: 'app-forms-client-btn-viewdownload',
  attachmentDownloadPDF: 'app-forms-client-btn-download-attach-pdf',
  formApproveButton: 'app-forms-client-btn-viewapprove',
  modalApproveButton: 'app-forms-client-btn-modalsubmitwb',
  modalCancelButton: 'app-forms-client-btn-modalcancel',
  modalViewFormButton: 'app-forms-client-btn-modalform',
  modalViewAttachmentsButton: 'app-forms-client-btn-modalattach',
  modalDownloadButton: 'app-forms-client-btn-modaldownload',
  modalPrintButton: 'app-forms-client-btn-modalprint',
  modalEditButton: 'app-forms-client-btn-modaledit',
  modalExitButton: 'app-forms-client-btn-modalexit',
  formNavigator: 'app-forms-client-btn-form-navigator',
  searchFilterButton: 'app-forms-client-btn-searchfilter',
  templatesButton: 'app-forms-client-btn-templates',
  packetsButton: 'app-forms-client-btn-packets',
  legalButton: 'app-forms-client-btn-legaldocs',
  templateSearch: 'app-forms-client-btn-templatesearchbar',
  templateSearchFilter: 'app-forms-client-btn-templatesearchfilter',
  viewTemplate: 'app-forms-client-btn-viewtemplate',
  templateMenuButton: 'app-forms-client-btn-templatemenu',
  templateCopyButton: 'app-forms-client-btn-templatecopy',
  templateEditButton: 'app-forms-client-btn-templateedit',
  templateDeleteButton: 'app-forms-client-btn-templatedelete',
  viewPacket: 'app-forms-client-btn-viewpacket',
  packetSearch: 'app-forms-client-btn-packetsearch',
  packetRearrange: 'app-forms-client-btn-rearrange',
  packetViewFormButton: 'app-forms-client-btn-viewincludedform',
  packetRemoveFormButton: 'app-forms-client-btn-remove',
  savePacketButton: 'app-forms-client-btn-savepacket',
  addPacketButton: 'app-forms-client-btn-newpacket',
  packetMenuButton: 'app-forms-client-btn-packetmenu',
  packetNameEditButton: 'app-forms-client-btn-editpacketname',
  packetCopyButton: 'app-forms-client-btn-copypacket',
  packetDeleteButton: 'app-forms-client-btn-deletepacket',
  brandingButton: 'app-forms-client-btn-branding',
  writebacksButton: 'app-forms-client-btn-wbsettings',
  medicalButton: 'app-forms-client-btn-medical',
  patientCreationSetting: 'app-forms-client-btn-patientcreation',
  patientUpdateSetting: 'app-forms-client-btn-patientupdate',
  pdfUploadSetting: 'app-forms-client-btn-PDF',
  packetPDFDownloadButton: 'app-forms-client-btn-packetPDF',
  markAsUnread: 'app-forms-client-btn-markasunread-web',
  writebackOnOff: 'app-forms-client-btn-onoff',
  writebackAuto: 'app-forms-client-btn-automatic',
  writebackManual: 'app-forms-client-btn-manual',
  writebackRetry: 'app-forms-client-btn-retrywb',
  changeWritebackSyncApp: 'app-forms-client-btn-changePMSforsubmission',
  medicalHistory: {
    syncButton: 'app-forms-client-btn-medhistorysync',
    optionToggle: 'app-forms-client-btn-medhistorytoggle',
    eagleSoft: 'app-forms-client-btn-template-ESmedhistory',
  },
  manualWriteback: {
    syncAll: 'app-forms-client-btn-manualwb-all',
    syncSelected: 'app-forms-client-btn-manualwb-selected',
  },
  archive: {
    category: 'app-forms-client-btn-archived',
    action: {
      singleArchive: 'app-forms-client-btn-menu-archiveCTA',
      singleUnarchive: 'app-forms-client-btn-menu-unarchiveCTA',
    },
  },
  bulkActions: {
    archive: 'app-forms-client-btn-archiveCTA',
    unarchive: 'app-forms-client-btn-unarchiveCTA',
    read: 'app-forms-client-btn-markasreadCTA',
    unread: 'app-forms-client-btn-markasunreadCTA',
  },
  reminderModals: {
    writebackAuthorization: {
      settingsPageLink: 'app-forms-client-btn-dialog-authwb-UI',
      weaveHelpLink: 'app-forms-client-btn-dialog-authwb-help',
    },
  },
  settings: {
    writebacks: {
      authorize: 'app-forms-client-tab-authorizewb',
      deauthorize: 'app-forms-client-tab-deauthorizewb',
      confirmAuthorize: 'app-forms-client-btn-modalconfirmauthorize',
      confirmDeauthorize: 'app-forms-client-btn-modalconfirmdeauthorize',
      changeDefaultPMS: 'app-forms-client-btn-changedefaultsyncapp',
      confirmDefaultPMSChange: 'app-forms-client-btn-confirmdefaultsyncapp',
      cancelDefaultPMSChange: 'app-forms-client-btn-canceldefaultsyncappchange',
      saveUnifySettings: 'app-forms-client-btn-saveunifysettings',
    },
    page: {
      writeback: {
        links: {
          root: 'app-forms-client-link-writeback',
          sources: 'app-forms-client-link-writeback-sources',
          settings: 'app-forms-client-link-writeback-settings',
        },
        newPatientCreation: {
          off: 'app-forms-client-btn-patientcreation-off',
          manual: 'app-forms-client-btn-patientcreation-manual',
          automatic: 'app-forms-client-btn-patientcreation-automatic',
        },
        patientRecord: {
          off: 'app-forms-client-btn-patientupdate-off',
          manual: 'app-forms-client-btn-patientupdate-manual',
          automatic: 'app-forms-client-btn-patientupdate-automatic',
        },
        pdfDocument: {
          off: 'app-forms-client-btn-PDF-off',
          manual: 'app-forms-client-btn-PDF-manual',
          automatic: 'app-forms-client-btn-PDF-automatic',
        },
      },
      medicalHistory: {
        link: 'app-forms-client-link-medicalhistory',
        allergies: {
          link: 'app-forms-client-medicalhistory-allergies',
          sync: 'app-forms-client-allergies-sync',
          save: 'app-forms-client-allergies-save',
        },
        medicalConditions: {
          link: 'app-forms-client-medicalhistory-medicalconditions',
          sync: 'app-forms-client-medicalconditions-sync',
          save: 'app-forms-client-medicalconditions-save',
        },
        medications: {
          link: 'app-forms-client-medicalhistory-medications',
          sync: 'app-forms-client-medications-sync',
          save: 'app-forms-client-medications-save',
        },
      },
      terms: {
        link: 'app-forms-client-link-termsconditions',
        preview: 'app-forms-client-preview-terms',
        save: 'app-forms-client-save-terms',
      },
      privacy: {
        link: 'app-forms-client-link-privacypolicy',
        preview: 'app-forms-client-preview-privacy',
        save: 'app-forms-client-save-privacy',
      },
      providerMapping: {
        link: 'app-forms-client-link-providermapping',
        changeMapping: 'app-forms-client-btn-changeprovidermapping',
        save: 'app-forms-client-btn-saveprovidermapping',
      },
      customizeKiosk: {
        link: 'app-forms-client-link-customizekiosk',
      },
    },
  },
  formBuilder: {
    actions: {
      preview: {
        newForm: 'app-forms-builder-preview-new-form',
        singleLocationForm: 'app-forms-builder-preview-single-location-form',
        multiLocationForm: {
          openLocationPickerModal: 'app-forms-builder-preview-multi-location-form-open-location-picker-modal',
          openLocationPicker: 'app-forms-builder-preview-multi-location-form-open-location-picker',
          changeLocation: 'app-forms-builder-preview-multi-location-form-change-location',
          show: 'app-forms-builder-preview-multi-location-form-show-preview',
          cancel: 'app-forms-builder-preview-multi-location-form-cancel-preview',
        },
      },
      generatePDF: {
        newForm: 'app-forms-builder-generate-pdf-new-form',
        singleLocationForm: 'app-forms-builder-generate-pdf-single-location-form',
        multiLocationForm: {
          openLocationPickerModal: 'app-forms-builder-generate-pdf-multi-location-form-open-location-picker-modal',
          openLocationPicker: 'app-forms-builder-generate-pdf-multi-location-form-open-location-picker',
          changeLocation: 'app-forms-builder-generate-pdf-multi-location-form-change-location',
          generate: 'app-forms-builder-generate-pdf-multi-location-form-generate',
          cancel: 'app-forms-builder-generate-pdf-multi-location-form-cancel',
        },
      },
      saveForm: {
        updateForm: 'app-forms-builder-save-form-update-form',
        singleLocationForm: 'app-forms-builder-save-form-single-location-form',
        multiLocationForm: {
          openLocationPickerModal: 'app-forms-builder-save-form-multi-location-form-open-location-picker-modal',
          openLocationPicker: 'app-forms-builder-save-form-multi-location-form-open-location-picker',
          changeLocation: 'app-forms-builder-save-form-multi-location-form-change-location',
          save: 'app-forms-builder-save-form-multi-location-form-save',
          cancel: 'app-forms-builder-save-form-multi-location-form-cancel',
          selectAllLocations: 'app-forms-builder-save-form-multi-location-form-select-all-available-locations',
          clearAllLocations: 'app-forms-builder-save-form-multi-location-form-clear-all-locations',
          selectLocation: 'app-forms-builder-save-form-multi-location-form-select-location',
          deselectLocation: 'app-forms-builder-save-form-multi-location-form-deselect-location',
          applyLocations: 'app-forms-builder-save-form-multi-location-form-apply-locations',
        },
      },
    },
    divisions: {
      primaryFields: 'app-forms-builder-primary-fields-division',
      elements: 'app-forms-builder-elements-division',
      medicalHistory: 'app-forms-builder-medical-history-division',
      sectionTemplates: 'app-forms-builder-section-templates-division',
      payments: 'app-forms-builder-payments-division',
    },
    categories: {
      primaryFields: {
        personalInformation: {
          firstName: 'app-forms-builder-primary-fields--personal-information--first-name',
          middleName: 'app-forms-builder-primary-fields--personal-information--middle-name',
          lastName: 'app-forms-builder-primary-fields--personal-information--last-name',
          preferredName: 'app-forms-builder-primary-fields--personal-information--preferred-name',
          birthdate: 'app-forms-builder-primary-fields--personal-information--birthdate',
          gender: 'app-forms-builder-primary-fields--personal-information--gender',
          maritalStatus: 'app-forms-builder-primary-fields--personal-information--marital-status',
          socialSecurityNumber: 'app-forms-builder-primary-fields--personal-information--social-security-number',
          driversLicenseNumber: 'app-forms-builder-primary-fields--personal-information--drivers-license-number',
        },
        contactInformation: {
          mobilePhone: 'app-forms-builder-primary-fields--contact-information--mobile-phone',
          homePhone: 'app-forms-builder-primary-fields--contact-information--home-phone',
          workPhone: 'app-forms-builder-primary-fields--contact-information--work-phone',
          email: 'app-forms-builder-primary-fields--contact-information--email',
        },
        residentialAddress: {
          address1: 'app-forms-builder-primary-fields--residential-address--address-1',
          address2: 'app-forms-builder-primary-fields--residential-address--address-2',
          city: 'app-forms-builder-primary-fields--residential-address--city',
          state: 'app-forms-builder-primary-fields--residential-address--state',
          postalCode: 'app-forms-builder-primary-fields--residential-address--postal-code',
        },
        insuranceInformation: {
          primaryInsuranceId: 'app-forms-builder-primary-fields--insurance-information--primary-insurance-id',
          primaryInsuranceAddress1:
            'app-forms-builder-primary-fields--insurance-information--primary-insurance-address-1',
          primaryInsuranceAddress2:
            'app-forms-builder-primary-fields--insurance-information--primary-insurance-address-2',
          primaryInsuranceCity: 'app-forms-builder-primary-fields--insurance-information--primary-insurance-city',
          primaryInsuranceName: 'app-forms-builder-primary-fields--insurance-information--primary-insurance-name',
          primaryInsurancePostalCode:
            'app-forms-builder-primary-fields--insurance-information--primary-insurance-postal-code',
          primaryInsuranceRelationship:
            'app-forms-builder-primary-fields--insurance-information--primary-insurance-relationship',
          primaryInsuranceSSN: 'app-forms-builder-primary-fields--insurance-information--primary-insurance-ssn',
          primaryInsuranceSpouseName:
            'app-forms-builder-primary-fields--insurance-information--primary-insurance-spouse-name',
          primaryInsuranceSpouseSSN:
            'app-forms-builder-primary-fields--insurance-information--primary-insurance-spouse-ssn',
          primaryInsuranceState: 'app-forms-builder-primary-fields--insurance-information--primary-insurance-state',
          primaryPolicyHolderName:
            'app-forms-builder-primary-fields--insurance-information--primary-policy-holder-name',
          primaryGroupNumber: 'app-forms-builder-primary-fields--insurance-information--primary-group-number',
          primaryRxNumber: 'app-forms-builder-primary-fields--insurance-information--primary-rx-number',
          secondaryInsuranceId: 'app-forms-builder-primary-fields--insurance-information--secondary-insurance-id',
          secondaryInsuranceAddress1:
            'app-forms-builder-primary-fields--insurance-information--secondary-insurance-address-1',
          secondaryInsuranceAddress2:
            'app-forms-builder-primary-fields--insurance-information--secondary-insurance-address-2',
          secondaryInsuranceCity: 'app-forms-builder-primary-fields--insurance-information--secondary-insurance-city',
          secondaryInsuranceName: 'app-forms-builder-primary-fields--insurance-information--secondary-insurance-name',
          secondaryInsurancePostalCode:
            'app-forms-builder-primary-fields--insurance-information--secondary-insurance-postal-code',
          secondaryInsuranceRelationship:
            'app-forms-builder-primary-fields--insurance-information--secondary-insurance-relationship',
          secondaryInsuranceSSN: 'app-forms-builder-primary-fields--insurance-information--secondary-insurance-ssn',
          secondaryInsuranceSpouseName:
            'app-forms-builder-primary-fields--insurance-information--secondary-insurance-spouse-name',
          secondaryInsuranceSpouseSSN:
            'app-forms-builder-primary-fields--insurance-information--secondary-insurance-spouse-ssn',
          secondaryInsuranceState: 'app-forms-builder-primary-fields--insurance-information--secondary-insurance-state',
          secondaryPolicyHolderName:
            'app-forms-builder-primary-fields--insurance-information--secondary-policy-holder-name',
          secondaryGroupNumber: 'app-forms-builder-primary-fields--insurance-information--secondary-group-number',
          secondaryRxNumber: 'app-forms-builder-primary-fields--insurance-information--secondary-rx-number',
        },
        medicalHistory: {
          allergies: 'app-forms-builder-primary-fields--medical-history--allergies',
          medications: 'app-forms-builder-primary-fields--medical-history--medications',
          disease: 'app-forms-builder-primary-fields--medical-history--disease',
        },
      },
      otherFields: {
        genericFormFields: {
          eSign: 'app-forms-builder-other-fields--generic-form-fields--e-sign',
          cardCapture: 'app-forms-builder-other-fields--generic-form-fields--card-capture',
          checklist: 'app-forms-builder-other-fields--generic-form-fields--checklist',
          checkbox: 'app-forms-builder-other-fields--generic-form-fields--checkbox',
          date: 'app-forms-builder-other-fields--generic-form-fields--date',
          select: 'app-forms-builder-other-fields--generic-form-fields--select',
          email: 'app-forms-builder-other-fields--generic-form-fields--email',
          richText: 'app-forms-builder-other-fields--generic-form-fields--rich-text',
          textarea: 'app-forms-builder-other-fields--generic-form-fields--textarea',
          text: 'app-forms-builder-other-fields--generic-form-fields--text',
        },
        surveyAnswers: {
          radio: 'app-forms-builder-other-fields--survey-answers--radio',
          binaryDecision: 'app-forms-builder-other-fields--survey-answers--binary-decision',
        },
        contactInformation: {
          phone: 'app-forms-builder-other-fields--contact-information--phone',
          postalCode: 'app-forms-builder-other-fields--contact-information--postal-code',
          referralSource: 'app-forms-builder-other-fields--contact-information--referral-source',
          ssn: 'app-forms-builder-other-fields--contact-information--ssn',
        },
      },
      medicalHistoryFields: {
        medicalHistory: {
          staticAllergies: 'app-forms-builder-medical-history-fields--medical-history--static-allergies',
          medicalConditions: 'app-forms-builder-medical-history-fields--medical-history--medical-conditions',
          femaleOnlyConditions: 'app-forms-builder-medical-history-fields--medical-history--female-only-conditions',
        },
      },
      sectionTemplates: {
        allergies: 'app-forms-builder-section-templates--allergies',
        covid: 'app-forms-builder-section-templates--covid',
        femaleMedicalConditions: 'app-forms-builder-section-templates--female-medical-conditions',
        medicalConditions: 'app-forms-builder-section-templates--medical-conditions',
        patient: 'app-forms-builder-section-templates--patient',
        primaryInsurance: 'app-forms-builder-section-templates--primary-insurance',
        secondaryInsurance: 'app-forms-builder-section-templates--secondary-insurance',
      },
      paymentsFields: {
        cardOnFile: 'app-forms-builder-payments-fields--card-on-file',
      },
    },
    sections: {
      addFirstSection: 'app-forms-builder--sections--add-first-section',
      addAnotherSection: 'app-forms-builder--sections--add-another-section',
      editTitle: 'app-forms-builder--sections--edit-title',
      clone: {
        dontAskAgain: 'app-forms-builder--sections--clone-section--dont-ask-again',
        confirm: 'app-forms-builder--sections--clone-section--confirm',
        cancel: 'app-forms-builder--sections--clone-section--cancel',
        trigger: 'app-forms-builder--sections--clone-section--trigger',
      },
      delete: {
        confirm: 'app-forms-builder--sections--delete-section--confirm',
        cancel: 'app-forms-builder--sections--delete-section--cancel',
        trigger: 'app-forms-builder--sections--delete-section--trigger',
      },
      moveSectionUp: 'app-forms-builder--sections--move-section-up',
      moveSectionDown: 'app-forms-builder--sections--move-section-down',
    },
    fields: {
      conditions: {
        delete: {
          confirm: 'app-forms-builder--fields--conditions--delete--confirm',
          cancel: 'app-forms-builder--fields--conditions--delete--cancel',
        },
        sourceFieldConditions: {
          isEqualTo: 'app-forms-builder--fields--conditions--source-field-conditions--is-equal-to',
          isNotEqualTo: 'app-forms-builder--fields--conditions--source-field-conditions--is-not-equal-to',
          isAnswered: 'app-forms-builder--fields--conditions--source-field-conditions--is-answered',
          isGreaterThan: 'app-forms-builder--fields--conditions--source-field-conditions--is-greater-than',
          isLessThan: 'app-forms-builder--fields--conditions--source-field-conditions--is-less-than',
          isGreaterThanOrEqual:
            'app-forms-builder--fields--conditions--source-field-conditions--is-greater-than-or-equal',
          isLessThanOrEqual: 'app-forms-builder--fields--conditions--source-field-conditions--is-less-than-or-equal',
          contains: 'app-forms-builder--fields--conditions--source-field-conditions--contains',
          notContains: 'app-forms-builder--fields--conditions--source-field-conditions--not-contains',
        },
        changeSourceFieldConditionValue: 'app-forms-builder--fields--conditions--change-source-field-condition-value',
        targetedFieldConditions: {
          show: 'app-forms-builder--fields--conditions--targeted-field-conditions--show',
          hide: 'app-forms-builder--fields--conditions--targeted-field-conditions--hide',
        },
        targetedFields: 'app-forms-builder--fields--conditions--targeted-fields',
      },
      clone: {
        confirm: 'app-forms-builder--fields--clone--confirm',
        cancel: 'app-forms-builder--fields--clone--cancel',
        dontAskAgain: 'app-forms-builder--fields--clone--dont-ask-again',
      },
      delete: {
        confirm: 'app-forms-builder--fields--delete--confirm',
        cancel: 'app-forms-builder--fields--delete--cancel',
      },
      editFieldName: 'app-forms-builder--fields--edit-field-name',
      cardCaptureField: {
        conditions: {
          addNew: 'app-forms-builder--fields--card-capture-field--conditions--add-new',
          delete: 'app-forms-builder--fields--card-capture-field--conditions--delete',
        },
        clone: 'app-forms-builder--fields--card-capture-field--clone',
        delete: 'app-forms-builder--fields--card-capture-field--delete',
        markAsRequired: {
          toggleOn: 'app-forms-builder--fields--card-capture-field--mark-as-required--toggle-on',
          toggleOff: 'app-forms-builder--fields--card-capture-field--mark-as-required--toggle-off',
        },
      },
      cardOnFileField: {
        clone: 'app-forms-builder--fields--card-on-file-field--clone',
        delete: 'app-forms-builder--fields--card-on-file-field--delete',
        markAsRequired: {
          toggleOn: 'app-forms-builder--fields--card-on-file-field--mark-as-required--toggle-on',
          toggleOff: 'app-forms-builder--fields--card-on-file-field--mark-as-required--toggle-off',
        },
        paymentOnboarding: {
          continueWithoutOnboardingAllLocations:
            'app-forms-builder--fields--card-on-file-field--payment-onboarding--continue-without-onboarding-all-locations',
          setupPayments: 'app-forms-builder--fields--card-on-file-field--payment-onboarding--setup-payments',
          cancelOnboarding: 'app-forms-builder--fields--card-on-file-field--payment-onboarding--cancel-onboarding',
        },
      },
      checkboxField: {
        conditions: {
          addNew: 'app-forms-builder--fields--checkbox-field--conditions--add-new',
          delete: 'app-forms-builder--fields--checkbox-field--conditions--delete',
        },
        clone: 'app-forms-builder--fields--checkbox-field--clone',
        delete: 'app-forms-builder--fields--checkbox-field--delete',
        markAsRequired: {
          toggleOn: 'app-forms-builder--fields--checkbox-field--mark-as-required--toggle-on',
          toggleOff: 'app-forms-builder--fields--checkbox-field--mark-as-required--toggle-off',
        },
      },
      dateField: {
        conditions: {
          addNew: 'app-forms-builder--fields--date-field--conditions--add-new',
          delete: 'app-forms-builder--fields--date-field--conditions--delete',
        },
        clone: 'app-forms-builder--fields--date-field--clone',
        delete: 'app-forms-builder--fields--date-field--delete',
        markAsRequired: {
          toggleOn: 'app-forms-builder--fields--date-field--mark-as-required--toggle-on',
          toggleOff: 'app-forms-builder--fields--date-field--mark-as-required--toggle-off',
        },
      },
      digitalSignatureField: {
        conditions: {
          addNew: 'app-forms-builder--fields--digital-signature-field--conditions--add-new',
          delete: 'app-forms-builder--fields--digital-signature-field--conditions--delete',
        },
        clone: 'app-forms-builder--fields--digital-signature-field--clone',
        delete: 'app-forms-builder--fields--digital-signature-field--delete',
        markAsRequired: {
          toggleOn: 'app-forms-builder--fields--digital-signature-field--mark-as-required--toggle-on',
          toggleOff: 'app-forms-builder--fields--digital-signature-field--mark-as-required--toggle-off',
        },
      },
      digitalTypedSignatureField: {
        conditions: {
          addNew: 'app-forms-builder--fields--digital-typed-signature-field--conditions--add-new',
          delete: 'app-forms-builder--fields--digital-typed-signature-field--conditions--delete',
        },
        clone: 'app-forms-builder--fields--digital-typed-signature-field--clone',
        delete: 'app-forms-builder--fields--digital-typed-signature-field--delete',
        markAsRequired: {
          toggleOn: 'app-forms-builder--fields--digital-typed-signature-field--mark-as-required--toggle-on',
          toggleOff: 'app-forms-builder--fields--digital-typed-signature-field--mark-as-required--toggle-off',
        },
      },
      emailField: {
        conditions: {
          addNew: 'app-forms-builder--fields--email-field--conditions--add-new',
          delete: 'app-forms-builder--fields--email-field--conditions--delete',
        },
        clone: 'app-forms-builder--fields--email-field--clone',
        delete: 'app-forms-builder--fields--email-field--delete',
        markAsRequired: {
          toggleOn: 'app-forms-builder--fields--email-field--mark-as-required--toggle-on',
          toggleOff: 'app-forms-builder--fields--email-field--mark-as-required--toggle-off',
        },
      },
      checklistField: {
        conditions: {
          addNew: 'app-forms-builder--fields--checklist-field--conditions--add-new',
          delete: 'app-forms-builder--fields--checklist-field--conditions--delete',
        },
        clone: 'app-forms-builder--fields--checklist-field--clone',
        delete: 'app-forms-builder--fields--checklist-field--delete',
        markAsRequired: {
          toggleOn: 'app-forms-builder--fields--checklist-field--mark-as-required--toggle-on',
          toggleOff: 'app-forms-builder--fields--checklist-field--mark-as-required--toggle-off',
        },
        addOption: 'app-forms-builder--fields--checklist-field--add-option',
        editOption: 'app-forms-builder--fields--checklist-field--edit-option',
        removeOption: 'app-forms-builder--fields--checklist-field--remove-option',
      },
      optionSwitchField: {
        conditions: {
          addNew: 'app-forms-builder--fields--option-switch-field--conditions--add-new',
          delete: 'app-forms-builder--fields--option-switch-field--conditions--delete',
        },
        clone: 'app-forms-builder--fields--option-switch-field--clone',
        delete: 'app-forms-builder--fields--option-switch-field--delete',
        markAsRequired: {
          toggleOn: 'app-forms-builder--fields--option-switch-field--mark-as-required--toggle-on',
          toggleOff: 'app-forms-builder--fields--option-switch-field--mark-as-required--toggle-off',
        },
        addOption: 'app-forms-builder--fields--option-switch-field--add-option',
        editOption: 'app-forms-builder--fields--option-switch-field--edit-option',
        removeOption: 'app-forms-builder--fields--option-switch-field--remove-option',
      },
      radioField: {
        conditions: {
          addNew: 'app-forms-builder--fields--radio-field--conditions--add-new',
          delete: 'app-forms-builder--fields--radio-field--conditions--delete',
        },
        clone: 'app-forms-builder--fields--radio-field--clone',
        delete: 'app-forms-builder--fields--radio-field--delete',
        markAsRequired: {
          toggleOn: 'app-forms-builder--fields--radio-field--mark-as-required--toggle-on',
          toggleOff: 'app-forms-builder--fields--radio-field--mark-as-required--toggle-off',
        },
        addOption: 'app-forms-builder--fields--radio-field--add-option',
        editOption: 'app-forms-builder--fields--radio-field--edit-option',
        removeOption: 'app-forms-builder--fields--radio-field--remove-option',
      },
      selectField: {
        conditions: {
          addNew: 'app-forms-builder--fields--select-field--conditions--add-new',
          delete: 'app-forms-builder--fields--select-field--conditions--delete',
        },
        clone: 'app-forms-builder--fields--select-field--clone',
        delete: 'app-forms-builder--fields--select-field--delete',
        markAsRequired: {
          toggleOn: 'app-forms-builder--fields--select-field--mark-as-required--toggle-on',
          toggleOff: 'app-forms-builder--fields--select-field--mark-as-required--toggle-off',
        },
        addOption: 'app-forms-builder--fields--select-field--add-option',
        editOption: 'app-forms-builder--fields--select-field--edit-option',
        removeOption: 'app-forms-builder--fields--select-field--remove-option',
      },
      searchableListboxField: {
        conditions: {
          addNew: 'app-forms-builder--fields--searchable-listbox-field--conditions--add-new',
          delete: 'app-forms-builder--fields--searchable-listbox-field--conditions--delete',
        },
        clone: 'app-forms-builder--fields--searchable-listbox-field--clone',
        delete: 'app-forms-builder--fields--searchable-listbox-field--delete',
        markAsRequired: {
          toggleOn: 'app-forms-builder--fields--searchable-listbox-field--mark-as-required--toggle-on',
          toggleOff: 'app-forms-builder--fields--searchable-listbox-field--mark-as-required--toggle-off',
        },
        addOption: 'app-forms-builder--fields--searchable-listbox-field--add-option',
        editOption: 'app-forms-builder--fields--searchable-listbox-field--edit-option',
        removeOption: 'app-forms-builder--fields--searchable-listbox-field--remove-option',
      },
      phoneField: {
        conditions: {
          addNew: 'app-forms-builder--fields--phone-field--conditions--add-new',
          delete: 'app-forms-builder--fields--phone-field--conditions--delete',
        },
        clone: 'app-forms-builder--fields--phone-field--clone',
        delete: 'app-forms-builder--fields--phone-field--delete',
        markAsRequired: {
          toggleOn: 'app-forms-builder--fields--phone-field--mark-as-required--toggle-on',
          toggleOff: 'app-forms-builder--fields--phone-field--mark-as-required--toggle-off',
        },
      },
      postalCodeField: {
        conditions: {
          addNew: 'app-forms-builder--fields--postal-code-field--conditions--add-new',
          delete: 'app-forms-builder--fields--postal-code-field--conditions--delete',
        },
        clone: 'app-forms-builder--fields--postal-code-field--clone',
        delete: 'app-forms-builder--fields--postal-code-field--delete',
        markAsRequired: {
          toggleOn: 'app-forms-builder--fields--postal-code-field--mark-as-required--toggle-on',
          toggleOff: 'app-forms-builder--fields--postal-code-field--mark-as-required--toggle-off',
        },
      },
      richTextEditorField: {
        conditions: {
          addNew: 'app-forms-builder--fields--rich-text-editor-field--conditions--add-new',
          delete: 'app-forms-builder--fields--rich-text-editor-field--conditions--delete',
        },
        clone: 'app-forms-builder--fields--rich-text-editor-field--clone',
        delete: 'app-forms-builder--fields--rich-text-editor-field--delete',
        markAsRequired: {
          toggleOn: 'app-forms-builder--fields--rich-text-editor-field--mark-as-required--toggle-on',
          toggleOff: 'app-forms-builder--fields--rich-text-editor-field--mark-as-required--toggle-off',
        },
        edit: 'app-forms-builder--fields--rich-text-editor-field--edit',
        cancelEdit: 'app-forms-builder--fields--rich-text-editor-field--cancel-edit',
        closeModal: 'app-forms-builder--fields--rich-text-editor-field--close-modal',
        save: 'app-forms-builder--fields--rich-text-editor-field--save-changes',
      },
      ssnField: {
        conditions: {
          addNew: 'app-forms-builder--fields--ssn-field--conditions--add-new',
          delete: 'app-forms-builder--fields--ssn-field--conditions--delete',
        },
        clone: 'app-forms-builder--fields--ssn-field--clone',
        delete: 'app-forms-builder--fields--ssn-field--delete',
        markAsRequired: {
          toggleOn: 'app-forms-builder--fields--ssn-field--mark-as-required--toggle-on',
          toggleOff: 'app-forms-builder--fields--ssn-field--mark-as-required--toggle-off',
        },
      },
      textAreaField: {
        conditions: {
          addNew: 'app-forms-builder--fields--text-area-field--conditions--add-new',
          delete: 'app-forms-builder--fields--text-area-field--conditions--delete',
        },
        clone: 'app-forms-builder--fields--text-area-field--clone',
        delete: 'app-forms-builder--fields--text-area-field--delete',
        markAsRequired: {
          toggleOn: 'app-forms-builder--fields--text-area-field--mark-as-required--toggle-on',
          toggleOff: 'app-forms-builder--fields--text-area-field--mark-as-required--toggle-off',
        },
      },
      textField: {
        conditions: {
          addNew: 'app-forms-builder--fields--text-field--conditions--add-new',
          delete: 'app-forms-builder--fields--text-field--conditions--delete',
        },
        clone: 'app-forms-builder--fields--text-field--clone',
        delete: 'app-forms-builder--fields--text-field--delete',
        markAsRequired: {
          toggleOn: 'app-forms-builder--fields--text-field--mark-as-required--toggle-on',
          toggleOff: 'app-forms-builder--fields--text-field--mark-as-required--toggle-off',
        },
      },
    },
  },
  beta: {
    unifyOptOut: 'app-form-client-btn-unify-beta-optout',
  },
  templateGallery: {
    navLink: 'app-template-gallery-nav',
    termsAndConditions: {
      accepted: 'app-template-gallery-terms-and-conditions-accepted',
      declined: 'app-template-gallery-terms-and-conditions-declined',
    },
    views: {
      grid: 'app-template-gallery-grid-view',
      list: 'app-template-gallery-list-view',
    },
    location: {
      save: 'app-template-gallery-save-location',
      cancel: 'app-template-gallery-cancel-location',
    },
    copyTemplate: 'app-template-gallery-copy-template',
    previewButton: 'app-template-gallery-preview-btn',
    preview: {
      desktop: 'app-template-gallery-desktop-preview',
      mobile: 'app-template-gallery-mobile-preview',
    },
    markAs: {
      favourite: 'app-template-gallery-favourite-the-template',
      unfavourite: 'app-template-gallery-unfavourite-the-template',
    },
    sort: {
      aToZ: 'app-template-gallery-sort-a-z',
      zToA: 'app-template-gallery-sort-z-a',
      oldToNew: 'app-template-gallery-sort-old-to-new',
      newToOld: 'app-template-gallery-sort-new-to-old',
      mostPopular: 'app-template-gallery-sort-by-most-popular',
    },
    search: 'app-template-gallery-search',
    usageTerms: {
      accept: 'template-gallery-usage-terms-accept',
      decline: 'template-gallery-usage-terms-decline',
      select: 'template-gallery-usage-terms-select',
    },
  },
  // TODO: Clean up the tags under 'providerReview'
  providerReview: {
    toggleReviewForm: 'app-forms-toggle-provider-review',
    toggleReviewPacket: 'app-packet-toggle-provider-review',
    cancelForm: 'app-form-provider-review-cancel',
    cancelPacket: 'app-packet-provider-review-cancel',
    confirmForm: 'app-form-provider-review-confirm',
    confirmPacket: 'app-packet-provider-review-confirm',
    modal: {
      approve: 'app-forms-provider-review-approve',
      reject: 'app-forms-provider-review-reject',
    },
    signature: {
      saveDefaultSignature: 'app-forms-provider-review-save-default-signature',
      wetSignature: 'app-forms-provider-review-wet-signature',
      image: 'app-forms-provider-review-signature-image',
      typed: 'app-forms-provider-review-signature-typed',
    },
    approveNotes: {
      approveBtn: 'app-forms-provider-review-approve-notes-approve',
      continueToSync: 'app-forms-provider-review-approve-notes-continue-to-sync',
    },
    rejectSubmission: 'app-forms-provider-review-reject-submission',
    writebackModal: {
      sync: 'app-forms-provider-review-writeback-modal-sync',
      approve: 'app-forms-provider-review-writeback-modal-approve',
    },
    changeProvider: 'app-forms-provider-review-change-provider',
  },
  // TODO: Clean up the tags under 'writebackWidget'
  writebackWidget: {
    open: 'app-forms-wb-widget-open',
    close: 'app-forms-wb-widget-close',
    backdropClose: 'app-forms-wb-widget-close-using-backdrop',
    approve: 'app-forms-wb-widget-approve',
    showRejectUI: 'app-forms-wb-widget-show-reject-ui',
    rejectConfirm: 'app-forms-wb-widget-reject-confirm',
    cancelReject: 'app-forms-wb-widget-cancel-reject',
    retrySync: 'app-forms-wb-widget-retry-sync',
    sync: 'app-forms-wb-widget-sync',
  },
  writbackWidgetV2: {
    openModal: 'app-forms-wb-widget-v2-open-modal',
    closeModal: 'app-forms-wb-widget-v2-close-modal',
    cancel: 'app-forms-wb-widget-v2-cancel',
    sync: 'app-forms-wb-widget-v2-sync',
    retrySync: 'app-forms-wb-widget-v2-retry-sync',
    turnCreatePersonOn: 'app-forms-wb-widget-v2-turn-create-person-on',
    turnCreatePersonOff: 'app-forms-wb-widget-v2-turn-create-person-off',
    turnUpdatePersonOn: 'app-forms-wb-widget-v2-turn-update-person-on',
    turnUpdatePersonOff: 'app-forms-wb-widget-v2-turn-update-person-off',
    turnUploadDocumentOn: 'app-forms-wb-widget-v2-turn-upload-document-on',
    turnUploadDocumentOff: 'app-forms-wb-widget-v2-turn-upload-document-off',
    openAvailableSourcesList: 'app-forms-wb-widget-v2-open-available-sources-list',
    openAvailableSourceTenantsList: 'app-forms-wb-widget-v2-open-available-source-tenants-list',
    changeSource: 'app-forms-wb-widget-v2-change-source',
  },
  providerReviewV2: {
    approvalDisclaimer: 'app-provider-review-approval-disclaimer',
    approvalSignature: 'app-provider-review-approval-signature',
    approvalNote: 'app-provider-review-approval-note',
    rejectionNote: 'app-provider-review-rejection-note',
    sync: 'app-provider-review-sync',
    openModal: 'app-forms-provider-review-v2-open-modal',
    closeModal: 'app-forms-provider-review-v2-close-modal',
  },
  // TODO: Clean up the tags under 'writebackAuthSetup'
  writebackAuthSetup: {
    termsOfUse: 'app-forms-wb-setup-terms-of-use',
    getStarted: 'app-forms-wb-setup-get-started',
    cancelSourceSelection: 'app-forms-wb-setup-cancel-source-selection',
    selectAnotherSource: 'app-forms-wb-setup-select-another-source',
    reviewSourceSelection: 'app-forms-wb-setup-review-source-selection',
    goToDashboard: 'app-forms-wb-setup-go-to-dashboard',
    goToWBSettings: 'app-forms-wb-setup-go-to-wb-settings',
    skipSetup: 'app-forms-wb-setup-skip',
    contactSupport: 'app-forms-wb-setup-contact-support',
    continueSourceSelection: 'app-forms-wb-setup-continue-source-selection',
  },
  writebackSetup: {
    skipWelcomeSetup: 'app-forms-wb-setup-skip-welcome',
    skipNewLocationSetup: 'app-forms-wb-setup-skip-new-location',
    skipOutdatedSourceTenantSetup: 'app-forms-wb-setup-skip-outdated-source-tenant',
    remindWelcomeSetupLater: 'app-forms-wb-setup-remind-welcome-later',
    remindNewLocationSetupLater: 'app-forms-wb-setup-remind-new-location-later',
    remindOutdatedSourceTenantSetupLater: 'app-forms-wb-setup-remind-outdated-source-tenant-later',
    goToWelcomeSettings: 'app-forms-wb-setup-go-to-welcome-settings',
    goToNewLocationSettings: 'app-forms-wb-setup-go-to-new-location-settings',
    goToOutdatedSourceTenantSettings: 'app-forms-wb-setup-go-to-outdated-source-tenant-settings',
  },
  preferredSourceCorrection: {
    cancelNotSure: 'app-forms-wb-source-correction-not-sure',
    updatePreferredMultiPMS: 'app-forms-wb-source-correction-save-for-multi-pms',
    updatePreferredSinglePMS: 'app-forms-wb-source-correction-save-for-single-pms',
    navigateToWBSettings: 'app-forms-wb-source-correction-navigate-to-settings',
  },
  library: {
    formsTab: 'app-forms-library-forms-list',
    packetsTab: 'app-forms-library-packets-list',
    importedForms: 'app-forms-library-imported-forms-list',
    form: 'app-forms-library-form',
    packet: 'app-forms-library-packet',
    importedForm: 'app-forms-library-imported-form',
    initiateFormSending: 'app-forms-library-initiate-form-sending',
    initiatePacketSending: 'app-forms-library-initiate-packet-sending',
    forms: {
      copyFormLink: 'app-forms-library-copy-form-link',
      deleteForm: 'app-forms-library-delete-form',
      confirmDeleteForm: 'app-forms-library-confirm-delete-form',
      cancelDeleteForm: 'app-forms-library-cancel-delete-form',
      editForm: 'app-forms-library-edit-form',
      cloneFormToOtherLocations: 'app-forms-library-open-clone-form-to-other-locations',
      cloneFormToSameLocation: 'app-forms-library-open-clone-form-to-same-location',
    },
    packets: {
      createPacket: 'app-forms-library-create-packet',
      createPacketLocationSelector: 'app-forms-library-create-packet-location-selector',
      addFormToPacket: 'app-forms-library-add-form-to-packet',
      cancelAddFormToPacket: 'app-forms-library-cancel-add-form-to-packet',
      addMoreFormsToPacket: 'app-forms-library-add-more-forms-to-packet',
      savePacket: 'app-forms-library-save-packet',
      changeProviderReview: 'app-forms-library-change-provider-review',
      previewPacket: 'app-forms-library-preview-packet',
      copyPacketLink: 'app-forms-library-copy-packet-link',
      deletePacket: 'app-forms-library-delete-packet',
      confirmDeletePacket: 'app-forms-library-confirm-delete-packet',
      cancelDeletePacket: 'app-forms-library-cancel-delete-packet',
      deleteFormTile: 'app-forms-library-delete-packet-form-tile',
      deleteFormFromPreview: 'app-forms-library-delete-packet-form-from-preview',
      deleteFormConfirmation: 'app-forms-library-delete-form-confirmation',
      editPacketName: 'app-forms-library-edit-packet-name',
      previewPacketForm: 'app-forms-library-preview-packet-form',
      rearrangePacketForms: 'app-forms-library-rearrange-packet-forms',
      backToPacketList: 'app-forms-library-back-to-packet-list',
    },
  },
  sendFormsExperience: {
    close: 'app-forms-send-exp-close',
    steps: {
      appointments: {
        back: 'app-forms-send-exp-appointments-back',
        next: 'app-forms-send-exp-appointments-next',
        select: 'app-forms-send-exp-appointments-select',
        deselect: 'app-forms-send-exp-appointments-deselect',
      },
      contacts: {
        select: 'app-forms-send-exp-select-contact',
        search: 'app-forms-send-exp-search-contact',
      },
      deliveryMethod: {
        direct: {
          select: 'app-forms-send-exp-delivery-method-direct-select',
          via: {
            sms: 'app-forms-send-exp-delivery-method-direct-via-sms',
            email: 'app-forms-send-exp-delivery-method-direct-via-email',
          },
        },
        kiosk: {
          select: 'app-forms-send-exp-delivery-method-kiosk-select',
        },
        back: 'app-forms-send-exp-delivery-method-back',
      },
      composeMessage: {
        back: 'app-forms-send-exp-compose-message-back',
      },
      attachments: {
        clickToUpload: 'app-forms-send-exp-attachments-click-to-upload',
      },
      documentSelection: {
        select: {
          packet: 'app-forms-send-exp-document-selection-select-packet',
          form: 'app-forms-send-exp-document-selection-select-form',
        },
        tab: {
          packets: 'app-forms-send-exp-document-selection-tab-packets',
          forms: 'app-forms-send-exp-document-selection-tab-forms',
        },
        next: 'app-forms-send-exp-document-selection-next',
      },
    },
    send: 'app-forms-send-exp-send',
    cancel: 'app-forms-send-exp-cancel',
  },
  sideNav: {
    myForms: 'app-forms-side-nav-my-forms',
    submissions: 'app-forms-side-nav-submissions',
    builder: 'app-forms-side-nav-builder',
    kiosk: 'app-forms-side-nav-kiosk',
    templates: 'app-forms-side-nav-templates',
    requestDigitization: 'app-forms-side-nav-request-digitization',
  },
  formsCloner: {
    confirmCopyToSameLocation: 'app-forms-cloner-confirm-copy-to-same-location',
    closeCopyToSameLocationModal: 'app-forms-cloner-close-copy-to-same-location-modal',
    confirmCloneToOtherAccessibleLocations: 'app-forms-cloner-confirm-clone-to-other-accessible-locations',
    confirmCloneToOtherPrivilegedLocations: 'app-forms-cloner-confirm-clone-to-other-privileged-locations',
    closeCloneToOtherLocationsModal: 'app-forms-cloner-close-clone-to-other-locations-modal',
    selectLocation: 'app-forms-cloner-select-location',
    deselectLocation: 'app-forms-cloner-deselect-location',
    removeLocation: 'app-forms-cloner-remove-location',
  },
  formDigitization: {
    digitizationBase: 'app-form-digitization',
    submitForDigitization: 'app-form-digitization-submit-for-digitization',
    addMoreForms: 'app-form-digitization-add-more-forms',
    goToMyForms: 'app-form-digitization-go-to-my-forms',
    requestDigitization: 'app-form-digitization-request-digitization',
    changeLocation: 'app-form-digitization-change-location',
    pickLocation: 'app-form-digitization-pick-location',
    uploadFiles: 'app-form-digitization-upload-files',
    acceptTerms: 'app-form-digitization-accept-terms',
    cancelTerms: 'app-form-digitization-cancel-terms',
  },
};
