import { theme } from '@frontend/theme';

export const stepValueContainerStyles = {
  display: 'flex',
  rowGap: theme.spacing(2),
};

export const overrideTextStyles = {
  fontWeight: 'normal',
};
