import { ComponentProps } from 'react';
import { useTranslation } from '@frontend/i18n';
import { ColorSetting, Setting } from '.';

type Props = ComponentProps<typeof ColorSetting> & {
  isLast?: boolean;
};

export const FrameSetting = ({ isLast, ...fieldProps }: Props) => {
  const { t } = useTranslation('content-composer-settings');

  return (
    <Setting title={t('Frame')} isLast={isLast}>
      <ColorSetting {...fieldProps} isLast />
    </Setting>
  );
};
