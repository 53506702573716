import { useMemo } from 'react';
import { css } from '@emotion/react';
import dayjs from 'dayjs';
import {
  DashboardWidget,
  DashboardWidgetFC,
  TimePeriodListBoxMenu,
  useDashboardWidget,
  useTimePeriodListBoxMenuState,
} from '@frontend/grid-dashboard';
import { useTranslation } from '@frontend/i18n';
import { LocationFilterButton, useLocationFilter } from '@frontend/location-filter';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { ContentLoader, Text, emptyStateGraphics, styles } from '@frontend/design-system';
import { useFetchAggregatedCallsSummary } from '../../components/phone/hooks';
import { appendTime } from '../../utils';
import { CallResultsPieChart, CallResultsPieChartDataInfo } from './pie-chart';

/**
 * @dashboard-widget
 *
 * id: call-results-widget
 * title: Call Results
 * description: Highlights incoming calls, showing whether they were answered, missed, or abandoned.
 * icon: phone-small
 */
export const CallResultsWidget: DashboardWidgetFC = () => {
  const { t } = useTranslation('analytics');
  const { startDate, endDate, ...timePeriodMenuState } = useTimePeriodListBoxMenuState('week');
  const { getSelectedLocationData } = useAppScopeStore();
  const { widgetTrackingId } = useDashboardWidget();
  const { buttonProps, filteredLocationIds } = useLocationFilter();

  const { data, isLoading } = useFetchAggregatedCallsSummary({
    end_date: appendTime(dayjs(endDate)),
    location_id: filteredLocationIds,
    start_date: appendTime(dayjs(startDate)),
    time_zone: getSelectedLocationData()[filteredLocationIds[0]]?.timezone,
  });

  const chartData = useMemo<CallResultsPieChartDataInfo[]>(() => {
    return [
      {
        key: 'answered',
        label: t('Answered'),
        value: data?.answerRate?.answered || 0,
      },
      {
        key: 'missed',
        label: t('Missed'),
        value: data?.answerRate?.missed || 0,
      },
      {
        key: 'abandoned',
        label: t('Abandoned'),
        value: data?.answerRate?.abandoned || 0,
      },
    ];
  }, [data]);

  return (
    <DashboardWidget>
      <DashboardWidget.Header>
        <div css={{ flexGrow: 1, display: 'flex', flexDirection: 'row-reverse' }}>
          <TimePeriodListBoxMenu {...timePeriodMenuState} readonly />
        </div>
      </DashboardWidget.Header>
      <DashboardWidget.Content css={contentContainerStyle}>
        <ContentLoader show={isLoading} />
        <LocationFilterButton trackingId={widgetTrackingId('location-selector')} {...buttonProps} />
        {!data?.totalCalls?.total && !isLoading ? (
          <div css={[styles.flexCenter, { flexDirection: 'column', gap: theme.spacing(2) }]}>
            {!!filteredLocationIds.length && <emptyStateGraphics.no_calls_made height={120} width={120} />}
            <Text color='light'>
              {!filteredLocationIds.length ? t('No locations selected') : t('No data available')}
            </Text>
          </div>
        ) : (
          <div css={halfWidthStyle}>
            <CallResultsPieChart centerMetricLabel={t('Total Calls')} data={chartData} tooltipLabel={t('Calls')} />
          </div>
        )}
      </DashboardWidget.Content>
    </DashboardWidget>
  );
};

CallResultsWidget.config = {
  size: 'medium-narrow',
  feature: 'phone',
};

const contentContainerStyle = css({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  gap: theme.spacing(1),
  height: '100%',
  minHeight: 0,
});

const halfWidthStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  gap: theme.spacing(1),
  flexDirection: 'column',
  height: '100%',
  width: '100%',
  minHeight: 0,
});
