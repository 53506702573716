import { useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import { PhoneAnalyticsTypes } from '@frontend/api-analytics';
import { BarChartData, Chart } from '@frontend/charts';
import { i18next, useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { useLocations } from '../../../hooks';
import { formatDateByTimezone, getHourlyInsightsLabel, toHHmmss } from '../../../utils';
import { DemoChip } from '../../demo-chip';
import { InfoTipPopover } from '../../info-tip-popover';
import { useFetchAggregatedCallsAndDurationReport, usePhoneAnalyticsShallowStore } from '../hooks';
import { ChartProps } from './shared-types';

const colors = {
  abandoned: theme.colors.warning50,
  answered: theme.colors.secondary.seaweed30,
  missed: theme.colors.critical30,
};

const labels = {
  abandoned: i18next.t('Abandoned', { ns: 'analytics' }),
  answered: i18next.t('Answered', { ns: 'analytics' }),
  missed: i18next.t('Missed', { ns: 'analytics' }),
};

export const AverageIncomingCallDurationChart = ({
  defaultChartAppearance = {},
  exportPdfProps,
  onFetchStateChange,
}: ChartProps) => {
  const { t } = useTranslation('analytics');
  const { locations } = useLocations();
  const { filterHintText, filters, isDemoAccount } = usePhoneAnalyticsShallowStore(
    'filterHintText',
    'filters',
    'isDemoAccount'
  );
  const { data, isHourlyData, isLoading, isMultiLocation } =
    useFetchAggregatedCallsAndDurationReport<PhoneAnalyticsTypes.IncomingCallsAggregationKey>('incoming_call_status');

  const chartData = useMemo(() => {
    let groups: BarChartData['groups'] = [];

    if (isMultiLocation) {
      // There will be multiple location objects with single/multiple dates
      groups = Object.entries(data?.aggregatedMultiLocations || {}).map(([key, { calls, duration }]) => {
        const abandonedCalls = calls?.abandoned || 0;
        const abandonedDuration = duration?.abandoned || 0;
        const answeredCalls = calls?.answered || 0;
        const answeredDuration = duration?.answered || 0;
        const missedCalls = calls?.missed || 0;
        const missedDuration = duration?.missed || 0;

        return {
          name: locations?.[key] || key,
          values: {
            abandoned: abandonedDuration / (abandonedCalls || 1),
            answered: answeredDuration / (answeredCalls || 1),
            missed: missedDuration / (missedCalls || 1),
          },
        };
      });
    } else {
      // For hourly data: There will be only one object with single date and hourly data for the given location
      // For daily data: There will be only one object with multiple dates for the given location
      const locationData = Object.values(data?.locations || {})[0] || {};

      if (isHourlyData) {
        // Hourly data is restricted to a single date, hence only one object
        const { abandoned, answered, missed } = Object.values(locationData || {})[0] || {};
        const limitedAbandonedCalls = abandoned?.calls?.hourly || [];
        const limitedAbandonedDuration = abandoned?.duration?.hourly || [];
        const limitedAnsweredCalls = answered?.calls?.hourly || [];
        const limitedAnsweredDuration = answered?.duration?.hourly || [];
        const limitedMissedCalls = missed?.calls?.hourly || [];
        const limitedMissedDuration = missed?.duration?.hourly || [];

        groups = limitedAbandonedCalls.map((abandonedCalls, i) => ({
          name: getHourlyInsightsLabel(i),
          values: {
            abandoned: limitedAbandonedDuration[i] / (abandonedCalls || 1),
            answered: limitedAnsweredDuration[i] / (limitedAnsweredCalls[i] || 1),
            missed: limitedMissedDuration[i] / (limitedMissedCalls[i] || 1),
          },
        }));
      } else {
        groups = Object.entries(locationData).map(([date, { abandoned, answered, missed }]) => {
          const abandonedCalls = abandoned?.calls?.daily || 0;
          const abandonedDuration = abandoned?.duration?.daily || 0;
          const answeredCalls = answered?.calls?.daily || 0;
          const answeredDuration = answered?.duration?.daily || 0;
          const missedCalls = missed?.calls?.daily || 0;
          const missedDuration = missed?.duration?.daily || 0;

          return {
            name: dayjs(date).isValid() ? formatDateByTimezone(date, filters.start_date, filters.time_zone) : date,
            values: {
              abandoned: abandonedDuration / (abandonedCalls || 1),
              answered: answeredDuration / (answeredCalls || 1),
              missed: missedDuration / (missedCalls || 1),
            },
          };
        });
      }
    }

    return {
      groups,
    };
  }, [data, filters, isHourlyData, isMultiLocation, locations]);

  useEffect(() => {
    onFetchStateChange?.(isLoading);
  }, [isLoading]);

  return (
    <Chart colors={colors} isLoading={isLoading} labels={labels}>
      <Chart.Header
        {...exportPdfProps}
        infoTip={
          <InfoTipPopover>
            {t(
              'Incoming: Refers to the average time on Answered, Missed and Abandoned calls. It only includes Incoming calls. Value is in hh:mm:ss'
            )}
          </InfoTipPopover>
        }
        leftElement={isDemoAccount && <DemoChip />}
        subtitle={filterHintText}
        title={t('Average Call Duration - Incoming')}
      />
      <Chart.Legends />
      <Chart.BarChart appearance={defaultChartAppearance} data={chartData} formatValue={(value) => toHHmmss(value)} />
    </Chart>
  );
};
