import { useState } from 'react';
import { PaymentMethod } from '@frontend/api-payment-plans';
import { useTranslation } from '@frontend/i18n';
import { CardACHSelection } from '@frontend/payments-card-on-file';
import { PaymentsFlowType, StripeElementsWrapper } from '@frontend/payments-stripe-wrapper';
import { Modal } from '@frontend/design-system';

export const BillingMethodModal = ({
  patientId,
  patientEmail,
  handleSetBillingMethod,
  onClose,
}: {
  patientId?: string;
  patientEmail: string;
  handleSetBillingMethod: (pm: PaymentMethod) => void;
  onClose: () => void;
}) => {
  const { t } = useTranslation('payments');
  const [selectedPM, setSelectedPM] = useState<PaymentMethod>();

  return (
    <StripeElementsWrapper type={PaymentsFlowType.AddCardOnFile}>
      <CardACHSelection
        patientId={patientId}
        patientEmail={patientEmail || ''}
        selectedPM={selectedPM}
        onChangePM={setSelectedPM}
        paymentAmount={0}
        minACHAmount={0}
        addACHContent={<CardACHSelection.AddACHForm />}
        addCardContent={<CardACHSelection.AddCardForm />}
      >
        <Modal.Header textAlign='left'>{t('Manage Payment Methods')}</Modal.Header>
        <CardACHSelection.Content />
        <CardACHSelection.Action
          onCancel={onClose}
          primaryLabel={t('Save')}
          onPrimaryClick={() => selectedPM && handleSetBillingMethod(selectedPM)}
        />
      </CardACHSelection>
    </StripeElementsWrapper>
  );
};
