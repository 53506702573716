export const gradient = (id: string, startColor?: string, endColor?: string, degrees?: number) => {
  // Convert degrees to radians
  const radians = ((degrees || 0) * Math.PI) / 180;

  // Calculate x1, y1, x2, y2 based on the angle
  const x1 = Math.round(50 + 50 * Math.cos(radians - Math.PI / 2));
  const y1 = Math.round(50 + 50 * Math.sin(radians - Math.PI / 2));
  const x2 = Math.round(50 + 50 * Math.cos(radians + Math.PI / 2));
  const y2 = Math.round(50 + 50 * Math.sin(radians + Math.PI / 2));

  return (
    <defs>
      <linearGradient id={id} x1={`${x1}%`} y1={`${y1}%`} x2={`${x2}%`} y2={`${y2}%`}>
        <stop offset='0%' stopColor={startColor} />
        <stop offset='100%' stopColor={endColor} />
      </linearGradient>
    </defs>
  );
};
