import { useState } from 'react';
import { CreateEmailResponse } from '@weave/schema-gen-ts/dist/schemas/wgpt/v1/wgpt_service.pb';
import { EmailAssistantQueries } from '@frontend/api-email-assistant';
import { http } from '@frontend/fetch';
import { useTranslation } from '@frontend/i18n';
import { sentry } from '@frontend/tracking';
import { useAlert } from '@frontend/design-system';
import { SectionTypes } from '../types';

const createContext = (email: SectionTypes, includeEmail = true, prompt?: string) => {
  const subject = email.subject;
  const body = email.body.map((section) => section.value);
  const context = includeEmail ? [subject, ...body] : [];
  if (prompt) {
    context.unshift(prompt);
  }
  return context;
};

export const useEmailAssistant = (email: SectionTypes) => {
  const { t } = useTranslation('email-composer');
  const alert = useAlert();

  const [isContentLoading, setIsContentLoading] = useState(false);
  const [generatedSubjects, setGeneratedSubjects] = useState<string[]>([]);
  const [generatedBodies, setGeneratedBodies] = useState<CreateEmailResponse[]>(
    email.body ? [{ response: email.body.map((section) => section.value).join(' ') }] : []
  );
  const [bodyPointer, setBodyPointer] = useState(0);
  const currentBody = generatedBodies[bodyPointer]?.response;
  const canUndoBodyGeneration = bodyPointer > 1;
  const canRedoBodyGeneration = bodyPointer < generatedBodies.length - 1;

  const createSubjects = EmailAssistantQueries.useAssistantCreateSubjects();
  const createEmail = EmailAssistantQueries.useAssistantCreateEmail();
  const refine = EmailAssistantQueries.useAssistantRefineEmail();
  const { data: refinements, isLoading: isRefinementsLoading } = EmailAssistantQueries.useAssistantGetRefinements();

  const handleServiceError = (error: any) => {
    if (http.isHttpError(error)) {
      if (error.status === 408) {
        alert.warning(t('Our service is busy for the moment. Please try again.'));
      } else {
        alert.error(t('An error occurred while generating content for your email.'));
        console.error(error);
      }
    } else {
      alert.error(t('An error occurred while generating content for your email.'));
      sentry.error({
        topic: 'messages',
        severityLevel: 'error',
        error: error,
        addContext: {
          name: 'Weave Assistant in Email Composer',
          context: {
            errMessage: 'Error generating AI content for email',
          },
        },
      });
    }
  };

  const addGeneratedBody = (body: CreateEmailResponse) => {
    setGeneratedBodies((prev) => {
      const updatedBodies = prev.slice(0, bodyPointer + 1);
      updatedBodies.push(body);
      setBodyPointer(updatedBodies.length - 1);
      return updatedBodies;
    });
  };

  const generateEmail = async (prompt: string, includeEmail = true) => {
    setIsContentLoading(true);
    try {
      const [subjectsResponse, bodyResponse] = await Promise.all([
        createSubjects(createContext(email, includeEmail, prompt)),
        createEmail(createContext(email, includeEmail), prompt),
      ]);
      setGeneratedSubjects((prev) => [...prev, ...(subjectsResponse?.subjects ?? [])]);
      addGeneratedBody(bodyResponse);
    } catch (error) {
      handleServiceError(error);
    }
    setIsContentLoading(false);
  };

  const generateSubjects = async (prompt?: string, includeEmail = true) => {
    setIsContentLoading(true);
    try {
      const response = await createSubjects(createContext(email, includeEmail, prompt));
      setGeneratedSubjects((prev) => [...prev, ...(response?.subjects ?? [])]);
    } catch (error) {
      handleServiceError(error);
    }
    setIsContentLoading(false);
  };

  const generateBody = async (prompt = '', includeEmail = true) => {
    setIsContentLoading(true);
    try {
      const response = await createEmail(createContext(email, includeEmail), prompt);
      addGeneratedBody(response);
    } catch (error) {
      handleServiceError(error);
    }
    setIsContentLoading(false);
  };

  const refineBody = async (refinement: string) => {
    const text = currentBody ? currentBody : email.body.map((section) => section.value).join(' ');
    if (!text) return;

    setIsContentLoading(true);
    try {
      const response = await refine(text, refinement);
      addGeneratedBody({ response: response.text });
    } catch (error) {
      handleServiceError(error);
    }
    setIsContentLoading(false);
  };

  const undoBodyGeneration = () => {
    if (canUndoBodyGeneration) {
      setBodyPointer((prev) => prev - 1);
    }
  };

  const redoBodyGeneration = () => {
    if (canRedoBodyGeneration) {
      setBodyPointer((prev) => prev + 1);
    }
  };

  const reset = () => {
    setGeneratedSubjects([]);
    setGeneratedBodies([]);
    setBodyPointer(0);
  };

  return {
    generateEmail,
    generateSubjects,
    generateBody,
    subjects: generatedSubjects,
    body: currentBody,
    bodyRefinements: refinements?.refinements,
    refineBody,
    undoBodyGeneration,
    redoBodyGeneration,
    canUndoBodyGeneration,
    canRedoBodyGeneration,
    isLoading: isContentLoading || isRefinementsLoading,
    reset,
  };
};
