import { ComponentProps } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { RadioField, Text } from '@frontend/design-system';
import {
  circleStyling,
  dropdownSectionTypeStyling,
  getRadioOptionStyling,
  labelStyling,
  overridingRadioGroupStyling,
  roundedStyling,
  squareStyling,
} from '../../styles';
import { Corners } from '../../types';

type Props = ComponentProps<typeof RadioField> & {
  isLast?: boolean;
  value: Corners;
};

export const CornersSetting = ({ isLast, ...fieldProps }: Props) => {
  const { t } = useTranslation('content-composer-settings');

  return (
    <div
      css={[
        dropdownSectionTypeStyling,
        !isLast &&
          css`
            margin-bottom: ${theme.spacing(2)};
          `,
      ]}
    >
      <Text css={labelStyling}>{t('Corners')}</Text>
      <RadioField {...fieldProps} css={overridingRadioGroupStyling}>
        <RadioField.Radio
          value='square'
          className='hide-radio'
          css={getRadioOptionStyling(fieldProps.value === 'square')}
        >
          <div css={squareStyling}></div>
        </RadioField.Radio>
        <RadioField.Radio
          value='rounded'
          className='hide-radio'
          css={getRadioOptionStyling(fieldProps.value === 'rounded')}
        >
          <div css={roundedStyling}></div>
        </RadioField.Radio>
        <RadioField.Radio value='pill' className='hide-radio' css={getRadioOptionStyling(fieldProps.value === 'pill')}>
          <div css={circleStyling}></div>
        </RadioField.Radio>
      </RadioField>
    </div>
  );
};
