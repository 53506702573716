import { useEffect, useState, createContext, useContext } from 'react';
import { css } from '@emotion/react';
import { ErrorBoundary } from '@sentry/react';
import { QueryClient } from 'react-query';
import { getUser } from '@frontend/auth-helpers';
import { defaultMissingModuleFallback, defaultModuleFetchErrorFallback } from '@frontend/file-based-routing';
import { theme } from '@frontend/theme';
import { Heading, Text } from '@frontend/design-system';
import { locationRedirect, LOCATION_REDIRECT_STORAGE_KEY } from '../../utils/location-redirect';
import { getWeaveRoutes } from '../../utils/routes/routes';
import {
  transFormAndExtractLocationIdFromPortalRoute,
  getNewRouteFromLegacyPortalRoute,
} from './legacy-portal-redirects';
import { getMurderThePortalRedirects } from './portal-redirects';

type ContextValue = {
  routes: ReturnType<typeof getWeaveRoutes>;
  refresh: () => void;
};

const RoutesContext = createContext<ContextValue>({
  routes: [],
  refresh: () => {},
});

export const useRoutes = () => useContext(RoutesContext);

type Props = {
  children: React.ReactNode;
  queryClient?: QueryClient;
};
export const RoutesProvider = ({ children, queryClient }: Props) => {
  const [routes, setRoutes] = useState<ReturnType<typeof getWeaveRoutes>>(() => {
    return getWeaveRoutes({
      queryClient,
      missingModuleFallback: defaultMissingModuleFallback,
      moduleFetchErrorFallback: defaultModuleFetchErrorFallback,
    });
  });

  //this whole function is to help support the migration from the old portal to the new portal.
  //after a few months of the new portal being released, we should remove this
  const interceptLegacyRoutes = (path: string) => {
    const { locationId, transformedRoute } = transFormAndExtractLocationIdFromPortalRoute(path);
    if (locationId) {
      localStorage.setItem(LOCATION_REDIRECT_STORAGE_KEY, locationId);
    }

    // Set up location for when user has already been logged in (not going through sign in callback route)
    locationRedirect(getUser());

    const legacyRedirect = transformedRoute ? getNewRouteFromLegacyPortalRoute(transformedRoute) : null;

    //TODO: turn this to true once all locations have the murder-the-portal feature flag...
    // and turn it off in useMurderThePortalRedirect in app.tsx
    // the redirect will be much faster and easier here because we don't have to wait for authentication and fetching of the flags
    const hasMurderThePortalFlag = false;
    const murderThePortalRedirect = hasMurderThePortalFlag ? getMurderThePortalRedirects(legacyRedirect ?? path) : null;

    const finalRedirect = murderThePortalRedirect || legacyRedirect;

    if (finalRedirect) {
      const nextUrl = new URL(window.location.href);
      nextUrl.pathname = finalRedirect;
      nextUrl.searchParams.delete('forwardPath');
      console.info(`Hit Legacy Path: ${path}. Navigating to ${nextUrl.href} to handle Legacy Path`, nextUrl);
      window.location.href = nextUrl.href;
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (!location.pathname) {
      return;
    }

    if (!routes) {
      return;
    }
    const intercepted = interceptLegacyRoutes(location.pathname);
    if (intercepted) {
      return;
    }
  }, [location.pathname, routes]);

  const value: ContextValue = {
    routes,
    refresh: () =>
      setRoutes(
        getWeaveRoutes({
          queryClient,
          missingModuleFallback: defaultMissingModuleFallback,
          moduleFetchErrorFallback: defaultModuleFetchErrorFallback,
        })
      ),
  };
  return (
    <RoutesContext.Provider value={value}>
      <ErrorBoundary
        beforeCapture={(scope) => {
          scope.setTag('topic', 'routeError');
          scope.setLevel('fatal');
        }}
        fallback={(props) => <RouteError {...props} />}
      >
        {children}
      </ErrorBoundary>
    </RoutesContext.Provider>
  );
};

const RouteError = ({ error }: { error: Error | unknown }) => {
  return (
    <main
      css={css`
        max-width: 100%;
        padding: ${theme.spacing(1)};
      `}
    >
      <Heading
        css={css`
          margin-bottom: ${theme.spacing(1)};
        `}
      >
        Error Loading Route
      </Heading>
      {error instanceof Error && (
        <>
          <Text>{error.message}</Text>
          <br />
          <Text
            css={css`
              padding: ${theme.spacing(1)};
              background: ${theme.colors.neutral10};
              border-radius: ${theme.borderRadius.small};
            `}
          >
            {error.stack}
          </Text>
        </>
      )}
    </main>
  );
};
