import { forwardRef, memo, useEffect, useImperativeHandle } from 'react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { FormRow, Text, TextField, CheckboxField, useForm, FormFieldActionTypes } from '@frontend/design-system';
import { AddressForm } from './address-form';
import { defaultAddressInfo, defaultProviderInfo } from './constants';
import { convertFormValuesToAddressInfo, isAlphaNumericValue, validateAccountPin, validateUserName } from './helpers';
import { useAddressForm } from './hooks';
import { AddressInfo, FormRefType, HandleSubmitFnType, PhoneProviderAndAddressInfo, PhoneProviderInfo } from './types';

interface Props {
  debug?: boolean;
  initialAddressInfo?: AddressInfo;
  initialPhoneProviderInfo?: PhoneProviderInfo;
  isInsideIntakeForm?: boolean;
  setIsFormValid: (isValid: boolean) => void;
  onFormChange?: (values: PhoneProviderAndAddressInfo) => void;
}

const PhoneProviderInformationFormComponent = forwardRef<FormRefType, Props>(
  (
    {
      initialAddressInfo = defaultAddressInfo,
      initialPhoneProviderInfo = defaultProviderInfo,
      isInsideIntakeForm,
      debug,
      setIsFormValid,
      onFormChange,
    },
    ref
  ) => {
    const { t } = useTranslation('phoneProviderInformationForm');
    const { verified: initialIsVerified, ...initialAddress } = initialAddressInfo;

    const {
      addressFormProps,
      isValid: isAddressValid,
      values: addressValues,
      isVerified: isAddressVerified,
      validate: validateAddress,
    } = useAddressForm({ initialAddress, initialIsVerified });

    const {
      formProps: providerFormProps,
      getFieldProps: getProviderFormFieldProps,
      isComplete: isProviderFormComplete,
      seedValues: seedProviderFormValues,
      values: providerFormValues,
      validate: validateProviderForm,
    } = useForm({
      fields: {
        businessName: { type: 'text', required: !debug },
        name: { type: 'text', required: !debug },
        accountPin: {
          type: 'text',
          validator: validateAccountPin,
          required: false,
        },
        accountNumber: { type: 'text', required: !debug },
        authorizedUser: {
          type: 'text',
          validator: validateUserName,
          required: !debug,
        },
        providerInfoCorrect: { type: 'checkbox', required: isInsideIntakeForm && !debug },
        officeEmail: { type: 'email', required: !debug },
      },
      fieldStateReducer: (state, action) => {
        if (action.type === FormFieldActionTypes.Blur) {
          switch (action.payload.name) {
            case 'providerAccountNumber': {
              if (typeof state.accountNumber.value === 'string') {
                if (!isAlphaNumericValue(state.accountNumber.value)) {
                  return {
                    ...state,
                    providerAccountNumber: {
                      ...state.accountNumber,
                      value: state.accountNumber.value.replace(/[^a-zA-Z0-9]/g, ''),
                    },
                  };
                }
              }
            }
          }
        }
        return null;
      },
    });

    useEffect(() => {
      seedProviderFormValues(initialPhoneProviderInfo);
    }, []);

    useEffect(() => onFormChange?.(getFormValues()), [addressValues, providerFormValues]);

    useEffect(() => {
      setIsFormValid(isProviderFormComplete && addressFormProps.isComplete);
    }, [isProviderFormComplete, addressFormProps.isComplete]);

    const getFormValues = () => {
      const addressInfo = convertFormValuesToAddressInfo(addressValues, isAddressVerified);
      const providerInfo: PhoneProviderInfo = {
        businessName: providerFormValues.businessName ?? '',
        name: providerFormValues.name ?? '',
        officeEmail: providerFormValues.officeEmail ?? '',
        accountPin: providerFormValues.accountPin ?? '',
        accountNumber: providerFormValues.accountNumber ?? '',
        authorizedUser: providerFormValues.authorizedUser ?? '',
        infoCorrect: providerFormValues.providerInfoCorrect ?? false,
      };
      return { addressInfo, providerInfo };
    };
    const handleSubmit: HandleSubmitFnType = () => {
      if (debug || (isProviderFormComplete && isAddressValid)) {
        return getFormValues();
      } else {
        validateAddress();
        validateProviderForm();
        return;
      }
    };

    useImperativeHandle<FormRefType, FormRefType>(ref, () => ({ handleSubmit }));

    return (
      <form css={{ width: '100%' }} {...providerFormProps}>
        <Text weight='semibold' css={{ marginBottom: theme.spacing(2) }}>
          {t('Business Information')}
        </Text>
        <FormRow>
          <TextField {...getProviderFormFieldProps('businessName')} label={t('Business Name')} />
        </FormRow>
        <FormRow>
          <TextField {...getProviderFormFieldProps('officeEmail')} label={t('Office Contact Email')} />
        </FormRow>
        <FormRow>
          <TextField {...getProviderFormFieldProps('accountPin')} label={t('PIN')} />
        </FormRow>
        <Text weight='semibold' css={{ marginBottom: theme.spacing(2) }}>
          {t('Phone Provider Information')}
        </Text>
        <FormRow>
          <TextField {...getProviderFormFieldProps('name')} label={t('Current Phone Provider')} />
        </FormRow>
        <FormRow>
          <TextField {...getProviderFormFieldProps('accountNumber')} label={t('Account Number')} />
        </FormRow>
        <FormRow css={{ marginBottom: theme.spacing(3) }}>
          <TextField
            {...getProviderFormFieldProps('authorizedUser')}
            label={t('Authorized User First and Last Name')}
          />
        </FormRow>
        <AddressForm
          {...addressFormProps}
          addressTitle={t('Service Address')}
          addressLine1Label={t('Service Address')}
        />
        {isInsideIntakeForm && (
          <>
            <Text weight='semibold' css={{ marginBottom: theme.spacing(2) }}>
              {t('Please add your Point of Contact Email')}
            </Text>
            <FormRow>
              <TextField {...getProviderFormFieldProps('officeEmail')} label={t('Office Contact Email')} />
            </FormRow>
            <FormRow>
              <CheckboxField
                {...getProviderFormFieldProps('providerInfoCorrect')}
                label={t('This information is correct')}
              />
            </FormRow>
          </>
        )}
      </form>
    );
  }
);

PhoneProviderInformationFormComponent.displayName = 'PhoneProviderInformationFormComponent';

export const PhoneProviderInformationForm = memo(PhoneProviderInformationFormComponent);
