import { useMemo } from 'react';
import { css } from '@emotion/react';
import { InsuranceDetailQueries } from '@frontend/api-insurance-detail';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { WivPromotionCard, useWivTeaserHandler } from '@frontend/self-upgrade';
import { theme } from '@frontend/theme';
import { Chip, NakedButton, SpinningLoader } from '@frontend/design-system';
import { INSURANCE_STATUS_MAPPING } from './constants';

interface Props {
  personId: string;
  locationId: string;
  onClick: () => void;
  handleVerifyStatusClick?: () => void;
  isLoading?: boolean;
}

export const InsuranceStatusChipButton = ({
  personId,
  locationId,
  onClick,
  handleVerifyStatusClick,
  isLoading = false,
}: Props) => {
  const { t } = useTranslation('scheduleCalendarEvents');

  const {
    isLoading: isLoadingWivEligibility,
    showWeaveVerify,
    showWeaveVerifyPromotion,
    handleWivUpgradeSuccess,
  } = useWivTeaserHandler({ locationIds: [locationId] });

  const { data: verificationDetails, isLoading: isLoadingVerificationDetails } =
    InsuranceDetailQueries.useGetInsuranceVerificationDetailsPersonId(personId, locationId, {
      disabled: !showWeaveVerify,
    });

  const statusInfo = useMemo(() => {
    const status = verificationDetails?.coverage?.thirdPartyCoverage?.coverage?.status;
    const overriddenStatus = verificationDetails?.coverage?.overriddenCoverage?.coverage;

    return overriddenStatus
      ? INSURANCE_STATUS_MAPPING.get(overriddenStatus.toLowerCase().split('_').join('-'))
      : status
      ? INSURANCE_STATUS_MAPPING.get(status.toLowerCase())
      : undefined;
  }, [verificationDetails?.coverage]);

  if (isLoading || isLoadingVerificationDetails || isLoadingWivEligibility) return <SpinningLoader size='xs' />;

  if (!showWeaveVerify && !showWeaveVerifyPromotion) return null;

  if (!statusInfo) {
    return (
      <div css={showWeaveVerifyPromotion && wivPromotionWrapperStyles}>
        <NakedButton
          css={buttonStyle}
          disabled={!showWeaveVerify}
          onClick={handleVerifyStatusClick ? handleVerifyStatusClick : onClick}
        >
          <Icon name='shield-small' css={{ color: 'currentcolor' }} />
          {t('Verify')}
        </NakedButton>
        {showWeaveVerifyPromotion && (
          <WivPromotionCard
            source='appointments'
            locationIds={[locationId]}
            cardStyles={wivPromotionCardStyles}
            onUpgradeSuccess={handleWivUpgradeSuccess}
          />
        )}
      </div>
    );
  }

  return (
    <NakedButton onClick={onClick}>
      <Chip
        variant={statusInfo.variant}
        css={{ width: 'auto' }}
        leftElement={<Icon name={statusInfo.iconName} css={{ color: 'currentcolor' }} />}
      >
        {statusInfo.text}
      </Chip>
    </NakedButton>
  );
};

const buttonStyle = css({
  display: 'inline-flex',
  alignItems: 'center',
  gap: theme.spacing(0.5),
  fontWeight: theme.font.weight.bold,
  fontSize: theme.fontSize(14),
  color: theme.colors.primary50,
  [':disabled']: {
    opacity: 0.5,
  },
});

const wivPromotionWrapperStyles = css({
  position: 'relative',
  ['div:last-child']: {
    opacity: 0,
    pointerEvents: 'none',
  },
  [':hover div:last-child']: {
    opacity: 1,
    zIndex: 1,
    pointerEvents: 'auto',
  },
});

const wivPromotionCardStyles = css({
  position: 'absolute',
  left: 0,
  top: '100%',
  width: '300px',
  boxShadow: theme.shadows.floating,
  backgroundColor: theme.colors.white,
  borderRadius: theme.borderRadius.small,
  padding: theme.spacing(2),
  transition: 'opacity 0.25s ease-in-out',
});
