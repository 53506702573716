import { useInfiniteQuery } from 'react-query';
import { SchedulerV3 } from '@frontend/api-schedule-v3';
import { SchedulerV3API, SchedulerV3QueryKeys, SchedulerV3Types } from '@frontend/api-scheduler-v3';
import { useLocalizedQuery } from '@frontend/location-helpers';
import { useSchedulingLocationInfo } from '../use-scheduling-location-info';

type UseGetAppointmentTypesV3DataParamsType = {
  selectedLocationId: string;
  selectedLocationIds?: string[];
  debouncedSearchValue?: string;
  pageConfig?: SchedulerV3Types.PageConfig;
  isEnabled?: boolean;
};

export const useGetAppointmentTypesV3Data = ({
  selectedLocationId,
  debouncedSearchValue,
  selectedLocationIds,
  pageConfig,
  isEnabled = true,
}: UseGetAppointmentTypesV3DataParamsType) => {
  const { isMultiLocation, parentLocationId } = useSchedulingLocationInfo();
  const isMultiLocationApiCall = !!parentLocationId && isMultiLocation;

  const {
    data: appointmentTypesSingleLocationData,
    isLoading: isLoadingAppointmentTypesData,
    isFetching: isFetchingAppointmentTypesData,
    refetch: refetchAppointmentTypes,
  } = useLocalizedQuery({
    queryKey: SchedulerV3QueryKeys.AppointmentTypesQueryKeys.getAppointmentTypes(
      selectedLocationId,
      pageConfig?.page.toString(),
      pageConfig?.limit.toString(),
      debouncedSearchValue
    ),

    queryFn: () =>
      SchedulerV3API.getAppointmentTypes({
        ...(pageConfig?.limit && { limit: pageConfig?.limit }),
        ...(pageConfig?.page && { page: pageConfig?.page }),
        ...(debouncedSearchValue && { name: debouncedSearchValue }),
        locationId: selectedLocationId,
      }),
    enabled: !isMultiLocationApiCall && isEnabled,
  });

  const {
    data: appointmentTypesMultiData,
    isLoading: isLoadingAppointmentTypesMultiData,
    isFetching: isFetchingAppointmentTypesMultiData,
    refetch: refetchAppointmentMultiTypes,
  } = useLocalizedQuery({
    queryKey: SchedulerV3QueryKeys.AppointmentTypesQueryKeys.getAppointmentTypesMultiLocation(
      selectedLocationIds || [],
      parentLocationId || '',
      pageConfig?.page.toString(),
      pageConfig?.limit.toString(),
      debouncedSearchValue
    ),

    queryFn: () =>
      SchedulerV3API.getAppointmentTypesForMultiLocation({
        ...(pageConfig?.limit && { limit: pageConfig?.limit }),
        ...(pageConfig?.page && { page: pageConfig?.page }),
        ...(debouncedSearchValue && { name: debouncedSearchValue }),
        locationsIds: selectedLocationIds || [],
        parentLocationId: parentLocationId || '',
      }),
    enabled: isMultiLocationApiCall && !!selectedLocationIds?.length && isEnabled,
  });

  if (isMultiLocationApiCall) {
    return {
      data: appointmentTypesMultiData,
      isLoading: isLoadingAppointmentTypesMultiData,
      isFetching: isFetchingAppointmentTypesMultiData,
      refetch: refetchAppointmentMultiTypes,
    };
  }

  return {
    data: appointmentTypesSingleLocationData,
    isLoading: isLoadingAppointmentTypesData,
    isFetching: isFetchingAppointmentTypesData,
    refetch: refetchAppointmentTypes,
  };
};

export const useGetAppointmentTypeInfiniteListForV3 = ({
  locationId,
  pageLimit = 50,
  searchText,
}: {
  locationId: string;
  pageLimit?: number;
  searchText?: string;
}) => {
  const { isMultiLocation, parentLocationId } = useSchedulingLocationInfo();

  const requestForSingleLocation: SchedulerV3Types.ListAppointmentTypesApiType['input'] = {
    locationId: locationId,
    limit: pageLimit,
    name: searchText,
  };

  const singleLocationAppointmentTypeQuery = useInfiniteQuery({
    queryKey: SchedulerV3QueryKeys.AppointmentTypesQueryKeys.getAppointmentTypeInfiniteList(requestForSingleLocation),
    queryFn: async ({ pageParam = 1 }) => {
      const req = { ...requestForSingleLocation, page: pageParam };
      const res = await SchedulerV3API.getAppointmentTypes(req);
      return {
        // filter out active appointments
        rows: (res.appointmentTypes ?? []).filter((appointmentType) => !!appointmentType.details?.act),
        totalCount: res.totalCount ?? 0,
        page: req.page,
        totalPages: Math.ceil((res.totalCount ?? 0) / pageLimit),
      };
    },
    enabled: !isMultiLocation && !!requestForSingleLocation.locationId,
    getNextPageParam: (lastPage) => (lastPage.page < lastPage.totalPages ? lastPage.page + 1 : undefined),
    getPreviousPageParam: (firstPage) => (firstPage.page > 1 ? firstPage.page - 1 : undefined),
  });

  const requestForMultiLocation: SchedulerV3Types.ListAppointmentTypesMultiLocationApiType['input'] = {
    locationsIds: [locationId],
    parentLocationId: parentLocationId,
    limit: pageLimit,
    name: searchText,
  };

  const multiLocationAppointmentTypeQuery = useInfiniteQuery({
    queryKey:
      SchedulerV3QueryKeys.AppointmentTypesQueryKeys.getAppointmentTypesInfiniteListForMultiLocation(
        requestForMultiLocation
      ),
    queryFn: async ({ pageParam = 1 }) => {
      const req = { ...requestForMultiLocation, page: pageParam };
      const res = await SchedulerV3API.getAppointmentTypesForMultiLocation(req);
      return {
        // filter out active appointments
        rows: (res.appointmentTypes ?? []).filter((appointmentType) => !!appointmentType.details?.act),
        totalCount: res.totalCount ?? 0,
        page: req.page,
        totalPages: Math.ceil((res.totalCount ?? 0) / pageLimit),
      };
    },
    enabled: isMultiLocation && !!requestForMultiLocation.locationsIds.length,
    getNextPageParam: (lastPage) => (lastPage.page < lastPage.totalPages ? lastPage.page + 1 : undefined),
    getPreviousPageParam: (firstPage) => (firstPage.page > 1 ? firstPage.page - 1 : undefined),
  });

  return isMultiLocation ? multiLocationAppointmentTypeQuery : singleLocationAppointmentTypeQuery;
};

export const useGetPractitionerInfiniteListForV3 = ({
  locationId,
  pageLimit = 50,
  searchText,
}: {
  locationId: string;
  pageLimit?: number;
  searchText?: string;
}) => {
  const { isMultiLocation, parentLocationId } = useSchedulingLocationInfo();

  const singleLocationProvidersQuery = SchedulerV3.Queries.useListProvidersInfiniteQuery({
    request: {
      locationId: locationId,
      limit: pageLimit,
      name: searchText,
    },
    options: {
      enabled: !isMultiLocation && !!locationId,
      select: (data) => {
        return {
          ...data,
          pages: data.pages.map((page) => ({
            totalCount: page.totalCount,
            rows: page.providers,
          })),
        };
      },
    },
  });

  const multiLocationProvidersQuery = SchedulerV3.Queries.useListProvidersMultiInfiniteQuery({
    request: {
      locationIds: [locationId],
      parentLocationId: parentLocationId,
      limit: pageLimit,
      name: searchText,
    },
    options: {
      enabled: !isMultiLocation && !!locationId && !!parentLocationId,
      select: (data) => {
        return {
          ...data,
          pages: data.pages.map((page) => ({
            totalCount: page.totalCount,
            rows: page.providers,
          })),
        };
      },
    },
  });

  return isMultiLocation ? multiLocationProvidersQuery : singleLocationProvidersQuery;
};
