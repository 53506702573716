import { FC } from 'react';
import { css } from '@emotion/react';
import { FormsDigitization } from '@frontend/api-forms';
import appConfig from '@frontend/env';
import { useFetch } from '@frontend/fetch';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { CheckboxField, Modal, Text, useModalLoadingState, useForm, useAlert, Button } from '@frontend/design-system';
import { pendoTags } from '../shared/constants';

interface Props {
  locationId: string;
  onClose: (formsSubmitted?: boolean) => void;
}

const FormsSubmissionConfirmation: FC<React.PropsWithChildren<Props>> = ({ locationId, onClose }) => {
  const alert = useAlert();
  useFetch({ baseUrl: appConfig.BACKEND_API });
  const { setLoading } = useModalLoadingState();
  const { formProps, getFieldProps, isComplete } = useForm({
    fields: {
      condition: { type: 'checkbox', required: true },
    },
  });
  const { t } = useTranslation('forms');

  const onFinishFormsUploadConfirmed = async () => {
    try {
      setLoading(true, t('modal submitting text'));
      const formUploadResponse = await FormsDigitization.API.finishFormsUpload(locationId);

      if (formUploadResponse.success) {
        onClose(true);
      } else {
        alert.error(t('forms upload failed'));
      }
    } catch (error: any) {
      alert.error(t('something wrong'));
    }

    setLoading(false);
  };

  return (
    <>
      <Modal.Header>
        {t('Confirm Forms Submission')}
        <Text color='subdued' size='small' weight='regular'>
          {t(
            "Once submitted, your forms will be digitized by our team and you won't be able to upload any additional forms."
          )}
        </Text>
      </Modal.Header>
      <Modal.Body>
        <Text>{t('Please read the following points carefully before proceeding to submit.')}</Text>

        <ul>
          <Text
            as='li'
            css={css`
              margin-bottom: ${theme.spacing(2)};
            `}
          >
            {t(
              'I understand that Weave will only accept this one-time upload of forms for digitization. Once my forms are submitted, I cannot submit additional forms to be digitized by Weave.'
            )}
          </Text>
          <Text
            as='li'
            css={css`
              margin-bottom: ${theme.spacing(2)};
            `}
          >
            {t(
              'I understand that Weave will digitize each of my forms separately. I upload a packet of forms, I agree that the forms will be built as individual forms and not as a packet.'
            )}
          </Text>
          <Text
            as='li'
            css={css`
              margin-bottom: ${theme.spacing(2)};
            `}
          >
            {t(
              'I understand that Weave will only accept forms in Adobe Acrobat (PDF), Microsoft Word (DOCX), or Google Docs format. I understand that Weave may reject any form that includes embedded images (including, but not limited to, JPEG, PNG, GIF) or text that has restricted editing capabilities.'
            )}
          </Text>
          <Text
            as='li'
            css={css`
              margin-bottom: ${theme.spacing(2)};
            `}
          >
            {t(
              'I agree that I am solely responsible for thoroughly reviewing and editing forms as needed prior to use and for determining whether forms meet my requirements and needs.'
            )}
          </Text>
          <Text
            as='li'
            css={css`
              margin-bottom: ${theme.spacing(2)};
            `}
          >
            {t(
              'I understand that Weave will not accept any forms for digitization that include personally identifiable information (PII) or protected health information (PHI).'
            )}
          </Text>
          <Text
            as='li'
            css={css`
              margin-bottom: ${theme.spacing(2)};
            `}
          >
            {t('I understand that the one-time upload of forms for digitization is limited to 20 forms.')}
          </Text>
          <Text as='li'>
            {t(
              "I understand that creation of any additional forms, or edits to forms digitized by Weave, will require my office to use the Form Builder or use Weave's form digitization service at the cost of $20 per form. The cost of digitizing additional forms and applicable fees and taxes will be billed to my payment method on file with Weave."
            )}
          </Text>
        </ul>
      </Modal.Body>
      <Modal.Footer>
        <Text>
          {t('By checking the box below and clicking the Submit, you acknowledge and agree to all the above points.')}
        </Text>
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            align-items: center;
          `}
        >
          <form {...formProps}>
            <CheckboxField
              {...getFieldProps('condition')}
              label={t('I have read and agree to all of the points mentioned above.')}
            />
          </form>
          <span
            css={css`
              display: flex;
              gap: ${theme.spacing(2)};
            `}
          >
            <Button
              onClick={onFinishFormsUploadConfirmed}
              disabled={!isComplete}
              trackingId={pendoTags.formDigitization.acceptTerms}
            >
              {t('Submit')}
            </Button>
            <Button variant='secondary' onClick={() => onClose()} trackingId={pendoTags.formDigitization.cancelTerms}>
              {t('Cancel')}
            </Button>
          </span>
        </div>
      </Modal.Footer>
    </>
  );
};

export default FormsSubmissionConfirmation;
