import { ComponentProps } from 'react';
import { useTranslation } from '@frontend/i18n';
import { AlignCenterIconSmall, AlignLeftIconSmall, AlignRightIconSmall, RadioField } from '@frontend/design-system';
import { getRadioOptionStyling, overridingRadioGroupStyling } from '../../styles';
import { FrameAlignment } from '../../types';
import { Setting } from '.';

type Props = ComponentProps<typeof RadioField> & {
  value: FrameAlignment;
  isLast?: boolean;
};

export const AlignmentSetting = ({ isLast, ...fieldProps }: Props) => {
  const { t } = useTranslation('content-composer-settings');
  return (
    <Setting title={t('Layout')} isLast={isLast}>
      <RadioField {...fieldProps} css={overridingRadioGroupStyling}>
        <RadioField.Radio value='left' className='hide-radio' css={getRadioOptionStyling(fieldProps.value === 'left')}>
          <AlignLeftIconSmall />
        </RadioField.Radio>
        <RadioField.Radio
          value='center'
          className='hide-radio'
          css={getRadioOptionStyling(fieldProps.value === 'center')}
        >
          <AlignCenterIconSmall />
        </RadioField.Radio>
        <RadioField.Radio
          value='right'
          className='hide-radio'
          css={getRadioOptionStyling(fieldProps.value === 'right')}
        >
          <AlignRightIconSmall />
        </RadioField.Radio>
      </RadioField>
    </Setting>
  );
};
