import { useTranslation } from '@frontend/i18n';
import { SegmentedBar, SegmentedBarTypes } from '@frontend/segmented-bar';
import { theme } from '@frontend/theme';
import { Text, useFormField } from '@frontend/design-system';
import { LocationUsageAndAllotmentWithLimit } from '../type';
import { InsufficientMessage } from './insufficient-message';
import { MessageSpend } from './message-spend';

type Props = LocationUsageAndAllotmentWithLimit & {
  contactCount: number;
  items: SegmentedBarTypes.Data[];
  setData: (value: number) => void;
};

export const CardBody = ({
  items,
  allotment = 0,
  contactCount,
  error = 0,
  limit = 0,
  scheduled = 0,
  sent = 0,
  setData,
}: Props) => {
  const { t } = useTranslation('bulk-messaging');
  const fieldProps = useFormField({ type: 'number', value: limit.toString() });
  const totalUsedInput = Number(fieldProps.value);
  const totalAvailableInput = allotment - totalUsedInput;
  const remainingMonths = allotment - sent - scheduled - error;
  const itemsWithInputValue = items.map((item) =>
    item.isRemaining
      ? {
          ...item,
          data: totalAvailableInput,
          hoverLabel: item.hoverLabel?.replace(/\d+/g, totalAvailableInput.toLocaleString()),
        }
      : {
          ...item,
          data: totalUsedInput,
          hoverLabel: item.hoverLabel?.replace(/\d+/g, totalUsedInput.toLocaleString()),
        }
  );

  const props = {
    contactCount,
    totalAvailable: remainingMonths,
    totalUsed: totalUsedInput,
  };

  return (
    <div
      css={{
        padding: theme.spacing(2, 2, 0),
        border: `1px solid ${theme.colors.neutral20}`,
        borderTop: 'none',
        borderBottom: 'none',
      }}
    >
      <Text as='span' css={{ display: 'block', marginBottom: theme.spacing(0.5) }}>
        {t('{{numerator}} / {{max}} Available Messages', {
          numerator: Number(fieldProps.value).toLocaleString(),
          max: remainingMonths.toLocaleString(),
        })}
      </Text>
      <div
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
        }}
      >
        <SegmentedBar items={itemsWithInputValue}>
          <SegmentedBar.Bar barStyles={{ width: 220, height: theme.spacing(5) }} max={remainingMonths} />
        </SegmentedBar>
        <MessageSpend fieldProps={fieldProps} setData={setData} />
      </div>
      <InsufficientMessage {...props} />
    </div>
  );
};
