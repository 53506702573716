import { useQuery } from '@frontend/react-query-helpers';
import { SchemaIntegrationsService } from '@frontend/schema';
import { useAppScopeStore } from '@frontend/scope';

type UseGetDataSourcesForLocationArgs = {
  locationId: string;
  isEnabled?: boolean;
};

export const useGetDataSourcesForLocation = ({ locationId, isEnabled = false }: UseGetDataSourcesForLocationArgs) => {
  const { selectedParentsIds } = useAppScopeStore();
  const dataSourceLocationId = selectedParentsIds?.[0] || locationId || ''; // Data sources are present in parent location

  return useQuery({
    queryKey: ['data-sources', dataSourceLocationId],
    queryFn: () => SchemaIntegrationsService.ListDataSourcesByLocationID({ locationId: dataSourceLocationId }),
    enabled: !!dataSourceLocationId && isEnabled,
  });
};
