import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { CallRecordingSetting } from '@weave/schema-gen-ts/dist/schemas/phone/call-config/v2/call_config.pb';
import { CallRecordingApi } from '@frontend/api-call-recording';
import { Trans, useTranslation } from '@frontend/i18n';
import { Icon, IconName } from '@frontend/icons';
import { breakpoints } from '@frontend/responsiveness';
import { useAppScopeStore, useScopedQuery } from '@frontend/scope';
import { useSettingsNavigate } from '@frontend/settings-routing';
import { theme } from '@frontend/theme';
import { Button, Text, TextLink } from '@frontend/design-system';
import { queryKeys } from '../../query-keys';
import { useCallIntelShallowStore } from './hooks';

type CallRecordingConfigKeys = Exclude<
  CallRecordingSetting,
  CallRecordingSetting.BOTH | CallRecordingSetting.UNSPECIFIED
>;

type CallRecordingConfigType = Record<
  CallRecordingConfigKeys,
  { heading: string; description?: React.ReactNode; icon: IconName; textBtnLabel?: string }
>;

const callRecordingsSettingPage = '/phone/call-recording';

export const CallRecordingsBanner = () => {
  const { t } = useTranslation('analytics');
  const { navigate } = useSettingsNavigate();
  const { isDemoAccount } = useCallIntelShallowStore('isDemoAccount');

  const { accessibleLocationData, selectedOrgId } = useAppScopeStore();

  const [isBannerVisible, setIsBannerVisible] = useState(false);

  const location = accessibleLocationData[selectedOrgId];
  const tenantId = location?.phoneTenantId || '';

  const callRecordingsQuery = useScopedQuery({
    queryKey: queryKeys.listCallRecording(tenantId),
    queryFn: () => CallRecordingApi.GetCallRecording({ tenantId: tenantId }),
  });

  const setting = callRecordingsQuery.data?.setting as CallRecordingSetting;

  const bannerType = (() => {
    if (setting === CallRecordingSetting.NONE) return 'error';
    if (setting === CallRecordingSetting.INBOUND || setting === CallRecordingSetting.OUTBOUND) return 'info';
    return null;
  })();

  // Config to manage different headings based on call recording settings
  const config: CallRecordingConfigType = {
    [CallRecordingSetting.NONE]: {
      heading: t('Call Recordings are Disabled'),
      description: t(
        'Calls are not being transcribed or analyzed in Call Intelligence. Enable Call Recordings to resume call transcription and analysis.'
      ),
      icon: 'error-badge',
      textBtnLabel: t('Manage Call Recording'),
    },
    [CallRecordingSetting.INBOUND]: {
      heading: t('Recording Inbound Calls Only'),
      description: (
        <>
          <Trans t={t}>
            <Text as='span'>Your</Text>{' '}
            <TextLink
              onClick={() => {
                navigate({ to: callRecordingsSettingPage });
              }}
            >
              Call Recording settings
            </TextLink>{' '}
            <Text as='span'>
              are set to only record inbound calls. Call Intelligence will now only transcribe and analyze inbound calls
            </Text>
          </Trans>
        </>
      ),
      icon: 'info-badge',
    },
    [CallRecordingSetting.OUTBOUND]: {
      heading: t('Recording Outbound Calls Only'),
      description: (
        <>
          <Trans t={t}>
            <Text as='span'>Your</Text>{' '}
            <TextLink
              onClick={() => {
                navigate({ to: callRecordingsSettingPage });
              }}
            >
              Call Recording settings
            </TextLink>{' '}
            <Text as='span'>
              are set to only record outbound calls. Call Intelligence will now only transcribe and analyze outbound
              calls
            </Text>
          </Trans>
        </>
      ),
      icon: 'info-badge',
    },
  };

  useEffect(() => {
    if (setting) {
      if (setting === CallRecordingSetting.BOTH || setting === CallRecordingSetting.UNSPECIFIED) {
        setIsBannerVisible(false);
        return;
      }

      // Always show the banner if the setting is NONE
      if (setting === CallRecordingSetting.NONE) {
        setIsBannerVisible(true);
        return;
      }

      const bannerState = JSON.parse(localStorage.getItem('callRecordingBanner') || '{}');
      const { dismissed, lastSetting } = bannerState;

      if (bannerType === 'info') {
        // If there is no lastSetting stored yet, save the current setting but do not show the banner
        if (!lastSetting) {
          localStorage.setItem('callRecordingBanner', JSON.stringify({ dismissed: false, lastSetting: setting }));
          return;
        }

        // Show the banner if the settings have changed and haven't been dismissed
        if (lastSetting !== setting) {
          setIsBannerVisible(true);
          localStorage.setItem('callRecordingBanner', JSON.stringify({ dismissed: false, lastSetting: setting }));
        } else if (!dismissed) {
          // Show the banner if it hasn't been dismissed
          setIsBannerVisible(true);
        }
      }
    }
  }, [setting, bannerType]);

  const dismissBanner = () => {
    localStorage.setItem('callRecordingBanner', JSON.stringify({ dismissed: true, lastSetting: setting }));
    setIsBannerVisible(false);
  };

  const bannerConfig = config[setting as CallRecordingConfigKeys];

  if (isDemoAccount || !isBannerVisible || !bannerType || !bannerConfig) {
    return null;
  }

  return (
    <div
      css={[
        styles.wrapper,
        css`
          background-color: ${bannerType === 'error' ? theme.colors.critical5 : theme.colors.primary5};
        `,
      ]}
    >
      <Icon name={bannerConfig.icon} size={24} color={bannerType === 'error' ? 'error' : 'primary'} css={styles.icon} />
      <div className={bannerType === 'error' ? 'note-wrapper note-wrapper-small' : 'note-wrapper'}>
        <div style={{ flexGrow: 1 }}>
          <Text style={{ maxWidth: '800px' }} weight='bold'>
            {bannerConfig.heading}
          </Text>
          <Text style={{ maxWidth: '800px' }}>{bannerConfig.description}</Text>
        </div>
        {bannerType === 'error' ? (
          <TextLink
            weight='bold'
            onClick={() => {
              navigate({ to: callRecordingsSettingPage });
            }}
          >
            {bannerConfig.textBtnLabel}
          </TextLink>
        ) : (
          <Button iconName='x' variant='secondary' size='small' css={styles.btnIcon} onClick={dismissBanner} />
        )}
      </div>
    </div>
  );
};

const styles = {
  wrapper: css`
    display: flex;
    align-items: flex-start;
    box-shadow: ${theme.shadows.floating};
    gap: ${theme.spacing(2)};
    margin-bottom: ${theme.spacing(0.5)};
    padding: ${theme.spacing(2)};
    align-items: center;

    .note-wrapper {
      align-items: center;
      display: flex;
      flex-grow: 1;
      gap: ${theme.spacing(2)};
      flex-direction: row;
    }

    .note-wrapper-small {
      @media screen and (max-width: ${breakpoints.small.max}px) {
        align-items: flex-start;
        flex-direction: column;
      }
    }
  `,
  icon: css`
    min-width: ${theme.spacing(3)};
    min-height: ${theme.spacing(3)};
  `,

  btnIcon: css`
    padding: ${theme.spacing(0)};
    border: ${theme.spacing(0)};
    svg {
      min-width: ${theme.spacing(2)};
      min-height: ${theme.spacing(2)};
    }
  `,
};
