import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Text, emptyStateGraphics } from '@frontend/design-system';

const EmptyStateGraphic = emptyStateGraphics.generic;

export const EmptySearchState = () => {
  const { t } = useTranslation('team-chat');

  return (
    <div css={emptyStateContainerStyle}>
      <EmptyStateGraphic />
      <Text weight='bold' size='large'>
        {t('No members found')}
      </Text>
    </div>
  );
};

const emptyStateContainerStyle = css({
  display: 'block',
  textAlign: 'center',

  svg: {
    maxWidth: '200px',
    margin: '0 auto',
  },

  p: {
    color: theme.colors.neutral50,
  },
});
