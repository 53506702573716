import { FC, PropsWithChildren } from 'react';
import { CSSObject } from '@emotion/react';
import { Media } from '@weave/schema-gen-ts/dist/schemas/messaging/media/public/v1/service.pb';
import dayjs from 'dayjs';
import { Chips } from '@frontend/chips';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import {
  Heading,
  IconProps,
  NakedButton,
  PopoverMenu,
  PopoverMenuItem,
  styles,
  Text,
  usePopoverMenu,
} from '@frontend/design-system';

export const Image = ({
  canModify = false,
  image,
  selected,
  onClick,
  onDoubleClick,
  popoverActions,
  size = 'small',
}: {
  canModify?: boolean;
  image: Media;
  selected: boolean;
  onClick: () => void;
  onDoubleClick?: () => void;
  popoverActions?: {
    css?: CSSObject;
    hide?: boolean;
    Icon: FC<PropsWithChildren<Pick<IconProps, 'size' | 'color'>>>;
    label: string;
    onClick: () => void;
    trackingId: string;
  }[];
  size?: 'small' | 'large';
}) => {
  const { t } = useTranslation('messages');
  const { getMenuProps, getTriggerProps, getItemProps } = usePopoverMenu({
    placement: 'bottom-start',
    middlewareOptions: {
      offset: {
        mainAxis: 16,
        alignmentAxis: -8,
      },
    },
  });

  return (
    <>
      <NakedButton
        onClick={onClick}
        onDoubleClick={onDoubleClick}
        css={{
          position: 'relative',
          cursor: 'pointer',
          border: selected ? `2px solid ${theme.colors.primary50}` : `2px solid ${theme.colors.neutral20}`,
          borderRadius: theme.borderRadius.medium,
          height: size === 'large' ? 200 : 152,
          width: size === 'large' ? 200 : 152,
        }}
      >
        <img
          src={image.thumbnailUrl || image.url}
          alt={image.fileName}
          css={{
            borderRadius: 6,
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: 'center',
          }}
        />
        {selected && canModify && (
          <>
            <NakedButton
              {...getTriggerProps()}
              onClick={(e) => {
                e.stopPropagation();
                getTriggerProps()?.onClick?.(e);
              }}
              css={{
                position: 'absolute',
                top: theme.spacing(1),
                right: theme.spacing(1),
                padding: theme.spacing(1),
                background: theme.colors.neutral10,
                borderRadius: theme.borderRadius.medium,
              }}
            >
              <Icon name='more' />
            </NakedButton>
            <PopoverMenu {...getMenuProps()} css={{ paddingTop: 0, width: 240 }}>
              <div
                css={{
                  padding: theme.spacing(2),
                  borderBottom: `1px solid ${theme.colors.neutral20}`,
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: theme.spacing(1),
                }}
              >
                <Heading level={3} css={[styles.truncate, { maxWidth: '100%' }]}>
                  {image.fileName}
                </Heading>
                <Chips.LocationChip variant='warningDark'>
                  {t('{{count}} Location', { count: image.businessGroupIds.length })}
                </Chips.LocationChip>
                <Text color='light' size='small'>
                  {t('Uploaded {{date}}', { date: dayjs(image.createdAt).format('MMMM D') })}
                </Text>
              </div>
              {popoverActions
                ?.filter(({ hide }) => !hide)
                ?.map((action, index) => (
                  <PopoverMenuItem
                    key={index}
                    Icon={action.Icon}
                    {...getItemProps({
                      index,
                      onClick: action.onClick,
                    })}
                    css={[{ cursor: 'pointer' }, action.css]}
                    trackingId={action.trackingId}
                  >
                    {action.label}
                  </PopoverMenuItem>
                ))}
            </PopoverMenu>
          </>
        )}
      </NakedButton>
    </>
  );
};
