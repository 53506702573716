import { useEffect } from 'react';
import { formatDate } from '@frontend/date';
import { DateRangeField, useFormField } from '@frontend/design-system';

export const formatRange = (range?: string[], format = 'YYYY-MM-DD'): [string, string] => {
  if (!range) {
    return ['', ''];
  }
  return [formatDate(range?.[0], format), formatDate(range?.[1], format)];
};

export type DateRange = [string, string];
export const rangeHasDate = (range: DateRange) => range && (!!range[0] || !!range[1]);

type Props = {
  value: DateRange;
  name: string;
  onChange: (value: DateRange | undefined) => void;
};

export const DateFilter = ({ value, name, onChange }: Props) => {
  const dateRangeField = useFormField({ type: 'dateRange', required: true });

  useEffect(() => {
    if (dateRangeField.value !== value) {
      const dateRangeValue = dateRangeField.value as DateRange;
      const filterValue = rangeHasDate(dateRangeValue) ? dateRangeValue : undefined;
      onChange(filterValue);
    }
  }, [dateRangeField.value]);

  return (
    <div>
      <DateRangeField label='' name={name} {...dateRangeField} />
    </div>
  );
};
