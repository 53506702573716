import { css } from '@emotion/react';
import { CircularProgressBar } from '@frontend/task-tray';
import { theme } from '@frontend/theme';
import { Accordion, Heading, Text } from '@frontend/design-system';

interface Props {
  title: string;
  statusText: string;
  progress: number;
}

export const ModuleAccordionHeader = ({ title, statusText, progress }: Props) => (
  <Accordion.Header>
    <div css={containerStyle}>
      <CircularProgressBar progress={progress} />
      <Heading level={3} css={{ flexGrow: '1' }}>
        {title}
      </Heading>
      <Text as='span' size='small' color='light'>
        {statusText}
      </Text>
    </div>
  </Accordion.Header>
);

const containerStyle = css({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  columnGap: theme.spacing(2),
});
