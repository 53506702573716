import { ROOT_NODE, useEditor } from '@craftjs/core';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { NakedButton, SecondaryButton, Text, useModalControl } from '@frontend/design-system';
import { SIDEBAR_BREAKPOINTS } from '../../constants';
import { emailAssistantGradientStyling } from '../../styles';
import { useContentComposer } from '../content-composer-provider';
import { EmailAssistantModal } from '../email-assistant-modal';

export const Footer = () => {
  const { t } = useTranslation('content-composer');
  const { activePanel, setActivePanel } = useContentComposer();
  const { actions, isFooter, query, sections, selectedNode, selectedNodeId, selectedNodeName } = useEditor(
    (state, query) => {
      const [selectedNodeId] = state.events.selected;
      const rootNode = query.node(ROOT_NODE).get();
      return {
        // TODO: Find a better way to identify the footer section
        isFooter:
          Object.entries(state.nodes).find(([_key, value]) => value.data.name === 'FooterSection')?.[0] ===
          selectedNodeId,
        sections: rootNode ? rootNode.data.nodes : [],
        selectedNode: selectedNodeId ? query.node(selectedNodeId).get() : undefined,
        selectedNodeId,
        selectedNodeName: selectedNodeId ? state.nodes?.[selectedNodeId]?.data.name : undefined,
      };
    }
  );
  const aiAssistantList = ['TextSection'];
  const hasAiAssistant = aiAssistantList.find((type) => selectedNodeName === type);
  const selectedIdPosition = sections.findIndex((sectionId) => sectionId === selectedNodeId);

  const { modalProps, triggerProps } = useModalControl();

  // TODO: talk to Donat about if it would be possible for RTE's `initialHTML` property to be watched (i.e. manually update this state)
  //       this function wouldn't be necessary if that were the case (just need to update the text prop)
  // TODO: double check that this works for undo and redo
  const addTextComponent = (value: string) => {
    if (!selectedNodeId || !selectedNode) return;
    const index = sections?.findIndex((id) => id === selectedNodeId);
    // creates a copy of the node to be replaced by a new one
    const parsed = query.parseFreshNode(selectedNode).toNode();
    parsed.data.props.text = value;
    actions.delete(selectedNode.id);
    actions.add(parsed, ROOT_NODE, index);
  };

  const handleMove = (direction: 'up' | 'down') => {
    if (!selectedNodeId || !selectedNode) return;
    const directionIndex = direction === 'up' ? -1 : 1;
    const index = sections?.findIndex((id) => id === selectedNodeId);
    const newIndex = index + directionIndex;
    // creates a copy of the node to be replaced by a new one
    const parsed = query.parseFreshNode(selectedNode).toNode();
    actions.delete(selectedNode.id);
    actions.add(parsed, ROOT_NODE, newIndex);
  };

  return activePanel === 'settings' ? (
    <section
      css={[
        css`
          grid-area: footer;
          display: grid;
          grid-template-columns: auto 1fr;
          align-items: center;
          padding: ${theme.spacing(2)};
          column-gap: ${theme.spacing(1)};
          border-top: solid 1px ${theme.colors.neutral20};
          background: ${theme.colors.white};
          position: fixed;
          width: calc(100% - ${theme.spacing(31)});
          bottom: 0;
          z-index: ${theme.zIndex.middle + 1};
          @media (max-width: ${SIDEBAR_BREAKPOINTS.desktop}px) {
            width: 100%;
          }
        `,
        css`
          @media (min-width: ${SIDEBAR_BREAKPOINTS.mobile + 1}px) {
            display: none;
          }
        `,
      ]}
    >
      <div
        css={css`
          background: ${theme.colors.white};
          width: calc(100vw - 32px);
          display: flex;
          justify-content: space-between;
          align-items: center;
          @media (min-width: ${SIDEBAR_BREAKPOINTS.mobile + 1}px) {
            display: none;
          }
        `}
      >
        <div
          css={css`
            visibility: ${isFooter ? 'hidden' : 'visible'};
            display: flex;
            column-gap: ${theme.spacing(1)};
          `}
        >
          <SecondaryButton
            disabled={selectedIdPosition === 0}
            onClick={() => handleMove('up')}
            css={css`
              display: flex;
              padding: ${theme.spacing(1, 0)};
              width: ${theme.spacing(4)};
            `}
          >
            <Icon
              name='trend-up'
              css={css`
                transform: rotate(-45deg);
                width: ${theme.spacing(2)};
              `}
            />
          </SecondaryButton>
          <SecondaryButton
            disabled={selectedIdPosition === sections.length - 1}
            onClick={() => handleMove('down')}
            css={css`
              display: flex;
              padding: ${theme.spacing(1, 0)};
              width: ${theme.spacing(4)};
            `}
          >
            <Icon
              name='trend-up'
              css={css`
                transform: rotate(135deg);
                width: ${theme.spacing(2)};
              `}
            />
          </SecondaryButton>
        </div>
        <NakedButton
          onClick={triggerProps.onClick}
          css={[
            emailAssistantGradientStyling,
            css`
              padding: ${theme.spacing(0.75)};
              border-radius: ${theme.borderRadius.small};
              height: ${theme.spacing(4)};
              width: ${theme.spacing(4)};
              justify-content: center;
              align-items: center;
              display: ${hasAiAssistant ? 'flex' : 'none'};
            `,
          ]}
        >
          <Icon name='aiassistant-small' />
        </NakedButton>
        <NakedButton
          css={
            isFooter &&
            css`
              display: none;
            `
          }
          onClick={() => {
            if (selectedNodeId && !isFooter) {
              actions.delete(selectedNodeId);
            }
          }}
        >
          <Icon name='trash' />
        </NakedButton>
        <NakedButton
          onClick={() => {
            actions.clearEvents();
            setActivePanel('unset');
          }}
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <Icon
            name='check'
            color='primary'
            size={20}
            css={css`
              margin-right: ${theme.spacing(0.5)};
            `}
          />
          <Text as='span' color='primary'>
            {t('Done')}
          </Text>
        </NakedButton>
        <EmailAssistantModal
          modalProps={modalProps}
          onComplete={(value: string) => addTextComponent(value)}
          previousText='' // TODO: grab the text prop from the selected node
          type='text'
        />
      </div>
    </section>
  ) : null;
};
