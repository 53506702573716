import { nanoid } from 'nanoid';
import { createProvider } from '@frontend/store';
import { GlobalBannerStore, type Banner } from './types';

const sortBanners = (banners: Banner[]) => [...banners].sort((a, b) => b.priority - a.priority);

export const { Provider: GlobalBannerProvider, useStore: useGlobalBannerStore } = createProvider<GlobalBannerStore>()(
  (set, get) => ({
    banners: [],
    registerBanner: (banner) => {
      if (!!get().banners.find((existingBanner) => existingBanner.id === banner.id)) {
        throw new Error(
          `Each Global Banner must have a unique identifier - duplicate IDs for banner {${banner.id}} are not permitted.`
        );
      }

      set((state) => ({
        banners: sortBanners([...state.banners, banner]),
      }));
    },
    unregisterBanner: (id) => {
      set((state) => ({
        banners: state.banners.filter((banner) => banner.id !== id),
      }));
    },
    addBanner: (banner) => {
      const id = banner.id || nanoid();
      const existingBanner = get().banners.find((b) => b.id === id);

      if (existingBanner) {
        console.warn(`Banner with ID ${id} already exists. Generating new ID.`);
        return get().addBanner({ ...banner, id: undefined });
      }

      const bannerData = {
        ...banner,
        id,
        onDismiss: banner.isDismissible
          ? () => {
              get().removeBanner(id);
              banner.onDismiss?.();
            }
          : undefined,
      };

      set((state) => ({
        banners: sortBanners([...state.banners, bannerData]),
      }));

      return id;
    },
    removeBanner: (id) => {
      set((state) => ({
        banners: state.banners.filter((banner) => banner.id !== id),
      }));
    },
    updateBanner: (id, updates) => {
      set((state) => ({
        banners: sortBanners(state.banners.map((banner) => (banner.id === id ? { ...banner, ...updates } : banner))),
      }));
    },
  })
);
