import { useMemo } from 'react';
import { AnalyticsCommonTypes } from '@frontend/api-analytics';
import { useCallIntelShallowStore } from './use-call-intel-store';

/** Custom hook to check if the selected locations and current locations match. */

export const useLocationsMatch = (pickerLocation: AnalyticsCommonTypes.StringRecord): boolean => {
  const { locationIds: selectedFilterLocations, filters } = useCallIntelShallowStore('locationIds', 'filters');

  return useMemo(() => {
    const pickerLocations = Object.keys(pickerLocation);

    //This is for initial load , we are setting it to true
    if (!pickerLocations.length || !Object.keys(filters).length) return true;

    if (pickerLocations.length !== selectedFilterLocations.length) return false;

    // Check if all selected locations exist in the current locations
    const currentLocationsSet = new Set(pickerLocations);
    return selectedFilterLocations.every((locationId) => currentLocationsSet.has(locationId));
  }, [pickerLocation, selectedFilterLocations]);
};
