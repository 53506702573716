import { FC } from 'react';
import { css } from '@emotion/react';
import { NumberType } from '@weave/schema-gen-ts/dist/shared/porting/v1/enums.pb';
import { PortingTypes } from '@frontend/api-porting';
import { useTranslation } from '@frontend/i18n';
import { formatPhoneFaxNumber, formatPhoneNumber } from '@frontend/phone-numbers';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';

interface Props {
  portRequest: PortingTypes.ModifiedPortingData;
}

export const ExpandedRowContent: FC<React.PropsWithChildren<Props>> = ({ portRequest }) => {
  const {
    companyName,
    currentPhoneServiceProvider,
    phoneServiceAccountNumber,
    authorizedUserFirstName,
    authorizedUserLastName,
    accountPin,
    serviceHouseNumber,
    serviceStreet1,
    serviceStreet2,
    serviceCity,
    serviceState,
    serviceZip,
    numbers,
  } = portRequest;
  const { t } = useTranslation('porting', { keyPrefix: 'phoneNumbers' });
  return (
    <div css={{ padding: theme.spacing(4), backgroundColor: theme.colors.neutral5, width: 'inherit' }}>
      <Text weight='bold'>{t('Account Information')}</Text>
      <hr css={{ margin: theme.spacing(1, 0), borderColor: theme.colors.neutral5 }} />
      <div css={containerStyle}>
        <Text css={heading}>{t('Business Account')}</Text>
        <Text css={contentStyleWidthFix}>{companyName || '-'}</Text>
        <Text css={heading}>{t('Service Provider')}</Text>
        <Text css={largeContentStyle}>{currentPhoneServiceProvider || '-'}</Text>
      </div>
      <div css={containerStyle}>
        <Text css={heading}>{t('Account Number')}</Text>
        <Text css={contentStyleWidthFix}>{phoneServiceAccountNumber || '-'}</Text>
        <Text css={heading}>{t('Authorized User')}</Text>
        <Text css={largeContentStyle}>{`${authorizedUserFirstName} ${authorizedUserLastName}`.trim() || '-'}</Text>
      </div>
      <div css={containerStyle}>
        <Text css={heading}>{t('Pin')}</Text>
        <Text css={contentStyleWidthFix}>{accountPin || '-'}</Text>
        <Text css={heading}>{t('Service Address')}</Text>
        <Text css={largeContentStyle}>
          {serviceHouseNumber} {serviceStreet1} {serviceStreet2}
          <Text>
            {serviceCity}
            {serviceCity && ','} {serviceState} {serviceZip}
          </Text>
        </Text>
      </div>
      <Text weight='bold'>{t('Phone Numbers')}</Text>
      <hr css={{ margin: theme.spacing(1, 0), borderColor: theme.colors.neutral5 }} />

      <div css={{ display: 'flex' }}>
        <Text css={headingDIDS}>{t('DID(s)')}</Text>
        <div css={{ display: 'grid', gridTemplateColumns: 'repeat(3, minmax(0, 1fr))', gridColumnGap: '20px' }}>
          {numbers?.map((info) => (
            <div
              css={{
                width: '300px',
                padding: theme.spacing(1),
                margin: theme.spacing(0.5),
              }}
              key={info.number}
            >
              {info.numberType === NumberType.NUMBER_TYPE_FAX
                ? formatPhoneFaxNumber(info.number)
                : formatPhoneNumber(info.number)}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const heading = css`
  width: 150px;
  color: ${theme.colors.neutral40};
  margin-bottom: ${theme.spacing(0.5)};
`;
const contentStyleWidthFix = css`
  width: 150px;
  margin-bottom: ${theme.spacing(0.5)};
`;

const largeContentStyle = css`
  width: 250px;
  margin-bottom: ${theme.spacing(0.5)};
`;

const containerStyle = css`
  display: flex;
  justify-content: space-between;
`;

const headingDIDS = css`
  width: 336px;
  color: ${theme.colors.neutral40};
  margin-bottom: ${theme.spacing(0.5)};
  @media (min-width: 708px) and (max-width: 1024px) {
    width: 250px;
  }
`;
