import { theme } from '@frontend/theme';
import { PolymorphicComponentPropWithoutRef } from '@frontend/design-system';

type SettingsSectionProps<C extends React.ElementType> = PolymorphicComponentPropWithoutRef<C>;

export const SettingsSection = <C extends React.ElementType = 'div'>({
  as,
  children,
  ...rest
}: SettingsSectionProps<C>) => {
  const Component = as || 'div';

  return (
    <Component
      style={{
        background: theme.colors.white,
        padding: theme.spacing(3),
        borderRadius: theme.borderRadius.medium,
        boxShadow: theme.shadows.light,
      }}
      {...rest}
    >
      {children}
    </Component>
  );
};
