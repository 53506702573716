import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { useMatchRoute } from '@tanstack/react-location';
import { LOADetails, LOASignDetails } from '@weave/schema-gen-ts/dist/schemas/agreements/v1/insys_agreements.pb';
import { canadaOptions, usOptions, StateOption } from '@frontend/geography';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';
import { CountryCodes } from '../../helper/state-codes';
import { SignedComponent } from './signed-component';
import { SignedEditableComponent } from './signed-editable-component';

interface Props {
  isPdf: boolean;
  loaDetails?: LOADetails;
  signComponent?: LOASignDetails;
  loaId: string;
  handleSignedData: (authorizedUserName: string, signDate: string) => void;
}

export const PdfViewComponent = ({ loaDetails, isPdf, signComponent, handleSignedData }: Props) => {
  const { t } = useTranslation('portingLoa');
  const [filteredStates, setFilteredStates] = useState<StateOption[]>([]);
  const [filteredBillingStates, setFilteredBillingStates] = useState<StateOption[]>([]);

  const shippingCountry =
    loaDetails?.serviceAddress?.shippingCountry === CountryCodes.Canada.Code
      ? CountryCodes.Canada.Label
      : CountryCodes.USA.Label;
  const billingCountry =
    loaDetails?.billingAddress?.shippingCountry === CountryCodes.Canada.Code
      ? CountryCodes.Canada.Label
      : CountryCodes.USA.Label;

  useEffect(() => {
    shippingCountry === CountryCodes.Canada.Label ? setFilteredStates(canadaOptions) : setFilteredStates(usOptions);
    billingCountry === CountryCodes.Canada.Label
      ? setFilteredBillingStates(canadaOptions)
      : setFilteredBillingStates(usOptions);
  }, [shippingCountry, billingCountry]);

  const state = loaDetails?.serviceAddress?.shippingState;
  const shippingStateValue = filteredStates.find((st) => state === st.value);

  const billingState = loaDetails?.billingAddress?.shippingState;
  const billingStateValue = filteredBillingStates.find((st) => billingState === st.value);

  const handleDisableButton = (signDate: string, authorizedUserFullName: string): boolean => {
    return !(signDate && authorizedUserFullName);
  };

  const renderFieldComponent = (label: string, value: string | undefined) => {
    const colonIndex = label.indexOf(':');
    const labelText = label.slice(0, colonIndex + 1);
    const additionalText = label.slice(colonIndex + 1).trim();
    return (
      <div css={contentStyleContainer}>
        <Text weight='bold' css={contentStyle}>
          {labelText}
          <div> {additionalText}</div>
        </Text>

        <Text
          css={css`
            padding: ${theme.spacing(1)};
          `}
        >
          {value}
        </Text>
      </div>
    );
  };

  const renderTextComponent = (value: string | undefined) => {
    return <Text css={fieldStyles}>{value}</Text>;
  };
  const matchRoute = useMatchRoute();
  const isPdfRoute = matchRoute({ to: '/porting/loa/:loaId/pdf' });

  return (
    <div>
      <div css={pdfViewContainer}>
        <div css={boxStyle}>
          <Text weight='bold' css={contentStyle}>
            {t('Customer Account or Company Name:')}
          </Text>
          <Text css={{ padding: theme.spacing(1) }}>{loaDetails?.companyName}</Text>
        </div>
        {renderFieldComponent(t('Billing Telephone Number:'), loaDetails?.billingTelephoneNumber)}
        {renderFieldComponent(t('Current Carrier:'), loaDetails?.currentCarrier)}
        <div css={addressContainer}>
          <Text weight='bold' css={addressStyle}>
            {t('Service Address:')}
          </Text>
          <Text weight='bold' css={addressStyle}>
            {t('City:')}
          </Text>
          <Text weight='bold' css={addressStyle}>
            {t('Country:')}
          </Text>
          <Text weight='bold' css={addressStyle}>
            {t('State:')}
          </Text>
          <Text weight='bold' css={postalCodeText}>
            {t('Postal Code:')}
          </Text>
          {renderTextComponent(
            `${loaDetails?.serviceAddress?.shippingStreet} ${loaDetails?.serviceAddress?.shippingStreet2}`
          )}
          {renderTextComponent(loaDetails?.serviceAddress?.shippingCity)}
          {renderTextComponent(shippingCountry)}
          {renderTextComponent(shippingStateValue?.label)}
          <Text css={postalCodeValue}>{loaDetails?.serviceAddress?.shippingPostalCode}</Text>
        </div>
      </div>
      <div css={billingAddressStyle}>
        <Text weight='bold' css={addressStyle}>
          {t('Billing Address:')}
        </Text>
        <Text weight='bold' css={addressStyle}>
          {t('City:')}
        </Text>
        <Text weight='bold' css={addressStyle}>
          {t('Country:')}
        </Text>
        <Text weight='bold' css={addressStyle}>
          {t('State:')}
        </Text>
        <Text weight='bold' css={postalCodeText}>
          {t('Postal Code:')}
        </Text>
        {renderTextComponent(
          `${loaDetails?.billingAddress?.shippingStreet} ${loaDetails?.billingAddress?.shippingStreet2}`
        )}
        {renderTextComponent(loaDetails?.billingAddress?.shippingCity)}
        {renderTextComponent(billingCountry)}
        {renderTextComponent(billingStateValue?.label)}
        <Text css={postalCodeValue}>{loaDetails?.billingAddress?.shippingPostalCode}</Text>
      </div>
      <Text
        weight='bold'
        css={{
          margin: theme.spacing(3.5, 0, 3.5, 1),
          paddingTop: theme.spacing(isPdfRoute ? 5.5 : 0),
        }}
      >
        {t('Telephone numbers to be ported ')}
        <Text as='span' weight='regular'>
          {t('(xxx-xxx-xxxx format):')}
        </Text>
      </Text>
      <div css={telephoneDataStyle}>
        <div
          css={css`
            border: 1px solid ${theme.colors.black};
            display: flex;
          `}
        >
          <Text weight='bold' css={contentStyle}>
            {t('Main telephone number:')}
          </Text>
          <Text css={{ padding: theme.spacing(1) }}>{loaDetails?.mainPhoneNumber}</Text>
        </div>
        {renderFieldComponent(t('Main fax number:'), loaDetails?.mainFaxNumber)}
        {renderFieldComponent(
          t('All other telephone numbers:') + '\n' + t('(separated by commas)'),
          loaDetails?.otherPhoneNumbers?.join(', ')
        )}
        {renderFieldComponent(
          t('All other Fax numbers:') + '\n' + t('(separated by commas)'),
          loaDetails?.otherFaxNumbers?.join(', ')
        )}
        {signComponent ? (
          <SignedComponent
            authorizedSignatureData={signComponent?.signedName ?? ''}
            dateData={signComponent?.signDate ?? ''}
          />
        ) : (
          <SignedEditableComponent
            handleDisableButton={handleDisableButton}
            isPdf={isPdf}
            handleSignedData={handleSignedData}
          />
        )}
      </div>
    </div>
  );
};

const pdfViewContainer = css`
  display: grid;
  grid-template-columns: auto;
  padding: ${theme.spacing(1)};
`;

const boxStyle = css`
  border: 1px solid ${theme.colors.black};
  display: flex;
`;

const contentStyle = css`
  width: 300px;
  border-right: 1px solid ${theme.colors.black};
  padding: ${theme.spacing(1)};
`;

const contentStyleContainer = css`
  border: 1px solid ${theme.colors.black};
  display: flex;
  border-top: 0;
`;
const addressStyle = css`
  border: 1px solid ${theme.colors.black};
  padding: ${theme.spacing(1)};
  border-right: 0;
`;

const fieldStyles = css`
  border: 1px solid ${theme.colors.black};
  padding: ${theme.spacing(1)};
  border-top: 0;
  border-right: 0;
`;

const telephoneDataStyle = css`
  display: grid;
  grid-template-columns: auto;
  padding: ${theme.spacing(1)};
  padding-top: 0;
`;

const billingAddressStyle = css`
  display: grid;
  margin-top: ${theme.spacing(4.5)};
  padding: ${theme.spacing(1)};
  grid-template-columns: auto auto auto auto auto;
`;

const postalCodeText = css`
  border: 1px solid ${theme.colors.black};
  padding: ${theme.spacing(1)};
`;

const postalCodeValue = css`
  border: 1px solid ${theme.colors.black};
  padding: ${theme.spacing(1)};
  border-top: 0;
`;
const addressContainer = css`
  display: grid;
  margin-top: ${theme.spacing(6.5)};
  grid-template-columns: auto auto auto auto auto;
`;
