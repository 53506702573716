import { useMemo } from 'react';
import { SMSAnalyticsApi, SMSAnalyticsTypes } from '@frontend/api-analytics';
import { useTranslation } from '@frontend/i18n';
import { useScopedQuery } from '@frontend/scope';
import { useAlert } from '@frontend/design-system';
import { queryKeys } from '../../../query-keys';
import { useMessagingAnalyticsShallowStore } from './use-messaging-analytics-store';

type UseFetchAggregatedSMSReport = {
  data?: SMSAnalyticsTypes.AggregatedSMSReport;
  isLoading?: boolean;
};

export const useFetchAggregatedSMSReport = (
  condition: SMSAnalyticsTypes.SMSAggregateCondition,
  customFilters?: SMSAnalyticsTypes.Filters
): UseFetchAggregatedSMSReport => {
  const alert = useAlert();
  const { t } = useTranslation('analytics');
  const { demoData, filters, isDemoAccount } = useMessagingAnalyticsShallowStore(
    'demoData',
    'filters',
    'isDemoAccount'
  );

  // Extract unwanted filters for the charts view
  const { content_type, message_category, number, sms_type, source_type, status, ...restFilters } =
    customFilters || filters;
  const queryString = useMemo(() => `${JSON.stringify({ ...restFilters })}`, [restFilters]);

  const { data, isLoading } = useScopedQuery({
    queryKey: queryKeys.smsAnalyticsCharts(`${queryString}-aggregated-messaging-data-${condition}`),
    queryFn: () => SMSAnalyticsApi.getAggregatedSMSReport(condition, restFilters),
    onError: () => {
      alert.error(t("Couldn't load the sms reports charts. Please try again."));
    },
    enabled: !isDemoAccount,
    retry: false,
    refetchOnWindowFocus: false,
    staleTime: 5 * 60 * 1000, // 5 minutes
  });

  const formattedData = useMemo(() => {
    let dataToProcess = data;

    if (isDemoAccount) {
      switch (condition) {
        case 'ContentType':
          dataToProcess = demoData?.chartsData.smsVsMms;
          break;

        case 'Direction':
          dataToProcess = demoData?.chartsData.incomingVsOutgoingMessages;
          break;

        case 'MessageCategory':
          dataToProcess = demoData?.chartsData.messageCategories;
          break;

        case 'SourceType':
          dataToProcess = demoData?.chartsData.patientsVsUnknownMessages;
          break;
      }
    }

    const reducedData = dataToProcess?.aggregate_data?.reduce<SMSAnalyticsTypes.AggregatedSMSReport>((acc, cur) => {
      if (!acc[cur.location_id]) {
        acc[cur.location_id] = {};
      }

      if (!acc[cur.location_id][cur.date]) {
        // Include all the keys that are present in the data_points
        // Also prefill the hourly array with 24 zeros to help later calculations and chart rendering
        // The 24 zeros represent the 24 hours in a day
        acc[cur.location_id][cur.date] =
          dataToProcess.data_points?.reduce(
            (acc1, dataPoint) => ({
              ...acc1,
              [dataPoint]: {
                hourly: Array(24).fill(0),
                daily: 0,
              },
            }),
            {}
          ) || {};
      }

      acc[cur.location_id][cur.date][cur.name] = {
        hourly: cur.data,
        daily: cur.data?.reduce((sum, curValue) => sum + curValue, 0) || 0,
      };

      return acc;
    }, {});

    // Sort the dates within each location_id object
    if (reducedData) {
      Object.keys(reducedData).forEach((locationId) => {
        const dates = Object.keys(reducedData[locationId]).sort();
        const sortedData = dates.reduce<SMSAnalyticsTypes.AggregatedSMSReportLocation>((sortedAcc, date) => {
          sortedAcc[date] = reducedData[locationId][date];
          return sortedAcc;
        }, {});
        reducedData[locationId] = sortedData;
      });
    }

    return reducedData;
  }, [data, demoData, isDemoAccount]);

  return {
    data: formattedData,
    isLoading,
  };
};
