import { ReactElement } from 'react';
import { ROOT_NODE, useEditor } from '@craftjs/core';

export const useInsertSection = () => {
  const {
    actions: { addNodeTree, selectNode },
    query: { parseReactElement },
  } = useEditor();

  return (element: ReactElement, index?: number) => {
    const parsed = parseReactElement(element).toNodeTree();
    const sectionId = Object.values(parsed.nodes)?.[0]?.id;
    addNodeTree(parsed, ROOT_NODE, index);
    selectNode(sectionId);
    setTimeout(() => {
      if (sectionId) {
        document.getElementById(sectionId)?.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);
  };
};
