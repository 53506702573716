import { useEffect, useState } from 'react';
import { Media } from '@weave/schema-gen-ts/dist/schemas/messaging/media/public/v1/service.pb';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { ImageGallery, PublicImageWarning } from '@frontend/image-gallery';
import { ImageGalleryPrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import { Heading, Modal, ModalControlModalProps } from '@frontend/design-system';
import { useContentComposer } from '../../content-composer-provider';

interface Props {
  modalProps: ModalControlModalProps;
  onSelect: (files: (Media | undefined)[]) => void;
  selectedIndex: number;
  selectedImages?: (Media | undefined)[];
}

export const UploadOrSelectImageModal = ({ modalProps, onSelect, selectedImages = [], selectedIndex }: Props) => {
  const { t } = useTranslation('content-composer');
  const [selectedImage, setSelectedImage] = useState<Media | undefined>(selectedImages[selectedIndex]);
  const { locationIds } = useContentComposer();

  const handleImageSelect = (newImage?: Media) => {
    const newSelectedImages = selectedImages.slice();
    newSelectedImages[selectedIndex] = newImage;
    setSelectedImage(newImage);
  };

  useEffect(() => {
    setSelectedImage(selectedImages[selectedIndex]);
  }, [selectedImages]);

  return (
    <Modal {...modalProps} minWidth={700}>
      <Modal.Header>
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: theme.spacing(3),
          }}
        >
          <Heading level={2}>{t('Upload or Select Logo')}</Heading>
          <Icon name='x-small' color='light' size={16} onClick={modalProps.onClose} />
        </div>
      </Modal.Header>
      <Modal.Body>
        <PublicImageWarning />
        <ImageGallery
          locationIds={locationIds}
          handleImageSelect={handleImageSelect}
          onDoubleClick={(newImage?: Media) => {
            const newSelectedImages = selectedImages.slice();
            newSelectedImages[selectedIndex] = newImage;
            onSelect(newSelectedImages);
            modalProps.onClose();
          }}
          selectedImages={[selectedImage]}
          css={{ '.image-gallery-content': { marginTop: theme.spacing(2) } }}
          trackingId={ImageGalleryPrefixes.ContentComposer}
        />
      </Modal.Body>
      <Modal.Actions
        primaryLabel={t('Select')}
        onPrimaryClick={() => {
          const newSelectedImages = selectedImages.slice();
          newSelectedImages[selectedIndex] = selectedImage;
          onSelect(newSelectedImages);
          modalProps.onClose();
        }}
        secondaryLabel={t('Cancel')}
        onSecondaryClick={modalProps.onClose}
        disablePrimary={!selectedImage}
      />
    </Modal>
  );
};
