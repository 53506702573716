import { PercentCrop } from 'react-image-crop';

export const getImageCropStyles = (imgWidth?: number, imgHeight?: number, crop?: PercentCrop) => {
  if (!imgWidth || !imgHeight || !crop) return {};

  // conversion of the crop dimensions to pixels
  const startingX = (crop.x / 100) * imgWidth;
  const startingY = (crop.y / 100) * imgHeight;
  const cropWidth = (crop.width / 100) * imgWidth;
  const cropHeight = (crop.height / 100) * imgHeight;

  // clip path crop dimensions
  const left = startingX;
  const top = startingY;
  const right = imgWidth - startingX - cropWidth;
  const bottom = imgHeight - startingY - cropHeight;
  const clipPath = `inset(${top}px ${right}px ${bottom}px ${left}px)`;

  // scaling of the image
  const scaledX = imgWidth / cropWidth;
  const scaledY = imgHeight / cropHeight;

  // calculate the difference between the center of the cropped image and the center of the original image to determine how much to translate the cropped image
  const croppedCenterX = (startingX + (startingX + cropWidth)) / 2;
  const croppedCenterY = (startingY + (startingY + cropHeight)) / 2;
  const centerOfOriginalX = imgWidth / 2;
  const centerOfOriginalY = imgHeight / 2;
  const differenceCenterX = centerOfOriginalX - croppedCenterX;
  const differenceCenterY = centerOfOriginalY - croppedCenterY;

  const transform = `scale(${scaledX}, ${scaledY}) translate(${differenceCenterX}px, ${differenceCenterY}px)`;

  return {
    clipPath,
    transform,
  };
};
