import { useTranslation } from '@frontend/i18n';
import { BannerNotification } from '@frontend/design-system';

export const PublicImageWarning = ({ ...rest }) => {
  const { t } = useTranslation('messages');

  return (
    <BannerNotification
      message={t(
        'Images uploaded will be publicly available to anyone with the link. Please do not upload content with sensitive or protected information.'
      )}
      status='info'
      {...rest}
    />
  );
};
