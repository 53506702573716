import { useDevToolsStore } from '@frontend/devtools';
import { useTranslation } from '@frontend/i18n';
import { CheckboxField, Stepper, useControlledField } from '@frontend/design-system';
import { trackingIds } from '../../constants';

interface Props {
  isFormValid: boolean;
  isInfoCorrect: boolean;
  setIsInfoCorrect: (isValid: boolean) => void;
  onNextClick?: () => Promise<void>;
  onSubmit?: () => void;
}

export const StepperFooter = ({ isFormValid, isInfoCorrect, setIsInfoCorrect, onNextClick, onSubmit }: Props) => {
  const { t } = useTranslation('porting');
  const isDebugModeOn = useDevToolsStore((state) => state.options.isDebugModeOn);
  const infoCorrectCheckboxField = useControlledField({
    type: 'checkbox',
    value: isInfoCorrect,
    onChange: (value) => setIsInfoCorrect(value),
  });

  const isEnableNextButton = (isFormValid && infoCorrectCheckboxField.value) || isDebugModeOn;
  return (
    <Stepper.ButtonBar css={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <CheckboxField
        {...infoCorrectCheckboxField}
        disabled={!isFormValid}
        name='info-correct-checkbox'
        label={t('This information is correct')}
        data-trackingid={`${trackingIds.submitPortRequest}_info-correct_checkbox`}
      />
      <Stepper.NextButton
        isValid={isEnableNextButton}
        onClick={onNextClick}
        onSubmit={onSubmit}
        trackingId={`${trackingIds.submitPortRequest}_next_btn`}
      >
        {t('Next')}
      </Stepper.NextButton>
    </Stepper.ButtonBar>
  );
};
