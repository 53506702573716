export type IntakeFormRoutePaths = ReturnType<typeof getIntakeFormPaths>;

export type IntakeFormRouteKey = keyof IntakeFormRoutePaths;

enum IntakeFormRootPath {
  DEMO = '/onboarding-demo/form',
  ORIGINAL = '/onboarding/form',
}

export const PORTAL_DASHBOARD_PATH = '/portal';
export const NWX_DASHBOARD_PATH = '/home/dashboard';
export const INTAKE_FORM_PATH_V2 = '/onboard';

const getIntakeFormPaths = (rootPath: IntakeFormRootPath) =>
  ({
    root: rootPath,
    location: `${rootPath}/:locationId`,
    screening: `${rootPath}/:locationId/screening`,
    dashboard: `${rootPath}/:locationId/dashboard`,
    complete: `${rootPath}/:locationId/complete`,
    section: `${rootPath}/:locationId/:section`,
    step: `${rootPath}/:locationId/:section/:task/:step`,
  } as const);

export const INTAKE_FORM_PATHS = getIntakeFormPaths(IntakeFormRootPath.ORIGINAL);
export const INTAKE_FORM_DEMO_PATHS = getIntakeFormPaths(IntakeFormRootPath.DEMO);
