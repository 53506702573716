import { useLocalizedQuery } from '@frontend/location-helpers';
import { SchemaIO } from '@frontend/schema';
import { SchemaMetlAmp } from './services';

export const queryKeys = {
  base: 'messages',
  activeTriggerTypes: () => [queryKeys.base, 'active-trigger-types'],
  getLocations: (orgId?: string) => ['locations', orgId],
  triggerFilterOptions: (locationIds: string[]) => [queryKeys.base, 'triggerFilterOptions', locationIds],
};

type SchemaActiveTriggerTypes = SchemaIO<(typeof SchemaMetlAmp)['ActiveTriggerTypes']>;
export const useActiveTriggerTypes = (request: SchemaActiveTriggerTypes['input']) =>
  useLocalizedQuery({
    queryKey: queryKeys.activeTriggerTypes(),
    queryFn: () => SchemaMetlAmp.ActiveTriggerTypes(request),
    select: (response) => response.hasAppointmentEnteredAt,
    enabled: !!request.locationIds && request.locationIds.length > 0,
  });

type SchemaGetLocation = SchemaIO<(typeof SchemaMetlAmp)['GetLocations']>;
export const useGetLocation = ({ orgId }: SchemaGetLocation['input']) =>
  useLocalizedQuery({
    queryKey: queryKeys.getLocations(orgId),
    queryFn: () => SchemaMetlAmp.GetLocations({ orgId }),
    select: (data) => data.locations,
    enabled: !!orgId,
  });

type SchemaTriggerFilterOptions = SchemaIO<(typeof SchemaMetlAmp)['TriggerFilterOptions']>;
export const useTriggerFilterOptions = (request: SchemaTriggerFilterOptions['input']) =>
  useLocalizedQuery({
    queryKey: queryKeys.triggerFilterOptions(request.locationIds ?? []),
    queryFn: (queryFunctionContext) =>
      SchemaMetlAmp.TriggerFilterOptions(request, { signal: queryFunctionContext.signal }),
    select: (data) => data.options,
    enabled: !!request.locationIds?.length,
  });
