import { useMemo } from 'react';
import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { CustomizationFlagQueries, CustomizationFlagTypes } from '@frontend/api-customization-flags';
import { FeatureFlagQueries } from '@frontend/api-feature-flags';
import { useMerchant } from '@frontend/payments-hooks';
import { ScheduleFeatureFlag } from '../../constants';
import { useSchedulingLocationInfo } from '../use-scheduling-location-info';

export const useBookingSiteSetupInfo = () => {
  const { isMultiLocation, selectedLocationIds, parentLocationId } = useSchedulingLocationInfo();

  //#region CF Info
  const { data: locationWiseCustomizationFlags, isLoading: isLoadingCustomizationFlags } =
    CustomizationFlagQueries.useMultiCustomizationFlags(selectedLocationIds);

  const checkHasOnlineSchedulingCF = (locationId: string) =>
    locationWiseCustomizationFlags?.locationsFeatures?.[locationId]?.features?.find(
      (feature) => feature.featureEnum === Feature.ONLINE_SCHEDULING
    )?.state === CustomizationFlagTypes.CustomizationFlagState.ACTIVE;

  const checkHasPaymentTerminalsCF = (locationId: string) => {
    const paymentTerminalCFInfo = locationWiseCustomizationFlags?.locationsFeatures?.[locationId]?.features?.find(
      (feature) => feature.featureEnum === Feature.PAYMENT_TERMINALS
    )?.state;
    return (
      paymentTerminalCFInfo === CustomizationFlagTypes.CustomizationFlagState.ACTIVE ||
      paymentTerminalCFInfo === CustomizationFlagTypes.CustomizationFlagState.PROMOTE
    );
  };

  //#endregion

  //#region FF Info
  const paymentsIntegrationFFInfo = FeatureFlagQueries.useAggregateFeatureFlagQuery({
    locationIds: selectedLocationIds,
    flagName: ScheduleFeatureFlag.SchedulePaymentsIntegrationRelease,
  });

  const checkHasPaymentsIntegrationFF = (locationId: string) => paymentsIntegrationFFInfo.flagsByGroupId[locationId];
  //#endregion

  const merchantInfo = useMerchant({ allLocations: true });

  const locationWiseBookingSiteInfoMap = useMemo(() => {
    const locationWiseMap = new Map<string, { shouldRenderGoogleAccount: boolean; locationPortalSlug: string }>();

    [...selectedLocationIds, parentLocationId].forEach((locationId) => {
      const hasPayments = checkHasPaymentTerminalsCF(locationId) && checkHasPaymentsIntegrationFF(locationId);
      const locationMerchantInfo = merchantInfo.merchantsData[locationId];

      locationWiseMap.set(locationId, {
        shouldRenderGoogleAccount: checkHasOnlineSchedulingCF(locationId) && !isMultiLocation,
        locationPortalSlug:
          parentLocationId !== locationId && // for parent location, we don't need to set slug as we were planning to get it for selected child location directly on booking site
          hasPayments &&
          locationMerchantInfo.chargesEnabled &&
          locationMerchantInfo.merchant?.locationPortalSlug
            ? locationMerchantInfo.merchant.locationPortalSlug
            : '',
      });
    });

    return locationWiseMap;
  }, [merchantInfo.merchantsData, locationWiseCustomizationFlags?.locationsFeatures, paymentsIntegrationFFInfo]);

  return {
    isLoading: paymentsIntegrationFFInfo.isLoading || isLoadingCustomizationFlags || merchantInfo.isLoading,
    locationWiseBookingSiteInfoMap,
  };
};
