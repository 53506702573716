import { useMemo } from 'react';
import { MappingType } from '@weave/schema-gen-ts/dist/schemas/schedule/v3/mapping.pb';
import { useQueries } from 'react-query';
import { ScheduleApiQueryKeys } from '@frontend/api-schedule';
import { SchedulerV3 } from '@frontend/api-schedule-v3';
import { SchemaScheduleService } from '@frontend/schema';
import { useGetSchedulerV3FeatureFlagDetails } from '../use-get-scheduler-v3-feature-flag-details';
import { useSchedulingLocationInfo } from '../use-scheduling-location-info';

export const useGetIsAppointmentTypeMappedWithPractitioner = () => {
  const { isScheduleV3FlagEnabled } = useGetSchedulerV3FeatureFlagDetails();
  const { selectedLocationIds } = useSchedulingLocationInfo();

  const listMappingsQueriesV3 = SchedulerV3.Queries.useListMappingsQuery({
    request: {
      locationIds: selectedLocationIds,
      appointmentTypeId: '',
    },
    options: {
      enabled: isScheduleV3FlagEnabled && !!selectedLocationIds.length,
      refetchOnMount: 'always',
    },
  });

  const appointmentTypesQueriesV2 = useQueries(
    selectedLocationIds.map((locationId) => ({
      queryKey: ScheduleApiQueryKeys.scheduleBookingSiteQueryKeys.appointmentTypes({ locationId }),
      queryFn: () => SchemaScheduleService.GetAppointmentTypeList({ locationId }),
      enabled: !isScheduleV3FlagEnabled && !!locationId,
      refetchOnMount: 'always' as const,
    }))
  );

  const locationWiseMappingStatus = useMemo<Record<string, boolean>>(() => {
    if (isScheduleV3FlagEnabled) {
      return selectedLocationIds.reduce<Record<string, boolean>>((acc, locationId) => {
        acc[locationId] = (listMappingsQueriesV3.data?.mappings ?? []).some(
          (mapping) =>
            mapping.locationId === locationId &&
            mapping.mappingType === MappingType.PROVIDER_APPOINTMENT_TYPE &&
            mapping.appointmentTypeMetaData?.id &&
            mapping.providerMetaData?.id
        );
        return acc;
      }, {});
    }

    return appointmentTypesQueriesV2.reduce<Record<string, boolean>>((acc, query) => {
      const currentLocationId = query.data?.data?.[0]?.locationId;
      if (currentLocationId) {
        const hasMappedProviders = !!query.data?.data?.some((appointmentType) =>
          appointmentType.providerAssets?.some((provider) => !!provider.available)
        );
        acc[currentLocationId] = hasMappedProviders;
      }
      return acc;
    }, {});
  }, [listMappingsQueriesV3, appointmentTypesQueriesV2, isScheduleV3FlagEnabled]);

  // Check if there is a mapping for any of the location
  const hasAnyLocationMapping = Object.values(locationWiseMappingStatus).includes(true);
  const isLoading = appointmentTypesQueriesV2.some((query) => query.isLoading) || listMappingsQueriesV3.isLoading;

  return {
    isLoading,
    hasAnyLocationMapping,
    locationWiseMappingStatus,
  };
};
