import { stateOptions, CountryCodes, CountryOption, countryOptions as allCountryOptions } from '@frontend/geography';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { DropdownField, FormRow, PostalCodeField, Text, TextField } from '@frontend/design-system';
import { useAddressForm } from './hooks';

type Props = {
  values: ReturnType<typeof useAddressForm>['addressFormProps']['values'];
  getFieldProps: ReturnType<typeof useAddressForm>['addressFormProps']['getFieldProps'];
  addressTitle?: string;
  addressLine1Label?: string;
  countryOptions?: CountryOption[];
  disabled?: boolean;
};

export const AddressForm = ({
  values,
  addressTitle,
  getFieldProps,
  addressLine1Label,
  countryOptions = allCountryOptions,
  disabled,
}: Props) => {
  const { t } = useTranslation('phoneProviderInformationForm');
  return (
    <>
      {addressTitle && (
        <Text weight='semibold' css={{ marginBottom: theme.spacing(2) }}>
          {addressTitle}
        </Text>
      )}
      <FormRow>
        <TextField
          {...getFieldProps('address1')}
          label={addressLine1Label ?? t('Street Address')}
          disabled={disabled}
        />
      </FormRow>
      <FormRow>
        <TextField
          {...getFieldProps('address2')}
          label={t('Apt, suite, etc. (optional)')}
          helperText={t('e.g. "Ste 42", "Apt 42" - not "Apt42" or "Ste42".')}
          disabled={disabled}
        />
      </FormRow>

      <FormRow cols={[50, 50]}>
        <TextField {...getFieldProps('city')} label={t('City')} disabled={disabled} />
        <DropdownField {...getFieldProps('state')} label={t('State')} disabled={disabled}>
          {stateOptions[(values.country as CountryCodes) || CountryCodes.USA]?.map((state) => (
            <DropdownField.Option key={state.value} value={state.value} disabled={disabled} searchValue={state.label}>
              {state.label}
            </DropdownField.Option>
          ))}
        </DropdownField>
      </FormRow>

      <FormRow cols={[50, 50]}>
        <PostalCodeField {...getFieldProps('postal')} label={t('Postal')} disabled={disabled} />
        <DropdownField {...getFieldProps('country')} label={t('Country')} disabled={disabled}>
          {countryOptions.map((country) => (
            <DropdownField.Option key={country.value} value={country.value} disabled={disabled}>
              {country.label}
            </DropdownField.Option>
          ))}
        </DropdownField>
      </FormRow>
    </>
  );
};
