import {
  Campaign,
  CampaignType_Enum as CampaignType,
  Status_Enum as CampaignStatus,
} from '@weave/schema-gen-ts/dist/schemas/messaging/bulk/v2';
import { DaysOfWeek } from '@weave/schema-gen-ts/dist/schemas/messaging/shared/v1/bulk.pb';
import { createShallowStore, createStoreWithSubscribe } from '@frontend/store';

// TODO: Rename members to match new Campaign schema better
type BulkEmailEditorState = {
  activeStep: number;
  attachments?: string[]; // TODO: Rename to attachmentMediaIds
  audienceCount?: number;
  campaignId: string;
  consent: boolean;
  currentStatus: CampaignStatus;
  enableValidation: boolean;
  locationIds: string[];
  sendDaysOfWeek: DaysOfWeek | null; // TODO: Rename to sendDays
  sendPerDay: number;
  startSendAt: string;
  subject: string;
  templateHtml: string;
  templateJson: string;
  thumbnailUrl: string;
  thumbnailImage: string;
  title: string;
};

type BulkEmailEditorActions = {
  getAsCampaign: () => Campaign;
  setActiveStep: (activeStep: BulkEmailEditorState['activeStep']) => void;
  setAttachments: (attachments: BulkEmailEditorState['attachments']) => void;
  setAudienceCount: (audienceCount: BulkEmailEditorState['audienceCount']) => void;
  setCampaignId: (campaignId: BulkEmailEditorState['campaignId']) => void;
  setConsent: (consent: BulkEmailEditorState['consent']) => void;
  setCurrentStatus: (currentStatus: BulkEmailEditorState['currentStatus']) => void;
  setEnableValidation: (enableValidation: BulkEmailEditorState['enableValidation']) => void;
  setFromCampaign: (campaign: Campaign) => void;
  setLocationIds: (locationIds: string[]) => void;
  setSendDaysOfWeek: (sendDaysOfWeek: BulkEmailEditorState['sendDaysOfWeek']) => void;
  setSendPerDay: (sendPerDay: BulkEmailEditorState['sendPerDay']) => void;
  setStartSendAt: (startSendAt: BulkEmailEditorState['startSendAt']) => void;
  setSubject: (subject: BulkEmailEditorState['subject']) => void;
  setTemplateHtml: (templateHtml: BulkEmailEditorState['templateHtml']) => void;
  setTemplateJson: (templateJson: BulkEmailEditorState['templateJson']) => void;
  setThumbnailImage: (thumbnailImage: BulkEmailEditorState['thumbnailImage']) => void;
  setTitle: (title: BulkEmailEditorState['title']) => void;
};

type BulkEmailEditorStore = BulkEmailEditorState & BulkEmailEditorActions;

export const useBulkEmailEditorStore = createStoreWithSubscribe<BulkEmailEditorStore>(
  (set, get) => {
    return {
      activeStep: 1,
      attachments: undefined,
      audienceCount: undefined,
      campaignId: '',
      consent: false,
      currentStatus: CampaignStatus.UNSPECIFIED,
      enableValidation: false,
      locationIds: [],
      sendDaysOfWeek: null,
      sendPerDay: 0,
      startSendAt: '',
      subject: '',
      templateHtml: '',
      templateJson: '',
      thumbnailUrl: '',
      thumbnailImage: '',
      title: '',

      getAsCampaign: () => {
        const store = get();
        const campaign: Campaign = {
          attachmentMediaIds: store.attachments,
          campaignId: store.campaignId,
          currentStatus: store.currentStatus,
          locationIds: store.locationIds,
          messageTemplate: store.templateHtml,
          sendDays: store.sendDaysOfWeek ?? undefined,
          sendPerDay: store.sendPerDay,
          startSendAt: store.startSendAt,
          subject: store.subject,
          templateJson: store.templateJson,
          title: store.title,
          type: CampaignType.EMAIL,
        };
        return campaign;
      },
      setActiveStep: (activeStep) => set((prevState) => ({ ...prevState, activeStep })),
      setAttachments: (attachments) => set((prevState) => ({ ...prevState, attachments })),
      setAudienceCount: (audienceCount) => set((prevState) => ({ ...prevState, audienceCount })),
      setConsent: (consent) => set((prevState) => ({ ...prevState, consent })),
      setCampaignId: (campaignId) => set((prevState) => ({ ...prevState, campaignId })),
      setCurrentStatus: (currentStatus) => set((prevState) => ({ ...prevState, currentStatus })),
      setEnableValidation: (enableValidation) => set((prevState) => ({ ...prevState, enableValidation })),
      setFromCampaign: (campaign) => {
        set({
          attachments: campaign.attachmentMediaIds,
          audienceCount: campaign.messageCounts?.total,
          campaignId: campaign.campaignId ?? '',
          currentStatus: campaign.currentStatus ?? CampaignStatus.UNSPECIFIED,
          locationIds: campaign.locationIds ?? [],
          sendDaysOfWeek: campaign.sendDays ?? null,
          sendPerDay: campaign.sendPerDay ?? 0,
          startSendAt: campaign.startSendAt ?? '',
          subject: campaign.subject ?? '',
          templateHtml: campaign.messageTemplate ?? '',
          templateJson: campaign.templateJson ?? '',
          thumbnailUrl: campaign.thumbnailUrl ?? '',
          thumbnailImage: '',
          title: campaign.title ?? '',
        });
      },
      setLocationIds: (locationIds) => set((prevState) => ({ ...prevState, locationIds })),
      setSendDaysOfWeek: (sendDaysOfWeek) => set((prevState) => ({ ...prevState, sendDaysOfWeek })),
      setSendPerDay: (sendPerDay) => set((prevState) => ({ ...prevState, sendPerDay })),
      setStartSendAt: (startSendAt) => set((prevState) => ({ ...prevState, startSendAt })),
      setSubject: (subject) => set((prevState) => ({ ...prevState, subject })),
      setTemplateHtml: (templateHtml) => set((prevState) => ({ ...prevState, templateHtml })),
      setTemplateJson: (templateJson) => set((prevState) => ({ ...prevState, templateJson })),
      setThumbnailImage: (thumbnailImage) => set((prevState) => ({ ...prevState, thumbnailImage })),
      setTitle: (title) => set((prevState) => ({ ...prevState, title })),
    };
  },
  { name: 'BulkEmailEditorStore' }
);

export const useBulkEmailEditorShallowStore = createShallowStore(useBulkEmailEditorStore);
