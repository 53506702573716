import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { Practitioner } from '@weave/schema-gen-ts/dist/schemas/schedule/v3/practitioner.pb';
import { i18next, useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Modal, ModalControlModalProps, Stepper } from '@frontend/design-system';
import { useAddPractitionerV3Context } from '../../context/AddPractitionerV3Context';
import { AddPractitionerStepperFirstStep } from './AddPractitionerStepper/AddPractitionerStepperFirstStep';
import { AddPractitionerStepperSecondStep } from './AddPractitionerStepper/AddPractitionerStepperSecondStep';

type AddPractitionerModalProps = {
  modalProps: ModalControlModalProps;
};

const stepTitleText = {
  1: i18next.t('Provider Details', { ns: 'schedule' }),
  2: i18next.t('Working Hours', { ns: 'schedule' }),
};

const modalWidth = 900;

export const AddPractitionerModal = ({ modalProps }: AddPractitionerModalProps) => {
  const { t } = useTranslation('schedule');
  const { setPractitionerDetails, onClose } = useAddPractitionerV3Context();
  const [isCreatePractitionerError, setIsCreatePractitionerError] = useState(false);

  useEffect(() => {
    return () => {
      setPractitionerDetails({} as Practitioner);
      setIsCreatePractitionerError(false);
    };
  }, []);

  return (
    <Modal {...modalProps} css={{ padding: 0, overflow: 'auto' }} minWidth={modalWidth} maxWidth={modalWidth}>
      <Modal.Body css={{ padding: theme.spacing(1), overflow: 'auto' }}>
        <Stepper stepTitleText={stepTitleText} isVerticalSingleView css={stepperStyle}>
          <Stepper.Card>
            <Modal.Header css={{ padding: theme.spacing(0, 0, 2, 0) }} onClose={onClose}>
              {t('Provider Details')}
            </Modal.Header>
            <AddPractitionerStepperFirstStep setIsCreatePractitionerError={setIsCreatePractitionerError} />
          </Stepper.Card>
          <Stepper.Card>
            <Modal.Header css={{ padding: theme.spacing(0, 0, 2, 0) }} onClose={onClose}>
              {t('Working Hours')}
            </Modal.Header>
            <AddPractitionerStepperSecondStep isCreatePractitionerError={isCreatePractitionerError} />
          </Stepper.Card>
        </Stepper>
      </Modal.Body>
    </Modal>
  );
};

const stepperStyle = css({
  margin: 0,
  width: '100%',
  maxWidth: 830,
  '.step-container > section': {
    border: 0,
    borderLeft: `1px solid ${theme.colors.neutral20}`,
    padding: theme.spacing(2),
    margin: 0,
    height: '100% !important',
  },
  '.stepper-vertical': {
    padding: `${theme.spacing(0.5)} ${theme.spacing(3)}`,
  },
});
