import { ReactNode } from 'react';
import { Heading, Text } from '@frontend/design-system';
import { useTranslation } from '@frontend/i18n';
import { SegmentedBar, SegmentedBarTypes } from '@frontend/segmented-bar';
import { theme } from '@frontend/theme';

type Props = {
  legendOptions: SegmentedBarTypes.Data[];
  title?: ReactNode;
};
export const TrayHeader = ({ legendOptions, title }: Props) => {
  const { t } = useTranslation('bulk-messaging');

  return (
    <header>
      <Heading level={2} css={{ marginBottom: theme.spacing(1) }}>
        {title ?? t('Message Allotments')}
      </Heading>
      <Text>
        {t(
          'Manage your location allotment(s) for this send. Bulk message allotments will replenish at the beginning of each month.'
        )}
      </Text>
      <SegmentedBar items={legendOptions} css={{ marginTop: theme.spacing(2) }}>
        <SegmentedBar.Legend />
      </SegmentedBar>
    </header>
  );
};
