import { ParseResult, parse } from 'papaparse';
import { useTranslation } from '@frontend/i18n';
import { useAlert } from '@frontend/design-system';

export const useCsvParse = <T>() => {
  const { t } = useTranslation('bulk-messaging');
  const alert = useAlert();

  return (files: File[]) => {
    return new Promise<ParseResult<T>>((resolve, reject) => {
      if (files[0]) {
        parse<T>(files[0], {
          header: true,
          transformHeader: (header) => header.toLowerCase().replace(/\s/g, ''),
          skipEmptyLines: true,
          complete: (results) => {
            resolve(results);
          },
          error: reject,
        });
      } else {
        reject(new Error(t('Please upload a CSV file.')));
      }
    }).catch((error) => {
      alert.error(error.message);
    });
  };
};
