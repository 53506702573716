import { Link, Text } from '@react-email/components';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { useComposerSection, useLinks } from '../../hooks';
import { ComposerSectionOutputProps, FooterLink, FooterSectionProps, UsableComposerSectionProps } from '../../types';
import { getPaddingStyles } from '../../utils/get-padding-styles';
import { EmailSvg } from '../assets/email-svg';
import { FacebookSvg } from '../assets/facebook-svg';
import { GoogleSvg } from '../assets/google-svg';
import { PhoneSvg } from '../assets/phone-svg';
import { useContentComposer } from '../content-composer-provider';
import { frameColorStyle } from './styles';

// Required fields for the email footer: unsubscribe link, business address and the preference center link
// This is to help prevent the email from being marked as spam
export const FooterOutput = ({ renderMode }: ComposerSectionOutputProps) => {
  const { t } = useTranslation('content-composer');
  const { props } = useComposerSection<FooterSectionProps>();
  const { composerMode } = useContentComposer();

  const { address, businessName, formatLink, managementUrl, oneClickUrl } = useLinks();

  const textAlign = props.frameAlignment === 'left' ? 'left' : props.frameAlignment === 'right' ? 'right' : 'center';

  const linkStyles = {
    color: props.textColor,
    fontSize: props.textSize,
    fontStyle: props.italic ? 'italic' : 'normal',
    fontFamily: props.fontType,
    fontWeight: props.bold ? theme.font.weight.bold : theme.font.weight.regular,
  };

  return (
    <div style={{ ...createFrameStyle(props) }}>
      {props?.links && props.links.length > 0 && (
        <div
          style={{
            textAlign,
            marginBottom: theme.spacing(1),
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: theme.spacing(3),
          }}
        >
          {props.links.map((link: FooterLink, index: number, arr: FooterLink[]) => {
            const marginRight = index === arr.length - 1 ? 0 : theme.spacing(1);
            return (
              <Link
                key={link.text}
                href={formatLink(link.link, link.type, renderMode)}
                style={{
                  ...linkStyles,
                  verticalAlign: link.type === 'custom' ? 'super' : 'initial',
                  marginRight,
                  width: link.type !== 'custom' ? theme.spacing(3) : 'auto',
                  height: link.type !== 'custom' ? theme.spacing(3) : 'auto',
                }}
              >
                {link.type === 'custom' && link.text}
                {link.type === 'email' && <EmailSvg fillColor={props.textColor} />}
                {link.type === 'phone' && <PhoneSvg fillColor={props.textColor} />}
                {link.type === 'facebook' && <FacebookSvg fillColor={props.textColor} />}
                {link.type === 'google' && <GoogleSvg fillColor={props.textColor} />}
              </Link>
            );
          })}
        </div>
      )}
      <div style={{ textAlign, marginBottom: theme.spacing(1) }}>
        {/* TODO: figure out how we want to handle this better since there are P.O. Boxes that we wouldn't want end users to be able to link to. */}
        <span style={linkStyles}>{renderMode === 'preview' ? address : '{{BUSINESS_GROUP_ADDRESS}}'}</span>
      </div>
      <div style={{ textAlign }}>
        <p style={linkStyles}>{renderMode === 'preview' ? businessName : '{{BUSINESS_GROUP_NAME}}'}</p>
        {composerMode === 'marketing' && (
          <>
            <Link href={renderMode === 'preview' ? oneClickUrl : '{{UNSUBSCRIBE_LINK}}'} style={linkStyles}>
              {t('Unsubscribe')}
            </Link>
            <Text style={{ margin: theme.spacing(0, 1), display: 'inline-block', color: props.textColor }}>|</Text>
            <Link href={renderMode === 'preview' ? managementUrl : '{{PREFERENCE_MANAGEMENT_LINK}}'} style={linkStyles}>
              {t('Preference Center')}
            </Link>
          </>
        )}
      </div>
    </div>
  );
};

const createFrameStyle = (props: UsableComposerSectionProps<FooterSectionProps>) => ({
  ...frameColorStyle(props),
  ...getPaddingStyles(props),
});
