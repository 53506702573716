import { useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { LocationFilterMenu } from '@frontend/location-filter-menu';
import { formatPhoneNumber, removeNonDigits } from '@frontend/phone-numbers';
import { PickerLocation, useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { Table, Chip, SearchField, FormRow, SwitchField, useForm } from '@frontend/design-system';
import { PhoneNumber } from '../../views/settings/types';

export const PhoneNumbersTable = ({
  tenantLocation,
  phoneNumbers,
  selectedPhoneNumberIds,
  trackingIdBase,
  setSelectedPhoneNumberIds,
}: {
  tenantLocation: PickerLocation;
  phoneNumbers: PhoneNumber[];
  selectedPhoneNumberIds: string[];
  trackingIdBase?: string;
  setSelectedPhoneNumberIds: (phoneNumbers: string[]) => void;
}) => {
  const { t } = useTranslation('phone');
  const { getLocationName } = useAppScopeStore();

  const [filteredLocations, setFilteredLocations] = useState<string[]>(() => {
    // only show locations that have phone numbers
    const uniqueLocations = new Set(phoneNumbers.map((phoneNumber) => phoneNumber.locationId));
    return Array.from(uniqueLocations);
  });

  const form = useForm({
    fields: {
      search: {
        type: 'text',
        debounce: 300,
      },
      showSelected: {
        type: 'switch',
      },
    },
  });

  const filteredTableData = useMemo(() => {
    let filteredData = phoneNumbers;

    if (filteredLocations.length) {
      filteredData = phoneNumbers.filter((row) => filteredLocations.includes(row.locationId));
    }

    const value = form.values.search?.trim();
    if (!value) {
      return filteredData;
    }

    return filteredData.filter((row) => String(row.phoneNumber?.nationalNumber)?.includes(removeNonDigits(value)));
  }, [phoneNumbers, form.values.search, filteredLocations]);

  const hasChildLocations = Boolean(tenantLocation.children?.length);

  return (
    <>
      <div>
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            & > div {
              display: inline-block;
            }
          `}
        >
          {hasChildLocations && (
            <>
              <LocationFilterMenu
                tenantLocation={tenantLocation}
                initialFilteredLocations={filteredLocations}
                setFilteredLocations={setFilteredLocations}
                trackingIdBase={trackingIdBase ? trackingIdBase + '-location-filter' : undefined}
              />
              <SearchField
                label={t('Search Phone Numbers')}
                {...form.getFieldProps('search')}
                css={css`
                  width: 260px;
                `}
                data-trackingid={trackingIdBase ? trackingIdBase + '-search' : undefined}
              />
            </>
          )}
        </div>

        <FormRow>
          <SwitchField
            {...form.getFieldProps('showSelected')}
            name='showSelected'
            label={t('Show Selected Numbers')}
            trackingId={trackingIdBase ? trackingIdBase + '-show-selected-toggle' : undefined}
            css={css`
              display: flex;
              flex-direction: row-reverse;
              gap: ${theme.spacing(1)};
              margin-top: ${theme.spacing(2)};
            `}
          />
        </FormRow>
      </div>

      <Table
        data={filteredTableData}
        isSelectable={true}
        isPaginated={true}
        globalTrackingId={trackingIdBase ? trackingIdBase + '-table' : undefined}
        colConfig={[
          {
            Header: t('Phone Number'),
            accessor: (phoneNumber) => phoneNumber.phoneNumber.nationalNumber,
            id: 'phone-number',
            cellRenderer: (phoneNumber) => formatPhoneNumber(phoneNumber),
            width: 180,
          },
          {
            Header: t('Location'),
            accessor: (phoneNumber) => phoneNumber.locationId,
            cellRenderer: (locationId) =>
              locationId ? <Chip.SingleChip>{getLocationName(locationId)}</Chip.SingleChip> : '',
            id: 'location-name',
            sortType: 'caseInsensitive',
            width: 190,
            omit: !hasChildLocations,
          },
        ]}
        rowSelectionConfig={{
          hideBulkSelection: true,
          onSelectionToggle: (_, selectedData) => {
            setSelectedPhoneNumberIds(selectedData.map((phoneNumber) => phoneNumber.id));
          },
          initialState: Object.fromEntries(
            filteredTableData.map((phoneNumber, index) => [index, selectedPhoneNumberIds.includes(phoneNumber.id)])
          ),
        }}
      />
    </>
  );
};
