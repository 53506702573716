import { MessageAction_index } from '@weave/schema-gen-ts/dist/schemas/websocket-director/v1/websocket.pb';
import { WebsocketEventPayload } from '../events';

export type HeartbeatState = {
  connectAttemptStartedAt: number | null;
  lastHeartbeatAt: number | null;
  lastPingSentAt: number | null;
  reconnectWaitInterval: number;
  inReconnectLoop: boolean;
  refreshTokenStartedAt: number | null;
};

export enum WebsocketReadyState {
  CONNECTING,
  OPEN,
  CLOSING,
  CLOSED,
}

export type GetWebsocketProps = {
  url: string;
  onMessage: (payload: WebsocketEventPayload) => void;
  heartbeatRef: HeartbeatState;
};

export type ServerMessageActionIndex = {
  action: MessageAction_index;
  payload?: string;
  trace_id?: string;
};

export enum WebsocketState {
  WAITING_FOR_URL = 'WAITING_FOR_URL',
  CONNECTING = 'CONNECTING',
  HEALTHY_CONNECTION = 'HEALTHY_CONNECTION',
  NEED_TO_SEND_PING = 'NEED_TO_SEND_PING',
  WAITING_FOR_PONG = 'WAITING_FOR_PONG',
  NEED_TO_REFRESH_TOKEN = 'NEED_TO',
  NEED_TO_RECONNECT = 'NEED_TO_RECONNECT',
  RECONNECTING = 'RECONNECTING',
}

export type ConnectProps = {
  url: string;
  onMessage: (payload: WebsocketEventPayload) => void;
  heartbeatState: HeartbeatState;
  reevaluateCurrentState: () => void;
  userId: string;
};

export type WebsocketWithSubscriptions = WebSocket & {
  subscribedLocations: string[];
  connectedDeviceSipId?: string;
};
