import { useEffect, useMemo } from 'react';
import { css } from '@emotion/react';
import { useNavigate } from '@tanstack/react-location';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { humanizeDateDifference } from '@frontend/shared';
import { theme } from '@frontend/theme';
import { ContentLoader } from '@frontend/design-system';
import { URLs } from '../../../constants';
import { useAnalyticsOrgLocations, useTimePeriodPopoverSelector } from '../../../hooks';
import { trackingIds } from '../../../tracking-ids';
import { CheckListPopoverSelector } from '../../filter-selectors';
import { ROIFilters, useROIShallowStore } from '../hooks';

interface Props {
  isLoading?: boolean;
}

export const Filters = ({ isLoading }: Props) => {
  const { t } = useTranslation('analytics');
  const { selectedLocationIds } = useAppScopeStore();
  const navigate = useNavigate();
  const { isLoadingLocations, isMultiLocation, locationIds, locationNames } = useAnalyticsOrgLocations({
    module: 'ROI',
  });

  const {
    filters,
    resetStore,
    selectedRoiLocationIds = [],
    setDefaultFilters,
    setFilterHintText,
    setFilters,
    setSelectedRoiLocationIds,
  } = useROIShallowStore(
    'filters',
    'resetStore',
    'selectedRoiLocationIds',
    'setDefaultFilters',
    'setFilterHintText',
    'setFilters',
    'setSelectedRoiLocationIds'
  );

  const joinedSelectedLocationIds = selectedLocationIds.sort().join(',');
  const joinedSelectedRoiLocationIds = selectedRoiLocationIds.sort().join(',');
  const filteredLocationsCount = filters?.locationIds?.length || 0;

  const { defaultEndDate, defaultStartDate, selectedPeriod, TimePeriodPopoverSelector, timePeriods } =
    useTimePeriodPopoverSelector({
      values: {
        startDate: filters?.startDate,
        endDate: filters?.endDate,
      },
    });

  const defaultFilters = useMemo(
    (): ROIFilters => ({
      endDate: defaultEndDate,
      locationIds,
      startDate: defaultStartDate,
    }),
    [defaultEndDate, defaultStartDate, locationIds?.sort().join(',')]
  );

  const applyDefaultFilters = () => {
    // Also used for resetting filters whenever required
    setDefaultFilters(defaultFilters);
    setFilters(defaultFilters);
  };

  useEffect(() => {
    // This text is displayed in sub view pages and charts to indicate the currently applied filters
    if (filters?.startDate && filters.endDate) {
      setFilterHintText(
        selectedPeriod
          ? timePeriods[selectedPeriod].label
          : humanizeDateDifference(filters?.startDate, filters?.endDate)
      );
    }
  }, [filters, selectedPeriod, timePeriods]);

  useEffect(() => {
    if (!defaultFilters.startDate || !defaultFilters.endDate || !defaultFilters.locationIds?.length) {
      return;
    }

    if (joinedSelectedRoiLocationIds) {
      if (joinedSelectedLocationIds !== joinedSelectedRoiLocationIds) {
        // Location IDs has changed, reset the store and set new location ids
        resetStore();
        setSelectedRoiLocationIds(selectedLocationIds.sort());

        // Let's apply the default filters again as we have reset the store
        applyDefaultFilters();

        // If single location is selected, then navigate to the main dashboard
        if (selectedLocationIds.length === 1) {
          navigate({
            to: URLs.ROI_MISSED_CALL_TEXT,
          });
        }
      } else {
        // If we have previously applied filters, then retain them
        // Do not add dependency for `filters` as we only want to apply any exsiting filters before appling the default filters when page loads
        // This comes handy when navigating between pages and need to retain the filters
        const hasExistingFilters = Object.values(filters || {}).find((value) => {
          if (Array.isArray(value)) {
            return value.length > 0;
          }
          return !!value;
        });

        if (hasExistingFilters) {
          // It will retain the existing filters
          return;
        }

        applyDefaultFilters();
      }
    } else {
      // Location IDs and filters were never set, hence set them for the first time
      applyDefaultFilters();
      setSelectedRoiLocationIds(selectedLocationIds.sort());
    }
  }, [defaultFilters, joinedSelectedLocationIds, joinedSelectedRoiLocationIds]);

  return (
    <div css={styles.wrapper}>
      {isMultiLocation && (
        <CheckListPopoverSelector<string>
          defaultValue={defaultFilters.locationIds}
          disabled={isLoading}
          iconName='location-small'
          label={
            !filteredLocationsCount || filteredLocationsCount > 1
              ? t('{{filteredLocationsCount}} Locations', { filteredLocationsCount })
              : t('1 Location')
          }
          onChange={(locationIds) => {
            setFilters({ locationIds });
          }}
          options={locationNames}
          value={filters?.locationIds}
          trackingId={trackingIds.roi.missedCallTextLocationPopoverFilter}
        />
      )}

      <TimePeriodPopoverSelector
        disabled={isLoading}
        onChange={({ startDate, endDate }) => {
          setFilters({ startDate, endDate });
        }}
        trackingIdBase={trackingIds.roi.missedCallTextTimePopoverFilter}
      />

      <ContentLoader show={isLoadingLocations} size='small' />
    </div>
  );
};

const styles = {
  wrapper: css`
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: ${theme.spacing(2)};
    position: relative;
  `,
};
