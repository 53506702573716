import { Permission } from '@weave/schema-gen-ts/dist/shared/waccess/acls.pb';

export const flags = {
  bulkTexting: 'commx-bulk-messaging',
  bulkTextingV2: 'commx-bulk-messaging-v2-api',
  emailMarketing: 'commx-email-marketing-v2',
};

export const permissions = {
  bulkTexting: Permission.BULK_TEXTING_MANAGE,
  emailMarketing: Permission.EMAIL_MARKETING_MANAGE,
};
