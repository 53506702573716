import { useMemo } from 'react';
import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { CustomizationFlagQueries, CustomizationFlagTypes } from '@frontend/api-customization-flags';
import { getUser } from '@frontend/auth-helpers';
import { useAppScopeStore } from '@frontend/scope';
import { useCallIntelShallowStore } from './use-call-intel-store';
import { useIsCallIntelEligibleAccount } from './use-is-call-intel-eligible-account';

export const useIsCallIntelDemoAccount = () => {
  const { selectedLocationIdsWithParents } = useAppScopeStore();
  const { isCallIntelEligibleAccount } = useIsCallIntelEligibleAccount();
  const { isDemoModal } = useCallIntelShallowStore('isDemoModal');
  const isWeaveDemoUser = getUser()?.username.toLowerCase().includes('@demoweave');

  // Fetch customization flags based on selected locations
  const { data: locationWiseCustomizationFlags, isLoading } =
    CustomizationFlagQueries.useMultiCustomizationFlags(selectedLocationIdsWithParents);

  const isCallIntelActive = useMemo(() => {
    if (!selectedLocationIdsWithParents || isLoading || !locationWiseCustomizationFlags?.locationsFeatures) {
      return false;
    }

    return Object.keys(locationWiseCustomizationFlags.locationsFeatures ?? {}).some((locationId) =>
      locationWiseCustomizationFlags.locationsFeatures?.[locationId]?.features?.some(
        (feature) =>
          feature.featureEnum === Feature.CALL_INTELLIGENCE &&
          (feature.state === CustomizationFlagTypes.CustomizationFlagState.ACTIVE ||
            feature.state === CustomizationFlagTypes.CustomizationFlagState.PROMOTE)
      )
    );
  }, [isLoading, locationWiseCustomizationFlags, selectedLocationIdsWithParents]);

  if (isWeaveDemoUser) {
    // Demo users can use any location for demo purpose
    return true;
  }

  if (isDemoModal) {
    // Show demo data when the user has opened the demo modal
    return true;
  }

  if (isLoading) return false;

  if (isCallIntelEligibleAccount && !isCallIntelActive) {
    // Show demo data to all users when an eligible location has not activated the feature
    return true;
  }

  return false;
};
