export const NodeType = {
  Start: 'start',
  Boolean: 'boolean',
  ForwardDevice: 'forwardDevice',
  ForwardNumber: 'forwardNumber',
  VoicemailBox: 'voicemailBox',
  VoicemailPrompt: 'voicemailPrompt',
  Terminate: 'terminate',
  CallGroup: 'callGroup',
  CallQueue: 'callQueue',
  PhoneTree: 'phoneTree',
  Repeat: 'repeat',
  TreeOption: 'treeOption',
  PlayMessage: 'playMessage',
  OfficeHours: 'officeHours',
} as const;

export const EdgeType = {
  Basic: 'basic',
  ToTarget: 'toTarget',
  FromSource: 'fromSource',
  TreeOption: 'treeOption',
} as const;

type BaseNodeData = {
  /** A unique ID for the node (frontend generated) */
  id: string;
  position?: {
    x: number;
    y: number;
  };
};

export type DataPayload = {
  /** The ID of the node returned from the API */
  id: string;
  label: string;
};

type SimpleNodeData = BaseNodeData & {
  type: typeof NodeType.Start | typeof NodeType.Terminate;
  item: DataPayload;
};

type PayloadNodeData = BaseNodeData & {
  type:
    | typeof NodeType.VoicemailPrompt
    | typeof NodeType.CallGroup
    | typeof NodeType.PhoneTree
    | typeof NodeType.TreeOption
    | typeof NodeType.PlayMessage
    | typeof NodeType.Boolean
    | typeof NodeType.Repeat
    | typeof NodeType.OfficeHours
    | typeof NodeType.ForwardDevice
    | typeof NodeType.ForwardNumber
    | typeof NodeType.VoicemailBox
    | typeof NodeType.CallQueue;
  item: DataPayload;
};

export type NodeData = SimpleNodeData | PayloadNodeData;

export type EdgeData = {
  id: string;
  source: string;
  target: string;
  label?: string;
  type?: typeof EdgeType.Basic | typeof EdgeType.ToTarget | typeof EdgeType.FromSource | typeof EdgeType.TreeOption;
  originalType?:
    | typeof EdgeType.Basic
    | typeof EdgeType.ToTarget
    | typeof EdgeType.FromSource
    | typeof EdgeType.TreeOption;
};

const nodes: NodeData[] = [
  {
    id: 'set-1-1',
    type: NodeType.Start,
    position: { x: 0, y: 0 },
    item: {
      id: 'start',
      label: 'Start',
    },
  },
  {
    id: 'set-1-2',
    type: NodeType.Boolean,
    position: { x: 0, y: 100 },
    item: {
      id: 'd161a436',
      label: 'Skip Condition',
    },
  },
  {
    id: 'set-1-3',
    type: NodeType.VoicemailPrompt,
    position: { x: 0, y: 200 },
    item: {
      id: 'vm-1',
      label: 'Voicemail Prompt: General',
    },
  },
  {
    id: 'set-1-4',
    type: NodeType.ForwardNumber,
    position: { x: 0, y: 300 },
    item: {
      id: 'vm-1',
      label: 'Forwarding Number: 1234567890',
    },
  },
  {
    id: 'set-1-5',
    type: NodeType.PlayMessage,
    position: { x: 0, y: 400 },
    item: {
      id: 'play-1',
      label: 'Play: Breaking the Habit.wav',
    },
  },
  {
    id: 'set-1-6',
    type: NodeType.VoicemailPrompt,
    position: { x: 0, y: 500 },
    item: {
      id: 'vm-1',
      label: 'Voicemail Prompt: General',
    },
  },
  {
    id: 'set-1-7',
    type: NodeType.CallQueue,
    position: { x: 0, y: 600 },
    item: {
      id: 'cq-1',
      label: 'Call Queue: My Queue',
    },
  },
  {
    id: 'set-1-8',
    type: NodeType.PlayMessage,
    position: { x: 0, y: 700 },
    item: {
      id: 'play-2',
      label: 'Play: fearless.m4a',
    },
  },
  {
    id: 'set-1-9',
    type: NodeType.Terminate,
    position: { x: 0, y: 800 },
    item: {
      id: 'terminate-1',
      label: 'Hangup',
    },
  },
  {
    id: 'set-1-10',
    type: NodeType.CallGroup,
    position: { x: 0, y: 900 },
    item: {
      id: 'cg-2',
      label: 'Call Group: Default Ring',
    },
  },
  {
    id: 'set-1-11',
    type: NodeType.Terminate,
    position: { x: 0, y: 1000 },
    item: {
      id: 'terminate-2',
      label: 'Hangup',
    },
  },
  {
    id: 'set-1-12',
    type: NodeType.VoicemailPrompt,
    position: { x: 0, y: 1100 },
    item: {
      id: 'vm-1',
      label: 'Voicemail Prompt: General',
    },
  },
  {
    id: 'set-1-13',
    type: NodeType.VoicemailPrompt,
    position: { x: 0, y: 1200 },
    item: {
      id: 'vm-1',
      label: 'Voicemail Prompt: General',
    },
  },
  {
    id: 'set-1-14',
    type: NodeType.Boolean,
    position: { x: 0, y: 1300 },
    item: {
      id: 'd161a436',
      label: 'Forward Condition',
    },
  },
  {
    id: 'set-1-15',
    type: NodeType.ForwardDevice,
    position: { x: 0, y: 1400 },
    item: {
      id: 'forwarding-1',
      label: 'Forward: 940a049c-d6bf-426b-8543-1223be1675f6',
    },
  },
  {
    id: 'set-1-16',
    type: NodeType.OfficeHours,
    position: { x: 0, y: 1500 },
    item: {
      id: 'schedule-1',
      label: 'Schedule Routing',
    },
  },
  {
    id: 'set-1-17',
    type: NodeType.Terminate,
    position: { x: 0, y: 1600 },
    item: {
      id: 'terminate-3',
      label: 'Hangup',
    },
  },
  {
    id: 't6',
    type: NodeType.PhoneTree,
    position: { x: 0, y: 500 },
    item: {
      id: 'pt-1',
      label: 'Main Phone Tree',
    },
  },
  {
    id: 't7',
    type: NodeType.CallGroup,
    position: { x: 0, y: 600 },
    item: {
      id: 'cg-2',
      label: 'My Call Group',
    },
  },
  {
    id: 't8',
    type: NodeType.CallGroup,
    position: { x: 0, y: 700 },
    item: {
      id: 'cg-3',
      label: 'Another Call Group',
    },
  },
  {
    id: 't9',
    type: NodeType.VoicemailPrompt,
    position: { x: 0, y: 800 },
    item: {
      id: 'vm-2',
      label: 'Another Voicemail',
    },
  },
];

const edges: EdgeData[] = [
  { id: 'e1', source: 'set-1-2', target: 'set-1-8', label: 'Enabled' },
  { id: 'e2', source: 'set-1-8', target: 'set-1-13' },
  { id: 'e3', source: 'set-1-14', target: 'set-1-15', label: 'Disabled' },
  { id: 'e4', source: 'set-1-2', target: 'set-1-14', label: 'Disabled' },
  { id: 'e5', source: 'set-1-16', target: 'set-1-5', label: 'd161a436 - Games with Team' },
  { id: 'e6', source: 'set-1-5', target: 'set-1-3' },
  { id: 'e7', source: 'set-1-3', target: 'set-1-9' },
  { id: 'e8', source: 'set-1-14', target: 'set-1-16', label: 'Enabled' },
  { id: 'e9', source: 'set-1-16', target: 'set-1-10', label: 'd161a436 - Company Lunch' },
  { id: 'e10', source: 'set-1-10', target: 'set-1-6' },
  { id: 'e11', source: 'set-1-6', target: 'set-1-17' },
  { id: 'e12', source: 'set-1-16', target: 't6', label: 'd161a436 - Open' },
  { id: 'e13', source: 'set-1-7', target: 'set-1-4' },
  { id: 'e14', source: 'set-1-4', target: 'set-1-11' },
  { id: 'e15', source: 'set-1-16', target: 'set-1-12', label: 'd161a436 - Closed' },
  { id: 'e16', source: 'set-1-1', target: 'set-1-2' },
  { id: 'e18', source: 't6', target: 't7', label: '1', type: EdgeType.TreeOption },
  { id: 'e19', source: 't6', target: 't8', label: '2', type: EdgeType.TreeOption },
  { id: 'e20', source: 't6', target: 't9', label: '3', type: EdgeType.TreeOption },
];

export const data = {
  nodes: nodes,
  edges: edges,
};

const nodes2: NodeData[] = [
  {
    id: '-1',
    type: NodeType.Start,
    position: { x: 0, y: 0 },
    item: {
      id: 'start',
      label: 'Incoming Call',
    },
  },
  {
    id: '-2',
    type: NodeType.Boolean,
    position: { x: 0, y: 100 },
    item: {
      id: 'dda8d21a',
      label: 'Skip Condition',
    },
  },
  {
    id: '-3',
    type: NodeType.Boolean,
    position: { x: 0, y: 200 },
    item: {
      id: 'dda8d21a',
      label: 'Forward Condition',
    },
  },
  {
    id: '-4',
    type: NodeType.VoicemailPrompt,
    position: { x: 0, y: 300 },
    item: {
      id: 'vm-1',
      label: 'Voicemail Prompt: General',
    },
  },
  {
    id: '-5',
    type: NodeType.Terminate,
    position: { x: 0, y: 400 },
    item: {
      id: 'terminate-1',
      label: 'Hangup',
    },
  },
  {
    id: '-6',
    type: NodeType.CallGroup,
    position: { x: 0, y: 500 },
    item: {
      id: 'cg-1',
      label: 'Call Group: Default Ring',
    },
  },
  {
    id: '-7',
    type: NodeType.CallGroup,
    position: { x: 0, y: 600 },
    item: {
      id: 'cg-2',
      label: 'Call Group: Default Ring',
    },
  },
  {
    id: '-8',
    type: NodeType.VoicemailPrompt,
    position: { x: 0, y: 700 },
    item: {
      id: 'vm-2',
      label: 'Voicemail Prompt: General',
    },
  },
  {
    id: '-9',
    type: NodeType.PhoneTree,
    position: { x: 0, y: 800 },
    item: {
      id: 'ivr-1',
      label: 'IvrMenu: Nested IVR',
    },
  },
  {
    id: '-10',
    type: NodeType.CallGroup,
    position: { x: 0, y: 900 },
    item: {
      id: 'cg-3',
      label: 'Call Group: Default Ring',
    },
  },
  {
    id: '-11',
    type: NodeType.VoicemailPrompt,
    position: { x: 0, y: 1000 },
    item: {
      id: 'vm-3',
      label: 'Voicemail Prompt: General',
    },
  },
  {
    id: '-12',
    type: NodeType.PhoneTree,
    position: { x: 0, y: 1100 },
    item: {
      id: 'ivr-2',
      label: 'IvrMenu: NestedNested IVR',
    },
  },
  {
    id: '-13',
    type: NodeType.VoicemailPrompt,
    position: { x: 0, y: 1200 },
    item: {
      id: 'vm-4',
      label: 'Voicemail Prompt: General',
    },
  },
  {
    id: '-14',
    type: NodeType.VoicemailPrompt,
    position: { x: 0, y: 1300 },
    item: {
      id: 'vm-5',
      label: 'Voicemail Prompt: General',
    },
  },
  {
    id: '-15',
    type: NodeType.VoicemailPrompt,
    position: { x: 0, y: 1400 },
    item: {
      id: 'vm-6',
      label: 'Voicemail Prompt: General',
    },
  },
  {
    id: '-16',
    type: NodeType.OfficeHours,
    position: { x: 0, y: 1500 },
    item: {
      id: 'schedule-1',
      label: 'Schedule Routing',
    },
  },
  {
    id: '-17',
    type: NodeType.PhoneTree,
    position: { x: 0, y: 1600 },
    item: {
      id: 'ivr-3',
      label: 'IvrMenu: TopNestedIVR',
    },
  },
  {
    id: '-18',
    type: NodeType.CallGroup,
    position: { x: 0, y: 1700 },
    item: {
      id: 'cg-4',
      label: 'Call Group: Default Ring',
    },
  },
];

const edges2: EdgeData[] = [
  {
    id: 'e21',
    source: '-2',
    target: '-15',
    label: 'Disabled',
  },
  {
    id: 'e22',
    source: '-3',
    target: '-8',
    label: 'Disabled',
  },
  {
    id: 'e23',
    source: '-2',
    target: '-3',
    label: 'Enabled',
  },
  {
    id: 'e24',
    source: '-16',
    target: '-17',
    label: 'dda8d21a - Open',
  },
  {
    id: 'e25',
    source: '-17',
    target: '-9',
    label: '1',
    type: EdgeType.TreeOption,
  },
  {
    id: 'e26',
    source: '-9',
    target: '-10',
    label: '6',
    type: EdgeType.TreeOption,
  },
  {
    id: 'e27',
    source: '-10',
    target: '-4',
  },
  {
    id: 'e28',
    source: '-9',
    target: '-12',
    label: '7',
    type: EdgeType.TreeOption,
  },
  {
    id: 'e29',
    source: '-12',
    target: '-18',
    label: '5',
    type: EdgeType.TreeOption,
  },
  {
    id: 'e30',
    source: '-18',
    target: '-11',
  },
  {
    id: 'e31',
    source: '-12',
    target: '-12',
    label: '4',
    type: EdgeType.TreeOption,
  },
  {
    id: 'e32',
    source: '-12',
    target: '-9',
    label: '1',
    type: EdgeType.TreeOption,
  },
  {
    id: 'e33',
    source: '-12',
    target: '-17',
    label: '3',
    type: EdgeType.TreeOption,
  },
  {
    id: 'e34',
    source: '-9',
    target: '-9',
    label: '3',
    type: EdgeType.TreeOption,
  },
  {
    id: 'e35',
    source: '-9',
    target: '-17',
    label: '1/2',
    type: EdgeType.TreeOption,
  },
  {
    id: 'e36',
    source: '-17',
    target: '-13',
    label: '2',
    type: EdgeType.TreeOption,
  },
  {
    id: 'e37',
    source: '-17',
    target: '-6',
    label: 'Default',
    type: EdgeType.TreeOption,
  },
  {
    id: 'e38',
    source: '-6',
    target: '-5',
  },
  {
    id: 'e39',
    source: '-3',
    target: '-16',
    label: 'Enabled',
  },
  {
    id: 'e40',
    source: '-16',
    target: '-7',
    label: 'dda8d21a - Closed',
  },
  {
    id: 'e41',
    source: '-7',
    target: '-14',
  },
  {
    id: 'e42',
    source: '-1',
    target: '-2',
  },
];

export const data2 = {
  nodes: nodes2,
  edges: edges2,
};
