import { Practitioner } from '@weave/schema-gen-ts/dist/schemas/schedule/v3/practitioner.pb';
import { SchedulerV3 } from '@frontend/api-schedule-v3';
import { i18next } from '@frontend/i18n';
import { PractitionerDetailsV3 } from '../views/ManagePractitionersV3/PractitionerDetails/PractitionerDetailsV3';

type EditPractitionerDetailsProps = {
  locationId: string;
  practitionerId?: string;
  onSave?: () => void;
  onCancel?: () => void;
  onErrors?: (err: string) => void;
  primaryButtonLabel?: string;
  secondaryButtonLabel?: string;
  shouldRenderSecondaryButton?: boolean;
  isStepperComponent?: boolean;
  setPractitionerDetailsOnCreate?: (practitionerDetails: Practitioner) => void;
};

type EditPractitionerDetailsReturnType = {
  PractitionerDetails: JSX.Element;
};

export const usePractitionerDetails = ({
  locationId,
  practitionerId,
  onSave,
  onCancel,
  onErrors,
  primaryButtonLabel = i18next.t('Save', { ns: 'schedule' }),
  secondaryButtonLabel = i18next.t('Cancel', { ns: 'schedule' }),
  shouldRenderSecondaryButton = true,
  isStepperComponent = false,
  setPractitionerDetailsOnCreate,
}: EditPractitionerDetailsProps): EditPractitionerDetailsReturnType => {
  const { data, isLoading } = SchedulerV3.Queries.useGetProviderQuery({
    request: { id: practitionerId ?? '' },
    options: {
      enabled: !!practitionerId,
    },
  });

  return {
    PractitionerDetails: (
      <PractitionerDetailsV3
        practitionerDetails={data?.provider || ({} as Practitioner)}
        locationId={locationId}
        practitionerId={practitionerId}
        onSave={onSave}
        onCancel={onCancel}
        primaryButtonLabel={primaryButtonLabel}
        secondaryButtonLabel={secondaryButtonLabel}
        isLoading={isLoading}
        shouldRenderSecondaryButton={shouldRenderSecondaryButton}
        isStepperComponent={isStepperComponent}
        setPractitionerDetailsOnCreate={setPractitionerDetailsOnCreate}
        onErrors={onErrors}
      />
    ),
  };
};
