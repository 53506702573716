import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const barStyles = (isLarge: boolean) =>
  isLarge
    ? css`
        height: ${theme.spacing(6)};
        background: ${theme.colors.neutral10};
        border-radius: ${theme.borderRadius.small};
        margin-bottom: ${theme.spacing(1.5)};
      `
    : css`
        margin: ${theme.spacing(0.5, 0, 1)};
      `;
