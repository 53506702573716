import { css } from '@emotion/react';
import { EventType } from '@weave/schema-gen-ts/dist/schemas/insys/onboarding/v1/onboarding-tasks/onboarding_tasks.pb';
import { OnboardingModulesApi, OnboardingModulesTypes } from '@frontend/api-onboarding-modules';
import { getUser } from '@frontend/auth-helpers';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { InfoText, TRACKING_IDS, TaskHeader, useTaskTrayShallowStore } from '@frontend/task-tray';
import { theme } from '@frontend/theme';
import { Accordion, PrimaryButton, Text } from '@frontend/design-system';

interface Props {
  taskInfo: OnboardingModulesTypes.TaskTrayTaskInfo;
}

export const ModuleTaskItem = ({ taskInfo }: Props) => {
  const { t } = useTranslation('onboarding-modules');

  const { setSelectionInfo } = useTaskTrayShallowStore('setSelectionInfo');
  const user = getUser();
  const { selectedLocationIdsWithParents } = useAppScopeStore();

  const handleStartTaskClick = () => {
    setSelectionInfo({ taskId: taskInfo.id, moduleId: taskInfo.moduleId, stepIndex: 0 });

    OnboardingModulesApi.sendTaskEvent({
      locationId: selectedLocationIdsWithParents[0],
      userId: user?.userID ?? '',
      guideName: taskInfo.title,
      guideVersion: 1,
      eventDescription: 'Start Task Clicked in Portal Dashboard',
      stepNumber: 0,
      moduleName: `${taskInfo.moduleId}`,
      urlRoute: window.location.href,
      eventType: EventType.EVENT_START_TASK,
      taskId: taskInfo.id,
    });
  };

  const { isCompleted, title, description, id, imageUrl, information } = taskInfo;
  return (
    <Accordion.Item
      trackingId={TRACKING_IDS.moduleTaskAccordion(taskInfo.moduleId, taskInfo.id)}
      value={String(id)}
      data-test-id='module-task-item'
    >
      <Accordion.Header css={accordionHeader(isCompleted)}>
        <TaskHeader title={title} isCompleted={isCompleted} css={{ columnGap: theme.spacing(1) }} />
      </Accordion.Header>
      <Accordion.Body css={{ padding: theme.spacing(isCompleted ? 2 : 0, 2, 2, 6) }}>
        <div css={{ display: 'flex', alignItems: 'center', gap: theme.spacing(2) }}>
          <div css={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(2) }}>
            <Text size='medium'>{description}</Text>
            {!!information && <InfoText text={information} />}
            {!isCompleted && (
              <PrimaryButton
                disabled={isCompleted}
                size='large'
                css={{ maxWidth: 'fit-content' }}
                onClick={handleStartTaskClick}
                trackingId={TRACKING_IDS.startTaskButton(taskInfo.id)}
              >
                {t('Start Task')}
              </PrimaryButton>
            )}
          </div>
          {!!imageUrl && <img src={imageUrl} alt='' width='125' />}
        </div>
      </Accordion.Body>
    </Accordion.Item>
  );
};

const accordionHeader = (isCompleted: boolean) =>
  css({
    border: 'none',
    background: isCompleted ? theme.colors.neutral5 : 'inherit',
    p: {
      color: isCompleted ? theme.colors.neutral50 : 'inherit',
    },
  });
