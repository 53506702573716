import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { ContentLoader, NakedButton } from '@frontend/design-system';
import { TruncatedText } from '../../truncated-text';
import { StatItem, StatItemData } from './stat-item';

export type TabCardProps = {
  cardId: string;
  data: StatItemData[];
  infoHoverText?: string;
  isActive?: boolean;
  isLoading?: boolean;
  onClick?: (id: string) => void;
  title: string;
  trackingId: string;
};

export const TabCard = ({
  cardId,
  data = [],
  infoHoverText,
  isActive,
  isLoading,
  onClick,
  title,
  trackingId,
}: TabCardProps) => {
  return (
    <NakedButton
      className={isActive ? 'active' : ''}
      css={styles.card(typeof onClick === 'undefined')}
      onClick={() => onClick?.(cardId)}
      trackingId={trackingId}
    >
      <div css={styles.title}>
        <TruncatedText as='span' className='name' text={title} weight='bold' />
      </div>
      <div css={styles.dataPoints}>
        {data.map(({ label, value }) => (
          <StatItem infoHoverText={infoHoverText} key={label} label={label} value={value} />
        ))}
      </div>

      <ContentLoader show={isLoading} size='small' />
    </NakedButton>
  );
};

const styles = {
  card: (noop?: boolean) => css`
    border-radius: ${theme.borderRadius.medium};
    border: 2px solid ${theme.colors.neutral10};
    cursor: ${noop ? 'default' : 'pointer'};
    flex: 1;
    min-width: 200px;
    overflow: hidden;
    padding: ${theme.spacing(3)};
    position: relative;
    transition: background-color 0.2s ease;

    &:hover {
      background-color: ${noop ? '' : theme.colors.neutral5};
    }

    &.active {
      border-color: ${theme.colors.primary50};
    }
  `,

  title: css`
    align-items: center;
    display: flex;
    gap: ${theme.spacing(2)};
    justify-content: space-between;

    .name {
      text-align: start;
      font-size: ${theme.font.size.h3};
    }
  `,

  dataPoints: css`
    display: flex;
    justify-content: space-between;
    margin-top: ${theme.spacing(2)};
  `,
};
