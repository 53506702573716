import { useState } from 'react';

export type PaginationHookResponse = {
  activePage: number;
  assets: string[];
  getNextAsset: (token?: string) => void;
  getNextPage: () => void;
  getPreviousPage: () => void;
  pageToken: string | undefined;
  setActivePage: (page: number) => void;
  setAssets: (assets: string[]) => void;
  setTotalPages: (totalPages: number) => void;
  totalPages: number;
};

export const usePaginationHook = (): PaginationHookResponse => {
  const [assets, setAssets] = useState<string[]>([]);
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [pageToken, setPageToken] = useState<string | undefined>();

  const getPreviousPage = () => {
    if (activePage > 1) {
      const newActivePage = activePage - 1;
      setActivePage(newActivePage);
      const previousPageToken = assets[newActivePage - 2];
      setPageToken(previousPageToken);
    }
  };

  const getNextPage = () => {
    const newActivePage = activePage + 1;
    setActivePage(newActivePage);
    const nextPageToken = assets[newActivePage - 2];
    setPageToken(nextPageToken);
  };

  const getNextAsset = (token?: string) => {
    if (token && !assets.includes(token)) {
      setAssets([...assets, token]);
    }
  };

  return {
    activePage,
    assets,
    getNextAsset,
    getNextPage,
    getPreviousPage,
    pageToken,
    setActivePage,
    setAssets,
    setTotalPages,
    totalPages,
  };
};
