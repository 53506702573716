import { useState } from 'react';
import { Recipient } from '@weave/schema-gen-ts/dist/schemas/messaging/bulk/v2';
import { List, ListListsRequest } from '@weave/schema-gen-ts/dist/schemas/messaging/bulk/v2/service.pb';
import { Virtuoso } from 'react-virtuoso';
import { BulkMessagingMutations } from '@frontend/api-bulk-messaging';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { BulkEmailPrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import { Button, SearchField, SpinningLoader, Text, useFormField, useModalControl } from '@frontend/design-system';
import { DeleteListModal } from '../saved-list/delete-list-modal';
import { Mode } from '../types';
import { NewSavedListItem } from './new-saved-list-item';
import { RenameListItem } from './rename-list-item';
import { SavedListItem } from './saved-list-item';

type Props = {
  getNextPage: () => void;
  hasMore: boolean;
  isFetchingNextPage: boolean;
  lists: List[];
  queryKey: (string | ListListsRequest)[];
  recipientsToUpload: Recipient[];
  selectedListIds: string[];
  setLists: (lists: List[]) => void;
  setMode: (mode: Mode) => void;
  setRecipientsToUpload: (recipients: Recipient[]) => void;
  setSelectedListIds: (listIds: string[]) => void;
};

export const SavedListModal = ({
  getNextPage,
  hasMore,
  isFetchingNextPage,
  lists,
  queryKey,
  recipientsToUpload,
  selectedListIds,
  setLists,
  setMode,
  setRecipientsToUpload,
  setSelectedListIds,
}: Props) => {
  const { t } = useTranslation('bulk-messaging');
  const searchListsFieldProps = useFormField({ type: 'text' });
  const displayedLists = searchListsFieldProps.value
    ? lists.filter((list) => list.name.toLowerCase().includes(searchListsFieldProps.value.toLowerCase()))
    : lists;

  // DELETE FUNCTIONALITY
  const [listIdToDelete, setListIdToDelete] = useState<string | undefined>(undefined);
  const { modalProps: deleteModalProps, triggerProps: deleteTriggerProps } = useModalControl();
  const { mutate: deleteMutation } = BulkMessagingMutations.useDeleteList(queryKey);

  // RENAME FUNCTIONALITY
  const [listIdToRename, setListIdToRename] = useState<string | undefined>(undefined);

  return (
    <>
      <div
        css={{
          display: 'grid',
          gridTemplateColumns: '1fr auto',
          alignItems: 'center',
          columnGap: theme.spacing(1),
          margin: theme.spacing(2, 0),
        }}
      >
        <SearchField
          {...searchListsFieldProps}
          name='search-lists'
          placeholder={t('Search Lists')}
          maxLength={70}
          data-trackingid={`${BulkEmailPrefixes.Audience}-contacts-list-search-input`}
        />
        <Button
          variant='tertiary'
          hoverLabel={t('Upload CSV List')}
          onClick={() => setMode('csv')}
          trackingId={`${BulkEmailPrefixes.Audience}-contacts-upload-csv-btn`}
          css={{ padding: theme.spacing(1) }}
        >
          <Icon name='upload' color='default' />
        </Button>
      </div>
      {recipientsToUpload.length > 0 && (
        <NewSavedListItem
          onAdd={(list: List) => {
            const newSelectedListIds = [...selectedListIds];
            if (list.listId) newSelectedListIds.push(list.listId);
            setSelectedListIds(newSelectedListIds);
            setLists([list, ...lists]);
          }}
          queryKey={queryKey}
          recipientsToUpload={recipientsToUpload}
          setRecipientsToUpload={setRecipientsToUpload}
        />
      )}
      {displayedLists.length > 0 ? (
        <Virtuoso
          style={{ height: `calc(100% - ${theme.spacing(9)})` }}
          data={displayedLists}
          totalCount={displayedLists.length}
          itemContent={(_index, list) =>
            listIdToRename && listIdToRename === list.listId ? (
              <RenameListItem
                key={list.listId}
                list={list}
                queryKey={queryKey}
                onRename={(newList: List) => {
                  setListIdToRename(undefined);
                  const newLists = lists.map((l) => {
                    if (l.listId === newList.listId) {
                      return newList;
                    }
                    return l;
                  });
                  setLists(newLists);
                }}
                setListIdToRename={setListIdToRename}
              />
            ) : (
              <SavedListItem
                key={list.listId}
                list={list}
                disabled={false}
                checked={!!selectedListIds.find((listId) => listId === list.listId)}
                onCheck={() => {
                  if (!list.listId) return;
                  if (selectedListIds.includes(list.listId)) {
                    const newSelectedListIds = selectedListIds.filter((listId) => listId !== list.listId);
                    setSelectedListIds(newSelectedListIds);
                  } else {
                    setSelectedListIds([...selectedListIds, list.listId]);
                  }
                }}
                onDelete={() => {
                  deleteTriggerProps.onClick();
                  setListIdToDelete(list.listId);
                }}
                setListIdToRename={setListIdToRename}
              />
            )
          }
          endReached={getNextPage}
          followOutput='smooth'
          components={{
            Footer: () => {
              if (isFetchingNextPage) {
                return <SpinningLoader />;
              } else if (!hasMore && !isFetchingNextPage) {
                return (
                  <Text css={{ margin: 0, padding: theme.spacing(3, 0), textAlign: 'center' }}>
                    🎉 {t('You have reached the end')}
                  </Text>
                );
              }
              return null;
            },
          }}
        />
      ) : recipientsToUpload.length === 0 ? (
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: `calc(100% - ${theme.spacing(9)})`,
            padding: theme.spacing(0, 4),
            rowGap: theme.spacing(2),
          }}
        >
          <Text color='subdued' weight='bold'>
            {t('No Saved Lists')}
          </Text>
          <Text color='subdued' css={{ textAlign: 'center' }}>
            {t('Target a group of contacts from an uploaded csv.')}
          </Text>
          <Button
            css={{ display: 'flex', columnGap: theme.spacing(0.5), marginTop: theme.spacing(1) }}
            iconName='plus'
            onClick={() => setMode('csv')}
            trackingId={`${BulkEmailPrefixes.Audience}-contacts-empty-screen-upload-csv-btn`}
            variant='tertiary'
          >
            <Text color='primary'>{t('Upload List')}</Text>
          </Button>
        </div>
      ) : null}
      <DeleteListModal
        modalProps={deleteModalProps}
        onDelete={() => {
          if (listIdToDelete) deleteMutation({ listId: listIdToDelete });
          deleteModalProps.onClose();

          // Remove the list from the selected lists
          const newSelectedListIds = selectedListIds.filter((listId) => listId !== listIdToDelete);
          setSelectedListIds(newSelectedListIds);

          // Remove the list from the displayed lists
          setLists(lists.filter((l) => l.listId !== listIdToDelete));

          // Reset the list id to delete
          setListIdToDelete(undefined);
        }}
      />
    </>
  );
};
