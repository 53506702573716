import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const selectedWritebackDestinationContainerStyles = css`
  display: flex;
  align-items: center;
  gap: ${theme.spacing(1)};
`;

export const writebackDestinationContainerStyle = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const writebackDestinationTextStyle = css`
  color: ${theme.colors.neutral50};
  font-size: ${theme.fontSize(14)};

  .pms-version {
    font-size: ${theme.fontSize(12)};
  }
`;

export const multiMessageTextStyle = css`
  margin-bottom: ${theme.spacing(1)};
  font-size: ${theme.fontSize(14)};
  text-align: left;
`;
