import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { BASE_URL_ANALYTICS, PhoneAnalyticsApi, PhoneAnalyticsTypes } from '@frontend/api-analytics';
import { http } from '@frontend/fetch';
import { useTranslation } from '@frontend/i18n';
import { useScopedQuery } from '@frontend/scope';
import { useAlert } from '@frontend/design-system';
import { useGetChartPayloadAndConfig } from './use-get-chart-payload-and-config';
import { usePhoneAnalyticsShallowStore } from './use-phone-analytics-store';

type DateData = {
  date: string;
  daily: number;
  hourly?: number[];
};

type LocationData = {
  id: string;
  dates?: DateData[];
  rate?: number;
};

export type CallAnswerRateResponse = {
  locationIds?: LocationData[];
  weave_average?: number[];
};

type FormattedLocations = {
  [locatonId: string]: {
    [date: string]: {
      daily: number;
      hourly: number[];
    };
  };
};

type MultiLocationDailyRate = {
  [locationId: string]: number;
};

type DataState = {
  locations: FormattedLocations;
  multiLocationsDailyRate?: MultiLocationDailyRate;
  weaveAverage: number[];
};

const formatLocationsData = (locationIds?: LocationData[]) => {
  const multiLocationsDailyRate: MultiLocationDailyRate = {};

  const locations: FormattedLocations =
    locationIds?.reduce((acc, { dates, id, rate = 0 }) => {
      multiLocationsDailyRate[id] = rate;

      return {
        ...acc,
        [id]:
          dates?.reduce(
            (acc2, { daily, date, hourly }) => ({
              ...acc2,
              [date]: {
                daily,
                hourly: hourly || [],
              },
            }),
            {}
          ) || {},
      };
    }, {}) || {};

  return {
    locations,
    multiLocationsDailyRate,
  };
};

const api = async (payload: PhoneAnalyticsTypes.Filters) => {
  const { data } = await http.post<{ data: CallAnswerRateResponse }>(
    `${BASE_URL_ANALYTICS}/${'phonereports/v2/getcallanswerrate'}`,
    PhoneAnalyticsApi.getDatesIntercepted(payload)
  );
  return data;
};

export const useFetchAggregatedCallAnswerRate = (customFilters?: Partial<PhoneAnalyticsTypes.Filters>) => {
  const alert = useAlert();
  const { t } = useTranslation('analytics');
  const { demoData, filters, isDemoAccount } = usePhoneAnalyticsShallowStore('demoData', 'filters', 'isDemoAccount');
  const [data, setData] = useState<DataState | undefined>();
  const { applicableFilters, config } = useGetChartPayloadAndConfig(customFilters || filters, 'call-answer-rate');

  const isMultiLocation = (applicableFilters?.location_id || []).length > 1;
  const isHourlyData =
    !isMultiLocation &&
    applicableFilters.start_date &&
    applicableFilters.end_date &&
    dayjs(applicableFilters.start_date).isSame(applicableFilters.end_date, 'day');

  const { data: callAnswerRate, isLoading } = useScopedQuery({
    ...config,
    queryFn: () => api(applicableFilters),
    onError: () => {
      alert.error(t("Couldn't load the call answer rate data. Please try again."));
    },
  });

  useEffect(() => {
    const dataToUse = isDemoAccount ? demoData?.chartsData.callAnswerRate : callAnswerRate;
    const formattedLocationsData = formatLocationsData(dataToUse?.locationIds);

    setData({
      ...formattedLocationsData,
      weaveAverage: dataToUse?.weave_average || [],
    });
  }, [callAnswerRate, demoData, isDemoAccount]);

  return {
    data,
    isHourlyData,
    isLoading,
    isMultiLocation,
  };
};
