import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Modal, Text, ModalControlModalProps, useFormField, CheckboxField } from '@frontend/design-system';

interface Props extends ModalControlModalProps {
  onConfirm: () => void;
}

export const RequestCancellationModal = ({ onConfirm, ...modalProps }: Props) => {
  const { t } = useTranslation('porting', { keyPrefix: 'phoneNumbers' });
  const checkboxField = useFormField({ type: 'checkbox', value: false }, [modalProps.show]);

  return (
    <Modal maxWidth={600} {...modalProps}>
      <Modal.Header onClose={modalProps.onClose}>{t('Request to Port Cancellation Request')}</Modal.Header>
      <Modal.Body css={{ margin: theme.spacing(2, 0) }}>
        <Text css={{ marginBottom: theme.spacing(3) }}>
          {t(
            'We strongly advise against canceling port requests once a request is submitted or approved. Last minute changes may result in disruption of service or phone numbers. If you wish to proceed, check the box below.'
          )}
        </Text>

        <CheckboxField
          {...checkboxField}
          name='cancel-request-checkbox'
          label={t(
            'I understand that this request for cancelation is not a guarantee and may result in disruption or loss of service.'
          )}
        />
      </Modal.Body>
      <Modal.Footer
        primary={{
          label: t('Submit Cancelation Request'),
          destructive: true,
          disabled: !checkboxField.value,
          onClick: onConfirm,
        }}
      />
    </Modal>
  );
};
