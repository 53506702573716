export const PhoneSvg = ({ fillColor }: { fillColor?: string }) => (
  <svg
    height={24}
    width={24}
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    version='1.1'
    baseProfile='full'
  >
    <path
      fill={fillColor}
      fillRule='evenodd'
      d='m20.743 17.457 1.45 2.037-1.812 1.29a5 5 0 0 1-5.294.316l-1.32-.72a22.5 22.5 0 0 1-8.998-9.013l-.734-1.35a5 5 0 0 1 .362-5.344L5.7 2.898a3 3 0 0 1 4.544-.343l2.136 2.143a3.001 3.001 0 0 1 .406 3.73 7.179 7.179 0 0 0 3.886 3.9 3.001 3.001 0 0 1 3.728.418l2.179 2.186a3 3 0 0 1-.386 4.562l-1.45-2.037m-3.452-2.319.63-.628a.5.5 0 0 1 .707 0l2.179 2.187a.5.5 0 0 1-.064.76l-1.812 1.29a2.5 2.5 0 0 1-2.647.158l-1.32-.72a20 20 0 0 1-7.998-8.012l-.734-1.35a2.5 2.5 0 0 1 .181-2.671l1.302-1.775a.5.5 0 0 1 .757-.058l2.137 2.144a.5.5 0 0 1-.002.707l-.63.629.014.061a9.677 9.677 0 0 0 7.3 7.278'
      clipRule='evenodd'
    />
  </svg>
);
