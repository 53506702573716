import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { LineSaperator } from '..';
import { useAnalyticsOrgLocations } from '../../hooks';
import { usePracticeAnalyticsShallowStore } from './hooks';
import {
  CancellationsMetric,
  HygieneFollowUpMetric,
  HygieneTreatmentMetric,
  NewPatientsMetric,
  PractitionerAnalysisMetric,
  RecapturedMetric,
  RestorativeTreatmentMetric,
  SummaryMetric,
} from '.';

type Props = {
  onFetchStateChange: (isFetching: boolean) => void;
};

export const PracticeChartsView = ({ onFetchStateChange }: Props) => {
  const { t } = useTranslation('analytics');
  const { filters, filterHintText, isDemoAccount } = usePracticeAnalyticsShallowStore(
    'filters',
    'filterHintText',
    'isDemoAccount'
  );
  const { isMultiLocation, locationIds } = useAnalyticsOrgLocations({ isDemoAccount, module: 'PA' });

  const commonProps = {
    isDemoAccount,
    onFetchStateChange,
  };

  const getPageSubtitleText = () =>
    `${
      isMultiLocation && filters.locations?.length
        ? filters.locations.length === locationIds.length
          ? t('all locations, ')
          : filters.locations.length === 1
          ? t('1 location, ')
          : t('{{count}} locations, ', { count: filters.locations.length })
        : ''
    }${filterHintText}`;

  return (
    <div css={styles.wrapper}>
      <SummaryMetric {...commonProps} />
      <LineSaperator label={t('Showing results for {{filtersInfo}}', { filtersInfo: getPageSubtitleText() })} />
      <HygieneFollowUpMetric {...commonProps} />
      <HygieneTreatmentMetric {...commonProps} />
      <RestorativeTreatmentMetric {...commonProps} />
      <PractitionerAnalysisMetric {...commonProps} />
      <NewPatientsMetric {...commonProps} />
      <CancellationsMetric {...commonProps} />
      <RecapturedMetric {...commonProps} />
    </div>
  );
};

const styles = {
  wrapper: css`
    width: 100%;
  `,
};
