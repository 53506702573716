import dayjs from 'dayjs';
import { PhoneAnalyticsTypes } from '@frontend/api-analytics';
import { BarChartData } from '@frontend/charts';
import { formatDateByTimezone, getHourlyInsightsLabel } from '../../../utils';
import { useFetchAggregatedCallsAndDurationReport } from '../hooks';

type DataKey = 'calls' | 'duration';

type Params = ReturnType<typeof useFetchAggregatedCallsAndDurationReport<PhoneAnalyticsTypes.CallsAggregationKey>> & {
  dataKey: DataKey;
  locations?: Record<string, string>;
  startDate?: string;
  timeZone?: string;
};

export const formatCallVolumeAndDurationCharts = ({
  data,
  dataKey,
  isHourlyData,
  isMultiLocation,
  locations,
  startDate,
  timeZone,
}: Params): BarChartData => {
  let groups: BarChartData['groups'] = [];

  if (isMultiLocation) {
    // There will be multiple location objects with single/multiple dates
    groups = Object.entries(data?.aggregatedMultiLocations || {}).map(([key, values]) => {
      const incoming = values[dataKey]?.inbound || 0;
      const outgoing = values[dataKey]?.outbound || 0;
      const total = incoming + outgoing;

      return {
        name: locations?.[key] || key,
        values: {
          inbound: incoming,
          outbound: outgoing,
          total,
        },
      };
    });
  } else {
    // For hourly data: There will be only one object with single date and hourly data for the given location
    // For daily data: There will be only one object with multiple dates for the given location
    const locationData = Object.values(data?.locations || {})[0] || {};

    if (isHourlyData) {
      // Hourly data is restricted to a single date, hence only one object
      const { inbound, outbound } = Object.values(locationData || {})[0] || {};
      const limitedInbound = inbound?.[dataKey]?.hourly || [];
      const limitedOutbound = outbound?.[dataKey]?.hourly || [];
      const limitedTotal = limitedInbound.map((value, i) => value + (limitedOutbound[i] || 0));

      groups = limitedTotal.map((total, i) => ({
        name: getHourlyInsightsLabel(i),
        values: {
          inbound: limitedInbound[i],
          outbound: limitedOutbound[i],
          total,
        },
      }));
    } else {
      groups = Object.entries(locationData).map(([date, { inbound, outbound }]) => {
        const incoming = inbound?.[dataKey]?.daily || 0;
        const outgoing = outbound?.[dataKey]?.daily || 0;
        const total = incoming + outgoing;

        return {
          name: dayjs(date).isValid() ? formatDateByTimezone(date, startDate, timeZone) : date,
          values: {
            inbound: incoming,
            outbound: outgoing,
            total,
          },
        };
      });
    }
  }

  return {
    groups,
  };
};
