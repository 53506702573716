import { css } from '@emotion/react';
import { Entry } from '@weave/schema-gen-ts/dist/schemas/schedule/quickfill/v2/quickfill.pb';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Chip, IconButton, Text } from '@frontend/design-system';
import { quickfillManageListTrackingIds } from '../../quickfill-tracking-ids';

interface QuickfillListItemProps {
  quickfillRecipient: Entry;
  deleteRecipient: (personId: string) => void;
}

export const QuickfillListItem = ({ quickfillRecipient, deleteRecipient }: QuickfillListItemProps) => {
  const { t } = useTranslation('scheduleQuickfill');
  const name = `${quickfillRecipient.person?.firstName ?? ''} ${quickfillRecipient.person?.lastName ?? ''}`;

  return (
    <div css={quickfillListItemContainerStyles}>
      <div css={{ marginRight: theme.spacing(1) }}>
        <div css={quickfillListItemHeaderStyles}>
          <Text size='medium' weight='bold'>
            {name.trim()}
          </Text>
          {/**TODO: Add logic to calculate overdue time */}
        </div>
        {quickfillRecipient?.note && <Text size='small'>{quickfillRecipient?.note}</Text>}
      </div>
      <div css={{ display: 'flex', alignItems: 'center' }}>
        {quickfillRecipient.validTo && (
          <Chip tooltipLabel={t('Appointment Scheduled')}>
            <Icon name='calendar-small' color='primary' />
          </Chip>
        )}
        <IconButton
          onClick={() => deleteRecipient(quickfillRecipient?.personId ?? '')}
          label={t('Remove Quick Fill Recipient')}
          trackingId={quickfillManageListTrackingIds.removeQuickfillRecipientButton}
        >
          <Icon name='x-small' />
        </IconButton>
      </div>
    </div>
  );
};

const quickfillListItemContainerStyles = css({
  padding: theme.spacing(1, 2, 1, 4),
  borderBottom: `1px solid ${theme.colors.neutral20}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const quickfillListItemHeaderStyles = css({
  display: 'flex',
  alignItems: 'center',
});
