import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const modalStyle = css`
  min-width: min(100%, 650px);
`;

export const footerStyle = css`
  padding: ${theme.spacing(0, 2)};
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap: ${theme.spacing(1)};
`;

export const copyLinkButtonStyle = css`
  > span {
    > span {
      display: flex;
      gap: ${theme.spacing(1)};
    }
  }
`;
