import { useEffect, useState, useMemo } from 'react';
import { flushSync } from 'react-dom';
import { css } from '@emotion/react';
import { PhoneSyncApi, useQueryDevices } from '@frontend/api-phone-sync';
import { useTranslation } from '@frontend/i18n';
import { useLocationDataShallowStore } from '@frontend/location-helpers';
import { usePhoneConfigShallowStore } from '@frontend/phone-config';
import {
  DeviceSelectModal,
  LosingAccessModal,
  PhoneSyncButton,
  PhoneSyncModal,
  usePhonePairSuccessHandler,
  usePhoneSyncControl,
  usePhoneSyncMutations,
} from '@frontend/phone-sync';
import { useAppScopeStore, type OrgIdMapKey } from '@frontend/scope';
import { useContactPanelStore } from '@frontend/shared';
import { useSlidePanelStore } from '@frontend/slide-panel';
import { useSoftphoneManager } from '@frontend/weave-softphone-manager';
import { theme } from '@frontend/theme';
import {
  Heading,
  useFormField,
  Text,
  ChecklistField,
  Chip,
  TextLink,
  PrimaryButton,
  SecondaryButton,
  useModalControl,
  RadioField,
  BackIcon,
  useControlledField,
  CheckIcon,
  BannerNotification,
  Accordion,
  SkeletonLoader,
  SearchField,
  SpinningLoader,
  useAlert,
} from '@frontend/design-system';
import { useConfigureLocation } from '../../utils/configure-location';
import { useLocationPickerShallowStore } from '../use-location-picker';
import {
  RefineSelectionAction,
  SoftphoneIsConnectedTooltip,
  usePhoneConfigInfo,
  useWeaveQueryLocations,
} from './shared';
import { DataLocationSearchResults } from './types';

type Props = {
  isPrivileged?: boolean;
  hasSearchTerm?: boolean;
  locationResults: DataLocationSearchResults[];
  isLoading?: boolean;
  closeModal?: () => void;
};

type OrgSelectionAccordion = 'selected' | 'inflight' | null;

export const PrivilegedMultiLocationSelector = ({ closeModal, locationResults, isLoading, hasSearchTerm }: Props) => {
  const { t } = useTranslation('base');
  const [filterLoading, setFilterLoading] = useState(false);
  const orgIdMap = useMemo(() => {
    if (!locationResults) return {};
    return locationResults?.reduce((acc, location) => {
      if (location.orgId) {
        if (acc[location.orgId]) {
          acc[location.orgId].locations.push(location);
        } else {
          acc[location.orgId] = { locations: [location] };
        }
      }
      return acc;
    }, {} as Record<string, { locations: DataLocationSearchResults[] }>);
  }, [locationResults]);
  const orgIds = useMemo(() => Object.keys(orgIdMap), [orgIdMap]);

  const {
    setSelectedLocationIds,
    selectedLocationIds,
    setSelectedOrgId,
    selectedParentsIds,
    setSelectedParentsIds,
    getLocationName,
    selectedOrgId,
    setOrgIds,
    setOrgIdMap,
    setAccessibleLocationData,
    accessibleLocationData,
    setAccessibleLocationIds,
    accessibleLocationIds,
  } = useAppScopeStore();
  const { setMultiSelectedIds } = useLocationDataShallowStore('setMultiSelectedIds');

  const { configureLocationData } = useConfigureLocation();
  const alerts = useAlert();

  const [inFlightOrgId, setInFlightOrgId] = useState(selectedOrgId);
  const [inFlightLocationIds, setInFlightLocationIds] = useState(selectedLocationIds);
  const [openedAccordionId, setOpenedAccordionId] = useState<OrgSelectionAccordion>('selected');
  const [searchTerm, setSearchTerm] = useState('');

  const clearSearch = () => {
    setSearchTerm('');
  };

  const searchFieldProps = useControlledField({ type: 'text', value: searchTerm, onChange: setSearchTerm });

  const {
    selectOrgLocations,
    isLoading: orgIsLoading,
    accessibleLocationData: inFlightAccessibleLocationData,
  } = useWeaveQueryLocations({ orgId: inFlightOrgId });

  const filteredOrgLocations = useMemo(() => {
    if (searchTerm) {
      return selectOrgLocations?.locations.filter((location) =>
        location?.name?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
    return selectOrgLocations?.locations;
  }, [searchTerm, selectOrgLocations]);

  const { isLocationsView, setIsLocationsView } = useLocationPickerShallowStore(
    'isLocationsView',
    'setIsLocationsView'
  );

  const handleOrgChange = (newOrgId: string) => {
    setInFlightOrgId(newOrgId);
    setOpenedAccordionId(newOrgId !== selectedOrgId ? 'inflight' : 'selected');
    setShowActivePhoneCallError(false);
  };
  const orgRadioFieldProps = useControlledField({ type: 'radio', value: inFlightOrgId, onChange: handleOrgChange });
  const locationsChecklistFieldProps = useFormField({ type: 'checklist', value: inFlightLocationIds }, [
    inFlightLocationIds,
  ]);

  const isCurrentlySelectedOrg = inFlightOrgId === selectedOrgId;

  /********************* PHONES STUFF ***************************************/
  const { phoneConfig } = usePhoneConfigShallowStore('phoneConfig');
  const { data: devices, refetch: refetchDevices } = useQueryDevices(inFlightLocationIds);
  const deviceSelectModal = useModalControl();
  const losingAccessModal = useModalControl();

  const hasAvailableDevices = devices && devices.filter((device) => device.availableInContext).length > 0;

  //*** SOFTPHONE STUFF */
  const [showActivePhoneCallError, setShowActivePhoneCallError] = useState(false);
  const isSoftphoneCallActive = useSoftphoneManager((ctx) => ctx.hasActiveCalls);

  // In the case where a softphone call is active & the user tries to update the filters to location(s) and org that is not associated w/ the softphone,
  // update the inFlight locations & orgId to include the softphone orgId & locationId
  const handleSoftphoneActiveRevertFilters = () => {
    if (!phoneConfigInfo || !phoneConfigInfo?.isSoftphone) return;
    if (inFlightOrgId !== phoneConfigInfo.orgId) {
      setInFlightOrgId(phoneConfigInfo.orgId);
    }

    setInFlightLocationIds(selectedLocationIds);
  };

  const handleOrganizationChange = () => {
    setMultiSelectedIds(inFlightLocationIds);
    setSelectedLocationIds(inFlightLocationIds);
    setSelectedOrgId(inFlightOrgId);
    setOrgIds([inFlightOrgId]);
    setOrgIdMap({ [inFlightOrgId]: selectOrgLocations });
    setAccessibleLocationIds(Object.keys(inFlightAccessibleLocationData));
    setAccessibleLocationData(inFlightAccessibleLocationData);
    setSelectedParentsIds(selectOrgLocations?.parents?.map((location) => location.locationID) ?? []);

    useContactPanelStore.reset();
    useSlidePanelStore.reset();
    configureLocationData({
      // This is to satisfy the old single location paradigm
      newLocationId: inFlightLocationIds[0],
      onSuccess() {
        if (inFlightLocationIds[0]) {
          alerts.success(
            t('Successfully switched to {{name}}', {
              name: inFlightAccessibleLocationData[inFlightLocationIds[0]].name,
              interpolation: { escapeValue: false },
            })
          );
        }
      },
      onError() {
        alerts.error({
          autoDismissAfter: 10000,
          message: t(
            'Failed to load location information. Please make sure you have no unpaid bills or contact support at (888) 579-5668 option 2, then option 3'
          ),
        });
      },
    });
  };

  const handleViewLocation = async () => {
    setFilterLoading(true);
    if (!getCanUpdateWhenSoftphoneActive()) {
      setShowActivePhoneCallError(true);
      handleSoftphoneActiveRevertFilters();
      return;
    }
    // Get the new list of devices that are available within the selected location ids
    const { data: devices } = await refetchDevices();

    // These are devices that are currently in context (available within the selected location ids)
    const availableDevices = devices?.filter((device) => device.availableInContext);
    const isCurrentDeviceWithinContext =
      phoneConfig && availableDevices?.some((device) => phoneConfig.sipProfileId === device.sipProfileId);

    const movingAwayFromCurrentConfig = phoneConfig && !isCurrentDeviceWithinContext;
    const hasAvailableDevices = availableDevices && availableDevices.length;
    // The SelectDeviceModal should open if the location with the connected device is deselected (or if no device is connected),
    // and the newly selected location has available devices to be connected.
    const shouldPromptForDeviceSelection = movingAwayFromCurrentConfig;

    if (shouldPromptForDeviceSelection) {
      if (hasAvailableDevices) {
        deviceSelectModal.openModal();
      } else {
        // No available devices to connect to - warn the user
        losingAccessModal.openModal();
      }
    } else {
      handleOrganizationChange();
      closeModal?.();
    }
  };

  const { disconnectDevice } = usePhoneSyncMutations();
  const { modalProps, buttonProps } = usePhoneSyncControl();
  const onSyncSuccess = usePhonePairSuccessHandler()(inFlightLocationIds);
  const phoneConfigInfo = usePhoneConfigInfo({ phoneConfig, accessibleLocationData });

  // Get whether or not we can update the location filters when a softphone call is active.
  const getCanUpdateWhenSoftphoneActive = () => {
    if (!isSoftphoneCallActive || !phoneConfigInfo?.isSoftphone) return true;

    const softphoneOrgIdInFlight = inFlightOrgId === phoneConfigInfo.orgId;
    const softphoneLocationIdInFlight = inFlightLocationIds.includes(phoneConfigInfo.locationId);

    // We can update if the orgId & locationId associated w/ the softphone are 'in flight'
    return softphoneOrgIdInFlight && softphoneLocationIdInFlight;
  };

  useEffect(() => {
    const shouldUseSelectedLocationIds = selectedOrgId === inFlightOrgId;
    const locationIds = shouldUseSelectedLocationIds
      ? selectedLocationIds
      : selectOrgLocations?.locations?.map((location) => location['locationID']) ?? [];

    setInFlightLocationIds(locationIds);
  }, [selectOrgLocations]);

  useEffect(() => {
    if (inFlightLocationIds.length !== locationsChecklistFieldProps.value.length) {
      setInFlightLocationIds(locationsChecklistFieldProps.value);
    }
  }, [locationsChecklistFieldProps.value]);

  const getOrgName = (orgId: string) =>
    locationResults?.find(({ locationId }) => locationId === orgId)?.name ??
    selectOrgLocations?.parents?.find(({ locationId }) => locationId === orgId)?.name ??
    selectOrgLocations?.locations?.find(({ locationId }) => locationId === orgId)?.name;

  return (
    <>
      {isLocationsView && (
        <div
          css={css`
            padding: ${theme.spacing(0, 3)};
          `}
        >
          <SearchField
            {...searchFieldProps}
            placeholder='Locations Search'
            css={{ margin: 1 }}
            name='location-search'
          />
        </div>
      )}
      <div
        css={css`
          display: flex;
          flex-direction: column;
          overflow: hidden;
          flex: 1;
        `}
      >
        <div
          css={css`
            overflow: hidden;
            margin-left: ${sideSpacing};
            margin-right: ${sideSpacing};
          `}
        >
          <Accordion
            variant='hybrid'
            controlledValue={openedAccordionId}
            onChange={(value: any) => setOpenedAccordionId(value as OrgSelectionAccordion)}
            padding={1}
            distance={1}
            chevronSize={16}
          >
            <Accordion.Item value='selected'>
              <Accordion.Header
                css={css`
                  background: ${theme.colors.neutral10};
                  border: 1px solid ${theme.colors.neutral10};
                  transition: border-radius 0.3s ease;
                  border-radius: ${openedAccordionId === 'selected'
                    ? `${theme.borderRadius.medium} ${theme.borderRadius.medium} 0 0`
                    : theme.borderRadius.medium};
                  padding-left: ${theme.spacing(0.5)};
                  svg {
                    fill: ${theme.colors.neutral70};
                  }
                `}
              >
                <Text
                  weight='bold'
                  size='large'
                  css={css`
                    color: ${theme.colors.neutral70};
                  `}
                >
                  {t('Current Organization in Use')}
                </Text>
              </Accordion.Header>
              <Accordion.Body
                css={css`
                  padding: ${theme.spacing(1, 1.5)};
                `}
              >
                <div
                  css={css`
                    display: flex;
                    align-items: flex-start;
                    justify-content: space-between;
                    margin-bottom: ${theme.spacing(0.5)};
                  `}
                >
                  <Text
                    weight='bold'
                    size='large'
                    css={css`
                      color: ${theme.colors.neutral70};
                    `}
                  >
                    {getLocationName(selectedOrgId) || t('Select to view name')}
                  </Text>
                  {!(isCurrentlySelectedOrg && isLocationsView) &&
                    accessibleLocationIds.length - selectedParentsIds.length > 1 && (
                      <RefineSelectionAction
                        css={css`
                          margin-top: ${theme.spacing(0.25)};
                          margin-left: ${theme.spacing(1)};
                        `}
                        onClick={() => {
                          clearSearch();
                          setInFlightOrgId(selectedOrgId);
                          setIsLocationsView(true);
                        }}
                      />
                    )}
                </div>
                <Text size='small' color='light'>
                  {t('{{selected}} of {{total}} Locations Selected', {
                    selected: selectedLocationIds.length,
                    total: accessibleLocationIds.length - selectedParentsIds.length,
                  })}
                </Text>
              </Accordion.Body>
            </Accordion.Item>
            {!isCurrentlySelectedOrg && (
              <Accordion.Item value='inflight'>
                <Accordion.Header
                  css={css`
                    background: ${theme.colors.primary20};
                    border: 1px solid ${theme.colors.primary20};
                    border-radius: ${openedAccordionId === 'inflight'
                      ? `${theme.borderRadius.medium} ${theme.borderRadius.medium} 0 0`
                      : theme.borderRadius.medium};
                    transition: border-radius 0.3s ease;
                    padding-left: ${theme.spacing(0.5)} svg {
                      fill: ${theme.colors.warning60};
                    }
                  `}
                >
                  <Text
                    size='large'
                    weight='bold'
                    css={css`
                      color: ${theme.colors.primary70};
                    `}
                  >
                    {t('Selected Organization (Pending)')}
                  </Text>
                </Accordion.Header>
                <Accordion.Body
                  css={css`
                    padding: ${theme.spacing(1, 1.5)};
                  `}
                >
                  <div
                    css={css`
                      display: flex;
                      align-items: flex-start;
                      justify-content: space-between;
                      margin-bottom: ${theme.spacing(0.5)};
                    `}
                  >
                    <Text
                      weight='bold'
                      size='large'
                      css={css`
                        color: ${theme.colors.primary70};
                      `}
                    >
                      {getOrgName(inFlightOrgId) || t('Select to view name')}
                    </Text>
                    {selectOrgLocations?.locations?.length > 1 && !isLocationsView && (
                      <RefineSelectionAction
                        css={css`
                          margin-top: ${theme.spacing(0.25)};
                          margin-left: ${theme.spacing(1)};
                        `}
                        onClick={() => {
                          clearSearch();
                          setInFlightOrgId(inFlightOrgId);
                          setIsLocationsView(true);
                        }}
                      />
                    )}
                  </div>
                  <Text size='small' color='light'>
                    {t('{{selected}} of {{total}} Locations Selected', {
                      selected: inFlightLocationIds.length,
                      total: selectOrgLocations?.locations?.length,
                    })}
                  </Text>
                </Accordion.Body>
              </Accordion.Item>
            )}
          </Accordion>
        </div>

        <div
          css={css`
            display: flex;
            justify-content: space-between;
            margin: ${theme.spacing(2, 3, 1, 3)};
          `}
        >
          <Heading level={3}>
            {!isLocationsView
              ? t('Matching Organizations')
              : `${t('Locations')} (${selectOrgLocations.locations.length})`}
          </Heading>
          {isLocationsView && (
            <SelectDeselectLink
              selectedOrgLocations={selectOrgLocations}
              selectedLocations={inFlightLocationIds}
              setInFlightLocationIds={setInFlightLocationIds}
            />
          )}
        </div>
        <div
          css={[
            css`
              overflow: auto;
              flex: 1;
              padding: ${sideSpacing};
              border-top: 1px solid ${theme.colors.neutral20};
              border-bottom: 1px solid ${theme.colors.neutral20};
              background-color: ${isLocationsView ? theme.colors.white : theme.colors.neutral5};
            `,
            isLocationsView &&
              css`
                padding-top: ${theme.spacing(1)};
              `,
          ]}
        >
          {!hasSearchTerm && !isLocationsView && (
            <BannerNotification
              css={css`
                margin-bottom: ${theme.spacing(1)};
              `}
              message={t('Search to see organizations')}
              status='info'
            />
          )}
          {isLoading && <SkeletonLoader animation='shimmer' height={90} width={'100%'} count={10} distance={10} />}
          {!isLocationsView && (
            <RadioField {...orgRadioFieldProps} name='org-id'>
              {orgIds?.map((orgIdInstance) => (
                <RadioField.Radio
                  css={css`
                    position: relative;
                    align-items: flex-start;
                    padding: ${theme.spacing(2, 2, 1, 2)};
                    background-color: ${theme.colors.white};
                    border-radius: ${theme.borderRadius.small};
                    border-top: 1px solid ${theme.colors.neutral20};
                    box-shadow: ${theme.shadows.light};
                    width: 100%;
                    label {
                      width: 100%;
                      min-width: 0;
                    }
                  `}
                  value={orgIdInstance}
                  key={orgIdInstance}
                >
                  <div
                    css={css`
                      display: flex;
                      align-self: flex-start;
                      margin-top: -2;
                      flex: 1;
                    `}
                  >
                    <div
                      css={css`
                        width: 100%;
                      `}
                    >
                      <div
                        css={css`
                          display: flex;
                          align-items: flex-start;
                        `}
                      >
                        <Text
                          weight='bold'
                          size='large'
                          css={{
                            display: 'flex',
                            margin: 0,
                            minWidth: 0,
                            marginRight: theme.spacing(2),
                            gap: theme.spacing(1),
                          }}
                        >
                          {getOrgName(orgIdInstance) || (
                            <span
                              css={css`
                                color: ${theme.font.colors.disabled};
                              `}
                            >
                              {t('Select to view names')}
                            </span>
                          )}
                          {phoneConfigInfo?.orgId === orgIdInstance && (
                            <SoftphoneIsConnectedTooltip deviceName={phoneConfig?.name} />
                          )}
                          {selectedOrgId === orgIdInstance && <CheckIcon color='primary' />}
                        </Text>
                        <Text
                          size='small'
                          color='light'
                          css={{ margin: 0, flexShrink: 0, marginLeft: 'auto', color: theme.colors.neutral50 }}
                        >
                          {inFlightOrgId === orgIdInstance ? (
                            orgIsLoading ? (
                              <span
                                css={css`
                                  color: ${theme.colors.warning60};
                                `}
                              >
                                Loading
                              </span>
                            ) : (
                              <span
                                css={css`
                                  color: ${theme.font.colors.default};
                                `}
                              >
                                {selectOrgLocations?.locations.length > 1 ? (
                                  <Chip.MultiChip>
                                    {t('{{count}} Locations', {
                                      count: selectOrgLocations?.locations.length,
                                    })}
                                  </Chip.MultiChip>
                                ) : (
                                  <Chip.SingleChip>
                                    {t('{{count}} Location', {
                                      count: selectOrgLocations?.locations.length,
                                    })}
                                  </Chip.SingleChip>
                                )}
                              </span>
                            )
                          ) : null}
                        </Text>
                      </div>
                      <div>
                        <div
                          css={css`
                            margin-top: ${theme.spacing(0.5)};
                            padding-bottom: ${theme.spacing(1)};
                            border-bottom: 1px solid ${theme.colors.neutral10};
                          `}
                        >
                          <Text size='small' color='light'>
                            {t('{{count}} matching locations', { count: orgIdMap?.[orgIdInstance]?.locations.length })}
                          </Text>
                          {orgIdInstance === inFlightOrgId && selectOrgLocations?.locations?.length > 1 && (
                            <RefineSelectionAction
                              includesArrow
                              css={css`
                                margin-top: ${theme.spacing(1)};
                              `}
                              onClick={() => {
                                clearSearch();
                                setIsLocationsView(true);
                                setShowActivePhoneCallError(false);
                              }}
                            />
                          )}
                        </div>
                        <div
                          css={css`
                            display: flex;
                            gap: 6px;
                            margin-top: 8px;
                            margin-bottom: 10px;
                            flex-wrap: wrap;
                          `}
                        >
                          {orgIdMap?.[orgIdInstance]?.locations.map((location) => (
                            <Chip
                              variant={location?.name?.toLowerCase().includes('parent') ? 'outline' : 'neutral'}
                              css={css`
                                max-width: 100%;
                              `}
                              key={location.locationID}
                            >
                              {location.name}
                            </Chip>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </RadioField.Radio>
              ))}
            </RadioField>
          )}

          {!isLoading && !isLocationsView && hasSearchTerm && !locationResults.length && (
            <BannerNotification message={t('We couldn’t find any matches for your search.')} status='info' />
          )}
          {isLocationsView && (
            <>
              <ChecklistField
                {...locationsChecklistFieldProps}
                name='multi-location'
                label=''
                css={{ padding: theme.spacing(0.5) }}
                onChange={(e) => {
                  locationsChecklistFieldProps.onChange(e);
                  setShowActivePhoneCallError(false);
                }}
              >
                {filteredOrgLocations.map((location) => {
                  return (
                    <ChecklistField.Option name={location.locationID} key={location.locationID}>
                      <div
                        css={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: theme.spacing(1),
                          color: theme.font.colors.default,
                          fontWeight: locationsChecklistFieldProps.value.includes(location.locationID)
                            ? theme.font.weight.semibold
                            : theme.font.weight.regular,
                        }}
                      >
                        <span>{location.name}</span>
                        {phoneConfigInfo?.locationId === location.locationID && (
                          <SoftphoneIsConnectedTooltip deviceName={phoneConfig?.name} />
                        )}
                      </div>
                    </ChecklistField.Option>
                  );
                })}
                {searchTerm && !filteredOrgLocations.length && (
                  <BannerNotification
                    css={css`
                      margin-bottom: ${theme.spacing(2)};
                    `}
                    message={t('We couldn’t find any matches for your search.')}
                    status='info'
                  />
                )}
              </ChecklistField>
            </>
          )}
          {!inFlightLocationIds.length && (
            <BannerNotification message={t('Please select at least one location.')} status='info' />
          )}
        </div>
        <section
          css={css`
            display: flex;
            justify-content: end;
            gap: ${theme.spacing(2)};
            padding: ${theme.spacing(2, 3, 2, 3)};
          `}
        >
          {isLocationsView && (
            <TextLink
              size='medium'
              weight='bold'
              onClick={() => {
                flushSync(() => {
                  clearSearch();
                });
                setIsLocationsView(false);
              }}
              css={css`
                display: flex;
                align-items: center;
                gap: ${theme.spacing(0.5)};
                margin-right: auto;
              `}
            >
              <BackIcon size={16} /> <span>{t('Select An Organization')}</span>
            </TextLink>
          )}

          <SecondaryButton
            size='tiny'
            onClick={() => {
              setInFlightLocationIds(selectedLocationIds);
              setInFlightOrgId(selectedOrgId);
              setIsLocationsView(!!selectedOrgId && orgIdMap?.[selectedOrgId]?.locations.length > 1);
              closeModal?.();
            }}
          >
            {t('Close')}
          </SecondaryButton>
          {filterLoading ? (
            <div css={{ width: 112, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <SpinningLoader size='medium' />
            </div>
          ) : (
            <PrimaryButton
              disabled={inFlightLocationIds.length === 0 || showActivePhoneCallError}
              onClick={handleViewLocation}
              size='tiny'
              trackingId='locpick-2.0-tray-button-apply-filter-privileged'
            >
              {t(`Apply Filter`)}
            </PrimaryButton>
          )}
        </section>
        {hasAvailableDevices ? (
          <>
            <DeviceSelectModal
              variant='location-picker'
              locationIds={inFlightLocationIds}
              deviceSelectModal={deviceSelectModal}
              closeModal={() => {
                handleOrganizationChange();
                closeModal?.();
              }}
              pairDeviceButton={
                <PhoneSyncButton
                  {...buttonProps}
                  variant='tertiary'
                  onClick={() => {
                    deviceSelectModal.closeModal();
                  }}
                />
              }
            />
            <PhoneSyncModal
              {...modalProps}
              locationIds={inFlightLocationIds}
              onBack={() => {
                deviceSelectModal.openModal();
                modalProps.onClose();
              }}
              onSyncSuccess={(config) => {
                onSyncSuccess(config);

                handleOrganizationChange();
                closeModal?.();
              }}
              syncFn={PhoneSyncApi.pairDevice}
            />
          </>
        ) : (
          <>
            <LosingAccessModal
              modal={losingAccessModal}
              onContinue={() => {
                handleOrganizationChange();
                disconnectDevice();
                modalProps.onClose();
                closeModal?.();
              }}
              pairDeviceButton={
                <PhoneSyncButton
                  {...buttonProps}
                  variant='secondary'
                  btnLabel={t('Pair New Device')}
                  onClick={() => {
                    losingAccessModal.closeModal();
                  }}
                />
              }
            />
            <PhoneSyncModal
              {...modalProps}
              locationIds={inFlightLocationIds}
              onBack={() => {
                modalProps.onClose();
              }}
              onSyncSuccess={(config) => {
                onSyncSuccess(config);
                handleOrganizationChange();
                closeModal?.();
              }}
              syncFn={PhoneSyncApi.pairDevice}
            />
          </>
        )}
      </div>
    </>
  );
};

type SelectDeselectLinkProps = {
  selectedOrgLocations: OrgIdMapKey;
  selectedLocations: string[];
  setInFlightLocationIds: (locationIds: string[]) => void;
};

const SelectDeselectLink = ({
  selectedOrgLocations,
  selectedLocations,
  setInFlightLocationIds,
}: SelectDeselectLinkProps) => {
  const { t } = useTranslation('base');

  const currentOrgLocationIds = useMemo(
    () => selectedOrgLocations?.locations.map((location) => location['locationID']),
    [selectedOrgLocations]
  );

  const showSelectAllButton = selectedLocations.length === 0;

  const handleClick = () => {
    if (showSelectAllButton) {
      setInFlightLocationIds(currentOrgLocationIds);
      return;
    }
    setInFlightLocationIds([]);
  };

  return (
    <TextLink onClick={handleClick} size='medium' weight='bold'>
      {showSelectAllButton
        ? `${t('Select')} (${currentOrgLocationIds.length})`
        : `${t('Deselect')} (${selectedLocations.length})`}
    </TextLink>
  );
};

const sideSpacing = theme.spacing(3);
