import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Chip } from '@frontend/design-system';

type Props = {
  contactCount: number;
};

export const CardFooter = ({ contactCount }: Props) => {
  const { t } = useTranslation('bulk-messaging');
  return (
    <div
      css={{
        display: 'flex',
        // TODO: replace this when adding segments in v2
        justifyContent: 'flex-end', //'space-between',
        alignItems: 'flex-end',
        borderRadius: `0 0 ${theme.borderRadius.medium} ${theme.borderRadius.medium}`,
        padding: theme.spacing(2),
        border: `1px solid ${theme.colors.neutral20}`,
        borderTop: 'none',
      }}
    >
      {/* TODO: uncomment this when adding segment creation in v2 */}
      {/* <Text color="primary">Segment</Text> */}
      <Chip.Tag
        color='green'
        css={{ border: 'none', maxWidth: 'unset', display: 'flex', width: 'auto' }}
        leftElement={<Icon name='assigned-user-small' color='success' />}
      >
        {t('{{contact}} Contacts found in segments', { contact: contactCount.toLocaleString() })}
      </Chip.Tag>
    </div>
  );
};
