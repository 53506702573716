import {
  AlignmentSetting,
  FrameSetting,
  HeightSetting,
  PaddingSetting,
  SettingsPanel,
} from '@frontend/content-composer-settings';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { useSettingsForm } from '../../hooks';
import { LogoSectionProps } from '../../types';

export const LogoSettings = () => {
  const { t } = useTranslation('content-composer');

  const { getFieldProps } = useSettingsForm<LogoSectionProps>({
    corners: { type: 'radio' },
    frameAlignment: { type: 'radio' },
    frameColor: { type: 'text' },
    height: { type: 'text' },
    paddingBottom: { type: 'text' },
    paddingLeft: { type: 'text' },
    paddingRight: { type: 'text' },
    paddingTop: { type: 'text' },
  });

  return (
    <SettingsPanel title={t('Logo Styling')}>
      <AlignmentSetting {...getFieldProps('frameAlignment')} />
      <HeightSetting
        {...getFieldProps('height')}
        options={['auto', theme.spacing(5), theme.spacing(8), theme.spacing(10), theme.spacing(15)]}
        independent
      />
      <FrameSetting {...getFieldProps('frameColor')} />
      <PaddingSetting
        paddingTopFieldProps={getFieldProps('paddingTop')}
        paddingBottomFieldProps={getFieldProps('paddingBottom')}
        paddingLeftFieldProps={getFieldProps('paddingLeft')}
        paddingRightFieldProps={getFieldProps('paddingRight')}
        isLast
      />
    </SettingsPanel>
  );
};
