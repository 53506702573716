import { css } from '@emotion/react';
import { useNavigate } from '@tanstack/react-location';
import { CallIntelligenceTypes } from '@frontend/api-analytics';
import { BarChartAppearance, BarChartProps, Chart } from '@frontend/charts';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';
import { URLs } from '../../../constants';
import { usePreviousTimePeriodLabel } from '../../../hooks/use-previous-time-period-label';
import { formatters, isOngoingLabel } from '../../../utils';
import { CallIntelInfoTips } from '../call-intel-info-tips';
import { CallIntelMockData } from '../demo-data';
import { useCallIntelLocations, useCallIntelShallowStore } from '../hooks';
import { CustomToolTipTitle, getPartialComparisonMessage } from '../insight-card';

const colors = {
  previousUnresolved: theme.colors.critical20,
  currentUnresolved: theme.colors.critical30,
  previousResolved: theme.colors.primary30,
  currentResolved: theme.colors.primary40,
};

const strokeColors = {
  previousUnresolved: theme.colors.critical40,
  currentUnresolved: theme.colors.critical50,
  previousResolved: theme.colors.primary50,
  currentResolved: theme.colors.primary60,
};

const appearance: BarChartAppearance = {
  barBackground: true,
  barSize: 40,
  groupsGap: 40,
  labelConfig: {
    customStyles: ({ color }) => ({
      fill: color,
      fontSize: theme.font.size.h3,
      fontWeight: 'bold',
    }),
    show: true,
  },
  maxValue: 100,
  showXAxis: true,

  // (barSize * groups.length) + groupsGap + (chartSpaceOnLeft + chartSpaceOnRight)
  // spaces on left and right of chart is equal to groupsGap /2
  width: 40 * 2 + 80 + (40 + 40),
  wrapperStyles: css`
    display: flex;
    justify-content: center;
  `,
};

const commonBarChartProps: BarChartProps = {
  customLegendsIds: Object.keys(colors),
  formatValue: (value) => formatters.percent.appendPercent(value),
};

const demoLocations = CallIntelMockData.dummyLocationNames();

export type PeriodOverPeriodStats = {
  currentUnresolvedRate: number;
  currentUnresolvedValue: number;
  currentTotalValue: number;
  previousUnresolvedRate: number;
  previousUnresolvedValue: number;
  previousTotalValue: number;
  currentResolvedRate: number;
  currentResolvedValue: number;
  previousResolvedRate: number;
  previousResolvedValue: number;
  isPartialCurrent: boolean;
  isPartialPrevious: boolean;
  resolvedChangeType: CallIntelligenceTypes.ChangeType;
  resolvedPercentageChange: number;
  unresolvedChangeType: CallIntelligenceTypes.ChangeType;
  unresolvedPercentageChange: number;
};

type Props = {
  isDemoAccount?: boolean;
  isLoading: boolean;
  previousInterval: CallIntelligenceTypes.DateInterval | null;
  stats: PeriodOverPeriodStats;
};

type ChangeIndicator = {
  change: number;
  changeType: CallIntelligenceTypes.ChangeType;
  type:
    | CallIntelligenceTypes.ServiceQualityFlagEnum.FLAG_UNRESOLVED_ISSUE
    | CallIntelligenceTypes.ServiceQualityFlagEnum.FLAG_EXCELLENT_RESOLUTION;
};

const ChangeIndicator = ({ type, change, changeType }: ChangeIndicator) => {
  const isUnresolved = type === CallIntelligenceTypes.ServiceQualityFlagEnum.FLAG_UNRESOLVED_ISSUE;
  const icon = changeType === 'increased' ? 'up' : 'down';
  const color =
    (changeType === 'increased' && !isUnresolved) || (changeType === 'decreased' && isUnresolved) ? 'success' : 'error';

  return (
    <Text css={styles.changeIndicator} color={color} size='small'>
      <Icon name={icon} size={12} />
      {change}%
    </Text>
  );
};

export const ServiceQualityPeriodOverPeriod = ({ isDemoAccount, isLoading, previousInterval, stats }: Props) => {
  const { t } = useTranslation('analytics');
  const navigate = useNavigate();

  const {
    dataLabels,
    filterHintText: currentPeriodLabel,
    filters,
    setFilters,
    setFiltersToRestore,
    setSubView,
  } = useCallIntelShallowStore(
    'dataLabels',
    'filterHintText',
    'filters',
    'setFilters',
    'setFiltersToRestore',
    'setSubView'
  );

  const previousTimePeriodLabel = usePreviousTimePeriodLabel(currentPeriodLabel);

  const partialMessage = getPartialComparisonMessage({
    isPartialCurrent: stats.isPartialCurrent,
    isPartialPrevious: stats.isPartialPrevious,
  });

  const isOngoingPeriod = isOngoingLabel(currentPeriodLabel);

  const isEmpty = stats.currentTotalValue === 0 && stats.previousTotalValue === 0;

  const { isMultiLocation } = useCallIntelLocations({
    demoLocations: isDemoAccount ? demoLocations : undefined,
  });

  const handleBarClick = (barId: string, flag: CallIntelligenceTypes.ServiceQualityFlagEnum) => {
    setFiltersToRestore(filters);

    if (previousInterval && barId.includes('previous')) {
      const { startDate, endDate } = previousInterval;
      setFilters({ startDate, endDate });
    }

    setSubView({ id: flag, type: 'service-quality' });

    navigate({
      search: { type: 'service-quality' },
      to: `${URLs.CALL_INTEL_BASE}/${flag.toLowerCase()}`,
    });
  };

  return (
    <Chart
      colors={colors}
      emptyStateConfig={{
        description: t('Choose a different time period or adjust your filters.'),
        label: t('No data to display.'),
      }}
      isLoading={isLoading}
      strokeColors={strokeColors}
    >
      <Chart.Header
        title={t('Service Quality Period-Over-Period')}
        subtitle={currentPeriodLabel}
        infoTip={<CallIntelInfoTips tip='serviceQualityPeriodOverPeriod' />}
      />
      <Chart.HorizontalContainer marginBottom={0}>
        <div>
          <Chart.BarChart
            {...commonBarChartProps}
            appearance={{
              ...appearance,
              patterns: {
                ...((isOngoingPeriod || stats.isPartialCurrent) && { currentUnresolved: { name: 'lines' } }),
                ...(stats.isPartialPrevious && { previousUnresolved: { name: 'lines' } }),
              },
              height: 300,
              customTooltipTitle: ({ hoveredSegment }) => (
                <CustomToolTipTitle
                  label={
                    <Text color='subdued' size='small'>
                      <Text as='span' weight='bold' color='subdued' size='small'>
                        {hoveredSegment === 'previousUnresolved' ? previousTimePeriodLabel : currentPeriodLabel}:{' '}
                      </Text>
                      {
                        dataLabels.serviceQualityFlag?.[
                          CallIntelligenceTypes.ServiceQualityFlagEnum.FLAG_UNRESOLVED_ISSUE
                        ]
                      }
                    </Text>
                  }
                  isMultiLocation={isMultiLocation}
                  locationCount={filters?.locations?.length ?? 0}
                />
              ),
              customTooltipData: () => [
                {
                  color: theme.colors.critical50,
                  formattedValue: stats.currentUnresolvedValue,
                  id: 'currentUnresolved',
                  label: t('Unresolved Calls Calls'),
                  subLabel: t('Out of {{totalValue}} Calls Analyzed', { totalValue: stats.currentTotalValue }),
                },
                {
                  color: theme.colors.critical30,
                  formattedValue: stats.previousUnresolvedValue,
                  id: 'previousUnresolved',
                  label: t('Unresolved Calls'),
                  subLabel: t('Out of {{totalValue}} Calls Analyzed', { totalValue: stats.previousTotalValue }),
                },

                {
                  color: theme.colors.critical50,
                  formattedValue: `${stats.currentUnresolvedRate}%`,
                  id: 'currentUnresolved',
                  label: t('Unresolved Call Rate'),
                },
                {
                  color: theme.colors.critical30,
                  formattedValue: `${stats.previousUnresolvedRate}%`,
                  id: 'previousUnresolved',
                  label: t('Unresolved Call Rate'),
                },
              ],
              labelConfig: {
                ...appearance.labelConfig,
                showNoDataLabel: {
                  previousUnresolved: stats.previousTotalValue === 0,
                },
              },
            }}
            data={{
              groups: !isEmpty
                ? [
                    {
                      name: previousTimePeriodLabel,
                      values: {
                        previousUnresolved: stats.previousUnresolvedRate,
                      },
                    },
                    {
                      name: currentPeriodLabel,
                      values: {
                        currentUnresolved: stats.currentUnresolvedRate,
                      },
                    },
                  ]
                : [],
            }}
            onClick={({ barId }) =>
              handleBarClick(barId, CallIntelligenceTypes.ServiceQualityFlagEnum.FLAG_UNRESOLVED_ISSUE)
            }
          />
          {!isEmpty && (
            <div css={styles.changeContainer}>
              <Text size='large' weight='bold'>
                {dataLabels.serviceQualityFlag?.[CallIntelligenceTypes.ServiceQualityFlagEnum.FLAG_UNRESOLVED_ISSUE]}
              </Text>

              {stats.unresolvedChangeType !== 'noData' && (
                <ChangeIndicator
                  type={CallIntelligenceTypes.ServiceQualityFlagEnum.FLAG_UNRESOLVED_ISSUE}
                  change={stats.unresolvedPercentageChange}
                  changeType={stats.unresolvedChangeType}
                />
              )}
            </div>
          )}
        </div>

        <div>
          <Chart.BarChart
            {...commonBarChartProps}
            appearance={{
              ...appearance,
              patterns: {
                ...((isOngoingPeriod || stats.isPartialCurrent) && { currentResolved: { name: 'lines' } }),
                ...(stats.isPartialPrevious && { previousResolved: { name: 'lines' } }),
              },
              height: 300,
              customTooltipTitle: ({ hoveredSegment }) => (
                <CustomToolTipTitle
                  label={
                    <Text color='subdued'>
                      <Text as='span' weight='bold' color='subdued'>
                        {hoveredSegment === 'previousResolved' ? previousTimePeriodLabel : currentPeriodLabel}:{' '}
                      </Text>
                      {
                        dataLabels.serviceQualityFlag?.[
                          CallIntelligenceTypes.ServiceQualityFlagEnum.FLAG_EXCELLENT_RESOLUTION
                        ]
                      }
                    </Text>
                  }
                  isMultiLocation={isMultiLocation}
                  locationCount={filters?.locations?.length ?? 0}
                />
              ),
              customTooltipData: () => [
                {
                  color: theme.colors.primary60,
                  formattedValue: stats.currentResolvedValue,
                  id: 'currentResolved',
                  label: t('Excellent Service Calls'),
                  subLabel: t('Out of {{totalValue}} Calls Analyzed', { totalValue: stats.currentTotalValue }),
                },
                {
                  color: theme.colors.primary40,
                  formattedValue: stats.previousResolvedValue,
                  id: 'previousResolved',
                  label: t('Excellent Service Calls'),
                  subLabel: t('Out of {{totalValue}} Calls Analyzed', { totalValue: stats.previousTotalValue }),
                },

                {
                  color: theme.colors.primary60,
                  formattedValue: `${stats.currentResolvedRate}%`,
                  id: 'currentResolved',
                  label: t('Excellent Service Rate'),
                },
                {
                  color: theme.colors.primary40,
                  formattedValue: `${stats.previousResolvedRate}%`,
                  id: 'previousResolved',
                  label: t('Excellent Service Rate'),
                },
              ],
              labelConfig: {
                ...appearance.labelConfig,
                showNoDataLabel: {
                  previousResolved: stats.previousTotalValue === 0,
                },
              },
            }}
            data={{
              groups: !isEmpty
                ? [
                    {
                      name: previousTimePeriodLabel,
                      values: {
                        previousResolved: stats.previousResolvedRate,
                      },
                    },
                    {
                      name: currentPeriodLabel,
                      values: {
                        currentResolved: stats.currentResolvedRate,
                      },
                    },
                  ]
                : [],
            }}
            onClick={({ barId }) =>
              handleBarClick(barId, CallIntelligenceTypes.ServiceQualityFlagEnum.FLAG_EXCELLENT_RESOLUTION)
            }
          />
          {!isEmpty && (
            <div css={styles.changeContainer}>
              <Text size='large' weight='bold'>
                {
                  dataLabels.serviceQualityFlag?.[
                    CallIntelligenceTypes.ServiceQualityFlagEnum.FLAG_EXCELLENT_RESOLUTION
                  ]
                }
              </Text>
              {stats.unresolvedChangeType !== 'noData' && (
                <ChangeIndicator
                  type={CallIntelligenceTypes.ServiceQualityFlagEnum.FLAG_EXCELLENT_RESOLUTION}
                  change={stats.resolvedPercentageChange}
                  changeType={stats.resolvedChangeType}
                />
              )}
            </div>
          )}
        </div>
      </Chart.HorizontalContainer>
      {!isEmpty && !!partialMessage && (
        <Text as='i' color='subdued' size='small' textAlign='center'>
          {partialMessage}
        </Text>
      )}
    </Chart>
  );
};

const styles = {
  changeContainer: css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: ${theme.spacing(0.5)};
    margin-top: ${theme.spacing(2)};
  `,
  changeIndicator: css`
    display: flex;
    gap: ${theme.spacing(0.5)};
    align-items: center;
  `,
};
