import { useCustomizationFlagShallowStore, CustomizationFlagTypes } from '@frontend/api-customization-flags';
import { useShowCallIntelNav } from '@frontend/analytics';
import { CoreACLs, getDecodedWeaveToken, hasACL, isWeaveUser } from '@frontend/auth-helpers';
import { BulkMessageHooks } from '@frontend/bulk-message-service';
import { useIsWritebackSupported } from '@frontend/insurance';
import { useLocationDataShallowStore } from '@frontend/location-helpers';
import { useMerchantsInfo } from '@frontend/payments-hooks';
import { useHasPhoneSystemAccess } from '@frontend/phone-config';
import { useFeatureFlagShallowStore, useIsDentalOffice } from '@frontend/shared';
import { useShell } from '@frontend/shell-utils';
import { isMultiLocationUser } from '../multi-location-checker';

/**
 * @returns an object of boolean values to determine what the signed in user has access to see
 */
export const useRouteAccess = () => {
  // primitives, aka vars we do not return from this hook
  const { locationId, locationData, isParentLocation } = useLocationDataShallowStore(
    'locationId',
    'locationData',
    'isParentLocation'
  );
  const isChildLocation = !!locationData?.ParentID;
  const isDental = useIsDentalOffice();
  const userIsMultiLocation = isMultiLocationUser(getDecodedWeaveToken());

  // customizations
  const { flags, hasActiveFlag } = useCustomizationFlagShallowStore('flags', 'hasActiveFlag');
  const isDigitalFormsEnabled = flags?.forms?.state === CustomizationFlagTypes.CustomizationFlagState.ACTIVE;
  const isPaymentsEnabled = flags?.weavepay?.state !== CustomizationFlagTypes.CustomizationFlagState.HIDE;
  const hasPhoneSystemAccess = useHasPhoneSystemAccess();

  const isApptRemindersEnabled = hasActiveFlag('apptreminders-automated');
  const isBirthdayReminderEnabled = hasActiveFlag('birthdaytexts/emails-automated');
  const isEyewearReminderEnabled = hasActiveFlag('eyewearready');
  const isMissedCallTextEnabled = hasActiveFlag('missedcalltext');
  const isMissedTextMessageEnabled = hasActiveFlag('automissedtextmessage');
  const isRecallReminderEnabled = hasActiveFlag('recallreminders-automated');
  const isTextConnectEnabled = hasActiveFlag('textconnect');
  const isOnlineSchedulingEnabled = hasActiveFlag('onlinescheduling');
  const isEmailMarketingEnabled = hasActiveFlag('emailmarketing');
  const isReviewsEnabled = hasActiveFlag('reviewshomeicon');
  const { isShell } = useShell();

  const hasCallIntelAccess = useShowCallIntelNav();
  const bulkMessages = BulkMessageHooks.useHasBulkTexting();

  // feature flags
  const { flagValues } = useFeatureFlagShallowStore('flagValues');
  const departments = flagValues['departments'];
  const passwordPolicy = flagValues['password-policy'];
  const contactDirectories = flagValues['phones-contact-directories'];
  const weaveVerify = hasActiveFlag('weaveverify');
  const tenDLCFormFeatureFlag = flagValues['ten-dlc-form'];
  const quickFill2 = flagValues['schedule:quick-fill-2'];
  const onlineBillPay = flagValues['payments_online_payment_settings'];
  const paymentPlans = flagValues['payments_payment_plans'];
  const sideKeys = flagValues['phone-side-keys'];
  const hasInstallerLinks =
    flagValues['show-installer-link'] &&
    !isShell &&
    (navigator.platform?.toLowerCase().includes('mac') || navigator.platform?.toLowerCase().includes('win'));

  // pure ACL(s)
  const phoneDepartmentWrite = hasACL(locationId, CoreACLs.PHONE_DEPARTMENT_WRITE);
  const phoneContactsWrite = hasACL(locationId, CoreACLs.PHONE_CONTACTS_WRITE);
  const phoneDevicesWrite = hasACL(locationId, CoreACLs.PHONE_DEVICE_WRITE);
  const hasMessageHistoryView = hasACL(locationId, CoreACLs.MESSAGE_HISTORY_VIEW);
  const hasReviewsSettingsAccess = hasACL(locationId, CoreACLs.REVIEW_SETTING);

  // custom hooks/misc.
  const { anyLocationHasPayments } = useMerchantsInfo();
  const { isWritebackSupported } = useIsWritebackSupported();

  // Messaging
  const autoMessaging =
    isApptRemindersEnabled ||
    isBirthdayReminderEnabled ||
    isEyewearReminderEnabled ||
    isMissedCallTextEnabled ||
    isRecallReminderEnabled ||
    isMissedTextMessageEnabled ||
    isReviewsEnabled ||
    anyLocationHasPayments; // locations that have either one of the auto-messaging customizations enabled or if they have a stripe account which means they can use payment reminders

  const debugTools = !isParentLocation && hasMessageHistoryView;
  const messageQueue = !isParentLocation;
  const reminderSettings = !isParentLocation && isApptRemindersEnabled;
  const textConnect = !isParentLocation && isTextConnectEnabled;

  // combinations
  const hasBillingInfoAccess =
    locationData?.Type === 'Dev' || (hasACL(locationId, CoreACLs.BILLING_INFORMATION_READ) && !isParentLocation);
  const canEditCallGroups = hasACL(locationId, CoreACLs.CALL_GROUPS_WRITE) || isWeaveUser();
  const hasPasswordPolicyPermission =
    !isChildLocation && hasACL(locationId, CoreACLs.ADMIN_WRITE_SECURITY_SETTINGS) && passwordPolicy;
  const hasPermissionToUploadForms = hasACL(locationId, CoreACLs.FORMS_UPLOAD_MANAGE) || isWeaveUser();
  const hasWritebackLogsPermission = isDental && weaveVerify && isWritebackSupported;
  const canManageCallQueues = hasACL(locationId, CoreACLs.CALL_QUEUE_WRITE);
  const showLocationPage = (userIsMultiLocation && !isParentLocation && !isChildLocation) || isWeaveUser();
  const hasDataSourceAccess = isChildLocation;
  const hasTenDLCFormAccess = tenDLCFormFeatureFlag || hasACL(locationId, CoreACLs.WRITE_LOCATIONS);

  return {
    customization: {
      digitalForms: isDigitalFormsEnabled,
      emailMarketing: isEmailMarketingEnabled,
      onlineScheduling: isOnlineSchedulingEnabled,
      payments: isPaymentsEnabled,
      reviews: isReviewsEnabled,
      phoneSystem: hasPhoneSystemAccess,
    },
    feature: {
      autoMessaging,
      bulkMessages,
      contactDirectories,
      departments,
      debugTools,
      hasInstallerLinks,
      messageQueue,
      onlineBillPay,
      passwordPolicy,
      paymentPlans,
      paymentsMerchant: anyLocationHasPayments,
      reminderSettings,
      quickFill2,
      sideKeys,
      textConnect,
      weaveVerify,
    },
    acl: {
      phoneDepartmentWrite,
      hasMessageHistoryView,
      hasReviewsSettingsAccess,
      phoneContactsWrite,
      phoneDevicesWrite,
    },
    combination: {
      canEditCallGroups,
      canManageCallQueues,
      hasPermissionToUploadForms,
      hasBillingInfoAccess,
      hasCallIntelAccess,
      hasDataSourceAccess,
      hasPasswordPolicyPermission,
      hasTenDLCFormAccess,
      hasWritebackLogsPermission,
      showLocationPage,
    },
  };
};
