import { useEffect } from 'react';
import { css } from '@emotion/react';
import dayjs from 'dayjs';
import { BulkMessagingTypes } from '@frontend/api-bulk-messaging';
import { getTodaysDate } from '@frontend/date';
import { Divider } from '@frontend/divider';
import { useTranslation } from '@frontend/i18n';
import { BulkEmailPrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import { DateRangeField, DropdownField, Text, useFormField } from '@frontend/design-system';
import { DateRange, formatRange, rangeHasDate } from './date-filter';

const createMinMax = (appointmentQualifier?: BulkMessagingTypes.AppointmentQualifier) => {
  switch (appointmentQualifier) {
    case BulkMessagingTypes.AppointmentQualifier.NEXT_APPOINTMENT:
      return {
        maxDate: '',
        minDate: dayjs().add(1, 'day').format('MM/DD/YYYY'),
      };

    case BulkMessagingTypes.AppointmentQualifier.LAST_SEEN:
      return {
        maxDate: getTodaysDate('MM/DD/YYYY'),
        minDate: '',
      };

    default: // 'No Appointment' and 'All'
      return {
        maxDate: '',
        minDate: '',
      };
  }
};

type Props = {
  appointmentQualifier: BulkMessagingTypes.AppointmentQualifier | undefined;
  onChangeAppointmentQualifier: (appointmentQualifier: BulkMessagingTypes.AppointmentQualifier) => void;
  appointmentDateRange: [string, string];
  onChangeAppointmentDateRange: (appointmentDateRange: [string, string]) => void;
};

const optionsMap = {
  [BulkMessagingTypes.AppointmentQualifier.NEXT_APPOINTMENT]: 'Next Appointment',
  [BulkMessagingTypes.AppointmentQualifier.LAST_SEEN]: 'Last Seen',
  [BulkMessagingTypes.AppointmentQualifier.NO_APPOINTMENT]: 'No Appointment',
} as const;

export const AppointmentDateFilter = ({
  appointmentDateRange,
  appointmentQualifier,
  onChangeAppointmentDateRange,
  onChangeAppointmentQualifier,
}: Props) => {
  const { t } = useTranslation('bulk-messaging');
  const appointmentQualifierField = useFormField({
    type: 'dropdown',
    value: appointmentQualifier,
  });
  const appointmentDateField = useFormField({
    type: 'dateRange',
    value: formatRange(appointmentDateRange, 'MM/DD/YYYY'),
    required: true,
    ...createMinMax(appointmentQualifier),
    validator: (dateRangeField) => {
      const value = dateRangeField.value;

      if (!value[0] || !value[1]) {
        return '';
      }

      if (dayjs(value[0]).isBefore(dayjs(dateRangeField.minDate))) {
        return t('Date range must be in the future');
      }

      if (dayjs(value[1]).isAfter(dayjs(dateRangeField.maxDate))) {
        return t('Date range must be in the past');
      }

      return '';
    },
  });

  useEffect(() => {
    if (appointmentQualifierField.value !== appointmentQualifier) {
      onChangeAppointmentQualifier(appointmentQualifierField.value as BulkMessagingTypes.AppointmentQualifier);
    }
  }, [appointmentQualifierField.value]);

  useEffect(() => {
    if (appointmentDateField.value !== appointmentDateRange) {
      const range = rangeHasDate(appointmentDateField.value as DateRange) ? appointmentDateField.value : undefined;
      onChangeAppointmentDateRange(range as DateRange);
    }
  }, [appointmentDateField.value]);

  return (
    <>
      <Text size='small'>{t('Appointment Date')}</Text>

      <DropdownField
        label={appointmentQualifierField.value === '' ? t('All') : ''}
        name='appointmentQualifier'
        data-trackingid={`${BulkEmailPrefixes.Audience}-appointment-date-type-input`}
        {...appointmentQualifierField}
      >
        <DropdownField.Option value={''}>{t('All Appointments')}</DropdownField.Option>
        {Object.values(optionsMap).map((option, index) => (
          <DropdownField.Option key={index} value={option}>
            {option}
          </DropdownField.Option>
        ))}
      </DropdownField>
      <DateRangeField label='' name='appointmentDate' {...appointmentDateField} />
      <Divider margin={theme.spacing(1)} />
      {appointmentDateField.error && (
        <Text
          size='small'
          color='error'
          css={css`
            margin-top: ${theme.spacing(0.5)};
          `}
        >
          {appointmentDateField.error}
        </Text>
      )}
    </>
  );
};
