import { createProvider } from '@frontend/store';
import { DEFAULT_NEW_CONVERSATION } from '../constants';
import { StreamConversation, Conversations, StreamInstance, StreamUser, UserStatus } from '../types';
import { TeamChatState, TeamChatStore, InitializeParams } from './types';

const DEFAULT_CHAT_STATE: TeamChatState = {
  isTrayOpen: false,
  isInitializing: true,
  currentUser: null,
  isConnected: false,
  streamClient: null,
  userStatus: {},
  conversations: {
    groups: [],
    dm: [],
  },
  users: [],
  activeConversation: undefined,
  activeThread: undefined,
  isNewConversation: false,
  teamChatOrgId: '',
  isChatListExpanded: false,
  totalUnreadCount: 0,
  activeChannelId: undefined,
  isStatusModalOpen: false,
  unreadMessageCount: 0,
  isFetchingChats: false,
};

const { Provider: TeamChatProvider, useStore: useTeamChatStore } = createProvider<TeamChatStore>()((set) => ({
  ...DEFAULT_CHAT_STATE,

  initialize: (newState: Partial<InitializeParams>) => set((state) => ({ ...state, ...newState })),

  reset: () => set(DEFAULT_CHAT_STATE),

  reconnect: () => {},

  setConversations: (conversations: Conversations) => set({ conversations }),

  // TODO: revisit this. Should this be a string instead?
  setActiveConversation: (channel: StreamConversation) => set((state) => ({ ...state, activeConversation: channel })),

  resetActiveConversation: () =>
    set({
      activeConversation: DEFAULT_CHAT_STATE.activeConversation,
      isNewConversation: false,
      isChatListExpanded: false,
    }),

  // TODO: This should be residing somewhere else
  refetchConversation: () => {},

  setActiveThread: () => {},

  resetActiveThread: () => {},

  setStreamClient: (client: StreamInstance) => set((state) => ({ ...state, streamClient: client })),

  initializeNewConversation: ({ type, name }) =>
    set((state) => ({
      ...state,
      activeConversation: { ...DEFAULT_NEW_CONVERSATION, ...(type && { type }), ...(name && { name }) },
      isNewConversation: true,
    })),

  completeNewConversation: (activeConversation?: StreamConversation) =>
    set((state) => ({ ...state, isNewConversation: false, ...(activeConversation && { activeConversation }) })),

  setChannelMembers: (members: StreamUser[]) =>
    set((state) => ({
      ...state,
      ...(state.activeConversation && { activeConversation: { ...state.activeConversation, members: [...members] } }),
    })),

  setTeamChatOrgId: (orgId: string) => set((state) => ({ ...state, teamChatOrgId: orgId })),

  setIsConnected: (isConnected: boolean) => set((state) => ({ ...state, isConnected })),

  setIsChatListExpanded: (isChatListExpanded: boolean) => set((state) => ({ ...state, isChatListExpanded })),

  setTotalUnreadCount: (count: number) => set({ totalUnreadCount: count }),

  setTrayOpen: (isOpen: boolean) => set({ isTrayOpen: isOpen }),

  removeConversation: (channelId) =>
    set((state) => ({
      ...state,
      conversations: {
        ...state.conversations,
        ...(channelId.startsWith('!members') && {
          dm: state.conversations.dm.filter((channel) => channel.channelId !== channelId),
        }),
        ...(!channelId.startsWith('!members') && {
          groups: state.conversations.groups.filter((channel) => channel.channelId !== channelId),
        }),
      },
    })),

  setUserStatus: (userId: string, status: UserStatus) => {
    set((state) => ({
      userStatus: {
        ...state.userStatus,
        [userId]: status,
      },
    }));
  },

  batchSetUserStatus: (statuses: Record<string, UserStatus>) => {
    set((state) => ({
      userStatus: {
        ...state.userStatus,
        ...statuses,
      },
    }));
  },

  setActiveChannelId: (channelId?: string) => set((state) => ({ ...state, activeChannelId: channelId })),

  setStatusModalOpen: (isOpen: boolean) => set((state) => ({ ...state, isStatusModalOpen: isOpen })),

  setCurrentUser: (user: StreamUser) => set((state) => ({ ...state, currentUser: user })),
  // Set the unread count when the chat is closed
  setUnreadMessageCount: (count: number) => set((state) => ({ ...state, unreadMessageCount: count })),

  setIsFetchingChats: (isFetchingChats: boolean) => set({ isFetchingChats }),
}));

export { TeamChatProvider, useTeamChatStore };
