import { useEffect, useMemo, useState } from 'react';
import { Column, Img, Row, Section } from '@react-email/components';
import { Media } from '@weave/schema-gen-ts/dist/schemas/messaging/media/public/v1/service.pb';
import { PercentCrop } from 'react-image-crop';
import { useComposerSection } from '../../hooks';
import { ComposerSectionOutputProps, ImageSectionProps, UsableComposerSectionProps } from '../../types';
import { getImageCropStyles } from '../../utils';
import { getPaddingStyles } from '../../utils/get-padding-styles';
import { useContentComposer } from '../content-composer-provider';
import { cornersStyle, frameColorStyle, heightStyle } from './styles';

const getImageWidthDifference = (count: number, spacing: number) => ((count - 1) * spacing) / count;

type ImageItemProps = {
  crop?: PercentCrop;
  handleImageReady: () => void;
  image: Media;
  index: number;
  props: UsableComposerSectionProps<ImageSectionProps>;
  width: number | undefined;
  height: number | undefined;
};

const ImageItem = ({ crop, handleImageReady, image, index, props, height, width }: ImageItemProps) => {
  const { deviceSizeProps } = useContentComposer();
  const deviceSize = deviceSizeProps?.value;
  const imageCount = Number(props.count) ?? 1;
  const imageSpacing = Number(props.imageSpacing?.replace('px', '')) || 0;
  const imageWidth = (width ?? 0) - getImageWidthDifference(imageCount, imageSpacing);

  useEffect(() => {
    // if (image?.SecureURL) {
    //   imgRef.current?.setAttribute('src', image?.SecureURL);
    // }
    handleImageReady();
  }, [image]);

  const imgDimensionStyling = getImageCropStyles(imageWidth, height, crop);

  return (
    <>
      <Img
        style={{
          width: deviceSize === 'mobile' ? '100%' : imageWidth,
          height,
          maxWidth: '100%',
          ...getMarginStyle(index, props, deviceSize),
          ...imgDimensionStyling,
          ...cornersStyle(props),
        }}
        src={image?.url}
      />
    </>
  );
};

export const ImageOutput = ({ onRenderReady }: ComposerSectionOutputProps) => {
  const [localImagesReady, setLocalImagesReady] = useState<boolean[]>([]);

  const { props, sectionHTML } = useComposerSection<ImageSectionProps>();

  // to get the unique image elements inside the Image section.
  const imageElements = useMemo(() => sectionHTML?.querySelectorAll('img[data-type="src-image"]'), [sectionHTML]);

  const handleImageReady = (index: number) => {
    setLocalImagesReady((prev) => {
      const copy = [...prev];
      copy[index] = true;
      return copy;
    });
  };

  const images = props.images;

  useEffect(() => {
    if (localImagesReady.length === (images?.length ?? 0) && localImagesReady.every((ready) => ready)) {
      onRenderReady?.();
    }
  }, [localImagesReady, images]);

  return images?.length ? (
    <Section style={{ ...getPaddingStyles(props), ...frameColorStyle(props) }}>
      {images.length === 1 ? (
        <ImageItem
          crop={props?.crops?.[0]}
          handleImageReady={() => handleImageReady(0)}
          image={images[0] as Media}
          index={0}
          props={props}
          width={imageElements?.[0]?.clientWidth}
          height={imageElements?.[0]?.clientHeight}
        />
      ) : (
        <Row className='images-container'>
          {images.map((image, index) =>
            image ? (
              <Column
                key={index}
                className={`image-container image-container-${images.length}`}
                style={{
                  ...heightStyle(props),
                  position: 'relative',
                }}
              >
                <ImageItem
                  crop={props?.crops?.[index]}
                  handleImageReady={() => handleImageReady(index)}
                  image={image as Media}
                  index={index}
                  props={props}
                  width={imageElements?.[index]?.clientWidth}
                  height={imageElements?.[index]?.clientHeight}
                />
              </Column>
            ) : null
          )}
        </Row>
      )}
    </Section>
  ) : null;
};

const getMarginStyle = (
  index: number,
  props: UsableComposerSectionProps<ImageSectionProps>,
  deviceSize = 'desktop'
) => {
  const imageCount = Number(props.count) ?? 0;
  const imageSpacing = Number(props.imageSpacing?.replace('px', '')) || 0;
  const halvedSpacing = imageSpacing / 2;
  const mobileSpacing = { marginBottom: imageSpacing };
  switch (index) {
    case 0:
      if (imageCount === 1) return { margin: '0 auto' };
      return deviceSize === 'mobile' ? mobileSpacing : { marginRight: halvedSpacing };
    case imageCount - 1:
      return deviceSize === 'mobile' ? {} : { marginLeft: halvedSpacing };
    default:
      return deviceSize === 'mobile' ? mobileSpacing : { margin: `0 ${halvedSpacing}px` };
  }
};

export const ImageOutputStyles = () => (
  <style>
    {`.images-container > tbody > tr > .image-container{
        display: flex;
        justify-content: center;
        align-items: center;
      }
    `}
    {`@media (max-width: 480px) {
        .images-container > tbody > tr {
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 8px;
          align-items: center;
          .image-container {
            margin-bottom: 8px; // TODO: need to make this dynamic but this resets the other margin values
            img {
              margin: 0 !important;
            }
          }
        }
      }`}
    {`@media (min-width: 481px) {
      .images-container > tbody > tr {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 8px;

        .image-container-1 {
          width: 100%;
        }
        .image-container-2 {
          width: 50%;
        }
        .image-container-3 {
          width: 33.33%;
        }
      }
    }`}
  </style>
);
