import { Segment } from '@weave/schema-gen-ts/dist/schemas/messaging/bulk/v2';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { BulkEmailPrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import { ListRow, NakedButton, PopoverMenu, Text, usePopoverMenu } from '@frontend/design-system';

type Props = {
  name: string;
  onDelete: () => void;
  segment: Segment;
};

export const SegmentItem = ({ name, onDelete, segment }: Props) => {
  const { t } = useTranslation('bulk-messaging');
  const { getMenuProps, getTriggerProps } = usePopoverMenu();

  return (
    <div
      css={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <div css={{ display: 'flex', flexDirection: 'column', rowGap: theme.spacing(0.5), alignItems: 'flex-start' }}>
        <Text weight='bold' as='span'>
          {name}
        </Text>
        <Text as='span'>
          {segment.recipientCount === 1
            ? t('1 Contact Found')
            : t('{{contact}} Contacts Found', { contact: segment.recipientCount ?? 0 })}
        </Text>
      </div>
      <NakedButton css={{ padding: theme.spacing(1) }} {...getTriggerProps()}>
        <Icon name='more-small' css={{ color: theme.colors.neutral70 }} />
      </NakedButton>
      <PopoverMenu {...getMenuProps()}>
        <ListRow
          css={{
            border: 'unset',
            display: 'flex',
            columnGap: theme.spacing(1),
            padding: theme.spacing(1, 2),
            cursor: 'pointer',
          }}
          onClick={onDelete}
          trackingId={`${BulkEmailPrefixes.Audience}-delete-segment-btn`}
        >
          <Icon name='trash' color='error' />
          <Text color='error'>{t('Delete Segment')}</Text>
        </ListRow>
      </PopoverMenu>
    </div>
  );
};
