import { useMemo } from 'react';
import { useTranslation } from '@frontend/i18n';
import { InsuranceDetailsConstants } from '@frontend/insurance-details';
import { useGetWeaveInsuranceVerificationCustomizationFlagDetails } from '@frontend/insurance-verification';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import {
  Heading,
  RadioField,
  useDebouncedValue,
  useFormField,
  PopoverMenu,
  usePopoverMenu,
  PopoverMenuItem,
  Text,
  ListFieldProps,
  Button,
} from '@frontend/design-system';
import { useGetIntegrationDetails } from '../../../../../../hooks/use-get-integration-details';
import { useScheduleActionsAppointmentListInfoShallowStore } from '../../../../../../stores/use-schedule-actions-appointment-list-store';
import { InsuranceStatusCheckList } from '../../../../components/HeaderBar/Filter/InsuranceStatusCheckList';
import {
  ProviderCheckList,
  Clear,
  ProviderSearchField,
  SelectAll,
} from '../../../../components/HeaderBar/Filter/ProviderCheckList';
import { ScheduleMassMessageFilterTrackingIds } from '../trackingIds';
import { FilterMenuButton } from './FilterMenuButton';

type FilterModalProps = {
  closeFilterModal: () => void;
  appointmentStatuses: string[];
  providerCheckListField: ListFieldProps;
  appointmentStatusRadioField: any;
  insuranceStatusCheckListField: ListFieldProps;
};

const { INSURANCE_STATUS_MAPPING } = InsuranceDetailsConstants;

const INSURANCE_STATUS_COUNT = INSURANCE_STATUS_MAPPING.size;

const INSURANCE_STATUS_VARIANTS = [...INSURANCE_STATUS_MAPPING.values()].map((status) =>
  status.text.toLowerCase().replace(' ', '_')
);

export const FilterModal = ({
  closeFilterModal,
  appointmentStatuses,
  providerCheckListField,
  appointmentStatusRadioField,
  insuranceStatusCheckListField,
}: FilterModalProps) => {
  const { t } = useTranslation('scheduleCalendarActions');
  const { selectedLocationIds } = useAppScopeStore();
  const hasOnlyOneLocation = selectedLocationIds.length === 1;

  const { isIntegratedOffice } = useGetIntegrationDetails({ selectedLocationId: selectedLocationIds[0] });

  const { isFeatureHiddenInAllLocation } = useGetWeaveInsuranceVerificationCustomizationFlagDetails();

  //NOTE - Check if the office is integrated and has Weave Verify enabled, otherwise don't show the ability to filter by insurance status.
  const canUseInsuranceFilters = isIntegratedOffice && !isFeatureHiddenInAllLocation;

  const { defaultProviderList, setProviderList, setHasActiveFilters, setStatusFilter, setInsuranceStatusFilters } =
    useScheduleActionsAppointmentListInfoShallowStore(
      'setProviderList',
      'defaultProviderList',
      'setHasActiveFilters',
      'setStatusFilter',
      'setInsuranceStatusFilters'
    );

  const {
    getTriggerProps: getProviderTriggerProps,
    getMenuProps: getProviderMenuProps,
    getItemProps: getProviderItemProps,
    close: closeProviderCheckList,
  } = usePopoverMenu({
    placement: 'right-start',
    middlewareOptions: {
      offset: {
        mainAxis: 0,
        crossAxis: -8,
      },
    },
  });

  const {
    getTriggerProps: getInsuranceStatusTriggerProps,
    getMenuProps: getInsuranceStatusMenuProps,
    getItemProps: getInsuranceStatusItemProps,
    close: closeInsuranceStatusMenu,
  } = usePopoverMenu({
    placement: 'right-start',
    middlewareOptions: {
      offset: {
        mainAxis: 0,
        crossAxis: -8,
      },
    },
  });

  const closeFilterMenu = () => {
    closeFilterModal();
    closeProviderCheckList();
    closeInsuranceStatusMenu();
  };

  const applyFilters = () => {
    const hasStatusFilter = appointmentStatusRadioField?.value?.toLowerCase() !== 'all';
    const hasProviderFilter = providerCheckListField.value?.length !== defaultProviderList.length;
    const hasInsuranceStatusFilter = !!insuranceStatusCheckListField.value?.length;
    const hasFilters = hasStatusFilter || hasProviderFilter || hasInsuranceStatusFilter;

    setHasActiveFilters(hasFilters);
    setProviderList(providerCheckListField.value ?? []);
    setStatusFilter && setStatusFilter(appointmentStatusRadioField?.value ?? '');
    setInsuranceStatusFilters(insuranceStatusCheckListField.value ?? []);
    closeFilterMenu();
  };

  const searchField = useFormField({ type: 'text' });
  const debouncedSearchTerm = useDebouncedValue(searchField.value);

  const searchProviders = useMemo(() => {
    return defaultProviderList.filter(({ firstName, lastName, publicDisplayName, resourceName }) => {
      // Account for the possibility of the provider name being set to publicDisplayName, or firstName + lastName, or resourceName, in the use-get-providers-details-for-calendar.ts hook.
      const name = publicDisplayName || `${firstName} ${lastName}`.trim() || resourceName || '';
      const nameMatch = name.toLowerCase().replaceAll(/[.,*()]/g, '');
      const search = debouncedSearchTerm.toLowerCase().replaceAll(/[.,*()]/g, '');
      return nameMatch?.includes(search);
    });
  }, [debouncedSearchTerm, defaultProviderList, debouncedSearchTerm]);

  const handleSelectAllProviders = () => {
    providerCheckListField.onChange({
      value: [...searchProviders.map(({ id }) => id), ...(providerCheckListField.value ?? [])],
      name: 'providers',
    });
  };

  const handleClearProviders = () => {
    providerCheckListField.onChange({ value: [], name: 'providers' });
  };

  const handleSelectAllInsuranceStatuses = () => {
    insuranceStatusCheckListField.onChange({
      name: 'appointment-list-insurance-statuses',
      value: [...INSURANCE_STATUS_VARIANTS, ...(insuranceStatusCheckListField.value ?? [])],
    });
  };

  const handleClearInsuranceStatuses = () => {
    insuranceStatusCheckListField.onChange({ value: [], name: 'insurance-statuses' });
  };

  return (
    <>
      {appointmentStatuses.length > 1 && (
        <>
          <Heading level={3} css={{ marginBottom: theme.spacing(2) }}>
            {t('Confirmation Status')}
          </Heading>
          <RadioField
            {...appointmentStatusRadioField}
            name='appointment-status-radio-field'
            label=''
            css={{
              borderBottom: `1px solid ${theme.colors.neutral20}`,
              marginBottom: theme.spacing(1),
              paddingBottom: theme.spacing(1),
            }}
          >
            {appointmentStatuses.map((status) => {
              return (
                <RadioField.Radio key={status} value={status} css={{ textTransform: 'capitalize' }}>
                  {status}
                </RadioField.Radio>
              );
            })}
          </RadioField>
        </>
      )}

      <FilterMenuButton
        getTriggerProps={getProviderTriggerProps}
        selectedCount={providerCheckListField.value?.length}
        menuName={t('Provider')}
        iconName='user'
      />

      {canUseInsuranceFilters && (
        <FilterMenuButton
          getTriggerProps={getInsuranceStatusTriggerProps}
          selectedCount={insuranceStatusCheckListField.value?.length}
          menuName={t('Insurance Status')}
          iconName='shield'
        />
      )}
      <PopoverMenu {...getProviderMenuProps()} css={{ maxWidth: 220, padding: theme.spacing(2) }}>
        <PopoverMenuItem
          as='span'
          {...getProviderItemProps({
            index: 0,
            disableCloseOnSelect: true,
            onFocusCapture: (e) => {
              e.preventDefault();
              e.stopPropagation();
              searchField.onFocus();
            },
            onBlurCapture: (e) => {
              e.preventDefault();
              e.stopPropagation();
              searchField.onBlur();
            },
          })}
          css={{ padding: 0 }}
        >
          <ProviderSearchField searchFieldProps={searchField} />
        </PopoverMenuItem>
        {providerCheckListField.error && (
          <Text css={{ marginTop: theme.spacing(2) }} color='error' size='small'>
            {providerCheckListField.error}
          </Text>
        )}
        <PopoverMenuItem
          as='span'
          {...getProviderItemProps({
            index: 1,
            disableCloseOnSelect: true,
          })}
          css={{
            marginTop: theme.spacing(1),
            padding: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            ':hover': {
              backgroundColor: 'inherit',
            },
            ':focus': {
              backgroundColor: 'inherit',
            },
          }}
        >
          <SelectAll
            isDisabled={false}
            handleSelectAll={handleSelectAllProviders}
            trackingId={ScheduleMassMessageFilterTrackingIds.selectAllProvidersFilterButton}
          />
          <Clear
            handleClear={handleClearProviders}
            trackingId={ScheduleMassMessageFilterTrackingIds.clearProviderFilterButton}
          />
        </PopoverMenuItem>
        <div css={{ padding: theme.spacing(0), paddingRight: 0 }}>
          <ProviderCheckList
            searchProviders={searchProviders}
            checkListProps={providerCheckListField}
            locations={selectedLocationIds}
            hasOnlyOneLocation={hasOnlyOneLocation}
          />
        </div>
      </PopoverMenu>

      {canUseInsuranceFilters && (
        <PopoverMenu {...getInsuranceStatusMenuProps()} css={{ maxWidth: 220, padding: theme.spacing(2) }}>
          <PopoverMenuItem
            as='span'
            {...getInsuranceStatusItemProps({
              index: 1,
              disableCloseOnSelect: true,
            })}
            css={{
              marginTop: theme.spacing(1),
              padding: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              ':hover': {
                backgroundColor: 'inherit',
              },
              ':focus': {
                backgroundColor: 'inherit',
              },
            }}
          >
            <SelectAll
              isDisabled={insuranceStatusCheckListField.value.length === INSURANCE_STATUS_COUNT}
              handleSelectAll={handleSelectAllInsuranceStatuses}
              trackingId={ScheduleMassMessageFilterTrackingIds.selectAllInsuranceStatusesFilterButton}
            />
            <Clear
              handleClear={handleClearInsuranceStatuses}
              trackingId={ScheduleMassMessageFilterTrackingIds.clearInsuranceStatusesFilterButton}
            />
          </PopoverMenuItem>
          <div css={{ padding: theme.spacing(0), paddingRight: 0 }}>
            <InsuranceStatusCheckList checkListProps={insuranceStatusCheckListField} />
          </div>
        </PopoverMenu>
      )}

      <Button variant='primary' onClick={applyFilters}>
        {t('Apply')}
      </Button>
    </>
  );
};
