import { useEffect, useMemo } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { useSettingsNavigate } from '@frontend/settings-routing';
import { sentry } from '@frontend/tracking';
import { theme } from '@frontend/theme';
import { Stepper } from '@frontend/design-system';
import { usePortOrderCreationStore } from '../../providers';
import { AccountInformationStep } from './account-information-step';
import { ActionButtons } from './action-buttons';
import { AddNumbersStep } from './add-numbers-step';
import { PortDetailsStep } from './port-details-step';
import { TermsAndConditionsStep } from './terms-and-conditions-step';
import { UploadPhoneBillsStep } from './upload-phone-bills-step';

export const PortRequestFormWizard = () => {
  const { t } = useTranslation('porting');
  const { navigate: settingsNavigate } = useSettingsNavigate();
  const { selectedLocationIds } = useAppScopeStore();
  const { storePortOrder } = usePortOrderCreationStore(['storePortOrder']);

  useEffect(() => {
    // when location change from global location picker, we need redirect user back to listing page
    if (storePortOrder.locationId && !selectedLocationIds.includes(storePortOrder.locationId)) {
      sentry.warn({
        error: 'Location change detected, redirecting user to phone numbers page from port request form.',
        topic: 'onboarding',
        addContext: {
          name: 'details',
          context: {
            formLocation: storePortOrder.locationId,
            selectedLocations: selectedLocationIds,
          },
        },
      });
      settingsNavigate({ to: '/organization/phone-numbers' });
    }
  }, [storePortOrder.locationId, selectedLocationIds]);

  const stepTitleText = useMemo<Record<number, string>>(
    () => ({
      1: t('Port Details'),
      2: t('Add Numbers'),
      3: t('Account Information'),
      ...(storePortOrder.isSMSHosting
        ? { 4: t('Terms and Conditions') }
        : { 4: t('Upload Phone Bill'), 5: t('Terms and Conditions') }),
    }),
    [storePortOrder.isSMSHosting]
  );

  return (
    <section css={containerStyle}>
      <Stepper css={stepperStyle} isVerticalSingleView stepTitleText={stepTitleText}>
        <Stepper.Card>
          <PortDetailsStep />
        </Stepper.Card>
        <Stepper.Card>
          <AddNumbersStep />
        </Stepper.Card>
        <Stepper.Card>
          <AccountInformationStep />
        </Stepper.Card>
        {!storePortOrder.isSMSHosting && (
          <Stepper.Card>
            <UploadPhoneBillsStep />
          </Stepper.Card>
        )}
        <Stepper.Card>
          <TermsAndConditionsStep />
        </Stepper.Card>
      </Stepper>
      <ActionButtons />
    </section>
  );
};

const containerStyle = css({
  background: theme.colors.white,
  padding: theme.spacing(2, 2, 2, 4),
  maxWidth: 900,
});

const stepperStyle = css({
  maxWidth: 'none',
  '.stepper-vertical': {
    maxHeight: 350,
  },
  '.step-card': {
    borderLeft: `1px solid ${theme.colors.neutral20}`,
    paddingRight: 0,
    paddingTop: 0,
  },
});
