import dayjs from 'dayjs';
import { i18next } from '@frontend/i18n';
import { IconName } from '@frontend/icons';
import { ChipVariants } from '@frontend/design-system';

export enum VerificationError {
  INVALID_DATA = 'Invalid data',
  PAYER_SPECIFIC_ERROR = 'Payer Specific Error',
  WEAVE_PAYER_NOT_SUPPORTED = 'weave_payer_not_supported',
  INACTIVE_MEMBER = 'Inactive Member',
}

export enum RelationshipEnum {
  Self = 'se',
  Spouse = 'sp',
  Child = 'ch',
  Dependent = 'de',
}
export const RELATIONSHIP_LIST = [
  {
    label: i18next.t('Self', { ns: 'insuranceDetails' }),
    value: RelationshipEnum.Self,
  },
  {
    label: i18next.t('Spouse', { ns: 'insuranceDetails' }),
    value: RelationshipEnum.Spouse,
  },
  {
    label: i18next.t('Child', { ns: 'insuranceDetails' }),
    value: RelationshipEnum.Child,
  },
  {
    label: i18next.t('Dependent', { ns: 'insuranceDetails' }),
    value: RelationshipEnum.Dependent,
  },
];

export const INPUT_DATE_FORMAT = 'MM-DD-YYYY';

export type DateRangeOptionValue = 'today' | 'this-week' | 'this-month' | 'this-year';
export const DATE_RANGE_OPTIONS: {
  label: string;
  value: DateRangeOptionValue;
  start: dayjs.Dayjs;
  end: dayjs.Dayjs;
}[] = [
  {
    label: 'Today',
    value: 'today',
    start: dayjs().startOf('day'),
    end: dayjs().endOf('day'),
  },
  {
    label: 'This Week',
    value: 'this-week',
    start: dayjs().startOf('week'),
    end: dayjs().endOf('week'),
  },
  {
    label: 'This Month',
    value: 'this-month',
    start: dayjs().startOf('month'),
    end: dayjs().endOf('month'),
  },
  {
    label: 'This Year',
    value: 'this-year',
    start: dayjs().startOf('year'),
    end: dayjs().endOf('year'),
  },
];

export const MONTH_INFO = {
  startDate: dayjs().startOf('month'),
  endDate: dayjs().endOf('month'),
  monthName: dayjs().format('MMMM'),
};

export const ERROR_MESSAGES = {
  out_of_network: i18next.t('Your dental office is out of network with this insurance provider', {
    ns: 'insuranceDetails',
  }),
  unable_to_respond_at_current_time: i18next.t(
    'Weave is not able to reach this insurance provider right now, try again later',
    { ns: 'insuranceDetails' }
  ),
  provider_not_on_file: i18next.t('Please ensure that your location is registered with the insurance payer', {
    ns: 'insuranceDetails',
  }),
  invalid_missing_subscriber_insured_id: i18next.t(
    "Primary subscriber's memberId is either invalid, missing or unknown",
    { ns: 'insuranceDetails' }
  ),
  invalid_missing_subscriber_insured_name: i18next.t(
    "Primary subscriber's name is either invalid, missing or unknown",
    { ns: 'insuranceDetails' }
  ),
  subscriber_insured_not_found: i18next.t('Primary subscriber is not found with this insurance provider', {
    ns: 'insuranceDetails',
  }),
  subscriber_found_patient_not_found: i18next.t(
    'Primary subscriber was found, but the patient as a dependent was not',
    { ns: 'insuranceDetails' }
  ),
  no_response_received_transaction_terminated: i18next.t(
    'Weave did not get a response from the insurance provider right now, try again later',
    { ns: 'insuranceDetails' }
  ),
  authorization_access_restrictions: i18next.t(
    "Delta Dental has temporary data restrictions regarding the subscriber of your inquiry that prevents disclosure of eligibility data.  Please contact Delta Dental's customer service.",
    { ns: 'insuranceDetails' }
  ),
};

export const INSURANCE_STATUS_MAPPING = new Map<string, { text: string; variant: ChipVariants; iconName: IconName }>([
  [
    'active',
    { text: i18next.t('Verified', { ns: 'scheduleCalendarEvents' }), variant: 'success', iconName: 'shield-small' },
  ],
  [
    'self-pay',
    {
      text: i18next.t('Self Pay', { ns: 'scheduleCalendarEvents' }),
      variant: 'success',
      iconName: 'insurance-self-pay-small',
    },
  ],
  [
    'incomplete',
    {
      text: i18next.t('Incomplete', { ns: 'scheduleCalendarEvents' }),
      variant: 'warn',
      iconName: 'insurance-incomplete-small',
    },
  ],
  [
    'unknown',
    {
      text: i18next.t('Unknown', { ns: 'scheduleCalendarEvents' }),
      variant: 'warn',
      iconName: 'insurance-incomplete-small',
    },
  ],
  [
    'investigate',
    {
      text: i18next.t('Investigate', { ns: 'scheduleCalendarEvents' }),
      variant: 'warn',
      iconName: 'insurance-investigate-small',
    },
  ],
  [
    'inactive',
    {
      text: i18next.t('Inactive', { ns: 'scheduleCalendarEvents' }),
      variant: 'critical',
      iconName: 'insurance-uninsured-small',
    },
  ],
  [
    'error',
    {
      text: i18next.t('Error', { ns: 'scheduleCalendarEvents' }),
      variant: 'critical',
      iconName: 'insurance-error-small',
    },
  ],
]);
