import { useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import {
  Accordion,
  Button,
  Chip,
  Heading,
  SpinningLoader,
  styles,
  Text,
  TextLink,
  useModalControl,
} from '@frontend/design-system';
import { CopyLinkHelper } from '../../components/CopyLinkHelper';
import { WEAVE_HELP_URLS } from '../../constants';
import { useSchedulingLocationInfo } from '../../hooks';
import { useGetIsAppointmentTypeMappedWithPractitioner } from '../../hooks/booking-site/use-get-is-appointment-type-mapped-with-practitioner';
import { getBookingSiteUrl } from '../../utils';
import { EmptyAppointmentTypeMappingModal } from './EmptyAppointmentTypeMappingModal';
import { ProviderAppointmentTypeMappingInstructions } from './ProviderAppointmentTypeMappingInstructions';
import { bookingSiteSetupTrackingIds } from './trackingIds';

interface Props {
  locationPortalSlugMap: Record<string, string>;
}

export const WebsiteTabContent = ({ locationPortalSlugMap }: Props) => {
  const [modalLocationId, setModalLocationId] = useState<string>('');
  const { t } = useTranslation('scheduleGlobalSettings');
  const { modalProps, triggerProps } = useModalControl();
  const { isMultiLocation, selectedLocationIds, parentLocationId, getLocationName } = useSchedulingLocationInfo();

  const {
    hasAnyLocationMapping,
    locationWiseMappingStatus,
    isLoading: isLoadingAppointmentTypeMapping,
  } = useGetIsAppointmentTypeMappedWithPractitioner();

  const bookingSiteUrls = useMemo(() => {
    if (isMultiLocation) {
      return {
        unifyLink: getBookingSiteUrl(parentLocationId, locationPortalSlugMap[parentLocationId]),
        locationUrls: selectedLocationIds.reduce((acc, locationId) => {
          acc[locationId] = getBookingSiteUrl(locationId, locationPortalSlugMap[locationId]);
          return acc;
        }, {} as Record<string, string>),
      };
    }
    return {
      unifyLink: getBookingSiteUrl(selectedLocationIds[0], locationPortalSlugMap[selectedLocationIds[0]]),
      locationUrls: {},
    };
  }, [isMultiLocation, parentLocationId, selectedLocationIds]);

  const handleWarningIconClick = (locationId: string) => {
    setModalLocationId(locationId);
    triggerProps.onClick();
  };

  if (isLoadingAppointmentTypeMapping)
    return (
      <div css={[styles.flexCenter, { marginTop: theme.spacing(2) }]}>
        <SpinningLoader size='medium' />
      </div>
    );

  if (!hasAnyLocationMapping) {
    return <ProviderAppointmentTypeMappingInstructions css={{ marginTop: theme.spacing(2) }} />;
  }

  return (
    <>
      <Heading level={2}>{t('Booking Links')}</Heading>
      <Text>
        {t(
          'Add an appointment booking link to your website or social media pages. Find detailed instructions on setting up your booking link seamlessly across your digital platforms at'
        )}
      </Text>{' '}
      <TextLink
        trackingId={bookingSiteSetupTrackingIds.weaveHelpLink}
        size='large'
        to={WEAVE_HELP_URLS.ADD_ONLINE_SCHEDULING_LINK}
        target='_blank'
      >
        weavehelp.com
      </TextLink>
      {isMultiLocation ? (
        <>
          <section css={sectionStyle}>
            <Heading level={3}>{t('Multi-location booking site URL')}</Heading>
            <Text css={{ marginBottom: theme.spacing(1) }}>
              {t('Customers can view availability and schedule appointments at any of your office locations.')}
            </Text>
            <CopyLinkHelper
              textToCopy={bookingSiteUrls.unifyLink}
              trackingId={bookingSiteSetupTrackingIds.copyLinkButton}
            />
          </section>
          <section css={sectionStyle}>
            <Heading level={3}>{t('Single-location booking site URLs')}</Heading>
            <Text>{t('Customers can view availability and schedule appointments specifically at one location.')}</Text>
          </section>
          <Accordion
            variant='location'
            showBoxShadow
            chevronSize={16}
            startingValue='first'
            size='large'
            css={{
              maxWidth: 700,
              marginTop: theme.spacing(1),
              // TODO: Remove the below line once the Accordion component has ability to show clickable icon in collapsed state when disabled.
              // This is a workaround to absolutely position the warning icon on disabled state of AccordionItem when no mapping is found for the location.
              '& > div': { position: 'relative' },
            }}
          >
            {selectedLocationIds?.map((locationId: string) => {
              const hasMapping = locationWiseMappingStatus[locationId];
              const locationBookingUrl = bookingSiteUrls.locationUrls[locationId];

              return (
                <Accordion.Item value={locationId} key={locationId} css={{ position: 'relative' }}>
                  <Accordion.Header disabled={!hasMapping}>
                    <Accordion.Header.Location title={getLocationName(locationId)} />
                    {!hasMapping && (
                      <Chip variant='default' css={{ marginLeft: theme.spacing(1) }}>
                        {t('Disabled')}
                      </Chip>
                    )}
                  </Accordion.Header>
                  {!hasMapping && (
                    <Button
                      size='large'
                      variant='tertiary'
                      iconName='warning-badge'
                      onClick={() => handleWarningIconClick(locationId)}
                      css={{
                        padding: 0,
                        height: 24,
                        position: 'absolute',
                        top: '50%',
                        transform: 'translateY(-50%)', // Center the icon vertically
                        right: 44, // 16px (chevron icon) + 16px (chevron padding) + 8px (chevron right margin) + 4px (gap as per design) = 44px
                        svg: { color: `${theme.colors.warning50} !important`, background: theme.colors.white }, // override hover color using `!important`
                      }}
                    />
                  )}
                  <Accordion.Body>
                    <CopyLinkHelper
                      textToCopy={locationBookingUrl}
                      trackingId={bookingSiteSetupTrackingIds.copyLinkButton}
                    />
                  </Accordion.Body>
                </Accordion.Item>
              );
            })}
          </Accordion>
          <EmptyAppointmentTypeMappingModal modalProps={modalProps} locationId={modalLocationId} />
        </>
      ) : (
        <section css={sectionStyle}>
          <Heading level={3}>{t("Your location's URL")}</Heading>
          <Text css={{ marginBottom: theme.spacing(1) }}>
            {t('Customers can view availability and schedule appointments with providers at your office.')}
          </Text>
          <CopyLinkHelper
            textToCopy={bookingSiteUrls.unifyLink}
            trackingId={bookingSiteSetupTrackingIds.copyLinkButton}
          />
        </section>
      )}
    </>
  );
};

const sectionStyle = css({
  marginTop: theme.spacing(2),
  paddingRight: theme.spacing(1),
});
