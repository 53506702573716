import { useMemo } from 'react';
import dayjs from 'dayjs';
import { DashboardAnalyticsProgressCircle } from '@frontend/components';
import { useTranslation } from '@frontend/i18n';
import { calculatePercentage } from '@frontend/math';
import { useAppScopeStore } from '@frontend/scope';
import { useFetchAggregatedCallsSummary } from '../components/phone/hooks';
import { appendTime } from '../utils';

const endOfWeek = dayjs();
const startOfWeek = dayjs().startOf('week');

export const CallsAnswered = () => {
  const { getSelectedLocationData, selectedLocationIds } = useAppScopeStore();
  const { t } = useTranslation('analytics');

  const { data } = useFetchAggregatedCallsSummary({
    end_date: appendTime(endOfWeek),
    location_id: selectedLocationIds,
    start_date: appendTime(startOfWeek),
    time_zone: getSelectedLocationData()[selectedLocationIds[0]]?.timezone,
  });

  const callsAnsweredPercentage = useMemo(
    () => calculatePercentage(data?.answerRate?.answered || 0, data?.totalCalls?.inbound || 0),
    [data]
  );

  return <DashboardAnalyticsProgressCircle label={t('Calls Answered')} percentage={callsAnsweredPercentage} />;
};
