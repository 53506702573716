import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { CustomizationFlagQueries } from '@frontend/api-customization-flags';
import { useAppScopeStore } from '@frontend/scope';

export const useHasListHomeIconAccess = () => {
  const { selectedLocationIds } = useAppScopeStore();
  const { isFeatureHiddenInAllLocations: isHiddenInAllLocations } =
    CustomizationFlagQueries.useAggregateCustomizationFlagDetails({
      locationIds: selectedLocationIds,
      enabled: true,
      customizationFlag: Feature.LIST_HOME_ICON,
    });
  return !isHiddenInAllLocations;
};
