import { useTranslation } from '@frontend/i18n';
import { Modal, ModalControlModalProps, Text } from '@frontend/design-system';

interface Props {
  modalProps: ModalControlModalProps;
  disableCloseOnEscape?: boolean;
  message?: string;
}

export const ErrorModal = ({ modalProps, message, disableCloseOnEscape = false }: Props) => {
  const { t } = useTranslation('portingLoa');

  return (
    <Modal {...modalProps} disableCloseOnEscape={disableCloseOnEscape}>
      <Modal.Header>{t('Something went wrong')}</Modal.Header>
      <Modal.Body>
        <Text>{message}</Text>
      </Modal.Body>
    </Modal>
  );
};
