import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { SkeletonLoader } from '@frontend/design-system';

const DEFAULT_CHAT_ITEM_COUNT = 5;
const DEFAULT_CHAT_ITEM_HEIGHT = 18;
const CHAT_AVATAR_SHIMMER_WIDTH = 24;
const CHAT_AVATAR_SHIMMER_HEIGHT = 24;
const ODD_SKELETON_WIDTH = 226;
const EVEN_SKELETON_WIDTH = 255;

export const ChatListItemShimmer: React.FC = () => {
  return (
    <>
      {Array.from({ length: DEFAULT_CHAT_ITEM_COUNT }).map((_, index) => (
        <div key={`chat-item-loader-${index}`} css={containerStyle}>
          <SkeletonLoader shape='circle' height={CHAT_AVATAR_SHIMMER_HEIGHT} width={CHAT_AVATAR_SHIMMER_WIDTH} />
          <SkeletonLoader height={DEFAULT_CHAT_ITEM_HEIGHT} />
        </div>
      ))}
    </>
  );
};

const containerStyle = css({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  padding: theme.spacing(0.5),
  marginBottom: '2px',
  ':nth-of-type(odd)': {
    'div:nth-child(2)': {
      width: ODD_SKELETON_WIDTH,
    },
  },
  ':nth-of-type(even)': {
    'div:nth-child(2)': {
      width: EVEN_SKELETON_WIDTH,
    },
  },
});
