import { useNavigate } from '@tanstack/react-location';
import { Trans, useTranslation } from '@frontend/i18n';
import { Text, TextLink } from '@frontend/design-system';

interface IProps {
  warningDayCount: number;
}

export const OverdueBy40DaysMessage = ({ warningDayCount }: IProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation('billing-alerts');

  const goToBilling = () => {
    navigate({ to: '/portal/account/billing' });
  };
  return (
    <Trans t={t} count={warningDayCount}>
      <Text>
        Your bill is critically overdue. If your account balance is not resolved{' '}
        <TextLink as='span' onClick={goToBilling} trackingId='billing-overdue-40-resolve-balance-here-btn'>
          here
        </TextLink>{' '}
        within the next {{ count: warningDayCount }} days, your account will be suspended.
      </Text>
    </Trans>
  );
};
