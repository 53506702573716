import { http } from '@frontend/fetch';
import { SchemaCommPreference } from '@frontend/schema';
import { useScopedQuery } from '@frontend/scope';
import {
  GenerateManagementUrlsRequest,
  GenerateManagementUrlsResponse,
} from '@weave/schema-gen-ts/dist/schemas/comm-preference/preference/v1/preference_service.pb';
import { MarketingBusinessFields } from './types';

const businessSettingUrl = 'portal/v1/email/settings';

export const useGetBusinessSettings = () =>
  useScopedQuery({
    queryKey: 'business-settings',
    queryFn: async (): Promise<Record<'data', MarketingBusinessFields>> => await http.get(businessSettingUrl),
    select: (res) => res.data,
  });

export const useGenerateManagementUrls = (req: GenerateManagementUrlsRequest) =>
  useScopedQuery({
    queryKey: 'preference-center-link',
    queryFn: async (): Promise<GenerateManagementUrlsResponse> =>
      await SchemaCommPreference.GenerateManagementUrls(req),
    enabled: !!req.locationId && !!req.channel && !!req.userChannelAddress,
  });
