import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { IconButton, Modal, Text, useModalControl } from '@frontend/design-system';

export const PortTypesInfoIcon = () => {
  const { t } = useTranslation('porting');
  const { modalProps, triggerProps } = useModalControl();

  return (
    <>
      <IconButton label={t('Port Types Information')} size='small' {...triggerProps} css={iconButtonStyle}>
        <Icon name='info' size={16} color='light' />
      </IconButton>
      <Modal {...modalProps} maxWidth={600}>
        <Modal.Header onClose={modalProps.onClose}>{t('Port Types')}</Modal.Header>
        <Modal.Body>
          <dl css={dlStyle}>
            <div>
              <Text as='dt' weight='bold'>
                {t('Full Port')}
              </Text>
              <Text as='dd'>
                {t(
                  "This port will be for your phone, text, and fax lines. The entirety of your number will transfer and you'll be able to answer calls from these numbers as well as text from them if you choose. Choose this option if you are interested in porting your fax number."
                )}
              </Text>
            </div>

            <div>
              <Text as='dt' weight='bold'>
                {t('SMS Hosting')}
              </Text>
              <Text as='dd'>
                {t(
                  'This port type is specifically for SMS purposes only. The voice portion of your number will stay with your current provider and only the SMS portion will transfer to Weave. You will still answer calls from this number on your current phones, but you will be able to use this number to text within Weave.'
                )}
              </Text>
            </div>
          </dl>
        </Modal.Body>
        <Modal.Footer primary={{ label: t('Close'), onClick: modalProps.onClose }} />
      </Modal>
    </>
  );
};

const iconButtonStyle = css({
  height: 'auto',
  padding: 0,
  width: 'auto',
  minWidth: 'auto',
});

const dlStyle = css({
  margin: theme.spacing(4, 0),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
});
