import { CountryCodes } from '@frontend/geography';
import { AddressInfo, PhoneProviderInfo } from './types';

export const defaultAddressInfo: AddressInfo = {
  address1: '',
  address2: '',
  city: '',
  state: '',
  country: CountryCodes.USA,
  postal: '',
};

export const defaultProviderInfo: PhoneProviderInfo = {
  businessName: '',
  name: '',
  accountPin: '',
  accountNumber: '',
  authorizedUser: '',
  infoCorrect: false,
  officeEmail: '',
};
