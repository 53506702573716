import { FC } from 'react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Modal, Text, ModalControlModalProps } from '@frontend/design-system';
import { trackingIds } from '../../constants';

interface Props extends ModalControlModalProps {
  isOnboarder: boolean;
}

export const OldRequestModal: FC<React.PropsWithChildren<Props>> = ({ isOnboarder, ...modalProps }) => {
  const { t } = useTranslation('porting', { keyPrefix: 'phoneNumbers' });
  return (
    <Modal maxWidth={650} {...modalProps}>
      <Modal.Header closeBtnTrackingId={trackingIds.closeRequestModal} onClose={modalProps.onClose}>
        {t('Changing or Canceling your Request')}
      </Modal.Header>
      <Modal.Body>
        <Text css={{ marginBottom: theme.spacing(4), marginTop: theme.spacing(1) }}>
          {t(
            'We strongly advise against changing porting dates once a port request is accepted. Any changes to your port request needs to happen within 72 hours of the accepted port date, although changes are not always possible. Last minute changes may result in disruption or even loss of service or phone numbers. Please contact {{person}} if you need to change or cancel your request.',
            {
              person: isOnboarder ? 'your onboarder' : 'support',
            }
          )}
        </Text>
      </Modal.Body>
      <Modal.Footer primary={{ label: t('Close'), onClick: modalProps.onClose }} />
    </Modal>
  );
};
