const DEFAULT_PADDING = '0px';

const getPaddingValue = (value: string) => {
  const isOnlyNumbers = /^\d+$/.test(value);
  return isOnlyNumbers ? `${value}px` : value;
};

type FramePaddingStyleProps = {
  paddingBottom?: string;
  paddingLeft?: string;
  paddingRight?: string;
  paddingTop?: string;
};

export const getPaddingStyles = ({ paddingTop, paddingRight, paddingBottom, paddingLeft }: FramePaddingStyleProps) => {
  const top = paddingTop ? getPaddingValue(paddingTop) : DEFAULT_PADDING;
  const right = paddingRight ? getPaddingValue(paddingRight) : DEFAULT_PADDING;
  const bottom = paddingBottom ? getPaddingValue(paddingBottom) : DEFAULT_PADDING;
  const left = paddingLeft ? getPaddingValue(paddingLeft) : DEFAULT_PADDING;
  return { padding: `${top} ${right} ${bottom} ${left}` };
};
