import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

interface ButtonGroupProps {
  breakpoint: number;
  children: React.ReactNode;
}

export const ToolbarButtonGroup = ({ breakpoint, children }: ButtonGroupProps) => (
  <div
    css={css`
      display: inline-flex;
      column-gap: ${theme.spacing(0.5)};
      @media (max-width: ${breakpoint}px) {
        display: none;
      }
    `}
  >
    {children}
  </div>
);

export const PopoverButtonGroup = ({ breakpoint, children }: ButtonGroupProps) => (
  <div
    css={css`
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: ${theme.spacing(1)};

      button {
        width: auto;
        min-width: unset;
      }

      @media (min-width: ${breakpoint + 1}px) {
        display: none;
      }
    `}
  >
    {children}
  </div>
);
