import { ReactNode } from 'react';
import { LocationAudience } from '@weave/schema-gen-ts/dist/schemas/messaging/bulk/v2/service.pb';
import { SegmentedBarTypes } from '@frontend/segmented-bar';
import { ContentLoader, ModalControlModalProps, Tray } from '@frontend/design-system';
import { Cards } from '../cards';
import { LocationUsageAndAllotmentWithLimit } from '../type';
import { TrayFooter } from './tray-footer';
import { TrayHeader } from './tray-header';

type Props = {
  audienceCount?: LocationAudience[];
  chartData: Record<string, SegmentedBarTypes.Data[]>;
  data?: LocationUsageAndAllotmentWithLimit[];
  isLoading: boolean;
  modalProps: ModalControlModalProps;
  onBack: () => void;
  onSave: () => void;
  setData: (value: LocationUsageAndAllotmentWithLimit[]) => void;
  title?: ReactNode;
};

export const MessageAllotmentsFilters = ({
  audienceCount,
  chartData,
  data,
  isLoading,
  modalProps,
  onBack,
  onSave,
  setData,
  title,
}: Props) => {
  // Just need an example of what the legend will look like
  const first = chartData[Object.keys(chartData)[0]];

  return (
    <Tray {...modalProps} width='medium'>
      <Tray.Header>
        <TrayHeader legendOptions={first} title={title} />
      </Tray.Header>
      <Tray.Body>
        {isLoading ? (
          <ContentLoader show />
        ) : (
          <Cards data={data} chartData={chartData} audienceCount={audienceCount} setData={setData} />
        )}
      </Tray.Body>
      <TrayFooter onSave={onSave} onBack={onBack} />
    </Tray>
  );
};
