import { removeLeadingSlash } from '@frontend/uri';

const home = '';

type PathMapRoute = {
  old: string;
  new: string;
};

/** Note:
 * when adding routes to this list:
 * make sure to order static routes before dynamic routes
 * that share the same number of route segments
 *
 * Route params will be injected from left to right in order.
 * If the new route has more route params, the extras obviously won't receive anything.
 */
export const pathMap: PathMapRoute[] = [
  //test
  { old: 'testing/:param1/deeper/:param2/:param3', new: 'new-testing/deeper/:param1/:param2/:param3' },

  //dashboard
  { old: 'dashboard', new: '' },

  //sales-dev
  { old: 'account-settings/billing', new: 'account/billing' },
  { old: 'account-settings/billing/invoice/:invoiceId', new: 'account/billing/invoice/:invoiceId' },

  //onboarding
  { old: 'onboarding', new: 'onboarding/form' },

  //marketing
  { old: 'marketing', new: 'marketing/campaigns' },

  //Reviews
  { old: 'reviews', new: 'reviews/analytics' },

  //Fax
  { old: 'fax-history', new: 'fax' },

  //Insurance Writebacks
  { old: 'insurance/writeback-logs', new: 'writeback-logs' },
  { old: 'insurance/writeback-logs/source/:sourceID/person/:personID', new: 'writeback-logs' },

  //Morning Huddle
  { old: 'analytics/morning-huddle/dashboard', new: home },
  { old: 'analytics/morning-huddle/settings', new: home },

  //Multi Reporting
  { old: 'multi-reporting', new: home },

  //Locatinos Access
  { old: 'data-sources/locations-access', new: home },
  { old: 'data-sources/integrations/manage', new: 'data-sync' },
  { old: 'data-sources/integrations', new: 'data-sync' },
  { old: 'data-sources', new: 'data-sync' },

  //Messages
  { old: 'auto-message-queue', new: 'messages/message-queue' },
  { old: 'auto-reminder-settings-v2/status-mapping', new: 'messages/reminder-settings' },
  { old: 'auto-reminder-settings-v2', new: home },
  { old: 'auto-reminder-presets', new: home },
  { old: 'weave-connection', new: 'messages/weave-pms-connection' },

  //Phone
  { old: 'phone-system', new: 'phone/devices' },
  { old: 'phone-system/voicemail-greetings', new: 'phone/voicemail-boxes' },
  { old: 'phone-system', new: 'phone' },
  { old: 'phone-system/devices', new: 'phone/devices' },
  { old: 'phone-system/devices/:deviceId', new: 'phone/devices/:deviceId' },
  { old: 'phone-system/main-line', new: 'phone/main-line' },
  { old: 'phone-system/main-line/:departmentId', new: 'phone/main-line/:departmentId' },
  { old: 'phone-system/departments', new: 'phone/departments' },
  { old: 'phone-system/departments/:departmentId', new: 'phone/departments/:departmentId' },
  { old: 'phone-system/call-records', new: 'phone/call-records' },
  { old: 'phone-system/voicemail-override', new: 'phone/voicemail-override' },
  { old: 'phone-system/call-blocking', new: 'phone/call-blocking' },
  { old: 'phone-system/call-groups', new: 'phone/call-groups' },
  { old: 'phone-system/call-groups/:callGroupId', new: 'phone/call-groups/:callGroupId' },
  { old: 'phone-system/call-queues', new: 'phone/call-queues' },
  { old: 'phone-system/call-queues/:callQueueId', new: 'phone/call-queues/:callQueueId' },
  { old: 'phone-system/media-library', new: 'phone/media-library' },
  { old: 'phone-system/phone-tree', new: 'phone/phone-tree' },
  { old: 'phone-system/phone-tree/:IVRMenuId', new: 'phone/phone-tree/:IVRMenuId' },
  { old: 'phone-system/voicemail-greetings', new: 'phone/voicemail-greetings' },
  { old: 'phone-system/voicemail-boxes', new: 'phone/voicemail-boxes' },
  { old: 'phone-system/voicemail-boxes/:mailboxId', new: 'phone/voicemail-boxes/:mailboxId' },
  {
    old: 'phone-system/voicemails/mailboxes/:mailboxId/messages/:messageId',
    new: 'phone/voicemails/mailboxes/:mailboxId/messages/:messageId',
  },
  { old: 'phone-system/hold-music', new: 'phone/hold-music' },

  //Verify
  { old: 'verify-beta', new: 'subscriptions' },
  { old: 'verify-sign-up', new: 'subscriptions' },

  // Referral
  { old: 'referral', new: home },

  //Subscriptions
  { old: 'subscriptions/verify-beta', new: 'subscriptions/verify-sign-up' },
  { old: 'subscriptions/verify-sign-up', new: 'subscriptions/verify-sign-up' },

  //Users Management
  { old: 'employees/employee-list', new: 'account/users' },
];

const isParam = (str: string) => str.startsWith(':');

/**
 *
 * Here is how this works.
 * The user's current route is compared to all of the 'old' routes from admin portal
 *  We find this by checking the current route, and each old route, and comparing each segment of the two.
 *  Any segment on the old route that is a route param, is considered a match for that segment
 *  If a route match is found (all segments are matches):
 *    We create a list of the indexes of :param segments of the old route
 *    Then for the 'new' route, for each :param segment n, we inject the value of the current route's value of segment n
 *    This replaces path variables from left to right from the old route to the new route respectively
 *    And now we have the final path for redirection
 *
 * For example:
 *  The user hits route /things/123/children/456/sections/7
 *  There is a route in the map with
 *    old: /things/:thingId/children/:childId/section/:sectionId
 *    new: /special-settings/things/:thingId/:childId/:sectionId
 *  So our route would match old, the :params would be replaced and we'd end up with:
 *  /special-settings/things/123/456/7

 * @returns string
 */
export const injectParamsfromPortalRoute = (route: string, oldPath: string, newPath: string) => {
  const routeSegments = route
    .trim()
    .replace(/^\/|\/$/g, '')
    .split('/');

  const paramIdxs = oldPath.split('/').reduce((acc, segment, i) => {
    return isParam(segment) ? [...acc, i] : acc;
  }, [] as number[]);

  return newPath
    .split('/')
    .map((segment) => (isParam(segment) ? routeSegments[paramIdxs.shift() as number] : segment))
    .join('/');
};

export const findRoute = (route: string, routes: PathMapRoute[] = pathMap) => {
  const routeSegments = route
    .trim()
    .replace(/^\/|\/$/g, '')
    .split('/');
  return routes.find((path) => {
    const segments = path.old.split('/');
    return (
      segments.length === routeSegments.length &&
      segments.every((segment, i) => isParam(segment) || segment === routeSegments[i])
    );
  });
};

export const getNewRouteFromLegacyPortalRoute = (route: string, routes: PathMapRoute[] = pathMap) => {
  const replaced = route.replace('portal/', '');
  const match = findRoute(replaced, routes);
  return match ? ('/portal/' + injectParamsfromPortalRoute(replaced, match.old, match.new)).replace('//', '') : route;
};

export const transFormAndExtractLocationIdFromPortalRoute = (path: string) => {
  const cleanPath = removeLeadingSlash(path);
  const params = new URLSearchParams(window.location.search);
  if (cleanPath.match(new RegExp(/^\/?admin\/([0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[0-9A-F]{4}-[0-9A-F]{12})\/.*/gi))) {
    //For routes like /admin/[location-id]/[path]
    const locationId = removeLeadingSlash(cleanPath).split('/')[1];
    return { locationId, transformedRoute: 'portal/' + cleanPath.split('/').slice(2).join('/') };
  } else if (
    cleanPath.match(new RegExp(/^\/?location\/([0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[0-9A-F]{4}-[0-9A-F]{12})\/.*/gi))
  ) {
    //For routes like /location/[location-id]/[path]
    const locationId = removeLeadingSlash(cleanPath).split('/')[1];
    return { locationId, transformedRoute: cleanPath.split('/').slice(2).join('/') };
  } else if (cleanPath.match(new RegExp(/^\/?([0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[0-9A-F]{4}-[0-9A-F]{12})\/.*/gi))) {
    //For routes like /[location-id]/[path]
    const locationId = removeLeadingSlash(cleanPath).split('/')[0];
    return { locationId, transformedRoute: 'portal/' + cleanPath.split('/').slice(1).join('/') };
  } else if (cleanPath.match(/^\/?admin\//) && params.get('forwardPath')) {
    //For routes like /admin/[path]?forwardPath=[path]
    const forwardPath = removeLeadingSlash(params.get('forwardPath') ?? '');
    const resolvedPath = cleanPath + forwardPath;
    const locationId = removeLeadingSlash(resolvedPath).split('/')[1];
    return { locationId, transformedRoute: 'portal/' + resolvedPath.split('/').slice(2).join('/') };
  } else if (cleanPath.match(/^\/?admin\//)) {
    //For routes like /admin/[path]
    const transformedRoute = cleanPath.replace('admin/', 'portal/');
    return { locationId: undefined, transformedRoute };
  }
  return { locationId: undefined, transformedRoute: undefined };
};
