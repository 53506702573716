import { ReactElement, useEffect, useState } from 'react';
import { Campaign, Status_Enum as CampaignStatus } from '@weave/schema-gen-ts/dist/schemas/messaging/bulk/v2';
import { DaysOfWeek } from '@weave/schema-gen-ts/dist/schemas/messaging/shared/v1/bulk.pb';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import { useQueryClient } from 'react-query';
import {
  BulkMessagingMutations,
  BulkMessagingQueries,
  BulkMessagingTypes,
  BulkMessagingUtils,
} from '@frontend/api-bulk-messaging';
import { ClientSettingsApi, ClientSettingsQueries } from '@frontend/api-client-settings';
import { MediaQueries } from '@frontend/api-media';
import { SettingsSection } from '@frontend/components';
import { formatDate, getTodaysDate } from '@frontend/date';
import { FeatureAccessFlags, FeatureAccessPermissions, useFeatureAccess } from '@frontend/feature-access';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { BulkEmailPrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import {
  Button,
  ButtonBar,
  CheckboxField,
  FormFieldActionTypes,
  FormRow,
  Heading,
  StepStatus,
  Stepper,
  Text,
  useAlert,
  useForm,
} from '@frontend/design-system';
import { useBulkEmailEditorShallowStore, useBulkMessagingNavigator, useMonthUsageBreakdown } from '../../hooks';
import { getUniqueItems, sortItems } from '../../utils';
import { BulkEmailSegmentedBar } from '../bulk-email-segmented-bar';
import {
  BACKEND_DATE_TIME_FORMAT,
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  DATE_TIME_SECOND_FORMAT,
  MAX_BATCH_RECIPIENTS,
  TIME_FORMAT,
  getNextMinTime,
} from '../utils';
import { ReplyTo } from './reply-to';
import { useAttachmentStep } from './use-attachment-step';
import { useAudienceStep } from './use-audience-step';
import { useEmailContentStep } from './use-email-content-step';
import { useLocationStep } from './use-location-step';
import { useTitleStep } from './use-title-step';
import { useWhenToSendStep } from './use-when-to-send-step';

dayjs.extend(customParseFormat);
dayjs.extend(utc);

export enum SendType {
  SendAll = 'send-all',
  SendSpecific = 'send-specific',
}

type DayToSend = 'sunday' | 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday';

type Props = {
  campaign?: Campaign;
  isUnscheduled: boolean;
  onSaveDraft?: (state: Campaign) => Promise<string>;
  onSaveHiddenDraft?: (state: Campaign) => Promise<string>;
  onSchedule: (state: Campaign) => Promise<string>;
};

const defaultDaysToSend: DayToSend[] = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'];

const convertToDaysToSend = (daysOfWeek: DaysOfWeek) => {
  const values: DayToSend[] = [];
  let key: keyof DaysOfWeek;
  for (key in daysOfWeek) {
    if (daysOfWeek[key] === true) {
      values.push(key);
    }
  }
  return values;
};

const convertToDaysOfWeek = (daysToSend: DayToSend[]) =>
  daysToSend.reduce((acc, value) => ({ ...acc, [value]: true }), {}) as DaysOfWeek;

export type StepId = 'title' | 'locations' | 'when-to-send' | 'email-content' | 'audience' | 'attachments';
export type Step = {
  id: StepId;
  Component: ReactElement;
  collapsedValue: ReactElement;
  readValue?: ReactElement;
  hide?: boolean;
};

// TODO: Break this massive component up into smaller hooks, components, and chunks
export const BulkEmailEditorStepper = ({
  campaign,
  isUnscheduled,
  onSaveHiddenDraft,
  onSaveDraft,
  onSchedule,
}: Props) => {
  const { t } = useTranslation('bulk-messaging');
  const alert = useAlert();

  const store = useBulkEmailEditorShallowStore(
    'activeStep',
    'attachments',
    'audienceCount',
    'campaignId',
    'consent',
    'enableValidation',
    'getAsCampaign',
    'locationIds',
    'sendDaysOfWeek',
    'sendPerDay',
    'setActiveStep',
    'setAttachments',
    'setAudienceCount',
    'setCampaignId',
    'setConsent',
    'setEnableValidation',
    'setLocationIds',
    'setSendDaysOfWeek',
    'setSendPerDay',
    'setStartSendAt',
    'setTitle',
    'startSendAt',
    'subject',
    'templateJson',
    'title'
  );

  const { selectedOrgId: orgId } = useAppScopeStore();
  const { locationsWithEnabledFlag, locationsWithACLAccess } = useFeatureAccess(
    FeatureAccessFlags.emailMarketing,
    FeatureAccessPermissions.emailMarketing
  );

  // invalidate drafts cache
  const queryClient = useQueryClient();
  const draftsQueryKey = BulkMessagingQueries.keys.draftCampaigns();
  const invalidateDrafts = (draft: Campaign) => {
    const previous = queryClient.getQueryData<Campaign[]>(draftsQueryKey);
    const newDrafts = BulkMessagingUtils.sortCampaigns([...(previous ?? []), draft]);
    queryClient.invalidateQueries(draftsQueryKey);
    queryClient.setQueryData(draftsQueryKey, { draftCampaigns: newDrafts });
  };

  const navigateTo = useBulkMessagingNavigator(true);

  const readOnlyStatuses = [
    CampaignStatus.CANCELED,
    CampaignStatus.COMPLETED,
    CampaignStatus.DELETED,
    CampaignStatus.PROCESSING,
  ];
  const startSendAtPlus15Minutes = dayjs(campaign?.startSendAt).add(15, 'minutes').format(BACKEND_DATE_TIME_FORMAT);
  const now = dayjs().format(BACKEND_DATE_TIME_FORMAT);
  const within15MinutesOrPast = startSendAtPlus15Minutes < now;
  const readOnly =
    !!(
      readOnlyStatuses.find((status) => status === campaign?.currentStatus) ||
      (campaign?.currentStatus === CampaignStatus.SCHEDULED && within15MinutesOrPast)
    ) && !isUnscheduled;

  const todaysDate = getTodaysDate(DATE_FORMAT);
  const {
    getFieldProps,
    seedValues,
    validate: validateForm,
    values,
  } = useForm({
    computeChangedValues: true,
    fields: {
      title: {
        type: 'text',
        value: store.title,
        required: true,
        placeholder: t('Name Your Campaign'),
      },
      locationIds: {
        type: 'checklist',
        value: store.locationIds ?? [],
        required: true,
        placeholder: t('Select Locations'),
      },
      date: {
        type: 'datePicker',
        value: formatDate(store.startSendAt, DATE_FORMAT),
        required: !readOnly,
        minDate: todaysDate,
        maxDate: dayjs().add(1, 'year').format(DATE_FORMAT),
      },
      time: {
        type: 'time',
        value: formatDate(store.startSendAt, TIME_FORMAT) || getNextMinTime(todaysDate, dayjs().hour()),
        required: !readOnly,
        hidden: !store.startSendAt,
      },
      sendOptions: {
        type: 'radio',
        value: store.sendPerDay > 0 ? SendType.SendSpecific : SendType.SendAll,
        required: !readOnly,
        hidden: !store.startSendAt,
      },
      sendInterval: {
        type: 'number',
        value: store.sendPerDay > 0 ? store.sendPerDay.toString() : '',
        min: 1,
        max: MAX_BATCH_RECIPIENTS, // TODO: What is the maximum number of recipients?
      },
      daysToSend: {
        type: 'checklist',
        value: store.sendDaysOfWeek ? convertToDaysToSend(store.sendDaysOfWeek) : defaultDaysToSend,
        required: !readOnly,
        hidden: store.sendPerDay === 0,
      },
      attachments: {
        type: 'multiselect',
        value: store.attachments,
        hidden: store.locationIds.length > 1,
      },
      consent: {
        type: 'checkbox',
        required: !readOnly,
        hidden: readOnly,
        value: store.consent,
      },
    },
    fieldStateReducer: (state, action) => {
      if (action.type !== FormFieldActionTypes.Update) return null;

      if (action.payload.name === 'date') {
        return {
          ...state,
          // TODO: Fix the time dropdown when switching between dates
          time: {
            ...state.time,
            minTime: state.date.value === todaysDate ? getNextMinTime(state.date.value as string) : '0:00',
            hidden: action.payload.value === '',
          },
          sendOptions: {
            ...state.sendOptions,
            hidden: action.payload.value === '',
          },
          daysToSend: {
            ...state.daysToSend,
            hidden: action.payload.value === '' || state.sendOptions.value !== SendType.SendSpecific,
          },
        };
      }

      if (action.payload.name === 'sendOptions') {
        return {
          ...state,
          daysToSend: {
            ...state.daysToSend,
            hidden: action.payload.value !== SendType.SendSpecific,
          },
        };
      }

      return state;
    },
  });

  const {
    campaignPlanned,
    displayedMonth,
    monthAllotment,
    otherCampaignsPending,
    otherCampaignsUsed,
    remainingCampaigns,
  } = useMonthUsageBreakdown(getFieldProps('date').value, campaign, store.audienceCount);

  const props = {
    attachments: getFieldProps('attachments'),
    consent: getFieldProps('consent'),
    date: getFieldProps('date'),
    daysToSend: getFieldProps('daysToSend'),
    locationIds: getFieldProps('locationIds'),
    sendInterval: getFieldProps('sendInterval'),
    sendOptions: getFieldProps('sendOptions'),
    time: getFieldProps('time'),
    title: getFieldProps('title'),
  };

  useEffect(() => {
    const uniqueLocationIdsToAdd = getUniqueItems(props.locationIds.value, store.locationIds);
    if (uniqueLocationIdsToAdd?.length) {
      seedValues({ locationIds: uniqueLocationIdsToAdd });
    }
  }, [sortItems([...store.locationIds]).join(',')]);

  // For various reasons, this validation is better handled outside of the useForm hook
  const sendIntervalError =
    values.sendOptions === SendType.SendSpecific &&
    (props.sendInterval.error || (values.sendInterval === '' && props.sendInterval.touched))
      ? props.sendInterval.error || t('This field is required')
      : '';

  const displayedLocations = [...new Set([...locationsWithACLAccess, ...(campaign?.locationIds ?? [])])];
  const showLocationsSelectorStep = locationsWithEnabledFlag.length > 1;
  const hideAttachmentStep = props.locationIds.value.length > 1;

  // media manager attachments by location
  const singularLocationId = props.locationIds.value[0]; // we only care when there is only one location selected
  const { data: allAttachments = [] } = MediaQueries.useGetAttachmentMedia(
    singularLocationId ? [singularLocationId] : undefined
  );

  const getSelectorSteps = () => {
    const steps: StepStatus = {
      1: 'active',
      2: 'inactive',
      3: 'inactive',
      4: 'inactive',
    };

    if (showLocationsSelectorStep) {
      steps[5] = 'inactive';
    }
    if (!hideAttachmentStep) {
      steps[6] = 'inactive';
    }
    return steps;
  };

  const [stepStatus, setStepStatus] = useState<StepStatus>(getSelectorSteps());

  const {
    querySetKeys: { sets, keys },
  } = ClientSettingsApi;
  const { useGetSetting } = ClientSettingsQueries;
  const { data: replyTo, isLoading: isReplyToLoading } = useGetSetting(sets.email, keys.email, {
    enabled: locationsWithEnabledFlag.length > 0,
    onError: () => {
      alert.error(t('Failed to get reply-to email address.'));
    },
  });

  const saveToStore = () => {
    const {
      attachments,
      consent = false,
      title = '',
      locationIds = [],
      date,
      time,
      sendOptions,
      sendInterval,
      daysToSend,
    } = values;
    const startSendAt =
      date && time ? formatDate(dayjs(`${date} ${time}`, DATE_TIME_FORMAT), BACKEND_DATE_TIME_FORMAT) : '';
    const sendPerDay = sendOptions === SendType.SendAll ? 0 : sendInterval ? parseInt(sendInterval) : 0;
    const sendDaysOfWeek =
      sendPerDay === 0
        ? null
        : daysToSend
        ? convertToDaysOfWeek(daysToSend as DayToSend[])
        : ({} as ReturnType<typeof convertToDaysOfWeek>);

    store.setTitle(title);
    store.setLocationIds(locationIds);
    store.setStartSendAt(startSendAt);
    store.setSendPerDay(sendPerDay);
    store.setSendDaysOfWeek(sendDaysOfWeek);
    store.setAttachments(attachments);
    store.setConsent(consent);
  };

  const createHiddenDraft = async () => {
    if (!store.campaignId) {
      try {
        if (!onSaveHiddenDraft) return;
        saveToStore();
        const state = store.getAsCampaign();
        const campaignId = await onSaveHiddenDraft(state);
        store.setCampaignId(campaignId);
      } catch (error) {
        alert.error(t('An unexpected error occurred. Please try again.'));
        console.error(error);
      }
    }
  };

  const saveDraft = async () => {
    try {
      if (!onSaveDraft) return;
      saveToStore();
      const state = store.getAsCampaign();
      const newCampaignId = await onSaveDraft(state);
      const createdAt = dayjs.utc().format(BACKEND_DATE_TIME_FORMAT);
      const campaignId = store.campaignId || newCampaignId;
      invalidateDrafts({ ...state, campaignId, createdAt });
      navigateTo.drafts();
    } catch (error) {
      alert.error(
        t(
          'An unexpected error occurred while saving your campaign. Please try again. If the problem persists, contact support.'
        )
      );
    }
  };

  const scheduleCampaign = async () => {
    try {
      saveToStore();
      validateEditor();

      const dateTimeValue = dayjs(`${values.date} ${values.time}`, DATE_TIME_SECOND_FORMAT);
      const isDateTimeComplete =
        !props.date.error && !props.time.error && dateTimeValue.isValid() && dateTimeValue.isAfter(dayjs());

      const isSendIntervalComplete =
        values.sendOptions === SendType.SendAll || (!props.sendInterval.error && values.sendInterval !== '');

      const isDaysToSendComplete =
        values.sendOptions === SendType.SendAll || (!props.daysToSend.error && values.daysToSend?.length !== 0);

      const isEditorComplete =
        (readOnly && !props.title.error) ||
        (!readOnly &&
          !props.title.error &&
          !isLocationIdsError &&
          isDateTimeComplete &&
          isSendIntervalComplete &&
          isDaysToSendComplete &&
          !!store.templateJson &&
          !!store.subject &&
          !!store.audienceCount &&
          !props.consent.error &&
          !!replyTo);

      if (!isEditorComplete) return;

      if (!store.campaignId) {
        alert.error(t('An unexpected error occurred. Please try again or contact support.'));
        console.error(
          'Cannot schedule campaign. A campaign ID has somehow not been set and yet there is an audience count. How on earth has this happened?'
        );
        return;
      }

      const state = store.getAsCampaign();
      await onSchedule(state);
      alert.success(isUnscheduled ? t('Email campaign scheduled!') : t('Email campaign updated!'));
      navigateTo.campaigns();
    } catch (error) {
      if (isUnscheduled) {
        alert.error(
          isUnscheduled
            ? t(
                'An unexpected error occurred while scheduling your campaign. Please try again. If the problem persists, contact support.'
              )
            : t(
                'An unexpected error occurred while updating your campaign. Please try again. If the problem persists, contact support.'
              )
        );
      }
    }
  };

  const openComposer = () => {
    saveToStore();
    if (!store.templateJson) {
      navigateTo.newEmail();
      return;
    }
    navigateTo.emailComposer();
  };

  const { mutateAsync: createAudienceSegment } = BulkMessagingMutations.useCreateSegment(
    {
      onError: () => {
        alert.error(t('An error occurred while saving your segment. Please try again or contact support.'));
      },
    },
    () => {
      alert.success(t('Segment added to campaign.'));
    }
  );

  const { refetch: refetchAudience } = BulkMessagingQueries.useGetAudience({ campaignId: store.campaignId, orgId });
  const [isCreatingSegment, setIsCreatingSegment] = useState(false);
  const handleCreateSegment = async (filters?: BulkMessagingTypes.AudienceFilters, listId?: string) => {
    if (props.locationIds.value.length === 0) return;
    if (!store.campaignId) {
      alert.error(t('An unexpected error occurred. Please contact support.'));
      console.error('Cannot save audience filters. A campaign ID has not been set.');
      return;
    }

    setIsCreatingSegment(true);
    await createAudienceSegment({
      campaignId: store.campaignId,
      orgId,
      segment: {
        filterOptions: filters,
        segmentId: '', // TODO: What should a new segmentId be?
        listId,
      },
    });

    const { data: audience } = await refetchAudience();
    store.setAudienceCount(audience?.total ?? 0);
    setIsCreatingSegment(false);
  };

  const validateEditor = () => {
    store.setEnableValidation(true);
    validateForm();
  };

  const emailError = store.enableValidation
    ? store.templateJson
      ? store.subject
        ? ''
        : t('A subject is required')
      : t('Email content is required')
    : '';
  const audienceError = store.enableValidation ? (store.audienceCount ? '' : t('An audience is required')) : '';

  const isTitleError = props.title.touched && !!props.title.error;
  const isDateError = props.date.touched && !!props.date.error;
  const isTimeError = props.time.touched && !!props.time.error;
  const isDaysToSendError =
    values.sendOptions === SendType.SendSpecific &&
    (!!props.daysToSend.error || (values.daysToSend?.length === 0 && props.daysToSend.touched));
  const isLocationIdsError = showLocationsSelectorStep ? !!props.locationIds.error : false;
  // checks the list of available media and make sure that it's available for the selected location
  const missingAttachments = (store.attachments || [])?.filter(
    (id) => !allAttachments?.find((media) => media.ID === id)
  );
  const isAttachmentError = store.attachments ? !!missingAttachments.length : false;
  const isConsentError = props.consent.touched && !!props.consent.error;

  const [touched, setTouched] = useState<Record<StepId, boolean>>({
    title: false,
    locations: false,
    'when-to-send': false,
    'email-content': false,
    audience: false,
    attachments: false,
  });
  const attachmentsIndex = showLocationsSelectorStep ? 5 : 4;

  const updateStepperStatus = () => {
    if (readOnly) {
      if (showLocationsSelectorStep && !hideAttachmentStep) {
        setStepStatus({ 1: 'active', 2: 'completed', 3: 'completed', 4: 'completed', 5: 'completed', 6: 'completed' });
      } else if (showLocationsSelectorStep) {
        setStepStatus({ 1: 'active', 2: 'completed', 3: 'completed', 4: 'completed', 5: 'completed' });
      } else {
        setStepStatus({ 1: 'active', 2: 'completed', 3: 'completed', 4: 'completed' });
      }
      return;
    }

    const errors: boolean[] = [
      isTitleError,
      isDateError || isTimeError || isDaysToSendError || !!sendIntervalError,
      !!emailError,
      !!audienceError,
    ];
    if (showLocationsSelectorStep) {
      errors.splice(1, 0, !!props.locationIds.error);
    }
    if (!hideAttachmentStep) {
      errors.push(isAttachmentError);
    }

    const isSendOptionsPopulated =
      values.sendOptions === SendType.SendSpecific ? !!values.sendInterval && !!values.daysToSend : true;
    const populated: boolean[] = [
      !!values.title,
      !!values.date && !!values.time && isSendOptionsPopulated,
      !!store.templateJson,
      !!store.audienceCount,
    ];
    if (showLocationsSelectorStep) {
      populated.splice(1, 0, !!values.locationIds);
    }
    if (!hideAttachmentStep) {
      populated.push(!!store.attachments);
    }

    const newStepStatus: StepStatus = {};
    errors.forEach((error, index) => {
      const key = index + 1;
      if (key === store.activeStep) {
        if (error) {
          newStepStatus[key] = 'errorActive';
        } else {
          newStepStatus[key] = 'active';
        }
      } else {
        if (error) {
          newStepStatus[key] = 'error';
        } else if (populated[index] && index === attachmentsIndex) {
          newStepStatus[key] = touched.attachments ? 'completed' : 'inactive';
        } else if (populated[index]) {
          newStepStatus[key] = 'completed';
        } else {
          newStepStatus[key] = 'inactive';
        }
      }
    });

    setStepStatus(newStepStatus);
  };

  useEffect(() => {
    updateStepperStatus();
  }, [
    store.activeStep,
    store.enableValidation,
    isTitleError,
    isDateError,
    isTimeError,
    isDaysToSendError,
    sendIntervalError,
    emailError,
    audienceError,
    isLocationIdsError,
  ]);

  useEffect(() => {
    if (store.enableValidation) {
      validateForm();
    }
  }, [store.enableValidation]);

  const getMaxStep = () => {
    let maxStep = 4;
    if (showLocationsSelectorStep) maxStep += 1;
    if (!hideAttachmentStep) maxStep += 1;
    return maxStep;
  };
  const maxStep = getMaxStep();

  const next = (stepId: StepId) => {
    const nextStep = Math.min(store.activeStep + 1, maxStep);
    store.setActiveStep(nextStep);
    const nextStepIdIndex = stepsData.findIndex((step) => step.id === stepId) + 1;
    const nextStepId = stepsData[nextStepIdIndex]?.id;
    if (nextStepId) setTouched({ ...touched, [nextStepId]: true });
  };

  const previous = (stepId: StepId) => {
    const previousStep = Math.max(store.activeStep - 1, 1);
    store.setActiveStep(previousStep);
    const previousStepIdIndex = stepsData.findIndex((step) => step.id === stepId) - 1;
    const previousStepId = stepsData[previousStepIdIndex]?.id;
    if (previousStepId) setTouched({ ...touched, [previousStepId]: true });
  };

  const getValidStepNumber = (step: number): number => {
    if (step > maxStep) return maxStep;
    if (step < 1) return 1;
    return step;
  };

  const skipTo = (step: number, stepId: StepId) => {
    if (step === store.activeStep) return;
    const validatedStep = getValidStepNumber(step);
    store.setActiveStep(validatedStep);
    setTouched({ ...touched, [stepId]: true });
  };

  const stepperControlProps = {
    next,
    previous,
  };

  const titleStep = useTitleStep({ fieldProps: props.title, readOnly, ...stepperControlProps });

  const locationStep = useLocationStep({
    displayedLocations,
    locationIdsFieldProps: props.locationIds,
    locationsWithACLAccess,
    hide: !showLocationsSelectorStep,
    ...stepperControlProps,
  });

  const whenToSendStep = useWhenToSendStep({
    fieldProps: {
      date: props.date,
      time: props.time,
      sendOptions: props.sendOptions,
      sendInterval: props.sendInterval,
      daysToSend: props.daysToSend,
    },
    readOnly,
    sendIntervalError,
    ...stepperControlProps,
  });

  const emailContentStep = useEmailContentStep({ emailError, openComposer, ...stepperControlProps });

  const audienceStep = useAudienceStep({
    createHiddenDraft,
    locationIds: props.locationIds.value,
    ...stepperControlProps,
    next: !hideAttachmentStep ? next : undefined,
    handleCreateSegment,
    isCreatingSegment,
  });

  const attachmentStep = useAttachmentStep({
    ...stepperControlProps,
    allAttachments,
    fieldProps: props.attachments,
    hide: hideAttachmentStep,
    missingAttachments,
    readOnly,
    selectedLocationId: singularLocationId,
  });

  const stepsData = (
    [titleStep, locationStep, whenToSendStep, emailContentStep, audienceStep, attachmentStep] as Step[]
  ).filter((item) => !item.hide);

  return (
    <>
      <BulkEmailSegmentedBar
        additional={campaignPlanned}
        month={displayedMonth}
        pending={otherCampaignsPending}
        quota={monthAllotment}
        sent={otherCampaignsUsed}
        showAllotments={showLocationsSelectorStep}
      />
      {readOnly ? (
        <div
          css={{ display: 'flex', flexDirection: 'column', rowGap: theme.spacing(2), marginBottom: theme.spacing(6) }}
        >
          {stepsData.map((step, index) => (
            <SettingsSection key={index}>{step.readValue ?? step.collapsedValue}</SettingsSection>
          ))}
        </div>
      ) : (
        <Stepper
          stepStatus={stepStatus}
          shouldScrollToCard={false}
          css={{ maxWidth: 'initial', marginRight: 0, marginBottom: theme.spacing(6) }}
        >
          {stepsData.map((step, index) => (
            <Stepper.Card
              key={index}
              css={StepperCardStyles(readOnly)}
              onClick={readOnly ? undefined : (stepNumber) => skipTo(stepNumber, step.id)}
              preventDefaultOnClick
              stepValue={step.collapsedValue}
            >
              {step.Component}
            </Stepper.Card>
          ))}
        </Stepper>
      )}
      <section css={{ display: 'flex', marginBottom: theme.spacing(3) }}>
        <div
          css={{
            border: `solid 1px ${theme.colors.neutral20}`,
            borderRadius: theme.borderRadius.medium,
            marginRight: theme.spacing(2),
            padding: theme.spacing(2),
          }}
        >
          <Heading level={2}>{campaignPlanned.toLocaleString()}</Heading>
          <Text>{t('Emails Will Be Used')}</Text>
        </div>
        <div
          css={{
            border: `solid 1px ${theme.colors.neutral20}`,
            borderRadius: theme.borderRadius.medium,
            padding: theme.spacing(2),
            color: remainingCampaigns < 0 ? theme.font.colors.error : theme.colors.neutral90,
          }}
        >
          <Heading level={2} css={{ color: 'inherit' }}>
            {remainingCampaigns.toLocaleString()}
          </Heading>
          <Text css={{ color: 'inherit' }}>{t('Emails Remaining After Send')}</Text>
        </div>
      </section>
      <ReplyTo
        isMultiLocation={showLocationsSelectorStep}
        isReplyToLoading={isReplyToLoading}
        replyTo={replyTo}
        css={{ marginBottom: theme.spacing(3) }}
      />
      <FormRow>
        <CheckboxField
          css={{
            div: {
              alignSelf: 'center',
            },
            label: {
              fontSize: theme.font.size.small,
              color: isConsentError ? theme.font.colors.error : theme.font.colors.default,
            },
          }}
          {...props.consent}
          label={t(
            "Several laws in the US and Canada require that emails sent by businesses to consumers are appropriate and are sent only after receiving the individual's consent. By checking this box, you confirm that the recipients of this email have opted-in to receive messages."
          )}
          trackingId={`${BulkEmailPrefixes.Editor}-consent-checkbox`}
        />
      </FormRow>
      {isConsentError && (
        <Text color='error' size='small' css={{ margin: theme.spacing(-1, 0, 3, 0) }}>
          {props.consent.error}
        </Text>
      )}
      <ButtonBar css={{ justifyContent: 'start', padding: 0 }}>
        <Button onClick={scheduleCampaign} trackingId={`${BulkEmailPrefixes.Editor}-schedule-campaign-btn`}>
          {isUnscheduled ? t('Schedule Campaign') : t('Update Campaign')}
        </Button>
        {!!onSaveDraft && (
          <Button variant='secondary' onClick={saveDraft} trackingId={`${BulkEmailPrefixes.Editor}-save-draft-btn`}>
            {t('Save as Draft')}
          </Button>
        )}
      </ButtonBar>
    </>
  );
};

const StepperCardStyles = (readOnly: boolean) => ({
  '&[aria-current="false"]': {
    cursor: readOnly ? 'not-allowed' : 'pointer',
    height: 'fit-content !important', // This is needed since the height is a style prop
    '.step-content-title': {
      overflow: 'initial',
    },
  },
  '.step-content-title': {
    height: 'fit-content',
  },
});
