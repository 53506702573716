import { ReactNode } from 'react';
import { Trans, useTranslation } from '@frontend/i18n';
import { BulkEmailPrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import { Button, Heading, Text, TextLink, useModalControl } from '@frontend/design-system';
import { PrivacyRulesTray } from '../privacy-rules-tray';
import { Mode } from '../types';

const FormModeTrayHeader = () => {
  const { t } = useTranslation('bulk-messaging');
  const { modalProps, triggerProps } = useModalControl();

  return (
    <>
      <Text color='subdued'>
        <Trans t={t}>
          Select the filters that describe the contacts you are targeting.
          <TextLink
            {...triggerProps}
            css={{ marginLeft: theme.spacing(0.75) }}
            trackingId={`${BulkEmailPrefixes.Audience}-privacy-rules-btn`}
          >
            Privacy Rules
          </TextLink>
        </Trans>
      </Text>
      <PrivacyRulesTray modalProps={modalProps} />
    </>
  );
};

type Props = {
  mode?: Mode;
  setMode: (mode: Mode) => void;
  title?: ReactNode;
};

export const TrayHeader = ({ mode, setMode, title }: Props) => {
  const { t } = useTranslation('bulk-messaging');
  const hasBack = mode !== 'display';

  return (
    <div>
      <div css={{ display: 'flex', columnGap: theme.spacing(1), alignItems: 'center' }}>
        {hasBack && (
          <Button
            variant='tertiary'
            iconName='caret-left'
            onClick={() => setMode('display')}
            css={{
              svg: {
                color: theme.colors.neutral90,
                height: 20,
                width: 20,
              },
            }}
          />
        )}
        <Heading level={2} css={{ margin: 0 }}>
          {title ?? t('Create Audience')}
        </Heading>
      </div>
      {mode === 'form' && <FormModeTrayHeader />}
    </div>
  );
};
