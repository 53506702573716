import { useLayoutEffect } from 'react';
import { ZendeskWidgetType, zendesk } from '@frontend/tracking';

export const useSuppressZendeskWidget = (widgetName: ZendeskWidgetType) => {
  useLayoutEffect(() => {
    const supressSettingId = zendesk.suppressWidget(widgetName);

    return () => zendesk.removeSuppressWidgetSettingById(supressSettingId);
  }, []);
};
