import { ReactNode } from 'react';
import { OnboardingModulesTypes } from '@frontend/api-onboarding-modules';
import { i18next } from '@frontend/i18n';
import { IconName } from '@frontend/icons';
import { NavigateArgs, StaticSettingsRoutes } from '@frontend/settings-routing';
import { theme } from '@frontend/theme';
import { Text, TextLink } from '@frontend/design-system';

export const LOCAL_STORAGE_KEYS = {
  INCOMPLETE_TASKS_INTRO_MODAL_SHOWN: 'weave.incomplete-tasks-intro-modal-shown',
};

export const NEW_SINGLE_SOFTWARE_ONLY_FLOW_FEATURE_FLAG =
  'onboarding:enable-new-software-only-single-location-intake-form';

export const trackingIds = {
  nwxDashboardTasksSection: {
    // task card buttons
    verifyBusinessInformationButton: 'nwx-dashboard-tasks-section:verify-business-information-button',
    inviteUserToWeaveButton: 'nwx-dashboard-tasks-section:invite-user-to-weave-button',
    scheduleTimeNowButton: 'nwx-dashboard-tasks-section:schedule-time-now-button',
    setupPaymentsButton: 'nwx-dashboard-tasks-section:setup-payments-button',

    // task incomplete intro dialog
    taskIncompleteIntroDialog: {
      closeButton: 'nwx-dashboard-tasks-section:task-incomplete-intro-dialog:close-button',
      finishLaterButton: 'nwx-dashboard-tasks-section:task-incomplete-intro-dialog:finish-later-button',
      verifyBusinessInformationButton:
        'nwx-dashboard-tasks-section:task-incomplete-intro-dialog:verify-business-information-button',
      inviteUserToWeaveButton: 'nwx-dashboard-tasks-section:task-incomplete-intro-dialog:invite-user-to-weave-button',
      scheduleTimeNowButton: 'nwx-dashboard-tasks-section:task-incomplete-intro-dialog:schedule-time-now-button',
      einTaskArticleLink: 'nwx-dashboard-tasks-section:task-incomplete-intro-dialog:ein-task-article-link',
    },

    // schedule call dialog
    scheduleCallDialog: {
      closeButton: 'nwx-dashboard-tasks-section:schedule-call-dialog:close-button',
      finishLaterButton: 'nwx-dashboard-tasks-section:schedule-call-dialog:finish-later-button',
      scheduleCallButton: 'nwx-dashboard-tasks-section:schedule-call-dialog:schedule-call-button',
      chillPiperClickHereLinkButton:
        'nwx-dashboard-tasks-section:schedule-call-dialog:chill-piper-click-here-link-button',
    },
  },
};

// TODO: Update these guide IDs with the correct ones once they are available
const TASK_PENDO_GUIDE_IDS: Partial<Record<OnboardingModulesTypes.TaskIds, string>> = {
  [OnboardingModulesTypes.TaskIds.EINSetup]: 'vswWYW66Ho9ZLNG4nCBSp6dqED8',
  [OnboardingModulesTypes.TaskIds.UserInviteSetup]: 'jCdj8Mn8y924yIwnZ4rEZLpY69k',
  [OnboardingModulesTypes.TaskIds.PaymentsStripeSetup]: 'n9M3q_ekMQxGkihJPZCW-eyLkCg',
};

interface DialogInfo {
  title: string;
  description: string | ReactNode;
  buttonText: string;
}

export type NWXDashboardTaskInfo = {
  id: OnboardingModulesTypes.TaskIds;
  icon: IconName;
  title: string;
  description: string;
  isCompleted?: boolean;
  isRequiredTask?: boolean;
  // dialogInfo is required for tasks that are required
  dialogInfo?: DialogInfo;
  settingsRedirectConfig?: NavigateArgs<StaticSettingsRoutes>;
};

const BUSINESS_INFO_TASK_ARTICLE_LINK =
  'https://www.weavehelp.com/hc/en-us/articles/4405882188955-Text-Messaging-Brand-Registration-FAQ';

// This is the list of tasks that will be displayed on the NWX dashboard
// for only single software only locations
export const NWX_DASHBOARD_TASK_LIST: NWXDashboardTaskInfo[] = [
  {
    id: OnboardingModulesTypes.TaskIds.EINSetup,
    icon: 'building-small',
    title: i18next.t('Verify Business Information', { ns: 'onboarding-modules' }),
    description: i18next.t(
      'Submit your Employer Identification Information to comply with US and Canadian regulations regarding communication.',
      { ns: 'onboarding-modules' }
    ),
    isRequiredTask: true,
    dialogInfo: {
      title: i18next.t('Verify Business Information', { ns: 'onboarding-modules' }),
      description: (
        <>
          <Text as='span' css={{ marginRight: theme.spacing(1) }}>
            {i18next.t('We need to verify your business information so you can keep using some features.', {
              ns: 'onboarding-modules',
            })}
          </Text>
          <TextLink
            href={BUSINESS_INFO_TASK_ARTICLE_LINK}
            target='_blank'
            rel='noopener noreferrer'
            css={{ color: theme.colors.neutral90, textDecoration: 'underline' }}
            trackingId={trackingIds.nwxDashboardTasksSection.taskIncompleteIntroDialog.einTaskArticleLink}
          >
            {i18next.t('Why is this important?', { ns: 'onboarding-modules' })}
          </TextLink>
        </>
      ),
      buttonText: i18next.t('Verify Information', { ns: 'onboarding-modules' }),
    },
    settingsRedirectConfig: {
      to: '/organization/business-verification',
      search: {
        pendo: TASK_PENDO_GUIDE_IDS[OnboardingModulesTypes.TaskIds.EINSetup],
      },
    },
  },
  {
    id: OnboardingModulesTypes.TaskIds.UserInviteSetup,
    icon: 'assign-user-small',
    title: i18next.t('Invite Users to Weave', { ns: 'onboarding-modules' }),
    description: i18next.t(
      'Invite coworkers (office manager, office administrator, etc) to your organization and set their user permissions.',
      {
        ns: 'onboarding-modules',
      }
    ),
    isRequiredTask: true,
    dialogInfo: {
      title: i18next.t('Invite Users', { ns: 'onboarding-modules' }),
      description: i18next.t(
        'Invite coworkers (office manager, office administrator, etc) to your organization and set their user permissions.',
        {
          ns: 'onboarding-modules',
        }
      ),
      buttonText: i18next.t('Invite Users', { ns: 'onboarding-modules' }),
    },
    settingsRedirectConfig: {
      to: '/organization/users',
      search: {
        invite: 'true',
        pendo: TASK_PENDO_GUIDE_IDS[OnboardingModulesTypes.TaskIds.UserInviteSetup],
      },
    },
  },
  {
    id: OnboardingModulesTypes.TaskIds.ScheduleCallWithOnboarder,
    icon: 'phone-call-small',
    title: i18next.t('Schedule Time Now', { ns: 'onboarding-modules' }),
    description: i18next.t('Let your Onboarding Manager help connect Weave with your practice management system.', {
      ns: 'onboarding-modules',
    }),
    isRequiredTask: true,
    dialogInfo: {
      title: i18next.t('Schedule With a Weave Onboarder', { ns: 'onboarding-modules' }),
      description: i18next.t(
        'Our onboarding specialist will walk you through advanced configurations like setting up data sync.',
        {
          ns: 'onboarding-modules',
        }
      ),
      buttonText: i18next.t('Schedule a Call', { ns: 'onboarding-modules' }),
    },
  },
  {
    id: OnboardingModulesTypes.TaskIds.PaymentsStripeSetup,
    icon: 'payments-small',
    title: i18next.t('Set Up Payments', { ns: 'onboarding-modules' }),
    description: i18next.t('Streamline your billing workflow and collect more by setting up your payments account.', {
      ns: 'onboarding-modules',
    }),
    settingsRedirectConfig: {
      to: '/payments',
      search: {
        pendo: TASK_PENDO_GUIDE_IDS[OnboardingModulesTypes.TaskIds.PaymentsStripeSetup],
      },
    },
  },
];
