import { useEffect, useState } from 'react';
import { CampaignType_Enum as CampaignType, Recipient } from '@weave/schema-gen-ts/dist/schemas/messaging/bulk/v2';
import { List } from '@weave/schema-gen-ts/dist/schemas/messaging/bulk/v2/service.pb';
import { BulkMessagingMutations, BulkMessagingQueries, BulkMessagingTypes } from '@frontend/api-bulk-messaging';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { BulkEmailPrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import { Button, ModalControlModalProps, Tabs, Tray, useModalControl } from '@frontend/design-system';
import { MessageAllotmentsFiltersModal } from '../message-allotment';
import { SavedListModalContent } from '../saved-list/saved-list-modal-content';
import { Mode, TabMode } from '../types';
import { SegmentsModalContent } from './segments-modal-content';
import { TrayFooter } from './tray-footer';
import { TrayHeader } from './tray-header';
import { useCountSegmentRecipients } from './use-count-segment-recipients';

type Props = {
  accessibleLocationIds: string[];
  audienceCount?: number;
  campaignId: string;
  closeModal: () => void;
  duplicateCount?: number;
  isCreatingSegment: boolean;
  modalProps: ModalControlModalProps;
  onCreateSegment: (filters?: BulkMessagingTypes.AudienceFilters, listId?: string) => void;
  setAudienceCount: (count?: number) => void;
};

export const AudienceSelectionTray = ({
  accessibleLocationIds,
  audienceCount,
  campaignId,
  closeModal,
  duplicateCount,
  isCreatingSegment,
  modalProps,
  onCreateSegment,
  setAudienceCount,
}: Props) => {
  const { t } = useTranslation('bulk-messaging');
  const [mode, setMode] = useState<Mode>('display');
  const [filters, setFilters] = useState<BulkMessagingTypes.AudienceFilters>({});
  const [tabMode, setTabMode] = useState<TabMode>('segments');
  const [selectedListIds, setSelectedListIds] = useState<string[]>([]);

  const { selectedOrgId: orgId } = useAppScopeStore();
  const { data: segments } = BulkMessagingQueries.useListSegments({ campaignId, orgId });

  useEffect(() => {
    if (segments) {
      const segmentsWithListIds = segments
        .filter((segment) => !!segment.listId)
        .map((segment) => segment.listId as string); // in the map we know it's a string
      setSelectedListIds(segmentsWithListIds);
    }
  }, [segments]);

  const { modalProps: messageAllotmentsModalProps, triggerProps: messageAllotmentsTriggerProps } = useModalControl();
  const recipientCount = useCountSegmentRecipients(campaignId, filters);
  const [recipientsToUpload, setRecipientsToUpload] = useState<Recipient[]>([]);

  const [pageToken, setPageToken] = useState<string | undefined>(undefined);
  const [lists, setLists] = useState<List[]>([]);

  const {
    data,
    queryKey: listQueryKey,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    isFetched,
  } = BulkMessagingQueries.useListLists({
    locationId: accessibleLocationIds[0],
    pageSize: 25,
    pageToken,
    types: [CampaignType.EMAIL],
  });

  useEffect(() => {
    const newData = data?.pages[0]?.lists ?? [];
    const newLists = [...newData, ...lists];
    const filteredLists = newLists.filter(
      (list, index, arr) => arr.findIndex((l) => l.listId === list.listId) === index
    );
    setLists(filteredLists);
    return () => {
      setLists([]);
    };
  }, [pageToken, isFetched]);

  const getNextPage = () => {
    if (hasNextPage && !isFetchingNextPage) {
      setPageToken(data?.pages[0].nextPageToken);
      fetchNextPage();
    }
  };

  const getTotalSegmentsAndSaveListsRecipientCount = () => {
    const savedListsRecipientCount =
      accessibleLocationIds.length === 1
        ? selectedListIds
            .map((listId) => lists.find((list) => list.listId === listId)?.recipientCount ?? 0)
            .reduce((acc, count) => acc + count, 0)
        : 0;
    const segmentsRecipientCount =
      segments?.filter((list) => !list.listId)?.reduce((acc, segment) => acc + (segment?.recipientCount || 0), 0) ?? 0;
    return segmentsRecipientCount + savedListsRecipientCount;
  };
  const totalSegmentsAndSaveListsRecipientCount = getTotalSegmentsAndSaveListsRecipientCount();

  const getTrayHeaderTitle = () => {
    if (mode === 'form') {
      return t('Create a Segment');
    } else if (mode === 'csv') {
      return t('Upload CSV List');
    }
    return;
  };

  const getSourceCountText = () => {
    if (audienceCount && selectedListIds.length) {
      return t('From Segments and Saved Lists');
    } else if (selectedListIds.length) {
      return t('From Saved Lists');
    } else if (audienceCount) {
      return t('From Segments');
    }
    return tabMode === 'segments' ? t('From Segments') : t('From Saved Lists');
  };

  const { mutate: deleteSegment } = BulkMessagingMutations.useDeleteSegment();

  return (
    <Tray
      {...modalProps}
      width='large'
      css={{
        padding: theme.spacing(4, 0),
        gap: 'unset',
        '.tray-header': {
          alignItems: mode === 'form' ? 'flex-start' : 'center',
          padding: theme.spacing(0, 4),
        },
      }}
    >
      <Tray.Header
        Buttons={
          <Button
            variant='tertiary'
            hoverLabel={t('close')}
            iconName='x'
            onClick={() => {
              closeModal();
              setMode('display');
            }}
            css={{ svg: { color: theme.colors.neutral90 } }}
          />
        }
      >
        <TrayHeader mode={mode} setMode={setMode} title={getTrayHeaderTitle()} />
      </Tray.Header>

      {/* show the tabs + saved lists only when the user has exactly one selected location because we don't know which location these contacts will be saved to or come from */}
      {accessibleLocationIds.length === 1 ? (
        <Tabs controlledTab={tabMode} onChange={(value) => setTabMode(value as TabMode)}>
          {/* only show the tabs in the display view */}
          {mode === 'display' ? (
            <Tabs.Bar fullWidth css={{ margin: theme.spacing(2, 4, 0) }}>
              <Tabs.Tab
                id='segments'
                controls='segments-panel'
                trackingId={`${BulkEmailPrefixes.Audience}-segments-tab`}
              >
                {t('Segments')}
              </Tabs.Tab>
              <Tabs.Tab
                id='saved-lists'
                controls='saved-lists-panel'
                trackingId={`${BulkEmailPrefixes.Audience}-saved-lists-tab`}
              >
                {t('Saved Lists')}
              </Tabs.Tab>
            </Tabs.Bar>
          ) : null}
          <Tray.Body css={{ display: 'flex', flexDirection: 'column', padding: theme.spacing(0, 4) }}>
            <Tabs.Panel id='segments-panel' controller='segments' css={tabPanelStyling}>
              <SegmentsModalContent
                accessibleLocationIds={accessibleLocationIds}
                campaignId={campaignId}
                filters={filters}
                isCreatingSegment={isCreatingSegment}
                mode={mode}
                segments={segments?.filter((segment) => !segment.listId)}
                setFilters={setFilters}
                setMode={setMode}
              />
            </Tabs.Panel>
            <Tabs.Panel id='saved-lists-panel' controller='saved-lists' css={tabPanelStyling}>
              <SavedListModalContent
                campaignId={campaignId}
                getNextPage={getNextPage}
                hasMore={!!(isFetched && hasNextPage)}
                isFetchingNextPage={!!isFetchingNextPage}
                lists={lists}
                locationId={accessibleLocationIds[0]}
                mode={mode}
                queryKey={listQueryKey}
                recipientsToUpload={recipientsToUpload}
                selectedListIds={selectedListIds}
                setLists={setLists}
                setMode={setMode}
                setRecipientsToUpload={setRecipientsToUpload}
                setSelectedListIds={setSelectedListIds}
              />
            </Tabs.Panel>
          </Tray.Body>
        </Tabs>
      ) : (
        <SegmentsModalContent
          accessibleLocationIds={accessibleLocationIds}
          campaignId={campaignId}
          filters={filters}
          isCreatingSegment={isCreatingSegment}
          mode={mode}
          segments={segments?.filter((segment) => !segment.listId)}
          setFilters={setFilters}
          setMode={setMode}
        />
      )}

      <TrayFooter
        // for the display mode, this first iteration we will display the summation of the recipients from the segments and saved lists and not the audienceCount
        // TODO: product/design is going to think through how we want to juggle both the audienceCount while still showing the summation in real-time in a future iteration
        audienceCount={mode === 'form' ? recipientCount : totalSegmentsAndSaveListsRecipientCount}
        duplicateCount={duplicateCount}
        isValid={true}
        mode={mode}
        onCancel={() => {
          setMode('display');
          if (mode === 'display') closeModal();
        }}
        onConfirm={() => {
          const isSavedListsDisplay = mode === 'display' && tabMode === 'saved-lists';
          const isSegmentsDisplay = mode === 'display' && tabMode === 'segments';

          if (isSavedListsDisplay) {
            // delete the segments that are not selected but was previously selected (only the ones that have listId)
            const deletedListIds = segments?.filter(
              (segment) => segment.listId && !selectedListIds.includes(segment.listId as string)
            );
            deletedListIds?.forEach((segment) => deleteSegment({ campaignId, segmentId: segment.segmentId, orgId }));

            // add the selected lists to the segments
            const newListIds = selectedListIds.filter(
              (listId) => !segments?.find((segment) => segment.listId === listId)
            );
            newListIds.forEach((listId) => {
              onCreateSegment(undefined, listId);
            });

            closeModal();
          } else if (isSegmentsDisplay) {
            closeModal();
          } else if (mode === 'form') {
            onCreateSegment(filters);
            setMode('display');
            closeModal();
          } else if (mode === 'csv') {
            setMode('display');
          }
        }}
        sourceText={getSourceCountText()}
        tabMode={tabMode}
        triggerProps={messageAllotmentsTriggerProps}
      />
      {messageAllotmentsModalProps.show && (
        <MessageAllotmentsFiltersModal
          campaignId={campaignId}
          modalProps={messageAllotmentsModalProps}
          locationIds={filters.locationIds}
          setAudienceCount={setAudienceCount}
        />
      )}
    </Tray>
  );
};

const tabPanelStyling = { height: '100%' };
