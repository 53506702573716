import { useMemo } from 'react';
import { css } from '@emotion/react';
import { ScheduleRequestSource } from '@weave/schema-gen-ts/dist/schemas/schedule/settings/v2/settings.pb';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { SpinningLoader, Tabs } from '@frontend/design-system';
import { useBookingSiteSetupInfo } from '../../hooks/booking-site';
import { getBookingSiteUrl } from '../../utils';
import { GoogleAccountContent } from './GoogleBusinessProfileContent/GoogleAccountContent';
import { bookingSiteSetupTrackingIds } from './trackingIds';
import { WebsiteTabContent } from './WebsiteTabContent';

enum TabEnum {
  GoogleAccount = 'google-account',
  Website = 'website',
}

export const BookingSiteSetup = () => {
  const { t } = useTranslation('scheduleGlobalSettings');
  const { selectedLocationIds } = useAppScopeStore();
  const locationId = selectedLocationIds[0];

  const { isLoading, locationWiseBookingSiteInfoMap } = useBookingSiteSetupInfo();

  // NOTE: Google Account tab is rendered only for single location.
  // For multi-location, as of now, we don't have a decision made on it's implementation. This needs to be updated later.
  const bookingSiteUrlForGoogleAccount = getBookingSiteUrl(
    locationId,
    locationWiseBookingSiteInfoMap.get(locationId)?.locationPortalSlug,
    ScheduleRequestSource.GOOGLE_BUSINESS_PROFILE
  );
  const shouldRenderGoogleAccount = !!locationWiseBookingSiteInfoMap.get(locationId)?.shouldRenderGoogleAccount;

  const locationPortalSlugMap = useMemo(
    () =>
      [...locationWiseBookingSiteInfoMap.entries()].reduce<Record<string, string>>(
        (acc, [locationId, { locationPortalSlug }]) => {
          acc[locationId] = locationPortalSlug;
          return acc;
        },
        {}
      ),
    [locationWiseBookingSiteInfoMap]
  );

  return (
    <section css={containerStyle}>
      {isLoading ? (
        <div css={{ alignSelf: 'center', marginTop: theme.spacing(5) }}>
          <SpinningLoader size='medium' />
        </div>
      ) : (
        <Tabs initialTab={shouldRenderGoogleAccount ? TabEnum.GoogleAccount : TabEnum.Website}>
          <Tabs.Bar>
            {shouldRenderGoogleAccount && (
              <Tabs.Tab
                id={TabEnum.GoogleAccount}
                controls={`${TabEnum.GoogleAccount}-panel`}
                trackingId={bookingSiteSetupTrackingIds.googleAccountTab}
              >
                {t('Google Account')}
              </Tabs.Tab>
            )}
            <Tabs.Tab id={TabEnum.Website} controls={`${TabEnum.Website}-panel`}>
              {t('Website')}
            </Tabs.Tab>
          </Tabs.Bar>
          {shouldRenderGoogleAccount && (
            <Tabs.Panel id={`${TabEnum.GoogleAccount}-panel`} controller={TabEnum.GoogleAccount}>
              <GoogleAccountContent locationId={locationId} bookingSiteURL={bookingSiteUrlForGoogleAccount} />
            </Tabs.Panel>
          )}
          <Tabs.Panel id={`${TabEnum.Website}-panel`} controller={TabEnum.Website}>
            <WebsiteTabContent locationPortalSlugMap={locationPortalSlugMap} />
          </Tabs.Panel>
        </Tabs>
      )}
    </section>
  );
};

const containerStyle = css({
  position: 'relative',
  height: '100%',
  maxWidth: 700,
  background: theme.colors.white,
  borderRadius: theme.borderRadius.medium,
  padding: theme.spacing(2, 3, 5, 3),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
});
