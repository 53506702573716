import { useLayoutEffect, useRef, useState } from 'react';
import { Text, TextProps, styles as dsStyles, useTooltip } from '@frontend/design-system';

interface Props extends TextProps {
  text?: string;
}

export const TruncatedText = ({ text, ...rest }: Props) => {
  const textRef = useRef<HTMLElement | null>(null);
  const { Tooltip, triggerProps, tooltipProps } = useTooltip({ placement: 'top' });
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  useLayoutEffect(() => {
    if (textRef.current) {
      setShowTooltip(textRef.current.offsetWidth < textRef.current.scrollWidth);
    }
  }, [textRef.current?.scrollWidth]);

  return (
    <>
      <Text
        css={dsStyles.truncate}
        {...rest}
        {...triggerProps}
        ref={(node) => {
          textRef.current = node;
          triggerProps.ref(node);
        }}
      >
        {text}
      </Text>

      {showTooltip && (
        <Tooltip {...tooltipProps}>
          <Text size='medium' color='white'>
            {text}
          </Text>
        </Tooltip>
      )}
    </>
  );
};
