import { useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import dayjs from 'dayjs';
import { InvoiceModel, InvoiceStatus, PaymentModel, PaymentRefund } from '@frontend/api-invoices';
import { UsersApi } from '@frontend/api-users';
import { PrintDialog } from '@frontend/assets';
import { formatCentsToCurrency } from '@frontend/currency';
import { formatDate } from '@frontend/date';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { downloadCSV } from '@frontend/media-helpers';
import { paymentTypeMap } from '@frontend/payments-hooks';
import { prettify } from '@frontend/string';
import { UsersTypes } from '@frontend/user-helpers';
import { theme } from '@frontend/theme';
import {
  Button,
  Heading,
  MarkIcon,
  PrintIcon,
  RefundIcon,
  Table,
  Text,
  UnmarkIcon,
  useAlert,
  useModalControl,
} from '@frontend/design-system';
import { useCanDoAction, usePrintDialogue, usePrintReceipt, useToggleInvoiceRecorded } from '../../hooks';
import { PaymentRow } from '../../types';
import { getPaymentMethod } from '../../utils';
import { PrintHeader } from '../PrintHeader';
import { SendReceiptModal } from '../Receipts';
import { RefundModal } from '../Refunds';
import { generateColumns } from './generate-columns';
import { calculateInvoicePayments } from './preview-create-request-modal';
import { styles, paymentInfoStyles } from './styles';

type RequestDetailsProps = {
  invoice: InvoiceModel;
};

export const PaymentInformationTable = ({ invoice }: RequestDetailsProps) => {
  const { t } = useTranslation('payments');
  const alerts = useAlert();
  const print = usePrintReceipt();
  const { canRefund, canExport } = useCanDoAction();
  const { toggleRecorded } = useToggleInvoiceRecorded();
  const { showPrint, handlePrint } = usePrintDialogue();

  const refundModalProps = useModalControl({ disableReturnFocus: true });
  const sendModalProps = useModalControl();

  const [row, setRow] = useState<PaymentRow | undefined>(undefined);

  const columns = useMemo(() => generateColumns(t), []);
  const data = formatPaymentData(invoice);

  const issueRefund = () => {
    if (!canRefund) {
      alerts.error(t(`You don't have permission to perform a refund`));
      return;
    }
    refundModalProps.triggerProps.onClick();
  };

  const transformDataForCsvExport = (data: PaymentRow[]) =>
    data.map((paymentRow) => {
      return {
        Type: paymentRow.status === 'PARTIALLY_REFUNDED' || paymentRow.status === 'REFUNDED' ? 'Refund' : 'Payment',
        Date: formatDate(paymentRow.date, 'YYYY-MM-D'),
        Amount: formatCentsToCurrency(paymentRow.amount) || '0.00',
        Origin: paymentRow.origin ? paymentTypeMap[paymentRow.origin] : 'N/A',
        Method: `${getPaymentMethod(
          paymentRow.paymentType,
          paymentRow.paymentDetails.brand
        )} ${paymentRow.paymentDetails.lastFour.trim()}`,
        User: paymentRow.user || 'N/A',
        Status: prettify(paymentRow.status || '-', { split: '_', outputCase: 'Sentence case' }),
      };
    });

  const exportPayments = () => {
    if (!canExport) {
      alerts.error(t('Payment admin role required'));
      return;
    }

    if (data && data.length > 0) {
      const filename = `request_details_payment_list_${invoice.id}`;
      const transformedData = transformDataForCsvExport(data.sort((a, b) => b.date.localeCompare(a.date)));

      downloadCSV(transformedData, filename);
    }
  };

  const openSendReceipt = (data: PaymentRow) => {
    setRow(data);
    sendModalProps.openModal();
  };

  const handlePrintClick = (data: PaymentRow) => {
    print(invoice, {
      receiptType: isRefund(data) ? 'refund' : 'payment',
      refundId: data.refundId,
    });
  };

  const isRefund = (row: PaymentRow | undefined) => row?.status === 'PARTIALLY_REFUNDED' || row?.status === 'REFUNDED';

  return (
    <div css={styles.card}>
      <div css={paymentInfoStyles.tableHeader}>
        <Heading level={3} css={{ marginBottom: theme.spacing(2) }}>
          {t('Payment Information')}
        </Heading>
        <div>
          <Button iconName='print' onClick={handlePrint} variant='secondary' size='large' />
          <Button iconName='download' onClick={exportPayments} variant='secondary' size='large' />
        </div>
      </div>
      <PaymentBreakdown invoice={invoice} />
      <Table
        colConfig={columns}
        data={data || []}
        rowActions={{
          actions: [
            {
              label: t('Send Receipt'),
              Icon: () => <Icon name='payments-receipt' size={16} />,
              onClick: openSendReceipt,
              hide: (data: PaymentRow) => data.status === 'FAILED',
              trackingId: 'pay-portal-requestdetails-btn-sendreceipt',
            },
            {
              label: t('Print Receipt'),
              Icon: PrintIcon,
              onClick: (data: PaymentRow) => handlePrintClick(data),
              hide: (data: PaymentRow) => data.status === 'FAILED',
              trackingId: 'pay-portal-requestdetails-btn-printreceipt',
            },
            {
              label: t('Refund'),
              Icon: RefundIcon,
              onClick: issueRefund,
              hide: (data: PaymentRow) =>
                !(
                  data.status === InvoiceStatus.Paid ||
                  data.status === InvoiceStatus.PartiallyPaid ||
                  data.status === 'SUCCEEDED'
                ),
              trackingId: 'pay-portal-requestdetails-btn-refund',
            },
            {
              label: t('Mark Recorded'),
              Icon: MarkIcon,
              hide: (data: PaymentRow) =>
                !!data.recordedAt ||
                !(
                  data.status === InvoiceStatus.Paid ||
                  data.status === InvoiceStatus.PartiallyPaid ||
                  data?.status === 'SUCCEEDED'
                ),
              onClick: (data: PaymentRow) =>
                toggleRecorded({ data: invoice, paymentId: data?.paymentId, recordedAt: data?.recordedAt }),
              trackingId: 'pay-portal-requestdetails-btn-recorded',
            },
            {
              label: t('Mark Unrecorded'),
              Icon: UnmarkIcon,
              hide: (data: PaymentRow) =>
                !data?.recordedAt ||
                !(
                  data.status === InvoiceStatus.Paid ||
                  data.status === InvoiceStatus.PartiallyPaid ||
                  data?.status === 'SUCCEEDED'
                ),
              onClick: (data: PaymentRow) =>
                toggleRecorded({ data: invoice, paymentId: data?.paymentId, recordedAt: data?.recordedAt }),
              trackingId: 'pay-portal-requestdetails-btn-unrecorded',
            },
          ],
        }}
      />
      <RefundModal invoice={invoice} {...refundModalProps.modalProps} />
      <SendReceiptModal
        invoice={invoice}
        receiptType={isRefund(row) ? 'refund' : 'payment'}
        refundId={row?.refundId}
        {...sendModalProps.modalProps}
      />
      {showPrint && <PrintFriendly invoice={invoice} />}
    </div>
  );
};

const PaymentBreakdown = ({ invoice }: RequestDetailsProps) => {
  const { t } = useTranslation('payments');

  const calculateNet = (payment: PaymentModel) => {
    return payment?.refunds?.reduce((refAcc, refund) => refAcc + refund.amount, 0) ?? 0;
  };

  const totalPaid = useMemo(() => calculateInvoicePayments(invoice), [invoice]);
  const totalOwed = useMemo(() => invoice.billedAmount - totalPaid, [invoice, totalPaid]);
  const totalFees = useMemo(() => invoice.payments?.reduce((acc, payment) => acc + payment.fee, 0), [invoice]);
  const totalNet = useMemo(
    () =>
      invoice.payments?.reduce(
        (acc, payment) => acc + (payment?.refunds ? payment.net - calculateNet(payment) : payment.net),
        0
      ),
    [invoice]
  );

  return (
    <div css={paymentInfoStyles.paymentDetailsRow}>
      <div css={paymentInfoStyles.paymentDetails}>
        <Text color='light'>{t('Total paid')}</Text>
        <Text weight='bold'>{formatCentsToCurrency(totalPaid)}</Text>
      </div>
      <div css={paymentInfoStyles.paymentDetails}>
        <Text color='light'>{t('Total owed')}</Text>
        <Text weight='bold'>{formatCentsToCurrency(totalOwed)}</Text>
      </div>
      <div css={paymentInfoStyles.paymentDetails}>
        <Text color='light'>{t('Total fees')}</Text>
        <Text weight='bold'>{formatCentsToCurrency(totalFees)}</Text>
      </div>
      <div css={paymentInfoStyles.paymentDetails}>
        <Text color='light'>{t('Total net')}</Text>
        <Text weight='bold'>{formatCentsToCurrency(totalNet)}</Text>
      </div>
    </div>
  );
};

const PrintFriendly = ({ invoice }: RequestDetailsProps) => {
  const { t } = useTranslation('payments');
  const columns = useMemo(() => generateColumns(t), []);
  const data = formatPaymentData(invoice);

  return (
    <PrintDialog show={true} onClose={() => {}}>
      <div
        css={css`
          margin: ${theme.spacing(2)};
        `}
      >
        <PrintHeader />
        <Heading level={3} css={styles.marginBottom}>
          {t('Payment Information')}
        </Heading>
        <PaymentBreakdown invoice={invoice} />
        <Table colConfig={columns} data={data || []} />
      </div>
    </PrintDialog>
  );
};

const formatPaymentData = (invoice: InvoiceModel) => {
  const [userMap, setUserMap] = useState<UsersTypes.User[]>([]);
  const lookupUser = (userId: string) => {
    const user = userMap?.find((user: UsersTypes.User) => user.UserID === userId);
    return user ? `${user.FirstName} ${user.LastName}` : '';
  };

  const getUsers = async () => {
    try {
      const users = await UsersApi.list.users();
      setUserMap(users);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  const getPaymentStatus = (payment: PaymentModel) => {
    if (payment.status === 'SUCCEEDED') {
      if (payment.paidAmount === invoice.billedAmount) {
        return InvoiceStatus.Paid;
      } else {
        return InvoiceStatus.PartiallyPaid;
      }
    } else {
      return 'FAILED';
    }
  };

  const getRefundStatus = (refund: PaymentRefund) => {
    if (refund.amount === invoice.payments?.find((payment) => payment.paymentId === refund.paymentId)?.paidAmount) {
      return 'REFUNDED';
    } else {
      return 'PARTIALLY_REFUNDED';
    }
  };

  const data: PaymentRow[] = [];

  invoice.payments?.forEach((payment) => {
    if (payment) {
      data.push({
        amount: payment.paidAmount,
        date: payment.paidAt,
        origin: payment.origin,
        paymentType: payment.paymentType,
        paymentDetails: payment.paymentDetails,
        paymentId: payment.paymentId,
        recordedAt: payment.recordedAt,
        status: getPaymentStatus(payment),
        user: '', // This should be the office user
        confirmationCode: payment.confirmationCode,
      });
      if (payment.refunds) {
        payment.refunds.forEach(
          (refund) =>
            refund &&
            data.push({
              amount: -refund.amount,
              date: refund.createdAt,
              paymentDetails: payment.paymentDetails,
              paymentId: payment.paymentId,
              paymentType: payment.paymentType,
              recordedAt: payment.recordedAt,
              refundId: refund.stripeId,
              status: getRefundStatus(refund),
              user: lookupUser(refund.refundedBy),
            })
        );
      }
    }
  });

  data.sort((a, b) => dayjs(b.date).valueOf() - dayjs(a.date).valueOf());

  return data;
};
