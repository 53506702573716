import { useState } from 'react';
import { css } from '@emotion/react';
import { AnalyticsCommonTypes } from '@frontend/api-analytics';
import { breakpoints } from '@frontend/responsiveness';
import { useHasFeatureFlag } from '@frontend/shared';
import { theme } from '@frontend/theme';
import { featureFlags } from '../../feature-flags';
import { CallAnswerRate, CallVolume, TotalMessages } from './summary-cards';
import { PhoneSummary } from './types';
import { MessagingCategoriesOnDashboard, PhoneIncomingCategoriesOnDashboard } from '.';

type Props = {
  filters: AnalyticsCommonTypes.AnalyticsSummaryFilters;
};

const defaultPhoneSummaryData: PhoneSummary = {
  answered: 0,
  incoming: 0,
  total: 0,
};

export const HomepageAnalyticsBody = ({ filters }: Props) => {
  const messagingAnalyticsEnabled = useHasFeatureFlag(featureFlags.messagingAnalytics);
  const phoneAnalyticsEnabled = useHasFeatureFlag(featureFlags.phoneAnalytics);
  const [isLoadingMessagingData, setIsLoadingMessagingData] = useState<boolean | undefined>(false);
  const [totalMessageCount, setTotalMessageCount] = useState<number>(0);
  const [isLoadingPhoneData, setIsLoadingPhoneData] = useState<boolean | undefined>(false);
  const [phoneSummaryData, setPhoneSummaryData] = useState<PhoneSummary>(defaultPhoneSummaryData);

  return (
    <>
      <div css={styles.summaryCardsWrapper}>
        {phoneAnalyticsEnabled && (
          <>
            <CallVolume data={phoneSummaryData} isLoading={isLoadingPhoneData} />
            <CallAnswerRate data={phoneSummaryData} isLoading={isLoadingPhoneData} />
          </>
        )}
        {messagingAnalyticsEnabled && (
          <TotalMessages data={{ total: totalMessageCount }} isLoading={isLoadingMessagingData} />
        )}
      </div>

      <div css={styles.flexibleCharts}>
        {phoneAnalyticsEnabled && (
          <PhoneIncomingCategoriesOnDashboard
            filters={filters}
            onLoadingStateChange={setIsLoadingPhoneData}
            onLoadData={setPhoneSummaryData}
          />
        )}
        {messagingAnalyticsEnabled && (
          <MessagingCategoriesOnDashboard
            filters={filters}
            onLoadingStateChange={setIsLoadingMessagingData}
            onLoadData={setTotalMessageCount}
          />
        )}
      </div>
    </>
  );
};

const styles = {
  summaryCardsWrapper: css`
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: ${theme.spacing(3)};
    margin-bottom: ${theme.spacing(3)};

    > * {
      flex-basis: calc(100% / 3 - ${theme.spacing(2)});
      min-width: 300px;
    }
  `,

  flexibleCharts: css`
    display: flex;
    flex-wrap: wrap;
    column-gap: ${theme.spacing(3)};

    > section {
      flex-basis: calc(50% - ${theme.spacing(1.5)});
      flex-grow: 1;

      @media screen and (min-width: ${breakpoints.small.min}px) {
        min-width: 400px;
      }
    }
  `,
};
