import { Dispatch, SetStateAction, useState } from 'react';
import { css } from '@emotion/react';
import { Contact, PhoneNumber } from '@weave/schema-gen-ts/dist/schemas/phone/contacts/contacts/contacts.pb';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { BaseFieldProps, Button, TextField } from '@frontend/design-system';
import { ContactTextField } from './fields';

type Props = {
  contact: Contact;
  setNumbers: Dispatch<SetStateAction<PhoneNumber[] | undefined>>;
  numbers: PhoneNumber[] | undefined;
  onError: (message: string) => void;
  nameFieldProps: BaseFieldProps<string, '', HTMLInputElement>;
  noteFieldProps: BaseFieldProps<string, '', HTMLInputElement>;
};

export const BusinessFields = ({ contact, numbers, setNumbers, onError, nameFieldProps, noteFieldProps }: Props) => {
  const { t } = useTranslation('phone');
  const notes = contact.notes;
  const [showNote, setShowNote] = useState(!!notes);

  const onUpdate = ({ label, number, index }: { label: string; number: string; index: string }) => {
    setNumbers((nums) => {
      const copy = nums?.map((num, i) => {
        if (i === +index) {
          return {
            label,
            number,
            id: num.id,
          };
        }
        return num;
      });

      return copy;
    });
  };

  const onAdd = (i: string) => {
    const newNumber = {
      label: '',
      number: '',
      id: i,
    };
    setNumbers((nums) => [...(nums ?? []), newNumber]);
  };

  const onRemove = (index: string) => {
    setNumbers(numbers?.filter((_, i) => i !== +index));
  };

  const onRemoveNote = () => {
    setShowNote(false);
  };

  return (
    <div css={styles}>
      <TextField
        containerCss={css`
          margin-bottom: ${theme.spacing(2)};
          width: 100%;
        `}
        name='name-field'
        label={t('Name')}
        {...nameFieldProps}
      />
      {numbers?.map((number, i) => {
        const removeable = i !== 0;
        return (
          <ContactTextField
            onUpdate={onUpdate}
            key={number.id}
            index={`${i}`}
            removeable={removeable}
            onRemove={onRemove}
            onError={onError}
            label={number.label}
            value={number.number}
          />
        );
      })}
      <Button
        css={{ marginTop: theme.spacing(2) }}
        variant='tertiary'
        onClick={() => {
          onAdd(`${numbers?.length ?? 0}`);
        }}
        iconName='add'
      >
        {t('Add Phone Number')}
      </Button>
      {showNote && (
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            gap: theme.spacing(1),
            marginTop: theme.spacing(2),
            width: '100%',
          }}
        >
          <Button
            destructive
            onClick={onRemoveNote}
            variant='secondary'
            css={{ minWidth: theme.spacing(5) }}
            iconName='remove-small'
          />
          <TextField
            containerCss={css`
              width: 100%;
            `}
            {...noteFieldProps}
            label={t('Note')}
            name='notes'
          />
        </div>
      )}
      <Button
        css={{ marginTop: theme.spacing(2) }}
        variant='tertiary'
        onClick={() => setShowNote(true)}
        iconName='add'
        disabled={showNote}
      >
        {t('Add Note')}
      </Button>
    </div>
  );
};

const styles = css`
  display: flex;
  flex-direction: column;
  align-items: start;
  padding-top: ${theme.spacing(2)};
`;
