import { useMemo, useState, MouseEvent } from 'react';
import { PortInSUPRequest } from '@weave/schema-gen-ts/dist/schemas/phone/porting/porting-data/v1/porting_data_service.pb';
import { PortStatus } from '@weave/schema-gen-ts/dist/shared/porting/v1/enums.pb';
import { FeatureFlagQueries } from '@frontend/api-feature-flags';
import { logOnboardingSentryError } from '@frontend/api-intake-form';
import { PortingTypes, PortingQueries } from '@frontend/api-porting';
import { getUser } from '@frontend/auth-helpers';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useMutation } from '@frontend/react-query-helpers';
import { SchemaPortingDataService } from '@frontend/schema';
import { useAppScopeStore } from '@frontend/scope';
import { useSettingsNavigate } from '@frontend/settings-routing';
import { theme } from '@frontend/theme';
import {
  Table,
  useModalControl,
  Chip,
  NakedButton,
  ContentLoader,
  ConfirmationModal,
  useAlert,
} from '@frontend/design-system';
import { PORT_TYPE_TEXT_MAP, trackingIds } from '../../constants';
import {
  checkPortOrderCancellable,
  checkPortOrderDateChangeable,
  getPortRequestStatusChipColor,
  getUTCDateStringForRequest,
} from '../../helper';
import { usePhoneNumbersPageShallowStore } from '../../hooks';
import { PortStatusInfoModal } from '../common';
import { ExpandedRowContent } from './expandable-row-component';
import { OldRequestModal } from './old-request-modal';
import { RequestCancellationModal } from './request-cancellation-modal';
import { RequestChangeRestrictedModal, RequestChangeRestrictedModalType } from './request-change-restricted-modal';
import { RequestDateChangeModal } from './request-date-change-modal';
import { TableFilterPanel } from './table-filter-panel';

const CANCEL_PORT_ORDER_RESTRICTED_STATUSES = [
  PortStatus.PORT_STATUS_CANCELLED,
  PortStatus.PORT_STATUS_CANCEL_PENDING,
  PortStatus.PORT_STATUS_CANCEL_SUBMITTED,
  PortStatus.PORT_STATUS_REQUESTED_CANCEL,
  PortStatus.PORT_STATUS_COMPLETED,
  PortStatus.PORT_STATUS_SPLIT_PORT,
  PortStatus.PORT_STATUS_ACTIVATION_IN_PROGRESS,
  PortStatus.PORT_STATUS_VALIDATE_TFNS,
];
const DATE_CHANGE_ALLOWED_PORT_ORDER_STATUSES = [PortStatus.PORT_STATUS_FOC];

const shouldShowCancelPortRequestOption = (portOrder?: PortingTypes.ModifiedPortingData) => {
  const portStatus = portOrder?.portStatus;
  const isFullPort = portOrder?.portType === PortingTypes.PortTypeEnum.FULL_PORT;
  const isDraft = portOrder?.portRequestStatus === PortingTypes.PortRequestStatus.DraftIncomplete;

  return isFullPort && !isDraft && (!portStatus || !CANCEL_PORT_ORDER_RESTRICTED_STATUSES.includes(portStatus));
};

const shouldShowChangePortDateOption = (portOrder?: PortingTypes.ModifiedPortingData) => {
  const portStatus = portOrder?.portStatus;
  const isFullPort = portOrder?.portType === PortingTypes.PortTypeEnum.FULL_PORT;
  const isDraft = portOrder?.portRequestStatus === PortingTypes.PortRequestStatus.DraftIncomplete;
  return isFullPort && !isDraft && (!portStatus || DATE_CHANGE_ALLOWED_PORT_ORDER_STATUSES.includes(portStatus));
};

export const PortRequestsTable = () => {
  const { t } = useTranslation('porting', { keyPrefix: 'phoneNumbers' });
  const user = getUser();
  const alert = useAlert();
  const { navigate } = useSettingsNavigate();
  const { selectedPortingId, setSelectedPortingId } = usePhoneNumbersPageShallowStore(
    'selectedPortingId',
    'setSelectedPortingId'
  );

  const { selectedLocationIds, getLocationName } = useAppScopeStore();
  const { isLoading, isRefetching, data, refetch } = PortingQueries.useGetPortRequests(selectedLocationIds);
  const locationWiseSUPAndCancellationFFQuery = FeatureFlagQueries.useMultiFeatureFlagIsEnabledQuery(
    {
      flagName: 'porting:port-request-sup-and-cancellation',
      groupIds: selectedLocationIds ?? [],
    },
    { enabled: !!selectedLocationIds?.length }
  );

  const oldRequestModalControl = useModalControl();
  const cancelRequestRestrictModalControl = useModalControl();
  const requestDateChangeRestrictModalControl = useModalControl();
  const requestCancellationModalControl = useModalControl();
  const requestDateChangeModalControl = useModalControl();
  const { modalProps: statusModalProps, openModal: openStatusModal } = useModalControl();

  const [searchValue, setSearchValue] = useState<string>('');
  const [tableFilters, setTableFilters] = useState<PortingTypes.PortRequestStatus[]>([]);
  const deleteDraftConfirmationModalControl = useModalControl();
  const [selectedRowPortOrderId, setSelectedRowPortOrderId] = useState('');

  const cancelPortInOrderMutation = useMutation({
    mutationFn: (portingDataId: string) =>
      SchemaPortingDataService.CancelPortInOrder({
        portingDataId,
        userEmail: user?.username,
      }),
    onError: (error) => logOnboardingSentryError('Error cancelling port order in Port Requests page.', error),
  });
  const portInSUPMutation = useMutation({
    mutationFn: (request: PortInSUPRequest) => SchemaPortingDataService.PortInSUP(request),
    onError: (error) =>
      logOnboardingSentryError('Error in  port order SUP(Date change) request in Port Requests page.', error),
  });

  // Note: set rowExpandingConfig for component mount case only as selectedPortingId set from other page component
  const rowExpandingConfig = useMemo(() => {
    if (selectedPortingId) {
      const config = { initialState: { [selectedPortingId]: true } };
      setSelectedPortingId(null);
      return config;
    }
    return undefined;
  }, []);

  const filteredData = useMemo(() => {
    if (!searchValue && !tableFilters.length) {
      return data;
    }
    if (!searchValue) {
      return data?.filter((item) => tableFilters.includes(item?.portRequestStatus));
    }
    const searchText = searchValue.toLocaleLowerCase();
    return data?.filter(
      (obj) =>
        (!tableFilters.length || tableFilters.includes(obj?.portRequestStatus)) &&
        (obj?.numbers.some((numberInfo) => numberInfo.number.includes(searchText)) ||
          obj?.portOrderNumber?.includes(searchText))
    );
  }, [data, tableFilters, searchValue]);

  const handleInfoIconClick = (e: MouseEvent) => {
    e.stopPropagation();
    openStatusModal();
  };

  const handleDeleteDraft = (portOrderId: string) => {
    setSelectedRowPortOrderId(portOrderId);
    deleteDraftConfirmationModalControl.openModal();
  };

  const handleConfirmDeleteDraft = () => {
    cancelPortInOrderMutation.mutate(selectedRowPortOrderId, {
      onSuccess: () => {
        alert.success(t('Success! Your port request is deleted.'));
        refetch();
      },
      onError: () => {
        alert.error(t('Error! Your port request is not deleted. Please try again.'));
      },
    });
    deleteDraftConfirmationModalControl.closeModal();
  };

  const handleConfirmCancelRequest = () => {
    cancelPortInOrderMutation.mutate(selectedRowPortOrderId, {
      onSuccess: () => {
        alert.success(t('Success! Your port request is cancelled.'));
        refetch();
      },
      onError: () => {
        alert.error(t('Error! Your port request is not cancelled. Please try again.'));
      },
    });
    requestCancellationModalControl.closeModal();
  };

  const handleConfirmRequestDateChange = (date: string) => {
    portInSUPMutation.mutate(
      {
        adminEmail: user?.username ?? '',
        portingDataId: selectedRowPortOrderId,
        focDate: getUTCDateStringForRequest(date),
        comments: 'Port Date change by customer from Portal',
        supReason: 'Other',
      },
      {
        onSuccess: () => {
          alert.success(t('Success! Your port request date changed.'));
          refetch();
        },
        onError: () => {
          alert.error(t("Error! Your port request date doesn't changed. Please try again."));
        },
      }
    );
    requestDateChangeModalControl.closeModal();
  };

  const handleCancelRequestClick = (data: PortingTypes.ModifiedPortingData) => {
    const isRequestSUPAndCancellationFFEnabled = locationWiseSUPAndCancellationFFQuery.data?.[data.locationId] ?? false;
    if (!isRequestSUPAndCancellationFFEnabled) {
      oldRequestModalControl.openModal();
      return;
    }

    const shouldShowCancellationModal = checkPortOrderCancellable(data.acceptedPortDate);

    if (shouldShowCancellationModal) {
      setSelectedRowPortOrderId(data.id);
      requestCancellationModalControl.openModal();
    } else {
      cancelRequestRestrictModalControl.openModal();
    }
  };

  const handleChangeDateClick = (data: PortingTypes.ModifiedPortingData) => {
    const isRequestSUPAndCancellationFFEnabled = locationWiseSUPAndCancellationFFQuery.data?.[data.locationId] ?? false;
    if (!isRequestSUPAndCancellationFFEnabled) {
      oldRequestModalControl.openModal();
      return;
    }

    const shouldShowDateChangeModal = checkPortOrderDateChangeable(data.acceptedPortDate);

    if (shouldShowDateChangeModal) {
      setSelectedRowPortOrderId(data.id);
      requestDateChangeModalControl.openModal();
    } else {
      requestDateChangeRestrictModalControl.openModal();
    }
  };

  const openEditPortRequestPage = (portOrderId: string) => {
    navigate({ to: '/organization/phone-numbers/port-request/:id', params: { id: portOrderId } });
  };

  return (
    <div css={{ position: 'relative' }}>
      <ContentLoader show={cancelPortInOrderMutation.isLoading} />
      <Table
        hasGlobalSearch
        hasResponsiveColWidths
        isPaginated
        isLoading={isLoading || isRefetching}
        globalTrackingId='port_requests'
        globalSearchConfig={{
          searchHandler: (searchValue) => setSearchValue(searchValue.length < 3 ? '' : searchValue),
        }}
        uniqueRowId={(row) => row.id}
        rowExpandingConfig={rowExpandingConfig}
        tableActions={[
          {
            Icon: () => <Icon name='update' />,
            label: t('Refresh'),
            onClick: () => refetch(),
          },
        ]}
        data={filteredData ?? []}
        emptyStateConfig={{
          type: 'sync_your_phone',
          header: t('No port requests'),
          description: t(
            "You haven't submitted any request. To submit a request click 'Port Additional Numbers' above"
          ),
        }}
        manualFiltersRender={(modalProps) => <TableFilterPanel {...modalProps} onChange={setTableFilters} />}
        expandableRowComponent={(row) => <ExpandedRowContent portRequest={row} />}
        rowActions={{
          actions: [
            {
              label: t('Change Date'),
              Icon: () => <Icon name='calendar-small' />,
              hide: (row) => !shouldShowChangePortDateOption(row),
              onClick: handleChangeDateClick,
            },
            {
              label: t('Cancel Request'),
              Icon: () => <Icon name='delete' size={20} />,
              hide: (row) => !shouldShowCancelPortRequestOption(row),
              onClick: handleCancelRequestClick,
            },
            {
              label: t('Edit Draft'),
              Icon: () => <Icon name='edit-small' />,
              hide: (row) => row.portRequestStatus !== PortingTypes.PortRequestStatus.DraftIncomplete,
              onClick: (data) => openEditPortRequestPage(data.id),
            },
            {
              label: t('Delete Draft'),
              Icon: () => <Icon name='trash-small' color='error' />,
              css: { span: { color: theme.colors.critical50 } },
              hide: (row) => row.portRequestStatus !== PortingTypes.PortRequestStatus.DraftIncomplete,
              onClick: (data) => handleDeleteDraft(data.id),
            },
          ],
        }}
        colConfig={[
          {
            id: 'portOrderNumber',
            Header: t('Port Order Number'),
            accessor: 'portOrderNumber',
            cellConfig: {
              element: 'Text',
            },
            width: 300,
          },
          {
            id: 'portType',
            Header: t('Port Type'),
            accessor: (item) => PORT_TYPE_TEXT_MAP[item.portType],
            cellConfig: {
              element: 'Text',
              showHoverLabel: true,
            },
            width: 150,
          },
          {
            id: 'location',
            Header: t('Location'),
            accessor: 'locationId',
            cellConfig: {
              element: 'Text',
            },
            width: 150,
            cellRenderer: (locationId) => {
              return (
                <>
                  {!!locationId && (
                    <Chip.SingleChip css={{ width: 'fit-content' }}>
                      {getLocationName(locationId) || locationId}
                    </Chip.SingleChip>
                  )}
                </>
              );
            },
          },
          {
            id: 'requestedDate',
            Header: t('Requested Date'),
            accessor: 'requestedPortDate',
            cellConfig: {
              element: 'Text',
              showHoverLabel: true,
            },
            width: 150,
          },
          {
            id: 'acceptedDate',
            Header: t('Accepted Date'),
            accessor: 'acceptedPortDate',
            cellConfig: {
              element: 'Text',
              showHoverLabel: true,
            },
            width: 150,
          },
          {
            id: 'status',
            Header: (
              <div
                css={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: `${theme.spacing(1)}`,
                  position: 'relative',
                }}
              >
                <span>{t('Status')}</span>
                <NakedButton onClick={(e) => handleInfoIconClick(e)} trackingId={trackingIds.infoIcon}>
                  <Icon name='info-small' color='light' />
                </NakedButton>
                <PortStatusInfoModal modalProps={statusModalProps} />
              </div>
            ),
            accessor: 'portRequestStatus',
            cellConfig: {
              element: 'Chip',
              customValue: (status: PortingTypes.PortRequestStatus) =>
                status === PortingTypes.PortRequestStatus.DraftIncomplete
                  ? PortingTypes.PortRequestStatus.Draft
                  : status,
              variant: (cellValue: PortingTypes.PortRequestStatus) => getPortRequestStatusChipColor(cellValue),
            },
            width: 150,
          },
        ]}
      />
      <OldRequestModal {...oldRequestModalControl.modalProps} isOnboarder={data?.[0]?.isOnboarder || false} />
      <RequestChangeRestrictedModal
        {...cancelRequestRestrictModalControl.modalProps}
        modalType={RequestChangeRestrictedModalType.CancelRequest}
      />
      <RequestChangeRestrictedModal
        {...requestDateChangeRestrictModalControl.modalProps}
        modalType={RequestChangeRestrictedModalType.DateChange}
      />
      <RequestCancellationModal
        {...requestCancellationModalControl.modalProps}
        onConfirm={handleConfirmCancelRequest}
      />
      <RequestDateChangeModal
        {...requestDateChangeModalControl.modalProps}
        onConfirm={handleConfirmRequestDateChange}
      />
      <ConfirmationModal
        destructive
        {...deleteDraftConfirmationModalControl.modalProps}
        maxWidth={530}
        message={t(
          'You are about to delete this draft. Once deleted all information collected in this port request will be lost. Are you sure you want to delete this draft ?'
        )}
        title={t('Delete Draft')}
        confirmLabel={t('Yes, Delete Draft')}
        onConfirm={handleConfirmDeleteDraft}
      />
    </div>
  );
};
