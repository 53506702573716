import { FC } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { useHasFeatureFlag } from '@frontend/shared';
import { theme } from '@frontend/theme';
import { SwitchField, CheckboxField, useControlledField, ListRow, Text, useAlert } from '@frontend/design-system';
import { useNotificationLocalStorageStore } from '../../hooks';

interface TeamChatNotificationSettingsProps {
  isDisabled: boolean;
}

export const TeamChatNotificationSettings: FC<TeamChatNotificationSettingsProps> = ({ isDisabled }) => {
  const { t } = useTranslation('team-chat');
  const isTeamChatRevampedEnabled = useHasFeatureFlag('team-chat-2.0');
  const { success } = useAlert();

  const {
    showNotificationWhenTeamChatActive,
    showNotificationWhenActiveConversation,
    setShowNotificationWhenTeamChatActive,
    setShowNotificationWhenActiveConversation,
  } = useNotificationLocalStorageStore(
    'showNotificationWhenTeamChatActive',
    'showNotificationWhenActiveConversation',
    'setShowNotificationWhenTeamChatActive',
    'setShowNotificationWhenActiveConversation'
  );

  const switchFieldProps = useControlledField({
    type: 'switch',
    value: showNotificationWhenTeamChatActive,
    onChange: (value) => {
      setShowNotificationWhenTeamChatActive(value);
      success(t('Successfully updated notification settings.'));
    },
  });
  const checkboxFieldProps = useControlledField({
    type: 'checkbox',
    value: !showNotificationWhenActiveConversation,
    onChange: (value) => {
      setShowNotificationWhenActiveConversation(!value);
      success(t('Successfully updated notification settings.'));
    },
  });

  if (isTeamChatRevampedEnabled) {
    return (
      <>
        <ListRow css={switchListStyles.title}>
          <ListRow.Content>
            <ListRow.Content.Title color={isDisabled ? 'subdued' : 'default'}>
              {t('Notify Even When Team Chat Is Open')}
            </ListRow.Content.Title>
            <ListRow.Content.Subtitle>
              {t('Continue receiving notifications even if the team chat panel is open and active.')}
            </ListRow.Content.Subtitle>
          </ListRow.Content>
          <ListRow.Trail>
            <SwitchField
              {...switchFieldProps}
              name='Notify Even When Team Chat Is Open'
              label=''
              data-trackingid='team-chat-2.0-notification-action-when-tray-opened'
              disabled={isDisabled}
            />
          </ListRow.Trail>
        </ListRow>

        <ListRow css={{ padding: 0, borderBottom: 'none' }}>
          <ListRow.Lead>
            <CheckboxField
              disabled={!showNotificationWhenTeamChatActive || isDisabled}
              {...checkboxFieldProps}
              name='Mute Notifications for active or open conversations'
              label=''
              data-trackingid='team-chat-2.0-notification-action-when-active-conversation'
            />
          </ListRow.Lead>
          <ListRow.Content>
            <Text color={!showNotificationWhenTeamChatActive || isDisabled ? 'subdued' : 'default'}>
              {t('Mute Notifications for active or open conversations')}
            </Text>
          </ListRow.Content>
        </ListRow>
      </>
    );
  }

  return null;
};

const switchListStyles = {
  title: css({
    padding: theme.spacing(2, 0),
    borderBottom: 'none',
    borderTop: `1px solid ${theme.colors.neutral20}`,
    marginTop: theme.spacing(1.5),
  }),
};
