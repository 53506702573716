import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const justifiInlineStyles = css`
  :root {
    --jfi-primary-color: ${theme.colors.primary50};
    --jfi-layout-font-family: ${theme.font.family};
    --jfi-form-label-font-family: ${theme.font.family};
    --jfi-error-message-color: ${theme.colors.critical50};
  }

  ::part(button) {
    padding: ${theme.spacing(1)} ${theme.spacing(1.5)};
    border-radius: ${theme.borderRadius.small};
    border: none;
    font-weight: ${theme.font.weight.bold};
    font-size: ${theme.font.size.large};
  }

  ::part(button-primary):hover {
    background-color: ${theme.colors.primary70};
  }

  ::part(input) {
    position: relative;
    z-index: 1;
    padding: ${theme.spacing(1)} ${theme.spacing(2)};
    border-radius: ${theme.borderRadius.small};
    font-size: ${theme.font.size.large};
    color: ${theme.colors.neutral90};
    border: solid 1px ${theme.colors.neutral20};
  }

  ::part(input):focus {
    border: solid 1px ${theme.colors.primary50};
    box-shadow: none;
  }

  ::part(input-invalid) {
    border: solid 1px ${theme.colors.critical50};
  }

  ::part(label) {
    margin-bottom: 0;
    position: relative;
    color: ${theme.colors.neutral50};
    z-index: 2;
    background-color: ${theme.colors.white};
    width: fit-content;
    padding: 0 ${theme.spacing(0.5)};
    font-size: ${theme.font.size.small};
  }
`.styles;

export const justifiOverrideStyles = css`
  h1 {
    font-size: ${theme.font.size.h2};
    font-weight: ${theme.font.weight.bold};
    color: ${theme.colors.neutral90};
    font-family: ${theme.font.family};
    margin-bottom: 0;
  }

  form-control-date {
    display: flex;
    flex-direction: column;
  }

  .btn-secondary {
    background-color: ${theme.colors.white};
    color: ${theme.colors.neutral70};
    border: solid 1px ${theme.colors.neutral30} !important;
  }

  .btn-secondary:hover,
  .btn-secondary:focus {
    background-color: ${theme.colors.neutral10};
    color: ${theme.colors.neutral90};
    border-color: ${theme.colors.neutral90} !important;
  }

  .btn-secondary:disabled {
    color: ${theme.colors.neutral70};
    background-color: ${theme.colors.neutral10};
  }

  .form-group .d-flex:has(label):has(+ .is-invalid),
  .form-group label:has(+ .is-invalid) {
    color: ${theme.colors.critical50} !important;
  }

  .form-group .d-flex:has(label),
  .form-group label:has(+ input) {
    margin-bottom: 4px;
  }

  form-control-tooltip,
  .tooltip-container {
    height: 16px;
  }

  .tooltip-container svg,
  .tooltip-container svg path {
    fill: ${theme.colors.neutral50};
    color: ${theme.colors.neutral50};
  }

  form-control-tooltip {
    background: ${theme.colors.white};
    border-radius: 50px;
    margin-top: -4px;
  }

  .popper-content .tooltip.show {
    opacity: 1;
  }

  .popper-content .tooltip-inner {
    background: ${theme.colors.neutral90};
    padding: ${theme.spacing(1)} !important;
    font-size: ${theme.font.size.medium} !important;
  }

  form-control-monetary label {
    margin-bottom: 4px !important;
    margin-left: 4px !important;
  }

  .d-flex.align-items-center:has(legend) {
    display: inline-flex !important;
  }
`.styles;

export const styles = {
  formWrapper: css`
    position: relative;
  `,
  pendingOverlay: css`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(115, 115, 115, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
  `,
};
