import { css } from '@emotion/react';
import { Status_Enum as CampaignStatus } from '@weave/schema-gen-ts/dist/schemas/messaging/bulk/v2';
import { theme } from '@frontend/theme';
import { Text, TextButton } from '@frontend/design-system';

type Props = {
  isDecimal?: boolean;
  isPercentage?: boolean;
  onClick?: () => void;
  status?: CampaignStatus;
  trackingId?: string;
  value?: number;
};

export const CellValue = ({
  isDecimal = false,
  isPercentage = false,
  onClick,
  status,
  trackingId,
  value = 0,
}: Props) => {
  const showValue = status === undefined || (status !== CampaignStatus.DRAFT && status !== CampaignStatus.HIDDEN_DRAFT);
  const suffix = isPercentage ? '%' : '';
  const numberValue = isDecimal ? value.toFixed(1) : value;

  if (showValue && value >= 0 && onClick) {
    return (
      <TextButton css={numberTextStyle} onClick={onClick} trackingId={trackingId}>
        {numberValue + suffix}
      </TextButton>
    );
  }

  return (
    <Text as='span' css={numberTextStyle}>
      {showValue ? numberValue + suffix : <>&mdash;</>}
    </Text>
  );
};

const numberTextStyle = css`
  font-size: ${theme.fontSize(14)};
  padding: ${theme.spacing(0.5, 1)};
`;
