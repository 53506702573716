import { FC, useEffect, useMemo, useState } from 'react';
import { gql } from 'graphql-request';
import { PracticeAnalyticsAggregations, PracticeAnalyticsApi, PracticeAnalyticsTypes } from '@frontend/api-analytics';
import { useTranslation } from '@frontend/i18n';
import { useScopedQuery } from '@frontend/scope';
import { emptyStateGraphics, useAlert } from '@frontend/design-system';
import { BulkMessageButton, HygieneFollowUpMetric } from '..';
import { InlineStats, TabbedTable } from '../..';
import { useAnalyticsOrgLocations, useShowBulkMessageButton } from '../../../hooks';
import { queryKeys } from '../../../query-keys';
import { trackingIds } from '../../../tracking-ids';
import { ExportUtils, formatters } from '../../../utils';
import { commonStyles } from '../../../views';
import { PatientsHelpers, getPracticeAnalyticsQueryVariables, subViewTableColConfig } from '../helpers';
import { usePatientsLastContacted, usePracticeAnalyticsShallowStore } from '../hooks';

const query = gql`
  query ($start: Int!, $end: Int!, $step: TimeStep!) {
    location {
      hygieneReappointment {
        details(start: $start, end: $end, step: $step) {
          patients {
            id
            productionAmount
            hygieneAppointmentDate
            nextHygieneAppointmentDate
            scheduledSameDay
            nextAppointmentDate
            FirstName: firstName
            LastName: lastName
            PMID: pmid
            MobilePhone: mobilePhone
            HomePhone: homePhone
            WorkPhone: workPhone
            Email: email
            PreferredName: preferredName
            Birthdate: birthdate
            Gender: gender
          }
        }
        totals(start: $start, end: $end, step: $step) {
          patients
          rescheduledSameDay
          unscheduled
        }
      }
    }
  }
`;

type CategorizedFollowUps = {
  all: PracticeAnalyticsTypes.PatientInfo[];
  sameDay: PracticeAnalyticsTypes.PatientInfo[];
  unscheduled: PracticeAnalyticsTypes.PatientInfo[];
};

export const HygieneFollowUpSubView: FC<React.PropsWithChildren<PracticeAnalyticsTypes.MetricSubViewProps>> = ({
  exportEnabled,
  onPatientsLoad,
}) => {
  const alert = useAlert();
  const { t } = useTranslation('analytics');
  const { demoData, filters, isDemoAccount, showDemoChipAndBanner } = usePracticeAnalyticsShallowStore(
    'demoData',
    'filters',
    'isDemoAccount',
    'showDemoChipAndBanner'
  );
  const { locationNames } = useAnalyticsOrgLocations({ isDemoAccount, module: 'PA' });
  const isBulkMessagingEnabled = useShowBulkMessageButton();

  const multipleLocationsSelected = (filters.locations?.length || 0) > 1;

  const [categorizedData, setCategorizedData] = useState<CategorizedFollowUps>({
    all: [],
    sameDay: [],
    unscheduled: [],
  });

  const { data, isLoading } = useScopedQuery({
    queryKey: queryKeys.practiceAnalyticsCharts(`hygieneFollowUpSubView-${isDemoAccount}-${JSON.stringify(filters)}`),
    queryFn: () =>
      isDemoAccount || !filters.locations?.length
        ? null
        : PracticeAnalyticsApi.getPracticeAnalyticsRecords<PracticeAnalyticsTypes.HygieneFollowUpResponse>({
            locationIds: filters.locations,
            queries: [query],
            variables: getPracticeAnalyticsQueryVariables(filters),
          }),
    onError: () => {
      alert.error(t("Couldn't load the dashboard data. Please try again."));
    },
    select: (data) => (isDemoAccount ? demoData?.hygieneFollowUp : data),
    retry: false,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5,
  });

  const processedData = useMemo(() => {
    return {
      aggregatedData: PracticeAnalyticsAggregations.hygieneFollowUpDetails(data),
      patientIds: Object.values(data?.data || {}).reduce((acc, locationDetails) => {
        return PatientsHelpers.collectPatientIds(
          locationDetails?.location?.hygieneReappointment?.details?.patients,
          acc
        );
      }, [] as string[]),
    };
  }, [data?.data]);

  const patientsLastContacted = usePatientsLastContacted({
    patientIds: processedData.patientIds,
  });

  const middleColumns = [
    {
      Header: t('Completed Hygiene'),
      headerLabel: t('Completed Hygiene'),
      accessor: ({ hygieneAppointmentDate }: PracticeAnalyticsTypes.PatientInfo) => hygieneAppointmentDate || '',
      cellRenderer: (date: string) => (date ? formatters.date.format(date) : '-'),
      id: 'hygieneAppointmentDate',
      width: 200,
    },
  ];

  const tabsCommonConfig = {
    colConfig: subViewTableColConfig({
      actionsTrackingIdBase: 'hygiene-follow-up-patient',
      middleColumns,
      patientsLastContacted,
    }),
    emptyStateConfig: 'users' as keyof typeof emptyStateGraphics,
    tableInstanceId: 'hygieneFollowUpPracticeSubView',
  };

  useEffect(() => {
    const patients = processedData.aggregatedData.hygieneReappointment.details?.patients || [];

    setCategorizedData(
      patients.reduce(
        (final, patient) => {
          let value = { ...final, all: [...final.all, patient] };
          if (patient.scheduledSameDay) {
            value = { ...value, sameDay: [...value.sameDay, patient] };
          }
          if (!patient.nextHygieneAppointmentDate) {
            value = { ...value, unscheduled: [...value.unscheduled, patient] };
          }
          return value;
        },
        { all: [], sameDay: [], unscheduled: [] } as CategorizedFollowUps
      )
    );
    onPatientsLoad?.(patients);
  }, [processedData]);

  return (
    <>
      <InlineStats
        data={[
          {
            label: t('Hygiene Visits'),
            value: formatters.value.format(processedData.aggregatedData.hygieneReappointment.totals.patients),
          },
          {
            label: t('Reappointment Same Day'),
            value: formatters.value.format(
              processedData.aggregatedData.hygieneReappointment.totals.rescheduledSameDay || 0
            ),
          },
          {
            label: t('Not Yet Rescheduled'),
            value: formatters.value.format(
              Math.round(processedData.aggregatedData.hygieneReappointment.totals.unscheduled || 0)
            ),
          },
        ]}
        isDemoAccount={showDemoChipAndBanner}
        isLoading={isLoading}
      />
      <HygieneFollowUpMetric clickNoop isDrillDownPage />
      <TabbedTable
        actions={BulkMessageButton}
        autorizedToExport={PracticeAnalyticsApi.auditPracticeAnalyticsExport}
        exportFileName='Hygiene Follow Up Patients' // Translation not needed
        initialTab='all'
        isExportReady={!isLoading && !patientsLastContacted.isLoading}
        isLoading={isLoading}
        isSelectable={isBulkMessagingEnabled}
        processExportableData={(data) =>
          // Translation is not needed as this is for export purpose
          ExportUtils.processExportableData({
            columns: [
              'Patient Name',
              ...(multipleLocationsSelected ? ['Location Name'] : []),
              'Phone Number',
              'Email',
              'Completed Hygiene',
              'Last Contacted',
              '$ Value',
            ],
            data,
            deriveExportValue: (params) =>
              PatientsHelpers.deriveExportValue({
                ...params,
                lastContactedDates: patientsLastContacted.lastContactedDates,
                locationNames,
              }),
            sortColumn: 'productionAmount',
          })
        }
        showExportIcon={exportEnabled}
        tabs={{
          all: {
            ...tabsCommonConfig,
            data: categorizedData.all,
            label: t('All'),
            trackingId: trackingIds.practiceAnalytics.hygieneFollowUpAllTab,
          },
          sameDay: {
            ...tabsCommonConfig,
            data: categorizedData.sameDay,
            label: t('Same Day'),
            trackingId: trackingIds.practiceAnalytics.hygieneFollowUpSameDayTab,
          },
          unscheduled: {
            ...tabsCommonConfig,
            data: categorizedData.unscheduled,
            label: t('Unscheduled'),
            trackingId: trackingIds.practiceAnalytics.hygieneFollowUpUnscheduledTab,
          },
        }}
        trackingIdBase='hygiene-follow-up-patients'
        wrapperStyle={commonStyles.drillDownTableSize}
      />
    </>
  );
};
