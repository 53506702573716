import dayjs from 'dayjs';
import { PhoneAnalyticsTypes } from '@frontend/api-analytics';
import { demoDataUtils } from '../../../utils';
import {
  CallAnswerRateResponse,
  CallsSummaryResponse,
  DeviceExtensionsResponse,
  HourlyInsightsResponse,
  LongDurationCallsResponse,
  TotalCallsAndDurationResponse,
} from '../hooks';

const totalCallsKeys: PhoneAnalyticsTypes.CallsAggregationKey[] = ['inbound', 'outbound'];
const incomingCallsKeys: PhoneAnalyticsTypes.IncomingCallsAggregationKey[] = ['abandoned', 'answered', 'missed'];
const sourceTypeKeys: PhoneAnalyticsTypes.SourceTypeAggregationKey[] = ['current_patient', 'unknown'];
const hourlyInsightsKey: PhoneAnalyticsTypes.HourlyInsightsAggregationKey[] = [
  'total_calls',
  'total_inbound_calls',
  'total_incoming_abandoned_calls',
  'total_incoming_answered_calls',
  'total_missed_incoming_calls_closed',
  'total_missed_incoming_calls_open',
  'total_missed_incoming_calls',
  'total_outbound_abandoned_calls',
  'total_outbound_answered_calls',
  'total_outbound_calls',
];

const last14Days = demoDataUtils.generateConsecutiveDates(
  new Date(dayjs().subtract(14, 'days').format('YYYY/MM/DD')),
  14
);

const zeroArray = new Array(4).fill(0);
const prefillZeros = (data: number[]) => [...zeroArray, ...data, ...zeroArray];
const generateDailyCount = () => Math.floor(Math.random() * 101);
const generateHourlyCount = () => prefillZeros(Array.from({ length: 16 }, () => Math.floor(Math.random() * 21)));
const generateDailyDuration = () => Math.floor(Math.random() * 500 + 500);
const generateHourlyDuration = () => prefillZeros(Array.from({ length: 16 }, () => Math.floor(Math.random() * 500)));
const extensions = demoDataUtils.generateRandomUserNames(5);

const generateExtensionsCalls = (
  dates: ReturnType<typeof demoDataUtils.generateConsecutiveDates>,
  extensions: ReturnType<typeof demoDataUtils.generateRandomUserNames>
) => {
  const blankArray = new Array(16).fill(0);
  return extensions.map((extension) => {
    return {
      Aggregates: dates.map((date) => {
        // Keep some values absolute zero so charts look more realistic
        const data = Math.random() > 0.5 ? blankArray : blankArray.map(() => Math.floor(Math.random() * 5));

        return {
          Date: dayjs(date).format('YYYY-MM-DD'),
          Values: prefillZeros(data),
        };
      }),
      Extention: `Dr. ${extension.firstName} ${extension.lastName}`,
    };
  });
};

const generateCallsAndDurations = <T extends string>(locationIds: string[], keys: T[]) => ({
  locationIds: locationIds.map((locationId) => ({
    id: locationId,
    dates: last14Days.map((date) => ({
      date: dayjs(date).format('YYYY-MM-DD'),
      aggregates: keys.map((key) => ({
        key,
        values: {
          calls: {
            daily: generateDailyCount(),
            hourly: generateHourlyCount(),
          },
          duration: {
            daily: generateDailyDuration(),
            hourly: generateHourlyDuration(),
          },
        },
      })),
    })),
  })),
});

export const data = (locationIds: string[]) => {
  const callAnswerRate: CallAnswerRateResponse = {
    locationIds: locationIds.map((locationId) => ({
      id: locationId,
      dates: last14Days.map((date) => ({
        date: dayjs(date).format('YYYY-MM-DD'),
        daily: generateDailyCount(),
        hourly: generateHourlyCount(),
      })),
      rate: Math.floor(Math.random() * 100),
    })),
    weave_average: last14Days.map(() => generateDailyCount()),
  };

  const totalCallsAndDurations: TotalCallsAndDurationResponse<PhoneAnalyticsTypes.CallsAggregationKey> =
    generateCallsAndDurations<PhoneAnalyticsTypes.CallsAggregationKey>(locationIds, totalCallsKeys);

  const incomingCallsAndDurations: TotalCallsAndDurationResponse<PhoneAnalyticsTypes.IncomingCallsAggregationKey> =
    generateCallsAndDurations<PhoneAnalyticsTypes.IncomingCallsAggregationKey>(locationIds, incomingCallsKeys);

  const sourceTypeCallsAndDurations: TotalCallsAndDurationResponse<PhoneAnalyticsTypes.SourceTypeAggregationKey> =
    generateCallsAndDurations<PhoneAnalyticsTypes.SourceTypeAggregationKey>(locationIds, sourceTypeKeys);

  const longDurationCalls: LongDurationCallsResponse = {
    locationIds: locationIds.map((locationId) => {
      return {
        id: locationId,
        dates: last14Days.map((date) => {
          const hourlyLongCalls = prefillZeros(Array.from({ length: 16 }, () => Math.floor(Math.random() * 2)));

          return {
            date: dayjs(date).format('YYYY-MM-DD'),
            aggregates: totalCallsKeys.map((key) => ({
              key,
              hourly: hourlyLongCalls,
              total: hourlyLongCalls.reduce((acc, value) => acc + value, 0),
            })),
          };
        }),
      };
    }),
  };

  const deviceExtensions: DeviceExtensionsResponse = {
    LocationIds: locationIds.map((locationId) => ({
      CallAnswered: generateExtensionsCalls(last14Days, extensions),
      CallPlaced: generateExtensionsCalls(last14Days, extensions),
      Id: locationId,
    })),
  };

  const hourlyInsights: HourlyInsightsResponse = {
    locationIds: locationIds.map((locationId) => ({
      id: locationId,
      aggregates: hourlyInsightsKey.map((key) => {
        const hourlyCalls = prefillZeros(Array.from({ length: 16 }, () => Math.floor(Math.random() * 50)));

        return {
          key,
          hourly: hourlyCalls,
          total: hourlyCalls.reduce((acc, value) => acc + value, 0),
        };
      }),
    })),
  };

  const callsSummary: CallsSummaryResponse = {
    answerRate: {
      abandoned: 80,
      answered: 150,
      missed: 20,
      rate: 60,
      dates: last14Days.map((date) => ({
        date: dayjs(date).format('YYYY-MM-DD'),
        daily: generateDailyCount(),
        hourly: generateHourlyCount(),
      })),
    },
    callDuration: {
      avg: 62,
      dates: last14Days.map((date) => ({
        date: dayjs(date).format('YYYY-MM-DD'),
        daily: generateDailyDuration(),
        hourly: generateHourlyDuration(),
      })),
    },
    totalCalls: {
      inbound: 250,
      outbound: 100,
      total: 350,
      dates: last14Days.map((date) => ({
        date: dayjs(date).format('YYYY-MM-DD'),
        daily: generateDailyCount(),
        hourly: generateHourlyCount(),
      })),
    },
  };

  return {
    callAnswerRate,
    callsSummary,
    deviceExtensions,
    hourlyInsights,
    incomingCallsAndDurations,
    longDurationCalls,
    sourceTypeCallsAndDurations,
    totalCallsAndDurations,
  };
};
