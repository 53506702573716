import { useReducer } from 'react';
import { css } from '@emotion/react';
import { AnimatePresence, motion } from 'framer-motion';
import { theme } from '@frontend/theme';
import { CaretDownIconSmall, NakedButton, Text } from '@frontend/design-system';

interface Props {
  headingText: string;
  children: React.ReactNode;
  isExpanded?: boolean;
}

export const CompletedItemListWrapper = ({ headingText, children, isExpanded = false }: Props) => {
  const [isExpandedContent, toggleExpandContent] = useReducer((value) => !value, isExpanded);

  return (
    <>
      <NakedButton css={toggleExpandButtonStyle} onClick={toggleExpandContent}>
        <Text as='span' size='small' color='light'>
          {headingText}
        </Text>
        <CaretDownIconSmall color='light' css={iconStyle(isExpandedContent)} />
      </NakedButton>
      <AnimatePresence initial={false}>
        {isExpandedContent && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.3 }}
            css={{ overflow: 'hidden', listStyleType: 'none', paddingLeft: 0 }}
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

const toggleExpandButtonStyle = css({
  display: 'flex',
  gap: theme.spacing(1),
  alignItems: 'center',
  marginBottom: theme.spacing(1),
});

const iconStyle = (isExpanded: boolean) =>
  css({
    transition: 'transform 300ms ease-out',
    transform: `rotate(${isExpanded ? 180 : 0}deg)`,
  });
