import { BarProps } from 'recharts';

type RectRadius = [number, number, number, number];

/**
 * This is the same function as the one in recharts.
 */
const getRectanglePath = (x: number, y: number, width: number, height: number, radius: number | RectRadius): string => {
  const maxRadius = Math.min(Math.abs(width) / 2, Math.abs(height) / 2);
  const ySign = height >= 0 ? 1 : -1;
  const xSign = width >= 0 ? 1 : -1;
  const clockWise = (height >= 0 && width >= 0) || (height < 0 && width < 0) ? 1 : 0;
  let path;

  if (maxRadius > 0 && radius instanceof Array) {
    const newRadius: RectRadius = [0, 0, 0, 0];
    for (let i = 0, len = 4; i < len; i++) {
      newRadius[i] = radius[i] > maxRadius ? maxRadius : radius[i];
    }

    path = `M${x},${y + ySign * newRadius[0]}`;

    if (newRadius[0] > 0) {
      path += `A ${newRadius[0]},${newRadius[0]},0,0,${clockWise},${x + xSign * newRadius[0]},${y}`;
    }

    path += `L ${x + width - xSign * newRadius[1]},${y}`;

    if (newRadius[1] > 0) {
      path += `A ${newRadius[1]},${newRadius[1]},0,0,${clockWise},
        ${x + width},${y + ySign * newRadius[1]}`;
    }
    path += `L ${x + width},${y + height - ySign * newRadius[2]}`;

    if (newRadius[2] > 0) {
      path += `A ${newRadius[2]},${newRadius[2]},0,0,${clockWise},
        ${x + width - xSign * newRadius[2]},${y + height}`;
    }
    path += `L ${x + xSign * newRadius[3]},${y + height}`;

    if (newRadius[3] > 0) {
      path += `A ${newRadius[3]},${newRadius[3]},0,0,${clockWise},
        ${x},${y + height - ySign * newRadius[3]}`;
    }
    path += 'Z';
  } else if (maxRadius > 0 && radius === +radius && radius > 0) {
    const newRadius = Math.min(maxRadius, radius);

    path = `M ${x},${y + ySign * newRadius}
            A ${newRadius},${newRadius},0,0,${clockWise},${x + xSign * newRadius},${y}
            L ${x + width - xSign * newRadius},${y}
            A ${newRadius},${newRadius},0,0,${clockWise},${x + width},${y + ySign * newRadius}
            L ${x + width},${y + height - ySign * newRadius}
            A ${newRadius},${newRadius},0,0,${clockWise},${x + width - xSign * newRadius},${y + height}
            L ${x + xSign * newRadius},${y + height}
            A ${newRadius},${newRadius},0,0,${clockWise},${x},${y + height - ySign * newRadius} Z`;
  } else {
    path = `M ${x},${y} h ${width} v ${height} h ${-width} Z`;
  }

  return path;
};

const defaultProps = {
  x: 0,
  y: 0,
  width: 0,
  height: 0,
  // The radius of border
  // The radius of four corners when radius is a number
  // The radius of left-top, right-top, right-bottom, left-bottom when radius is an array
  radius: 0,
  stroke: 'none',
  strokeWidth: 1,
  fill: '#000',
};

/**
 * This component is basically the Rectangle from recharts, but without the Animate parts.
 * The idea behind this component is to draw the stroke inside the rectangle, instead of outside.
 * SVGs do not have support to draw strokes inside the shape, so we have to use a clipPath to achieve this effect.
 */
export const InsetStrokeRectangle = (props: Omit<BarProps, 'id'> & { id: string }) => {
  const { x, y, width, height, radius, fill, stroke, strokeWidth, id, strokeOpacity, fillOpacity, cursor } = {
    ...defaultProps,
    ...props,
  };

  const resolvedStrokeWidth = typeof strokeWidth === 'number' ? strokeWidth : parseInt(strokeWidth);

  if (x !== +x || y !== +y || width !== +width || height !== +height || width === 0 || height === 0) {
    return null;
  }

  return (
    <>
      <defs>
        <path id={`path-${id}`} d={getRectanglePath(x, y, width, height, radius)} />
        <clipPath id={`clip-${id}`}>
          <use xlinkHref={`#path-${id}`} />
        </clipPath>
      </defs>
      <g>
        <use
          cursor={cursor}
          xlinkHref={`#path-${id}`}
          stroke={stroke}
          // double the stroke width to account for the stroke being drawn inside the rectangle
          strokeWidth={resolvedStrokeWidth * 2}
          fill={fill}
          clipPath={`url(#clip-${id})`}
          strokeOpacity={strokeOpacity}
          fillOpacity={fillOpacity}
        />
      </g>
    </>
  );
};
