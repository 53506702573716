import {
  CreateEmailTemplateRequest,
  CreateEmailTemplateResponse,
  DeleteEmailTemplateRequest,
  DeleteEmailTemplateResponse,
  EmailTemplate,
  ListEmailTemplatesResponse,
  UpdateEmailTemplateLocationsRequest,
  UpdateEmailTemplateLocationsResponse,
  UpdateEmailTemplateRequest,
  UpdateEmailTemplateResponse,
} from '@weave/schema-gen-ts/dist/schemas/messaging/bulk/email-template/v1/emailtemplate.pb';
import { MutationOptions, QueryClient, QueryKey, useMutation, useQueryClient } from 'react-query';
import { keys } from './queries';
import { SchemaEmailTemplate } from './services';

export type PaginationData = { pages: ListEmailTemplatesResponse[]; pageParams: [] } | undefined;

type MutationContext = {
  previousLists: [QueryKey, PaginationData[]];
  newTemplates?: ListEmailTemplatesResponse;
  previousTemplate?: EmailTemplate;
};

const getPreviousLists = (queryClient: QueryClient) => {
  // loop through all the matching query keys irrespective of the request itself
  const previousLists = queryClient.getQueriesData<PaginationData>(keys.listEmailTemplatesBase());

  // cancel all the queries for the matching query key
  previousLists.forEach(([keys]) => queryClient.cancelQueries(keys));

  return previousLists;
};

export const useCreateEmailTemplate = (
  options: MutationOptions<CreateEmailTemplateResponse, unknown, CreateEmailTemplateRequest> = {}
) =>
  useMutation({
    mutationKey: 'create-email-template',
    mutationFn: SchemaEmailTemplate.CreateEmailTemplate,
    ...options,
  });

export const useUpdateEmailTemplate = (
  options: MutationOptions<UpdateEmailTemplateResponse, unknown, UpdateEmailTemplateRequest> = {}
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: 'update-email-template',
    mutationFn: SchemaEmailTemplate.UpdateEmailTemplate,
    onMutate: (req) => {
      const previousLists = getPreviousLists(queryClient);

      let previousTemplate: EmailTemplate | undefined;

      // loop through all the matching query keys and update the template with the new data
      previousLists.forEach(([key, data]) => {
        const newPages = data?.pages.map((page) => {
          return page?.templates?.map((template) => {
            if (template.id === req.templateId) {
              // store the previous template to revert back in case of error
              previousTemplate = template;

              // create the updated template object
              const updatedTemplate = { ...template, ...req, updatedAt: new Date().toISOString() };

              // update the template with the new data
              if (req.templateId)
                queryClient.setQueryData(keys.getEmailTemplate({ templateId: req.templateId }), updatedTemplate);

              return updatedTemplate;
            }
            return template;
          });
        });
        queryClient.setQueryData(key, { ...data, pages: newPages });
      });

      return { previousLists, previousTemplate };
    },
    onError: (_err, req, context: unknown) => {
      const { previousLists, previousTemplate } = context as MutationContext;
      // revert back to the previous template lists
      previousLists.forEach(([key, data]) => {
        queryClient.setQueryData(key as string[], data);
      });

      // revert back to the previous template
      if (req.templateId)
        queryClient.setQueryData(keys.getEmailTemplate({ templateId: req.templateId }), previousTemplate);
    },
    ...options,
  });
};

export const useUpdateEmailTemplateLocations = (
  options: MutationOptions<UpdateEmailTemplateLocationsResponse, unknown, UpdateEmailTemplateLocationsRequest>
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: 'update-email-template-locations',
    mutationFn: SchemaEmailTemplate.UpdateEmailTemplateLocations,
    onMutate: (req) => {
      const previousLists = getPreviousLists(queryClient);

      let previousTemplate: EmailTemplate | undefined;

      // loop through all the matching query keys and update the template with the new data
      previousLists.forEach(([key, data]) => {
        const newPages = data?.pages.map((page) => {
          return page?.templates?.map((template) => {
            if (template.id === req.templateId) {
              // store the previous template to revert back in case of an error
              previousTemplate = template;

              // create the updated template object
              const updatedTemplate = { ...template, ...req, updatedAt: new Date().toISOString() };

              // update the template with the new data
              if (req.templateId)
                queryClient.setQueryData(keys.getEmailTemplate({ templateId: req.templateId }), updatedTemplate);

              return updatedTemplate;
            }
            return template;
          });
        });
        queryClient.setQueryData(key, { ...data, pages: newPages });
      });

      return { previousLists, previousTemplate };
    },
    onError: (_err, req, context: unknown) => {
      const { previousLists, previousTemplate } = context as MutationContext;

      // revert back to the previous template lists
      previousLists.forEach(([key, data]) => {
        queryClient.setQueryData(key as string[], data);
      });

      // revert back to the previous template
      if (req.templateId)
        queryClient.setQueryData(keys.getEmailTemplate({ templateId: req.templateId }), previousTemplate);
    },
    ...options,
  });
};

export const useDeleteEmailTemplate = (
  options: MutationOptions<DeleteEmailTemplateResponse, unknown, DeleteEmailTemplateRequest> = {}
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: 'delete-email-template',
    mutationFn: SchemaEmailTemplate.DeleteEmailTemplate,
    onMutate: (req) => {
      const previousLists = getPreviousLists(queryClient);

      // filter out the matching templateId from the templates array for that respective query key
      previousLists.forEach(([key, data]) => {
        const newPages = data?.pages.map((page) => {
          return page?.templates?.filter((template) => template.id !== req.templateId);
        });
        queryClient.setQueryData(key, { ...data, pages: newPages });
      });

      return { previousLists };
    },
    onError: (_err, _req, context: unknown) => {
      const { previousLists } = context as MutationContext;
      previousLists.forEach(([key, data]) => {
        queryClient.setQueryData(key as string[], data);
      });
    },
    ...options,
  });
};
