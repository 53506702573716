import { useEffect, useMemo } from 'react';
import { css } from '@emotion/react';
import { OnboardingModulesTypes } from '@frontend/api-onboarding-modules';
import { useTranslation } from '@frontend/i18n';
import { Icon, IconName } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Chip, Modal, NakedUl, Text, useModalControl } from '@frontend/design-system';
import { NWXDashboardTaskInfo, trackingIds } from '../constants';
import { incompleteTasksIntroModalLocalStorageHelper } from '../helpers';

const introDialogTrackingIds = trackingIds.nwxDashboardTasksSection.taskIncompleteIntroDialog;

const buttonTrackingIdTaskIdMap: Partial<Record<OnboardingModulesTypes.TaskIds, string>> = {
  [OnboardingModulesTypes.TaskIds.EINSetup]: introDialogTrackingIds.verifyBusinessInformationButton,
  [OnboardingModulesTypes.TaskIds.UserInviteSetup]: introDialogTrackingIds.inviteUserToWeaveButton,
  [OnboardingModulesTypes.TaskIds.ScheduleCallWithOnboarder]: introDialogTrackingIds.scheduleTimeNowButton,
};

interface Props {
  taskList: NWXDashboardTaskInfo[];
  locationId: string;
  userId: string;
  onTaskClick: (task: NWXDashboardTaskInfo) => void;
}

export const IncompleteTasksIntroModal = ({ taskList, locationId, userId, onTaskClick }: Props) => {
  const { t } = useTranslation('onboarding-modules');
  const modalControls = useModalControl();

  const { completedTasks, incompleteTasks } = useMemo(
    () => ({
      completedTasks: taskList.filter((task) => task.isCompleted),
      incompleteTasks: taskList.filter((task) => !task.isCompleted),
    }),
    [taskList]
  );
  const selectedTask = incompleteTasks[0];
  // if no incomplete tasks, then don't show this modal.
  const shouldShowModal = selectedTask && locationId && userId;

  useEffect(() => {
    if (shouldShowModal) {
      const isShown = incompleteTasksIntroModalLocalStorageHelper.checkIsShown({
        locationId,
        userId,
      });
      if (!isShown) {
        modalControls.openModal();
      }
      return;
    }
  }, [shouldShowModal]);

  if (!shouldShowModal) return null;

  const handleClose = () => {
    incompleteTasksIntroModalLocalStorageHelper.setShown({ locationId, userId });
    modalControls.closeModal();
  };
  const handleStartTask = () => {
    onTaskClick(selectedTask);
    handleClose();
  };

  return (
    <Modal
      {...modalControls.modalProps}
      maxWidth={600}
      disableCloseOnOverlayClick
      disableCloseOnEscape
      onClose={handleClose}
    >
      <Modal.Header onClose={handleClose} closeBtnTrackingId={introDialogTrackingIds.closeButton}>
        <div css={{ display: 'flex', alignItems: 'center', gap: theme.spacing(1) }}>
          <Icon name='graduation-cap' />
          {t("Let's finish setting Weave up")}
        </div>
      </Modal.Header>
      <Modal.Body>
        <NakedUl css={ulStyle}>
          {completedTasks.map((task) => (
            <Chip
              key={task.id}
              as='li'
              variant='success'
              css={{ maxWidth: 'max-content' }}
              leftElement={<Icon name={task.icon} />}
              rightElement={
                <>
                  <Icon name='check-small' css={{ marginLeft: theme.spacing(1) }} />
                  {t('Complete')}
                </>
              }
            >
              <strong>{task.dialogInfo?.title || task.title}</strong>
            </Chip>
          ))}
          {incompleteTasks.map((task) =>
            task.id === selectedTask.id ? (
              <li key={task.id} css={selectedTaskContainer}>
                <TaskHeader icon={task.icon} title={task.dialogInfo?.title || task.title} />
                <div css={{ marginLeft: theme.spacing(3) }}>
                  {typeof task.dialogInfo?.description === 'string' ? (
                    <Text>{task.dialogInfo?.description}</Text>
                  ) : (
                    task.dialogInfo?.description || <Text>{task.description}</Text>
                  )}
                </div>
              </li>
            ) : (
              <li key={task.id} css={{ paddingLeft: theme.spacing(2) }}>
                <TaskHeader icon={task.icon} title={task.dialogInfo?.title || task.title} />
              </li>
            )
          )}
        </NakedUl>
      </Modal.Body>
      <Modal.Footer
        primary={{
          label: selectedTask.dialogInfo?.buttonText || selectedTask.title,
          trackingId: buttonTrackingIdTaskIdMap[selectedTask.id],
          onClick: handleStartTask,
        }}
        secondary={{
          label: t("I'll Finish This Later"),
          trackingId: introDialogTrackingIds.finishLaterButton,
          onClick: handleClose,
        }}
      />
    </Modal>
  );
};

const TaskHeader = ({ icon, title }: { icon: IconName; title: string }) => (
  <div css={{ display: 'flex', alignItems: 'center', gap: theme.spacing(1) }}>
    <Icon name={icon} />
    <Text size='medium' weight='bold'>
      {title}
    </Text>
  </div>
);

const ulStyle = css({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  margin: theme.spacing(2, 0, 1, 0),
});

const selectedTaskContainer = css({
  border: `1px solid ${theme.colors.text.disabled}`,
  borderRadius: theme.borderRadius.medium,
  padding: theme.spacing(2),
});
