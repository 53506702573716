import { CustomizationFlagTypes, useCustomizationFlagShallowStore } from '@frontend/api-customization-flags';
import { getUser } from '@frontend/auth-helpers';
import { useIsCallIntelDemoAccount } from '../components/call-intelligence/hooks';

export const useShowCallIntelNav = () => {
  const { flags } = useCustomizationFlagShallowStore('flags');

  const isCallIntelActive =
    flags.callintelligence?.state === CustomizationFlagTypes.CustomizationFlagState.ACTIVE ||
    flags.callintelligence?.state === CustomizationFlagTypes.CustomizationFlagState.PROMOTE;

  const isDemoAccount = useIsCallIntelDemoAccount();
  const isWeaveDemoUser = getUser()?.username.toLowerCase().includes('@demoweave') || false;

  return isCallIntelActive || isDemoAccount || isWeaveDemoUser;
};
