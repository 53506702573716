import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { SIDEBAR_BREAKPOINTS } from './constants';

export const overridingRadioGroupStyling = css`
  display: flex;
  .hide-radio {
    margin-bottom: 0;
    &:last-of-type {
      label {
        margin-right: 0;
      }
    }
    & > div {
      display: none;
    }
    label {
      cursor: pointer;
      margin-left: 0;
      margin-right: ${theme.spacing(1)};
      padding: ${theme.spacing(1)};
    }
  }
`;

const selectedRadioOptionStyling = css`
  label {
    border-radius: ${theme.borderRadius.small};
    background: ${theme.colors.neutral10};
  }
`;

const unselectedRadioOptionStyling = css`
  label {
    border-radius: ${theme.borderRadius.small};
    border: 1px solid ${theme.colors.neutral50};
  }
`;

export const getRadioOptionStyling = (isSelected: boolean) =>
  isSelected ? selectedRadioOptionStyling : unselectedRadioOptionStyling;

export const labelStyling = css`
  @media (max-width: ${SIDEBAR_BREAKPOINTS.tablet}px) {
    margin-bottom: ${theme.spacing(0.5)};
  }
`;

export const dropdownSectionTypeStyling = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (min-width: ${SIDEBAR_BREAKPOINTS.mobile + 1}px) and (max-width: ${SIDEBAR_BREAKPOINTS.tablet}px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const squareStyling = css`
  width: ${theme.spacing(2.5)};
  height: ${theme.spacing(2.5)};
  border: 3px solid ${theme.colors.neutral90};
`;

export const roundedStyling = css`
  width: ${theme.spacing(2.5)};
  height: ${theme.spacing(2.5)};
  border: 3px solid ${theme.colors.neutral90};
  border-radius: ${theme.borderRadius.small};
`;

export const circleStyling = css`
  width: ${theme.spacing(2.5)};
  height: ${theme.spacing(2.5)};
  border: 3px solid ${theme.colors.neutral90};
  border-radius: 50%;
`;
