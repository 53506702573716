import { FC, useEffect } from 'react';
import { css } from '@emotion/react';
import { BarChartAppearance } from '@frontend/charts';
import { defaultDateRangeMap } from '../../utils';
import {
  AppointmentsCountChart,
  AppointmentsPatientsTypeChart,
  AppointmentsPmsStatusChart,
  AppointmentsWeaveStatusChart,
} from './charts';
import { useFetchAggregatedAppointmentsData } from './hooks';

interface Props {
  onFetchStateChange: (isFetching: boolean) => void;
}

const commonChartAppearanceProps: BarChartAppearance = {
  collectiveTooltip: true,
  showGridLines: true,
  showXAxis: true,
  showYAxis: true,
};

export const AppointmentChartsView: FC<React.PropsWithChildren<Props>> = ({ onFetchStateChange }) => {
  const { data, isLoading } = useFetchAggregatedAppointmentsData({
    dateRangeMap: defaultDateRangeMap,
  });

  useEffect(() => {
    onFetchStateChange(isLoading);
  }, [isLoading]);

  const commonProps = {
    appearance: commonChartAppearanceProps,
    data,
    isLoading,
  };

  return (
    <div css={styles.wrapper}>
      <AppointmentsCountChart {...commonProps} />
      <AppointmentsPmsStatusChart {...commonProps} />
      <AppointmentsWeaveStatusChart {...commonProps} />
      <AppointmentsPatientsTypeChart {...commonProps} />
    </div>
  );
};

const styles = {
  wrapper: css`
    height: 100%;
    overflow: auto;
  `,
};
