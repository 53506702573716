import { useEffect, useState } from 'react';
import { useEditor, useNode } from '@craftjs/core';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { NakedButton } from '@frontend/design-system';
import { useComposerSection, useLinks } from '../../hooks';
import { ComposerSection, FooterLink, FooterSectionProps } from '../../types';
import { EmailSvg } from '../assets/email-svg';
import { FacebookSvg } from '../assets/facebook-svg';
import { GoogleSvg } from '../assets/google-svg';
import { PhoneSvg } from '../assets/phone-svg';
import { useContentComposer } from '../content-composer-provider';
import { FooterOutput } from '../outputs';
import { FooterSettings } from '../settings';
import { FooterTool } from '../tools';
import { BaseComposerSection } from './floating-toolbar';

export type FooterMode = 'default' | 'options' | 'link' | 'custom';

const Pipe = () => (
  <div
    css={{
      width: 1,
      height: 12,
      background: theme.colors.neutral50,
      margin: theme.spacing(0, 2),
    }}
  />
);

export const FooterSection: ComposerSection<FooterSectionProps> = ({
  bold = false,
  fontType = 'Arial',
  frameAlignment,
  frameColor,
  italic = false,
  links,
  paddingBottom,
  paddingLeft,
  paddingRight,
  paddingTop,
  textColor = theme.colors.black,
  textSize = 16,
  ...rest
}) => {
  const { t } = useTranslation('content-composer');
  const [mode, setMode] = useState<FooterMode>('default');
  const [selectedLink, setSelectedLink] = useState<FooterLink>();

  const { address, businessName } = useLinks();
  const { setProps } = useComposerSection<FooterSectionProps>();

  const { composerMode } = useContentComposer();
  const isMarketingMode = composerMode === 'marketing';

  const { id } = useNode((state) => ({
    id: state.id,
  }));

  const { actions, selected } = useEditor((state, query) => {
    const [currentNodeId] = state.events.selected;
    let selected;
    if (currentNodeId) {
      selected = {
        id: currentNodeId,
        isDeletable: query.node(currentNodeId).isDeletable(),
      };
    }

    return {
      selected,
    };
  });

  const fontStyles = {
    fontWeight: bold ? 'bold' : 'normal',
    fontStyle: italic ? 'italic' : 'normal',
    fontFamily: fontType,
    fontSize: textSize + 'px',
    color: textColor,
    textDecoration: 'underline',
  };

  useEffect(() => {
    if (selected?.id !== id && mode !== 'default') {
      setMode('default');
    }
  }, [selected?.id]);

  const handleUnselectClick = () => {
    setSelectedLink(undefined);
    setMode('options');
  };

  const handleLinkUpdate = (link: FooterLink, modeType: FooterMode) => {
    setSelectedLink(link);
    setMode(modeType);
  };

  const alphaNumericRegex = /[^a-z0-9]/gi;
  const createId = (link: FooterLink) =>
    link.type + '-' + link.link.replace(alphaNumericRegex, '') + '-' + link.text.replace(alphaNumericRegex, '');

  return (
    <BaseComposerSection
      sectionType='Footer'
      title={t('Footer')}
      {...rest}
      id={id}
      onDelete={!isMarketingMode && selected?.isDeletable ? () => actions.delete(selected.id) : undefined}
      disableDrag={isMarketingMode}
      css={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: frameAlignment === 'left' ? 'flex-start' : frameAlignment === 'right' ? 'flex-end' : 'center',
        width: '100%',
        background: frameColor,
        padding: `${paddingTop} ${paddingRight} ${paddingBottom} ${paddingLeft}`,
      }}
      links={links}
      mode={mode}
      setMode={setMode}
      selectedLink={selectedLink}
      setSelectedLink={setSelectedLink}
      settings={{
        bold,
        fontType,
        italic,
        textSize,
        textColor,
      }}
    >
      {links && !!links.length && (
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: theme.spacing(1.5),
            columnGap: theme.spacing(1),
          }}
        >
          {links.map((link, index) => (
            <div
              key={index}
              css={css`
                position: relative;
              `}
            >
              {selected?.id === id && (
                <NakedButton
                  className={createId(link)}
                  css={{
                    position: 'absolute',
                    top: link.type === 'custom' ? theme.spacing(-0.5) : 0,
                    left: '50%',
                    transform: 'translate(-50%, -100%)',
                    background: theme.colors.white,
                    borderRadius: theme.borderRadius.small,
                    padding: theme.spacing(0, 0.5),
                    height: theme.spacing(3),
                    width: theme.spacing(3),
                    zIndex: theme.zIndex.middle + 1,
                  }}
                  onClick={() => {
                    if (selectedLink?.link === link.link) setMode('options');
                    setProps((props) => (props.links = links?.filter((l) => l.link !== link.link)));
                  }}
                >
                  <Icon name='x' />
                </NakedButton>
              )}
              {link.type === 'custom' ? (
                <NakedButton css={[buttonStyling, fontStyles]} onClick={() => handleLinkUpdate(link, 'custom')}>
                  {link.text}
                </NakedButton>
              ) : (
                <NakedButton
                  onClick={() =>
                    link.type === 'phone' || link.type === 'email'
                      ? handleUnselectClick()
                      : handleLinkUpdate(link, 'link')
                  }
                >
                  {link.type === 'email' && <EmailSvg fillColor={textColor} />}
                  {link.type === 'phone' && <PhoneSvg fillColor={textColor} />}
                  {link.type === 'facebook' && <FacebookSvg fillColor={textColor} />}
                  {link.type === 'google' && <GoogleSvg fillColor={textColor} />}
                </NakedButton>
              )}
            </div>
          ))}
        </div>
      )}
      <NakedButton
        css={[
          buttonStyling,
          fontStyles,
          {
            marginBottom: theme.spacing(2),
            textDecoration: 'none',
            cursor: 'initial',
          },
        ]}
      >
        {address}
      </NakedButton>
      <NakedButton
        css={[
          fontStyles,
          {
            marginBottom: theme.spacing(2),
            textDecoration: 'none',
            cursor: 'initial',
          },
        ]}
      >
        {businessName}
      </NakedButton>
      {isMarketingMode && (
        <div css={{ display: 'flex', alignItems: 'center', marginBottom: theme.spacing(1) }}>
          <NakedButton css={fontStyles}>{t('Preference Center')}</NakedButton>
          <Pipe />
          <NakedButton css={fontStyles}>{t('Unsubscribe')}</NakedButton>
        </div>
      )}
    </BaseComposerSection>
  );
};

FooterSection.craft = {
  props: {
    bold: false,
    fontType: 'Arial',
    frameAlignment: 'center',
    frameColor: theme.colors.neutral5.toUpperCase(),
    italic: false,
    links: [],
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    textColor: theme.colors.black.toUpperCase(),
    textSize: 16,
  },
  related: {
    settings: FooterSettings,
    output: FooterOutput,
    tool: FooterTool,
  },
};

const buttonStyling = { width: 'auto' };
