import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { Button } from '@frontend/design-system';

interface Props {
  onClick?: (value?: boolean) => void;
  value?: boolean;
}

export const ShowMore = ({ onClick, value }: Props) => {
  const { t } = useTranslation('analytics');

  return (
    <div css={styles.wrapper}>
      <Button onClick={() => onClick?.(!value)} variant='tertiary'>
        {value ? t('Show Less') : t('Show More')}
        <Icon name={value ? 'caret-up-small' : 'caret-down-small'} />
      </Button>
    </div>
  );
};

const styles = {
  wrapper: css`
    display: flex;
    justify-content: flex-end;
  `,
};
