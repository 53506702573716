import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const Divider = () => (
  <hr
    css={css`
      border: 0;
      border-top: solid 1px ${theme.colors.neutral20};
      margin: ${theme.spacing(1, 0)};
    `}
  />
);
