import { ReactNode } from 'react';
import { SerializedStyles, css } from '@emotion/react';
import urlRegex from 'url-regex';
import { BusinessInfoQueries, BusinessInfoTypes } from '@frontend/api-business-information';
import { DepartmentsApi } from '@frontend/api-departments';
import { FeatureFlagQueries } from '@frontend/api-feature-flags';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useLocationDataStore, useLocalizedQuery } from '@frontend/location-helpers';
import { getElectronShellDownloadUrl } from '@frontend/opt-in-to-2-0';
import { queryKeys as phoneQueryKeys } from '@frontend/phone';
import { useFeatureFlagShallowStore } from '@frontend/shared';
import { theme } from '@frontend/theme';
import { useRouteAccess } from './use-route-access';

export interface NavItem {
  to: string;
  name: string;
  show: boolean;
  icon?: ReactNode;
  isExternalLink?: boolean;
  isNew?: boolean;
  statusIcon?: ReactNode;
  style?: SerializedStyles;
}

export type NavGroup = Omit<NavItem, 'to'> & {
  navSubGroupItems: NavItem[];
};

export const usePortalNavItems = () => {
  const { t } = useTranslation('portalNav');
  const { data: tenDlcStatusIconFF } = FeatureFlagQueries.useFeatureFlagIsEnabledQuery('tenDlcStatusIcon', {
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });

  const { flagValues } = useFeatureFlagShallowStore('flagValues');
  const isPartnersPageEnabled = flagValues['our-partners-page'];

  const tenDlcStatusIconFFEnabled = tenDlcStatusIconFF?.enabled;
  const access = useRouteAccess();
  const { isParentLocation } = useLocationDataStore();
  const { data: locationTcrBrand } = BusinessInfoQueries.getLocationTcrBrand({ staleTime: 5000 * 60 });

  // temporary prompt to show some businesses to update their business website
  // will be removed after they are changed
  const isValidBusinessWebsite = (url: string) => {
    const forbiddenDomains = ['google.com', 'yahoo.com', 'hotmail.com', 'gmail.com'];
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const lowerCaseWebsite = url.toLowerCase();

    return (
      !forbiddenDomains.some((domain) => lowerCaseWebsite.includes(domain)) &&
      !emailRegex.test(lowerCaseWebsite) &&
      urlRegex({ exact: true, strict: false }).test(lowerCaseWebsite)
    );
  };

  const showBusinessInfoAlert =
    tenDlcStatusIconFFEnabled &&
    ((locationTcrBrand?.tcrBrand?.entityType === BusinessInfoTypes.EntityType.ENTITY_TYPE_PRIVATE_PROFIT &&
      locationTcrBrand?.tcrBrand?.identityStatus ===
        BusinessInfoTypes.TCRBrandIdentityStatus.TCR_BRAND_IDENTITY_STATUS_UNVERIFIED) ||
      (!locationTcrBrand?.tcrBrand?.website && locationTcrBrand?.tcrBrand?.brandId) ||
      (locationTcrBrand?.tcrBrand?.website && !isValidBusinessWebsite(locationTcrBrand?.tcrBrand?.website)));

  const { data: numDepartments } = useLocalizedQuery({
    queryKey: phoneQueryKeys.listDepartments(),
    queryFn: () => DepartmentsApi.listDept({}),
    select: (data) => data.departments?.length,
  });

  const items: (NavItem | NavGroup)[] = [
    {
      name: t('Switch to App'),
      show: true,
      to: '/home/dashboard',
      icon: <Icon name='computer' />,
      style: css`
        .link-content-wrapper {
          color: ${theme.colors.primary[50]};
          svg {
            fill: ${theme.colors.primary[50]};
          }
        }
      `,
    },
    {
      icon: <Icon name='star' />,
      name: t('Example'),
      to: '/portal/example',
      show: import.meta.env.MODE === 'development',
    },
    {
      icon: <Icon name='data-sync' />,
      name: t('Data Sync'),
      show: !access.combination.hasDataSourceAccess,
      to: '/portal/data-sync',
    },
    {
      icon: <Icon name='settings' />,
      name: t('Account'),
      show: true,
      navSubGroupItems: [
        {
          to: '/portal/account/business-information',
          name: 'Business Information',
          show: access.combination.hasTenDLCFormAccess,
          statusIcon: showBusinessInfoAlert ? (
            <Icon name='warning-badge' color='error' className='statusIcon' />
          ) : undefined,
        },
        {
          name: t('Password policy'),
          show: access.combination.hasPasswordPolicyPermission,
          to: '/portal/account/password-policy',
        },
        {
          name: t('Billing'),
          show: access.combination.hasBillingInfoAccess,
          to: '/portal/account/billing',
        },
        {
          name: t('Users'),
          show: true,
          to: '/portal/account/users',
        },
        {
          name: t('Phone Numbers'),
          show: !isParentLocation,
          to: '/portal/account/phone-numbers',
        },
      ],
    },
    {
      icon: <Icon name='message' />,
      name: t('Messages'),
      show:
        access.feature.autoMessaging ||
        access.feature.bulkMessages ||
        access.feature.debugTools ||
        access.feature.messageQueue ||
        access.feature.reminderSettings ||
        access.feature.textConnect,
      navSubGroupItems: [
        {
          name: t('Bulk Messages'),
          show: access.feature.bulkMessages,
          to: '/portal/messages/bulk',
        },
        {
          name: t('Message Queue'),
          show: access.feature.messageQueue,
          to: '/portal/messages/message-queue',
        },
        {
          name: t('Reminder Settings'),
          show: access.feature.reminderSettings,
          to: '/portal/messages/reminder-settings',
        },
        {
          name: t('Auto-Messaging'),
          show: access.feature.autoMessaging,
          to: '/portal/messages/auto-messaging',
        },
        {
          name: t('Text Connect'),
          show: access.feature.textConnect,
          to: '/portal/messages/text-connect',
        },
        {
          name: t('Debug Tools'),
          show: access.feature.debugTools,
          to: '/portal/messages/debug-tools',
        },
      ],
    },
    {
      icon: <Icon name='calendar' />,
      name: t('Online Scheduling'),
      show: access.customization?.onlineScheduling,
      navSubGroupItems: [
        {
          to: '/portal/online-scheduling/installation',
          name: t('Installation'),
          show: false && access.customization?.onlineScheduling,
        },
        {
          to: '/portal/online-scheduling/booking-site-setup',
          name: t('Booking Site Setup'),
          isNew: true,
          show: access.customization?.onlineScheduling,
        },
        {
          to: '/portal/online-scheduling/custom-fields',
          isNew: true,
          name: t('Custom Fields'),
          show: access.customization?.onlineScheduling,
        },
        {
          to: '/portal/online-scheduling/settings',
          name: t('Settings'),
          show: access.customization?.onlineScheduling,
        },
      ],
    },
    {
      icon: <Icon name='contacts' />,
      name: t('Customers'),
      show: true,
      navSubGroupItems: [
        {
          name: t('CSV upload'),
          show: true,
          to: '/portal/customers/csv-upload',
        },
        {
          name: t('Quick fill'),
          isNew: true,
          show: !isParentLocation && access.feature.quickFill2,
          to: '/portal/customers/quick-fill',
        },
        {
          name: t('Wellness forms'),
          show: !isParentLocation,
          to: '/portal/customers/wellness-forms',
        },
      ],
    },
    {
      icon: <Icon name='phone' />,
      name: t('Phone'),
      show: !isParentLocation,
      navSubGroupItems: [
        {
          name: t('Call Blocking'),
          show: access.customization.phoneSystem,
          to: '/portal/phone/call-blocking',
        },
        {
          name: t('Call Groups'),
          show: access.customization.phoneSystem && access.combination.canEditCallGroups,
          to: '/portal/phone/call-groups',
        },
        {
          name: t('Call Queues'),
          show: access.customization.phoneSystem && access.combination.canManageCallQueues,
          to: '/portal/phone/call-queues',
        },
        {
          name: t('Call Records'),
          show: access.customization.phoneSystem,
          to: '/portal/phone/call-records',
        },
        {
          name: t('Contact Directories'),
          show: access.customization.phoneSystem && access.feature.contactDirectories && access.acl.phoneContactsWrite,
          to: '/portal/phone/contact-directories',
        },
        {
          name: t('Departments'),
          show: access.feature.departments && access.acl.phoneDepartmentWrite,
          to: '/portal/phone/departments',
        },
        {
          name: t('Devices'),
          show: access.customization.phoneSystem,
          to: '/portal/phone/devices',
        },
        {
          name: t('Hold music'),
          show: access.customization.phoneSystem,
          to: '/portal/phone/hold-music',
        },
        {
          name: t('Line Keys'),
          show: access.customization.phoneSystem && access.acl.phoneDevicesWrite,
          to: '/portal/phone/line-keys',
        },
        {
          name: t('Main Line'),
          show: access.acl.phoneDepartmentWrite && access.feature.departments,
          to: '/portal/phone/mainline',
        },
        {
          name: t('Media Library'),
          show: access.customization.phoneSystem,
          to: '/portal/phone/media-library',
        },
        {
          name: t('Override', { count: !numDepartments ? 1 : numDepartments }),
          show: access.customization.phoneSystem,
          to: '/portal/phone/overrides',
        },
        {
          name: t('Phone Trees'),
          show: access.customization.phoneSystem,
          to: '/portal/phone/phone-tree',
        },
        {
          name: t('Voicemail Boxes'),
          show: access.customization.phoneSystem,
          to: '/portal/phone/voicemail-boxes',
        },
      ],
    },
    {
      icon: <Icon name='payments' />,
      name: t('Payments'),
      show: access.customization.payments || access.feature.paymentsMerchant,
      navSubGroupItems: [
        {
          to: '/portal/payments/invoices',
          name: t('Invoices / payments'),
          show: access.customization.payments,
        },
        {
          name: t('Refunds'),
          show: access.customization.payments,
          to: '/portal/payments/refunds',
        },
        {
          name: t('Payouts'),
          show: access.customization.payments,
          to: '/portal/payments/payouts',
        },
        {
          name: t('Buy Now, Pay-over-time'),
          show: access.feature.paymentsMerchant,
          to: '/portal/payments/buy-now-pay-later',
        },
        {
          name: t('Online Bill Pay'),
          show: access.customization.payments && access.feature.onlineBillPay,
          to: '/portal/payments/online-bill-pay',
        },
        {
          name: t('Payment Plans'),
          show: access.customization.payments && access.feature.paymentPlans,
          to: '/portal/payments/payment-plans',
        },
        {
          name: t('Settings'),
          show: access.customization.payments,
          to: '/portal/payments/settings',
        },
      ],
    },
    {
      name: t('Email Marketing'),
      show: !isParentLocation && access.customization.emailMarketing,
      icon: <Icon name='updates' />,
      navSubGroupItems: [
        {
          name: t('Campaigns'),
          show: true,
          to: '/portal/marketing/campaigns',
        },
        {
          name: t('Templates'),
          show: true,
          to: '/portal/marketing/templates',
        },
        {
          name: t('Filters'),
          show: true,
          to: '/portal/marketing/filters',
        },
        {
          name: t('Gallery'),
          show: true,
          to: '/portal/marketing/gallery',
        },
        {
          name: t('Settings'),
          show: true,
          to: '/portal/marketing/settings',
        },
      ],
    },
    {
      icon: <Icon name='forms' />,
      name: t('Forms'),
      show: access.combination.hasPermissionToUploadForms && access.customization.digitalForms,
      navSubGroupItems: [
        {
          name: t('Dashboard'),
          show: true,
          to: `/portal/forms/dashboard`,
        },
        {
          name: t('Upload Forms'),
          show: true,
          to: `/forms/digitization`,
        },
      ],
    },
    {
      icon: <Icon name='forms' />,
      name: t('Forms'),
      show: !access.combination.hasPermissionToUploadForms,
      to: `/portal/forms/dashboard`,
    },
    {
      icon: <Icon name='print' />,
      name: t('Fax'),
      show: !isParentLocation,
      to: '/portal/fax',
    },
    {
      name: t('Subscriptions'),
      show: true,
      icon: <Icon name='crown' />,
      to: '/portal/subscriptions',
      isNew: true,
    },
    {
      name: t('Partners'),
      show: isPartnersPageEnabled,
      icon: <Icon name='building' />,
      to: '/portal/partners',
      isNew: true,
    },
    {
      name: t('Writeback Logs'),
      show: access.combination.hasWritebackLogsPermission,
      icon: <Icon name='list' />,
      to: '/portal/writeback-logs',
    },
    {
      icon: <Icon name='analytics' />,
      name: t('Analytics'),
      // The visibility check is as per the available cards in the analytics dashboard (overview) page
      show: true,
      navSubGroupItems: [
        {
          name: t('Overview'),
          show: true,
          to: '/analytics/dashboard',
        },
        {
          isNew: true,
          name: t('Practice'),
          show: true,
          to: '/analytics/practice',
        },
        {
          isNew: true,
          name: t('Call Intelligence'),
          show: access.combination.hasCallIntelAccess,
          to: '/analytics/call-intelligence',
        },
        {
          name: t('Auto Recall'),
          show: true,
          to: '/analytics/auto-recall',
        },
        {
          name: t('Phone'),
          show: true,
          to: '/analytics/phone',
        },
        {
          name: t('Messaging'),
          show: true,
          to: '/analytics/messaging',
        },
        {
          name: t('Appointment'),
          show: true,
          to: '/analytics/appointment',
        },
      ],
    },
    {
      icon: <Icon name='training' />,
      isExternalLink: true,
      name: t('Weave help'),
      show: true,
      to: 'https://weavehelp.com',
    },
    {
      icon: <Icon name='message' />,
      isExternalLink: true,
      isNew: true,
      name: t('Desktop App'),
      show: access.feature.hasInstallerLinks,
      to: getElectronShellDownloadUrl(),
    },
  ];

  return items;
};

export const flattenNavItems = (items: ReturnType<typeof usePortalNavItems>) => {
  return items.reduce(
    (acc, item) => [...acc, item, ...(isNavGroup(item) ? item.navSubGroupItems : [])],
    [] as typeof items
  );
};

export const isNavItem = (item: NavItem | NavGroup): item is NavItem => {
  return 'to' in item;
};

export const isNavGroup = (item: NavItem | NavGroup): item is NavGroup => {
  return 'navSubGroupItems' in item;
};
