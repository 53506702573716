import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';
import { useLocationName } from './hooks';

export const DropdownDisplay = ({
  displayedLocations,
  labelType,
  selectedLocationIds,
}: {
  displayedLocations: string[];
  labelType?: 'count' | 'name';
  selectedLocationIds: string[];
}) => {
  const { getLocationName } = useLocationName();
  const { t } = useTranslation('messages');
  const MIN_CHAR_LENGTH = 26;
  const MAX_CHAR_LENGTH = 40;

  const sortLocations = (endFormat: 'id' | 'name') =>
    selectedLocationIds
      .map((id) => {
        const locationId = displayedLocations.find((locationId) => locationId === id);
        const name = locationId ? getLocationName(locationId) : '';
        return { id, name };
      })
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((location) => location[endFormat]);

  const getDropdownLabel = () => {
    if (!selectedLocationIds.length) return t('Select Locations');
    const sortedLocationNames = sortLocations('name');

    // If there is only one location, return the truncated location name if needed
    if (sortedLocationNames.length === 1) {
      const locationName = sortedLocationNames[0].substring(0, MIN_CHAR_LENGTH);
      return sortedLocationNames[0].length > MIN_CHAR_LENGTH
        ? t('{{locationName}}...', { locationName })
        : locationName;
    }

    let isLength = false;
    let additional = 0;

    const baseStr = sortedLocationNames.reduce((acc, locationName, index, arr) => {
      const newStr = acc + locationName;
      const isLast = arr.length - 1 === index;
      if (newStr.length < MIN_CHAR_LENGTH && locationName && !isLength) {
        return newStr + (!isLast ? ', ' : '');
      } else if (newStr.length > MIN_CHAR_LENGTH && newStr.length < MAX_CHAR_LENGTH && locationName && !isLength) {
        // This is to add a truncated location name before the additional count
        return newStr.substring(0, MIN_CHAR_LENGTH) + (!isLast ? '..., ' : '');
      }
      isLength = true;
      additional += 1;
      return acc;
    }, '');
    const additionalStr = additional > 0 ? ` ${additional} more...` : '';

    return t('{{baseStr}} {{additionalStr}}', { baseStr, additionalStr });
  };

  return (
    <Text
      as='span'
      color={selectedLocationIds ? 'default' : 'error'}
      css={{ display: 'flex', alignItems: 'center', columnGap: theme.spacing(1) }}
    >
      {labelType === 'count' && <Icon name='locations-small' />}
      {labelType === 'count' ? t('{{count}} Location', { count: selectedLocationIds.length }) : getDropdownLabel()}
    </Text>
  );
};
