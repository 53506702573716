import { css } from '@emotion/react';
import { DashboardWidget } from '@frontend/grid-dashboard';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { useGridDashboard } from './store';
import { useAccessibleWidgetConfig } from './use-accesible-widgets-config';

const trackingId = 'widgetized-dashboard-widget-add-widget';

export const AddWidget = () => {
  const { isEditMode, setIsEditMode, openWidgetLibrary } = useGridDashboard([
    'isEditMode',
    'setIsEditMode',
    'openWidgetLibrary',
  ]);
  const { accessibleWidgets: widgetConfigs } = useAccessibleWidgetConfig();

  return (
    <>
      <DashboardWidget
        isRemovable={widgetConfigs.length > 1}
        onClick={() => {
          setIsEditMode(true);
          openWidgetLibrary();
        }}
        data-trackingid={trackingId}
        css={[
          css`
            border: 1px dashed ${theme.colors.primary50};
            background: ${theme.colors.primary5};
            transition: 0.3s ease;

            @keyframes fade-in {
              from {
                opacity: 0;
                scale: 0.8;
              }
              to {
                opacity: 1;
                scale: 1;
              }
            }

            animation: fade-in 0.3s ease-in-out;
          `,
          !isEditMode &&
            css`
              :hover {
                background: ${theme.colors.primary10};
                cursor: pointer;
              }
            `,
        ]}
      >
        <DashboardWidget.Content
          css={css`
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 100%;
          `}
        >
          <div
            css={css`
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: ${theme.borderRadius.full};
              border: 1px dashed ${theme.colors.primary50};
              color: ${theme.colors.primary50};
              height: 46px;
              width: 46px;
            `}
          >
            <Icon name='plus' />
          </div>
        </DashboardWidget.Content>
      </DashboardWidget>
    </>
  );
};

export const AddWidgetPlaceholder = () => {
  const { openWidgetLibrary, setIsEditMode } = useGridDashboard(['openWidgetLibrary', 'setIsEditMode']);

  return (
    <>
      <div
        data-trackingid={trackingId}
        onClick={() => {
          setIsEditMode(true);
          openWidgetLibrary();
        }}
        css={[
          css`
            border: 1px dashed ${theme.colors.primary50};
            background: ${theme.colors.primary5};
            transition: 0.3s ease;

            @keyframes fade-in {
              from {
                opacity: 0;
                scale: 0.8;
              }
              to {
                opacity: 1;
                scale: 1;
              }
            }

            animation: fade-in 0.3s ease-in-out;
          `,
          css`
            :hover {
              background: ${theme.colors.primary10};
              cursor: pointer;
            }
            height: 250px;
            width: 319px;
            border-radius: ${theme.borderRadius.medium};
          `,
        ]}
      >
        <div
          css={css`
            display: flex;
            justify-content: center;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 100%;
          `}
        >
          <div
            css={css`
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: ${theme.borderRadius.full};
              border: 1px dashed ${theme.colors.primary50};
              color: ${theme.colors.primary50};
              height: 46px;
              width: 46px;
            `}
          >
            <Icon name='plus' />
          </div>
        </div>
      </div>
    </>
  );
};
