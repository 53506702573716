import { createProvider } from '@frontend/store';
import { FormDigitizationStore, FormDigitizationStoreState, LoaderData } from './types';

const INITIAL_STATE: FormDigitizationStoreState = {
  locationId: '',
  showDefaultUploader: false,
  loader: {
    show: false,
  },
  isCheckingIfFormsAreSubmittedForDigitization: true,
  areFormsSubmittedForDigitization: false,
  isLoadingCanUploadForms: true,
  canUploadForms: false,
  isLoadingUploadedForms: true,
  uploadedForms: [],

  formSubmitToggleStatus: false,
  isCheckingFormSubmitToggleStatus: true,
  isUpdatingFormSubmitToggleStatus: false,

  checkForDigitizationFee: false,
  isCheckingForDigitizationFee: true,
  isUpdatingCheckForDigitizationFee: false,
};

/**
 * This store (context) is used to hold the state for location.
 */
export const { Provider: FormDigitizationProvider, useStore: useFormDigitizationStore } =
  createProvider<FormDigitizationStore>()((set, get) => ({
    ...INITIAL_STATE,
    setLocationId: (value: string) => set({ locationId: value }),
    setShowDefaultUploader: (value: boolean) => set({ showDefaultUploader: value }),
    setLoader: (value: LoaderData) => set({ loader: value }),

    setIsCheckingIfFormsAreSubmittedForDigitization: (value: boolean) =>
      set({ isCheckingIfFormsAreSubmittedForDigitization: value }),
    setAreFormsSubmittedForDigitization: (value: boolean) => set({ areFormsSubmittedForDigitization: value }),

    setIsLoadingCanUploadForms: (value: boolean) => set({ isLoadingCanUploadForms: value }),
    setCanUploadForms: (value: boolean) => set({ canUploadForms: value }),

    setIsLoadingUploadedForms: (value: boolean) => set({ isLoadingUploadedForms: value }),
    setUploadedForms: (value: any) => set({ uploadedForms: value }),
    removeUploadedForms: (formIds: string[]) => {
      set((state) => {
        const updatedUploadedForms = state.uploadedForms.filter((form) => !formIds.includes(form.id));
        return { uploadedForms: updatedUploadedForms };
      });
    },

    setFormSubmitToggleStatus: (value: boolean) => set({ formSubmitToggleStatus: value }),
    setIsCheckingFormSubmitToggleStatus: (value: boolean) => set({ isCheckingFormSubmitToggleStatus: value }),
    setIsUpdatingFormSubmitToggleStatus: (value: boolean) => set({ isUpdatingFormSubmitToggleStatus: value }),

    setCheckForDigitizationFee: (value: boolean) => set({ checkForDigitizationFee: value }),
    setIsCheckingForDigitizationFee: (value: boolean) => set({ isCheckingForDigitizationFee: value }),
    setIsUpdatingCheckForDigitizationFee: (value: boolean) => set({ isUpdatingCheckForDigitizationFee: value }),

    getDigitizationStoreState: () => {
      const { locationId, areFormsSubmittedForDigitization, uploadedForms } = get();
      return { locationId, areFormsSubmittedForDigitization, uploadedForms };
    },

    resetDigitizationStore: () =>
      set((state) => {
        return {
          ...INITIAL_STATE,
          isCheckingFormSubmitToggleStatus: state.isCheckingFormSubmitToggleStatus,
          isCheckingForDigitizationFee: state.isCheckingForDigitizationFee,
        };
      }),
  }));
