import { Icon, IconName } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { NakedButton, Text, usePopoverMenu } from '@frontend/design-system';

type CheckListProps = {
  getTriggerProps: ReturnType<typeof usePopoverMenu<HTMLElement>>['getTriggerProps'];
  selectedCount: number;
  menuName: string;
  iconName: IconName;
};

export const FilterMenuButton = ({ getTriggerProps, selectedCount, menuName, iconName }: CheckListProps) => {
  return (
    <NakedButton
      {...getTriggerProps()}
      css={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'left',
        margin: theme.spacing(1.5, 0),
        width: '100%',
      }}
    >
      <div
        css={{
          display: 'flex',
          alignItems: 'left',
          gap: theme.spacing(1),
        }}
      >
        <Icon name={iconName} />
        <Text>{menuName}</Text>
      </div>
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          gap: theme.spacing(1),
          height: theme.spacing(4),
        }}
      >
        {!!selectedCount && (
          <Text
            css={{
              color: theme.colors.primary70,
              padding: theme.spacing(0.25, 0.5),
              borderRadius: theme.borderRadius.small,
              backgroundColor: theme.colors.primary10,
            }}
            size='small'
          >
            {selectedCount}
          </Text>
        )}

        <Icon
          name='alt-caret-right-tiny'
          css={{
            color: theme.colors.neutral50,
          }}
        />
      </div>
    </NakedButton>
  );
};
