import {
  GetFeatureFlagStatusApiType,
  ListAppointmentTypesApiType,
  ListAppointmentTypesMultiLocationApiType,
  ListCalendarAvailabilitiesApiType,
  ListCalendarEventsParamsType,
  ListScheduleRequestsApiType,
} from './types';

export const AppointmentTypesQueryKeys = {
  getAppointmentTypeInfiniteList: (request: ListAppointmentTypesApiType['input']) => [
    'appointmentTypesInfiniteList',
    request,
  ],
  getAppointmentTypesInfiniteListForMultiLocation: (request: ListAppointmentTypesMultiLocationApiType['input']) => [
    'appointmentTypesInfiniteListMultiLocation',
    request,
  ],
  getAppointmentTypes: (locationId: string, page?: string, limit?: string, searchValue?: string) => [
    'appointmentTypes',
    locationId,
    page,
    limit,
    searchValue,
  ],
  getAppointmentTypesMultiLocation: (
    locationIds: string[],
    parentLocationId: string,
    page?: string,
    limit?: string,
    searchValue?: string
  ) => ['appointmentTypesMultiLocation', ...locationIds, parentLocationId, page, limit, searchValue],
};

export const ScheduleEntriesQueryKeys = {
  getScheduleEntriesList: (locationId: string, entityIds: string[]) => ['scheduleEntries', locationId, ...entityIds],
};

export const CalendarEventsQueryKeys = {
  listCalendarEvents: ({
    limit,
    page,
    status,
    startDateTime,
    endDateTime,
    type,
    sortOrder,
  }: ListCalendarEventsParamsType) => [
    'calendarEvents',
    limit,
    page,
    status,
    type,
    startDateTime,
    endDateTime,
    sortOrder,
  ],
  listCalendarEventsMulti: ({
    startDateTime,
    endDateTime,
    locationIds,
    limit,
    page,
    sortOrder,
    status,
    type,
  }: ListCalendarEventsParamsType & { locationIds: string[] }) => [
    'calendarEventsMulti',
    ...locationIds,
    limit,
    page,
    status,
    startDateTime,
    endDateTime,
    sortOrder,
    type,
  ],
  listCalendarEventsByOwner: ({
    startDateTime,
    endDateTime,
    ownerId,
    limit,
    page,
    sortOrder,
  }: ListCalendarEventsParamsType & { ownerId: string }) => [
    'calendarEventsByOwner',
    ownerId,
    limit,
    page,
    startDateTime,
    endDateTime,
    sortOrder,
  ],
  getCalendarEvent: (eventId: string) => ['calendarEvents', eventId],
};
export const OperatoriesQueryKeys = {
  getOperatoriesList: (locationId: string, page?: string, limit?: string, searchValue?: string) => [
    'operatories',
    locationId,
    page,
    limit,
    searchValue,
  ],
  getOperatoriesListForMultiLocation: (
    locationIds: string[],
    parentLocationId: string,
    page?: string,
    limit?: string,
    searchValue?: string
  ) => ['operatories', ...locationIds, parentLocationId, page, limit, searchValue],
  getOperatoryByOperatoryId: (operatoryId: string) => ['operatories', operatoryId],
};

export const scheduleBookingSiteQueryKeys = {
  base: ['booking-site'],
  featureFlagStatus: (req: GetFeatureFlagStatusApiType['input']) => [
    ...scheduleBookingSiteQueryKeys.base,
    'feature-flag-status',
    req,
  ],
  calendarAvailabilities: (req: ListCalendarAvailabilitiesApiType['input']) => [
    ...scheduleBookingSiteQueryKeys.base,
    'calendar-availabilities',
    req,
  ],
};

export const scheduleRequestQueryKeys = {
  base: ['schedule-request'],
  pendingRequestCount: (locationIds: string[]) => [
    ...scheduleRequestQueryKeys.base,
    'pending-request-count',
    ...locationIds,
  ],
  infiniteList: (req: Omit<ListScheduleRequestsApiType['input'], 'page'>) => [
    ...scheduleRequestQueryKeys.base,
    'infinite-list',
    req,
  ],
  list: (req: ListScheduleRequestsApiType['input']) => [...scheduleRequestQueryKeys.base, req],
  scheduleRequest: (scheduleRequestId: string) => [...scheduleRequestQueryKeys.base, scheduleRequestId],
};
