import { theme } from '@frontend/theme';
import { NakedButton } from '@frontend/design-system';

type FloatingButtonProps = {
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  children?: React.ReactNode;
  disabled?: boolean;
};

export const FloatingButton = ({
  children,
  onClick,
  onMouseEnter,
  onMouseLeave,
  disabled = false,
  ...rest
}: FloatingButtonProps) => {
  return (
    <NakedButton
      css={{
        padding: theme.spacing(1),
        background: theme.colors.white,
        boxShadow: theme.shadows.light,
        border: 'solid 1px transparent',
        borderRadius: theme.borderRadius.small,
        transition: 'all 250ms ease',
        '&:hover, &:focus': {
          outline: 'none',
          boxShadow: theme.shadows.floating,
          background: theme.colors.neutral5,
        },
      }}
      onClick={(e) => {
        e.stopPropagation();
        onClick?.();
      }}
      onMouseEnter={(e) => {
        e.stopPropagation();
        onMouseEnter?.();
      }}
      onMouseLeave={(e) => {
        e.stopPropagation();
        onMouseLeave?.();
      }}
      disabled={disabled}
      {...rest}
    >
      {children}
    </NakedButton>
  );
};
