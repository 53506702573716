import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { ComponentProps } from '@frontend/types';
import { theme } from '@frontend/theme';
import { ChecklistField } from '@frontend/design-system';

type DaysToSendProps = Omit<ComponentProps<typeof ChecklistField>, 'children'> & {
  trackingId?: string;
};

export const DaysToSend = ({ trackingId, ...rest }: DaysToSendProps) => {
  const { t } = useTranslation('messages');

  return (
    <ChecklistField
      orientation='horizontal'
      css={css`
        & > label {
          font-weight: bold;
        }
      `}
      {...rest}
    >
      <Day name='sunday' label={t('Sun')} trackingId={trackingId} />
      <Day name='monday' label={t('Mon')} trackingId={trackingId} />
      <Day name='tuesday' label={t('Tues')} trackingId={trackingId} />
      <Day name='wednesday' label={t('Wed')} trackingId={trackingId} />
      <Day name='thursday' label={t('Thurs')} trackingId={trackingId} />
      <Day name='friday' label={t('Fri')} trackingId={trackingId} />
      <Day name='saturday' label={t('Sat')} trackingId={trackingId} />
    </ChecklistField>
  );
};

type DayProps = {
  name: string;
  label: string;
  trackingId?: string;
};

const Day = ({ name, label, trackingId }: DayProps) => (
  <ChecklistField.Option
    name={name}
    trackingId={trackingId ? `${trackingId}-${name}-checkbox` : undefined}
    css={css`
      margin: ${theme.spacing(0, 1, 0, 0)};
    `}
  >
    {label}
  </ChecklistField.Option>
);
