import { useState } from 'react';
import { css } from '@emotion/react';
import { ViewStatus } from '@weave/schema-gen-ts/dist/schemas/phone-exp/voicemail/voicemail.pb';
import { Tag } from '@weave/schema-gen-ts/dist/schemas/tag/shared/v1/models.pb';
import dayjs from 'dayjs';
import { UseInfiniteQueryResult } from 'react-query';
import { TagsUtils } from '@frontend/api-tag';
import { VoiceMailboxApi, VoiceMailboxTypes } from '@frontend/api-voicemail-boxes';
import { VoicemailTypes as VmTypes, VoicemailTypes } from '@frontend/api-voicemails';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { formatPhoneNumber } from '@frontend/phone-numbers';
import { Photos } from '@frontend/photos';
import { useScopedQuery } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { Avatar, Chip, SkeletonLoader, styles, Text, useAlert } from '@frontend/design-system';
import { CachedAudioScrubber } from '../../../components/all-calls/cached-audio-scrubber';
import { VoicemailInfiniteQueryData, VoicemailTableDataRow } from '../../../components/all-calls/types';
import { TranscriptionTextContent } from '../../../components/transcription/transcription-panel-sections';
import { VoicemailTagSelector } from '../../../components/voicemails/voicemail-tag-selector';
import { AllCallsTable, usePhonePageSettingShallowStore } from '../../../hooks/use-phone-config-state-store';
import { useVoicemailTagMutation } from '../../../hooks/use-voicemail-tag-mutations';
import { queryKeys } from '../../../query-keys';
import { SplitViewActions } from './actions';

export const VoicemailDetails = ({
  index,
  data,
  queryKey,
  voicemailDataQuery,
  onDeleteVoicemail,
  updateReadStatus,
}: {
  index: number;
  data: VoicemailTableDataRow;
  queryKey: string[];
  voicemailDataQuery: UseInfiniteQueryResult<VoicemailInfiniteQueryData, unknown>;
  onDeleteVoicemail: (payload: VmTypes.DeletePayload) => void;
  updateReadStatus: (payload: VoicemailTypes.StatusPayload) => Promise<VoicemailTypes.SetVoicemailViewStatusResponse>;
}) => {
  const createdAt = dayjs(data?.createdAt).format('MMM DD YYYY, hh:mm A');
  const { t } = useTranslation('calls', { keyPrefix: 'unread-voicemail-widget' });
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const { data: transcription } = useScopedQuery({
    queryKey: queryKeys.voicemailTranscript(data?.channelId ?? ''),
    queryFn: async () => {
      const response = await VoiceMailboxApi.getTranscription(data?.channelId ?? '');
      return response;
    },
    enabled: !!data,
  });

  return (
    <div css={customStyles.wrapper}>
      <section
        css={{
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(1),
          borderBottom: `1px solid ${theme.colors.neutral20}`,
          padding: theme.spacing(2),
        }}
      >
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <ProfileBody data={data} defaultName={t('Unknown')} markedUnread={!data.readAt} />
          {!!data && (
            <div>
              <SplitViewActions
                data={data}
                index={index}
                onDelete={onDeleteVoicemail}
                onForwardSuccess={() => {
                  voicemailDataQuery.refetch();
                }}
                updateReadStatus={updateReadStatus}
                setIsTagEditing={setIsEditing}
                trackingId={'unread-voicemails-widget-split-view-action'}
              />
            </div>
          )}
        </div>
        <div
          css={{
            display: 'flex',
            gap: theme.spacing(1),
            flexWrap: 'wrap',
          }}
        >
          {data.tags.length > 0 && <TagList tags={data.tags} voicemailId={data.voicemailId} queryKey={queryKey} />}
          {isEditing && (
            <VoicemailTagSelector
              voicemailId={data.voicemailId}
              queryKey={queryKey}
              existingTagIds={data.tags.map((tag) => tag.id)}
              onClose={() => {
                setIsEditing(false);
              }}
              onSelect={() => {
                setIsEditing(false);
              }}
            />
          )}
        </div>
      </section>
      <section
        css={{
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(2),
          padding: theme.spacing(2),
          overflow: 'auto',
        }}
      >
        <Text size='medium'>{createdAt}</Text>
        {!!data.voiceMailBoxName && (
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
              gap: '2px',
            }}
          >
            <Icon name='voicemail' size={16} color='light' />
            <Text size='medium'>{data.voiceMailBoxName}</Text>
          </div>
        )}
        <TranscriptionBody
          index={index}
          message={data}
          transcription={transcription}
          mediaFilePath={data?.mediaFilePath ?? ''}
          updateReadStatus={updateReadStatus}
        />
      </section>
    </div>
  );
};

const ProfileBody = ({
  data,
  defaultName,
  markedUnread = false,
}: {
  data: VoicemailTableDataRow;
  defaultName: string;
  markedUnread?: boolean;
}) => {
  const personID = data.contactId;
  const firstName = data.firstName;
  const lastName = data.lastName;
  const profileName = data.contactName;
  return (
    <div
      css={{
        display: 'grid',
        gridTemplateAreas: '"img name" "img number"',
        gridTemplateColumns: 'auto 1fr',
        columnGap: theme.spacing(1),
        alignContent: 'center',
        alignItems: 'center',
      }}
    >
      <Photos.ContactProfilePhoto
        css={{
          gridArea: 'img',
        }}
        locationId={data.locationIds[0]}
        personId={personID || ''}
        name={profileName}
        disableClick={!personID}
      >
        {!!markedUnread && <Avatar.Dot variant='primary' />}
      </Photos.ContactProfilePhoto>
      {(firstName || lastName) && (
        <div>
          {!!firstName && (
            <Text as='span' weight='bold'>
              {firstName}
            </Text>
          )}{' '}
          {!!lastName && <Text as='span'>{lastName}</Text>}
        </div>
      )}
      {!firstName && !lastName && <Text>{profileName || defaultName}</Text>}

      <Text css={{ gridArea: 'number' }} size='medium' color='light'>
        {formatPhoneNumber(data?.contactNumber)}
      </Text>
    </div>
  );
};

const TagList = ({ tags, voicemailId, queryKey }: { tags: Tag[]; voicemailId: string; queryKey: string[] }) => {
  const alerts = useAlert();
  const { t } = useTranslation('calls');
  const { removeTag } = useVoicemailTagMutation({
    queryKey,
    voicemailId,
    onSuccess: ({ op }) => {
      if (op === 'remove') alerts.success(t('Successfully removed tag.'));
    },
    onFailure: ({ op }) => {
      if (op === 'remove') alerts.error(t('Failed to remove tag.'));
    },
  });
  return (
    <>
      {tags.map((tag) => (
        <Chip.Tag
          key={tag.id}
          isRemovable
          onRemoveClick={() => {
            removeTag(tag);
          }}
          css={{ width: 'auto' }}
          color={TagsUtils.convertStringToTagColor(tag.color)}
        >
          {tag.name}
        </Chip.Tag>
      ))}
    </>
  );
};

const TranscriptionBody = ({
  index,
  message,
  transcription,
  mediaFilePath,
  updateReadStatus,
}: {
  index: number;
  message: VoicemailTableDataRow;
  mediaFilePath: string;
  transcription?: VoiceMailboxTypes.GetVoicemailTranscriptionResponse;
  updateReadStatus: (payload: VmTypes.StatusPayload) => Promise<VmTypes.SetVoicemailViewStatusResponse>;
}) => {
  const { t } = useTranslation('calls', { keyPrefix: 'unread-voicemail-widget' });

  const { config, setPageNumber } = usePhonePageSettingShallowStore('config', 'setPageNumber');

  if (!message) {
    return (
      <div css={styles.flexCenter}>
        <Text>{t('No data available')}</Text>
      </div>
    );
  }

  if (!transcription) {
    return <SkeletonLoader animation='shimmer' width={'100%'} height={20} count={2} distance={10} />;
  }
  const pageSize = config[AllCallsTable.Unread_Voicemails].pageSize;
  const setPageConfig = (index: number) => {
    const pageNumber = Math.floor(index / pageSize) + 1;
    setPageNumber(AllCallsTable.Unread_Voicemails, pageNumber);
  };

  return (
    <div>
      <div
        css={{
          width: '200px',
          marginBottom: theme.spacing(2),
        }}
      >
        <CachedAudioScrubber
          onPlay={() => {
            setPageConfig(index);
            updateReadStatus({
              index: index,
              status: ViewStatus.VIEW_STATUS_READ,
              voicemailId: message?.forwardedMessageId || message.channelId,
            });
          }}
          filePath={mediaFilePath}
          mediaId={message.mediaId ?? ''}
        />
      </div>
      <Text size='small' color='subdued'>
        {t('Transcript')}
      </Text>
      <TranscriptionTextContent
        transcription={transcription}
        textSize='medium'
        css={{
          padding: 0,
          backgroundColor: 'transparent',
        }}
      />
    </div>
  );
};

const customStyles = {
  wrapper: css`
    display: flex;
    height: 290px;
    flex-direction: column;
    border: 1px solid ${theme.colors.neutral20};
    border-radius: ${theme.borderRadius.medium};
  `,
};
