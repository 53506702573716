import { useTranslation } from '@frontend/i18n';
import { BulkEmailPrefixes } from '@frontend/tracking-prefixes';
import { useContentComposer } from '../content-composer-provider';
import { TextSection } from '../sections';
import { ToolboxButton } from '../sidebar';

export const TextTool = () => {
  const { t } = useTranslation('content-composer');
  const { insertSection } = useContentComposer();

  return (
    <ToolboxButton
      icon='template'
      title={t('Text')}
      subtitle={t('Insert header or body text.')}
      onClick={() => insertSection(<TextSection />)}
      trackingId={`${BulkEmailPrefixes.Composer}-text-tool-btn`}
    >
      <TextSection />
    </ToolboxButton>
  );
};
