import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';

interface Props {
  message: string;
}
export const ErrorComponent = ({ message }: Props) => {
  return (
    <div css={{ display: 'flex' }}>
      <div css={widthForField}> </div>
      <Text css={{ marginTop: theme.spacing(-2.5), paddingBottom: theme.spacing(1.5) }} color='error' size='small'>
        {message}
      </Text>
    </div>
  );
};

const widthForField = css`
  width: 300px;
`;
