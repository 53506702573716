import { ListMappingsIO } from '../types';
import { useSchedulerV3Queries } from './use-scheduler-v3-queries';
import { UseSchedulerV3QueryEndpointArgs, useSchedulerV3Query } from './use-scheduler-v3-query';

/**
 * A hook that returns a query for the `ListMappings` endpoint.
 * @param request The request object to pass to the query.
 * @param options (optional) The options to pass to `useQuery`.
 * @param httpOptions (optional) The http options to pass to the schema function.
 * @param fallbackDataOnError (optional) The fallback data to use when an error occurs. This will not be persisted to the cache.
 * @param requestKeysToOmitFromQueryKey (optional) - An array of keys to omit from the query key. This is useful for cases where the request object contains keys that cause issues
 * when included in the query key, such as the current timestamp.
 */
export const useListMappingsQuery = <E = unknown, D = ListMappingsIO['output']>(
  args: UseSchedulerV3QueryEndpointArgs<'ListMappings', E, D>
) =>
  useSchedulerV3Query({
    endpointName: 'ListMappings',
    ...args,
  });

/**
 * A hook that returns an array of queries for the `ListMappings` endpoint.
 * It takes an array of objects with the following properties:
 * @param request The request object to pass to the query.
 * @param options (optional) The options to pass to `useQuery`.
 * @param httpOptions (optional) The http options to pass to the schema function.
 * @param fallbackDataOnError (optional) The fallback data to use when an error occurs. This will not be persisted to the cache.
 * @param requestKeysToOmitFromQueryKey (optional) - An array of keys to omit from the query key. This is useful for cases where the request object contains keys that cause issues
 * when included in the query key, such as the current timestamp.
 */
export const useListMappingsQueries = <E = unknown, D = ListMappingsIO['output']>(
  argsArr: UseSchedulerV3QueryEndpointArgs<'ListMappings', E, D>[]
) =>
  useSchedulerV3Queries(
    argsArr.map((args) => ({
      endpointName: 'ListMappings',
      ...args,
    }))
  );
