import { useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { NumberType } from '@weave/schema-gen-ts/dist/shared/porting/v1/enums.pb';
import { CustomizationFlagQueries, CustomizationFlagTypes } from '@frontend/api-customization-flags';
import { i18next, useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { formatPhoneNumber } from '@frontend/phone-numbers';
import { useMutation, useQuery } from '@frontend/react-query-helpers';
import { SchemaPortingDataService } from '@frontend/schema';
import { sentry } from '@frontend/tracking';
import { theme } from '@frontend/theme';
import {
  BannerNotification,
  DatePickerField,
  DropdownField,
  Heading,
  NakedButton,
  PhoneField,
  Stepper,
  Text,
  TextButton,
  useControlledField,
  useFormField,
  useModalControl,
  useAlert,
} from '@frontend/design-system';
import { trackingIds } from '../../constants';
import { getPortDateFieldProps } from '../../helper';
import { useCreateOrUpdatePortOrder } from '../../hooks';
import { usePortOrderCreationStore } from '../../providers';
import { smallBannerStyle } from '../styles';
import { NumberNotPortableInfoModal } from './number-not-portable-info-modal';
import { StepLoader } from './step-loader';
import { StepperFooter } from './stepper-footer';

const NUMBER_TYPE_OPTIONS: { label: string; value: NumberType }[] = [
  {
    value: NumberType.NUMBER_TYPE_PHONE,
    label: i18next.t('Voice/SMS', { ns: 'porting' }),
  },
  {
    value: NumberType.NUMBER_TYPE_FAX,
    label: i18next.t('Fax', { ns: 'porting' }),
  },
];

export const AddNumbersStep = () => {
  const { t } = useTranslation('porting');
  const alert = useAlert();
  const numberNotPortableInfoModalControl = useModalControl();
  const { isSaving, storePortOrder, updateStorePortOrder } = usePortOrderCreationStore([
    'isSaving',
    'storePortOrder',
    'updateStorePortOrder',
  ]);
  const { data: customizationFlags, isLoading: isLoadingCustomizationFlags } = useQuery({
    queryKey: [storePortOrder.locationId, 'customization-flags'],
    queryFn: () => CustomizationFlagQueries.getCustomizationFlagsByLocationId(storePortOrder.locationId ?? ''),
    onError: () => sentry.warn({ error: 'Failed to fetch customization flags', topic: 'onboarding' }),
    enabled: !!storePortOrder.locationId,
  });
  const isSoftwareOnly = useMemo(
    () =>
      customizationFlags?.find(
        (flag) =>
          flag.featureEnum === Feature.PHONE_HOME_ICON &&
          flag.state === CustomizationFlagTypes.CustomizationFlagState.HIDE
      ),
    [customizationFlags]
  );

  const portDateFieldOptions = useMemo(() => getPortDateFieldProps(), []);
  const portDateField = useFormField({
    ...portDateFieldOptions,
    type: 'datePicker',
    value: storePortOrder.portDate ?? '',
  });
  const [isInfoCorrect, setIsInfoCorrect] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const phoneNumberField = useControlledField({
    type: 'phone',
    value: phoneNumber,
    onChange: (value) => setPhoneNumber(value),
  });
  const numberTypeDropdownField = useFormField(
    {
      type: 'dropdown',
      value: storePortOrder.isSMSHosting
        ? NumberType.NUMBER_TYPE_SMS
        : isSoftwareOnly
        ? NumberType.NUMBER_TYPE_FAX
        : '',
    },
    [storePortOrder.isSMSHosting, isSoftwareOnly]
  );

  useEffect(() => {
    if (storePortOrder.isSMSHosting) {
      updateStorePortOrder({ portDate: '' });
    } else if (portDateField.value && !portDateField.error) {
      updateStorePortOrder({ portDate: portDateField.value });
    }
  }, [storePortOrder.isSMSHosting, portDateField.value, portDateField.error]);

  const { createOrUpdatePortOrder } = useCreateOrUpdatePortOrder();
  const { mutate: checkPortability, isLoading: portabilityCheckIsLoading } = useMutation({
    mutationFn: (phoneNumber: string) =>
      storePortOrder.isSMSHosting
        ? SchemaPortingDataService.SMSHostabilityCheck({ phoneNumber })
        : SchemaPortingDataService.ValidatePortability({ phoneNumber }),
    onSuccess: (data) => {
      const phoneNumberIsPortable = data.validationPassed;

      if (!phoneNumberIsPortable) {
        numberNotPortableInfoModalControl.openModal();
        return;
      }

      // sort phone numbers to display voice/sms first and fax later
      const newNumbers = [
        ...(storePortOrder.numbers || []),
        {
          number: phoneNumber,
          numberType: numberTypeDropdownField.value as NumberType,
        },
      ].sort((a, b) => (a.numberType > b.numberType ? -1 : 1));

      updateStorePortOrder({ numbers: newNumbers });
      setPhoneNumber('');
      setIsInfoCorrect(false);
    },
    onError: () => {
      alert.error(
        t('Failed to validate number for {{portabilityText}}', {
          portabilityText: storePortOrder.isSMSHosting ? t('hostability') : t('portability'),
        })
      );
      sentry.warn({
        error: 'Failed to validate number for portability',
        topic: 'onboarding',
        addContext: {
          name: 'details',
          context: {
            phoneNumber,
          },
        },
      });
    },
  });

  const handleAddToListButtonClick = async () => {
    if (storePortOrder.numbers?.find((numberInfo) => numberInfo.number === phoneNumber)) {
      alert.error(t('Number already added in the list.'));
      return;
    }

    checkPortability(phoneNumber);
  };

  const handleDeletePhoneNumber = (phoneNumber: string) => {
    if (storePortOrder.numbers?.length === 1) {
      alert.error(t('You must have at least one number to port'));
      return;
    }

    updateStorePortOrder({
      numbers: storePortOrder.numbers?.filter((number) => number.number !== phoneNumber) ?? [],
    });
    setIsInfoCorrect(false);
  };

  const handleNextButtonClick = async () => {
    await createOrUpdatePortOrder();
  };

  const isFormValid =
    (storePortOrder.isSMSHosting || Boolean(storePortOrder.portDate)) && Boolean(storePortOrder.numbers?.length);
  const numberTypeText = storePortOrder.isSMSHosting ? t('Phone') : isSoftwareOnly ? t('Fax') : t('Phone or Fax');
  const portText = storePortOrder.isSMSHosting ? t('SMS host') : t('port');
  const shouldShowNumberTypeDropdown = !storePortOrder.isSMSHosting && !isSoftwareOnly;
  const isAddToListButtonDisabled =
    !numberTypeDropdownField.value ||
    Boolean(phoneNumberField.error) ||
    !phoneNumberField.value ||
    portabilityCheckIsLoading;

  return (
    <>
      <Stepper.Title>{t('Add Numbers')}</Stepper.Title>
      <Stepper.Content>
        <StepLoader show={isSaving || isLoadingCustomizationFlags} />

        {storePortOrder.isSMSHosting ? (
          <BannerNotification
            status='info'
            css={[smallBannerStyle, css({ marginBottom: theme.spacing(2) })]}
            message={t(
              'Reminder: By submitting an SMS Hosting request, the Voice portion of your number(s) will stay with your current provider and only the SMS portion will port to Weave.'
            )}
          />
        ) : (
          <>
            <Heading level={3} css={{ fontSize: theme.fontSize(16), marginBottom: theme.spacing(1) }}>
              {t('Select Requested Port Date')}
            </Heading>
            <DatePickerField {...portDateField} name='port-date' label={t('Date')} css={{ maxWidth: 'none' }} />
            <Text size='small' color='light' css={{ margin: theme.spacing(0.5, 0, 3, 0) }}>
              {t(
                'Select from these available dates. This date will be an estimate and your number(s) may get an accepted date for a different day.'
              )}
            </Text>
          </>
        )}
        <Heading level={3} css={{ fontSize: theme.fontSize(16), marginBottom: theme.spacing(1.5) }}>
          {t('Add any {{numberType}} numbers to {{portText}} for this account', {
            numberType: numberTypeText,
            portText,
          })}
        </Heading>

        <div css={phoneFieldContainerStyle}>
          {shouldShowNumberTypeDropdown && (
            <DropdownField name='numberType' label={t('Number Type')} {...numberTypeDropdownField} css={{ width: 160 }}>
              {NUMBER_TYPE_OPTIONS.map(({ label, value }) => (
                <DropdownField.Option key={value} value={value}>
                  {label}
                </DropdownField.Option>
              ))}
            </DropdownField>
          )}
          <PhoneField
            {...phoneNumberField}
            name='number'
            label={t('{{type}} number', { type: numberTypeText })}
            helperText={t('Enter a complete 10-digit number')}
          />
          <TextButton
            disabled={isAddToListButtonDisabled}
            css={addToListButtonStyle}
            onClick={handleAddToListButtonClick}
            trackingId={`${trackingIds.submitPortRequest}_add-numbers_add-to-list_btn`}
          >
            {t('Add to list')}
          </TextButton>
        </div>
        {Boolean(storePortOrder.numbers?.length) && (
          <>
            <Text size='small' color='light'>
              {t('Numbers to {{portText}}', { portText })}
            </Text>
            <hr css={{ margin: theme.spacing(1, 0), borderColor: theme.colors.neutral5 }} />
            <div css={numberListContainerStyle}>
              {storePortOrder.numbers?.map((numberInfo) => (
                <div key={numberInfo.number}>
                  <div css={{ display: 'flex', alignItems: 'center', gap: theme.spacing(1) }}>
                    {!storePortOrder.isSMSHosting && (
                      <>
                        <Icon
                          name={numberInfo.numberType === NumberType.NUMBER_TYPE_FAX ? 'fax' : 'phone'}
                          color='light'
                        />
                        <Text css={{ minWidth: 80 }} color='light' size='medium'>
                          {numberInfo.numberType === NumberType.NUMBER_TYPE_FAX ? t('Fax') : t('Voice/SMS')}
                        </Text>
                      </>
                    )}
                    {formatPhoneNumber(numberInfo.number)}
                  </div>
                  <NakedButton
                    onClick={() => handleDeletePhoneNumber(numberInfo.number)}
                    trackingId={`${trackingIds.submitPortRequest}_add-numbers_remove_x-icon`}
                  >
                    <Icon name='x-small' color='light' />
                  </NakedButton>
                </div>
              ))}
            </div>
          </>
        )}
      </Stepper.Content>
      <StepperFooter
        isFormValid={!isSaving && isFormValid}
        isInfoCorrect={isInfoCorrect}
        setIsInfoCorrect={setIsInfoCorrect}
        onNextClick={handleNextButtonClick}
      />
      <NumberNotPortableInfoModal
        {...numberNotPortableInfoModalControl.modalProps}
        numberType={numberTypeDropdownField.value as NumberType}
      />
    </>
  );
};

const phoneFieldContainerStyle = css({
  width: '100%',
  display: 'flex',
  gap: theme.spacing(2),
  marginBottom: theme.spacing(3),
  'div:last-of-type': {
    flexGrow: 1,
  },
});

const addToListButtonStyle = css({
  padding: theme.spacing(1),
  fontSize: theme.fontSize(16),
  fontWeight: theme.font.weight.bold,
  color: theme.colors.primary50,
  height: 40,
});

const numberListContainerStyle = css({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  marginBottom: theme.spacing(3),
  '> div': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});
