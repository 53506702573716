import { useSchedulerV3QueryUpdaters } from '../query-updaters';
import { CreateMappingsIO } from '../types';
import { useCreateMappingsMutation } from './use-create-mappings';
import { useDeleteMappingsMutation } from './use-delete-mappings';

type CreateOrUpdateMappingsRequest = CreateMappingsIO['input'] & {
  mappingIdsForDelete?: string[];
};

export const useCreateAndDeleteMappings = () => {
  const createMappingsMutation = useCreateMappingsMutation();
  const deleteMappingsMutation = useDeleteMappingsMutation();
  const { invalidateQueries } = useSchedulerV3QueryUpdaters();

  const createAndDeleteMappingsAsync = (request: CreateOrUpdateMappingsRequest) => {
    const { mappingIdsForDelete, ...createMappingsInput } = request;
    const createMappings = async () => {
      if (!createMappingsInput.values.length) return;

      await createMappingsMutation.mutateAsync(createMappingsInput);
    };

    const deleteMappings = async () => {
      if (!mappingIdsForDelete?.length) return;

      await deleteMappingsMutation.mutateAsync({ mappingIds: mappingIdsForDelete });
    };

    return Promise.all([createMappings(), deleteMappings()]).then(() => {
      return invalidateQueries({
        endpointName: 'ListMappings',
        queryFilters: {
          exact: false,
        },
      });
    });
  };

  return {
    isLoading: createMappingsMutation.isLoading || deleteMappingsMutation.isLoading,
    createAndDeleteMappingsAsync,
  };
};
