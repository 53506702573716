import { Dispatch, SetStateAction } from 'react';
import { css } from '@emotion/react';
import { UseInfiniteQueryResult } from 'react-query';
import { InfinitePaginatedList } from '@frontend/components';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { PrimaryDot, Text } from '@frontend/design-system';
import { VoicemailInfiniteQueryData, VoicemailTableDataRow } from '../../../components/all-calls/types';
import { formatDateTime } from '../../utils';

export const VoicemailsList = ({
  voicemailDataQuery,
  selectedVoicemail,
  setSelectedIndex,
}: {
  voicemailDataQuery: UseInfiniteQueryResult<VoicemailInfiniteQueryData, unknown>;
  selectedVoicemail: VoicemailTableDataRow | null;
  setSelectedIndex: Dispatch<SetStateAction<number>>;
}) => {
  const { t } = useTranslation('calls', { keyPrefix: 'unread-voicemail-widget' });
  const lastPage = (voicemailDataQuery?.data?.pages.length ?? 0) - 1;
  const { hasNextPage, ...alteredDataQuery } = voicemailDataQuery;
  const dataQuery = {
    ...voicemailDataQuery,
    hasNextPage: lastPage > -1 ? alteredDataQuery.data?.pages[lastPage].meta.hasNext : true,
  };

  return (
    <InfinitePaginatedList
      height={290}
      infiniteQueryProps={dataQuery}
      renderListItem={({ listItem, index }) => (
        <VoicemailItem
          voicemail={listItem}
          markedUnread={!listItem.readAt}
          activeSelection={selectedVoicemail}
          onClick={() => {
            setSelectedIndex(index);
          }}
        />
      )}
      emptyStateConfig={{
        header: t('No Items to Display'),
        type: 'voicemail',
        textProps: {
          color: 'light',
          weight: 'regular',
        },
      }}
      endOfListText=''
    />
  );
};

const VoicemailItem = ({
  voicemail,
  markedUnread,
  activeSelection,
  onClick: handleClick,
}: {
  voicemail: VoicemailTableDataRow;
  markedUnread: boolean;
  activeSelection: VoicemailTableDataRow | null;
  onClick: () => void;
}) => {
  const { t } = useTranslation('calls', { keyPrefix: 'unread-voicemail-widget' });
  const uniqueRowId = voicemail.channelId + '_' + voicemail.mediaId;
  const actionSelectionRowId = !!activeSelection ? activeSelection?.channelId + '_' + activeSelection?.mediaId : null;

  return (
    <section
      tabIndex={0}
      key={uniqueRowId}
      css={[styles.itemWrapper, actionSelectionRowId === uniqueRowId && styles.active]}
      onClick={handleClick}
    >
      <div css={styles.personInfo}>
        {!!markedUnread && <PrimaryDot css={{ position: 'static' }} hoverText='' />}
        <Text css={styles.wrappedName} size='large' weight={!!markedUnread ? 'bold' : 'light'}>
          {voicemail.contactName || t('Unknown')}
        </Text>
      </div>
      <Text size='small' color='subdued' css={styles.timeColumn}>
        {formatDateTime(voicemail.createdAt)}
      </Text>
    </section>
  );
};

const styles = {
  itemWrapper: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid ${theme.colors.neutral10};
    border-radius: ${theme.borderRadius.medium};
    padding: ${theme.spacing(2, 2)};
    margin-bottom: ${theme.spacing(1)};
    cursor: pointer;
    &:hover {
      border-color: ${theme.colors.primary20};
    }
  `,

  personInfo: css`
    display: flex;
    gap: ${theme.spacing(0.5)};
    align-items: center;
    flex-direction: row;
  `,

  wrappedName: css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 120px;
  `,

  timeColumn: css`
    min-width: 40px;
  `,

  active: css`
    border: 1px solid ${theme.colors.primary50};
  `,
};
