import { ComponentProps } from 'react';
import { useTranslation } from '@frontend/i18n';
import { CornersSetting, HeightSetting, Setting } from '.';

type Props = {
  cornersFieldProps: Omit<ComponentProps<typeof CornersSetting>, 'isLast'>;
  heightFieldProps: Omit<ComponentProps<typeof HeightSetting>, 'independent' | 'isLast' | 'title'>;
  isLast?: boolean;
};

export const ImageSetting = ({ cornersFieldProps, heightFieldProps, isLast }: Props) => {
  const { t } = useTranslation('content-composer-settings');

  return (
    <Setting title={t('Image')} isLast={isLast}>
      <CornersSetting {...cornersFieldProps} />
      <HeightSetting {...heightFieldProps} title={t('Image')} isLast />
    </Setting>
  );
};
