import { useTranslation } from '@frontend/i18n';
import { useContentComposer } from '../content-composer-provider';
import { PrivateReviewButtonSection } from '../sections';
import { ToolboxButton } from '../sidebar';

export const PrivateReviewButtonTool = () => {
  const { t } = useTranslation('content-composer');
  const { insertSection } = useContentComposer();

  return (
    <ToolboxButton
      icon='weave-logo-with-border'
      title={t('Private Review Button')}
      subtitle={t(`Link to Weave Private Review`)}
      onClick={() => insertSection(<PrivateReviewButtonSection />)}
    >
      <PrivateReviewButtonSection />
    </ToolboxButton>
  );
};
