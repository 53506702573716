import { css } from '@emotion/react';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { SecondaryButton } from '@frontend/design-system';

export const AddImageButton = ({ text, onClick }: { text: string; onClick: () => void }) => (
  <SecondaryButton
    size='tiny'
    onClick={(e) => {
      e.stopPropagation();
      onClick();
    }}
  >
    <Icon
      name='plus-small'
      css={css`
        margin-right: ${theme.spacing(0.75)};
      `}
    />
    {text}
  </SecondaryButton>
);
