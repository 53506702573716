import { useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { UseInfiniteQueryResult } from 'react-query';
import { VoicemailTypes, VoicemailTypes as VmTypes } from '@frontend/api-voicemails';
import { theme } from '@frontend/theme';
import { VoicemailInfiniteQueryData } from '../../components/all-calls/types';
import { AllCallsTable, usePhonePageSettingShallowStore } from '../../hooks/use-phone-config-state-store';
import { VoicemailDetails } from './split-view/voicemail-details';
import { VoicemailsList } from './split-view/voicemail-list';

export const SplitView = ({
  queryKey,
  voicemailDataQuery,
  onDeleteVoicemail,
  updateReadStatus,
}: {
  queryKey: string[];
  voicemailDataQuery: UseInfiniteQueryResult<VoicemailInfiniteQueryData, unknown>;
  onDeleteVoicemail: (payload: VmTypes.DeletePayload) => void;
  updateReadStatus: (payload: VoicemailTypes.StatusPayload) => Promise<VoicemailTypes.SetVoicemailViewStatusResponse>;
}) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const { config } = usePhonePageSettingShallowStore('config');
  const PAGE_SIZE = config[AllCallsTable.Unread_Voicemails].pageSize;

  const selectedVoicemail = useMemo(() => {
    const pageNumber = Math.floor(selectedIndex / PAGE_SIZE);
    const pageIndex = selectedIndex % PAGE_SIZE;
    return voicemailDataQuery?.data?.pages[pageNumber]?.data[pageIndex] ?? null;
  }, [voicemailDataQuery, selectedIndex]);

  return (
    <div css={styles.wrapper}>
      <div css={styles.listContent}>
        <VoicemailsList
          voicemailDataQuery={voicemailDataQuery}
          selectedVoicemail={selectedVoicemail}
          setSelectedIndex={setSelectedIndex}
        />
      </div>
      {!!selectedVoicemail && (
        <div css={styles.mainContent}>
          <VoicemailDetails
            index={selectedIndex}
            data={selectedVoicemail}
            queryKey={queryKey}
            voicemailDataQuery={voicemailDataQuery}
            onDeleteVoicemail={(payload) => {
              onDeleteVoicemail(payload);
              setSelectedIndex(0);
            }}
            updateReadStatus={updateReadStatus}
          />
        </div>
      )}
    </div>
  );
};

const styles = {
  wrapper: css`
    overflow: auto;
    display: flex;
    margin-top: ${theme.spacing(2)};
    gap: ${theme.spacing(1)};
  `,

  listContent: css`
    flex-grow: 1;
    min-width: 240px;
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(1)};
  `,

  mainContent: css`
    flex-grow: 3;
    min-width: 420px;
  `,
};
