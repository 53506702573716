import { Text } from '@frontend/design-system';
import { Icon } from '@frontend/icons';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';

export const CardHeader = ({ locationId = '' }: { locationId?: string }) => {
  const { accessibleLocationData } = useAppScopeStore();
  const locationName = accessibleLocationData[locationId]?.name;

  return (
    <div
      css={{
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(1.5, 2),
        border: `1px solid ${theme.colors.neutral20}`,
        borderRadius: `${theme.borderRadius.medium} ${theme.borderRadius.medium} 0 0`,
      }}
    >
      <div css={{ display: 'flex' }}>
        <Icon name='location' />
        <Text css={{ marginLeft: theme.spacing(0.5) }}>{locationName}</Text>
      </div>
      {/* TODO: will add these back in as well as the menu popover in v2 */}
      {/* <Icon name='more' /> */}
    </div>
  );
};
