import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { DevicesApi } from '@frontend/api-devices';
import { SoftphoneApi } from '@frontend/api-softphone';
import { UsersQueries } from '@frontend/api-users';
import { useTranslation } from '@frontend/i18n';
import { useQuery } from '@frontend/react-query-helpers';
import { theme } from '@frontend/theme';
import {
  DropdownField,
  FormRow,
  Modal,
  Stepper,
  Text,
  TextField,
  useDebouncedValue,
  useFormField,
} from '@frontend/design-system';
import { type CreateSoftphonePropTypes, inputFieldStyles } from '..';
import { useCreateSoftphoneContext } from '../../../../store/create-softphone-provider';

type Props = CreateSoftphonePropTypes;

export const FirstStep: FC<React.PropsWithChildren<Props>> = ({
  getFieldProps,
  values,
  locationIds,
  tenantId,
}: Props) => {
  const { t } = useTranslation('phone', { keyPrefix: 'create-new-softphone' });

  const { data } = UsersQueries.useGetUsersInLocations(locationIds);
  const { data: userSoftphones } = useQuery({
    queryKey: [locationIds, 'user-softphones'],
    queryFn: () => SoftphoneApi.list(),
  });
  const users = data?.users ?? [];
  const usersWithoutSoftphones = useMemo(
    () =>
      users
        .filter((user) => !userSoftphones?.some((softphone) => softphone.user_id === user.userId))
        .sort((a, b) => (a.firstName && b.firstName ? a.firstName.localeCompare(b.firstName) : 0)),
    [users, userSoftphones]
  );

  const errorMessage = useRef('');
  const [value, setValue] = useState(getFieldProps('extension').value);
  const debouncedSearch = useDebouncedValue(value);
  const [disableNextStepButton, setDisableNextStepButton] = useState(false);
  const { closeModal } = useCreateSoftphoneContext();

  const { mutate: mutateExtensionAvailability } = useMutation(
    (extensionNumber: string) => DevicesApi.checkExtensionAvailability({ extensionNumber, tenantId: tenantId ?? '' }),
    {
      onSuccess: ({ availability, message }) => {
        if (availability === 'IN_USE' || availability === 'FORBIDDEN') {
          errorMessage.current = message;
        } else {
          errorMessage.current = '';
          getFieldProps('extension').onChange({ name: 'extension', value });
        }
      },
      onSettled: () => {
        setDisableNextStepButton(false);
      },
    }
  );

  const extensionFieldProps = useFormField(
    {
      required: true,
      type: 'text',
      value,
      validator: () => errorMessage.current,
      validateOnChange: true,
    },
    [value, errorMessage.current]
  );

  useEffect(() => {
    if (debouncedSearch) {
      mutateExtensionAvailability(debouncedSearch);
    }
  }, [debouncedSearch]);

  useEffect(() => {
    setValue(extensionFieldProps.value);
  }, [extensionFieldProps.value]);

  const next = () => {
    const extension = getFieldProps('extension').value;
    const userFound = users?.find((user) => user.userId === values?.userID);
    getFieldProps('firstName').onChange({ name: 'firstName', value: `${userFound?.firstName}` });
    getFieldProps('deviceName').onChange({
      name: 'deviceName',
      value: `${extension + ' ' + userFound?.firstName}'s Softphone`,
    });
    getFieldProps('internalIDName').onChange({
      name: 'internalIDName',
      value: `${extension + ' ' + userFound?.firstName}'s Softphone`,
    });
  };

  return (
    <>
      <Modal.Header onClose={closeModal}>{t('Select User and Extension')}</Modal.Header>
      <Modal.Body>
        <Text>{t('Select the user who will be assigned the softphone device and choose an extension number.')}</Text>
        <FormRow css={inputFieldStyles}>
          <DropdownField {...getFieldProps('userID')} label={t('Select a User')}>
            {usersWithoutSoftphones.map((user) => {
              return user.userId ? (
                <DropdownField.Option key={user.userId} value={user.userId}>
                  {user.firstName + ' ' + user.lastName}
                </DropdownField.Option>
              ) : null;
            })}
          </DropdownField>
        </FormRow>
        <FormRow css={inputFieldStyles}>
          <TextField
            name={'Extension Number'}
            helperText={t('Choose an extension number that is not currently in use.')}
            label={t('Extension Number')}
            maxLength={6}
            {...extensionFieldProps}
            onKeyDown={() => {
              value.length < 6 ? setDisableNextStepButton(true) : undefined;
            }}
          />
        </FormRow>
      </Modal.Body>
      <Stepper.ButtonBar>
        <Stepper.NextButton
          onClick={next}
          css={{ height: theme.spacing(5), width: 'fit-content' }}
          isValid={!errorMessage.current && !!debouncedSearch && !disableNextStepButton}
        >
          {t('Continue')}
        </Stepper.NextButton>
      </Stepper.ButtonBar>
    </>
  );
};
