import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const modalBody = css`
  @media (max-width: 699px) {
    width: 100%;
    margin: 0;
  }
`;

export const page = css`
  padding: ${theme.spacing(2, 0)};
`;

export const subtitle = css`
  padding: ${theme.spacing(0, 2, 2, 2)};
`;

export const singleButton = css`
  width: fit-content;
  margin-left: ${theme.spacing(2)};
`;
