import { Permission } from '@weave/schema-gen-ts/dist/shared/waccess/acls.pb';
import { hasSchemaACL } from '@frontend/auth-helpers';

export interface PermissionsForLocation {
  hasPermissionToReviewSubmissions: boolean;
  hasPermissionToManageProviders: boolean;
  neitherHasReviewSubmissionNorManageProviderPermissions: boolean;
  hasPermissionToCloneForms: boolean;
  hasFormUploadACL: boolean;
}

export function getPermissionsForLocation(locationId: string): PermissionsForLocation {
  const hasProviderReviewManageACL = hasSchemaACL(locationId, Permission.FORMS_PROVIDER_REVIEW_MANAGE);
  const hasProviderReviewACL = hasSchemaACL(locationId, Permission.FORMS_PROVIDER_REVIEW);
  const hasFormClonerACL = hasSchemaACL(locationId, Permission.FORMS_CLONE_MANAGE);
  const hasFormUploadACL = hasSchemaACL(locationId, Permission.FORMS_UPLOAD_MANAGE);

  // Check if the user has the permission to manage providers
  const hasPermissionToManageProviders = hasProviderReviewManageACL;

  // Check if the user has the permission to only review the submissions
  const hasPermissionToReviewSubmissions = hasProviderReviewManageACL ? false : hasProviderReviewACL;

  // Check if the user neither has the permission to manage providers nor to review the submissions
  const neitherHasReviewSubmissionNorManageProviderPermissions =
    !hasPermissionToManageProviders && !hasPermissionToReviewSubmissions;

  return {
    hasPermissionToReviewSubmissions,
    hasPermissionToManageProviders,
    neitherHasReviewSubmissionNorManageProviderPermissions,
    hasPermissionToCloneForms: hasFormClonerACL,
    hasFormUploadACL,
  };
}
