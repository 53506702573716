import dayjs from 'dayjs';
import { i18next } from '@frontend/i18n';
import { sanitizePhoneNumber } from '@frontend/phone-numbers';
import { PhoneUtils } from '..';
import { VoicemailTableDataRow } from '../components/all-calls/types';

export const getFallbackCallerName = (contactNumber: string): string => {
  const sanitizedNumber = sanitizePhoneNumber(contactNumber ?? '');
  return !!sanitizedNumber ? PhoneUtils.getFormattedCallerName(sanitizedNumber) : i18next.t('Unknown', { ns: 'calls' });
};

export const getShortHandName = (data: VoicemailTableDataRow): string => {
  const { contactName, contactNumber } = data;
  return contactName !== '' ? contactName : getFallbackCallerName(contactNumber);
};

export const formatDateTime = (createdAt: string) => {
  const now = dayjs();
  const messageDate = dayjs(createdAt);

  if (messageDate.isSame(now, 'day')) {
    return messageDate.format('hh:mm A');
  } else if (messageDate.isSame(now.subtract(1, 'day'), 'day')) {
    return i18next.t('Yesterday', { ns: 'calls' });
  } else {
    return messageDate.format('MMM DD');
  }
};
