import { useState } from 'react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useMediaQuery } from '@frontend/responsiveness';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { Button, Text, TextField, useFormField, useModalControl } from '@frontend/design-system';
import { useComposerSection } from '../../../hooks';
import { useGetFormList, useGetPacketList } from '../../../queries';
import { ButtonLinkType, ButtonSectionProps } from '../../../types';
import {
  DocumentSelectorModal,
  ExpansionButton,
  FloatingButton,
  FloatingToolbarCenter,
  PopoverButtonGroup,
  ToolbarButtonGroup,
} from '.';

type Props = {
  link?: string;
  linkType?: ButtonLinkType;
};

type Option = { type: ButtonLinkType; label: string };

export const ButtonsToolbar = ({ link, linkType }: Props) => {
  const { t } = useTranslation('content-composer');
  const [showOptions, setShowOptions] = useState(!!link || !!linkType);
  const [showCustom, setShowCustom] = useState(false);
  const { setProps } = useComposerSection<ButtonSectionProps>();

  const EXTRA_LARGE_BREAKPOINT = 1500;
  const LARGE_BREAKPOINT = 1320;

  const options: Option[] = [
    { type: 'phone', label: t('Practice Phone') },
    { type: 'address', label: t('Practice Address') },
    { type: 'schedule', label: t('Schedule') },
    { type: 'form', label: t('Form') },
    { type: 'custom', label: t('Custom') },
  ];

  const getIcon = () => {
    switch (linkType) {
      case 'form':
        return 'forms';
      default:
        return 'link';
    }
  };

  const getLinkLabel = () => {
    switch (linkType) {
      case 'form':
        return documents.find((document) => document.id === link)?.name;
      case 'schedule':
        return t('Schedule');
      case 'phone':
        return t('Practice Phone');
      case 'address':
        return t('Practice Address');
      case 'custom':
      default:
        return link;
    }
  };

  const setLink = (value: string) => {
    setProps((props) => (props.link = value));
  };

  const setLinkType = (value: ButtonLinkType) => {
    setProps((props) => (props.linkType = value));
  };

  // FORM
  const { selectedLocationIdsWithParents } = useAppScopeStore();
  const locationId = selectedLocationIdsWithParents[0];
  const { data: forms } = useGetFormList({ companyId: locationId });
  const { data: packets } = useGetPacketList({ companyId: locationId });
  const documents = (forms || []).concat(packets || []);
  const { modalProps, triggerProps } = useModalControl();
  const openDocumentSelectorModal = () => {
    triggerProps.onClick();
  };
  const updateDocumentId = (documentId: string) => {
    setLink(documentId);
    modalProps.onClose();
  };

  // CUSTOM
  const fieldProps = useFormField({ type: 'text', value: link }, [link]);

  const initialState = (
    <FloatingButton
      onClick={() => setShowOptions(true)}
      css={{ ...toolbarStyling, fontWeight: theme.font.weight.bold }}
    >
      <Icon name='link' css={iconStyling} />
      <Text as='span'>{t('Link Button')}</Text>
    </FloatingButton>
  );

  const hasLinkState = (
    <FloatingButton css={toolbarStyling}>
      <Icon name={getIcon()} css={iconStyling} />
      <Text
        as='span'
        color='primary'
        css={{
          textDecoration: 'underline',
          textUnderlineOffset: theme.spacing(0.5),
        }}
        onClick={() => {
          if (linkType === 'form') {
            openDocumentSelectorModal();
          } else if (linkType === 'custom') {
            setShowCustom(true);
          }
        }}
      >
        {getLinkLabel()}
      </Text>
      <Icon
        name='x-small'
        onClick={() => {
          setLink('');
          setShowCustom(false);
        }}
      />
    </FloatingButton>
  );

  const handleCustomLinkInput = () => {
    if (fieldProps.value) {
      setLink(fieldProps.value);
      setShowCustom(false);
    }
  };

  const customLinkTypeState = (
    <FloatingButton css={toolbarStyling}>
      <Icon name='back' css={iconStyling} onClick={() => setShowCustom(false)} />
      <TextField
        {...fieldProps}
        name=''
        label=''
        autoFocus
        placeholder={t('Link URL')}
        css={{
          width: 450,
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') handleCustomLinkInput();
        }}
      />
      <Icon name='check' color={fieldProps.value ? 'primary' : 'disabled'} onClick={handleCustomLinkInput} />
    </FloatingButton>
  );

  const arr1 = options.slice(0, 1);
  const arr2 = options.filter((option) => !arr1.find((o) => o.type === option.type)).slice(0, 2);
  const arr3 = options.filter(
    (option) => !arr1.find((o) => o.type === option.type) && !arr2.find((o) => o.type === option.type)
  );
  const isLarge = useMediaQuery(`(min-width: ${LARGE_BREAKPOINT}px) and (max-width: ${EXTRA_LARGE_BREAKPOINT}px)`);

  const createLinkButton = (option: Option) => (
    <Button
      variant='secondary'
      key={option.type}
      css={{
        padding: theme.spacing(1),
        justifyContent: 'flex-start',
        width: 'auto',
        fontWeight: theme.font.weight.regular,
      }}
      onClick={() => {
        if (option.type === 'form') {
          setLink('');
          openDocumentSelectorModal();
        } else if (option.type === 'address' || option.type === 'phone' || option.type === 'schedule') {
          setLink(option.label);
        } else if (option.type === 'custom') {
          setShowCustom(true);
        }
        setLinkType(option.type);
      }}
    >
      <Icon name='link' css={iconStyling} />
      <Text as='span'>{option.label}</Text>
    </Button>
  );

  const linkOptionsState = (
    <div css={toolbarStyling}>
      <Icon name='x' onClick={() => setShowOptions(false)} css={iconStyling} />
      {arr1.map(createLinkButton)}
      <ToolbarButtonGroup breakpoint={LARGE_BREAKPOINT}>{arr2.map(createLinkButton)}</ToolbarButtonGroup>
      <ToolbarButtonGroup breakpoint={EXTRA_LARGE_BREAKPOINT}>{arr3.map(createLinkButton)}</ToolbarButtonGroup>
      {((arr2.length > 0 && !isLarge) || arr3.length > 0) && (
        <ExpansionButton breakpoint={EXTRA_LARGE_BREAKPOINT} hideBorder>
          {arr2.length > 0 ? (
            <PopoverButtonGroup breakpoint={LARGE_BREAKPOINT}>{arr2.map(createLinkButton)}</PopoverButtonGroup>
          ) : null}
          {arr3.length > 0 ? arr3.map(createLinkButton) : null}
        </ExpansionButton>
      )}
    </div>
  );

  const getContent = () => {
    if (!showOptions) return initialState;
    else if (showCustom) return customLinkTypeState;
    else if (link && !showCustom) return hasLinkState;
    else return linkOptionsState;
  };

  return (
    <>
      <FloatingToolbarCenter orientation='bottom'>{getContent()}</FloatingToolbarCenter>
      <DocumentSelectorModal
        modalProps={modalProps}
        documents={documents}
        documentId={link}
        updateDocumentId={updateDocumentId}
      />
    </>
  );
};

const toolbarStyling = {
  display: 'flex',
  alignItems: 'center',
  columnGap: theme.spacing(1),
  padding: theme.spacing(1),
  background: theme.colors.white,
  boxShadow: theme.shadows.light,
  border: `solid 1px transparent`,
  borderRadius: theme.borderRadius.small,
  transition: 'all 250ms ease',

  '&:hover, &:focus': {
    outline: 'none',
    boxShadow: theme.shadows.floating,
    background: theme.colors.neutral5,
  },
};

const iconStyling = {
  height: theme.spacing(2),
  width: theme.spacing(2),
  minHeight: theme.spacing(2),
  minWidth: theme.spacing(2),
};
