import { useSchedulerV3QueryUpdaters } from './query-updaters';

export const useSchedularV3InvalidateQueries = () => {
  const { invalidateQueries } = useSchedulerV3QueryUpdaters();

  // TODO: This functions need to be updated to optionally accept a locationId to invalidate only the queries related to that location
  const invalidateListProviders = () =>
    invalidateQueries({
      endpointName: 'ListProviders', // this will invalidate both `ListProviders` and `ListProvidersMulti` queries
      queryFilters: {
        exact: false,
      },
    });

  const invalidGetProvider = (providerId: string) =>
    invalidateQueries({
      endpointName: 'GetProvider',
      queryFilters: {
        exact: false,
        predicate: ({ queryKey }) => queryKey[2].id === providerId,
      },
    });

  const invalidateListMappings = () =>
    invalidateQueries({
      endpointName: 'ListMappings',
      queryFilters: {
        exact: false,
      },
    });

  return { invalidateListProviders, invalidGetProvider, invalidateListMappings };
};
