import { css } from '@emotion/react';
import { getUser } from '@frontend/auth-helpers';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Modal, Text, TextareaField } from '@frontend/design-system';
import { useContentComposer } from '../content-composer-provider';

export const PromptModal = ({ onClick, onClose }: { onClick: () => void; onClose: () => void }) => {
  const { t } = useTranslation('content-composer');
  const { promptProps } = useContentComposer();
  const user = getUser();
  const firstName = user?.firstName ?? '';

  return (
    <>
      <Modal.Header onClose={onClose}>
        <Text
          css={css`
            font-size: 20px;
          `}
        >
          {t('Hello{{firstName}}!', { firstName: firstName ? ` ${firstName}` : '' })}
        </Text>
      </Modal.Header>
      <Modal.Body>
        <Text
          css={css`
            margin: ${theme.spacing(2.5, 0, 2)};
          `}
        >
          {t('I can help you create a great email.')}
        </Text>
        <Text
          css={css`
            margin-bottom: ${theme.spacing(2)};
          `}
        >
          {t('Please write some ideas, topics, and details that you would like included in your email.')}
        </Text>
        <TextareaField {...promptProps} name='prompt' label='' />
      </Modal.Body>
      <Modal.Actions
        secondaryLabel={t('Cancel')}
        onSecondaryClick={onClose}
        primaryLabel={t('Continue')}
        disablePrimary={!promptProps.value}
        onPrimaryClick={onClick}
      />
    </>
  );
};
