import dayjs from 'dayjs';
import { Channel, UserResponse, ChannelData } from 'stream-chat';
import { CHANNELS_QUERY_LIMIT, MESSAGES_QUERY_LIMIT } from '../constants';
import { Conversations, StreamConversation, StreamInstance, StreamUser } from '../types';
import { getTeamId, formatUser, formatMessage } from '../utils';

// This method was taken from the old desktop client for conformity
export const generateUniqueName = (friendlyName: string) => {
  return `${friendlyName
    .toLocaleLowerCase()
    .replace(/[^a-zA-Z0-9 ]/g, '')
    .replace(/ /g, '-')}-${Date.now().toString()}`;
};

export const formatChannel = (channel: Channel, currentUserId = ''): StreamConversation => {
  const { messages, members, read } = channel.state;
  const { id = '' } = channel; // TODO: find a better way to avoid empty string here
  const isPrivate = channel.id?.startsWith('!members');
  const filteredMembers = Object.values(members).reduce<UserResponse[]>((newMembers, member) => {
    if (member.user && member.user.id !== currentUserId) {
      newMembers.push(member.user);
    }
    return newMembers;
  }, []);
  // @ts-expect-error - created_by is not in the types but it is in the data
  const createdByName: string = channel.data?.created_by.name ?? '';
  // Explicitly defining as string because they are meta data and are not defined in the channel data types
  const topic = (channel.data?.topic as string) ?? '';
  const description = (channel.data?.description as string) ?? '';
  const createdAt = channel.data?.created_at as string;

  return {
    channelId: id,
    name: !isPrivate && channel.data?.name ? channel.data?.name : filteredMembers.map((user) => user.name).join(', '),
    type: isPrivate ? 'DM' : 'Group',
    unreadCount: read[currentUserId].unread_messages,
    messages: messages.map((message) =>
      formatMessage({
        channelId: id,
        currentUserId,
        message,
        lastReadMessageId: read[currentUserId].last_read_message_id ?? '',
      })
    ),
    members: filteredMembers.map((member) => formatUser(member)),
    createdBy: createdByName,
    topic,
    description,
    createdAt: dayjs(createdAt).format('MMMM DD, YYYY'),
    isArchived: !!channel.data?.frozen,
    isHidden: !!channel.data?.hidden,
    usersTyping: [],
    lastReadMessageId: read[currentUserId].last_read_message_id ?? '',
  };
};

export const fetchChannel = async (client: StreamInstance, channelId: string, options?: ChannelData) => {
  const channel = client.getChannelById('team', channelId, { ...options });
  await channel.watch({ messages: { limit: MESSAGES_QUERY_LIMIT } });
  return formatChannel(channel, client.user?.id);
};

const fetchChannels = async (client: StreamInstance, selectedOrgId: string) => {
  let hasMoreChannels = true;
  const channels: Channel[] = [];
  let offset = 0;
  while (hasMoreChannels) {
    const paginatedChannels = await client.queryChannels(
      {
        type: 'team',
        team: { $in: [getTeamId(selectedOrgId)] },
        members: { $in: [client.user?.id ?? ''] },
        frozen: false,
      },
      [{ last_updated: -1 }],
      {
        watch: true,
        state: true,
        presence: true,
        limit: CHANNELS_QUERY_LIMIT,
        offset,
        message_limit: MESSAGES_QUERY_LIMIT,
      }
    );
    channels.push(...paginatedChannels);
    offset += CHANNELS_QUERY_LIMIT;
    hasMoreChannels = paginatedChannels.length === CHANNELS_QUERY_LIMIT;
  }
  return channels.map((channel) => formatChannel(channel, client.user?.id));
};

export const getChannels = async (client: StreamInstance, selectedOrgId: string) => {
  const formattedChannels = await fetchChannels(client, selectedOrgId);
  const { dm, groups } = formattedChannels
    .sort(({ name: name1 }, { name: name2 }) =>
      name1 && name2 ? (name2.toLocaleLowerCase() > name1.toLocaleLowerCase() ? -1 : 1) : 0
    )
    .reduce<Conversations>(
      (acc, channel) => {
        if (channel.type === 'DM') {
          acc.dm.push(channel);
        } else {
          acc.groups.push(channel);
        }
        return acc;
      },
      { dm: [], groups: [] }
    );
  return { dm, groups };
};

interface ChannelWithMembersProps {
  client: StreamInstance;
  members: StreamUser[];
  orgId: string;
}

export const findChannelWithMembers = async ({ client, members, orgId }: ChannelWithMembersProps) => {
  if (!client.user?.id) return null;
  const channels = await client.queryChannels(
    {
      type: 'team',
      team: { $in: [getTeamId(orgId)] },
      members: { $eq: [...members.map((member) => member.userID), client.user.id] },
    },
    [{ last_message_at: -1 }],
    { watch: false }
  );

  const channelsFilteredByMembers = channels.filter(
    (channel) => channel.id?.startsWith('!members') || channel.data?.is_dm
  );
  if (channelsFilteredByMembers.length) {
    return formatChannel(channelsFilteredByMembers[0], client.user.id);
  }
  return null;
};

interface CreateChannelProps {
  streamClient: StreamInstance;
  members: StreamUser[];
  orgId: string;
  isDm: boolean;
  name: string | undefined;
}

export const createChannel = async ({ streamClient, members, orgId, isDm, name = '' }: CreateChannelProps) => {
  if (!streamClient.user?.id) throw new Error('Stream client is not connected.');
  // We generate a new id with the help of this function. Because id is the only difference between the channels.
  // If we let stream generate it then it would be the same for all the channels and we would not be able
  // to differentiate between them.
  const channel = streamClient.channel('team', isDm ? null : generateUniqueName(name), {
    members: [streamClient.user.id, ...members.map((i) => i.userID)],
    created_by_id: streamClient.user.id,
    team: getTeamId(orgId),
    name: isDm ? undefined : name,
    is_dm: isDm,
  });
  await channel.create({ watch: true, presence: true });
  await channel.watch();
  return formatChannel(channel, streamClient.user.id);
};

export const getPreviousMessages = async ({
  channelId,
  client,
  conversation,
  currentUserId,
}: {
  channelId: string;
  client: StreamInstance;
  conversation: StreamConversation;
  currentUserId: string;
}) => {
  const previousResponse = await client.getChannelById('team', channelId, {})?.query({
    messages: { id_lt: conversation.messages[0].id, limit: MESSAGES_QUERY_LIMIT },
  });
  const readData = previousResponse.read?.find((read) => read.user.id === currentUserId);

  previousResponse?.messages?.length &&
    (conversation.messages = [
      ...previousResponse.messages.map((message) =>
        formatMessage({ channelId, currentUserId, message, lastReadMessageId: readData?.last_read_message_id ?? '' })
      ),
      ...conversation.messages,
    ]);

  const hasMoreMessages = previousResponse?.messages?.length === MESSAGES_QUERY_LIMIT;
  return { conversation, hasMoreMessages };
};
