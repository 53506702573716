import { useEffect } from 'react';
import { css } from '@emotion/react';
import { ScheduleAvailabilityUtils } from '@frontend/api-schedule-availability';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { breakpoints, useMatchMedia } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';
import { useFormField, TimeRangeField, Text } from '@frontend/design-system';
import { useScheduleAvailabilityHoursContext } from '../../context/ScheduleAvailabilityHoursContext';
import { BreakEvent, DayOfWeek, DisplayScheduleAvailabilityHour } from '../../types';
import { AddBreakButton } from '../AddBreakButton';
import { ScheduleBreaks } from '../ScheduleBreaks';

const { addBreakToDisplaySchedule, checkOverlappingBreak, withinOpenHours, formatTimeString, TranslatedWeek } =
  ScheduleAvailabilityUtils;

type DayScheduleProps = {
  day: DayOfWeek;
  index?: number;
  displaySchedule?: DisplayScheduleAvailabilityHour;
  shouldShowDayLabel?: boolean;
};

export const DaySchedule = ({ day, displaySchedule, shouldShowDayLabel = true, index = 0 }: DayScheduleProps) => {
  const { t } = useTranslation('schedule');
  const isMobile = useMatchMedia({ maxWidth: breakpoints.xsmall.max });

  const { onUpdateDay, closedOfficeText, updateErrorsByDay, timeRangeJoiningText } =
    useScheduleAvailabilityHoursContext();

  const timeRangeField = useFormField(
    {
      type: 'timeRange',
      value: [displaySchedule?.startTime || '', displaySchedule?.endTime || ''],
      interval: 15,
    },
    [displaySchedule?.startTime, displaySchedule?.endTime]
  );

  useEffect(() => {
    const [startTime, endTime] = timeRangeField.value;

    const newDisplaySchedule = { ...displaySchedule };
    if (startTime) {
      newDisplaySchedule.startTime = formatTimeString(startTime);
    }
    if (endTime) {
      newDisplaySchedule.endTime = formatTimeString(endTime);
    }
    onUpdateDay(day, newDisplaySchedule as DisplayScheduleAvailabilityHour, index);
  }, [day, timeRangeField.value[0], timeRangeField.value[1], index]);

  const handleUpdateBreak = (day: DayOfWeek, idx: number) => {
    return ([startTime, endTime]: string[]) => {
      const newDisplaySchedule = {
        ...(displaySchedule || {}),
      } as DisplayScheduleAvailabilityHour;
      if (newDisplaySchedule.breaks) {
        newDisplaySchedule.breaks = newDisplaySchedule.breaks.map((breakEvent, i) =>
          i === idx ? { startTime: formatTimeString(startTime), endTime: formatTimeString(endTime) } : breakEvent
        );
      }

      onUpdateDay(day, newDisplaySchedule, index);
    };
  };

  const handleDeleteBreak = (day: DayOfWeek, idx: number) => {
    return () => {
      if (!displaySchedule) {
        return;
      }
      const breaks = [...(displaySchedule?.breaks || [])];
      breaks.splice(idx, 1);

      // Validate breaks after deleting
      breaks.forEach((breakEvent, i) => {
        validateBreaks(i)(day, breakEvent.startTime, breakEvent.endTime);
      });

      if (breaks.length === 0) {
        updateErrorsByDay(day, false);
      }

      onUpdateDay(day, { ...displaySchedule, breaks }, index);
    };
  };

  const handleAddBlock = () => {
    if (!displaySchedule) {
      return;
    }
    onUpdateDay(day, addBreakToDisplaySchedule(displaySchedule), index);
  };

  const validateBreaks = (index: number) => (day: string, start: string, end: string) => {
    const isOverlappingBreak =
      checkOverlappingBreak({
        index,
        timeString: start,
        breaks: displaySchedule?.breaks || [],
      }) &&
      checkOverlappingBreak({
        index,
        timeString: end,
        breaks: displaySchedule?.breaks || [],
      });

    const isWithinOpenHours = withinOpenHours({
      end,
      start,
      hours: timeRangeField.value,
    });

    // Update error state for the day
    updateErrorsByDay(day, !isWithinOpenHours || !isOverlappingBreak);

    if (!isWithinOpenHours) {
      return t('Break is outside of scheduled hours');
    }
    if (!isOverlappingBreak) {
      return t('Break overlaps with another break');
    }
    return '';
  };

  const renderBreaksLabel = () => {
    if (!isMobile || !displaySchedule?.breaks?.length) {
      return null;
    }
    return <label css={{ textAlign: 'center', marginBottom: theme.spacing(2) }}>{t('Break')}</label>;
  };

  const isClosedDays = !displaySchedule?.startTime && !displaySchedule?.endTime;

  return (
    <>
      <div css={dayScheduleStyles(isMobile, isClosedDays)}>
        {shouldShowDayLabel && <Text>{TranslatedWeek()[day]}</Text>}
        {displaySchedule && (
          <div css={timeFieldContainerMobileStyles}>
            <TimeRangeField
              joiningText={timeRangeJoiningText}
              {...timeRangeField}
              name={`${day}TimeRange`}
              label={t('Time Range')}
            />
            <AddBreakButton onClick={handleAddBlock} />
          </div>
        )}
        {!displaySchedule && (
          <div css={closedDayStyles}>
            <Icon size={16} name='moon' css={{ marginRight: theme.spacing(1), color: theme.colors.neutral20 }} />
            <Text css={{ color: theme.colors.neutral20 }} as='span'>
              {closedOfficeText}
            </Text>
          </div>
        )}
      </div>
      {displaySchedule && (
        <div css={scheduleBreakEventContainer(isMobile)}>
          {renderBreaksLabel()}
          {displaySchedule?.breaks?.map(({ startTime, endTime }: BreakEvent, idx: number) => (
            <ScheduleBreaks
              day={day}
              key={`${startTime}_${endTime}_${idx}`}
              startTime={startTime}
              endTime={endTime}
              validateBreaks={validateBreaks(idx)}
              onChange={handleUpdateBreak(day, idx)}
              onDelete={handleDeleteBreak(day, idx)}
              officeTimeRange={timeRangeField.value}
            />
          ))}
        </div>
      )}
    </>
  );
};

const dayScheduleStyles = (isMobile: boolean, isClosedDays: boolean) =>
  !isMobile ? dayScheduleRowStyles : dayScheduleRowStylesMobile(isClosedDays);

const dayScheduleRowStyles = css({
  display: 'grid',
  gridTemplateColumns: 'minmax(auto, 50%) 1fr',
  alignItems: 'center',
  padding: theme.spacing(1.5, 0),
});

const dayScheduleRowStylesMobile = (isClosedDays: boolean) =>
  css({
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 24,
    ...(isClosedDays && { borderBottom: `thin solid ${theme.colors.neutral20}` }),
  });

const closedDayStyles = css({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: theme.colors.neutral5,
  borderRadius: theme.borderRadius.small,
  padding: theme.spacing(1),
  width: '100%',
});

const timeFieldContainerMobileStyles = css({
  display: 'flex',
  alignItems: 'center',
});

const scheduleBreakEventContainer = (isMobile: boolean) =>
  css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    ...(isMobile && { borderBottom: `thin solid ${theme.colors.neutral20}` }),
  });
