import { useMemo } from 'react';
import { ScheduleRequestHistoryItem } from '@weave/schema-gen-ts/dist/schemas/schedule/api/v2/api.pb';
import { OrderBy } from '@weave/schema-gen-ts/dist/schemas/schedule/v3/booking_submission.pb';
import { startCase } from 'lodash-es';
import { ScheduleQueries, ScheduleTypes } from '@frontend/api-schedule';
import { SchedulerV3Queries } from '@frontend/api-scheduler-v3';
import { UsersQueries } from '@frontend/api-users';
import { getFullName } from '../../utils';
import { useGetSchedulerV3FeatureFlagDetails } from '../use-get-scheduler-v3-feature-flag-details';
import { useGetAppointmentTypesV3Data } from './use-get-appointment-types-v3-data';

export const useGetScheduleRequestHistoryData = (
  locationId: string,
  pageConfig: ScheduleTypes.ScheduleRequestHistoryPageConfig
) => {
  const { isScheduleV3FlagEnabled, isLoading: isLoadingScheduleV3FF } = useGetSchedulerV3FeatureFlagDetails();

  const listScheduleRequestsQueryV2 = ScheduleQueries.useGetScheduleRequestsHistory(
    {
      limit: pageConfig.pageSize,
      offset: pageConfig.offset,
      locationIds: locationId ? [locationId] : [],
      orderBy: 'date_time',
    },
    {
      enabled: !isScheduleV3FlagEnabled && !isLoadingScheduleV3FF,
    },
    locationId
  );

  const listScheduleRequestsQueryV3 = SchedulerV3Queries.useListScheduleRequestsQuery(
    {
      locationsIds: locationId ? [locationId] : [],
      limit: pageConfig.pageSize,
      page: Math.floor(pageConfig.offset / pageConfig.pageSize) + 1,
      orderBy: OrderBy.REVIEWED_AT,
    },
    {
      enabled: isScheduleV3FlagEnabled && !isLoadingScheduleV3FF,
    }
  );

  const { data: appointmentTypes, isLoading: isLoadingAppointmentTypes } = useGetAppointmentTypesV3Data({
    selectedLocationId: locationId,
  });

  const locationUsers = UsersQueries.useGetMultiLocationsUsers({
    locationIds: [locationId],

    params: { showChildren: false },
  }).flatMap(({ data }) => data || []);

  const data = useMemo(() => {
    if (isScheduleV3FlagEnabled) {
      if (isLoadingAppointmentTypes) {
        return [];
      }
      // convert to v2 format
      return (listScheduleRequestsQueryV3.data?.bookingSubmissions ?? []).map<ScheduleRequestHistoryItem>(
        (scheduleRequest) => {
          const appointmentType = appointmentTypes?.appointmentTypes.find(
            (type) => type.id === scheduleRequest.appointmentTypeId
          );

          const reviewedByUser = locationUsers.find((user) => user.UserID === scheduleRequest.reviewedBy);

          return {
            locationId: scheduleRequest.locationId,
            reviewedAt: scheduleRequest.reviewedAt,
            reviewedBy: getFullName(reviewedByUser),
            appointmentType: appointmentType?.displayName || appointmentType?.externalName || '',
            appoinmentOutcome: startCase(scheduleRequest.reviewedStatus?.toLowerCase()),
            recipient: getFullName(scheduleRequest),
            messageDatetime: scheduleRequest.createdAt,
          };
        }
      );
    }

    return listScheduleRequestsQueryV2.data?.items ?? [];
  }, [
    isScheduleV3FlagEnabled,
    listScheduleRequestsQueryV2.data,
    listScheduleRequestsQueryV3.data,
    appointmentTypes,
    locationUsers,
  ]);

  const isLoading = isScheduleV3FlagEnabled
    ? listScheduleRequestsQueryV3.isFetching
    : listScheduleRequestsQueryV2.isFetching;

  const refetch = isScheduleV3FlagEnabled ? listScheduleRequestsQueryV3.refetch : listScheduleRequestsQueryV2.refetch;

  return { data, isLoading, refetch };
};
