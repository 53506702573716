import { css } from '@emotion/react';
import { Trans, useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { BulkEmailPrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import { Heading, IconButton, ModalControlModalProps, Text, TextLink, Tray } from '@frontend/design-system';

export const PrivacyRulesTray = ({ modalProps }: { modalProps: ModalControlModalProps }) => {
  const { t } = useTranslation('bulk-messaging');

  return (
    <Tray {...modalProps} width='medium'>
      <Tray.Header
        Buttons={
          <IconButton
            label='close'
            onClick={modalProps.onClose}
            css={css`
              padding: 0;
            `}
          >
            <Icon name='x' />
          </IconButton>
        }
      >
        <Heading level={2}>{t('Privacy Rules')}</Heading>
      </Tray.Header>
      <Tray.Body>
        <Text
          css={css`
            color: ${theme.colors.neutral50};
          `}
        >
          <Trans t={t}>
            Please note: The HIPAA Privacy Rule requires an individual's written authorization before their PHI can be
            used for marketing. Compliance with the Privacy Rule while using Weave's Bulk Messaging feature will depend
            on the content of the text and other factors. You can learn more about HIPAA and marketing by visiting the{' '}
            <TextLink
              to='https://www.hhs.gov/hipaa/for-professionals/privacy/guidance/marketing/index.html'
              target='_blank'
            >
              HHS website
            </TextLink>
            .
          </Trans>
        </Text>
      </Tray.Body>
      <Tray.Actions
        secondaryLabel={t('Back')}
        secondaryTrackingId={`${BulkEmailPrefixes.Audience}-privacy-rules-filter-back-btn`}
        onSecondaryClick={modalProps.onClose}
        disabledPrimary={true}
        css={css`
          .tray-primary-button {
            display: none;
          }
        `}
      />
    </Tray>
  );
};
