import { CSSProperties } from 'react';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { CategoryBarChartProps } from './category-bar-chart-types';
import { GroupName } from './group-name';

interface Props {
  groupNames: string[];
  highestValueConfig?: CategoryBarChartProps['highestValueConfig'];
  highestValueGroup?: string;
  style?: CSSProperties;
}

export const Groups = ({ groupNames = [], highestValueConfig, highestValueGroup, style }: Props) => {
  return (
    <div css={styles.wrapper} style={style}>
      {groupNames.map((name) => (
        <GroupName
          key={name}
          highestValueColor={highestValueConfig?.color}
          highestValueText={highestValueGroup === name ? highestValueConfig?.text : null}
          title={name}
        />
      ))}
    </div>
  );
};

const styles = {
  wrapper: css`
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-around;
    margin-bottom: ${theme.spacing(4)};
    transition: margin-top 0.2s;
  `,
};
