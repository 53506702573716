import { Element, Frame, useEditor } from '@craftjs/core';
import { css } from '@emotion/react';
import { SIDEBAR_BREAKPOINTS } from '../../constants';
import { useContentComposer } from '../content-composer-provider';
import { Sidebar } from '../sidebar';
import { Footer } from './footer';
import { Designer } from '.';

export const SUBJECT_FIELD_NAME = 'subject';

type ContainerProps = {
  canvasEmptyStateTitle?: string;
  canvasEmptyStateText?: string;
  canvasEmptyStateSubtext?: string;
};

// TODO: Move all widths and heights to a constants file.
// Also fix the heights and the parent container (or whatever it is that's causing issues) so we don't have to use calc.
export const Container = ({ canvasEmptyStateTitle, canvasEmptyStateText, canvasEmptyStateSubtext }: ContainerProps) => {
  const { activePanel, composerMode } = useContentComposer();
  const hideDefaultFooter = composerMode !== 'marketing';

  const {
    actions: { clearEvents },
    query: { getEvent },
  } = useEditor();

  // Close section settings onClicking anywhere outside that section in canvas
  const handleFrameClick = () => {
    const isAnyEventsAvailable =
      !getEvent('selected').isEmpty() || !getEvent('dragged').isEmpty() || !getEvent('hovered').isEmpty();
    if (isAnyEventsAvailable) {
      clearEvents();
    }
  };

  return (
    <article
      className='content-composer-container'
      css={css`
        display: grid;
        grid-template-columns: minmax(200px, 400px) minmax(300px, auto);
        height: 100%;
      `}
    >
      <Sidebar />
      <section
        css={(theme) => css`
          grid-area: main;
          display: grid;
          align-items: center;
          overflow-y: auto;
          background: ${theme.colors.neutral5};
          padding: ${theme.spacing(5.5, 4, 8)};
          height: calc(100% - ${theme.heightOffset}px);

          @media (max-width: ${SIDEBAR_BREAKPOINTS.desktop}px) {
            padding-bottom: ${theme.spacing(10)};
          }
          @media (max-width: ${SIDEBAR_BREAKPOINTS.mobile}px) {
            padding: ${activePanel !== 'unset' ? theme.spacing(4, 2, 15) : theme.spacing(4, 2, 10)};
            height: ${activePanel !== 'unset' ? '60%' : '100%'};
            max-width: 100vw;
            transition: height 0.3s ease-in-out;
          }
        `}
        onClick={handleFrameClick}
      >
        <Frame>
          <Element
            is={Designer}
            canvas
            canvasEmptyStateTitle={canvasEmptyStateTitle}
            canvasEmptyStateText={canvasEmptyStateText}
            canvasEmptyStateSubtext={canvasEmptyStateSubtext}
          />
        </Frame>
      </section>
      {hideDefaultFooter ? null : <Footer />}
    </article>
  );
};
