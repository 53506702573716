import { Appointment } from '@weave/schema-gen-ts/dist/schemas/schedule/calendar-events/v1/calendar_events.pb';
import { AppointmentTypesTypes } from '@frontend/api-appointment-types';
import { ScheduleTypes } from '@frontend/api-schedule';
import { createShallowStore, createStore } from '@frontend/store';
import { getFilteredProvidersInCalendarViewLocationStorageKey } from '../views/Calendar/helpers';

type CalendarHeaderFilterStore = {
  defaultLocationIds: string[];
  defaultProviders: ScheduleTypes.SchemaProvider[];
  defaultAppointmentTypes: AppointmentTypesTypes.AppointmentType[];
  selectedProvidersList: ScheduleTypes.SchemaProvider[];
  selectedAppointmentTypesList: string[];
  selectedInsuranceVerificationStatusList: string[];
  filteredAppointmentList: Appointment[];
  isUnConfirmationStatusSelected: boolean;
  isFiltersApplied: boolean;
  setSelectedProvidersList: (
    providersListData: ScheduleTypes.SchemaProvider[] | [],
    locationId: string,
    userId: string
  ) => void;
  resetSelectedProvidersList: (locationId: string, userId: string) => void;
  setSelectedAppointmentTypesList: (selectedAppointmentTypeList: string[]) => void;
  setSelectedInsuranceVerificationStatusList: (selectedInsuranceVerificationStatusList: string[]) => void;
  setIsUnConfirmationStatusSelected: (isConfirmationStatusSelected: boolean) => void;
  setIsFiltersApplied: (isFiltersApplied: boolean) => void;
  setFilteredAppointmentList: (filteredAppointmentListData: Appointment[] | []) => void;
  setDefaultLocationIds: (locationIds: string[]) => void;
  setDefaultProviders: (providers: ScheduleTypes.SchemaProvider[]) => void;
  setDefaultAppointmentTypes: (appointmentTypes: AppointmentTypesTypes.AppointmentType[]) => void;
  resetFiltersData: () => void;
};

export const useCalendarHeaderFilterStore = createStore<CalendarHeaderFilterStore>(
  (set) => ({
    defaultLocationIds: [],
    defaultProviders: [],
    defaultAppointmentTypes: [],
    filteredAppointmentList: [],
    selectedProvidersList: [],
    selectedAppointmentTypesList: [],
    selectedInsuranceVerificationStatusList: [],
    isUnConfirmationStatusSelected: false,
    isFiltersApplied: false,

    setSelectedProvidersList: (
      providersListData: ScheduleTypes.SchemaProvider[],
      locationId: string,
      userId: string
    ) => {
      // set only the providerIds in the local storage
      const filteredProviderIds = providersListData.map(({ id }) => id ?? '').filter((id) => id);
      localStorage.setItem(
        getFilteredProvidersInCalendarViewLocationStorageKey(locationId, userId),
        JSON.stringify(filteredProviderIds)
      );
      set({ selectedProvidersList: providersListData });
    },
    resetSelectedProvidersList: (locationId: string, userId: string) => {
      // Clear the local storage when the selected providers list is reset
      localStorage.removeItem(getFilteredProvidersInCalendarViewLocationStorageKey(locationId, userId));
      set({ selectedProvidersList: [] });
    },
    setSelectedAppointmentTypesList: (selectedAppointmentTypesList: string[]) => {
      set({ selectedAppointmentTypesList });
    },
    setSelectedInsuranceVerificationStatusList: (selectedInsuranceVerificationStatusList: string[]) => {
      set({ selectedInsuranceVerificationStatusList });
    },
    setIsUnConfirmationStatusSelected: (isUnConfirmationStatusSelected: boolean) => {
      set({ isUnConfirmationStatusSelected });
    },
    setIsFiltersApplied: (isFiltersApplied: boolean) => {
      set({ isFiltersApplied });
    },
    setFilteredAppointmentList: (filteredAppointmentListData: Appointment[] | []) => {
      set({ filteredAppointmentList: filteredAppointmentListData });
    },
    setDefaultLocationIds: (locationIds: string[]) => {
      set({ defaultLocationIds: locationIds });
    },
    setDefaultProviders: (providers: ScheduleTypes.SchemaProvider[]) => {
      set({ defaultProviders: providers });
    },
    setDefaultAppointmentTypes: (appointmentTypes: AppointmentTypesTypes.AppointmentType[]) => {
      set({ defaultAppointmentTypes: appointmentTypes });
    },
    resetFiltersData: () => {
      set({
        selectedProvidersList: [],
        selectedAppointmentTypesList: [],
        selectedInsuranceVerificationStatusList: [],
        isUnConfirmationStatusSelected: false,
        filteredAppointmentList: [],
        isFiltersApplied: false,
      });
    },
  }),
  {
    name: 'useCalendarHeaderFilterStore',
  }
);

export const useCalendarHeaderFilterShallowStore =
  createShallowStore<CalendarHeaderFilterStore>(useCalendarHeaderFilterStore);
