import { useState, useCallback } from 'react';
import { useInterval } from '@frontend/design-system';
import { useGlobalBannerStore } from '../store';
import type { Banner } from '../types';
import { GlobalBannerBase } from './global-banner-base';
import { GlobalBannerPagination } from './global-banner-pagination';
import { BannerContainer } from './shared/banner-container';
import { BannerContentWrapper } from './shared/banner-content-wrapper';

interface UseBannerNavigationProps {
  totalBanners: number;
  isEnabled?: boolean;
  intervalDuration?: number;
}

const ONE_MINUTE_IN_MILLISECONDS = 60_000;

const useBannerNavigation = ({
  totalBanners,
  isEnabled = true,
  intervalDuration = ONE_MINUTE_IN_MILLISECONDS,
}: UseBannerNavigationProps) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [enableTimer, setEnableTimer] = useState(true);

  const realCurrentIndex = Math.min(currentIndex, totalBanners - 1);
  const hasMultipleBanners = totalBanners > 1;

  const reset = useInterval(
    () => {
      setCurrentIndex((current) => (current + 1) % totalBanners);
    },
    intervalDuration,
    hasMultipleBanners && isEnabled && enableTimer
  );

  const handleEnableTimer = useCallback(() => setEnableTimer(true), []);
  const handleDisableTimer = useCallback(() => setEnableTimer(false), []);

  const onIndicatorSelect = useCallback(
    (index: number) => {
      reset();
      setCurrentIndex(index);
    },
    [reset]
  );

  const handleNavigation = useCallback(
    (direction: 'next' | 'previous') => {
      const offset = direction === 'next' ? 1 : -1;
      reset();
      setCurrentIndex((current) => (current + offset + totalBanners) % totalBanners);
    },
    [totalBanners, reset]
  );

  return {
    currentIndex: realCurrentIndex,
    hasMultipleBanners,
    onIndicatorSelect,
    handleNavigation,
    handleEnableTimer,
    handleDisableTimer,
  };
};

interface BannerRendererProps {
  banner: Banner;
  onModalClose?: () => void;
  onModalOpen?: () => void;
}

const BannerRenderer = ({ banner, onModalClose, onModalOpen }: BannerRendererProps) => (
  <BannerContentWrapper>
    <GlobalBannerBase {...banner} onModalClose={onModalClose} onModalOpen={onModalOpen} />
  </BannerContentWrapper>
);

export const GlobalBannerManager = () => {
  const { banners } = useGlobalBannerStore(['banners']);

  const {
    currentIndex,
    hasMultipleBanners,
    onIndicatorSelect,
    handleNavigation,
    handleEnableTimer,
    handleDisableTimer,
  } = useBannerNavigation({
    totalBanners: banners.length,
  });

  const currentBanner = banners[currentIndex];

  if (!banners.length) return null;

  return (
    <BannerContainer bannerType={currentBanner?.type}>
      <BannerRenderer banner={currentBanner} onModalClose={handleEnableTimer} onModalOpen={handleDisableTimer} />
      <GlobalBannerPagination
        show={hasMultipleBanners}
        currentIndex={currentIndex}
        total={banners.length}
        onSelect={onIndicatorSelect}
        onNavigate={handleNavigation}
      />
    </BannerContainer>
  );
};
