import { forwardRef, LegacyRef } from 'react';
import { css } from '@emotion/react';
import { useMutation } from 'react-query';
import { DataSourcesHooks } from '@frontend/api-data-sources';
import { PatientPortalApi } from '@frontend/api-patient-portal';
import { PersonHelpers } from '@frontend/api-person';
import { PetQueries, PetTypes } from '@frontend/api-pet';
import { PetListAction, useSendPetReport } from '@frontend/contact-actions-service-report';
import { useTranslation } from '@frontend/i18n';
import { useTranslationTokens } from '@frontend/i18n-tokens';
import { Icon } from '@frontend/icons';
import { useContactPanelShallowStore, useFeatureFlagShallowStore } from '@frontend/shared';
import { theme } from '@frontend/theme';
import {
  Button,
  PopoverMenu,
  SpinningLoader,
  Text,
  TextButton,
  useAlert,
  usePopoverMenu,
} from '@frontend/design-system';
import { ContactDetails } from '../../contact-details/contact-details';
import { ContactDetailsHeader } from '../../contact-details/contact-details-header';
import { CustomerDetailsProps } from '../customer-details';
import { PetList } from './pet-list';

export const PetOverview = ({ data, locationId }: CustomerDetailsProps) => {
  const tokens = useTranslationTokens();
  const { flagValues } = useFeatureFlagShallowStore('flagValues');
  const hasPetServiceReport = flagValues?.['pet-service-report'];
  const { data: petsData = [], isLoading: petsIsLoading } = PetQueries.useGetPersonPets({
    personId: data?.PersonID || '',
  });
  const { openModal, SendInThreadModal } = useSendPetReport({
    locationId: locationId || '',
    mobilePhone: data?.MobilePhone || '',
    personId: data?.PersonID || '',
    petOwnerName: PersonHelpers.getFullName({ FirstName: data?.FirstName, LastName: data?.LastName }),
  });
  const { getTriggerProps, getMenuProps, getItemProps } = usePopoverMenu({
    placement: 'left-start',
    middlewareOptions: { offset: 0 },
  });

  const magicLinkFeatureFlag = flagValues['generate-patient-portal-magic-link'];

  if (petsIsLoading) {
    return (
      <ContactDetails>
        <section
          css={css`
            padding-bottom: ${theme.spacing(2)};
          `}
        >
          <ContactDetailsHeader title={tokens.household} css={{ padding: theme.spacing(2, 2, 0, 2) }} />
          <SpinningLoader size='medium' css={{ margin: 'auto', display: 'flex', justifyContent: 'center' }} />
        </section>
      </ContactDetails>
    );
  }

  if (!petsData?.length) return null;

  return (
    <ContactDetails>
      <section css={{ paddingBottom: theme.spacing(2) }}>
        {hasPetServiceReport ? (
          <>
            <ContactDetailsHeader
              title={tokens.household}
              css={{ padding: theme.spacing(2, 2, 0, 2) }}
              customAction={
                petsData?.length === 1 ? (
                  <SinglePetAction pet={petsData[0]} openModal={openModal} />
                ) : (
                  <CreateServiceReportButton {...getTriggerProps()} />
                )
              }
            />
            {SendInThreadModal}
            <PopoverMenu {...getMenuProps()}>
              {petsData?.map((pet, index) => (
                <PetListAction
                  key={pet.petId}
                  pet={pet}
                  index={index}
                  getItemProps={getItemProps}
                  trackingId='contact-panel-waterfall-create-pet-report'
                  handleClick={openModal}
                />
              ))}
            </PopoverMenu>
          </>
        ) : (
          <ContactDetailsHeader title={tokens.household} css={{ padding: theme.spacing(2, 2, 0, 2) }} />
        )}
        {magicLinkFeatureFlag && <GenerateMagicLink data={data} locationId={locationId} />}
        <PetList petsData={petsData} />
      </section>
    </ContactDetails>
  );
};

const GenerateMagicLink = ({ data, locationId }: CustomerDetailsProps) => {
  const alert = useAlert();
  const { t } = useTranslation('contacts');

  const { demoSourceIds } = DataSourcesHooks.useDemoLocationSourceIdsShallowStore('demoSourceIds');

  const generateMagicLinkMutation = useMutation({
    mutationFn: () =>
      PatientPortalApi.generatePortalMagicLink({
        personId: data?.PersonID ?? '',
        // if this gets put in front of customers, we will need to refine this logic
        locationId: locationId ?? demoSourceIds?.[0] ?? '',
      }),
    onSuccess: () => {
      alert.success(t(`Successfully sent portal login to ${PersonHelpers.getFullName(data)}.`));
    },
    onError: () => {
      alert.error(t(`Unable to send portal login to ${PersonHelpers.getFullName(data)}.`));
    },
  });

  return (
    <Button
      variant='secondary'
      css={{ margin: theme.spacing(1) }}
      onClick={() => {
        if (data?.Email === '') {
          alert.error(t('No email address to send to.'));
          return;
        } else {
          generateMagicLinkMutation.mutate();
        }
      }}
    >
      Generate Portal Link
    </Button>
  );
};

const SinglePetAction = ({
  pet,
  openModal,
}: {
  pet: PetTypes.Pet;
  openModal: ReturnType<typeof useSendPetReport>['openModal'];
}) => {
  const { data: petExtended } = PetQueries.useGetPetByPetId(pet.petId || '');
  return (
    <CreateServiceReportButton
      onClick={() => {
        if (!petExtended) return;
        openModal(petExtended);
      }}
    />
  );
};

const CreateServiceReportButton = forwardRef(
  (
    { onClick, ...rest }: { onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void },
    ref: LegacyRef<HTMLButtonElement>
  ) => {
    const { t } = useTranslation();
    const { personLocationId } = useContactPanelShallowStore('personLocationId');
    const alerts = useAlert();
    return (
      <TextButton
        onClick={(e) => {
          if (!personLocationId) {
            alerts.info(t('No locationId selected.'));
            return;
          }
          onClick(e);
        }}
        {...rest}
        ref={ref}
        css={{ display: 'flex', alignItems: 'center', gap: theme.spacing(1) }}
        trackingId='contact-panel-generate-pet-service-report'
      >
        <Icon name='list-small' color='primary' />
        <Text as='span' weight='bold' size='medium' color='primary'>
          {t('Create Service Report')}
        </Text>
      </TextButton>
    );
  }
);
