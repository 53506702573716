import { ReactNode } from 'react';
import { css } from '@emotion/react';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { PopoverDialog, Text, usePopoverDialog } from '@frontend/design-system';

interface Props {
  children: ReactNode;
  breakpoint: number;
  hideBorder?: boolean;
}

export const ExpansionButton = ({ children, breakpoint, hideBorder }: Props) => {
  const { getTriggerProps, getDialogProps, isOpen } = usePopoverDialog({
    placement: 'top-start',
    middlewareOptions: {
      offset: {
        crossAxis: 0,
        mainAxis: -5,
      },
    },
  });

  return (
    <>
      <Text
        css={css`
          display: flex;
          width: auto;
          min-width: ${theme.spacing(3.5)};
          max-width: none;
          height: 24px;
          font-weight: ${theme.font.weight.regular};
          font-size: ${theme.font.size.small};
          text-align: left;
          justify-content: space-between;
          align-items: center;
          border-radius: ${theme.borderRadius.small};
          background-color: ${theme.colors.white};
          padding: ${theme.spacing(0, 1)};
          gap: ${theme.spacing(0.5)};
          ${!hideBorder && `box-shadow: ${theme.shadows.floating};`}

          &:hover,
          &:focus {
            background-color: ${theme.colors.neutral10};
          }

          @media (min-width: ${breakpoint + 1}px) {
            display: none;
          }
        `}
        {...getTriggerProps()}
      >
        <Icon
          name='caret-down'
          size={16}
          css={css`
            transform: ${isOpen ? 'rotate(180deg)' : 'rotate(0deg)'};
            transition: transform 0.2s ease;
          `}
        />
      </Text>
      <PopoverDialog
        {...getDialogProps()}
        css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: ${theme.spacing(1.5, 0.5)};
          border-radius: ${theme.borderRadius.small};
          button {
            box-shadow: none;
            margin-right: 0;
            width: 100%;
            &:not(:last-of-type) {
              margin-bottom: ${theme.spacing(1)};
            }
            .alignment-icon {
              margin-right: 0;
            }
            .chevron-arrow {
              display: none;
            }
          }
        `}
      >
        {children}
      </PopoverDialog>
    </>
  );
};
