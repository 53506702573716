import { FC, useEffect, useMemo, useState } from 'react';
import { BarChartAppearance } from '@frontend/charts';
import { useHasFeatureFlag } from '@frontend/shared';
import { featureFlags } from '../../feature-flags';
import { useLocations } from '../../hooks';
import { ExportUtils } from '../../utils';
import {
  CallAnswerRateChart,
  HourlyInsightsChart,
  IncomingCallVolumeBreakdownChart,
  PhoneExtensionChart,
  TotalCallVolumeChart,
} from './charts';
import { usePhoneAnalyticsShallowStore } from './hooks';

interface Props {
  defaultChartAppearance?: Partial<BarChartAppearance>;
  onFetchStateChange?: (isFetching?: boolean) => void;
}

type ChartLoadingState = {
  callAnswerRate?: boolean;
  hourlyInsights?: boolean;
  incomingCallVolumeBreakdown?: boolean;
  phoneExtension?: boolean;
  totalCallVolume?: boolean;
};

export const CallAnalyticsPanel: FC<React.PropsWithChildren<Props>> = ({
  defaultChartAppearance,
  onFetchStateChange,
}) => {
  const { filters } = usePhoneAnalyticsShallowStore('filters');
  const [chartLoadingState, setChartLoadingState] = useState<ChartLoadingState>({});
  const isPhoneExtensionChartEnabled = useHasFeatureFlag(featureFlags.phoneAnalyticsExtension);
  const { locations } = useLocations();
  const isOpenHoursOnly = filters.open_office;

  const exportPdfProps = useMemo(
    () => ExportUtils.exportChartToPdfProps(filters.location_id || [], locations),
    [filters.location_id, locations]
  );

  const handleChartLoadingStateChange = (chartName: keyof ChartLoadingState, isLoading?: boolean) => {
    setChartLoadingState((prevState) => ({
      ...prevState,
      [chartName]: isLoading,
    }));
  };

  const commonProps = {
    defaultChartAppearance,
    exportPdfProps,
  };

  useEffect(() => {
    if (onFetchStateChange) {
      const isFetching = Object.values(chartLoadingState).some((isLoading) => isLoading);
      onFetchStateChange(isFetching);
    }
  }, [chartLoadingState]);

  return (
    <>
      <CallAnswerRateChart
        {...commonProps}
        onFetchStateChange={(value) => handleChartLoadingStateChange('callAnswerRate', value)}
      />

      <TotalCallVolumeChart
        {...commonProps}
        onFetchStateChange={(value) => handleChartLoadingStateChange('totalCallVolume', value)}
      />

      <IncomingCallVolumeBreakdownChart
        {...commonProps}
        onFetchStateChange={(value) => handleChartLoadingStateChange('incomingCallVolumeBreakdown', value)}
      />

      {isPhoneExtensionChartEnabled && (
        <PhoneExtensionChart
          {...commonProps}
          onFetchStateChange={(value) => handleChartLoadingStateChange('phoneExtension', value)}
        />
      )}

      {!isOpenHoursOnly && (
        <HourlyInsightsChart
          {...commonProps}
          onFetchStateChange={(value) => handleChartLoadingStateChange('hourlyInsights', value)}
        />
      )}
    </>
  );
};
