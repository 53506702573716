import {
  Media,
  MediaCollectionName_Enum,
} from '@weave/schema-gen-ts/dist/schemas/messaging/media/public/v1/service.pb';
import Compressor from 'compressorjs';
import { useQueryClient } from 'react-query';
import { PublicMediaMutations } from '@frontend/api-public-media';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { FileUpload, Text } from '@frontend/design-system';

const MAX_FILE_SIZE = 2 * 1000 * 1000; // 2MB

export const ImageUploader = ({
  imageSize,
  onImageUpload,
  queryKeys,
  selectedLocationIds,
}: {
  imageSize?: 'small' | 'large';
  onImageUpload: (newImage: Media) => void;
  queryKeys: PublicMediaMutations.QueryKeys;
  selectedLocationIds: string[];
}) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation('messages');

  const { mutateAsync: uploadMutation } = PublicMediaMutations.useUploadMedia({
    onMutate: ({ data, filename, selectedLocationIds }) => {
      queryClient.cancelQueries(queryKeys);
      const url = URL.createObjectURL(data);
      const previousMedia = queryClient.getQueryData<PublicMediaMutations.InfiniteMediaData>(queryKeys);
      queryClient.setQueryData(queryKeys, () => {
        const newMedia = { url, fileName: filename, businessGroupIds: selectedLocationIds };
        // there should only ever be one page of media at a time
        const firstPage = previousMedia?.pages?.[0];
        const otherMedia = firstPage?.media || [];
        return {
          ...previousMedia,
          pages: [{ ...firstPage, media: [newMedia, ...otherMedia] }],
        };
      });
      return { previousMedia, url };
    },
    onError: (_err, _req, context) => {
      const previousMedia = context?.previousMedia;
      queryClient.setQueryData(queryKeys, previousMedia);
    },
    onSettled: (res, _err, _req, context) => {
      queryClient.setQueryData<PublicMediaMutations.InfiniteMediaData>(queryKeys, (prev) => {
        const url = context?.url;
        // there should only ever be one page of media at a time
        const firstPage = prev?.pages?.[0];
        const newMedia = res
          ? firstPage?.media.map((media) => (media.url === url ? res : media))
          : firstPage?.media || [];
        return {
          ...(prev ?? {}),
          pages: [{ ...firstPage, media: newMedia }],
        } as PublicMediaMutations.InfiniteMediaData;
      });
      if (res) onImageUpload(res);
    },
  });

  const compressFile = async (file: File) => {
    return await new Promise((resolve: (value: File) => void, reject) => {
      new Compressor(file, {
        success: resolve,
        error: reject,
      });
    });
  };

  return (
    <FileUpload
      acceptedFileType='image'
      helperText={
        <>
          <Text size='small' weight='bold' css={{ marginBottom: theme.spacing(0.5) }}>
            {t('Upload Image')}
          </Text>
          <Text size='small' css={{ textAlign: 'center' }}>
            {t('JPG, PNG & SVG only - Files over 2MB will be compressed')}
          </Text>
        </>
      }
      keepPreviouslySelected={false}
      maxFileSize={MAX_FILE_SIZE}
      onFileProcess={async (file: File) => (file.size > MAX_FILE_SIZE ? await compressFile(file) : file)}
      css={{
        width: imageSize === 'large' ? 200 : 152,
        height: imageSize === 'large' ? 200 : 152,
        '#drop-area': {
          marginBottom: 0,
          padding: theme.spacing(2, 1),
        },
        label: {
          marginTop: theme.spacing(0.5),
          padding: theme.spacing(0.5, 1.5),
          fontWeight: theme.font.weight.bold,
        },
        svg: {
          display: 'none',
        },
        // hide the image preview container so it's less confusing to the user
        '.image-preview-container': { display: 'none' },
      }}
      onFileUpload={async (files) => {
        const file = files[0];
        if (!file) return;
        // converts the file to a blob which is needed for the upload media mutation
        const newFile = await new Blob([new Uint8Array(await file.arrayBuffer())], { type: file.type });
        await uploadMutation({
          data: newFile,
          filename: file.name,
          selectedLocationIds,
          type: MediaCollectionName_Enum.IMAGE_GALLERY,
        });
      }}
    />
  );
};
