import { useEffect, useMemo, useState } from 'react';
import {
  CampaignType_Enum as CampaignType,
  GetUsageRequest,
} from '@weave/schema-gen-ts/dist/schemas/messaging/bulk/v2';
import { useQueryClient } from 'react-query';
import { BulkMessagingMutations, BulkMessagingQueries } from '@frontend/api-bulk-messaging';
import { MessagingEtlAmpQueries } from '@frontend/api-messaging-etl-amp';
import { useTranslation } from '@frontend/i18n';
import { MessageAllotmentsFilters, MessageAllotmentsFilterTypes } from '@frontend/message-allotments-filters';
import { useAppScopeStore } from '@frontend/scope';
import { SegmentedBarTypes } from '@frontend/segmented-bar';
import { theme } from '@frontend/theme';
import { ModalControlModalProps } from '@frontend/design-system';

export const MessageAllotmentsFiltersModal = ({
  campaignId,
  locationIds,
  modalProps,
  setAudienceCount,
}: {
  campaignId?: string;
  locationIds?: string[];
  modalProps: ModalControlModalProps;
  setAudienceCount: (count?: number) => void;
}) => {
  const { t } = useTranslation('bulk-messaging');
  const { accessibleLocationIds, selectedOrgId: orgId } = useAppScopeStore();
  const [dataWithLimit, setDataWithLimit] = useState<MessageAllotmentsFilterTypes.LocationUsageAndAllotmentWithLimit[]>(
    []
  );
  const { data: organizationData = [] } = MessagingEtlAmpQueries.useGetLocation({ orgId });
  const distinctParentLocationIds = [...new Set(organizationData?.map((location) => location.parentId))];
  const locationIdsWithoutParent = organizationData
    ?.filter((location) => !distinctParentLocationIds.includes(location.locationId))
    .map((location) => location.locationId);

  // importing the `useBulkFeatureAccess` hook from the bulk-messaging feature isn't going to work here - but this modal wouldn't be available if they can't access the feature so this might be fine
  const usageRequest: GetUsageRequest = {
    locationIds: locationIds ?? accessibleLocationIds,
    orgId,
    type: CampaignType.EMAIL,
    year: new Date().getFullYear(),
  };
  const usageMonth = new Date().getMonth() + 1;
  const queryKey = BulkMessagingQueries.keys.getUsage(usageRequest, usageMonth);

  const { data: usageData = {}, isFetched: usageDataIsFetched } = BulkMessagingQueries.useGetUsage(
    usageRequest,
    usageMonth
  );
  const filteredData = useMemo(
    () =>
      Object.entries(usageData).filter(([locationId, _locationUsage]) => locationIdsWithoutParent.includes(locationId)),
    [usageData, locationIdsWithoutParent.sort().join(',')]
  );

  const getUsageAndRemainingLimit = (item: MessageAllotmentsFilterTypes.LocationUsageAndAllotmentWithLimit) => {
    const totalUsed = (item.sent ?? 0) + (item.scheduled ?? 0) + (item.error ?? 0);
    const totalAvailable = (item.allotment ?? 0) - totalUsed;
    return { totalAvailable, totalUsed };
  };

  const { data: audience, isFetched: audienceIsFetched } = BulkMessagingQueries.useGetAudience({
    orgId,
    campaignId: campaignId ?? '',
  });

  const getContactCount = (locationId: string) =>
    audience?.counts?.find((location) => location.locationId === locationId)?.count ?? 0;

  useEffect(() => {
    if (!audience || !filteredData.length) return;
    if (audienceIsFetched && usageDataIsFetched) {
      setDataWithLimit(
        filteredData.map(([locationId, locationUsage]) => {
          const monthUsage = locationUsage.usage[0];
          const limit = audience?.counts?.find((location) => location.locationId === locationId)?.count ?? 0;
          return {
            locationId,
            month: monthUsage.month,
            allotment: monthUsage.allotment,
            sent: monthUsage.sent,
            scheduled: monthUsage.scheduled,
            error: monthUsage.error,
            limit,
          };
        })
      );
    }
  }, [filteredData, audience?.counts]);

  const queryClient = useQueryClient();
  const { mutate: limitAudience } = BulkMessagingMutations.useLimitAudience({
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
    },
  });

  const chartData = dataWithLimit.reduce<Record<string, SegmentedBarTypes.Data[]>>((acc, item) => {
    const { totalAvailable, totalUsed } = getUsageAndRemainingLimit(item);
    if (!item.locationId) return acc;
    return {
      ...acc,
      [item.locationId]: [
        {
          data: totalUsed,
          label: t('To be used in this send'),
          color: theme.colors.secondary.eggplant40,
          hoverLabel: t('{{messageCount}} Used by This Send', { messageCount: totalUsed }),
        },
        {
          data: totalAvailable,
          label: t('Remaining in allotment'),
          color: theme.colors.neutral20,
          hoverLabel: t('{{messageCount}} Available', { messageCount: totalAvailable }),
          isRemaining: true,
        },
      ],
    };
  }, {});

  return (
    <MessageAllotmentsFilters
      audienceCount={audience?.counts}
      chartData={chartData}
      data={dataWithLimit}
      isLoading={!audienceIsFetched || !usageDataIsFetched}
      modalProps={modalProps}
      onBack={modalProps.onClose}
      onSave={() => {
        if (campaignId) {
          const limits = dataWithLimit.map((item) => {
            const contactCount = getContactCount(item.locationId!);
            const { totalAvailable } = getUsageAndRemainingLimit(item);
            const count = item.limit ?? 0;
            const exceedsContactCount = count > contactCount && contactCount < totalAvailable;
            const exceedsTotalAvailable = count > totalAvailable;

            return {
              locationId: item.locationId ?? '',
              count: exceedsContactCount ? contactCount : exceedsTotalAvailable ? totalAvailable : count,
            };
          });
          limitAudience({ orgId, campaignId, limits });
          setAudienceCount(limits.reduce((acc, item) => acc + (item.count ?? 0), 0));
        }
        modalProps.onClose();
      }}
      setData={setDataWithLimit}
    />
  );
};
