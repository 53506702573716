import { css } from '@emotion/react';
import { breakpoints } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';

export const bodyContainerStyle = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
`;

export const innerContainerStyle = css`
  display: flex;
  max-height: 600px;
  padding: ${theme.spacing(2)} 0;
  align-content: space-between;
  overflow: auto;

  @media (max-width: ${breakpoints.xsmall.max}px) {
    max-height: 300px;
  }
`;

export const sectionContainerStyle = css`
  display: flex;
  flex-flow: column;
  flex: 2 0 75%;
`;

export const sectionTitleStyle = css`
  margin-top: 0;
`;

export const fieldContainerStyle = css`
  display: flex;
  flex-flow: row wrap;
  column-gap: ${theme.spacing(2)};

  @media (max-width: ${breakpoints.xsmall.max}px) {
    justify-content: space-between;
  }
`;
