import { useEffect, useRef, useState, useCallback, useLayoutEffect } from 'react';
import { Media } from '@weave/schema-gen-ts/dist/schemas/messaging/media/public/v1/service.pb';
import ReactCrop, { PercentCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { cornersStyling } from '../../../styles';
import { Corners } from '../../../types';
import { defaultCrop, getImageCropStyles } from '../../../utils';
import { FloatingButton } from '../floating-toolbar';

export const ImageItem = ({
  image,
  corners,
  height,
  onReplace,
  onRemove,
  onCrop,
  selectedImage,
  selectImage,
  type = 'image',
  crop,
}: {
  image: Media;
  corners?: Corners;
  height?: string;
  onReplace: () => void;
  onRemove: () => void;
  onCrop: (crop: PercentCrop) => void;
  selectedImage: boolean;
  selectImage: () => void;
  type?: 'image' | 'logo';
  crop?: PercentCrop;
}) => {
  const [localCrop, setLocalCrop] = useState<PercentCrop | undefined>(crop);
  const { t } = useTranslation('content-composer');
  const imgRef = useRef<HTMLImageElement>(null);
  const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });
  const imgDimensionStyling = getImageCropStyles(imageDimensions.width, imageDimensions.height, localCrop);

  // Update the image dimensions and crop whenever the image height changes
  const updateCrop = useCallback(() => {
    if (imgRef.current) {
      const { width, height } = imgRef.current;
      setImageDimensions({ width, height });
    }
  }, [imageDimensions.height]);

  useEffect(() => {
    if (crop) {
      setLocalCrop(crop);
    }
  }, [crop]);

  useEffect(() => {
    setLocalCrop(defaultCrop);
  }, [image.url]);

  useLayoutEffect(() => {
    const imageElement = imgRef.current;
    if (!imageElement) return;

    const resizeObserver = new ResizeObserver(updateCrop);
    resizeObserver.observe(imageElement);

    return () => resizeObserver.disconnect();
  }, [updateCrop]);

  const replaceButton = (
    <FloatingButton onClick={onReplace} css={type === 'logo' && { width: 120 }}>
      {type === 'image' ? t('Replace') : t('Replace Logo')}
    </FloatingButton>
  );

  const cropButton = <FloatingButton onClick={() => localCrop && onCrop(localCrop)}>{t('Crop')}</FloatingButton>;

  //TODO: Take care for auto height changes with transition
  return (
    <div onClick={(e) => e.stopPropagation()}>
      <img
        ref={imgRef}
        src={image.url}
        alt={image.fileName}
        data-type='src-image'
        css={{
          height: height,
          visibility: 'hidden',
          position: 'absolute',
        }}
      />
      {selectedImage ? (
        <ReactCrop
          crop={localCrop}
          onChange={(_, percentageCrop) => setLocalCrop(percentageCrop)}
          css={{
            zIndex: theme.zIndex.low,
            display: 'block',
          }}
        >
          <img
            src={image.url}
            alt={image.fileName}
            css={[
              cornersStyling(corners),
              {
                height,
                border: `2px solid ${theme.colors.primary50}`,
                position: 'relative',
                transition: 'width 0.4s ease, height 0.4s ease',
              },
            ]}
          />
        </ReactCrop>
      ) : (
        <img
          src={image.url}
          alt={image.fileName}
          onClick={selectImage}
          css={[
            imgDimensionStyling,
            cornersStyling(corners),
            {
              border: 'none',
              height,
              transition: 'width 0.4s ease, height 0.4s ease',
            },
          ]}
        />
      )}
      {selectedImage && (
        <div
          css={{
            display: 'flex',
            position: 'absolute',
            bottom: 0,
            left: '50%',
            transform: 'translate(-50%, 100%)',
            zIndex: theme.zIndex.low,
            columnGap: theme.spacing(1),
          }}
        >
          {type === 'logo' && (
            <>
              {replaceButton}
              {selectedImage && cropButton}
            </>
          )}
          {type === 'image' && (
            <>
              {replaceButton}
              <FloatingButton onClick={onRemove}>{t('Remove')}</FloatingButton>
              {selectedImage && cropButton}
            </>
          )}
        </div>
      )}
    </div>
  );
};
