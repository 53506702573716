import { ListMediaRequest } from '@weave/schema-gen-ts/dist/schemas/messaging/media/public/v1/service.pb';
import { useInfiniteQuery } from 'react-query';
import { SchemaPublicMedia } from './service';

export const queryKeys = {
  base: 'public-media',
  listMedia: (request: ListMediaRequest) => [queryKeys.base, 'list-media', request],
  listSystemMedia: (request: ListMediaRequest) => [queryKeys.base, 'list-system-media', request],
};

const defaultOptions = {
  retry: false,
};

export const useListMedia = (request: ListMediaRequest) => {
  const queryKey = queryKeys.listMedia(request);
  return {
    ...useInfiniteQuery({
      ...defaultOptions,
      queryKey,
      queryFn: () => SchemaPublicMedia.ListMedia(request),
    }),
    queryKeys: queryKey,
  };
};

export const useListSystemMedia = (request: ListMediaRequest) => {
  const queryKey = queryKeys.listSystemMedia(request);
  return {
    ...useInfiniteQuery({
      ...defaultOptions,
      queryKey,
      queryFn: () => SchemaPublicMedia.ListSystemMedia(request),
    }),
    queryKeys: queryKey,
  };
};
