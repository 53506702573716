import { useCallback, useMemo } from 'react';
import { CategoryBarChartAppearance, CategoryBarChartData, Chart, TooltipData } from '@frontend/charts';
import { i18next, useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { formatters } from '../../../utils';
import { LocationChip } from '../../location-chip';
import { useFetchMissedCallTextConversionTotals } from '../hooks';

const commonColors = {
  messagesCount: theme.colors.primary50,
};

const commonLabels = {
  groupNameHeader: i18next.t('Location', { ns: 'analytics' }),
  messagesCount: i18next.t('Missed Call Texts Sent', { ns: 'analytics' }),
};

const appearance: CategoryBarChartAppearance = {
  alwaysApplyBarBorders: true,
  barBorderWidth: 0.75,
  barSize: 40,
  customTooltipTitle: ({ groupName }) => <LocationChip locationName={groupName} noTruncating />,
  groupsGap: 36,
  labelConfig: {
    customStyles: () => ({
      dy: theme.spacing(1),
      fill: theme.font.colors.default,
      fontSize: theme.font.size.h2,
      fontWeight: theme.font.weight.bold,
    }),
    distanceFromBar: 16,
    placement: 'end',
    show: true,
  },
  margin: {
    right: 90,
  },
  patterns: {
    messagesCount: {
      name: 'gradient',
      colors: {
        start: theme.colors.primary50,
        end: '#9EC5FF', // Color is not defined in the theme
      },
      degrees: 270,
    },
  },
};

interface Props extends ReturnType<typeof useFetchMissedCallTextConversionTotals> {
  isPracticeValueConversion?: boolean;
}

export const MissedCallTextMultiConversionComparisonChart = ({ data, isLoading, isPracticeValueConversion }: Props) => {
  const { t } = useTranslation('analytics');

  const colors = {
    ...commonColors,
    appointmentsCount: isPracticeValueConversion ? theme.colors.success50 : theme.colors.tangerine40,
  };

  const labels = {
    ...commonLabels,
    appointmentsCount: isPracticeValueConversion ? t('Appointments Completed') : t('Appointments Scheduled'),
  };

  const chartData: CategoryBarChartData = useMemo(() => {
    return Object.entries(data?.details || {}).reduce((acc, [locationId, locationDetails]) => {
      return {
        ...acc,
        [locationDetails?.location_name || locationId]: {
          appointmentsCount: locationDetails?.appointments_count || 0,
          messagesCount: locationDetails?.messages_count || 0,
        },
      };
    }, {});
  }, [data]);

  const customTooltipData = useCallback(
    (groupId: string, categoryId: string): TooltipData[] => {
      return [
        {
          color: colors.appointmentsCount,
          formattedValue: formatters.value.format(chartData[groupId]?.appointmentsCount),
          id: categoryId,
          label: labels.appointmentsCount,
        },
        {
          color: colors.messagesCount,
          formattedValue: formatters.value.format(chartData[groupId]?.messagesCount),
          id: categoryId,
          label: labels.messagesCount,
        },
      ];
    },
    [chartData]
  );

  return (
    <Chart colors={colors} isLoading={isLoading} labels={labels}>
      <Chart.Header title={t('Appointment Conversion')} />
      <Chart.CategoryBarChart
        appearance={{
          ...appearance,
          customTooltipData,
          patterns: {
            ...appearance.patterns,
            appointmentsCount: {
              name: 'gradient',
              colors: isPracticeValueConversion
                ? {
                    start: theme.colors.success50,
                    end: theme.colors.success20,
                  }
                : {
                    start: theme.colors.tangerine40,
                    end: '#F7B47D', // Color is not defined in the theme
                  },
              degrees: 270,
            },
          },
        }}
        data={chartData}
        highestValueConfig={{
          categoryId: 'appointmentsCount',
          color: isPracticeValueConversion ? theme.colors.success60 : theme.colors.warning50,
          text: t('Highest Conversion'),
        }}
        sortConfig={{
          allowUserToSort: true,
        }}
        trackingIdBase='mct-conversion-comparison'
      >
        <Chart.Legends />
      </Chart.CategoryBarChart>
    </Chart>
  );
};
