import { useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import { PhoneAnalyticsTypes } from '@frontend/api-analytics';
import { BarChartData, Chart } from '@frontend/charts';
import { i18next, useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { useLocations } from '../../../hooks';
import { formatDateByTimezone, getHourlyInsightsLabel, toHHmmss } from '../../../utils';
import { DemoChip } from '../../demo-chip';
import { InfoTipPopover } from '../../info-tip-popover';
import { useFetchAggregatedCallsAndDurationReport, usePhoneAnalyticsShallowStore } from '../hooks';
import { ChartProps } from './shared-types';

const colors = {
  incoming: theme.colors.secondary.seaweed30,
  outgoing: theme.colors.warning50,
};

const labels = {
  incoming: i18next.t('Incoming', { ns: 'analytics' }),
  outgoing: i18next.t('Outgoing', { ns: 'analytics' }),
};

export const AverageCallDurationChart = ({
  defaultChartAppearance = {},
  exportPdfProps,
  onFetchStateChange,
}: ChartProps) => {
  const { t } = useTranslation('analytics');
  const { locations } = useLocations();
  const { filterHintText, filters, isDemoAccount } = usePhoneAnalyticsShallowStore(
    'filterHintText',
    'filters',
    'isDemoAccount'
  );
  const { data, isHourlyData, isLoading, isMultiLocation } =
    useFetchAggregatedCallsAndDurationReport<PhoneAnalyticsTypes.CallsAggregationKey>('call_direction');

  const chartData = useMemo(() => {
    let groups: BarChartData['groups'] = [];

    if (isMultiLocation) {
      // There will be multiple location objects with single/multiple dates
      groups = Object.entries(data?.aggregatedMultiLocations || {}).map(([key, { calls, duration }]) => {
        const incomingCalls = calls?.inbound || 0;
        const incomingDuration = duration?.inbound || 0;
        const outgoingCalls = calls?.outbound || 0;
        const outgoingDuration = duration?.outbound || 0;

        return {
          name: locations?.[key] || key,
          values: {
            incoming: incomingDuration / (incomingCalls || 1),
            outgoing: outgoingDuration / (outgoingCalls || 1),
          },
        };
      });
    } else {
      // For hourly data: There will be only one object with single date and hourly data for the given location
      // For daily data: There will be only one object with multiple dates for the given location
      const locationData = Object.values(data?.locations || {})[0] || {};

      if (isHourlyData) {
        // Hourly data is restricted to a single date, hence only one object
        const { inbound, outbound } = Object.values(locationData || {})[0] || {};
        const limitedIncomingCalls = inbound?.calls?.hourly || [];
        const limitedIncomingDuration = inbound?.duration?.hourly || [];
        const limitedOutgoingCalls = outbound?.calls?.hourly || [];
        const limitedOutgoingDuration = outbound?.duration?.hourly || [];

        groups = limitedIncomingCalls.map((incomingCalls, i) => ({
          name: getHourlyInsightsLabel(i),
          values: {
            incoming: limitedIncomingDuration[i] / (incomingCalls || 1),
            outgoing: limitedOutgoingDuration[i] / (limitedOutgoingCalls[i] || 1),
          },
        }));
      } else {
        groups = Object.entries(locationData).map(([date, { inbound, outbound }]) => {
          const incomingCalls = inbound?.calls?.daily || 0;
          const incomingDuration = inbound?.duration?.daily || 0;
          const outgoingCalls = outbound?.calls?.daily || 0;
          const outgoingDuration = outbound?.duration?.daily || 0;

          return {
            name: dayjs(date).isValid() ? formatDateByTimezone(date, filters.start_date, filters.time_zone) : date,
            values: {
              incoming: incomingDuration / (incomingCalls || 1),
              outgoing: outgoingDuration / (outgoingCalls || 1),
            },
          };
        });
      }
    }

    return {
      groups,
    };
  }, [data, filters, isHourlyData, isMultiLocation, locations]);

  useEffect(() => {
    onFetchStateChange?.(isLoading);
  }, [isLoading]);

  return (
    <Chart colors={colors} isLoading={isLoading} labels={labels}>
      <Chart.Header
        {...exportPdfProps}
        infoTip={
          <InfoTipPopover>
            {t(
              'Incoming vs Outgoing: Refers to the average duration of the incoming and outgoing calls. Value is in hh:mm:ss'
            )}
          </InfoTipPopover>
        }
        leftElement={isDemoAccount && <DemoChip />}
        subtitle={filterHintText}
        title={t('Average Call Duration')}
      />
      <Chart.Legends />
      <Chart.BarChart appearance={defaultChartAppearance} data={chartData} formatValue={(value) => toHHmmss(value)} />
    </Chart>
  );
};
