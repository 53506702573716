import { PortingTypes } from '@frontend/api-porting';
import { i18next } from '@frontend/i18n';

export const trackingIds = {
  portRequestsTab: 'port-requests_tab',
  infoIcon: 'port-requests_info_icon',
  closeRequestModal: 'port-requests_close_request_modal',
  phoneNumbersTab: 'phone-numbers_tab',
  submitPortRequest: 'ob_portal_phone-numbers_submit-port-request',
};

export const PORT_TYPE_TEXT_MAP: Record<PortingTypes.PortTypeEnum, string> = {
  [PortingTypes.PortTypeEnum.FULL_PORT]: i18next.t('Full Port', { ns: 'porting' }),
  [PortingTypes.PortTypeEnum.SMS_HOSTING]: i18next.t('SMS Hosting', { ns: 'porting' }),
};

export const PORT_TYPE_OPTIONS = Object.entries(PORT_TYPE_TEXT_MAP).map(([value, label]) => ({
  label,
  value,
}));

export enum PhoneNumbersPageTab {
  PhoneNumbers = 'phoneNumbers',
  PortRequests = 'portRequests',
}
