import { FieldMask } from '@weave/schema-gen-ts/dist/google/protobuf/field_mask.pb';
import { List, ListListsRequest } from '@weave/schema-gen-ts/dist/schemas/messaging/bulk/v2';
import { BulkMessagingMutations } from '@frontend/api-bulk-messaging';
import { useTranslation } from '@frontend/i18n';
import { BulkEmailPrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import { Button, Text, TextField, useFormField } from '@frontend/design-system';

type Props = {
  list: List;
  onRename: (newList: List) => void;
  queryKey: (string | ListListsRequest)[];
  setListIdToRename: (listId?: string) => void;
};

export const RenameListItem = ({ list, onRename, queryKey, setListIdToRename }: Props) => {
  const { t } = useTranslation('bulk-messaging');
  const textFieldProps = useFormField({ type: 'text', value: list.name }, [list.name]);

  const { mutate: updateList } = BulkMessagingMutations.useUpdateList(queryKey);

  return (
    <div
      css={{
        padding: theme.spacing(2),
        marginBottom: theme.spacing(1),
        border: `1px solid ${theme.colors.neutral10}`,
        borderRadius: theme.borderRadius.medium,
      }}
      data-trackingid={`${BulkEmailPrefixes.Audience}-contacts-list-item`}
    >
      <Text size='large' weight='bold' css={{ marginBottom: theme.spacing(2) }}>
        {t('Rename List')}
      </Text>
      <div>
        {/* TODO: Make this save when enter is pressed. Cancelled when escape is pressed. */}
        <TextField
          {...textFieldProps}
          name='list-name'
          label={t('Rename Name')}
          css={{ marginBottom: theme.spacing(1) }}
          autoFocus
        />
        <Button
          variant='secondary'
          css={{ marginRight: theme.spacing(2) }}
          size='small'
          onClick={() => setListIdToRename(undefined)}
        >
          {t('Cancel')}
        </Button>
        <Button
          size='small'
          disabled={!textFieldProps.value || textFieldProps.value === list.name}
          onClick={() => {
            const newList = { ...list, name: textFieldProps.value };
            updateList({
              list: newList,
              updateMask: new FieldMask({ paths: ['list.name'] }),
            });
            onRename(newList);
          }}
        >
          {t('Save')}
        </Button>
      </div>
    </div>
  );
};
