import { List } from '@weave/schema-gen-ts/dist/schemas/messaging/bulk/v2';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { BulkEmailPrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import { Button, Checkbox, ListRow, PopoverMenu, PopoverMenuItem, usePopoverMenu } from '@frontend/design-system';

type Props = {
  checked: boolean;
  disabled: boolean;
  list: List;
  onCheck: () => void;
  onDelete: () => void;
  setListIdToRename: (listId?: string) => void;
};

export const SavedListItem = ({ checked, disabled, list, onCheck, onDelete, setListIdToRename }: Props) => {
  const { t } = useTranslation('bulk-messaging');
  const { getTriggerProps, getMenuProps, getItemProps } = usePopoverMenu<HTMLButtonElement | HTMLAnchorElement>();

  const getBorderColor = () => {
    if (disabled) {
      return { border: `1px solid ${theme.colors.neutral20}` };
    }
    return {
      border: `1px solid ${checked ? theme.colors.primary50 : theme.colors.neutral10}`,
      ':hover': { borderColor: theme.colors.primary50 },
    };
  };

  return (
    <ListRow
      as='label'
      css={[
        getBorderColor(),
        {
          padding: theme.spacing(1, 2),
          marginBottom: theme.spacing(1),
          borderRadius: theme.borderRadius.medium,
          cursor: 'pointer',
          ':hover': { backgroundColor: 'transparent' },
        },
      ]}
      data-trackingid={`${BulkEmailPrefixes.Audience}-contacts-list-item`}
    >
      <ListRow.Lead>
        {/* @ts-ignore purposefully omitting all of the checkbox's props */}
        <Checkbox value={checked} onChange={() => onCheck()} name={list.listId ?? ''} disabled={disabled} />
      </ListRow.Lead>
      <ListRow.Content>
        <ListRow.Content.Title css={disabled && { color: theme.colors.neutral20 }}>
          {list.name ? list.name : t('Untitled List')}
        </ListRow.Content.Title>
        <ListRow.Content.Subtitle css={disabled && { color: theme.colors.neutral20 }}>
          {list.recipientCount === 1
            ? t('1 Contact')
            : t('{{contact}} Contacts', { contact: list.recipientCount ?? 0 })}
        </ListRow.Content.Subtitle>
      </ListRow.Content>
      <ListRow.Trail>
        {disabled ? (
          <Icon name='more' color='disabled' />
        ) : (
          <>
            <Button
              iconName='more'
              css={{ svg: { color: theme.colors.neutral90 } }}
              variant='tertiary'
              hoverLabel={t('Actions')}
              {...getTriggerProps()}
            />
            <PopoverMenu {...getMenuProps()}>
              <PopoverMenuItem
                Icon={() => <Icon name='edit' />}
                {...getItemProps({
                  index: 0,
                  onClick: () => setListIdToRename(list.listId),
                })}
                trackingId={`${BulkEmailPrefixes.Audience}-contact-list-rename-btn`}
              >
                {t('Rename List')}
              </PopoverMenuItem>
              <PopoverMenuItem
                Icon={() => <Icon name='trash' />}
                {...getItemProps({
                  index: 1,
                  onClick: () => onDelete(),
                })}
                trackingId={`${BulkEmailPrefixes.Audience}-contact-list-delete-btn`}
              >
                {t('Delete List')}
              </PopoverMenuItem>
            </PopoverMenu>
          </>
        )}
      </ListRow.Trail>
    </ListRow>
  );
};
