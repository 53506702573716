import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const innerContainerStyle = css`
  display: flex;
  margin-bottom: ${theme.spacing(2)};
`;

export const fieldsContainerStyle = css`
  margin-top: ${theme.spacing(2)};
  padding: ${theme.spacing(3)};
  padding-bottom: 0;
  border: 1px solid ${theme.colors.neutral20};
  border-radius: ${theme.borderRadius.medium};
`;

export const textStyle = css`
  margin-bottom: ${theme.spacing(2)};
`;

export const actionsContainerStyle = css`
  margin-top: ${theme.spacing(2)};
  display: flex;
  justify-content: flex-end;
`;
