import { useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import { PhoneAnalyticsTypes } from '@frontend/api-analytics';
import { BarChartData, Chart } from '@frontend/charts';
import { i18next, useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { useLocations } from '../../../hooks';
import { formatDateByTimezone, getHourlyInsightsLabel } from '../../../utils';
import { DemoChip } from '../../demo-chip';
import { InfoTipPopover } from '../../info-tip-popover';
import { useFetchAggregatedCallsAndDurationReport, usePhoneAnalyticsShallowStore } from '../hooks';
import { ChartProps } from './shared-types';

const colors = {
  abandoned: theme.colors.warning50,
  answered: theme.colors.secondary.seaweed30,
  missed: theme.colors.critical30,
};

const labels = {
  abandoned: i18next.t('Abandoned', { ns: 'analytics' }),
  answered: i18next.t('Answered', { ns: 'analytics' }),
  missed: i18next.t('Missed', { ns: 'analytics' }),
};

export const IncomingCallVolumeBreakdownChart = ({
  defaultChartAppearance = {},
  exportPdfProps,
  onFetchStateChange,
}: ChartProps) => {
  const { t } = useTranslation('analytics');
  const { locations } = useLocations();
  const { filterHintText, filters, isDemoAccount } = usePhoneAnalyticsShallowStore(
    'filterHintText',
    'filters',
    'isDemoAccount'
  );
  const { data, isHourlyData, isLoading, isMultiLocation } =
    useFetchAggregatedCallsAndDurationReport<PhoneAnalyticsTypes.IncomingCallsAggregationKey>('incoming_call_status');

  const chartData: BarChartData = useMemo(() => {
    let groups: BarChartData['groups'] = [];

    if (isMultiLocation) {
      // There will be multiple location objects with single/multiple dates
      groups = Object.entries(data?.aggregatedMultiLocations || {}).map(([key, { calls = {} }]) => {
        return {
          name: locations?.[key] || key,
          values: {
            abandoned: calls.abandoned || 0,
            answered: calls.answered || 0,
            missed: calls.missed || 0,
          },
        };
      });
    } else {
      // For hourly data: There will be only one object with single date and hourly data for the given location
      // For daily data: There will be only one object with multiple dates for the given location
      const locationData = Object.values(data?.locations || {})[0] || {};

      if (isHourlyData) {
        // Hourly data is restricted to a single date, hence only one object
        const { abandoned, answered, missed } = Object.values(locationData || {})[0] || {};
        const limitedAbandoned = abandoned?.calls?.hourly || [];
        const limitedAnswered = answered?.calls?.hourly || [];
        const limitedMissed = missed?.calls?.hourly || [];

        groups = limitedAbandoned.map((abandoned, i) => ({
          name: getHourlyInsightsLabel(i),
          values: {
            abandoned,
            answered: limitedAnswered[i],
            missed: limitedMissed[i],
          },
        }));
      } else {
        groups = Object.entries(locationData).map(([date, { abandoned, answered, missed }]) => {
          return {
            name: dayjs(date).isValid() ? formatDateByTimezone(date, filters.start_date, filters.time_zone) : date,
            values: {
              abandoned: abandoned?.calls?.daily || 0,
              answered: answered?.calls?.daily || 0,
              missed: missed?.calls?.daily || 0,
            },
          };
        });
      }
    }

    return {
      groups,
    };
  }, [data, filters, isHourlyData, isMultiLocation, locations]);

  useEffect(() => {
    onFetchStateChange?.(isLoading);
  }, [isLoading]);

  return (
    <Chart colors={colors} isLoading={isLoading} labels={labels}>
      <Chart.Header
        {...exportPdfProps}
        infoTip={<InfoTipPopover>{t('Tells the end result of the incoming calls')}</InfoTipPopover>}
        leftElement={isDemoAccount && <DemoChip />}
        subtitle={filterHintText}
        title={t('Incoming Call Breakdown')}
      />
      <Chart.Legends />
      <Chart.BarChart appearance={defaultChartAppearance} data={chartData} />
    </Chart>
  );
};
