import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { DataSourcesHooks } from '@frontend/api-data-sources';
import { ListAPI, ListTypes } from '@frontend/api-list';
import { InfinitePaginatedList } from '@frontend/components';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore, useScopedInfiniteQuery } from '@frontend/scope';
import { Text } from '@frontend/design-system';
import { ContactListRow } from './contact-list-row';

dayjs.extend(relativeTime);

const LIMIT = 25;

export const OverdueView = ({
  overdueFilter,
  clientLocationIds,
}: {
  overdueFilter: ListTypes.OverdueFilterKeys;
  clientLocationIds?: string[];
}) => {
  const { t } = useTranslation('contacts');
  const { demoSourceIds } = DataSourcesHooks.useDemoLocationSourceIdsShallowStore('demoSourceIds');
  const overduePatientsInfiniteQuery = useScopedInfiniteQuery({
    queryKey: ['overdue-patients-list', overdueFilter, ...demoSourceIds, ...(clientLocationIds || [])],
    queryFn: ({ pageParam }) =>
      ListAPI.getOverduePatients({
        skip: pageParam,
        limit: LIMIT,
        filter: overdueFilter,
        dataSourceIds: demoSourceIds,
        clientLocationIds,
      }),
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.rows.length < LIMIT) return undefined;
      return pages.length * LIMIT;
    },
  });

  const { selectedLocationIds, getLocationName } = useAppScopeStore();
  const { getLocationsByDatasource } = DataSourcesHooks.useDatasourceToLocations(selectedLocationIds, getLocationName);

  return (
    <InfinitePaginatedList
      infiniteQueryProps={overduePatientsInfiniteQuery}
      renderListItem={({ listItem }) => (
        <ContactListRow
          listItem={{
            Gender: listItem.patient.gender,
            Birthdate: listItem.patient.birthdate,
            ClientLocationID: listItem.patient.client_location_id,
            ClientLocationName: listItem.patient.client_location_name,
            FirstName: listItem.patient.first_name,
            HomePhone: listItem.patient.phone_home,
            LastName: listItem.patient.last_name,
            MobilePhone: listItem.patient.phone_mobile,
            PersonID: listItem.patient.person_id,
            Status: listItem.patient.status,
            SourceType: undefined,
            Accounts: '',
            Address: {
              City: listItem.patient.address.city,
              State: listItem.patient.address.state,
              Street: listItem.patient.address.state,
              Zip: listItem.patient.address.zip,
            },
            Appointments: [],
            ContactInfo: {},
            Email: '',
            Notes: '',
            OtherContactInfo: '',
            PMDisplayID: '',
            PMID: '',
            Preferences: {},
            PreferredName: '',
            Recall: [],
            SourceID: '',
            SourceName: '',
            WorkPhone: '',
            WeaveHouseholdID: '',
            Department: '',
            ReceiveSMS: true,
          }}
          getLocationsByDatasource={getLocationsByDatasource}
        >
          <Text color='light'>
            {t(`Overdue: {{overdueTime}}`, {
              overdueTime: dayjs(listItem.due_for_cc).fromNow(),
            })}
          </Text>
        </ContactListRow>
      )}
    />
  );
};
