import { useEffect } from 'react';
import { capitalize, isEqual } from 'lodash-es';
import { PhoneAnalyticsTypes } from '@frontend/api-analytics';
import { TFunction, useTranslation } from '@frontend/i18n';
import { ContentLoader, FormRow, MultiselectField, TableFilters, useFormField } from '@frontend/design-system';
import { useFetchPhoneFiltersOptions } from './hooks';

interface Props {
  disabled?: boolean;
  filters: PhoneAnalyticsTypes.Filters;
  onChange: (args: Partial<PhoneAnalyticsTypes.Filters>) => void;
}

const renderMultiSelectOptions = (options: string[], t: TFunction<'analytics', undefined>) => {
  const getHumanizedValue = (type: string) => {
    if (type === 'inbound') return t('Incoming');
    else if (type === 'outbound') return t('Outgoing');
    else return undefined;
  };

  return options.sort().map((type) => (
    <MultiselectField.Option key={type} value={type}>
      {getHumanizedValue(type) || capitalize(type.replaceAll('_', ' '))}
    </MultiselectField.Option>
  ));
};

export const AdditionalPhoneFilters = ({ disabled, filters, onChange }: Props) => {
  const { t } = useTranslation('analytics');
  const { filterOptionsValues, isLoading } = useFetchPhoneFiltersOptions();

  const callTypeFieldProps = useFormField(
    {
      type: 'multiselect',
      value: filters.call_type,
    },
    [filters.call_type]
  );

  const resultFieldProps = useFormField(
    {
      type: 'multiselect',
      value: filters.result,
    },
    [filters.result]
  );

  const sourceTypeFieldProps = useFormField(
    {
      type: 'multiselect',
      value: filters.source_type,
    },
    [filters.source_type]
  );

  useEffect(() => {
    if (!isEqual(callTypeFieldProps.value.sort(), filters.call_type?.sort())) {
      onChange({ call_type: callTypeFieldProps.value });
    }
  }, [callTypeFieldProps.value]);

  useEffect(() => {
    if (!isEqual(resultFieldProps.value.sort(), filters.result?.sort())) {
      onChange({ result: resultFieldProps.value });
    }
  }, [resultFieldProps.value]);

  useEffect(() => {
    if (!isEqual(sourceTypeFieldProps.value.sort(), filters.source_type?.sort())) {
      onChange({ source_type: sourceTypeFieldProps.value });
    }
  }, [sourceTypeFieldProps.value]);

  return (
    <TableFilters.Section sectionHeaderLabel={t('Filter by types')}>
      <FormRow>
        <MultiselectField {...sourceTypeFieldProps} disabled={disabled} label={t('Source Type')} name='SourceType'>
          {renderMultiSelectOptions(filterOptionsValues?.SourceType || [], t)}
        </MultiselectField>
      </FormRow>

      <FormRow>
        <MultiselectField {...callTypeFieldProps} disabled={disabled} label={t('Call Type')} name='CallType'>
          {renderMultiSelectOptions(filterOptionsValues?.CallType || [], t)}
        </MultiselectField>
      </FormRow>

      <FormRow>
        <MultiselectField {...resultFieldProps} disabled={disabled} label={t('Result')} name='Result'>
          {renderMultiSelectOptions(filterOptionsValues?.Result || [], t)}
        </MultiselectField>
      </FormRow>
      <ContentLoader show={isLoading} />
    </TableFilters.Section>
  );
};
