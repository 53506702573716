import React, { ElementRef, useRef } from 'react';
import { css, SerializedStyles } from '@emotion/react';
import { motion } from 'framer-motion';
import useMeasure from 'react-use-measure';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { breakpoints, useMatchMedia } from '@frontend/responsiveness';
import { ShellColorTheme, useShellTheme } from '@frontend/shell-theme';
import { useShell } from '@frontend/shell-utils';
import { theme } from '@frontend/theme';
import {
  IconButton,
  SearchField,
  PopoverDialog,
  usePopoverDialog,
  useControlledField,
  NakedButton,
  useDebouncedFn,
  useEventListener,
  Button,
} from '@frontend/design-system';
import { GlobalSearchTrackingIds } from './constants';
import { RecentSearches } from './molecules';
import { closeGlobalSearchEvent, useGlobalSearch } from './provider';
import { useGlobalRecentSearchesShallowStore } from './stores';

const sidePadding = 5;
const addedWidth = sidePadding * 2;

type GlobalSearchMainProps = {
  children: React.ReactNode;
  isWeaveApp?: boolean;
  className?: string;
};

export const TOP_BAR_HEIGHT = 72;

type SearchStylesBaseProps = {
  isXSmall: boolean;
  isLarge: boolean;
  isWindows: boolean;
  isExpandedView: boolean;
  isWeaveApp: boolean;
  colorObject: ShellColorTheme;
};

type SearchStylesProps = SearchStylesBaseProps & {
  containerWidth: number;
  containerLeft: number;
  containerHeight: number;
};

function getDefaultSearchStyles({
  isXSmall,
  isLarge,
  containerLeft,
  containerWidth,
  containerHeight,
  isExpandedView,
  isWeaveApp,
  colorObject,
}: SearchStylesProps) {
  const dialogWidth = containerWidth + addedWidth;
  const dialogTop = isExpandedView ? 4 : isLarge ? 4 : containerHeight + 2;

  const containerCss = [
    css`
      flex-shrink: 1;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    `,
    isLarge &&
      css`
        flex-grow: 1;
        max-width: 518px;
      `,
    isXSmall &&
      css`
        height: 100%;
      `,
  ];

  const dialogCss = [
    css`
      width: ${containerWidth + addedWidth}px;
      min-width: ${!isLarge ? 'min(100% - 30px, 520px)' : '440px'};
      padding: 8px 5px 16px 5px;
      border-radius: 16px;
      box-shadow: ${theme.shadows.light};
      overflow: hidden;
    `,
    isExpandedView &&
      css`
        width: 100%;
        height: 100%;
      `,
  ];

  const dialogSearchCss = [
    css`
      height: 48px;
      width: 100%;
      border-radius: ${theme.borderRadius.medium};
      border: 1px solid ${theme.colors.neutral20};
      box-shadow: none !important;
      outline: none;
    `,
  ] as (SerializedStyles | false)[];

  const dialogSearchContainerCss = [
    css`
      background: ${theme.colors.white};
      border-radius: ${theme.borderRadius.medium};
      transition: 0.2s linear;
      caret-color: ${theme.colors.neutral50};
      :focus-within {
        background: ${theme.colors.neutral10};
      }

      & input {
        color: ${theme.colors.neutral90};
      }
      & input::placeholder {
        color: ${theme.colors.neutral50};
      }

      button {
        width: 40px;
        height: 40px;
      }

      svg {
        width: 24px;
        height: 24px;
        color: ${theme.colors.neutral50};
      }
    `,
  ] as (SerializedStyles | false)[];

  return {
    dialogOffsetMainAxis: -56,
    dialogLeftPosition: isExpandedView
      ? 0
      : isLarge
      ? containerLeft - sidePadding
      : `calc((100% - min(100% - 30px, 520px)) / 2)`,
    dialogWidth,
    dialogAnimatedWidth: isExpandedView ? '100%' : dialogWidth,
    dialogTop,
    dialogAnimatedTop: dialogTop,
    dialogHeight: isExpandedView ? '100%' : 'auto',
    containerCss,
    buttonCss: (_isOpen: boolean) =>
      [
        css`
          height: 100%;
          min-width: 56px;
          border-radius: 0;
          width: 56px;

          :hover {
            background: ${isWeaveApp ? theme.colors.neutral5 : theme.colors.neutral80};
          }
        `,
      ] as (SerializedStyles | false)[],
    searchFieldCss: (isOpen: boolean) =>
      [
        css`
          height: 48px;
          width: 100%;
          max-width: 518px;
          border-radius: ${theme.borderRadius.medium};
          border: 1px solid ${theme.colors.neutral20};
          :focus {
            border: 1px solid ${theme.colors.primary20};
          }
          transition: 0.3s ease;
        `,
        isOpen &&
          css`
            opacity: 0;
          `,
      ] as (SerializedStyles | false)[],
    searchFieldContainerCss: (isOpen: boolean) =>
      [
        css`
          background: ${theme.colors.white};
          border-radius: ${theme.borderRadius.medium};
          transition: background-color 0.2s ease;
          caret-color: ${theme.colors.neutral50};
          opacity: 1;
          & input {
            color: ${theme.colors.neutral90};
          }
          & input::placeholder {
            color: ${theme.colors.neutral50} !important;
          }

          label {
            color: ${colorObject.iconColor};
          }

          button {
            width: 40px;
            height: 40px;
          }

          svg {
            width: 24px;
            height: 24px;
            color: ${theme.colors.neutral50};
          }
        `,
        isOpen &&
          css`
            background-color: ${theme.colors.neutral10};
            opacity: 0;
          `,
      ] as (SerializedStyles | false)[],

    dialogCss,
    dialogSearchCss,
    dialogSearchContainerCss,
    searchResultsMaxHeight: (_heightOffset: number) => `calc(100vh - ${TOP_BAR_HEIGHT}px)`,
  };
}

type StyleConfig = ReturnType<typeof getDefaultSearchStyles>;

function getShellThemeSearchStyles(
  {
    isXSmall,
    isLarge,
    isWindows,
    isExpandedView,
    containerLeft,
    containerWidth,
    containerHeight,
    colorObject,
  }: SearchStylesProps,
  base: StyleConfig
): StyleConfig {
  const dialogBarOffset = containerHeight + 15;
  const dialogTop = isLarge ? (isExpandedView ? 0 : 6) : dialogBarOffset;

  const getDialogLeftPosition = () => {
    if (isExpandedView) {
      return 0;
    }

    // container is big enough to fit the dialog without overflowing
    if (containerWidth >= 440) {
      return containerLeft - sidePadding;
    }

    // container is too big to center the dialog
    // but needs a little bit of padding on the left
    if (!isXSmall) {
      return containerLeft - sidePadding - (440 - containerWidth);
    }
    // smallest view. Center it
    return `calc((100% - min(100% - 30px, 520px)) / 2)`;
  };

  return {
    ...base,
    dialogOffsetMainAxis: isExpandedView ? -40 : isXSmall ? -10 : -40,
    dialogTop: dialogTop,
    dialogAnimatedTop: dialogTop,
    dialogHeight: isExpandedView ? '95%' : 'auto',
    dialogLeftPosition: getDialogLeftPosition(),
    containerCss: [
      css`
        flex-shrink: 1;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
      `,
      isLarge
        ? css`
            flex-grow: 1;
            max-width: 518px;
          `
        : css`
            align-items: flex-end;
          `,

      isXSmall &&
        css`
          height: 100%;
        `,

      isXSmall && isWindows
        ? css`
            margin-left: auto;
          `
        : css`
            margin-right: auto;
          `,
    ],

    buttonCss: (isOpen: boolean) => [
      css`
        height: 100%;
        min-width: 56px;
        border-radius: ${theme.borderRadius.small};
        width: 56px;
        color: ${colorObject.iconColor};
        -webkit-app-region: no-drag;

        background: ${isOpen ? colorObject.hover : 'transparent'} !important;

        svg {
          fill: ${colorObject.iconColor};
          color: ${colorObject.iconColor};
        }

        :hover {
          background: ${colorObject.hover} !important;
        }
      `,
      isWindows &&
        css`
          margin-right: auto;
          margin-left: 0;
        `,
    ],

    searchFieldCss: (isOpen: boolean) => [
      css`
        height: 40px;
        width: 100%;
        max-width: 518px;
        outline: none;
        border-radius: ${theme.borderRadius.medium};
        border: 1px solid ${theme.colors.neutral20};
        :focus {
          border: 1px solid ${theme.colors.primary20};
        }

        button {
          &:focus {
            background: ${colorObject.helpColor};
          }
        }
        transition: 0.3s ease;
      `,
      isOpen &&
        css`
          opacity: 0;
        `,
    ],
    searchFieldContainerCss: (isOpen: boolean) => [
      css`
        background: transparent;
        border-radius: 0;
        transition: background-color 0.2s ease;
        caret-color: ${theme.colors.white};
        opacity: 1;
        -webkit-app-region: no-drag;

        & input {
          color: ${theme.colors.neutral90};
        }
        & input::placeholder {
          color: ${colorObject.iconColor} !important;
        }

        label {
          color: ${colorObject.iconColor};
        }

        button {
          width: 30px;
          height: 30px;
          :hover {
            background: ${colorObject.hover} !important;
          }
        }

        svg {
          width: 20px;
          height: 20px;
          color: ${colorObject.iconColor};
        }
      `,
      isOpen &&
        css`
          background-color: ${theme.colors.neutral10};
          opacity: 0;
        `,
    ],
    dialogCss: [
      css`
        width: ${base.dialogWidth}px;
        box-shadow: ${theme.shadows.light};
        overflow: hidden;
        min-width: ${!isLarge ? `min(100% - 40px, ${!isXSmall ? 440 : 520}px)` : '440px'};
        padding: 5px 5px 16px 5px;
        border-radius: 8px;
        top: 90px;
        -webkit-app-region: no-drag;
      `,

      isExpandedView &&
        css`
          width: 100%;
          height: 95%;
        `,
    ],
    dialogSearchCss: [
      css`
        height: 34px;
        width: 100%;
        border-radius: ${theme.borderRadius.medium};
        border: 1px solid ${theme.colors.neutral20};
        box-shadow: none !important;
        outline: none;
        margin-top: ${isExpandedView ? 10 : 0}px;
      `,
    ],
    dialogSearchContainerCss: [
      css`
        background: ${theme.colors.white};
        border-radius: ${theme.borderRadius.medium};
        transition: 0.2s linear;
        caret-color: ${theme.colors.neutral50};
        :focus-within {
          background: ${theme.colors.neutral10};
        }

        & input {
          color: ${theme.colors.neutral90};
        }
        & input::placeholder {
          color: ${theme.colors.neutral50};
        }

        button {
          width: 30px;
          height: 30px;
        }

        svg {
          width: 20px;
          height: 20px;
          color: ${theme.colors.neutral50};
        }
      `,
    ],
    searchResultsMaxHeight: (heightOffset: number) => `calc(100vh - ${TOP_BAR_HEIGHT}px - ${heightOffset}px)`,
  };
}

function getSearchStyles({
  hasShellThemeEnabled,
  containerRef,
  ...props
}: SearchStylesBaseProps & { hasShellThemeEnabled: boolean; containerRef: React.RefObject<HTMLDivElement> }) {
  const { left, width, height: containerRectHeight } = containerRef.current?.getBoundingClientRect() ?? {};
  const containerWidth = width ?? 0;
  const containerLeft = left ?? 0;
  const containerHeight = containerRectHeight || 0;

  const base = getDefaultSearchStyles({ ...props, containerWidth, containerLeft, containerHeight });
  if (hasShellThemeEnabled) {
    return getShellThemeSearchStyles({ ...props, containerWidth, containerLeft, containerHeight }, base);
  }

  return base;
}
export const GlobalSearchMain = ({ children, isWeaveApp = false, className }: GlobalSearchMainProps) => {
  const { t } = useTranslation('global-search');
  const isXIconFlex = useMatchMedia({ maxWidth: breakpoints.medium.min + 70 });
  const isXSmall = useMatchMedia({ maxWidth: breakpoints.xsmall.max });
  const isLarge = useMatchMedia({ minWidth: breakpoints.large.min });

  const { addSearch } = useGlobalRecentSearchesShallowStore('addSearch');
  const { colorObject } = useShellTheme('colorObject');
  const { isShell, featureAvailability, isWindows } = useShell();
  const hasShellThemeEnabled = isShell && featureAvailability?.has('shell-theme');

  const [ref, { height }] = useMeasure();

  const {
    searchTerm,
    setSearchTerm,
    isExpandedView,
    setIsExpandedView,
    debouncedSearchTerm,
    setDebouncedSearchTerm,
    searchFor,
    reset,
  } = useGlobalSearch([
    'searchTerm',
    'setSearchTerm',
    'isExpandedView',
    'setIsExpandedView',
    'debouncedSearchTerm',
    'setDebouncedSearchTerm',
    'searchFor',
    'reset',
  ]);

  const containerRef = useRef<ElementRef<'div'>>(null);

  const styleConfig = getSearchStyles({
    isXSmall,
    isLarge,
    isWindows: !!isWindows,
    hasShellThemeEnabled: !!hasShellThemeEnabled,
    isExpandedView,
    containerRef,
    isWeaveApp,
    colorObject,
  });

  const onSearchChange = useDebouncedFn((searchTerm: string) => {
    setDebouncedSearchTerm(searchTerm);
  }, 300);
  const searchFieldProps = useControlledField({
    type: 'text',
    value: searchTerm,
    onChange: (value) => {
      setSearchTerm(value);
      onSearchChange(value);
    },
  });

  const { getDialogProps, getTriggerProps, open, close, isOpen } = usePopoverDialog({
    placement: 'bottom',
    middlewareOptions: {
      offset: { mainAxis: styleConfig.dialogOffsetMainAxis },
      size: {
        padding: 0,
      },
    },
    onClose: () => {
      reset();
    },
    transform: false,
  });

  useEventListener(
    closeGlobalSearchEvent as any,
    () => {
      reset();
      close();
    },
    true
  );

  return (
    <div
      ref={containerRef}
      className={className}
      css={styleConfig.containerCss}
      onClick={() => {
        searchFieldProps.onFocus();
        open();
      }}
    >
      {!isLarge && (
        <Button
          {...getTriggerProps({
            onClick: () => {
              if (isOpen) {
                close();
              } else {
                searchFieldProps.onFocus();
              }
            },
          })}
          variant='secondary'
          trackingId={GlobalSearchTrackingIds.mobileSearchOpen}
          hoverLabel={isOpen ? '' : t('Open Global Search')}
          iconName='search'
          size='large'
          className={`global-search__trigger ${isOpen ? 'global-search__trigger--open' : ''}`}
          css={styleConfig.buttonCss(isOpen)}
        />
      )}
      {isLarge && (
        <SearchField
          name='Global Search'
          data-trackingid={GlobalSearchTrackingIds.searchField}
          onSearchSubmit={() => {
            if (!debouncedSearchTerm) return;
            setIsExpandedView(true);
          }}
          placeholder={t('Search Weave')}
          readOnly
          clearable={false}
          css={styleConfig.searchFieldCss(isOpen)}
          containerCss={styleConfig.searchFieldContainerCss(isOpen)}
          {...searchFieldProps}
        />
      )}
      <PopoverDialog
        initial={{
          opacity: 0,
          height: 'auto',
          width: styleConfig.dialogWidth,
          top: styleConfig.dialogTop,
          left: styleConfig.dialogLeftPosition,
        }}
        animate={{
          opacity: 1,
          width: styleConfig.dialogAnimatedWidth,
          height: styleConfig.dialogHeight,
          top: styleConfig.dialogAnimatedTop,
          left: styleConfig.dialogLeftPosition,
        }}
        exit={undefined}
        transition={{
          duration: 0.3,
          ease: 'linear',
        }}
        {...getDialogProps()}
        css={styleConfig.dialogCss}
      >
        <motion.div
          css={css`
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            form {
              flex: 1;
              max-width: ${isExpandedView ? '748px' : '518px'};
              transition: 0.3s ease;
            }
          `}
        >
          <SearchField
            name='Global Search'
            onSearchSubmit={() => {
              if (!debouncedSearchTerm) return;
              setIsExpandedView(true);
              addSearch(searchTerm, searchFor);
            }}
            placeholder='Search'
            clearable={false}
            css={styleConfig.dialogSearchCss}
            containerCss={styleConfig.dialogSearchContainerCss}
            {...searchFieldProps}
            onFocus={(e) => {
              searchFieldProps.onFocus(e);
              open();
            }}
            onBlur={(e) => {
              searchFieldProps.onBlur(e);
            }}
          />
          {isExpandedView && (
            <IconButton
              trackingId={GlobalSearchTrackingIds.closeExpandedView}
              css={[
                !isXIconFlex &&
                  css`
                    position: absolute;
                    top: 12px;
                    right: 8px;
                  `,
              ]}
              label={t('Close Global Search')}
              onClick={() => {
                setIsExpandedView(false);
                close();
              }}
            >
              <Icon name='x' />
            </IconButton>
          )}
        </motion.div>
        <motion.div
          css={css`
            margin: 0 -5px -9px -5px;
          `}
          animate={{ height: height }}
        >
          <motion.div
            ref={ref}
            css={(theme) => css`
              max-width: ${isExpandedView ? '748px' : '518px'};
              transition: 0.3s ease;
              margin: auto;
              display: flex;
              flex-direction: column;
              max-height: ${styleConfig.searchResultsMaxHeight(theme.heightOffset)};
            `}
          >
            {!debouncedSearchTerm ? (
              <RecentSearches />
            ) : (
              <>
                {children}
                <AllResultsButton />
              </>
            )}
          </motion.div>
        </motion.div>
      </PopoverDialog>
    </div>
  );
};

const AllResultsButton = () => {
  const { t } = useTranslation('global-search');
  const { searchFor, isExpandedView, debouncedSearchTerm, setIsExpandedView } = useGlobalSearch([
    'searchFor',
    'isExpandedView',
    'debouncedSearchTerm',
    'setIsExpandedView',
  ]);

  if (isExpandedView) return null;

  return (
    <div
      css={css`
        margin: 0 -5px -8px -5px;
        border-top: 1px solid ${theme.colors.neutral20};
      `}
    >
      <NakedButton
        trackingId={GlobalSearchTrackingIds.viewAllResults}
        onClick={() => setIsExpandedView(true)}
        css={css`
          padding: ${theme.spacing(2)};
          width: 100%;
          color: ${theme.font.colors.primary};
          font-size: ${theme.font.size.medium};
          font-weight: ${theme.font.weight.bold};

          :hover {
            background-color: ${theme.colors.neutral5};
          }
        `}
      >
        {searchFor === 'all' ? t("All Results for '{{debouncedSearchTerm}}'", { debouncedSearchTerm }) : t('View More')}
      </NakedButton>
    </div>
  );
};
