import { SpinningLoader, Text, TextLink } from '@frontend/design-system';
import { Trans, useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { ComponentProps } from 'react';
import { useBulkEmailEditorShallowStore, useBulkMessagingNavigator } from '../../hooks';

type ReplyToProps = ComponentProps<typeof Text> & {
  isMultiLocation: boolean;
  isReplyToLoading: boolean;
  replyTo?: string;
};

export const ReplyTo = ({ isMultiLocation, isReplyToLoading, replyTo, ...rest }: ReplyToProps) => {
  const { t } = useTranslation('bulk-messaging');
  const navigateTo = useBulkMessagingNavigator(true);
  const { enableValidation } = useBulkEmailEditorShallowStore('enableValidation');
  const hasError = !replyTo && !isReplyToLoading;

  return (
    <Text {...rest}>
      {isMultiLocation ? (
        <Trans t={t}>
          Manage reply-to email addresses in
          <TextLink onClick={navigateTo.emailSettings} css={{ marginLeft: theme.spacing(0.5) }}>
            Email Settings
          </TextLink>
        </Trans>
      ) : (
        <Trans t={t}>
          Reply-To Address: <ReplyToText replyTo={replyTo} isReplyToLoading={isReplyToLoading} />
        </Trans>
      )}
      {hasError && enableValidation && (
        <Text color='error' size='small' css={{ margin: theme.spacing(1, 0, 3, 0) }}>
          {t('This field is required')}
        </Text>
      )}
    </Text>
  );
};

type ReplyToTextProps = {
  isReplyToLoading: boolean;
  replyTo?: string;
};

const ReplyToText = ({ isReplyToLoading, replyTo }: ReplyToTextProps) => {
  const { t } = useTranslation('bulk-messaging');
  const navigateTo = useBulkMessagingNavigator(true);

  return isReplyToLoading ? (
    <SpinningLoader
      size='xs'
      css={{
        position: 'absolute',
        marginLeft: theme.spacing(0.5),
      }}
    />
  ) : (
    <TextLink onClick={navigateTo.emailSettings} css={{ marginLeft: theme.spacing(0.5) }}>
      {replyTo ? replyTo : t('Set Reply-To Address')}
    </TextLink>
  );
};
