import { Icon } from '@frontend/icons';
import { InsuranceDetailsConstants } from '@frontend/insurance-details';
import { ChecklistField, Chip, ListFieldProps } from '@frontend/design-system';

const { INSURANCE_STATUS_MAPPING } = InsuranceDetailsConstants;

type CheckListProps = {
  checkListProps: ListFieldProps;
};

export const InsuranceStatusCheckList = ({ checkListProps }: CheckListProps) => {
  return (
    <ChecklistField
      name='insuranceStatusCheckList'
      labelPlacement='right'
      label={''}
      css={{ maxHeight: '400px', overflowY: 'auto', width: '100%' }}
      {...checkListProps}
    >
      {Array.from(INSURANCE_STATUS_MAPPING.entries()).map((status) => {
        const [key, value] = status;

        return (
          <ChecklistField.Option key={key} name={value.text.toLowerCase().replace(' ', '_') ?? ''}>
            <Chip
              variant={value.variant}
              css={{ minWidth: 'fit-content', maxWidth: '100%', textTransform: 'capitalize' }}
              leftElement={<Icon name={value.iconName} css={{ color: 'currentcolor' }} />}
            >
              {value.text}
            </Chip>
          </ChecklistField.Option>
        );
      })}
    </ChecklistField>
  );
};
