import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { BASE_URL_ANALYTICS, PhoneAnalyticsApi, PhoneAnalyticsTypes } from '@frontend/api-analytics';
import { http } from '@frontend/fetch';
import { useTranslation } from '@frontend/i18n';
import { useScopedQuery } from '@frontend/scope';
import { useAlert } from '@frontend/design-system';
import { hourlyInsightsLimiter } from '../../../utils';
import { useGetChartPayloadAndConfig } from './use-get-chart-payload-and-config';
import { usePhoneAnalyticsShallowStore } from './use-phone-analytics-store';

type DateAggregate = {
  hourly?: number[];
  total: number;
};

type DateData = {
  date: string;
  aggregates?: (DateAggregate & { key: 'inbound' | 'outbound' })[];
};

type LocationData = {
  id: string;
  dates?: DateData[];
};

export type LongDurationCallsResponse = {
  locationIds?: LocationData[];
};

type FormattedLocations = {
  [locationId: string]: {
    [date: string]: {
      inbound?: DateAggregate;
      outbound?: DateAggregate;
    };
  };
};

type AggregatedMultiLocations = {
  [locationId: string]: {
    inbound?: number;
    outbound?: number;
  };
};

type DataState = {
  aggregatedMultiLocations?: AggregatedMultiLocations;
  locations: FormattedLocations;
};

const formatLocationsData = (locationIds?: LocationData[]): DataState => {
  const aggregatedMultiLocations: AggregatedMultiLocations = {};

  const formattedLocations =
    locationIds?.reduce((acc, { dates, id }) => {
      return {
        ...acc,
        [id]:
          dates?.reduce(
            (acc2, { aggregates, date }) =>
              aggregates?.reduce<{ [date: string]: DateAggregate }>((acc3, { hourly = [], key, total = 0 }) => {
                aggregatedMultiLocations[id] = {
                  ...(aggregatedMultiLocations[id] || {}),
                  [key]: (aggregatedMultiLocations[id]?.[key] || 0) + total,
                };

                return {
                  ...acc3,
                  [date]: {
                    ...acc3[date],
                    [key]: {
                      hourly: hourlyInsightsLimiter(hourly),
                      total,
                    },
                  },
                };
              }, acc2) || {},
            {}
          ) || {},
      };
    }, {}) || {};

  return {
    aggregatedMultiLocations,
    locations: formattedLocations,
  };
};

const api = async (filters: Partial<PhoneAnalyticsTypes.Filters>) => {
  const { data } = await http.post<{ data: LongDurationCallsResponse }>(
    `${BASE_URL_ANALYTICS}/${'phonereports/v2/getlongcallsaggregates'}`,
    PhoneAnalyticsApi.getDatesIntercepted(filters)
  );
  return data;
};

export const useFetchAggregatedLongDurationCallReports = (): PhoneAnalyticsTypes.UseFetchPhoneReports<DataState> => {
  const alert = useAlert();
  const { t } = useTranslation('analytics');
  const { demoData, filters, isDemoAccount } = usePhoneAnalyticsShallowStore('demoData', 'filters', 'isDemoAccount');
  const [data, setData] = useState<DataState | undefined>();
  const { applicableFilters, config } = useGetChartPayloadAndConfig(filters, 'long-duration-calls');

  const isMultiLocation = (applicableFilters.location_id || []).length > 1;
  const isHourlyData =
    !isMultiLocation &&
    !!applicableFilters.start_date &&
    !!applicableFilters.end_date &&
    dayjs(applicableFilters.start_date).isSame(applicableFilters.end_date, 'day');

  const { data: longDurationCalls, isLoading } = useScopedQuery({
    ...config,
    queryFn: () => api(applicableFilters),
    onError: () => {
      alert.error(t("Couldn't load the aggregated long duration calls. Please try again."));
    },
  });

  useEffect(() => {
    const dataToUse = isDemoAccount ? demoData?.chartsData.longDurationCalls : longDurationCalls;
    setData(formatLocationsData(dataToUse?.locationIds));
  }, [demoData, isDemoAccount, longDurationCalls]);

  return {
    data,
    isHourlyData,
    isLoading,
    isMultiLocation,
  };
};
