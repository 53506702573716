import { SchemaIO } from '@frontend/schema';
import { useSMSDataV3QueryUpdaters } from '../query-updaters';
import { ServiceMutations } from '../types';
import { useSMSDataV3Mutation, UseSMSDataV3MutationEndpointArgs } from './use-sms-data-v3-mutation';

/**
 * A hook that returns a mutation for the `SetThreadsActionable` mutation endpoint.
 * It handles query invalidation for the relevant query endpoints internally.
 * @param options (optional) The options to pass to `useMutation`.
 * @param httpOptions (optional) The http options to pass to the schema function.
 */
export const useSetThreadsActionableMutation = <
  E = unknown,
  C = unknown,
  OtherOptions extends object = never,
  RequestOverride extends SchemaIO<ServiceMutations['SetThreadsActionable']>['input'] = SchemaIO<
    ServiceMutations['SetThreadsActionable']
  >['input']
>({
  options,
  ...args
}: UseSMSDataV3MutationEndpointArgs<'SetThreadsActionable', E, C | undefined, OtherOptions, RequestOverride> = {}) => {
  const { updateThreadsActionableStatus } = useSMSDataV3QueryUpdaters();

  return useSMSDataV3Mutation<'SetThreadsActionable', E, C, OtherOptions, RequestOverride>({
    endpointName: 'SetThreadsActionable',
    ...args,
    options: {
      ...options,
      onSuccess: (response, request, context) => {
        const { userId, ...updateRequest } = request;
        updateThreadsActionableStatus({ ...updateRequest, actionable: false });
        return options?.onSuccess?.(response, request, context);
      },
    },
  });
};
