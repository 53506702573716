import { DataSourcesApi } from '@frontend/api-data-sources';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore, useScopedQuery } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { DropdownFilterChipField, ListFieldProps, styles } from '@frontend/design-system';

export const ClientLocationFilter = ({ checkListField }: { checkListField: ListFieldProps }) => {
  const { t } = useTranslation('contacts');
  const { singleLocationId } = useAppScopeStore();
  const clientLocationAccessQuery = useScopedQuery({
    queryKey: ['client-locations-access'],
    queryFn: () => {
      return DataSourcesApi.getDesktopLocationAccessData(singleLocationId);
    },
  });

  return (
    <div css={{ marginRight: theme.spacing(2), marginBottom: theme.spacing(2) }}>
      <DropdownFilterChipField
        {...checkListField}
        getCustomLabel={(items) => (items.length > 1 ? `${items.length} Locations` : items[0])}
        label={t('Locations')}
        name='location-filter'
        showClearAll={false}
        onApply={({ close }) => close()}
      >
        {clientLocationAccessQuery?.data?.ClientLocations.map((location) => (
          <DropdownFilterChipField.Option
            key={location.ClientLocationID}
            value={location.ClientLocationID}
            css={{ label: styles.truncate }}
          >
            {location.Name}
          </DropdownFilterChipField.Option>
        ))}
      </DropdownFilterChipField>
    </div>
  );
};
